/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import {
  BookmarkSmallIcon,
  ClockIcon,
  EyeSmallIcon,
  PlusIcon,
  QuoteIcon,
  ReviewSmallIcon,
  StarIcon,
  TickSmallIcon,
} from '../icons';

export default function ActivityDot({
  list,
  isOnBlueberry,
  isReview,
  isOnFeed = false,
}) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const color = getItemColor(list);
  const boxShadow = getBoxShadow({ isDark, isOnBlueberry, isOnFeed });

  const sx = {
    width: 16,
    height: 16,
    borderRadius: '50%',
    boxShadow,
    ...color,
  };

  return (
    <div sx={sx}>
      <ActivityIcon height={16} list={list} isReview={isReview} width={16} />
    </div>
  );
}

const ActivityIcon = ({ list, isReview, ...props }) => {
  if (isReview) {
    return <ReviewSmallIcon {...props} />;
  }
  switch (list) {
    case 'read':
      return <TickSmallIcon {...props} />;
    case 'reading':
      return <EyeSmallIcon {...props} />;
    case 'to-read':
      return <BookmarkSmallIcon {...props} />;
    case 'recs':
      return <StarIcon {...props} />;
    case 'abandoned':
      return <ClockIcon {...props} />;
    // TODO: unsure what the list key will be here
    case 'highlighted':
      return <QuoteIcon {...props} />;
    default:
      return <PlusIcon {...props} />;
  }
};

const getItemColor = list => {
  switch (list) {
    case 'read':
    case 'recs':
      return {
        color: 'milk',
        bg: 'kiwi.100',
      };
    case 'reading':
      return {
        color: 'milk',
        bg: 'banana.100',
      };
    case 'to-read':
      return {
        color: 'milk',
        bg: 'dragonfruit.100',
      };
    // TODO: unsure what the list key will be here
    case 'highlighted':
      return {
        color: 'blackberry',
        bg: 'milk',
      };
    default:
      return {
        color: 'milk',
        bg: 'blueberry.80',
      };
  }
};

const getBoxShadow = ({ isOnBlueberry, isDark, isOnFeed }) => {
  if (isOnFeed) {
    return 'activityDot.raised.light';
  }
  if (isDark && isOnBlueberry) {
    return 'activityDot.blueberry.dark';
  }
  if (!isDark && isOnBlueberry) {
    return 'activityDot.blueberry.light';
  }
  if (isDark && !isOnBlueberry) {
    return 'activityDot.dark';
  }
  if (!isDark && !isOnBlueberry) {
    return 'activityDot.light';
  }
  return null;
};
