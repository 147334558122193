import React from 'react';

import Svg from '../../components/Svg';

export default function ProfileIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m9 11c0-2.76142 2.2386-5 5-5s5 2.23858 5 5c0 2.7614-2.2386 5-5 5-2.7614 0-5-2.2386-5-5zm9.0256 5.7274c1.799-1.2668 2.9744-3.3599 2.9744-5.7274 0-3.86599-3.134-7-7-7s-7 3.13401-7 7c0 2.3675 1.17535 4.4605 2.97439 5.7274-.59421.2219-1.1712.4935-1.72467.8129-1.74844 1.0091-3.20053 2.4606-4.21041 4.2086-.27628.4782-.11258 1.0898.36564 1.3661.47821.2763 1.08985.1126 1.36613-.3656.8343-1.4441 2.03393-2.6432 3.47838-3.4769 1.44444-.8336 3.08284-1.2725 4.75054-1.2725h.0001c1.6677 0 3.3061.4389 4.7506 1.2726 1.4444.8337 2.644 2.0328 3.4783 3.4769.2762.4783.8879.642 1.3661.3657s.6419-.8879.3657-1.3661c-1.0099-1.748-2.4619-3.1995-4.2104-4.2087-.5535-.3194-1.1305-.591-1.7248-.813z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
