/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { hasTouch } from '../utils';

import { PRICING_ROUTE } from '../constants';

import Emoji from './Emoji';
import Link from './Link';

export default function Notification() {
  const location = useLocation();
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const sx = getStyles(isDark);
  return (
    <div sx={sx.container}>
      <div sx={sx.text}>
        <Link
          as={RouterLink}
          hasTransition={false}
          to={{ pathname: PRICING_ROUTE, search: location.search }}
          variant="primary"
          sx={sx.link}
        >
          <Emoji character="👋" label="Wave" /> Welcome Hunters! Get{' '}
          <strong sx={sx.strong}>20% off</strong> our premium memberships just
          for you &#8594;
        </Link>
      </div>
    </div>
  );
}

const getStyles = isDark => ({
  container: {
    width: '100%',
    bg: 'milk',
    borderRadius: 'button',
    boxShadow: isDark
      ? '0px 4px 21px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(193,190,203,0.1)'
      : '0px 4px 21px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(19,19,20,0.1)',
    willChange: 'transform',
    transition: 'smooth.fast',

    '&:hover': !hasTouch() && {
      transform: 'translate3d(0, -1px, 0)',
      bg: 'blueberry.2',

      boxShadow: isDark
        ? '0px 4px 21px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(193,190,203,0.14)'
        : '0px 4px 21px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(19,19,20,0.14)',
    },

    '&:active': {
      transform: 'translate3d(0, 0, 0)',
      boxShadow: isDark
        ? '0px 4px 21px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(193,190,203,0.1)'
        : '0px 4px 21px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(19,19,20,0.1)',
    },
  },
  text: {
    fontSize: 'xs',
    lineHeight: 'sub',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: 'blueberry.80',

    svg: {
      marginLeft: 'xxs',
    },
  },
  link: {
    padding: 's',
  },
  strong: {
    color: 'blackberry',
    fontWeight: 'button',
    display: 'inline',
  },
});
