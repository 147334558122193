import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import {
  ACTIVITY_EVERYBODY_ROUTE,
  ACTIVITY_FRIENDS_ROUTE,
} from '../../constants';

import { ACTIVITY_PREFERENCE, ACTIVE_TAB_GLOBAL } from './constants';

export default function Activity() {
  const dispatch = useDispatch();
  const preference = localStorage.getItem(ACTIVITY_PREFERENCE);

  if (preference === ACTIVE_TAB_GLOBAL) {
    dispatch(replace(ACTIVITY_EVERYBODY_ROUTE));
    return null;
  }

  dispatch(replace(ACTIVITY_FRIENDS_ROUTE));
  return null;
}
