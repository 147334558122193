import React from 'react';

import Svg from '../../components/Svg';

export default function PencilIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m16.6 8.21553-1.4015 1.40152 3.089 3.08895 1.4015-1.4015zm-8.15145 8.15147 5.90145-5.9014 3.0889 3.0889-5.9014 5.9015zm-1.38005-.1602c-.04528.126-.0685.259-.0685.3932v3.142c0 .3083.12248.604.34049.822s.5137.3405.82201.3405h3.142c.1527 0 .3038-.0301.4449-.0885.141-.0584.2692-.144.3771-.252l6.5792-6.5792.0061-.0059.0059-.0061 1.8463-1.8463c.218-.218.3405-.5137.3405-.822s-.1225-.604-.3405-.822l-3.142-3.14201c-.218-.21801-.5137-.34049-.822-.34049s-.604.12248-.822.34049l-8.43751 8.43751c-.10795.1079-.19358.2361-.252.3771-.00293.0071-.00579.0142-.00858.0213-.00408.0101-.00789.0202-.01141.0304zm1.1315 1.6087 1.889 1.889h-1.889z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
