export const fonts = {
  body:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  mono:
    '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace',
  serif: 'FabricSerifWeb, Georgia, Cambria, "Times New Roman", Times, serif',
};

export const fontSizes = {
  xxl: 48,
  xl: 36,
  l: 24,
  m: 16,
  s: 15,
  xs: 14,
  xxs: 12,
  xxxs: 10,
  xxxxs: 9,
};

export const fontWeights = {
  body: 400,
  heading: 400,
  button: 500,
  bold: 700,
};

export const lineHeights = {
  base: 1,
  body: 1.8,
  heading: 1.3,
  sub: 1.5,
};
