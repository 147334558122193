import React from 'react';

import Svg from '../../components/Svg';

export default function ProfileIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m10.25 11.75c0-2.07107 1.6789-3.75 3.75-3.75s3.75 1.67893 3.75 3.75c0 2.0711-1.6789 3.75-3.75 3.75-2.0711 0-3.75-1.6789-3.75-3.75zm6.7692 4.2955c1.3493-.9501 2.2308-2.5198 2.2308-4.2955 0-2.8995-2.3505-5.25-5.25-5.25s-5.25 2.3505-5.25 5.25c0 1.7756.88151 3.3454 2.2308 4.2955-.4457.1665-.8784.3702-1.29351.6097-1.31133.7569-2.4004 1.8455-3.1578 3.1565-.20721.3586-.08444.8173.27422 1.0246.35866.2072.81739.0844 1.0246-.2743.62572-1.083 1.52545-1.9824 2.60879-2.6076 1.0833-.6253 2.3121-.9544 3.5629-.9544h.0001c1.2508 0 2.4796.3292 3.5629.9545 1.0833.6252 1.983 1.5246 2.6087 2.6077.2072.3586.666.4814 1.0246.2742.3587-.2072.4815-.6659.2743-1.0246-.7574-1.311-1.8465-2.3996-3.1578-3.1565-.4151-.2396-.8479-.4433-1.2936-.6098z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
