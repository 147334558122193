/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SwiperSlide } from 'swiper/react';
import { useResponsiveValue } from '@theme-ui/match-media';

import CardPortrait from '../../components/CardPortrait';
import SwiperContainer from '../../components/SwiperContainer';
import SwiperGrid from '../../components/SwiperGrid';
import SwiperHeading from '../../components/SwiperHeading';

import { getBookAuthors, getBookTitle } from '../../selectors';

import {
  SWIPER_RECOMMENDED_GRID,
  SWIPER_RECOMMENDED_NEXT,
  SWIPER_RECOMMENDED_PREV,
} from '../../constants';

export default function SwiperRecommended({ books }) {
  const heading = useResponsiveValue([
    'Most recommended',
    'Most recommended',
    'Most recommended recently',
  ]);
  if (books?.length === 0) {
    return null;
  }
  return (
    <SwiperContainer>
      <SwiperHeading
        heading={heading}
        prevClassName={SWIPER_RECOMMENDED_PREV}
        nextClassName={SWIPER_RECOMMENDED_NEXT}
      />
      <SwiperGrid
        gridClassName={SWIPER_RECOMMENDED_GRID}
        prevClassName={SWIPER_RECOMMENDED_PREV}
        nextClassName={SWIPER_RECOMMENDED_NEXT}
      >
        {books.map(item => {
          const title = getBookTitle({
            title: item.title,
            subtitle: item.subtitle,
          });
          return (
            <SwiperSlide key={item.id}>
              <CardPortrait
                authors={getBookAuthors(item.authors)}
                shouldRespond={false}
                slug={item.slug}
                thumbnail={item.imageLinks.thumbnail}
                title={title}
              />
            </SwiperSlide>
          );
        })}
      </SwiperGrid>
    </SwiperContainer>
  );
}
