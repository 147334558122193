import mixpanel from 'mixpanel-browser';
import fetchJsonp from 'fetch-jsonp';
import objectReducer, { setKey, SET_KEY } from './object';
import { callApi } from '../utils/api';
import { setProfileTier } from './profile';

const PRECHECKOUT = '/api/checkout/begin';
const POSTCHECKOUT = '/api/checkout/complete';
const APPLE_POSTCHECKOUT = '/api/checkout/complete/ios';
const SWITCH_SUB = '/api/subscription/switch';

const SET_SUBSCRIPTION = 'sub.set';
export const SET_PRICES = 'prices.set';
const subscriptionReducer = (
  state = {
    lastFetched: null,
    items: {},
  },
  action
) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
    case SET_PRICES:
      return objectReducer(state, {
        ...action,
        type: SET_KEY,
      });
    default:
      return state;
  }
};

export default subscriptionReducer;

export function fetchLocalPrices({ productIds, dispatch }) {
  const stringIds = productIds.join(',');
  const url = `https://checkout.paddle.com/api/2.0/prices?product_ids=${stringIds}`;

  return fetchJsonp(url)
    .then(resp => {
      return resp.json();
    })
    .then(data => {
      if (!data.success) {
        throw new Error(data.error);
      }

      const productPrices = {};
      data.response.products.forEach(product => {
        productPrices[product.product_id] = {
          currency: product.currency,
          price: product.price,
          title: product.product_title,
          id: product.product_id,
        };
      });

      dispatch(
        setKey({ type: SET_PRICES, key: 'prices', value: productPrices })
      );
    })
    .catch(ex => {
      console.error('error', ex);
    });
}

export function startCheckoutSession({ token, tier, interval }) {
  return callApi(PRECHECKOUT, {
    method: 'POST',
    token,
    body: { tier, interval },
  }).then(data => {
    return {
      customerId: data.customer_id,
      sessionKey: data.session_key,
      paddlePlanId: data.paddle_plan_id,
      paddleSubId: data.paddle_sub_id,
      subscription: data.subscription,
    };
  });
}

export function pollCheckoutSession({
  dispatch,
  token,
  checkoutId,
  sessionKey,
  username,
}) {
  return callApi(POSTCHECKOUT, {
    method: 'POST',
    token,
    body: { sessionKey, checkoutId },
  }).then(({ status, subscription }) => {
    if (status === 'processed') {
      dispatch(
        setKey({ type: SET_SUBSCRIPTION, key: 'details', value: subscription })
      );
      dispatch(setProfileTier(username, subscription.tier));
      mixpanel.track('Checkout: Upgraded', {
        tier: subscription.tier,
        interval: subscription.interval,
      });
    }
  });
}

export function reconcileAppleSubscription({ dispatch, token, transactionId }) {
  return callApi(APPLE_POSTCHECKOUT, {
    method: 'POST',
    token,
    body: { transactionId },
  }).then(({ subscription, username }) => {
    dispatch(
      setKey({ type: SET_SUBSCRIPTION, key: 'details', value: subscription })
    );
    // subscription could be null, in this case user is free.
    const tier = subscription?.tier || 'free';
    dispatch(setProfileTier(username, tier));
    // todo: track event here
    // if (status === 'processed') {
    //   mixpanel.track('Checkout: Upgraded', {
    //     tier: subscription.tier,
    //     interval: subscription.interval,
    //   });
    // }
    return { subscription, username };
  });
}

export function switchExistingSubscription({
  dispatch,
  token,
  tier,
  interval,
  username,
}) {
  return callApi(SWITCH_SUB, {
    method: 'POST',
    token,
    body: { tier, interval },
  }).then(subscription => {
    dispatch(
      setKey({ type: SET_SUBSCRIPTION, key: 'details', value: subscription })
    );
    dispatch(setProfileTier(username, subscription.tier));
    return subscription;
  });
}

export function fetchSubscriptionDetails({ dispatch, token }) {
  return callApi(SWITCH_SUB, {
    token,
  }).then(data => {
    dispatch(
      setKey({
        type: SET_SUBSCRIPTION,
        key: 'details',
        value: data.subscription,
      })
    );
  });
}
