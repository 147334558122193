/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { use100vh } from 'react-div-100vh';

export default function FullHeight({
  alignItems,
  children,
  flexDirection = 'column',
  justifyContent,
}) {
  const height = use100vh();
  const minHeight = height || '100vh';
  return (
    <div
      sx={{
        alignItems,
        display: 'flex',
        flexDirection,
        justifyContent,
        minHeight,
      }}
    >
      {children}
    </div>
  );
}
