/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jsx, useColorMode } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Link from './Link';
import Text from './Text';
import ModalDialog from './ModalDialog';
import ProgressBar from './ProgressBar';
import SidebarLink from './SidebarLink';
import ReadingGoalModal from '../modals/ReadingGoal';

import {
  getActiveReadingGoal,
  getBooksAddedSince,
  getCurrentGoalProgress,
  getReadList,
  getToken,
} from '../selectors';

import { getReadingGoalRoute } from '../constants';

import { RainbowIcon, CaretUpIcon } from '../icons';
import { fetchReadingGoal } from '../ducks/goals';

export default function SidebarReadingGoal({
  enableGoals,
  hideReadingGoal,
  isMinimised,
  showReadingGoal,
  showUpsells,
}) {
  const [colorMode] = useColorMode();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const progress = useSelector(getCurrentGoalProgress);
  const cutoff = moment().startOf('year');
  const books = getBooksAddedSince(useSelector(getReadList), cutoff).reverse();
  const goal = useSelector(getActiveReadingGoal);
  const booksRead = books.length;

  const READING_GOAL_ROUTE = getReadingGoalRoute();

  useEffect(() => {
    fetchReadingGoal({ token, dispatch });
  }, []);

  const isDarkMode = colorMode === 'dark';
  const sx = getStyles({ isDarkMode });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!enableGoals) {
    if (!showUpsells) {
      return null;
    }
    return (
      <div sx={sx.minimised}>
        <SidebarLink icon={RainbowIcon} onClick={openModal}>
          Reading Goal
        </SidebarLink>
        <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
          <ReadingGoalModal onClose={closeModal} />
        </ModalDialog>
      </div>
    );
  }

  if (isMinimised === 1) {
    return (
      <div sx={sx.minimised}>
        <SidebarLink icon={RainbowIcon} to={READING_GOAL_ROUTE}>
          Reading Goal
        </SidebarLink>
        <div sx={sx.buttonWrap}>
          <SidebarLink extraStyles={sx.button} onClick={showReadingGoal}>
            <CaretUpIcon width={11} />
          </SidebarLink>
        </div>
        <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
          <ReadingGoalModal onClose={closeModal} />
        </ModalDialog>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div sx={sx.container}>
        <div sx={sx.header}>
          <Link as={RouterLink} to={READING_GOAL_ROUTE} sx={sx.headerInner}>
            <RainbowIcon /> Reading Goal
          </Link>
          <SidebarLink extraStyles={sx.buttonRotated} onClick={hideReadingGoal}>
            <CaretUpIcon width={11} />
          </SidebarLink>
        </div>
        <div sx={sx.progress}>
          <ProgressBar progress={`${progress}%`} />
        </div>
        <footer sx={sx.footer}>
          {goal ? (
            <Text fontSize="xxs">
              {booksRead}/{goal} books
            </Text>
          ) : (
            <div />
          )}
          <Link onClick={openModal} variant="body" hasUnderline>
            {goal ? 'Edit' : 'Set'} goal
          </Link>
        </footer>
      </div>
      <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
        <ReadingGoalModal onClose={closeModal} />
      </ModalDialog>
    </React.Fragment>
  );
}

const getStyles = ({ isDarkMode }) => ({
  container: {
    boxShadow: isDarkMode
      ? '0 0 0 1px rgba(255, 255, 255, 0.15), 0px 4px 4px -5px rgba(255, 255, 255, 0.25)'
      : '0 0 0 1px rgba(19, 19, 20, 0.15), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
    borderRadius: 'button',
    marginBottom: 's',
    marginTop: 'xxs',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingLeft: 's',
    paddingRight: 'xs',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    color: 'blueberry.80',
    fontSize: 's',

    svg: {
      marginRight: 'xs',
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 'xxs',
    paddingX: 's',
    paddingBottom: 's',
  },
  progress: {
    marginBottom: 10,
    marginTop: 12,
    paddingX: 's',
  },
  minimised: {
    position: 'relative',
  },
  buttonWrap: {
    position: 'absolute',
    top: '50%',
    right: 5,
    marginTop: -15,
  },
  button: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRotated: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      transform: 'rotate(180deg)',
    },
  },
});
