import React from 'react';

import Svg from '../components/Svg';

export default function PencilIcon({ ...props }) {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2082 1.79188C9.82532 1.47153 9.25193 1.49088 8.89435 1.84911L8.63128 2.11266L9.88651 3.37017L10.1493 3.108C10.5082 2.75005 10.5297 2.17721 10.2082 1.79188ZM11.5118 0.919104C12.2394 1.91455 12.1538 3.31935 11.2547 4.21614L3.68068 11.7715C3.53397 11.9178 3.3352 12 3.12798 12H0.782609C0.350386 12 0 11.6496 0 11.2174V8.86777C0 8.66046 0.0822588 8.46161 0.22872 8.31489L7.78658 0.743335C8.68536 -0.15708 10.0907 -0.236568 11.082 0.489022C11.1445 0.523719 11.2033 0.567629 11.2563 0.620751L11.38 0.74468C11.3803 0.744987 11.3806 0.745295 11.3809 0.745604M8.77837 4.47557L7.52551 3.22044L1.56522 9.19152V10.4348H2.80438L8.77837 4.47557Z"
      />
    </Svg>
  );
}
