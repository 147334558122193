/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import React from 'react';

import PageHeader from './PageHeader';
import ScrollToTop from './ScrollToTop';
import { getIsAuthenticated } from '../selectors';

import theme from '../theme';

export default function Page({
  children,
  hasSidebar = true,
  headerless = false,
  shouldScrollTop = true,
  title,
}) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const isAuthenticated = useSelector(getIsAuthenticated);
  const bodyStyle = isAuthenticated &&
    hasSidebar && {
      paddingLeft: [0, 0, 0, theme.sizes.sidebar, theme.sizes.sidebar],
    };
  return (
    <ShouldScrollToTop shouldScrollTop={shouldScrollTop}>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={`${title} / Oku`} />
          <meta name="twitter:title" content={`${title} / Oku`} />
          {isDark ? (
            <meta name="theme-color" content="#131314" />
          ) : (
            <meta name="theme-color" content="#FFFFFF" />
          )}
        </Helmet>
      )}
      {!headerless && <PageHeader />}
      <main sx={bodyStyle}>{children}</main>
    </ShouldScrollToTop>
  );
}

function ShouldScrollToTop({ shouldScrollTop, children }) {
  if (shouldScrollTop) {
    return <ScrollToTop>{children}</ScrollToTop>;
  }
  return <React.Fragment>{children}</React.Fragment>;
}
