/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import React, { useState } from 'react';

import Heading from '../../components/Heading';
import SelectableList from '../../components/SelectableList';

import { MoonIcon, SunIcon, ComputerIcon } from '../../icons';

import {
  APPEARANCE_PREFERENCE,
  MODE_DARK,
  MODE_LIGHT,
  MODE_SYSTEM_DEFAULT,
} from '../../constants';
import { messageNative } from '../../utils/native';

export default function Appearance() {
  const [activeItem, setActiveItem] = useState(
    localStorage.getItem(APPEARANCE_PREFERENCE) || MODE_SYSTEM_DEFAULT // set to system default by default
  );
  const [, setColorMode] = useColorMode();

  const savePreference = id => {
    setActiveItem(id);

    if (id === MODE_LIGHT) {
      messageNative({ colorMode: 'default' });
      setColorMode('default');
    }

    if (id === MODE_DARK) {
      setColorMode('dark');
      messageNative({ colorMode: 'dark' });
    }

    if (id === MODE_SYSTEM_DEFAULT) {
      const darkModeMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)'
      );

      if (darkModeMediaQuery.matches) {
        setColorMode('dark');
        messageNative({ colorMode: 'dark' });
      } else {
        messageNative({ colorMode: 'default' });
        setColorMode('default');
      }
    }

    localStorage.setItem(APPEARANCE_PREFERENCE, id);
  };

  const items = [
    {
      id: MODE_LIGHT,
      name: MODE_LIGHT,
      icon: SunIcon,
      isSelected: activeItem === MODE_LIGHT,
    },
    {
      id: MODE_DARK,
      name: MODE_DARK,
      icon: MoonIcon,
      isSelected: activeItem === MODE_DARK,
    },
    {
      id: MODE_SYSTEM_DEFAULT,
      name: MODE_SYSTEM_DEFAULT,
      icon: ComputerIcon,
      isSelected: activeItem === MODE_SYSTEM_DEFAULT,
    },
  ];

  return (
    <React.Fragment>
      <Heading variant="h2" as="h2" sx={sx.heading}>
        Appearance
      </Heading>

      <SelectableList
        items={items}
        onClick={itemId => savePreference(itemId)}
      />
    </React.Fragment>
  );
}

const sx = {
  heading: {
    marginTop: 'xl',
    marginBottom: 'm',
    paddingLeft: 'xxs',
  },
};
