import { hexToRGB } from '../utils';
import { colors } from './colors';

// Light colors
const blackberry07 = hexToRGB(colors.blackberry, 0.07);
const blackberry25 = hexToRGB(colors.blackberry, 0.25);
const blueberry05 = hexToRGB(colors.blueberry[100], 0.05);
const blueberry10 = hexToRGB(colors.blueberry[100], 0.1);
const blueberry80 = hexToRGB(colors.blueberry[100], 0.8);
const blackberry20 = hexToRGB(colors.blackberry, 0.2);
const candy10 = hexToRGB(colors.candy, 0.1);

// Dark colors
const blueberryDark05 = hexToRGB(colors.modes.dark.blueberry[100], 0.05);
const blueberryDark10 = hexToRGB(colors.modes.dark.blueberry[100], 0.1);
const blackberryDark07 = hexToRGB(colors.modes.dark.blackberry, 0.07);
const blackberryDark25 = hexToRGB(colors.modes.dark.blackberry, 0.25);

export const shadows = {
  border: {
    top: {
      light: `0 -1px 0 0 ${blueberry10}`,
      dark: `0 -1px 0 0 ${blueberryDark10}`,
    },
    avatar: {
      light: `inset 0 0 0 1px ${blueberry05}`,
      dark: `inset 0 0 0 1px ${blueberryDark05}`,
    },
  },
  menu: {
    light: `0 -1px 0 0 ${blueberry10}`,
    dark: `0 -1px 0 0 ${blackberry20}`,
  },
  activityDot: {
    light: `0 0 0 2px ${colors.milk}`,
    dark: `0 0 0 2px ${colors.modes.dark.milk}`,
    blueberry: {
      light: `0 0 0 2px ${colors.blueberry[5]}`,
      dark: `0 0 0 2px ${colors.modes.dark.blueberry[5]}`,
    },
    raised: {
      light:
        '0px 6px 8px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.15)',
      dark:
        '0px 6px 8px rgba(0, 0, 0, 0.08), 0px 1px 1.5px rgba(0, 0, 0, 0.15)',
    },
  },
  book: {
    default:
      '0px 1.1px 1.5px rgba(0, 0, 0, 0.15), 0px 2.8px 3.9px rgba(0, 0, 0, 0.1), 0px 5.8px 7.9px rgba(0, 0, 0, 0.08), 0px 12.0455px 16.4px rgba(0, 0, 0, 0.06), 0px 33px 45px rgba(0, 0, 0, 0.04)',
  },
  input: {
    default: `0 0 0 4px ${blueberry10}`,
    error: `0 0 0 4px ${candy10}`,
  },
  button: {
    ghost: {
      light:
        'inset 0 0 0 1px rgba(19, 19, 20, 0.22), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
      lightHover:
        'inset 0 0 0 1px rgba(19, 19, 20, 0.45), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
      dark:
        'inset 0 0 0 1px rgba(224, 225, 228, 0.22), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
      darkHover:
        'inset 0 0 0 1px rgba(224, 225, 228, 0.45), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
    },
    kiwi: {
      light: `inset 0 0 0 1px ${colors.kiwi[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
      dark: `inset 0 0 0 1px ${colors.modes.dark.kiwi[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
    },
    banana: {
      light: `inset 0 0 0 1px ${colors.banana[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
      dark: `inset 0 0 0 1px ${colors.modes.dark.banana[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
    },
    dragonfruit: {
      light: `inset 0 0 0 1px ${colors.dragonfruit[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
      dark: `inset 0 0 0 1px ${colors.modes.dark.dragonfruit[100]}, 0px 4px 4px -5px rgba(0, 0, 0, 0.25)`,
    },
  },
  collection: {
    selected: `inset 0 0 0 1px ${blueberry80}`,
    selectedDark: `inset 0 0 0 1px ${colors.modes.dark.blueberry[80]}`,
  },
  box: '0px 7.2px 6.4px rgba(0, 0, 0, 0.03)',
  blog: {
    section: `0px 0px 0px 1px ${blueberry10}, 0px 8px 17px ${blackberry07}, 0px 4px 4px -5px ${blackberry25}`,
    sectionDark: `0px 0px 0px 1px ${blueberryDark10}, 0px 8px 17px ${blackberryDark07}, 0px 4px 4px -5px ${blackberryDark25}`,
  },
  highlight: {
    light:
      '0px 36px 69px rgba(19, 19, 20, 0.04), 0px 8px 15px rgba(19, 19, 20, 0.02), 0px 2px 4px rgba(19, 19, 20, 0.01)',
    hover:
      '0px 40px 69px rgba(19, 19, 20, 0.06), 0px 10px 15px rgba(19, 19, 20, 0.04), 0px 4px 4px rgba(19, 19, 20, 0.02)',
  },
};
