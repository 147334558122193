/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
/* eslint-disable-next-line */
import { keyframes } from '@emotion/react';
import mixpanel from 'mixpanel-browser';

import Button from './Button';
import Heading from './Heading';
import Image from './Image';
import Text from './Text';

import { CloseIcon } from '../icons';
import { icon } from '../assets';

export default function AddToHomescreen({ isAndroid }) {
  const [hidden, setHidden] = useState(false);
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const preference = localStorage.getItem('READNG_SEEN_ADD_HOMESCREEN');

  if (hidden || preference !== null) {
    return null;
  }

  const closeDialog = () => {
    localStorage.setItem('READNG_SEEN_ADD_HOMESCREEN', 'true');
    setHidden(true);
  };
  const install = async () => {
    if (!isAndroid || window.beforeinstall === undefined) {
      // not installable yet
      return;
    }
    window.beforeinstall.prompt();

    const { outcome } = await window.beforeinstall.userChoice;
    delete window.beforeinstall;

    const success = outcome === 'accepted';
    if (success) {
      closeDialog();
      window.plausible('install-pwa', {
        props: {
          isiOs: !isAndroid,
        },
      });
      mixpanel.track('Install PWA', {
        ios: !isAndroid,
      });
    }
  };

  const sx = getStyles(isDark);
  return (
    <React.Fragment>
      <div sx={sx.overlay} />
      <div sx={sx.box}>
        <Heading sx={sx.heading}>Install Oku</Heading>
        <Text sx={sx.copy}>
          {isAndroid
            ? "Take Oku with you by adding it to your device's homescreen."
            : 'To install Oku on your iPhone, tap the share icon in Safari and then add to homescreen.'}
        </Text>
        <Text sx={sx.arrow}>↓</Text>
        {isAndroid && <Button onClick={install}>Tap to Install</Button>}
        <div sx={sx.icon}>
          <Image
            width={80}
            height={80}
            src={icon}
            src2x={icon}
            alt="Oku icon"
          />
        </div>
      </div>
      <button sx={sx.close} type="button" onClick={closeDialog}>
        <CloseIcon />
      </button>
    </React.Fragment>
  );
}

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const getStyles = isDark => ({
  overlay: {
    zIndex: 'overlay',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: 'rgba(0, 0, 0, 0.6)',
    opacity: 0.8,
  },
  close: {
    zIndex: 'overlay',
    position: 'fixed',
    top: 20,
    right: 20,
    color: isDark ? 'milk' : 'blueberry.100',
    bg: isDark ? 'blackberry' : 'milk',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'circle',
    padding: 0,
  },
  heading: {
    marginBottom: 'xs',
    fontSize: 'l',
    fontWeight: 'heading',
    lineHeight: 'heading',
    color: 'blackberry',
  },
  copy: {
    fontSize: 's',
    fontWeight: 'body',
    lineHeight: 'body',
    color: 'blueberry.80',
  },
  arrow: {
    marginBottom: 'xs',
    fontSize: 18,
    fontWeight: 'heading',
    lineHeight: 'heading',
    marginTop: 'xs',
    animation: `${bounce} 1.8s infinite`,
  },
  box: {
    bg: isDark ? 'blueberry.5' : 'milk',
    boxShadow:
      '0px -1.22278px 3.12487px rgba(0, 0, 0, 0.0418093), 0px -3.38082px 8.63987px rgba(0, 0, 0, 0.06), 0px -8.13971px 20.8015px rgba(0, 0, 0, 0.0781907), 0px -27px 69px rgba(0, 0, 0, 0.12)',
    borderRadius: '18px 18px 0px 0px',

    paddingX: 'l',
    paddingBottom: 's',
    paddingTop: 'xxl',
    textAlign: 'center',

    zIndex: 'overlay',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  icon: {
    zIndex: 'overlay',
    position: 'absolute',
    overflow: 'hidden',
    width: 80,
    height: 80,

    top: -40,
    left: '50%',
    marginLeft: -40,
    borderRadius: 19,

    boxShadow:
      '0px 8px 20px rgba(0, 0, 0, 0.11), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
});
