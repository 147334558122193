/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import DropzoneFile from '../../components/DropzoneFile';
import FormError from '../../components/FormError';
import GhostButton from '../../components/GhostButton';
import Heading from '../../components/Heading';
import InputWithLabel from '../../components/InputWithLabel';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';
import PrimaryButton from '../../components/PrimaryButton';
import TextareaWithLabel from '../../components/TextareaWithLabel';

import { SIGN_OUT_ROUTE } from '../../constants';
import { isNativeIos, messageNative, onReload } from '../../utils';

import {
  getAvatar,
  getBio,
  getFirstName,
  getLastName,
  getToken,
  getUpdateUserFormLoading,
  getUsername,
  getEmail,
  getFormState,
} from '../../selectors';

import {
  shouldUpdateUser,
  shouldUpdateAvatar,
  shouldRemoveAvatar,
} from '../../ducks/user';

import Appearance from './Appearance';
import Integrations from './Integrations';
import Privacy from './Privacy';
import Subscription from './Subscription';

import ConfirmDenyModal from '../../modals/ConfirmDeny';
import ModalDialog from '../../components/ModalDialog';
import { shouldDeleteAccount } from '../../ducks/auth';

export default function Settings() {
  const dispatch = useDispatch();
  const userName = useSelector(getUsername);
  const email = useSelector(getEmail);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const bio = useSelector(getBio);
  const token = useSelector(getToken);
  const avatar = useSelector(getAvatar);
  const isNative = isNativeIos();

  // must be a better way to check this?
  const defaultAvatarUrl =
    'https://readng.ams3.digitaloceanspaces.com/avatars/dogAvatar.png';
  const isDefaultAvatar = avatar === defaultAvatarUrl;

  const formLoading = useSelector(getUpdateUserFormLoading);
  const formState = useSelector(getFormState('settings'));
  const formError = formState.errors._global;
  const userNameError = formState.errors.username;
  const emailError = formState.errors.email;
  const firstNameError = formState.errors.firstname;
  const lastNameError = formState.errors.lastname;
  const bioError = formState.errors.bio;

  const [emailValue, setEmailValue] = useState(email);
  const onEmailChange = event => setEmailValue(event.target.value);

  const [userNameValue, setUserNameValue] = useState(userName);
  const onUserNameChange = event => setUserNameValue(event.target.value);

  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const onFirstNameChange = event => setFirstNameValue(event.target.value);

  const [lastNameValue, setLastNameValue] = useState(lastName);
  const onLastNameChange = event => setLastNameValue(event.target.value);

  const [bioValue, setBioValue] = useState(bio);
  const onBioChange = event => setBioValue(event.target.value);

  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = e => {
    e?.preventDefault();
    setModalVisible(false);
  };
  const openModal = e => {
    e?.preventDefault();
    setModalVisible(true);
  };
  const confirmDelete = e => {
    e?.preventDefault();
    setModalVisible(false);
    shouldDeleteAccount({ token, dispatch, confirm: true }).catch(err => {
      console.error('Delete account:', err);
      toast('We hit an error - please email us if you have more trouble', {
        autoClose: 7500,
      });
    });
  };

  const onSubmit = event => {
    event.preventDefault();
    shouldUpdateUser({
      dispatch,
      userName: userNameValue,
      email: emailValue,
      firstName: firstNameValue,
      lastName: lastNameValue,
      bio: bioValue,
      token,
    });
  };

  const restorePurchases = () => {
    messageNative({ restorePurchases: true });
  };

  const clearImage = () => {
    shouldRemoveAvatar({ dispatch, token }).catch(err => {
      // eslint-disable-next-line
      console.error(err);
      toast("Oops! That didn't work – please try again.", { autoClose: 4500 });
    });
  };

  const onFileDrop = file =>
    shouldUpdateAvatar({
      dispatch,
      token,
      file,
    });

  return (
    <Page title="Settings">
      <PageNavigation showBackButton />
      <PageBody maxWidth="maxWidth.phablet">
        <Heading variant="h1" as="h1" fontFamily="serif" sx={sx.heading}>
          Settings
        </Heading>
        <Subscription />
        <div sx={sx.input}>
          <DropzoneFile
            onFileDrop={onFileDrop}
            onRemoveFile={clearImage}
            shouldRemoveImage={!isDefaultAvatar}
            avatarProps={{
              name: `${firstName} ${lastName}`,
              src: avatar,
              src2x: avatar,
            }}
            helperText={
              isNative
                ? 'Take a selfie or upload an image from your files. Square images work best!'
                : 'Drag and drop an image or select from your files. Your avatar should be about 500px wide and under 1MB'
            }
          />
        </div>
        <form onSubmit={onSubmit}>
          <FormError>{formError}</FormError>
          <InputWithLabel
            id="userName"
            label="Username"
            onChange={onUserNameChange}
            placeholder="johndoe"
            sx={sx.input}
            type="text"
            value={userNameValue}
            error={userNameError}
          />
          <InputWithLabel
            id="email"
            label="Email address"
            onChange={onEmailChange}
            placeholder={`${userName}@oku.club`}
            sx={sx.input}
            type="text"
            value={emailValue}
            error={emailError}
            inputMode="email"
          />
          <InputWithLabel
            id="firstName"
            label="First name"
            onChange={onFirstNameChange}
            placeholder="John"
            sx={sx.input}
            type="text"
            error={firstNameError}
            value={firstNameValue}
          />
          <InputWithLabel
            error={lastNameError}
            id="lastName"
            label="Last name"
            onChange={onLastNameChange}
            placeholder="Doe"
            sx={sx.input}
            type="text"
            value={lastNameValue}
          />
          <TextareaWithLabel
            error={bioError}
            id="bio"
            label="Bio"
            onChange={onBioChange}
            placeholder="I love reading books..."
            sx={sx.input}
            type="text"
            value={bioValue}
          />
          <PrimaryButton type="submit" isLoading={formLoading}>
            Save
          </PrimaryButton>

          <Privacy />
          <Integrations />
          <Appearance />
          <div sx={sx.footer}>
            {isNative && (
              <GhostButton onClick={restorePurchases} sx={sx.footerButton}>
                Restore Purchases
              </GhostButton>
            )}
            <GhostButton
              as={RouterLink}
              to={SIGN_OUT_ROUTE}
              sx={sx.footerButton}
            >
              Signout
            </GhostButton>
            {!isNative && (
              <GhostButton onClick={onReload} sx={sx.footerButton}>
                Reload app{/* process.env.REACT_APP_READNG_VERSION */}
              </GhostButton>
            )}
            <Button
              variant="strawberry.default"
              onClick={openModal}
              sx={sx.footerButton}
            >
              Delete account
            </Button>
          </div>
          <ModalDialog isVisible={modalVisible} onClose={closeModal}>
            <ConfirmDenyModal
              heading="Confirm your account deletion"
              subtext="Oku won't be able to recover your data."
              confirmText="Delete Oku account"
              confirmVariant="strawberry.default"
              onDeny={closeModal}
              onConfirm={confirmDelete}
            />
          </ModalDialog>
        </form>
      </PageBody>
    </Page>
  );
}

const sx = {
  heading: {
    textAlign: 'center',
    marginBottom: 'l',
  },
  input: {
    marginBottom: 'm',
  },
  footer: {
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    marginY: 'xl',
  },
  footerButton: {
    flex: 1,
    '&:not(:last-child)': {
      marginRight: [0, 0, 's'],
      marginBottom: ['s', 's', 0],
    },
  },
};
