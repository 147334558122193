import React from 'react';

import Svg from '../components/Svg';

export default function RainbowBigIcon(props) {
  return (
    <Svg fill="none" height="28" viewBox="0 0 28 28" width="28" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="m19.25 20v-1.75c0-1.3924-.5531-2.7277-1.5377-3.7123s-2.3199-1.5377-3.7123-1.5377-2.7277.5531-3.7123 1.5377c-.98458.9846-1.5377 2.3199-1.5377 3.7123v1.75" />
        <path d="m22.75 20v-1.75c0-2.3206-.9219-4.5462-2.5628-6.1872-1.641-1.6409-3.8666-2.5628-6.1872-2.5628s-4.54624.9219-6.18718 2.5628c-1.64095 1.641-2.56282 3.8666-2.56282 6.1872v1.75" />
        <path d="m26.25 20v-1.75c0-3.2489-1.2906-6.3647-3.5879-8.66206-2.2974-2.29732-5.4132-3.58794-8.6621-3.58794s-6.36474 1.29062-8.66206 3.58794c-2.29732 2.29736-3.58794 5.41316-3.58794 8.66206v1.75" />
      </g>
    </Svg>
  );
}
