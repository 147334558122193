/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';

import PageHeaderMenu from './PageHeaderMenu';
import ModalDialog from './ModalDialog';
import SignUpModal from '../modals/SignUp';

import { getIsAuthenticated } from '../selectors';

export default function PageHeader({ withSpace, showHeader }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  const openSignUp = () => {
    setIsSignUpOpen(true);
  };

  if (isAuthenticated && !showHeader) {
    return null;
  }

  return (
    <React.Fragment>
      <PageHeaderMenu onJoinClick={openSignUp} withSpace={withSpace} />
      <ModalDialog isVisible={isSignUpOpen} onClose={closeSignUp}>
        <SignUpModal />
      </ModalDialog>
    </React.Fragment>
  );
}
