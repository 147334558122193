// Actions
export const APPEND_ITEMS = 'threads.append';
export const PREPEND_ITEMS = 'threads.prepend';
export const SET_ITEMS = 'threads.set';
export const REMOVE_ITEM = 'threads.remove_item';

export const threadReducer = (
  state = {
    nextLink: null,
    items: [],
  },
  action
) => {
  switch (action?.type) {
    case PREPEND_ITEMS:
      return {
        ...state,
        id: action.threadId,
        items: [...action.items, ...state.items],
      };
    case APPEND_ITEMS:
      return {
        ...state,
        nextLink: action.nextLink,
        id: action.threadId,
        items: [...state.items, ...action.items],
      };
    case SET_ITEMS:
      return {
        ...state,
        nextLink: action.nextLink,
        id: action.threadId,
        items: [...action.items],
      };
    case REMOVE_ITEM:
      let compareFunc = action.comparisonFunction;
      if (!compareFunc) {
        compareFunc = (_action, item) => {
          return item !== _action.item;
        };
      }
      return {
        ...state,
        items: state.items.filter(compareFunc.bind(null, action)),
      };
    default:
      return state;
  }
};

// Reducer
const threadsReducer = (state = {}, action) => {
  const threadId = action.threadId;
  const existingThread = state[threadId];

  switch (action?.type) {
    case PREPEND_ITEMS:
      if (!existingThread && !action.createIfNotExists) {
        return state;
      }
      break;
    case APPEND_ITEMS:
    case REMOVE_ITEM:
    case SET_ITEMS:
      break;
    default:
      return state;
  }

  const thread = existingThread || threadReducer();

  // todo: clearing thread
  return {
    ...state,
    [threadId]: threadReducer(thread, action),
  };
};

// Action creators
export const prependItems = ({
  threadId,
  items,
  createIfNotExists = true,
}) => ({
  type: PREPEND_ITEMS,
  threadId,
  items,
  createIfNotExists,
});

export const appendItems = (threadId, items, nextLink) => ({
  type: APPEND_ITEMS,
  threadId,
  items,
  nextLink,
});

export const setItems = (threadId, items, nextLink) => ({
  type: SET_ITEMS,
  threadId,
  items,
  nextLink,
});

export const removeItem = (threadId, item, comparisonFunction) => ({
  type: REMOVE_ITEM,
  threadId,
  item,
  comparisonFunction,
});

export default threadsReducer;
