/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BookCover from './BookCover';
import Card from './Card';
import Heading from './Heading';
import Highlighter from './Highlighter';
import Text from './Text';

import { COVER_WIDTH, COVER_WIDTH_MOBILE, COVER_HEIGHT } from '../constants';

export default function CardSelectable({
  authors,
  isSelected,
  onClick,
  searchWords,
  subtitle,
  thumbnail,
  title,
}) {
  return (
    <Card isActive={isSelected} onClick={onClick}>
      <div sx={sx.inner}>
        <div>
          <div sx={sx.book}>
            <BookCover
              thumbnail={thumbnail}
              thumbnail2x={thumbnail}
              title={title}
              width="60%"
            />
          </div>
        </div>
        <div sx={sx.meta}>
          {title && (
            <Heading as="h2" sx={sx.heading} truncated>
              {searchWords ? (
                <Highlighter
                  highlightClassName="highlighter-color"
                  autoEscape={false}
                  searchWords={searchWords}
                  textToHighlight={title}
                />
              ) : (
                title
              )}
            </Heading>
          )}
          {subtitle && (
            <Text as="h3" sx={sx.subheading} truncated>
              {searchWords ? (
                <Highlighter
                  highlightClassName="highlighter-color"
                  autoEscape={false}
                  searchWords={searchWords}
                  textToHighlight={subtitle}
                />
              ) : (
                title
              )}
            </Text>
          )}
          {authors && (
            <Text as="p" sx={sx.authors} truncated>
              {authors}
            </Text>
          )}
        </div>
      </div>
    </Card>
  );
}

const sx = {
  inner: {
    display: 'flex',
    alignItems: 'center',
  },
  book: {
    width: [
      COVER_WIDTH_MOBILE,
      COVER_WIDTH_MOBILE,
      COVER_WIDTH_MOBILE,
      COVER_WIDTH,
    ],
    height: COVER_HEIGHT,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  meta: {
    paddingY: 'm',
    paddingRight: 'm',
    maxWidth: [
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH}px)`,
    ],
  },
  heading: {
    color: 'olive',
    display: 'block',
    fontSize: 's',
    fontWeight: 'heading',
    lineHeight: 'heading',
    marginBottom: 'xs',
  },
  subheading: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xs',
    fontWeight: 'heading',
    lineHeight: 'heading',
    marginBottom: 10,
  },
  authors: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xxs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
};
