import React from 'react';

import Svg from '../components/Svg';

export default function PlusIcon(props) {
  return (
    <Svg fill="none" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.7"
      >
        <path d="m14 9v10" />
        <path d="m19 14h-10" />
      </g>
    </Svg>
  );
}
