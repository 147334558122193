import React from 'react';

import Svg from '../components/Svg';

export default function GoodreadsIcon({ ...props }) {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="m7.36704 16.029h.07745c.35145 0 .70562 0 1.05698.0029.04431 0 .08579-.011.09965.0574.19644.9544.83008 1.4931 1.74048 1.7475.7443.2078 1.4969.2132 2.2495.0492.9352-.2024 1.5495-.774 1.8816-1.6518.2213-.588.296-1.1977.3043-1.8186.0028-.1586.0084-1.2853-.0055-1.4439l-.0249-.0082c-.0221.0411-.0471.0793-.0692.1203-.6115 1.1788-1.6962 1.8432-2.9164 1.8898-2.85.1093-4.68725-1.5589-4.75917-4.8186-.01384-.64815.04981-1.28259.22965-1.90611.56997-1.94437 2.06414-3.23243 4.25842-3.24881 1.6961-.01095 2.8084 1.05832 3.2152 1.92249.0138.03003.0359.06287.0664.05195v-1.68454h1.2258c0 7.66532.0027 9.08462.0027 9.08462-.0027 2.1467-.7387 3.9299-2.85 4.4356-1.923.4623-4.39947.1314-5.42322-1.5641-.22138-.3693-.32654-.774-.35974-1.2171zm4.04806-10.03074c-1.45266-.0137-3.00217 1.11301-3.18204 3.65898-.11344 1.61346.4095 3.34176 1.97834 4.06386.7638.3527 2.0559.4101 2.9967-.238 1.3171-.9079 1.7349-2.6527 1.5163-4.21145-.2684-1.94436-1.3226-3.28161-3.3093-3.27339z" />
    </Svg>
  );
}
