/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import ListLandscape from '../../components/ListLandscape';
import Loader from '../../components/Loader';
import SearchEmptyState from './SearchEmptyState';
import SearchLoader from './SearchLoader';
import SwiperFriends from './SwiperFriends';
import SwiperRecommended from './SwiperRecommended';
import SwiperReviews from './SwiperReviews';
import SwiperCollections from './SwiperCollections';
import PrimaryButton from '../../components/PrimaryButton';
import GhostButton from '../../components/GhostButton';

import AddBookButton from './AddBookButton';

import { fetchDashboard } from '../../ducks/user';
import { fetchExplore } from '../../ducks/explore';

import {
  exploreFeaturedColsThreadId,
  exploreRecentColsThreadId,
  exploreRecsThreadId,
  exploreReviewsThreadId,
  getFriendsReading,
  getSearchAuthorHints,
  getThreadById,
  getToken,
} from '../../selectors';

export default function SearchBody({
  authorFilter,
  bookIds,
  books,
  hasSearched,
  isInputEmpty,
  isLoading,
  setAuthorFilter,
  setExploreLoading,
  setFriendsLoading,
}) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const friends = useSelector(getFriendsReading);
  const reviews = useSelector(getThreadById(exploreReviewsThreadId));
  const recommended = useSelector(getThreadById(exploreRecsThreadId));
  const recentCollections = useSelector(
    getThreadById(exploreRecentColsThreadId)
  );
  const featuredCollections = useSelector(
    getThreadById(exploreFeaturedColsThreadId)
  );
  const noResults = bookIds.length === 0;
  const authorHints = useSelector(getSearchAuthorHints);

  useEffect(() => {
    if (reviews && recommended) {
      setExploreLoading(false);
      setFriendsLoading(false);
    } else {
      fetchDashboard({ dispatch, token }).then(() => setFriendsLoading(false));
      fetchExplore({ dispatch, token }).then(() => setExploreLoading(false));
    }
  }, []);

  useEffect(() => {
    mixpanel.track('View Explore');
  }, []);

  // Show loading state
  // TODO: Create card skeleton to avoid flicker
  if (noResults && isLoading) {
    return (
      <div sx={sx.loaderContainer}>
        <AuthorsHints
          authorHints={authorHints}
          authorFilter={authorFilter}
          setAuthorFilter={setAuthorFilter}
        />
        <div sx={sx.loader}>
          <Loader />
        </div>
      </div>
    );
  }

  // Show an empty state for when there are no results
  if (noResults && !isInputEmpty && hasSearched) {
    return (
      <SearchEmptyState
        heading="Oh no!"
        subheading="Nothing really matches your search :'("
      />
    );
  }

  // Show explore if there are no results and user hasn't searched
  if (noResults && !isLoading) {
    return (
      <div sx={sx.explore}>
        <SwiperFriends paddingBottom={40 + 16} friends={friends} />
        <SwiperRecommended books={recommended?.items} />
        <SwiperCollections collections={recentCollections?.items} />
        {!!featuredCollections?.items?.length && (
          <SwiperCollections
            collections={featuredCollections?.items}
            responsiveHeading={['Featured collections']}
          />
        )}
        <SwiperReviews reviews={reviews?.items} />
      </div>
    );
  }

  return (
    <div sx={sx.container}>
      <AuthorsHints
        authorHints={authorHints}
        authorFilter={authorFilter}
        setAuthorFilter={setAuthorFilter}
      />
      <div sx={sx.addBookButton}>
        <AddBookButton />
      </div>
      <ListLandscape books={bookIds.map(id => books[id])} />
      {isLoading && <SearchLoader />}
    </div>
  );
}

function AuthorsHints({ authorHints, authorFilter, setAuthorFilter }) {
  if (authorHints && authorHints.length > 0) {
    return (
      <div sx={sx.sideScroll}>
        <div sx={sx.buttons}>
          {authorHints.map(name => {
            if (authorFilter === name) {
              return (
                <PrimaryButton
                  key={`disable-${name}`}
                  onClick={() => setAuthorFilter(null)}
                  sx={sx.button}
                >
                  {authorFilter}
                </PrimaryButton>
              );
            }
            return (
              <GhostButton
                key={`filter-${name}`}
                onClick={() => setAuthorFilter(name)}
                sx={sx.button}
              >
                {name}
              </GhostButton>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}

const sx = {
  container: {
    paddingBottom: ['s', 's', 'm'],
  },
  explore: {
    marginTop: ['xs', 'xs', 0],
  },
  sideScroll: {
    width: '100%',
    overflow: 'auto',
    marginBottom: ['s', 's', 'm'],
    paddingY: '1px',
  },
  buttons: {
    gap: 'xs',
    minWidth: '100%',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  button: {
    borderRadius: 'round',
  },
  loaderContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addBookButton: {
    display: ['block', 'block', 'block', 'block', 'none'],
    marginBottom: 's',
  },
};
