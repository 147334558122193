import React from 'react';

import Svg from '../../components/Svg';

export default function SearchIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m6 12.875c0-3.79696 3.07804-6.875 6.875-6.875 3.797 0 6.875 3.07804 6.875 6.875 0 1.8899-.7626 3.6017-1.9968 4.8445-.0058.0055-.0115.0111-.0172.0168-.0057.0056-.0112.0113-.0167.0171-1.2428 1.2341-2.9545 1.9966-4.8443 1.9966-3.79696 0-6.875-3.078-6.875-6.875zm12.4036 6.9431c-1.5166 1.2092-3.4382 1.9319-5.5286 1.9319-4.90153 0-8.875-3.9735-8.875-8.875 0-4.90153 3.97347-8.875 8.875-8.875 4.9015 0 8.875 3.97347 8.875 8.875 0 2.0905-.7228 4.0123-1.9322 5.5289l3.8887 3.8887c.3906.3905.3906 1.0236 0 1.4142-.3905.3905-1.0237.3905-1.4142 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
