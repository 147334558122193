import React from 'react';
import { useSelector } from 'react-redux';

import Dashboard from './Dashboard';
import EmptyState from './EmptyState';
import Loading from '../Loading';

import {
  getBookSlug,
  getFirstName,
  getListBooks,
  getListLength,
  getReadingList,
  getReadList,
  getToReadList,
  getUsername,
  getUserStale,
} from '../../selectors';

import { getBookRoute, getReadingRoute, getToReadRoute } from '../../constants';
import { getPersonalisedGreeting, pluralise } from '../../utils';

export default function Home() {
  const username = useSelector(getUsername);
  const staleUser = useSelector(getUserStale);
  const firstName = useSelector(getFirstName);
  const greeting = getPersonalisedGreeting(firstName);

  const toReadList = useSelector(getToReadList);
  const toReadCount = getListLength(toReadList);
  const booksToRead = getListBooks(toReadList);
  const toReadRoute = getToReadRoute(username);

  const readingList = useSelector(getReadingList);
  const readingCount = getListLength(readingList);
  const booksReading = getListBooks(readingList);
  const readingRoute = getReadingRoute(username);
  const bookRoute =
    booksReading.length && getBookRoute(getBookSlug(booksReading[0]));

  const readList = useSelector(getReadList);
  const readCount = getListLength(readList);

  const readingHeading =
    readingCount === 0
      ? `You're not reading anything right now. Enjoy your break!`
      : `You're reading ${pluralise({
          count: readingCount,
          word: 'book',
        })} right now`;

  const subheading = readingHeading;

  if (!username || staleUser) {
    return <Loading />;
  }

  if (readCount === 0 && readingCount === 0 && toReadCount === 0) {
    return <EmptyState />;
  }

  return (
    <Dashboard
      bookRoute={bookRoute}
      booksReading={booksReading}
      booksToRead={booksToRead}
      greeting={greeting}
      readingCount={readingCount}
      readingRoute={readingRoute}
      subheading={subheading}
      toReadCount={toReadCount}
      toReadRoute={toReadRoute}
    />
  );
}
