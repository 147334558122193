/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Link from '../../components/Link';

export default function ShowMoreLink({ onClick, isConcealed }) {
  return (
    <Link onClick={onClick} variant="body" hasUnderline sx={sx.showMore}>
      {isConcealed ? '↓ Show more' : '↑ Show less'}
    </Link>
  );
}

const sx = {
  showMore: {
    fontSize: 'xs',
    color: 'blueberry.80',
    marginY: 'xs',
    marginLeft: 'xs',
    lineHeight: 'sub',
  },
};
