/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';

import GhostButton from '../components/GhostButton';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import PrimaryButton from '../components/PrimaryButton';
import SelectableList from '../components/SelectableList';

import { LANGUAGES, LANGUAGE_PREF } from '../constants';

export default function LanguageSelector({ closeModal, updateLang }) {
  const initialState = localStorage.getItem(LANGUAGE_PREF);
  const [selectedLang, setSelectedLang] = useState(initialState);
  const items = Object.keys(LANGUAGES).map(code => {
    return {
      id: code,
      name: LANGUAGES[code],
      isSelected: selectedLang === code,
    };
  });

  const onSelect = code => {
    setSelectedLang(code);
  };

  const clearPreference = () => {
    localStorage.removeItem(LANGUAGE_PREF);
    updateLang(null);
    closeModal();
  };

  const savePreference = () => {
    localStorage.setItem(LANGUAGE_PREF, selectedLang);
    updateLang(selectedLang);
    closeModal();
  };

  return (
    <ModalBody>
      <ModalHeading>Search by language</ModalHeading>
      <ModalContent extraStyles={sx.content}>
        <SelectableList items={items} onClick={onSelect} />
      </ModalContent>
      <ModalFooter>
        <GhostButton onClick={clearPreference}>Clear</GhostButton>
        <PrimaryButton onClick={savePreference}>Search</PrimaryButton>
      </ModalFooter>
    </ModalBody>
  );
}

const sx = {
  content: {
    '> div': {
      height: '100%',
      overflow: 'auto',
      alignItems: 'flex-start',
    },
  },
};
