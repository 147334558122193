/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import Avatar from './Avatar';
import BookCover from './BookCover';
import GhostButton from './GhostButton';
import PrimaryButton from './PrimaryButton';
import Text from './Text';

import { hasTouch } from '../utils';

export default function DropzoneFile({
  avatarProps,
  bookProps,
  helperText,
  onFileDrop,
  onRemoveFile,
  shouldRemoveImage,
}) {
  const [imageLoading, setImageLoading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setImageLoading(true);
    acceptedFiles.forEach(file => {
      onFileDrop(file).then(() => setImageLoading(false));
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/jpg,image/png',
  });

  const clearImage = evt => {
    // Stop propagation to prevent dropzone from opening the filepicker
    evt.stopPropagation();
    onRemoveFile();
  };

  const preventSubmit = evt => evt.preventDefault();

  const rootStyles = {
    ...sx.root,
    bg: isDragActive ? 'blueberry.10' : 'blueberry.5',
    transform: isDragActive
      ? 'translate3d(0, -4px, 0) scale3d(1.04, 1.04, 1.04)'
      : 'translate3d(0, 0, 0) scale3d(1, 1, 1)',
  };

  return (
    <div {...getRootProps()} sx={rootStyles}>
      {(avatarProps || bookProps) && (
        <div sx={sx.image}>
          {avatarProps && <Avatar {...avatarProps} size={48} />}
          {bookProps && (
            <BookCover isRaised visibleByDefault width={86} {...bookProps} />
          )}
        </div>
      )}
      <div>
        <input {...getInputProps()} />
        <Text variant="body" sx={sx.helperText}>
          {helperText}
        </Text>
        <div sx={sx.buttons}>
          <PrimaryButton
            type="button"
            onClick={preventSubmit}
            isLoading={imageLoading}
          >
            Upload image
          </PrimaryButton>
          {shouldRemoveImage && (
            <GhostButton disabled={imageLoading} onClick={clearImage}>
              Remove image
            </GhostButton>
          )}
        </div>
      </div>
    </div>
  );
}

const sx = {
  heading: {
    textAlign: 'center',
    marginBottom: 'l',
  },
  input: {
    marginBottom: 'm',
  },
  root: {
    outline: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: ['column', 'column', 'row', 'row'],
    padding: 'l',
    borderRadius: 'button',
    transition: 'smooth.fast',
    cursor: 'pointer',

    '&:hover': !hasTouch() && {
      bg: 'blueberry.10',
    },
  },
  image: {
    marginRight: [0, 0, 'm', 'm'],
    marginBottom: ['s', 's', 0, 0],
    flexShrink: 0,
  },
  helperText: {
    display: 'block',
    marginBottom: 's',
  },
  buttons: {
    gap: 's',
    display: 'flex',
    marginTop: ['s', 's', 0],
    flexDirection: ['column', 'column', 'row'],
  },
  footer: {
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    marginY: 'xl',
  },
  footerButton: {
    flex: 1,
    '&:not(:last-child)': {
      marginRight: [0, 0, 's'],
      marginBottom: ['s', 's', 0],
    },
  },
  book: {
    paddingRight: 's',
  },
};
