/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import AvatarList from '../../components/AvatarList';
import Heading from '../../components/Heading';
import Rating from '../../components/Rating';
import Text from '../../components/Text';

import Section from './Section';
import BookTags from './BookTags';
import { pluralise } from '../../utils';

export default function BookRating({
  percentage,
  readngRating,
  recommendedBy,
  thirdPartyRatings,
  tags,
}) {
  return (
    <Section title="Rating">
      <div sx={sx.block}>
        <header sx={sx.ratingsWrap}>
          <RatingHeading percentage={percentage} />
          {readngRating === undefined ? (
            <Text variant="subtitle">
              Be one of the first to rate this book on Oku
            </Text>
          ) : (
            <AvatarList
              borderColor="milk"
              people={recommendedBy}
              text={`Based on ${pluralise({
                count: readngRating.count,
                word: 'community rating',
              })}`}
            />
          )}
          {tags.length > 0 && (
            <div sx={sx.tags}>
              <BookTags tags={tags} />
            </div>
          )}
        </header>
        {thirdPartyRatings.length > 0 && (
          <ul sx={sx.ratings}>
            {thirdPartyRatings.map(rating => (
              <Rating
                bg="blueberry.5"
                color="blueberry.80"
                key={rating.source}
                rating={rating}
                size={32}
                source={rating.source}
                withText
              />
            ))}
          </ul>
        )}
      </div>
    </Section>
  );
}

function RatingHeading({ percentage }) {
  if (percentage === undefined) {
    return (
      <Heading marginBottom="xs" variant="h2">
        More ratings needed
      </Heading>
    );
  }
  if (percentage === 0) {
    return (
      <Heading marginBottom="xs" variant="h2">
        Nobody has recommended this
      </Heading>
    );
  }
  return (
    <Heading marginBottom="xs" variant="h2">
      {percentage.toFixed()}% recommend this
    </Heading>
  );
}

const sx = {
  block: {
    border: '1px solid',
    borderColor: 'blueberry.10',
    borderRadius: 'button',
    overflow: 'hidden',
    position: 'relative',
  },
  ratingsWrap: {
    padding: 'm',
  },
  tags: {
    marginTop: 'm',
  },
  ratings: {
    borderTop: '1px solid',
    borderColor: 'blueberry.10',
    padding: 'm',
    '>div': {
      marginBottom: 'xs',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
};
