import { TIERS } from '../constants';
import { getTier } from './user';
import { getSubscriptionDetails, getSubTier } from './subscription';
import { isNativeIos } from '../utils';

export const FEATURES = {
  GOALS: 'goals',
  STATS: 'stats',
  EXPLORE: 'explore',
  PRIVATE_COLLECTIONS: 'private_col',
  HIGHLIGHTS: 'highlights',
  IMPORTER_IOS: 'importer.ios',
};

const FREE_FEATURES = [FEATURES.HIGHLIGHTS, FEATURES.GOALS, FEATURES.STATS];
const PRO_FEATURES = FREE_FEATURES.concat([FEATURES.PRIVATE_COLLECTIONS]);
const SUPPORTER_FEATURES = PRO_FEATURES.concat([]);
const PLANS = {
  [TIERS.FREE]: new Set(FREE_FEATURES),
  [TIERS.PREMIUM]: new Set(PRO_FEATURES),
  [TIERS.SUPPORTER]: new Set(SUPPORTER_FEATURES),
};

export const hasFeature = (feature, fallback = false) => {
  return state => {
    if (feature === FEATURES.IMPORTER_IOS) {
      if (!isNativeIos()) {
        return true;
      }
      return true;
    }

    // try sub state but fall back to `tier` attr on user
    // details, since we don't always have sub info fetched
    const tier = getSubTier(getSubscriptionDetails(state)) || getTier(state);
    const features = PLANS[tier];
    try {
      return features.has(feature);
    } catch (e) {
      // eslint-disable-next-line
      console.log('Not sure if user has feature', feature, tier);
      return fallback;
    }
  };
};

export const shouldShowUpsells = state => {
  const tier = getSubTier(getSubscriptionDetails(state)) || getTier(state);
  if (tier !== TIERS.FREE) {
    return false;
  }
  return true;
};
