/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BaseButton from '../../components/BaseButton';
import BookCover from '../../components/BookCover';
import Heading from '../../components/Heading';
import Text from '../../components/Text';

import BookActions from './BookActions';

export default function BookHeader({
  areListsLoadng,
  authors,
  bookId,
  hasReviewed,
  isAuthenticated,
  isReadList,
  listIds,
  lists,
  openSignUp,
  recommended,
  selectedList,
  subtitle,
  thumbnail,
  title,
  updateList,
  userIsStale,
  openSuggest,
}) {
  return (
    <header sx={sx.header}>
      <div sx={sx.leftCol}>
        <div sx={sx.meta}>
          <Heading as="h1" variant="h1" fontFamily="serif" sx={sx.heading}>
            {title}
          </Heading>
          {subtitle && (
            <Heading as="h2" variant="h2" sx={sx.subtitle}>
              {subtitle}
            </Heading>
          )}
          <Text as="p" variant="body" sx={sx.authors}>
            {authors}
          </Text>
        </div>
        <BookActions
          areListsLoadng={areListsLoadng}
          bookId={bookId}
          isAuthenticated={isAuthenticated}
          isReadList={isReadList}
          listIds={listIds}
          lists={lists}
          openSignUp={openSignUp}
          recommended={recommended}
          hasReviewed={hasReviewed}
          selectedList={selectedList}
          thumbnail={thumbnail}
          title={title}
          updateList={updateList}
          userIsStale={userIsStale}
        />
      </div>
      <div sx={sx.cover}>
        <BaseButton
          onClick={() => (!thumbnail && isAuthenticated ? openSuggest() : null)}
        >
          <BookCover
            hasEmptyState={isAuthenticated}
            isRaised
            thumbnail={thumbnail}
            thumbnail2x={thumbnail}
            title={title}
            visibleByDefault
            width={[140, 140, 140, 160]}
          />
        </BaseButton>
      </div>
    </header>
  );
}

const sx = {
  header: {
    paddingY: [0, 0, 0, 'xxl', 'xxl'],
    borderRadius: 'button',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: [
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'row',
    ],
  },
  cover: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    paddingLeft: [0, 0, 0, 0, 'xxl'],
    paddingTop: ['m', 'm', 'm', 0, 0],
    paddingBottom: ['l', 'l', 'l', 'l', 0],

    maxWidth: ['100%', '100%', '100%', '100%', 420],
    width: ['100%', '100%', '100%', '100%', '40%'],
  },
  leftCol: {
    flex: '1 1 60%',
  },
  meta: {
    paddingX: ['m', 'm', 'm', 0],
    textAlign: ['center', 'center', 'center', 'center', 'left'],
  },
  subtitle: {
    display: 'inline-block',
    lineHeight: 'sub',
  },
  heading: {
    marginBottom: 'xs',
  },
  authors: {
    marginTop: 'xs',
  },
};
