/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';

import ByPerson from '../../components/ByPerson';
import Text from '../../components/Text';

import Section from './Section';
import BookSideBlock from './BookSideBlock';
import SideBlockLink from './SideBlockLink';
import ShowMoreLink from './ShowMoreLink';

import { getProfileDisplayName } from '../../selectors';
import { getCollectionRoute, getProfileRoute } from '../../constants';
import { pluralise } from '../../utils';

export default function FriendsList({ otherCollections }) {
  const [isConcealed, setIsConisConcealed] = useState(true);

  if (otherCollections.length === 0) {
    return null;
  }
  const word = pluralise({
    count: otherCollections.length,
    word: 'collection',
  });
  return (
    <Section title={`Belongs to ${word}`}>
      <BookSideBlock padding="s">
        {otherCollections.slice(0, isConcealed ? 5 : 99).map(collection => {
          const { username, image } = collection.who;
          const { slug, key, id, name } = collection.list;
          const route = getCollectionRoute(username, { slug, key });
          const profileRoute = getProfileRoute(username);
          const displayName = getProfileDisplayName({
            details: collection.who,
          });
          return (
            <SideBlockLink
              flexDirection="column"
              key={id}
              to={route}
              mixpanelEvent="Click Collection From Book Page"
            >
              <Text as="h3" variant="body">
                {name}
              </Text>
              <div sx={{ marginBottom: 'xxs' }}>
                <ByPerson
                  avatarUrl={image}
                  displayName={displayName}
                  hasLink={false}
                  onClick={event => event.stopPropagation()}
                  profileRoute={profileRoute}
                />
              </div>
            </SideBlockLink>
          );
        })}
        {otherCollections.length > 5 && (
          <ShowMoreLink
            onClick={() => setIsConisConcealed(!isConcealed)}
            isConcealed={isConcealed}
          />
        )}
      </BookSideBlock>
    </Section>
  );
}
