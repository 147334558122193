/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Loader from '../../components/Loader';

export default function SearchLoader() {
  return (
    <div sx={sx.loader}>
      <Loader />
    </div>
  );
}

const sx = {
  loader: {
    display: 'flex',
    marginTop: ['s', 's', 'm'],
    justifyContent: 'center',
  },
};
