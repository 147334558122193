/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
  ACTIVITY_ROUTE,
  BOOK_ROUTE,
  SEARCH_ROUTE,
  HIGHLIGHTS_ROUTE,
  PROFILE_ROUTE,
} from '../constants';

import Link from './Link';

export default function SidebarLink({
  children,
  extraStyles = {},
  hasTransition,
  icon,
  isActive,
  onClick,
  to,
}) {
  const Icon = icon;
  const location = useLocation();

  const isLinkActive =
    to === ACTIVITY_ROUTE || to === SEARCH_ROUTE || to === HIGHLIGHTS_ROUTE
      ? location.pathname.includes(to) &&
        !location.pathname.includes(`${BOOK_ROUTE}/`) && // otherwise it catches /bookS
        !location.pathname.includes(PROFILE_ROUTE)
      : location.pathname === to;

  const styles =
    isLinkActive || isActive
      ? { ...sx.link.base, ...sx.link.active, ...extraStyles }
      : { ...sx.link.base, ...sx.link.default, ...extraStyles };

  const as = to ? RouterLink : undefined;

  return (
    <Link
      as={as}
      hasTransition={hasTransition}
      onClick={onClick}
      sx={styles}
      to={to}
    >
      {icon && (
        <div sx={sx.icon}>
          <Icon />
        </div>
      )}
      {children}
    </Link>
  );
}

const sx = {
  link: {
    active: {
      color: 'blueberry.100',
      bg: 'blueberry.5',
      '&:hover': {
        color: 'blackberry',
        bg: 'blueberry.10',
      },
    },
    default: {
      color: 'blueberry.80',
      '&:hover': {
        color: 'blueberry.100',
        bg: 'blueberry.5',
      },
    },
    base: {
      fontSize: 'xs',
      lineHeight: '24px',
      borderRadius: 'toast',
      cursor: 'pointer',

      display: 'flex',
      width: '100%',
      alignItems: 'center',
      marginBottom: 'xxs',

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  icon: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
