export const getThreadById = threadId => {
  return state => {
    return state.threads[threadId];
  };
};

export const getThreadItems = thread => {
  const items = thread?.items || [];
  return items;
};
