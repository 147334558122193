import React from 'react';

import Svg from '../components/Svg';

export default function TwitterIcon({ ...props }) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m16 2.57843c-.5879.26773-1.2209.44878-1.8848.52967.678-.41603 1.1983-1.07572 1.4434-1.86156-.6348.38522-1.3364.66546-2.0858.8157-.5973-.65391-1.451-1.06224-2.3957-1.06224-1.81162 0-3.28136 1.50716-3.28136 3.36583 0 .26388.02817.52005.08452.76659-2.72818-.1406-5.14645-1.47924-6.76645-3.518-.282678.49886-.444214 1.07765-.444214 1.694 0 1.1672.579454 2.19766 1.460354 2.80149-.53718-.01637-1.04432-.1695-1.487585-.41989v.04141c0 1.6314 1.131655 2.99217 2.634265 3.30035-.27516.07897-.56535.11845-.86494.11845-.2113 0-.41791-.02022-.61795-.05971.41792 1.33668 1.62939 2.31038 3.06627 2.33638-1.12321.9033-2.53942 1.4416-4.076776 1.4416-.264836 0-.526851-.0154-.783234-.0462 1.45284.9534 3.17896 1.511 5.03187 1.511 6.03863 0 9.33963-5.12817 9.33963-9.57647 0-.14638-.0028-.29276-.0084-.43626.6414-.47478 1.1983-1.06705 1.6369-1.74214z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
