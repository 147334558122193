/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import Button from './Button';
import ModalDialog from './ModalDialog';
import InviteUsers from '../modals/InviteUsers';

export default function InviteUsersButton() {
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  const closeInvite = () => {
    setIsInviteOpen(false);
  };

  const openInvite = () => {
    setIsInviteOpen(true);
  };

  return (
    <React.Fragment>
      <ModalDialog isVisible={isInviteOpen} onClose={closeInvite}>
        <InviteUsers onClose={closeInvite} />
      </ModalDialog>
      <Button onClick={openInvite}>Invite a friend</Button>
    </React.Fragment>
  );
}
