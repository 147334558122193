import React from 'react';

import Svg from '../../components/Svg';

export default function FeedIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14 2.5c.5523 0 1 .44772 1 1v1.875c0 .55228-.4477 1-1 1s-1-.44772-1-1v-1.875c0-.55228.4477-1 1-1zm0 6.875c-2.5543 0-4.625 2.0707-4.625 4.625s2.0707 4.625 4.625 4.625 4.625-2.0707 4.625-4.625-2.0707-4.625-4.625-4.625zm-6.625 4.625c0-3.6589 2.9661-6.625 6.625-6.625s6.625 2.9661 6.625 6.625-2.9661 6.625-6.625 6.625-6.625-2.9661-6.625-6.625zm-.09245-8.13191c-.39053-.39053-1.02369-.39053-1.41422 0-.39052.39052-.39052 1.02369 0 1.41421l1.32583 1.32583c.39052.39052 1.02369.39052 1.41421 0 .39053-.39053.39053-1.02369 0-1.41422zm-4.78255 8.13191c0-.5523.44772-1 1-1h1.875c.55228 0 1 .4477 1 1s-.44772 1-1 1h-1.875c-.55228 0-1-.4477-1-1zm6.10837 6.8057c.39053-.3905.39053-1.0236 0-1.4142-.39052-.3905-1.02369-.3905-1.41421 0l-1.32583 1.3259c-.39052.3905-.39052 1.0236 0 1.4142.39053.3905 1.02369.3905 1.41422 0zm5.39163.8193c.5523 0 1 .4477 1 1v1.875c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.875c0-.5523.4477-1 1-1zm6.8059-2.2335c-.3906-.3905-1.0237-.3905-1.4143 0-.3905.3906-.3905 1.0237 0 1.4142l1.3259 1.3259c.3905.3905 1.0237.3905 1.4142 0 .3905-.3906.3905-1.0237 0-1.4142zm.8191-5.3915c0-.5523.4477-1 1-1h1.875c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1.875c-.5523 0-1-.4477-1-1zm.5067-6.7177c.3905-.39052.3905-1.02369 0-1.41421-.3905-.39053-1.0237-.39053-1.4142 0l-1.3259 1.32582c-.3905.39053-.3905 1.02369 0 1.41422.3906.39052 1.0237.39052 1.4143 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
