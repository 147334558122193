/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import GhostButton from './GhostButton';
import Input from './Input';

import { checkIfiOS } from '../utils';
import { MinusIcon, PlusIcon } from '../icons';

export default function Counter({
  id,
  onBlur,
  onChange,
  onDecrease,
  onFocus,
  onIncrease,
  placeholder,
  value,
}) {
  const isiOs = checkIfiOS();

  // To trigger numeric input on iOS
  const iOSProps = isiOs && {
    pattern: '[0-9]*',
    novalidate: true,
  };

  return (
    <div sx={sx.counter}>
      <GhostButton size="iconBig" sx={sx.button} onClick={onDecrease}>
        <MinusIcon />
      </GhostButton>
      <Input
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        sx={sx.number}
        value={value}
        variant="input.primary"
        {...iOSProps}
      />
      <GhostButton size="iconBig" sx={sx.button} onClick={onIncrease}>
        <PlusIcon />
      </GhostButton>
    </div>
  );
}

const sx = {
  counter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    border: '1px solid',
    borderColor: 'blueberry.20',
    flex: 1,
    height: '46px',
    lineHeight: '44px',
    borderRadius: 'button',
    marginX: 'xs',
    textAlign: 'center',
    fontSize: 's',
    color: 'blueberry.100',
  },
  button: {
    borderRadius: 'button',
  },
};
