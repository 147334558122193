// form constants
export const SIGN_UP_FORM = 'signup';
export const SEND_FORGOT_PASSWORD_FORM = 'send-forgot-password';
export const SET_FORGOT_PASSWORD_FORM = 'set-forgot-password';

// Actions
export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';

// Reducer
const formsReducer = (
  state = {
    forms: {},
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FORM_STATE:
      const field = action.field || '_global';
      return {
        ...state,
        [action.form]: { errors: { [field]: action.error } },
      };
    default:
      return state;
  }
};

// Action creators
export const updateFormState = (form, errorMsg, field = '_global') => ({
  type: UPDATE_FORM_STATE,
  form,
  field,
  error: errorMsg,
});

export default formsReducer;
