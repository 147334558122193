import React from 'react';

import Svg from '../components/Svg';

export default function ComputerIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m14.922 14h-13.84499c-.369996.01-.719996-.18-.916996-.494-.1970001-.314-.2130001-.71-.043-1.04l.86-1.7v-7.76597c0-.532.212996-1.042.589996-1.418.377-.375.89-.584998 1.42-.582h10.03499c1.107-.001998 2.007.893 2.01 2v7.76697l.85 1.7c.17.33.155.725-.042 1.04s-.546.502-.918.493zm-1.92-10.99997h-10.00399v6h10.00399zm-.1 7.99997h-9.79399l-.5 1h10.79399z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
