/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Card from '../../components/Card';
import FeedItemFooter from './FeedItemFooter';

export default function FeedItem({
  action,
  children,
  containsSpoiler,
  date,
  firstName,
  link,
  review,
  title,
}) {
  return (
    <Card extraStyles={sx.card}>
      <div sx={sx.body}>{children}</div>
      <FeedItemFooter
        action={action}
        containsSpoiler={containsSpoiler}
        date={date}
        firstName={firstName}
        link={link}
        review={review}
        title={title}
      />
    </Card>
  );
}

const sx = {
  card: {
    overflow: 'hidden',
    '&:hover > div:first-of-type': {
      bg: 'blueberry.5',
    },
  },
  body: {
    cursor: 'pointer',
    bg: 'blueberry.2',
    transition: 'smooth.fast',
  },
};
