/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode, Grid } from 'theme-ui';

import AspectImage from '../../components/AspectImage';
import Heading from '../../components/Heading';
import Text from '../../components/Text';

import {
  trackYourReading,
  trackYourReading2x,
  trackYourReadingDark,
  trackYourReadingDark2x,
  discoverBooks,
  discoverBooks2x,
  discoverBooksDark,
  discoverBooksDark2x,
  setReadingGoal,
  setReadingGoal2x,
  setReadingGoalDark,
  setReadingGoalDark2x,
  unlockStats,
  unlockStats2x,
  unlockStatsDark,
  unlockStatsDark2x,
  readReviews,
  readReviews2x,
  readReviewsDark,
  readReviewsDark2x,
  createCollections,
  createCollections2x,
  createCollectionsDark,
  createCollectionsDark2x,
} from '../../assets';

export default function NewFeaturesBlock() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <div sx={sx.container}>
      <div sx={sx.block}>
        <div sx={sx.blockHeading}>
          <Heading as="h2" fontFamily="serif" maxWidth={480}>
            Track your reading and build your library
          </Heading>
          <Text as="p" variant="marketingSub">
            Simple to use &amp; easy on the eye - no spreadsheets needed.
          </Text>
        </div>
        <div sx={sx.blockFirstImage}>
          <AspectImage
            alt="Screenshots: Track your reading and build your library"
            src={isDark ? trackYourReadingDark : trackYourReading}
            src2x={isDark ? trackYourReadingDark2x : trackYourReading2x}
            aspectWidth={1616}
            aspectHeight={821}
            width="100%"
          />
        </div>
      </div>
      <Grid gap="m" columns={[1, 1, 1, 2]} sx={sx.topGrid}>
        <div sx={sx.block}>
          <div sx={sx.blockHeading}>
            <Heading as="h2" fontFamily="serif" maxWidth={420}>
              Discover your new favorite book
            </Heading>
            <Text as="p" variant="marketingSub">
              Connect with your friends to see what they&apos;re reading or
              explore recommendations from the Oku community.
            </Text>
          </div>
          <div sx={sx.blockSecondImage}>
            <AspectImage
              alt="Screenshot: Discover your next favorite book"
              src={isDark ? discoverBooksDark : discoverBooks}
              src2x={isDark ? discoverBooksDark2x : discoverBooks2x}
              aspectWidth={340}
              aspectHeight={715}
              width="100%"
            />
          </div>
        </div>
        <div sx={sx.block}>
          <div sx={sx.blockThirdImage}>
            <AspectImage
              alt="Screenshot: Stay on track with a reading goal"
              src={isDark ? setReadingGoalDark : setReadingGoal}
              src2x={isDark ? setReadingGoalDark2x : setReadingGoal2x}
              aspectWidth={340}
              aspectHeight={715}
              width="100%"
            />
          </div>
          <div sx={sx.blockHeading}>
            <Heading as="h2" fontFamily="serif" maxWidth={420}>
              Kickstart your reading habit
              {/* Stay on track with a reading goal */}
            </Heading>
            <Text as="p" variant="marketingSub">
              Motivate yourself to stay on track with a reading goal.
            </Text>
          </div>
        </div>
      </Grid>
      <div sx={sx.block}>
        <div sx={sx.blockHeading}>
          <Heading as="h2" fontFamily="serif" maxWidth={480}>
            See your personalized reading stats
          </Heading>
        </div>
        <div sx={sx.blockFourthImage}>
          <AspectImage
            alt="Screenshots: Unlock your personal statistics"
            src={isDark ? unlockStatsDark : unlockStats}
            src2x={isDark ? unlockStatsDark2x : unlockStats2x}
            aspectWidth={1460}
            aspectHeight={883}
            width="100%"
          />
        </div>
      </div>
      <Grid gap="m" columns={[1, 1, 1, 2]} sx={sx.grid}>
        <div sx={sx.block}>
          <div sx={sx.blockHeading}>
            <Heading as="h2" fontFamily="serif" maxWidth={420}>
              Create custom collections
            </Heading>
          </div>
          <div sx={sx.blockFifthImage}>
            <AspectImage
              alt="Screenshot: Create custom collections"
              src={isDark ? createCollectionsDark : createCollections}
              src2x={isDark ? createCollectionsDark2x : createCollections2x}
              aspectWidth={340}
              aspectHeight={715}
              width="100%"
            />
          </div>
        </div>
        <div sx={sx.block}>
          <div sx={sx.blockHeading}>
            <Heading as="h2" fontFamily="serif" maxWidth={420}>
              Read community reviews and add your own
            </Heading>
          </div>
          <div sx={sx.blockLastImage}>
            <AspectImage
              alt="Screenshot: Read community reviews or leave one of your own"
              src={isDark ? readReviewsDark : readReviews}
              src2x={isDark ? readReviewsDark2x : readReviews2x}
              aspectWidth={825}
              aspectHeight={1024}
              width="100%"
            />
          </div>
        </div>
      </Grid>
    </div>
  );
}

const sx = {
  container: {
    marginX: 'auto',
    paddingX: ['l', 'l', 'l', 'xl'],
    maxWidth: 'page.laptop',
    marginBottom: ['xxl', 'xxl', 'xxl', 'xxxl'],
  },
  block: {
    bg: 'blueberry.5',
    borderRadius: 'button',
    overflow: 'hidden',
  },
  blockHeading: {
    display: 'flex',
    paddingY: ['xl', 'xl', 'xl', 'xxl'],
    paddingX: ['m', 'm', 'm', 0],
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    h2: {
      fontSize: ['l', 'l', 'l', 'xl'],
      color: 'blackberry',
      lineHeight: 'heading',
      fontWeight: 400,
      letterSpacing: '-0.006em',
    },
    p: {
      marginTop: 's',
      maxWidth: 460,
      fontSize: ['xs', 'xs', 'm', 'm', 'm'],
    },
  },
  blockFirstImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: [-60, -60, -120, -120],
    '>div': {
      width: '90%',
      maxWidth: 1057,
    },
  },
  blockSecondImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -360,
    marginTop: -24,
    '>div': {
      width: '100%',
      maxWidth: 380,
    },
  },
  blockThirdImage: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -360,
    marginBottom: -84,
    '>div': {
      width: '100%',
      maxWidth: 380,
    },
  },
  blockFourthImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: [-60, -60, -180, -180],
    '>div': {
      width: '90%',
      maxWidth: 1057,
    },
  },
  blockFifthImage: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -30,
    marginBottom: -240,
    '>div': {
      width: '100%',
      maxWidth: 380,
    },
  },
  blockLastImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: [-120, -120, -120, -280],
    marginTop: -24,
    '>div': {
      width: '100%',
    },
  },
  topGrid: {
    marginY: 'm',
    '>div:last-child': {
      gridColumnStart: 1,
      gridRowStart: 1,
    },
  },
  grid: {
    marginY: 'm',
    '&:last-child': {
      marginBottom: 0,
    },
  },
};
