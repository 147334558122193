import { AUTH_TOKEN, AUTH_CACHE_KEY } from '../constants';

export const getIsAuthenticated = state => {
  if (state.auth.isAuthenticated) {
    return true;
  }

  // token may sometimes not exist even though the user is authed!
  if (getToken(state)) {
    return true;
  }

  // assume we are authed if we have cached data.
  // if not the data will be cleared by shouldSetUser
  const authCache = getCachedAuthData();
  if (authCache?.username) {
    return true;
  }
  return false;
};

export const getToken = state => {
  const token = state.auth.token;
  if (token) {
    return token;
  }
  return localStorage.getItem(AUTH_TOKEN);
};

export const getCachedAuthData = () => {
  try {
    return JSON.parse(localStorage.getItem(AUTH_CACHE_KEY));
  } catch (e) {
    // eslint-disable-next-line
    console.error('Could not parse cached auth data:', e);
    return null;
  }
};

export const getAuthFormLoading = state => state.auth.isLoading;
export const getAuthEmailError = state => state.auth.error.email;
export const getAuthFormError = state => state.auth.error.form;
export const getAuthPasswordError = state => state.auth.error.password;
export const getAuthUsernameError = state => state.auth.error.username;
