import { PROCESSOR_PADDLE } from '../constants';

export const isNativeIos = () => {
  return !!(
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.toggleMessageHandler
  );
};

export const canUseNativePayments = subscription => {
  // we must use native payments on iOS, but will disable it if
  // the user has an existing subscription paid via paddle.
  // we're checking if the existing subscription is not paddle
  // because it covers the case where the user has no subscription.
  return isNativeIos() && subscription?.payment_processor !== PROCESSOR_PADDLE;
};

export const messageNative = message => {
  // sends a message to the iOS app wrapper
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.toggleMessageHandler
  ) {
    window.webkit.messageHandlers.toggleMessageHandler.postMessage({
      message,
    });
    return true;
  }
  return false;
};
