/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

import Avatar from './Avatar';
import Card from './Card';
import FollowButton from './FollowButton';
import Text from './Text';

import { getProfileRoute } from '../constants';

export default function UserBlock({
  firstname,
  following,
  image,
  lastname,
  selfUsername,
  username,
}) {
  const userRoute = getProfileRoute(username);
  const shouldShowFollowButton = typeof following !== 'undefined';
  const fullName =
    firstname || lastname ? `${firstname} ${lastname}` : username;

  const followStyles = shouldShowFollowButton && sx.linkWithFollow;
  const copyStyles = shouldShowFollowButton && sx.copyWithFollow;
  const buttonStyles = shouldShowFollowButton && sx.buttonWrap;

  return (
    <Card sx={sx.block}>
      <Link to={userRoute} sx={{ ...sx.link, ...followStyles }}>
        <div sx={sx.avatar}>
          <Avatar src={image} src2x={image} name={fullName} />
        </div>
        <div sx={{ ...sx.copy, ...copyStyles }}>
          <Text variant="h2" fontSize="s">
            {fullName}
          </Text>
          <Text variant="overline" sx={sx.overline}>
            @{username}
          </Text>
        </div>
      </Link>
      {shouldShowFollowButton && (
        <div sx={{ ...sx.button, ...buttonStyles }}>
          <FollowButton
            isFollowing={following}
            size="s"
            user={selfUsername}
            userToFollow={username}
          />
        </div>
      )}
    </Card>
  );
}

const sx = {
  link: {
    display: 'flex',
    padding: 'm',
    height: ['auto', 'auto', '100%', '100%'],
    paddingRight: ['m', 'm', 126, 126],
  },
  linkWithFollow: {
    alignItems: 'center',
    flexDirection: ['column', 'column', 'row', 'row'],
  },
  copy: {
    marginLeft: 12,
  },
  copyWithFollow: {
    marginTop: ['s', 's', 0, 0],
    textAlign: ['center', 'center', 'left', 'left'],
  },
  overline: {
    marginTop: 6,
  },
  button: {
    position: ['relative', 'relative', 'absolute', 'absolute'],
    top: ['auto', 'auto', '50%', '50%'],
    right: ['auto', 'auto', 16, 16],
    marginTop: [0, 0, -17, -17],
  },
  buttonWrap: {
    paddingX: ['m', 'm', 0, 0],
    paddingBottom: ['m', 'm', 0, 0],
    textAlign: 'center',
  },
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
  },
};
