/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import BackButton from './BackButton';
import ShareButton from './ShareButton';

export default function PageNavigation({
  children,
  onGoBack,
  position = 'sticky',
  showBackButton,
  showShareButton,
}) {
  const history = useHistory();
  const goBack = () => history.goBack();
  return (
    <div sx={{ ...sx.container, position }}>
      {showBackButton ? (
        <BackButton onClick={onGoBack || goBack} sx={sx.button} />
      ) : (
        <div />
      )}
      <div sx={sx.flex}>
        {children}
        {showShareButton && <ShareButton />}
      </div>
    </div>
  );
}

const sx = {
  container: {
    padding: ['s', 's', 'm'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'navigation',
    pointerEvents: 'none',
  },
  button: {
    pointerEvents: 'all',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'all',
    '> button': {
      marginLeft: 'xs',
    },
  },
};
