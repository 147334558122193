/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Input from './Input';
import Button from './Button';
import { SearchIcon, CloseIcon } from '../icons';

const SearchInput = React.forwardRef(
  ({ children, value = '', onClear, ...props }, ref) => {
    const sx = getStyles();
    return (
      <div sx={sx.container}>
        <figure sx={sx.icon}>
          <SearchIcon />
        </figure>
        <Input
          ref={ref}
          sx={sx.input}
          type="search"
          value={value}
          variant="input.filled"
          {...props}
        />
        {value !== '' && (
          <Button
            type="button"
            onClick={onClear}
            sx={sx.clearButton}
            variant="transparent"
          >
            <CloseIcon />
          </Button>
        )}
      </div>
    );
  }
);

const getStyles = () => ({
  container: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: 0,
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'blueberry.100',
    pointerEvents: 'none',
  },
  input: {
    paddingLeft: 42,
    height: '42px',
    lineHeight: '42px',
    paddingRight: [42, 42, 54, 54],
  },
  clearButton: {
    position: 'absolute',
    top: '50%',
    marginTop: -22,
    right: 0,
    padding: 0,
    width: 44,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SearchInput;
