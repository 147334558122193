/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Avatar from '../../components/Avatar';
import Heading from '../../components/Heading';
import Text from '../../components/Text';
import Card from '../../components/Card';

import { TwitterIcon } from '../../icons';

export default function Tweet({
  avatar,
  avatarBg,
  url,
  author,
  handle,
  date,
  tweet,
}) {
  return (
    <Card href={url} target="_blank">
      <div sx={sx.block}>
        <div sx={sx.header}>
          <div sx={sx.meta}>
            <div sx={sx.avatar}>
              <Avatar
                bg={avatarBg}
                isGrayscale={false}
                name={author}
                size={36}
                src={avatar}
                src2x={avatar}
              />
            </div>
            <div>
              <Heading as="div" sx={sx.heading}>
                {author}
              </Heading>
              <Text as="div" sx={sx.handle}>
                @{handle}
              </Text>
            </div>
          </div>
          <TwitterIcon color="twitter.100" width={20} height={20} />
        </div>
        <div sx={sx.body}>{tweet}</div>
        <footer sx={sx.date}>{date}</footer>
      </div>
    </Card>
  );
}

const sx = {
  block: {
    padding: 'm',
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 's',
    img: {
      marginY: 0,
    },
  },
  heading: {
    color: 'blackberry',
    fontWeight: 500,
    fontSize: 'm',
    textAlign: 'left',
    lineHeight: 1.1,
    marginBottom: 'xxs',
  },
  handle: {
    fontWeight: 400,
    fontSize: 'xs',
    color: '#697882',
    textAlign: 'left',
    lineHeight: 1.1,
  },
  body: {
    color: 'blackberry',
    fontSize: 's',
    lineHeight: 1.375,
    marginTop: 's',
    marginBottom: 'm',
    textAlign: 'left',
  },
  date: {
    fontSize: 'xs',
    color: '#697882',
    textAlign: 'left',
  },
};
