/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Image from '../../../components/Image';

import BlogPageWrap from '../BlogPageWrap';

import {
  dashboard,
  dashboard2x,
  profileFemke,
  profileFemke2x,
  newBookPage,
  newBookPage2x,
  reviewPreview,
  reviewPreview2x,
  sidebar,
  sidebar2x,
} from '../../../assets';

export default function BlogPost() {
  return (
    <BlogPageWrap title="A new look and a sneak peak / Blog">
      <time>Feb 9, 2021</time>
      <h1>A new look and a sneak peak</h1>

      <Image
        src={dashboard}
        src2x={dashboard2x}
        alt="Oku dashboard screenshot"
      />

      <p>
        February&apos;s here and 2021 has already left a mark! It&apos;s shaping
        up to be an exciting year for Oku, and to kick it all off, we&apos;ve
        started with a refresh of our visual style. We think it brings
        consistency & maturity to the product and sets up well for the things to
        come this year. As ever we will continue to refine things, so let us
        know what you think, but first let&apos;s take a look at what&apos;s
        new.
      </p>

      <h2>Profiles</h2>
      <p>
        Maybe my favorite bit is the new profile page. The focus remains on you
        and your books, whether you wear your recommendations proudly, or hide
        your profile from view.
      </p>
      <Image
        src={profileFemke}
        src2x={profileFemke2x}
        alt="Oku profile screenshot"
      />

      <h2>Sidebar Navigation</h2>
      <p>
        Back in December we dropped a new sidebar that makes getting around Oku
        way more efficient. Drag &amp; drop coming soon!
      </p>
      <img src={sidebar} src2x={sidebar2x} alt="Oku sidebar screenshot" />

      <h2>Book pages</h2>
      <p>
        Book pages have been reworked a bit to make better use of wider screens.
        A new tab bar is the foundation for exciting things to come.
      </p>
      <img src={newBookPage} src2x={newBookPage2x} alt="" />

      <h2>Sneak peek: Book reviews</h2>
      <p>
        Whilst you&apos;re here I can&apos;t resist teasing what&apos;s got us
        excited at the moment. Soon you&apos;ll be able to hear directly from
        Oku members what they thought of a book, as well as be able to showcase
        your own reviews!
      </p>
      <img
        src={reviewPreview}
        src2x={reviewPreview2x}
        alt="Oku reviews screenshot"
      />

      <h2>Sign up for Oku</h2>
      <p>
        If you haven&apos;t already, you can create an account to start tracking
        books & sharing recommendations today! We&apos;ve got lots more to come
        and you&apos;ll be first in line to know about the progress we&apos;re
        making, plus your feedback will help shape our direction. Thanks for
        reading.{' '}
      </p>
    </BlogPageWrap>
  );
}
