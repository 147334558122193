import React from 'react';

import Svg from '../../components/Svg';

export default function FeedbackIcon(props) {
  return (
    <Svg {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 15.6557V6H17.5V9.5V13.75H9.5014C9.50093 13.75 9.50047 13.75 9.5 13.75C9.49953 13.75 9.49907 13.75 9.4986 13.75H8.7109C8.48207 13.75 8.26016 13.8285 8.08221 13.9723L6 15.6557ZM8.5 16.2064L5.62868 18.5277C5.32903 18.7699 4.91684 18.8184 4.56912 18.6524C4.22139 18.4864 4 18.1353 4 17.75V5.75C4 5.28587 4.18438 4.84075 4.51256 4.51256C4.84075 4.18438 5.28587 4 5.75 4H17.75C18.2141 4 18.6592 4.18437 18.9874 4.51256C19.3156 4.84075 19.5 5.28587 19.5 5.75V8.5H22.25C22.7141 8.5 23.1592 8.68437 23.4874 9.01256C23.8156 9.34075 24 9.78587 24 10.25V22.25C24 22.6353 23.7786 22.9864 23.4309 23.1524C23.0832 23.3184 22.671 23.2699 22.3713 23.0277L18.9354 20.25H10.25C9.78587 20.25 9.34075 20.0656 9.01256 19.7374C8.68437 19.4092 8.5 18.9641 8.5 18.5V16.2064ZM10.5 15.75V18.25H19.2891C19.5179 18.25 19.7398 18.3285 19.9178 18.4723L22 20.1557V10.5H19.5V14C19.5 14.4641 19.3156 14.9092 18.9874 15.2374C18.6592 15.5656 18.2141 15.75 17.75 15.75H10.5Z"
      />
    </Svg>
  );
}
