/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { toast } from 'react-toastify';

import { API_GR_COMPLETE_AUTH_URL, API_IMPORTER_URL } from '../constants';
import { callApi, isNativeIos, messageNative } from '../utils';
import { setToken } from './auth';
import { shouldSetUser } from './user';

export function authenticateGoodreads({ authUrl }) {
  // Returns a successful promise when the user has authed Goodreads.
  // To do so this will launch a popup window (in supported browsers)
  // that will be used to complete the oauth flow and then closes
  // itself once finished.

  if (isNativeIos()) {
    // iOS is a special case - we will never resolve or reject this promise.
    // In order to avoid the Goodreads app from hijacking the redirect our wrapper
    // app will open it in a safari webview in-app. After a successful auth,
    // that in-app webview will be redirected back to Oku and an import will
    // be started.
    return new Promise(() => {
      messageNative({ openUrl: authUrl });
    });
  }

  const windowOpts = {
    features: 'location=0,status=0,popup=1,width=600,height=700',
    target: '_blank',
    path: authUrl,
  };
  const popup = window.open(
    windowOpts.path,
    windowOpts.target,
    // windowOpts.name,
    windowOpts.features
  );

  if (popup === null) {
    // safari won't let us open a window in an async function,
    // so we need to redirect the whole window
    window.location = authUrl;
  }

  const handler = (resolve, reject) => {
    window.addEventListener('message', evt => {
      if (evt.origin !== window.location.origin) {
        console.log('message from wrong domain', evt);
        return;
      }
      console.log('got msg', evt);
      if (!Object.keys(evt.data).includes('authState')) {
        console.log('message doesnt contain expected data');
        return;
      }

      popup.close();
      if (evt?.data?.authState === 'complete') {
        mixpanel.track('Goodreads: Connect');
        return resolve(evt);
      }
      return reject(evt.data);
    });
  };
  window._oauthInterval = window.setInterval(() => {
    if (popup?.closed || popup === null) {
      window.clearInterval(window._oauthInterval);
      window.removeEventListener('message', handler);
    }
  }, 1000);
  return new Promise(handler);
}

export function completeGoodreadsAuth({
  token,
  oauthToken,
  okuToken,
  dispatch,
}) {
  return callApi(API_GR_COMPLETE_AUTH_URL, {
    token,
    method: 'POST',
    body: { token: oauthToken, okuToken },
  }).then(data => {
    if (data.authkey) {
      // on the native iOS app the backend will return an authkey
      // so that we can authenticate the in-app webview invisibly
      dispatch(setToken(data.authkey));
      return shouldSetUser({ dispatch, token: data.authkey })
        .catch(() => console.log)
        .then(() => data);
    }
    return data;
  });
}

export function disconnectGoodreads({ token }) {
  return callApi(API_GR_COMPLETE_AUTH_URL, {
    token,
    method: 'DELETE',
  });
}

export function fetchGoodreadsInfo({ token }) {
  return callApi(API_GR_COMPLETE_AUTH_URL, {
    token,
    method: 'GET',
  });
}

export function importWithAuth({
  dispatch,
  token,
  skipAuth = false,
  onGoodreadsConnected = () => {},
}) {
  const body = {
    ios: isNativeIos(),
  };
  return callApi(API_IMPORTER_URL, { token, method: 'POST', body }).then(
    data => {
      if (!data.ok) {
        return;
      }
      if (data.auth_needed && !skipAuth) {
        const authUrl = data.auth_url;
        return authenticateGoodreads({ token, authUrl }).then(() => {
          onGoodreadsConnected();
          return importWithAuth({ dispatch, token, skipAuth: true });
        });
      }
      return data;
    }
  );
}

export function beginImport({
  dispatch,
  token,
  skipAuth = false,
  onGoodreadsConnected = () => {},
}) {
  return importWithAuth({
    dispatch,
    token,
    skipAuth,
    onGoodreadsConnected,
  })
    .then(({ channel }) => {
      toast(
        <p>
          We&apos;re importing your books &amp; collections from Goodreads.
          We&apos;ll let you know once they&apos;re ready.
        </p>,
        { autoClose: 7000, toastId: 'start-import' }
      );
      mixpanel.track('Goodreads Import: Begin');
      return { channel };
    })
    .then(listenForCompletion)
    .then(() => {
      mixpanel.track('Goodreads Import: Complete');
      if (token) {
        return shouldSetUser({ dispatch, token, loaders: false });
      }
    })
    .then(() => {
      toast(<p>Your Goodreads import has finished</p>, {
        autoClose: 7000,
        toastId: 'import-ready',
      });
    });
}

export function listenForCompletion({ channel }) {
  return new Promise((resolve, reject) => {
    if (
      !window.ws ||
      [window.ws.CLOSED, window.ws.CLOSING].includes(window.ws.readyState)
    ) {
      let url = 'ws://127.0.0.1:8000/';
      if (process.env.NODE_ENV === 'production') {
        url = 'wss://oku.club/';
      }

      const ws = new WebSocket(url);
      window.ws = ws;
    }

    window.ws.onmessage = msg => {
      /* eslint-disable no-else-return */
      if (msg.data === '1') {
        console.log('import complete');
        window.ws.close();
        return resolve();
      } else if (msg.data === '-1') {
        // not implemented yet
        return reject();
      }
    };

    // todo: ws might already be open
    window.ws.onopen = () => {
      window.ws.send(`subscribe:${channel}`);
    };
  });
}
