/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import Badge from '../../components/Badge';
import BookStack from '../../components/BookStack';
import Button from '../../components/Button';
import FullHeight from '../../components/FullHeight';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import ListHeading from '../../components/ListHeading';
import ListLandscape from '../../components/ListLandscape';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PrimaryButton from '../../components/PrimaryButton';
import ReadingGoalLogo from '../../components/ReadingGoalLogo';
import ReadingProgress from '../../components/ReadingProgress';
import Text from '../../components/Text';

import {
  FEATURES,
  getActiveReadingGoal,
  getBooksAddedSince,
  getReadList,
  getToken,
  hasFeature,
  shouldShowUpsells,
  getStats,
} from '../../selectors';

import { SearchIcon } from '../../icons';
import { manFloating, manFloating2x } from '../../assets';

import {
  getReadingGoalRoute,
  PRICING_ROUTE,
  SEARCH_ROUTE,
  STATS_ROUTE,
  TIERS,
} from '../../constants';

import { fetchStats } from '../../ducks/stats';
import { fetchReadingGoal } from '../../ducks/goals';

import StatsPageHeader from '../Stats/StatsPageHeader';

export default function Dashboard({
  bookRoute,
  booksReading,
  booksToRead,
  greeting,
  readingCount,
  readingRoute,
  subheading,
  toReadCount,
  toReadRoute,
}) {
  const notReading = readingCount === 0;
  const cap = useResponsiveValue([6, 6, 6, 9]);
  const showOnMobile = useResponsiveValue([true, true, true, false]);
  const READING_GOAL_ROUTE = getReadingGoalRoute();
  const enableGoals = useSelector(hasFeature(FEATURES.GOALS));
  const goal = useSelector(getActiveReadingGoal);
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const showUpsells = useSelector(shouldShowUpsells);
  const stats = useSelector(getStats);
  const statsEnabled = useSelector(hasFeature(FEATURES.STATS, true));
  const needsFetch = !stats?.fetched;
  const [statsLoading, setStatsLoading] = useState(needsFetch);

  useEffect(() => {
    if (enableGoals && goal === undefined) {
      fetchReadingGoal({ dispatch, token });
    }
    if (needsFetch) {
      fetchStats({ dispatch, token }).then(() => {
        setTimeout(() => setStatsLoading(false), 300);
      });
    }
  }, []);

  const cutoff = moment().startOf('year');
  const booksRead = getBooksAddedSince(useSelector(getReadList), cutoff).length;

  return (
    <Page title="Dashboard">
      <FullHeight>
        <div sx={sx.container}>
          <div sx={{ paddingTop: 'xl' }} />
          <div sx={sx.header}>
            {notReading && (
              <Image
                alt="Man floating with books around him"
                blendIn
                maxWidth={420}
                width="90vw"
                respondToDarkMode
                src={manFloating}
                src2x={manFloating2x}
                sx={sx.image}
              />
            )}
            <Heading as="h1" fontSize="l" fontFamily="serif" sx={sx.greeting}>
              {greeting}
            </Heading>
            <Text sx={sx.body} variant="body">
              {subheading}
            </Text>
            {notReading ? (
              <PrimaryButton as={RouterLink} to={SEARCH_ROUTE} sx={sx.button}>
                <SearchIcon />
                What are you reading?
              </PrimaryButton>
            ) : (
              <RouterLink
                sx={sx.stack}
                to={readingCount === 1 ? bookRoute : readingRoute}
              >
                <BookStack books={booksReading} />
              </RouterLink>
            )}
          </div>
          <div sx={sx.placeholder} />
        </div>
        <PageBody extraStyles={sx.pageBody}>
          {enableGoals && showOnMobile && (
            <div sx={sx.featureWrap}>
              <ListHeading to={READING_GOAL_ROUTE}>Reading goal</ListHeading>
              <ReadingProgress
                hasLogo
                booksRead={booksRead}
                booksToRead={goal}
              />
            </div>
          )}
          {statsEnabled && (
            <div sx={sx.featureWrap}>
              <ListHeading to={STATS_ROUTE}>All time statistics</ListHeading>
              <StatsPageHeader
                isLoading={statsLoading}
                showHeading={false}
                stats={stats}
              />
            </div>
          )}
          {toReadCount > 0 && (
            <ListLandscape
              books={booksToRead}
              cap={cap}
              heading="To Read"
              route={toReadRoute}
            />
          )}
          {!enableGoals && showUpsells && showOnMobile && (
            <div sx={sx.readingGoalUpsell}>
              <div sx={sx.upsell.heading}>
                <Heading as="p" variant="h2" sx={sx.upsell.headingText}>
                  Reading goal
                </Heading>
                <Badge kind={TIERS.PREMIUM} />
              </div>
              <div sx={sx.upsell.box}>
                <ReadingGoalLogo />
                <Text as="p" sx={sx.upsell.paragraph}>
                  Upgrade your account to set a reading goal for this year
                </Text>
                <Button as={RouterLink} to={PRICING_ROUTE}>
                  Upgrade now
                </Button>
              </div>
            </div>
          )}
        </PageBody>
      </FullHeight>
    </Page>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    flex: 1,
  },
  image: {
    marginBottom: 's',
  },
  greeting: {
    color: 'blueberry.100',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingX: 'l',
  },
  stack: {
    marginTop: 'xl',
    cursor: 'pointer',
  },
  body: {
    marginTop: 'xxs',
    textAlign: 'center',
    paddingLeft: 'm',
    paddingRight: 'm',
  },
  placeholder: {
    paddingTop: 'xl',
  },
  button: {
    marginTop: 'm',
    span: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        marginRight: 'xs',
      },
    },
  },
  upsell: {
    heading: {
      display: 'flex',
      alignItems: 'center',
    },
    headingText: {
      marginRight: 'xs',
    },
    box: {
      bg: 'blueberry.5',
      padding: 'l',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 'button',
      marginTop: 's',
    },
    paragraph: {
      textAlign: 'center',
      maxWidth: 240,
      lineHeight: 'sub',
      color: 'blueberry.80',
      marginY: 'm',
    },
  },
  readingGoalUpsell: {
    marginTop: 'xl',
  },
  pageBody: {
    marginBottom: ['s', 's', 'm', 'xl'],
  },
  featureWrap: {
    marginBottom: 'xl',
  },
};
