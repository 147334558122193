/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { Link as RouterLink } from 'react-router-dom';
import range from 'lodash-es/range';
import random from 'lodash-es/random';

import Avatar from './Avatar';
import BookReviewBody from './BookReviewBody';
import BookReviewHeaderDesktop from './BookReviewHeaderDesktop';
import BookReviewHeaderMobile from './BookReviewHeaderMobile';
import ContentLoader from './ContentLoader';
import ModalDialog from './ModalDialog';

import DeleteReviewModal from '../modals/DeleteReview';
import ReviewBookModal from '../modals/ReviewBook';

import { PROFILE_ROUTE } from '../constants';

export default function BookReviewBlock({
  author,
  authorUsername,
  avatar,
  bookSlug,
  bookThumbnail,
  bookTitle,
  date,
  hasSpoilers,
  id,
  isLoading,
  ownReview,
  recommended,
  review,
  tags,
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDesktopHeader = useResponsiveValue([false, false, false, true]);
  const profileRoute = `${PROFILE_ROUTE}/${authorUsername}`;

  // Edit modal
  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  // Delete modal
  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  if (isLoading) {
    return (
      <div sx={sx.container}>
        <ContentLoader sx={sx.loader.avatar} />
        <div sx={sx.loader.header}>
          <ContentLoader sx={sx.loader.meta} />
          <ContentLoader sx={sx.loader.share} />
        </div>
        <div sx={sx.loader.review}>
          {range(1, 6).map(paragraph => (
            <ContentLoader
              key={paragraph}
              sx={{
                ...sx.loader.paragraph,
                width: `${random(50, 75)}%`,
              }}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div sx={sx.container}>
        {showDesktopHeader && (
          <RouterLink sx={sx.avatar} to={profileRoute}>
            <Avatar size={48} src={avatar} src2x={avatar} />
          </RouterLink>
        )}
        {showDesktopHeader ? (
          <BookReviewHeaderDesktop
            author={author}
            authorUsername={authorUsername}
            bookSlug={bookSlug}
            bookThumbnail={bookThumbnail}
            bookTitle={bookTitle}
            date={date}
            id={id}
            openDeleteModal={openDeleteModal}
            openEditModal={openEditModal}
            ownReview={ownReview}
            recommended={recommended}
          />
        ) : (
          <BookReviewHeaderMobile
            author={author}
            authorUsername={authorUsername}
            avatar={avatar}
            bookSlug={bookSlug}
            date={date}
            id={id}
            recommended={recommended}
          />
        )}
        <BookReviewBody hasSpoilers={hasSpoilers} review={review} tags={tags} />
      </div>
      <ModalDialog isVisible={isDeleteModalOpen} onClose={closeDeleteModal}>
        <DeleteReviewModal onClose={closeDeleteModal} reviewId={id} />
      </ModalDialog>
      <ModalDialog isVisible={isEditModalOpen} onClose={closeEditModal}>
        <ReviewBookModal
          hasSpoilers={hasSpoilers}
          onClose={closeEditModal}
          review={review}
          reviewId={id}
        />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  container: {
    position: 'relative',
    paddingLeft: [0, 0, 0, 68],
    marginBottom: 'l',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  loader: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 'circle',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 12,
    },
    meta: {
      width: '70%',
      height: 20,
      borderRadius: 'book',
    },
    share: {
      width: '10%',
      height: 20,
      borderRadius: 'book',
    },
    review: {
      padding: 's',
      border: '1px solid',
      borderColor: 'blueberry.10',
      borderRadius: 'button',
    },
    paragraph: {
      height: 20,
      borderRadius: 'book',
      marginBottom: 'xs',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
};
