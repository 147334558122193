/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BookStack from './BookStack';
import Card from './Card';
import FloatingFriend from './FloatingFriend';
import { getReadingRoute } from '../constants';

export default function FriendReadingCard({ books, who }) {
  const collectionRoute = getReadingRoute(who.username);
  return (
    <div sx={sx.container}>
      <Card extraStyles={sx.card} to={collectionRoute}>
        <BookStack asBlock books={books} hasHover={false} />
      </Card>
      <FloatingFriend who={who} />
    </div>
  );
}

const sx = {
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    minHeight: 330,
  },
  card: {
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
