/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Image from '../../components/Image';
import Button from '../../components/Button';
import FullHeight from '../../components/FullHeight';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import PrimaryButton from '../../components/PrimaryButton';
import Text from '../../components/Text';

import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../constants';
import { bookcaseHome, bookcaseHome2x } from '../../assets';

export default function HomePageMobile() {
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';
  useEffect(() => {
    mixpanel.track('View Home (native mobile)');
    window.plausible('new-home-view-native mobile');
  }, []);
  return (
    <Page headerless title="The companion app for every book">
      <FullHeight>
        <main sx={sx.body}>
          <div sx={sx.image}>
            <Image
              alt="Oku – The companion app for every book"
              respondToDarkMode
              src={bookcaseHome}
              src2x={bookcaseHome2x}
            />
          </div>
          <div sx={sx.content}>
            <Logo asLink={false} width={90} height="auto" />
            <Text as="p" sx={sx.paragraph}>
              Join a new community to track your reading, organise your
              highlights &amp; collections and discover new books!
            </Text>
            <PrimaryButton as={Link} to={SIGN_UP_ROUTE}>
              Sign up
            </PrimaryButton>
            <Button
              variant={isDarkMode ? 'ghost.dark' : 'light'}
              as={Link}
              to={SIGN_IN_ROUTE}
            >
              Sign in
            </Button>
          </div>
        </main>
      </FullHeight>
    </Page>
  );
}

const sx = {
  body: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    padding: 'm',
    width: '100%',
    position: 'relative',
    svg: {
      fill: 'blackberry',
    },
    a: {
      width: '100%',
      marginBottom: 's',
      maxWidth: 320,

      '&:last-child': {
        marginBottom: 'm',
      },
    },
  },
  paragraph: {
    maxWidth: 260,
    textAlign: 'center',
    marginBottom: 'l',
    marginTop: 'xs',
  },
  image: {
    flex: 1,
    bg: 'milk',
    width: '100%',
    position: 'relative',
    '> span': {
      width: '120%',
      left: '-10%',
      position: 'absolute',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '> img': {
        maxHeight: '150%',
      },
    },
  },
};
