/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import { pluralise } from '../../utils';

export default function BookTags({ tags }) {
  if (tags.length === 0) return null;
  return (
    <div sx={sx.tags}>
      {tags.map(item => {
        const { tag, count } = item;
        const content = `${pluralise({ count, word: 'vote' })}`;
        return (
          <Tooltip content={content} cursor="default" key={tag}>
            <Tag key={tag} marginRight="xs" marginBottom="xs">
              {tag} · {count}
            </Tag>
          </Tooltip>
        );
      })}
    </div>
  );
}

const sx = {
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};
