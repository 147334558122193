import { getThreadById } from './threads';

// this thread will contain all highlights for a book
export const getBookHighlightsThreadId = bookId => {
  return `highlights:b:${bookId}`;
};

// this thread will contain the book info & a threadId containing the actual highlights
export const getUserHighlightsThreadId = username => {
  return `highlights:u:${username}`;
};

// this thread will contain a user's highlights for a specific book
export const getUserHighlightsForBookThreadId = (username, bookId) => {
  return `highlights:u:${username}:b:${bookId}`;
};

export const getHighlightById = id => {
  return state => {
    return state.highlights?.items[id];
  };
};

export const getHighlightsFromThread = threadId => {
  return state => {
    const thread = getThreadById(threadId)(state) || {};
    const highlightIds = thread?.items;
    if (highlightIds === undefined) {
      return undefined;
    }
    return highlightIds
      .map(id => {
        return getHighlightById(id)(state);
      })
      .filter(id => id !== undefined);
  };
};

export const getBookHighlights = bookId => {
  return state => {
    const threadId = getBookHighlightsThreadId(bookId);
    return getHighlightsFromThread(threadId)(state);
  };
};

export const getUserHighlights = username => {
  return state => {
    const books = getThreadById(getUserHighlightsThreadId(username))(state);
    return books;
  };
};
