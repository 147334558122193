/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Tooltip from './Tooltip';

export default function HelpIcon({ content, position, width = 200 }) {
  return (
    <Tooltip
      content={content}
      display="block"
      width={width}
      whiteSpace="wrap"
      cursor="help"
      position={position}
    >
      <div sx={sx.icon}>?</div>
    </Tooltip>
  );
}

const sx = {
  icon: {
    width: 18,
    height: 18,
    bg: 'blueberry.10',
    borderRadius: 'circle',
    transition: 'smooth.fast',
    fontWeight: 'bold',
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 'base',
    paddingTop: 1,
    marginRight: 6,
    '&:hover': {
      bg: 'blueberry.20',
      color: 'blackberry',
    },
  },
};
