/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import GhostButton from '../../components/GhostButton';
import ModalDialog from '../../components/ModalDialog';

import SuggestEditModal from '../../modals/SuggestEdit';

import { PencilIcon } from '../../icons';

export default function SuggestEditButton({ bookId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <GhostButton onClick={openModal} sx={sx.button}>
        <PencilIcon />
        Suggest an edit
      </GhostButton>
      <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
        <SuggestEditModal bookId={bookId} onClose={closeModal} />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  button: {
    marginTop: 'm',
    span: {
      display: 'flex',
      alignItems: 'center',
    },
    svg: {
      marginRight: 'xs',
    },
  },
};
