/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BookCover from '../components/BookCover';
import Image from '../components/Image';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeadingText from '../components/ModalHeadingText';
import ModalShareButtons from '../components/ModalShareButtons';

import { shareLeft, shareLeft2x, shareRight, shareRight2x } from '../assets';

export default function ShareBookModal({
  heading,
  onClose,
  quote,
  shareUrl,
  showSkipButton,
  subheading,
  thumbnail,
  title,
}) {
  return (
    <ModalBody canGrow={false}>
      <header sx={sx.header}>
        <Image
          alt="Girl floating with books around her"
          blendIn
          boxShadow="none"
          respondToDarkMode
          src={shareLeft}
          src2x={shareLeft2x}
          visibleByDefault
          width="100%"
        />
        <figure sx={sx.bookCover}>
          <BookCover
            isRaised
            thumbnail={thumbnail}
            thumbnail2x={thumbnail}
            title={title}
            width={[140, 140, 160]}
          />
        </figure>
        <Image
          alt="Girl floating with books around her"
          blendIn
          respondToDarkMode
          src={shareRight}
          src2x={shareRight2x}
          visibleByDefault
          width="100%"
        />
      </header>
      <ModalContent extraStyles={sx.content}>
        <ModalHeadingText heading={heading} text={subheading} />
      </ModalContent>
      <ModalFooter direction="vertical">
        <ModalShareButtons
          onClose={onClose}
          quote={quote}
          shareUrl={shareUrl}
          showSkipButton={showSkipButton}
        />
      </ModalFooter>
    </ModalBody>
  );
}

const sx = {
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 'xs',
  },
  bookCover: {
    paddingTop: 'xl',
    display: 'block',
  },
  content: {
    textAlign: 'center',
  },
};
