/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { useResponsiveValue } from '@theme-ui/match-media';
import moment from 'moment';
import random from 'lodash-es/random';
import range from 'lodash-es/range';

import Avatar from '../components/Avatar';
import BookCover from '../components/BookCover';
import BookReviewBody from '../components/BookReviewBody';
import BookReviewHeaderMobile from '../components/BookReviewHeaderMobile';
import ContentLoader from '../components/ContentLoader';
import Link from '../components/Link';
import LoadingPage from './Loading';
import ModalDialog from '../components/ModalDialog';
import Page from '../components/Page';
import PageBody from '../components/PageBody';
import PageNavigation from '../components/PageNavigation';
import ProfileReviewMeta from '../components/ProfileReviewMeta';
import RecommendedText from '../components/RecommendedText';
import SidebarLink from '../components/SidebarLink';
import Text from '../components/Text';

import ReviewBookModal from '../modals/ReviewBook';

import {
  getBook,
  getBookAuthors,
  getBookSubTitle,
  getBookTitle,
  getCoverUrl,
  getIsAuthenticated,
  getProfileDisplayName,
  getReviewById,
  getSelectedList,
  getToken,
  getUserStale,
} from '../selectors';

import { ChatIcon, PencilIcon } from '../icons/sidebar';
import { getBookReview } from '../ducks/reviews';
import { getBookDetails } from '../ducks/books';

import {
  getBookRoute,
  getProfileRoute,
  getBookReviewsRoute,
} from '../constants';
import { getBookId } from '../utils';

export default function Review() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const showSidebar = useResponsiveValue([false, false, false, true]);
  const showDesktopHeader = useResponsiveValue([
    false,
    false,
    false,
    false,
    true,
  ]);
  const { reviewId } = match.params;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const bookId = getBookId(match.params.slug);
  const book = useSelector(getBook(bookId));
  const review = useSelector(getReviewById(reviewId));
  const userIsStale = useSelector(getUserStale);

  const workId = book?.workId || '';
  const selectedList = useSelector(getSelectedList(bookId, workId));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!review) {
      getBookReview({
        bookId,
        dispatch,
        reviewId,
        token,
      });
    }
    if (!book || !book.isComplete) {
      getBookDetails({ bookId, token, dispatch });
    }
  }, []);

  if (!review || !book) {
    return <LoadingPage />;
  }

  const {
    added_at,
    hasSpoilers,
    id,
    recommended,
    reviewedBy,
    tags,
    text,
  } = review;

  const { title, subtitle, imageLinks, authors, slug, yourReview } = book;

  const hasReviewed = yourReview !== null;
  const reviewerUsername = reviewedBy?.username;
  const isReadList = selectedList?.key === 'read';
  const bookRoute = getBookRoute(slug);
  const profileRoute = getProfileRoute(reviewerUsername);
  const thumbnail = getCoverUrl(imageLinks);
  const bookTitle = getBookTitle({ title, subtitle });
  const bookSubtitle = getBookSubTitle({ title, subtitle });
  const bookAuthors = getBookAuthors(authors);
  const displayName = getProfileDisplayName({ details: reviewedBy });
  const date = moment(added_at).format('DD MMM YYYY');
  const reviewsRoute = getBookReviewsRoute(slug);

  const extraModalProps = hasReviewed && {
    hasSpoilers,
    review: text,
    reviewId: id,
  };

  return (
    <Page title="Review">
      {isAuthenticated && <PageNavigation showBackButton showShareButton />}
      <PageBody>
        <div sx={sx.review}>
          {showSidebar && (
            <aside sx={sx.sidebar}>
              <Link as={RouterLink} to={bookRoute}>
                <BookCover
                  isRaised
                  thumbnail={thumbnail}
                  thumbnail2x={thumbnail}
                  title={bookTitle}
                  visibleByDefault
                  width={[60, 60, 60, 164]}
                />
              </Link>
              {userIsStale ? (
                <div sx={sx.sidebarLinks}>
                  {range(2).map(item => (
                    <div key={item} sx={sx.loader.outer}>
                      <ContentLoader
                        sx={{
                          ...sx.loader.inner,
                          width: `${random(60, 100)}%`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div sx={sx.sidebarLinks}>
                  {isReadList && (
                    <SidebarLink
                      extraStyles={sx.sidebarLink}
                      icon={PencilIcon}
                      onClick={openModal}
                    >
                      {hasReviewed ? 'Edit review' : 'Write review'}
                    </SidebarLink>
                  )}
                  <SidebarLink
                    extraStyles={sx.sidebarLink}
                    icon={ChatIcon}
                    to={reviewsRoute}
                  >
                    Read more reviews
                  </SidebarLink>
                  <ModalDialog
                    onClose={closeModal}
                    isVisible={isModalOpen}
                    width={520}
                  >
                    <ReviewBookModal
                      bookId={book?.id}
                      onClose={closeModal}
                      slug={slug}
                      {...extraModalProps}
                    />
                  </ModalDialog>
                </div>
              )}
            </aside>
          )}
          <header sx={sx.header}>
            {showDesktopHeader ? (
              <React.Fragment>
                <Link as={RouterLink} to={profileRoute} variant="primary">
                  <Avatar
                    name={displayName}
                    size={40}
                    src={reviewedBy?.image}
                    src2x={reviewedBy?.image}
                  />
                </Link>
                <div sx={sx.meta}>
                  <Text sx={sx.author}>
                    Review by{' '}
                    <Link as={RouterLink} to={profileRoute} variant="primary">
                      {displayName}
                    </Link>
                  </Text>
                  <div sx={sx.recommended}>
                    {recommended && (
                      <React.Fragment>
                        <RecommendedText />
                        <div sx={sx.headerDot}>·</div>
                      </React.Fragment>
                    )}
                    <Text variant="subtitle2">{date}</Text>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <BookReviewHeaderMobile
                author={displayName}
                authorUsername={reviewedBy?.username}
                avatar={reviewedBy?.image}
                bookId={bookId}
                date={date}
                id={id}
                recommended={recommended}
              />
            )}
          </header>
          <div sx={sx.recommended}>
            {!showSidebar && (
              <div sx={sx.cover}>
                <Link as={RouterLink} to={bookRoute}>
                  <BookCover
                    isRaised
                    thumbnail={thumbnail}
                    thumbnail2x={thumbnail}
                    title={bookTitle}
                    visibleByDefault
                    width={[60, 60, 60, 164]}
                  />
                </Link>
              </div>
            )}
            <ProfileReviewMeta
              author={bookAuthors}
              link={bookRoute}
              subtitle={bookSubtitle}
              title={bookTitle}
            />
          </div>
          <main sx={sx.body}>
            <BookReviewBody
              hasSpoilers={hasSpoilers}
              review={text}
              tags={tags}
            />
          </main>
        </div>
      </PageBody>
    </Page>
  );
}

const sx = {
  header: {
    marginBottom: 'l',
    paddingBottom: [0, 0, 0, 's'],
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    marginTop: 'l',
  },
  headerDot: {
    paddingX: 'xxs',
    color: 'blueberry.100',
    display: 'inline-block',
  },
  review: {
    position: 'relative',
    paddingLeft: [0, 0, 0, 164 + 48],
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
  cover: {
    marginRight: 's',
  },
  recommended: {
    display: 'flex',
    alignItems: 'center',
  },
  sidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  sidebarLink: {
    fontSize: 'xxs',
    marginBottom: 0,
  },
  sidebarLinks: {
    marginTop: 's',
  },
  meta: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 's',
  },
  loader: {
    outer: {
      width: '100%',
      paddingY: 'xs',
      marginBottom: 'xxs',
    },
    inner: {
      height: 28,
      borderRadius: 'toast',
    },
  },
};
