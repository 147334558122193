/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { Swiper } from 'swiper/react';

import theme from '../theme';

export default function SwiperGrid({
  children,
  gridClassName,
  nextClassName,
  paddingBottom = 40,
  prevClassName,
}) {
  const cardsPerView = useResponsiveValue(['auto', 'auto', 2, 2, 2, 3, 4]);
  const spaceBetween = useResponsiveValue([0, 0, 24]);
  const freeMode = useResponsiveValue([true, true, false]);
  const naviation = {
    nextEl: `.${nextClassName}`,
    prevEl: `.${prevClassName}`,
  };
  const sx = {
    [`.${gridClassName}`]: {
      paddingTop: 1,
      paddingBottom,
      marginBottom: 's',
    },
    '.swiper-pagination-bullet': {
      bg: 'blackberry',
    },
    '.swiper-pagination-bullet-active': {
      bg: 'blueberry.100',
    },
    [`.${gridClassName} .swiper-slide`]: {
      width: ['88%', '88%', '100%'],
      paddingLeft: [theme.space.s, theme.space.s, 0],
    },
    [`.${gridClassName} .swiper-slide:last-child`]: {
      paddingRight: [theme.space.s, theme.space.s, 0],
    },
  };
  return (
    <div sx={sx}>
      <Swiper
        className={gridClassName}
        freeMode={freeMode}
        navigation={naviation}
        pagination={false}
        simulateTouch={false}
        slidesPerView={cardsPerView}
        spaceBetween={spaceBetween}
      >
        {children}
      </Swiper>
    </div>
  );
}
