/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Button from '../components/Button';
import GhostButton from '../components/GhostButton';
import ModalHeading from '../components/ModalHeading';
import ModalFooter from '../components/ModalFooter';

export default function DeleteConfirmation({
  onClose,
  onSubmit,
  isLoading,
  heading,
}) {
  return (
    <form onSubmit={onSubmit}>
      <ModalHeading extraStyles={sx.heading}>{heading}</ModalHeading>
      <ModalFooter>
        <GhostButton type="button" onClick={onClose}>
          Leave it be
        </GhostButton>
        <Button variant="strawberry.active" isLoading={isLoading}>
          Delete
        </Button>
      </ModalFooter>
    </form>
  );
}

const sx = {
  heading: {
    marginBottom: ['s', 's', 'm'],
  },
};
