import React from 'react';

import Svg from '../components/Svg';

export default function SearchIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m7.60004 13.1002c0-3.0377 2.46246-5.50016 5.50016-5.50016 3.0376 0 5.5001 2.46246 5.5001 5.50016 0 1.5157-.6131 2.8882-1.6048 3.883-.0021.0021-.0042.0042-.0064.0064-.002.002-.004.004-.006.006-.9948.9917-2.3673 1.6047-3.8829 1.6047-3.0377 0-5.50016-2.4625-5.50016-5.5001zm9.92306 5.5547c-1.2133.9673-2.7506 1.5455-4.4229 1.5455-3.92134 0-7.1002-3.1789-7.1002-7.1002 0-3.92134 3.17886-7.1002 7.1002-7.1002 3.9213 0 7.1002 3.17886 7.1002 7.1002 0 1.6725-.5783 3.21-1.5459 4.4233l3.1112 3.1112c.3124.3124.3124.819 0 1.1314s-.819.3124-1.1314 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
