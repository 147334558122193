/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Text from '../../components/Text';
import ProfileWrap from './ProfileWrap';
import { girlReading, girlReading2x } from '../../assets';

export default function ProfileEmptyState({ heading, subheading, title }) {
  return (
    <ProfileWrap title={title} shouldShowEmptyState>
      <div sx={sx.emptyState}>
        <div />
        <div sx={sx.emptyStateBody}>
          <Heading as="h2" variant="h1" fontFamily="serif">
            {heading}
          </Heading>
          <Text as="p" variant="body">
            {subheading}
          </Text>
        </div>
        <figure sx={sx.image}>
          <Image
            alt="Girl reading a book"
            maxWidth={300}
            respondToDarkMode
            src={girlReading}
            src2x={girlReading2x}
            width="100%"
          />
        </figure>
      </div>
    </ProfileWrap>
  );
}

const sx = {
  emptyState: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 'xl',
  },
  emptyStateBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '>p': {
      marginTop: 'xs',
    },
  },
  image: {
    minHeight: [254, 254, 204, 204],
    overflow: 'hidden',
    '> span': {
      marginBottom: [-50, -50, -100, -100],
    },
  },
  container: {
    paddingTop: 'xl',
  },
  loadMoreButton: {
    marginBottom: ['s', 's', 'l', 'xl'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: [0, 0, 0, 132],
  },
};
