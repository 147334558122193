/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import ActivityDot from '../../components/ActivityDot';
import Avatar from '../../components/Avatar';
import Image from '../../components/Image';
import InviteUsersButton from '../../components/InviteUsersButton';
import Text from '../../components/Text';
import Link from '../../components/Link';

import { friendsDog, friendsDog2x } from '../../assets';
import { getProfileRoute } from '../../constants';

import BookSideBlock from './BookSideBlock';
import Section from './Section';
import ShowMoreLink from './ShowMoreLink';

export default function FriendsList({ isAuthenticated, friends }) {
  const [isConcealed, setIsConcealed] = useState(true);
  const allowCollapse = useResponsiveValue([true, true, true, true, false]);

  if (!isAuthenticated) return null;

  if (friends.length === 0) {
    return (
      <Section title="Activity from friends">
        <BookSideBlock
          bg="blueberry.5"
          borderColor="transparent"
          padding="24px 100px 24px 24px"
        >
          <div>
            <div sx={sx.image}>
              <Image
                alt="Follow your friends to see what they’re reading"
                blendIn
                respondToDarkMode
                src={friendsDog}
                src2x={friendsDog2x}
                width="100%"
              />
            </div>
            <div sx={sx.content}>
              <Text variant="body" sx={sx.text}>
                If your friends begin tracking this book, you’ll see it here.
              </Text>
              <InviteUsersButton />
            </div>
          </div>
        </BookSideBlock>
      </Section>
    );
  }

  return (
    <Section title="Activity from friends">
      <ul>
        {friends.slice(0, isConcealed && allowCollapse ? 5 : 99).map(friend => {
          const { username, userId, firstname, image } = friend.user;
          const profileRoute = getProfileRoute(username);
          const action = getFriendsSavedBook({
            collection: friend.collection.key,
            recommended: friend.recommended,
          });
          return (
            <div key={userId} sx={sx.item}>
              <Link as={RouterLink} sx={sx.relative} to={profileRoute}>
                <Avatar name={action} src={image} src2x={image} />
                <span sx={sx.dot}>
                  <ActivityDot
                    recommended={friend.recommended}
                    list={friend.collection.key}
                  />
                </span>
              </Link>
              <div sx={sx.meta}>
                <Link
                  as={RouterLink}
                  variant="body"
                  sx={{ fontSize: 'xs', color: 'blueberry.100' }}
                  to={profileRoute}
                >
                  {firstname || username}
                </Link>
                <Text as="div" variant="body">
                  {action} &middot; {formatDate(friend.date)}
                </Text>
              </div>
            </div>
          );
        })}
      </ul>
      {allowCollapse && friends.length > 5 && (
        <ShowMoreLink
          onClick={() => setIsConcealed(!isConcealed)}
          isConcealed={isConcealed}
        />
      )}
    </Section>
  );
}

// TODO: move to utils
function formatDate(date) {
  const currentDate = moment(new Date());
  const displayedDate = moment(date);
  const oneMonthAgo = currentDate.subtract(1, 'months');

  if (displayedDate.isBefore(oneMonthAgo)) {
    return ` ${moment(date).format('LL')}`;
  }
  return ` ${moment(date, 'YYYYMMDD').fromNow()}`;
}

const getFriendsSavedBook = ({ collection, recommended }) => {
  if (recommended) {
    return 'Recommended';
  }
  if (collection === 'to-read') {
    return 'Wants to read';
  }
  if (collection === 'reading') {
    return 'Started reading';
  }
  if (collection === 'read') {
    return 'Finished';
  }
  if (collection === 'abandoned') {
    return 'Abandoned';
  }
  return 'Saved';
};

const sx = {
  item: {
    display: 'flex',
    paddingY: 's',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  meta: {
    marginLeft: 's',
  },
  content: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: 340,
    pointerEvents: 'none',
  },
  text: {
    paddingBottom: 'xs',
    marginBottom: 'xs',
  },
  relative: {
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};
