import React from 'react';

import Svg from '../components/Svg';

export default function BookmarkIcon(props) {
  return (
    <Svg {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5885 7.62861C10.1368 7.62861 9.74359 7.98188 9.74359 8.44427V19.9788L13.5087 17.5795C13.805 17.3906 14.195 17.3906 14.4913 17.5795L18.2564 19.9788V8.44427C18.2564 7.98083 17.8649 7.62861 17.4115 7.62861H10.5885ZM9.74414 20.7253C9.74414 20.7253 9.74409 20.7249 9.74402 20.7241ZM8 8.44427C8 7.10624 9.14856 6 10.5885 6H17.4115C18.8543 6 20 7.1063 20 8.44427V20.7085C20 21.0791 19.8632 21.6007 19.3297 21.8671C18.7936 22.1347 18.2525 21.9478 17.9238 21.7383L14 19.2379L10.0762 21.7383C9.7478 21.9476 9.20567 22.1359 8.66851 21.8668C8.13521 21.5996 8 21.0774 8 20.7085V8.44427Z"
      />
    </Svg>
  );
}
