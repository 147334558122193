/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Label from './Label';
import Textarea from './Textarea';

const TextareaWithLabel = React.forwardRef(
  (
    { children, sx, id, label, variant = 'input.primary', error, ...props },
    ref
  ) => (
    <Label error={error} id={id} label={label}>
      <Textarea
        id={id}
        variant={error ? 'input.error' : variant}
        ref={ref}
        {...props}
      />
    </Label>
  )
);

export default TextareaWithLabel;
