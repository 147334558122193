/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../components/Heading';
import Footer from '../Home/Footer';
import Loader from '../../components/Loader';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import SwiperRecommended from './SwiperRecommended';
import SwiperCollections from './SwiperCollections';
import SwiperReviews from './SwiperReviews';

import { fetchExplore } from '../../ducks/explore';

import {
  exploreRecsThreadId,
  exploreFeaturedColsThreadId,
  exploreRecentColsThreadId,
  exploreReviewsThreadId,
  getThreadById,
} from '../../selectors';

export default function SearchBody() {
  const dispatch = useDispatch();
  const reviews = useSelector(getThreadById(exploreReviewsThreadId));
  const recommended = useSelector(getThreadById(exploreRecsThreadId));
  const recentCollections = useSelector(
    getThreadById(exploreRecentColsThreadId)
  );
  const featuredCollections = useSelector(
    getThreadById(exploreFeaturedColsThreadId)
  );
  const [exploreLoading, setExploreLoading] = useState(true);

  useEffect(() => {
    fetchExplore({ dispatch }).then(() => setExploreLoading(false));
  }, []);

  return (
    <Page title="Explore Oku">
      <PageBody>
        <div sx={sx.header}>
          <Heading as="h1" fontFamily="serif" variant="marketing">
            Explore Oku
          </Heading>
        </div>
        {exploreLoading ? (
          <div sx={sx.loader}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <SwiperRecommended books={recommended?.items} />
            <SwiperCollections collections={recentCollections?.items} />
            {!!featuredCollections?.items?.length && (
              <SwiperCollections
                collections={featuredCollections?.items}
                responsiveHeading={['Featured collections']}
              />
            )}
            <SwiperReviews reviews={reviews?.items} />
          </React.Fragment>
        )}
      </PageBody>
      <Footer />
    </Page>
  );
}

const sx = {
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: 'xl',
  },
  header: {
    textAlign: 'center',
    maxWidth: 'marketing.laptop',
    marginX: 'auto',
    marginBottom: 'xxl',
    paddingTop: [0, 0, 's'],
  },
};
