import React from 'react';

import Svg from '../components/Svg';

export default function Info({ ...props }) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14.35 5.5c-4.88772 0-8.85 3.96228-8.85 8.85 0 4.8877 3.96228 8.85 8.85 8.85 4.8877 0 8.85-3.9623 8.85-8.85 0-4.88772-3.9623-8.85-8.85-8.85zm-10.35 8.85c0-5.71615 4.63385-10.35 10.35-10.35 5.7161 0 10.35 4.63385 10.35 10.35 0 5.7162-4.6339 10.35-10.35 10.35-5.71615 0-10.35-4.6338-10.35-10.35zm8.7998-.8c0-.4142.3358-.75.75-.75h.8c.4142 0 .75.3358.75.75v4.85h.05c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-.8c-.4142 0-.75-.3358-.75-.75v-4.85h-.05c-.4142 0-.75-.3358-.75-.75zm2.7496-3.6c0 .6627-.5372 1.2-1.2 1.2-.6627 0-1.2-.5373-1.2-1.2 0-.66274.5373-1.2 1.2-1.2.6628 0 1.2.53726 1.2 1.2z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
