import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useColorMode } from 'theme-ui';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
import { Global, css } from '@emotion/react';

import {
  ACTIVITY_EVERYBODY_ROUTE,
  ACTIVITY_FRIENDS_ROUTE,
  ACTIVITY_ROUTE,
  BLOG_ROUTE,
  BOOK_HIGHLIGHT_ROUTE_PARAMS,
  BOOK_HIGHLIGHTS_ROUTE_PARAMS,
  BOOK_REVIEWS_ROUTE_PARAMS,
  BOOK_ROUTE_PARAMS,
  COLLECTION_ROUTE_PARAMS,
  COLLECTIONS_ROUTE_PARAMS,
  FOLLOWERS_ROUTE_PARAMS,
  FOLLOWING_ROUTE_PARAMS,
  HIGHLIGHT_ROUTE_PARAMS,
  HIGHLIGHTS_ROUTE,
  HOME_ROUTE,
  INVITE_JOIN_ROUTE_PARAMS,
  POST_ROUTE,
  PRICING_ROUTE,
  PRIVACY_ROUTE,
  PROFILE_HIGHLIGHTS_BOOK_ROUTE_PARAMS,
  PROFILE_HIGHLIGHTS_ROUTE_PARAMS,
  PROFILE_REVIEWS_ROUTE_PARAMS,
  PROFILE_ROUTE_PARAMS,
  READING_GOAL_ROUTE_PARAMS,
  REQUEST_ACCESS_ROUTE,
  REVIEW_ROUTE_PARAMS,
  ROOT_ROUTE,
  SEARCH_BOOKS_ROUTE,
  SEARCH_LEGACY_ROUTE,
  SEARCH_ROUTE,
  SEARCH_USERS_ROUTE,
  SEND_FORGOT_PASSWORD_ROUTE,
  SENT_FORGOT_PASSWORD_ROUTE,
  SET_FORGOT_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_OUT_ROUTE,
  SIGN_UP_ROUTE,
  STATS_ROUTE_PARAMS,
  SUPPORT_ROUTE,
  TERMS_ROUTE,
} from './constants';

import {
  getIsAuthenticated,
  getProfile,
  getProfileAvatar,
  getProfileFullName,
  getToken,
} from './selectors';
import {
  checkIfStandalone,
  checkIfiOS,
  checkIfAndroid,
  isNativeIos,
} from './utils';
import { shouldSetUser } from './ducks/user';
import { shouldFetchProfile } from './ducks/profile';

import {
  useCache,
  useCachedAuth,
  useColorModeBasedOnMediaQuery,
} from './hooks';

import ActivityPage from './pages/Activity/Activity';
import AuthPageWrap from './pages/Auth/AuthPageWrap';
import BlogIndex from './pages/Blog/BlogIndex';
import CheckoutProgress from './pages/CheckoutProgress';
import Collection from './pages/Collection';
import DashboardPage from './pages/Dashboard';
import ExplorePublic from './pages/Search/ExplorePublic';
import FeedFriendsPage from './pages/Activity/FeedFriends';
import FeedGlobalPage from './pages/Activity/FeedGlobal';
import GoodreadsConnecting from './pages/GoodreadsConnecting';
import HighlightsPage from './pages/Highlights';
import HighlightPage from './pages/Highlight';
import HomePage from './pages/Home';
import HomePageMobile from './pages/Home/HomepageMobile';
import NotFound from './pages/404';
import PasswordResetSent from './pages/Auth/PaswordResetSent';
import PricingPage from './pages/Pricing';
import PrivacyPage from './pages/Privacy';
import ReadingGoalPage from './pages/ReadingGoal';
import Review from './pages/Review';
import SearchPage from './pages/Search';
import SettingsPage from './pages/Settings';
import SignInPage from './pages/Auth/SignIn';
import SignOutPage from './pages/Auth/SignOut';
import SignUpPage from './pages/Auth/SignUp';
import StatsPage from './pages/Stats';
import SupportPage from './pages/Support';
import TermsPage from './pages/Terms';
import Theme from './pages/Theme';

import { Book, BookReviews, BookHighlights } from './pages/Book';
import { GenericErrorPage } from './pages/Error';

import {
  DeepWorkPost,
  GoodreadsImporterPost,
  InstallReadngPost,
  IntroducingOkuPost,
  NewLookPost,
  OkuForIosPost,
  PremiumLaunchPost,
  PriceDropPost,
  ReviewsPost,
  RssFeedsPost,
  ReferralProgramPost,
} from './pages/Blog/posts';

import AddToHomescreen from './components/AddToHomescreen';
import ContentLoader from './components/ContentLoader';
import Navigation from './components/Navigation';
import Notification from './components/Notification';
import NotificationWrapper from './components/NotificationWrapper';
import ToastContainer from './components/ToastContainer';

import ResetPasswordPage, {
  SetForgotPasswordPage,
} from './pages/Auth/ResetPassword';

import {
  CollectionssPage,
  FollowersPage,
  FollowingPage,
  ProfileHighlightsPage,
  ProfileBookHighlightsPage,
  ProfilePage,
  ReviewsPage,
} from './pages/Profile';

import {
  bookcase,
  bookcase2x,
  manFloating,
  manFloating2x,
  catAndManChilling,
  catAndManChilling2x,
} from './assets';

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = !!useSelector(getIsAuthenticated);
  const location = useLocation();
  const token = useSelector(getToken);
  const isStandalone = checkIfStandalone();
  const isiOs = checkIfiOS();
  const isAndroid = checkIfAndroid();
  const { pathname, search } = location;
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  useCache(pathname);
  useCachedAuth(dispatch);

  useEffect(() => {
    shouldSetUser({ dispatch, token });
  }, [token]);

  useColorModeBasedOnMediaQuery();

  const isNative = isNativeIos();

  useEffect(() => {
    // to see how many people use it in browser compared to installed
    window.plausible('app-load', {
      props: {
        version: process.env.REACT_APP_READNG_VERSION,
        isInstalled: isStandalone,
        isiOs,
      },
    });
  }, [isStandalone]);

  const routeProps = {
    exact: true,
    isAuthenticated,
  };

  const showSidebar =
    isAuthenticated &&
    !pathname.includes('/blog') &&
    !pathname.includes(PRIVACY_ROUTE) &&
    !pathname.includes(TERMS_ROUTE) &&
    !pathname.includes(SUPPORT_ROUTE) &&
    !pathname.includes('/checkout') &&
    !pathname.includes(PRICING_ROUTE);

  const showAddToHomescreen =
    isAuthenticated && isAndroid && !isStandalone && !isNative;

  return (
    <>
      {isDark && (
        <Global
          styles={css`
            :root {
              color-scheme: dark;
            }
          `}
        />
      )}
      <Helmet defaultTitle="Oku" titleTemplate="%s / Oku">
        <ThemeColor isDark={isDark} isAndroid={isAndroid} />
      </Helmet>
      {showSidebar && <Navigation />}
      {showAddToHomescreen && <AddToHomescreen isAndroid={isAndroid} />}

      <NotificationWrapper showSidebar={showSidebar}>
        <Notification />
      </NotificationWrapper>

      <Switch>
        <Route path={SIGN_OUT_ROUTE} exact>
          <SignOutPage />
        </Route>

        <Route {...routeProps} path="/theme">
          <Theme />
        </Route>
        <PublicRoute {...routeProps} path={ROOT_ROUTE}>
          {isNative ? <HomePageMobile /> : <HomePage />}
        </PublicRoute>
        <AuthRoute {...routeProps} path={SIGN_IN_ROUTE}>
          <SignInPage />
        </AuthRoute>
        <AuthRoute {...routeProps} path={REQUEST_ACCESS_ROUTE}>
          <Redirect to={{ pathname: SIGN_UP_ROUTE }} />
        </AuthRoute>
        <AuthRoute {...routeProps} path={SIGN_UP_ROUTE} search={search}>
          <SignUpPage />
        </AuthRoute>
        <AuthRoute
          {...routeProps}
          path={INVITE_JOIN_ROUTE_PARAMS}
          search={search}
        >
          <SignUpPage />
        </AuthRoute>
        <AuthRoute {...routeProps} path={SEND_FORGOT_PASSWORD_ROUTE}>
          <ResetPasswordPage />
        </AuthRoute>
        <AuthRoute {...routeProps} path={SENT_FORGOT_PASSWORD_ROUTE}>
          <PasswordResetSent />
        </AuthRoute>
        <AuthRoute {...routeProps} path={SET_FORGOT_PASSWORD_ROUTE}>
          <SetForgotPasswordPage />
        </AuthRoute>

        <MixedRoute {...routeProps} path={PRICING_ROUTE}>
          <PricingPage />
        </MixedRoute>
        <PrivateRoute {...routeProps} path={HOME_ROUTE}>
          <DashboardPage />
        </PrivateRoute>
        <MixedRoute {...routeProps} path={PRIVACY_ROUTE}>
          <PrivacyPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={TERMS_ROUTE}>
          <TermsPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={SUPPORT_ROUTE}>
          <SupportPage />
        </MixedRoute>
        <PrivateRoute {...routeProps} path={ACTIVITY_ROUTE}>
          <ActivityPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path={ACTIVITY_FRIENDS_ROUTE}>
          <FeedFriendsPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path={ACTIVITY_EVERYBODY_ROUTE}>
          <FeedGlobalPage />
        </PrivateRoute>

        <Route {...routeProps} path={SEARCH_LEGACY_ROUTE}>
          <Redirect to={{ pathname: SEARCH_ROUTE }} />
        </Route>
        <Route {...routeProps} path={SEARCH_ROUTE}>
          {isAuthenticated ? (
            <Redirect to={{ pathname: SEARCH_BOOKS_ROUTE }} />
          ) : (
            <ExplorePublic />
          )}
        </Route>
        <Route {...routeProps} path={SEARCH_USERS_ROUTE}>
          {isAuthenticated ? (
            <SearchPage />
          ) : (
            <Redirect to={{ pathname: SEARCH_ROUTE }} />
          )}
        </Route>
        <Route {...routeProps} path={SEARCH_BOOKS_ROUTE}>
          {isAuthenticated ? (
            <SearchPage />
          ) : (
            <Redirect to={{ pathname: SEARCH_ROUTE }} />
          )}
        </Route>

        <MixedRoute {...routeProps} path={BOOK_ROUTE_PARAMS}>
          <Book />
        </MixedRoute>
        <MixedRoute {...routeProps} path={BOOK_REVIEWS_ROUTE_PARAMS}>
          <BookReviews />
        </MixedRoute>
        <MixedRoute {...routeProps} path={BOOK_HIGHLIGHTS_ROUTE_PARAMS}>
          <BookHighlights />
        </MixedRoute>
        <MixedRoute {...routeProps} path={BOOK_HIGHLIGHT_ROUTE_PARAMS}>
          <HighlightPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={PROFILE_ROUTE_PARAMS}>
          <ProfilePage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={COLLECTIONS_ROUTE_PARAMS}>
          <CollectionssPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={PROFILE_REVIEWS_ROUTE_PARAMS}>
          <ReviewsPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={PROFILE_HIGHLIGHTS_ROUTE_PARAMS}>
          <ProfileHighlightsPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={PROFILE_HIGHLIGHTS_BOOK_ROUTE_PARAMS}>
          <ProfileBookHighlightsPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={FOLLOWERS_ROUTE_PARAMS}>
          <FollowersPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={FOLLOWING_ROUTE_PARAMS}>
          <FollowingPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path={COLLECTION_ROUTE_PARAMS}>
          <Collection />
        </MixedRoute>
        <MixedRoute {...routeProps} path={REVIEW_ROUTE_PARAMS}>
          <Review />
        </MixedRoute>
        <PrivateRoute {...routeProps} path={SETTINGS_ROUTE}>
          <SettingsPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path={READING_GOAL_ROUTE_PARAMS}>
          <ReadingGoalPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path={STATS_ROUTE_PARAMS}>
          <StatsPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path="/checkout/:sessionKey">
          <CheckoutProgress />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path="/checkout/ios/:sessionKey">
          <CheckoutProgress />
        </PrivateRoute>

        <MixedRoute {...routeProps} path={BLOG_ROUTE}>
          <BlogIndex />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.NewLookPost}>
          <NewLookPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.GoodreadsImporterPost}>
          <GoodreadsImporterPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.InstallReadngPost}>
          <InstallReadngPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.DeepWorkPost}>
          <DeepWorkPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.ReviewsPost}>
          <ReviewsPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.PremiumLaunchPost}>
          <PremiumLaunchPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.OkuForIosPost}>
          <OkuForIosPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.PriceDropPost}>
          <PriceDropPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.IntroducingOkuPost}>
          <IntroducingOkuPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.RssFeedsPost}>
          <RssFeedsPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path={POST_ROUTE.ReferralProgramPost}>
          <ReferralProgramPost />
        </MixedRoute>
        <MixedRoute {...routeProps} path="/error">
          <GenericErrorPage />
        </MixedRoute>
        <MixedRoute {...routeProps} path="/post-connect">
          <GoodreadsConnecting />
        </MixedRoute>

        <PrivateRoute {...routeProps} path={HIGHLIGHTS_ROUTE}>
          <HighlightsPage />
        </PrivateRoute>
        <PrivateRoute {...routeProps} path={HIGHLIGHT_ROUTE_PARAMS}>
          <HighlightsPage />
        </PrivateRoute>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <ToastContainer />
    </>
  );
}

function ThemeColor({ isDark, isAndroid }) {
  if (!isAndroid) {
    // on iOS we always have black background and rounded corners
    return <meta name="theme-color" content="#000000" />;
  }
  if (isDark) {
    return <meta name="theme-color" content="#131314" />;
  }
  return <meta name="theme-color" content="#ffffff" />;
}

function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: HOME_ROUTE,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN_ROUTE,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
}

function MixedRoute({ isAuthenticated, ...rest }) {
  if (isAuthenticated) {
    return PrivateRoute({ isAuthenticated, ...rest });
  }
  return PublicRoute({ isAuthenticated, ...rest });
}

function AuthRoute({ children, isAuthenticated, path, search, ...rest }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const urlParams = new URLSearchParams(search);
  const [username, setUsername] = useState(urlParams.get('invitedBy'));
  const user = useSelector(getProfile(username));
  const fullName = getProfileFullName(user);
  const avatar = getProfileAvatar(user);

  const [isLoading, setIsLoading] = useState(!!username);

  useEffect(() => {
    // No point to fetch anything if you're already logged in
    if (username && !isAuthenticated) {
      shouldFetchProfile({
        dispatch,
        username,
        token,
        rejectOnFailure: true,
      }).catch(() => {
        setUsername(null);
      });
    }
  }, [username, token]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const getDetails = () => {
    switch (path) {
      case SIGN_IN_ROUTE:
        return {
          heading: 'Hey, welcome back',
          subHeading: 'Good to see you again!',
          title: 'Sign In',
          image: {
            oneX: bookcase,
            twoX: bookcase2x,
          },
        };
      case SIGN_UP_ROUTE:
      case INVITE_JOIN_ROUTE_PARAMS:
        if (username) {
          return {
            heading: (
              <React.Fragment>
                {isLoading ? (
                  <ContentLoader
                    sx={{
                      width: 100,
                      height: 16,
                      borderRadius: 'button',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  fullName
                )}{' '}
                has invited you to join the Oku community.
              </React.Fragment>
            ),
            subHeading:
              'Track your reading, review/recommend books, create custom collections and follow your friends.',
            title: 'Sign Up',
            image: {
              oneX: bookcase,
              twoX: bookcase2x,
            },
          };
        }
        return {
          heading: 'Join our book community',
          title: 'Create your account',
          image: {
            oneX: catAndManChilling,
            twoX: catAndManChilling2x,
          },
        };
      case SEND_FORGOT_PASSWORD_ROUTE:
        return {
          heading: 'Forgotten your password?',
          subHeading: "We'll send you a reset link if your account exists",
          title: 'Forgotten your password?',
          image: {
            oneX: manFloating,
            twoX: manFloating2x,
          },
        };
      case SENT_FORGOT_PASSWORD_ROUTE:
        return {
          heading: 'Check your email',
          subHeading:
            "We've sent a reset link to your email address. Please check your spam filter and mark us as not spam also!",
          title: 'Confirming your password reset',
          image: {
            oneX: manFloating,
            twoX: manFloating2x,
          },
        };
      case SET_FORGOT_PASSWORD_ROUTE:
        return {
          heading: 'Reset your password',
          subHeading:
            'You want something something memorable and secure, and no shorter than 8 characters.',
          title: 'Reset Password',
          image: {
            oneX: manFloating,
            twoX: manFloating2x,
          },
        };
      default:
        return {};
    }
  };

  const details = getDetails();

  return (
    <Route
      {...rest}
      path={path}
      render={({ location }) =>
        !isAuthenticated ? (
          <AuthPageWrap
            avatar={avatar}
            heading={details.heading}
            image={details.image}
            subHeading={details.subHeading}
            title={details.title}
            invitedBy={
              username
                ? {
                    avatar,
                    fullName,
                    isLoading,
                  }
                : undefined
            }
          >
            {children}
          </AuthPageWrap>
        ) : (
          <Redirect
            to={{
              pathname: HOME_ROUTE,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
}
