import moment from 'moment';

import { API_FEED_FRIENDS_URL, API_FEED_GLOBAL_URL } from '../constants';
import { fetchApi } from '../utils/api';

// Actions
export const SET_FEED = 'SET_FEED';

// Reducer
const feedReducer = (
  state = {
    global: { items: [] },
    friends: { items: [] },
  },
  action
) => {
  switch (action.type) {
    case SET_FEED:
      const feed = action.friends ? 'friends' : 'global';
      return {
        ...state,
        [feed]: { items: action.results, updated: moment() },
      };
    default:
      return state;
  }
};

// Action creators
export const setFeed = (results, friends) => ({
  type: SET_FEED,
  results,
  friends,
});

// Side effects
export function shouldFetchFeed({ dispatch, token, friends }) {
  const url = friends ? API_FEED_FRIENDS_URL : API_FEED_GLOBAL_URL;
  return fetchApi(url, {
    token,
  })
    .then(response => {
      if (response.status !== 404) {
        return response.json();
      }
    })
    .then(({ results }) => {
      dispatch(setFeed(results, friends));
    });
}

export default feedReducer;
