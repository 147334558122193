import React from 'react';

import Svg from '../../components/Svg';

export default function HomeIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m12.8222 4.7179c.3221-.29289.7419-.45519 1.1772-.4552.4354-.00002.8551.16225 1.1773.45512l7.5005 6.81888c.1804.1641.3246.364.4232.587.0986.2229.1496.4641.1496.7079v9.4182.0002c0 .5523-.4477 1-1 1h-16.5c-.55228 0-1-.4477-1-1v-.0002-9.4182c0-.2438.05095-.4849.14957-.7079s.24275-.4229.42314-.5869zm8.4278 8.2243v8.3078h-4.0005v-4.251c0-.5523-.4477-1-1-1h-4.5c-.5523 0-1 .4477-1 1v4.251h-3.9995v-8.3078l7.2495-6.59162zm-8.5005 8.3078h2.5v-3.251h-2.5z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
