/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Image from './Image';

export default function Avatar({
  bg = 'blueberry.10',
  name,
  size = 40,
  extraStyles,
  ...props
}) {
  return (
    <span
      sx={{
        width: size,
        height: size,
        display: 'block',
        bg,
        borderRadius: 'circle',
        position: 'relative',
        '&:after': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          display: 'block',
          boxShadow: 'border.avatar.light',
          borderRadius: 'circle',
        },
        ...extraStyles,
      }}
    >
      <Image
        alt={name}
        borderRadius="circle"
        display="block"
        height={size}
        width={size}
        {...props}
      />
    </span>
  );
}
