/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import Image from '../../../components/Image';
import PrimaryButton from '../../../components/PrimaryButton';
import BlogPageWrap from '../BlogPageWrap';

import { getIsAuthenticated, getToken } from '../../../selectors';
import { SIGN_UP_ROUTE } from '../../../constants';
import { goodreadsImport, goodreadsImport2x } from '../../../assets';
import { beginImport } from '../../../ducks/goodreads';
import { InfoIcon } from '../../../icons';

export default function BlogPost() {
  const [colorMode] = useColorMode();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const isDark = colorMode === 'dark';
  const sx = getStyles({ isDark });

  const startImport = () => {
    if (!isAuthenticated) {
      dispatch(push(SIGN_UP_ROUTE));
      return;
    }
    beginImport({ token, dispatch }).catch(e => {
      toast(
        'Unable to import because of an error. Please wait a bit and try again.',
        { autoClose: 7500 }
      );
      console.error(e);
    });
  };

  return (
    <BlogPageWrap title="Importing your Goodreads reviews, books & shelves / Blog">
      <time>Feb 22, 2021</time>
      <h1>Importing your Goodreads reviews, books &amp; shelves</h1>

      <p>
        Did you know 53% of Oku members have imported their existing data over
        from Goodreads? It couldn&apos;t be easier. If you&apos;re a Goodreads
        user who wants to try out Oku but doesn&apos;t want to lose your
        historical data, this is for you.
      </p>

      <Image
        src={goodreadsImport}
        src2x={goodreadsImport2x}
        alt="Oku profile screenshot & Goodreads logo"
      />

      <p>
        All you need is your Goodreads account and a Oku account. If you
        don&apos;t already have one you can create one during the next step!
      </p>

      <section>
        <span sx={sx.icon}>
          <InfoIcon />
        </span>
        <h3>
          <strong>A note about the Goodreads iOS app</strong>
        </h3>
        <p>
          The Goodreads iOS app interferes with the authorisation process
          between us and goodreads.com. If you&apos;re a user of the Goodreads
          app on iOS you should complete the process on a different device or
          uninstall the app (you can re-install it right after!)
        </p>
      </section>

      <p>
        Just hit the button below and we&apos;ll ask you to connect Oku to your
        Goodreads account. This lets us pull your Goodreads data into our system
        automatically.
      </p>

      <div sx={sx.button}>
        <PrimaryButton as="a" onClick={startImport}>
          Import library from Goodreads ↗
        </PrimaryButton>
      </div>

      <p>
        You can find the import button in your settings page at any time to sync
        the changes since your previous import.
      </p>
    </BlogPageWrap>
  );
}

const getStyles = ({ isDark }) => ({
  button: {
    textAlign: 'center',
    paddingBottom: 's',
    a: {
      color: isDark ? 'olive' : 'milk',
      '&:hover': {
        color: isDark ? 'olive' : 'milk',
      },
    },
  },
  icon: {
    position: 'absolute',
    top: -20,
    left: '50%',
    color: 'blueberry.100',
    transform: 'translateX(-50%)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 40,
    height: 40,
    bg: 'milk',
    borderRadius: 'circle',
    boxShadow: isDark ? 'blog.sectionDark' : 'blog.section',
  },
});
