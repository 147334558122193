/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import CircleButton from './CircleButton';
import { ArrowLeftIcon } from '../icons';

export default function BackButton(props) {
  return (
    <CircleButton {...props}>
      <ArrowLeftIcon />
    </CircleButton>
  );
}
