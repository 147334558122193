/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import { BadgeSnowIcon, BadgeTickIcon } from '../icons';
import { TIERS } from '../constants';

export const BADGE_KINDS = {
  Crew: 'Crew',
  Author: 'Author',
  [TIERS.FREE]: 'Free',
  [TIERS.PREMIUM]: 'Premium',
  [TIERS.SUPPORTER]: 'Supporter',
  'Early Access': 'Early Access',
};

export default function Badge({ kind }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  if (!kind || !Object.keys(BADGE_KINDS).includes(kind)) {
    return null;
  }

  const kindStyles = isDark ? kinds[kind].dark : kinds[kind].default;

  return (
    <h3 sx={{ ...sx.badge, ...kindStyles }}>
      <Icon kind={kind} />
      {BADGE_KINDS[kind]}
    </h3>
  );
}

function Icon({ kind }) {
  switch (kind) {
    case BADGE_KINDS.Author:
      return <BadgeTickIcon />;
    case TIERS.SUPPORTER:
      return <BadgeSnowIcon />;
    default:
      return null;
  }
}

const sx = {
  badge: {
    padding: 'xs',
    lineHeight: 0.8,

    fontSize: 'xxxxs',
    fontWeight: 'bold',
    fontFamily: 'body',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',

    borderRadius: 'badge',
    display: 'flex',
    alignItems: 'center',

    '> svg': {
      marginRight: 6,
    },
  },
};

const kinds = {
  [BADGE_KINDS.Crew]: {
    default: {
      bg: 'badge.smoke.100',
      color: 'blueberry.100',
    },
    dark: {
      bg: 'badge.smoke.100',
      color: 'milk',
    },
  },
  [BADGE_KINDS.Author]: {
    default: {
      bg: 'badge.smoke.100',
      color: 'blueberry.100',
    },
    dark: {
      bg: 'badge.smoke.100',
      color: 'milk',
    },
  },
  [TIERS.FREE]: {
    default: {
      bg: 'blueberry.10',
      color: 'blueberry.100',
    },
    dark: {
      bg: 'blueberry.20',
      color: 'blueberry.100',
    },
  },
  [TIERS.PREMIUM]: {
    default: {
      bg: 'badge.premium.100',
      color: 'milk',
    },
    dark: {
      bg: 'badge.premium.100',
      color: 'milk',
    },
  },
  [TIERS.SUPPORTER]: {
    default: {
      bg: 'badge.supporter.100',
      color: 'milk',
    },
    dark: {
      bg: 'badge.supporter.100',
      color: 'milk',
    },
  },
  'Early Access': {
    default: {
      bg: 'badge.supporter.100',
      color: 'milk',
    },
    dark: {
      bg: 'badge.supporter.100',
      color: 'milk',
    },
  },
};
