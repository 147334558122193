/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { jsx } from 'theme-ui';
import range from 'lodash-es/range';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import mixpanel from 'mixpanel-browser';

import {
  FEATURES,
  getBook,
  getBookHighlights,
  getToken,
  hasFeature,
} from '../../selectors';

import { PRICING_ROUTE } from '../../constants';

import ContentLoader from '../../components/ContentLoader';
import CreateHighlightModal from '../../modals/CreateHighlight';
import { fetchHighlightsForBook } from '../../ducks/highlights';
import { getBookId } from '../../utils';
import theme from '../../theme';

import BookPageWrap from './BookPageWrap';
import BookEmptyState from './BookEmptyState';
import Highlight from '../Highlights/Highlight';

export default function BookReviewsWrapper() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const slug = match.params.slug;
  const token = useSelector(getToken);
  const bookId = getBookId(slug);

  const book = useSelector(getBook(bookId));
  const highlights = useSelector(getBookHighlights(bookId));

  const fetchNeeded = highlights === undefined;
  const [fetched, setFetched] = useState(!fetchNeeded);
  const [isLoading, setIsLoading] = useState(fetchNeeded);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const highlightsEnabled = useSelector(hasFeature(FEATURES.HIGHLIGHTS));

  useEffect(() => {
    if (fetchNeeded && !fetched) {
      setFetched(true);
      setIsLoading(true);
      fetchHighlightsForBook({ bookId, token, dispatch })
        .then(() => {
          setTimeout(() => setIsLoading(false), 600);
        })
        .catch(() => {
          toast('Something went wrong. Please try again!');
          setTimeout(() => setIsLoading(false), 600);
        });
    }

    mixpanel.track('View Book Highlights', {
      bookId,
    });
  }, [bookId]);

  // const fetchNextPage = thread => {
  //   return () => {
  //     if (thread?.nextLink === null) {
  //       return;
  //     }
  //     fetchReviewThread({ thread, token, dispatch }).catch(e => {
  //       // TODO: let user know too
  //       console.error(e);
  //       toast('Something went wrong. Please try again!');
  //     });
  //   };
  // };

  if (isLoading) {
    return (
      <BookPageWrap title="/ Book highlights">
        <Grid>
          {range(4).map((item, index) => (
            <ContentLoader
              key={item}
              sx={{
                width: '100%',
                borderRadius: 'button',
                height: (200 * (index + 1)) / 2,
              }}
            />
          ))}
        </Grid>
      </BookPageWrap>
    );
  }

  if (highlights.length === 0) {
    return (
      <React.Fragment>
        <BookEmptyState
          buttonText="New highlight"
          heading="No highlights just yet"
          onButtonClick={() =>
            highlightsEnabled
              ? setIsModalOpen(true)
              : dispatch(push(PRICING_ROUTE))
          }
          subheading="Maybe you could be the first?"
          title="/ Book highlights"
        />
        {highlightsEnabled && (
          <CreateHighlightModal
            book={book}
            closeModal={() => setIsModalOpen(false)}
            showModal={isModalOpen}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <BookPageWrap title="/ Book highlights">
      <Grid>
        {highlights.map(item => (
          <Highlight
            bookSlug={slug}
            canRemove={item.is_saved}
            canSave={!item.is_saved}
            containsSpoiler={item.contains_spoiler}
            countSaves={item.count_saves}
            id={item.id}
            key={item.id}
            position={item.position}
            text={item.text}
          />
        ))}
      </Grid>
    </BookPageWrap>
  );
}

function Grid({ children }) {
  const columns = {
    [parseInt(theme.breakpoints[0]) - 1]: 1,
    [parseInt(theme.breakpoints[1]) - 1]: 1,
    [parseInt(theme.breakpoints[2]) - 1]: 1,
    [parseInt(theme.breakpoints[3]) - 1]: 1,
    [parseInt(theme.breakpoints[4]) - 1]: 2,
  };
  const gutter = useResponsiveValue([
    theme.space.s,
    theme.space.s,
    theme.space.m,
  ]);
  return (
    <ResponsiveMasonry columnsCountBreakPoints={columns}>
      <Masonry gutter={`${gutter}px`}>{children}</Masonry>
    </ResponsiveMasonry>
  );
}
