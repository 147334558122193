import React from 'react';

import Svg from '../components/Svg';

export default function GiftIcon() {
  return (
    <Svg fill="none">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="m22.25 9.5h-16.5c-.41421 0-.75.33579-.75.75v3c0 .4142.33579.75.75.75h16.5c.4142 0 .75-.3358.75-.75v-3c0-.41421-.3358-.75-.75-.75z" />
        <path d="m21.5 14v6.75c0 .1989-.079.3897-.2197.5303-.1406.1407-.3314.2197-.5303.2197h-13.5c-.19891 0-.38968-.079-.53033-.2197-.14065-.1406-.21967-.3314-.21967-.5303v-6.75" />
        <path d="m14 9.5v12" />
        <path d="m18.2426 8.43933c-1.0606 1.06066-4.2426 1.06066-4.2426 1.06066s0-3.18198 1.0607-4.24264c.422-.42156.9942-.65828 1.5907-.65811s1.1685.23721 1.5903.65901.6589.99384.659 1.59035c.0002.59652-.2365 1.16869-.6581 1.59073z" />
        <path d="m9.75747 8.43933c1.06063 1.06066 4.24263 1.06066 4.24263 1.06066s0-3.18198-1.0607-4.24264c-.422-.42156-.9942-.65828-1.5907-.65811s-1.1685.23721-1.59032.65901c-.4218.4218-.65884.99384-.65901 1.59035-.00017.59652.23654 1.16869.6581 1.59073z" />
      </g>
    </Svg>
  );
}
