/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { toast } from 'react-toastify';

import Heading from '../../components/Heading';
import Text from '../../components/Text';
import SelectableList from '../../components/SelectableList';

import { EyeIcon, EyeClosedIcon } from '../../icons';
import { VISIBILITY } from '../../constants';
import { getVisibility, getToken } from '../../selectors';
import { shouldUpdateVisibility } from '../../ducks/user';

export default function Privacy() {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const visibility = useSelector(getVisibility);

  const savePreference = id => {
    shouldUpdateVisibility({ dispatch, visibility: id, token }).catch(() => {
      toast("That didn't work :(", { autoClose: 3500 });
    });
  };

  const items = [
    {
      id: VISIBILITY.PUBLIC,
      name: 'Public',
      icon: EyeIcon,
      isSelected: visibility === VISIBILITY.PUBLIC,
    },
    {
      id: VISIBILITY.HIDDEN,
      name: 'Hidden',
      icon: EyeClosedIcon,
      isSelected: visibility === VISIBILITY.HIDDEN,
    },
  ];

  return (
    <React.Fragment>
      <Heading variant="h2" as="h2" sx={sx.heading}>
        Privacy
      </Heading>
      <Text variant="body" as="p" sx={sx.explainer}>
        Hidden profiles won&apos;t show up in the public activity feed or
        search, but are still accessible to people that know your username or
        follow you.
      </Text>
      <SelectableList
        items={items}
        onClick={itemId => savePreference(itemId)}
      />
    </React.Fragment>
  );
}

const sx = {
  heading: {
    marginTop: 'xl',
    marginBottom: 's',
    paddingLeft: 'xxs',
  },
  explainer: {
    marginBottom: 'm',
    paddingLeft: 'xxs',
  },
};
