/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Label from './Label';
import Input from './Input';

const InputWithLabel = React.forwardRef((props, ref) => {
  const {
    children,
    sx,
    id,
    label,
    variant = 'input.primary',
    error,
    ...rest
  } = props;
  return (
    <Label error={error} id={id} label={label}>
      <Input
        id={id}
        variant={error ? 'input.error' : variant}
        ref={ref}
        {...rest}
      />
    </Label>
  );
});

export default InputWithLabel;
