/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';

import { setUser } from '../ducks/user';
import { getCachedAuthData } from '../selectors';

const useCachedAuth = dispatch => {
  const isDev = process.env.NODE_ENV === 'development';
  useEffect(() => {
    const storage = window.localStorage;
    if (!storage) {
      return;
    }
    const data = getCachedAuthData();
    if (!data) {
      return;
    }
    if (isDev) {
      // eslint-disable-next-line
      console.info('Using cached auth data:', data);
    }
    dispatch(setUser({ ...data, _stale: true }));
  }, []);
};

export default useCachedAuth;
