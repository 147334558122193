/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Input as ThemeInput } from 'theme-ui';

export const inputStyles = {
  height: '46px',
  lineHeight: '46px',
  paddingY: 0,
  paddingX: 's',
  outline: 'none',
  borderRadius: 'button',
  transition: 'smooth.fast',

  fontSize: 'xs',
  fontFamily: 'body',

  '&::-moz-placeholder': {
    opacity: 0.5,
    color: 'blueberry.100',
    transition: 'smooth.fast',
  },
  '::-webkit-input-placeholder': {
    opacity: 0.5,
    color: 'blueberry.100',
    transition: 'smooth.fast',
  },
  '&:-ms-input-placeholder': {
    opacity: 0.5,
    color: 'blueberry.100',
    transition: 'smooth.fast',
  },
  '&:focus': {
    '&::-moz-placeholder': {
      opacity: 0.7,
    },
    '::-webkit-input-placeholder': {
      opacity: 0.7,
    },
    '&:-ms-input-placeholder': {
      opacity: 0.7,
    },
  },
};

const Input = React.forwardRef(({ sx, disabled, ...props }, ref) => {
  const bg = disabled ? 'blueberry.10' : undefined;
  const color = disabled ? 'blueberry.50' : undefined;
  const borderColor = disabled ? 'blueberry.20' : undefined;
  return (
    <ThemeInput
      {...props}
      disabled={disabled}
      sx={{
        bg,
        color,
        pointerEvents: disabled ? 'none' : 'all',
        '&:hover': {
          borderColor,
        },
        ...inputStyles,
        ...sx,
      }}
      ref={ref}
    />
  );
});

export default Input;
