import React from 'react';

import Svg from '../components/Svg';

export default function BadgeSnow({ ...props }) {
  return (
    <Svg height="8" viewBox="0 0 7 8" width="7" {...props}>
      <path
        clipRule="evenodd"
        d="m3.50002 0c.38897 0 .7043.320751.7043.716418v2.042702l1.73911-1.02135c.33686-.19783.76761-.08043.96209.26223.19449.34266.07907.78081-.25779.97864l-1.73912 1.02136 1.73912 1.02135c.33686.19784.45228.63599.25779.97865-.19448.34266-.62523.46006-.96209.26223l-1.73911-1.02135v2.0427c0 .39567-.31533.71642-.7043.71642-.38898 0-.70431-.32075-.70431-.71642v-2.04272l-1.73914 1.02137c-.33686.19783-.767606.08043-.9620936-.26223-.1944884-.34266-.0790702-.78081.2577926-.97865l1.739121-1.02135-1.739121-1.02136c-.3368628-.19783-.452281-.63598-.2577926-.97864.1944876-.34266.6252336-.46006.9620936-.26223l1.73914 1.02137v-2.042722c0-.395667.31533-.716418.70431-.716418z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
