/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Heading from './Heading';
import Link from './Link';

export default function ListHeading({
  children,
  additionalElements,
  alignItems = 'flex-end',
  to,
}) {
  const sx = {
    container: {
      display: 'flex',
      alignItems,
      justifyContent: 'space-between',
      marginBottom: 'm',
    },
    inline: {
      display: 'inline',
    },
    link: {
      fontSize: 'xs',
    },
  };
  return (
    <div sx={sx.container}>
      <Heading as={to ? RouterLink : 'p'} to={to} variant="h2" sx={sx.inline}>
        {children}
      </Heading>
      {additionalElements}
      {to && (
        <Link as={RouterLink} variant="secondary" to={to} sx={sx.link}>
          See more &rarr;
        </Link>
      )}
    </div>
  );
}
