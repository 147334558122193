/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SwiperSlide } from 'swiper/react';

import FriendReadingCard from '../../components/FriendReadingCard';
import SwiperContainer from '../../components/SwiperContainer';
import SwiperGrid from '../../components/SwiperGrid';
import SwiperHeading from '../../components/SwiperHeading';

import {
  SWIPER_FRIENDS_GRID,
  SWIPER_FRIENDS_NEXT,
  SWIPER_FRIENDS_PREV,
} from '../../constants';

export default function SwiperFriends({ friends, paddingBottom }) {
  if (friends?.length === 0) {
    return null;
  }
  return (
    <div>
      {friends?.length > 0 && (
        <SwiperContainer>
          <SwiperHeading
            heading="What your friends are reading"
            prevClassName={SWIPER_FRIENDS_PREV}
            nextClassName={SWIPER_FRIENDS_NEXT}
          />
          <SwiperGrid
            paddingBottom={paddingBottom}
            gridClassName={SWIPER_FRIENDS_GRID}
            prevClassName={SWIPER_FRIENDS_PREV}
            nextClassName={SWIPER_FRIENDS_NEXT}
          >
            {friends?.map(({ books, who }) => {
              if (books.length === 0) {
                return null;
              }
              return (
                <SwiperSlide key={who.username}>
                  <FriendReadingCard books={books} who={who} />
                </SwiperSlide>
              );
            })}
          </SwiperGrid>
        </SwiperContainer>
      )}
    </div>
  );
}
