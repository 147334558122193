/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import theme from '../theme';
import { PRICING_ROUTE } from '../constants';

export default function NotificationWrapper({ children, showSidebar }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const param = urlParams.get('ref');
  const isPricingPage = window.location.pathname === PRICING_ROUTE;

  if (param === 'producthunt' && !isPricingPage) {
    return (
      <div
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          paddingBottom: 'm',
          paddingLeft: showSidebar ? [0, 0, 0, theme.sizes.sidebar] : 0,
          zIndex: 100,
          width: '90%',
          maxWidth: [320, 320, 360],
        }}
      >
        {children}
      </div>
    );
  }

  return null;
}
