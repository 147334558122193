/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function Tabs({ extraStyle = {}, children }) {
  const sx = {
    container: {
      width: '100%',
      overflow: 'auto',
    },
    nav: {
      minWidth: '100%',
      borderBottom: '1px solid',
      borderColor: 'blueberry.10',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
    },
  };
  return (
    <div sx={sx.container}>
      <nav sx={{ ...sx.nav, ...extraStyle }}>{children}</nav>
    </div>
  );
}
