/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import moment from 'moment';

import {
  getBookSlug,
  getCoverUrl,
  getFeedAction,
  getFeedBook,
  getFeedBookImage,
  getFeedBookTitle,
  getFeedDate,
  getFeedItemAvatar,
  getFeedItemFirstName,
  getFeedItemUsername,
} from '../../selectors';

import { BOOK_ROUTE, PROFILE_ROUTE, getCollectionRoute } from '../../constants';

import BookFeedItem from '../Activity/BookFeedItem';

export default function BooksBlock({ items, isLoading }) {
  return (
    <div sx={sx.container}>
      <div
        className="marquee3k"
        data-pausable="bool"
        data-speed="1"
        sx={sx.marquee}
      >
        <div>
          {!isLoading && (
            <div sx={sx.slider}>
              {items.map((item, index) => {
                const username = getFeedItemUsername(item);
                const firstName = getFeedItemFirstName(item);
                const avatar = getFeedItemAvatar(item);
                const date = getFeedDate(item);
                const when = moment(date).fromNow();
                const action = getFeedAction(item);
                const imageLinks = getFeedBookImage(item);
                const cover = getCoverUrl(imageLinks);
                const title = getFeedBookTitle(item);
                const book = getFeedBook(item);
                const slug = getBookSlug(book);
                const route = getCollectionRoute(username, { ...item.list });
                const listKey = item.list.key;
                return (
                  <div sx={sx.item} key={index}>
                    <BookFeedItem
                      action={action}
                      avatar={avatar}
                      bookRoute={`${BOOK_ROUTE}/${slug}`}
                      collectionRoute={route}
                      cover={cover}
                      date={when}
                      firstName={firstName}
                      listKey={listKey}
                      profileRoute={`${PROFILE_ROUTE}/${username}`}
                      title={title}
                      visibleByDefault
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const sx = {
  marquee: {
    overflow: 'hidden',
    paddingY: 20,
  },
  slider: {
    display: 'flex',
  },
  item: {
    width: ['86vw', '60vw', '48vw', '36w', '24vw'],
    marginX: 'xs',
  },
  container: {
    paddingBottom: [80, 80, 120, 120, 120],
  },
};
