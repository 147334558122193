import React from 'react';

import Svg from '../components/Svg';

export default function BookmarkIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66951 5.5C6.58405 5.5 6.5 5.57353 6.5 5.68057V10.1938L7.71821 9.36266C7.88818 9.2467 8.11182 9.2467 8.28179 9.36266L9.5 10.1938V5.68057C9.5 5.57298 9.41652 5.5 9.33049 5.5H6.66951ZM5.5 5.68057C5.5 5.03587 6.01725 4.5 6.66951 4.5H9.33049C9.98398 4.5 10.5 5.036 10.5 5.68057V10.8013C10.5 10.9849 10.4363 11.2753 10.154 11.4262C9.87023 11.5778 9.5926 11.4675 9.44012 11.3635L8 10.381L6.55988 11.3635C6.40769 11.4673 6.12947 11.5785 5.84508 11.4259C5.5629 11.2745 5.5 10.9838 5.5 10.8013V5.68057Z"
      />
    </Svg>
  );
}
