/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Image from '../../components/Image';
import ModalDialog from '../../components/ModalDialog';
import Text from '../../components/Text';

import CreateCollection from '../../modals/CreateCollection';

import { PlusIcon } from '../../icons';
import { girlFloating, girlFloating2x } from '../../assets';

export default function Collections({ hasNoCollections }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const ctaText = hasNoCollections
    ? 'Create your first collection'
    : 'Create a collection';

  return (
    <React.Fragment>
      <ModalDialog onClose={closeModal} isVisible={isModalOpen}>
        <CreateCollection onClose={closeModal} />
      </ModalDialog>
      <Card onClick={openModal}>
        <div sx={sx.newCollection}>
          <Button as="span" size="icon" sx={sx.button}>
            <PlusIcon />
          </Button>
          <Text variant="h2">{ctaText}</Text>
          <figure sx={sx.image}>
            <Image
              alt="Girl reading a book"
              blendIn
              hasTransition={false}
              maxWidth={350}
              respondToDarkMode
              src={girlFloating}
              src2x={girlFloating2x}
              width="100%"
            />
          </figure>
        </div>
      </Card>
    </React.Fragment>
  );
}

const sx = {
  newCollection: {
    paddingX: 'l',
    paddingTop: 'xl',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    overflow: 'hidden',
    userSelect: 'none',
  },
  button: {
    marginBottom: 's',
  },
  image: {
    img: {
      transform: 'rotate(-132deg)',
      marginBottom: '-65%',
      marginTop: 40,
      display: 'block',
    },
  },
  modal: {
    padding: 'xl',
  },
};
