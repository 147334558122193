/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Text from '../../components/Text';

export default function BookDetail({ label, content }) {
  const sx = getStyles();
  return (
    <div sx={sx.container}>
      <div sx={sx.left}>
        <Heading as="h4" variant="body" sx={sx.label}>
          {label}
        </Heading>
      </div>
      <div sx={sx.flex}>
        <Text as="p" variant="body" sx={sx.text}>
          {content}
        </Text>
      </div>
    </div>
  );
}

const getStyles = () => ({
  container: {
    display: 'flex',
    marginBottom: 's',
  },
  left: {
    minWidth: 100,
  },
  flex: {
    flex: 1,
  },
  label: {
    color: 'blueberry.80',
  },
  text: {
    color: 'blackberry',
  },
});
