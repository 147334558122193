import React from 'react';

import Svg from '../components/Svg';

export default function FacebookIcon({ ...props }) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.04889C16 3.60361 12.4183 0 8 0C3.58172 0 0 3.60361 0 8.04889C0 12.0663 2.92548 15.3962 6.75 16V10.3755H4.71875V8.04889H6.75V6.27562C6.75 4.25837 7.94434 3.1441 9.77172 3.1441C10.647 3.1441 11.5625 3.3013 11.5625 3.3013V5.28208H10.5537C9.55992 5.28208 9.25 5.90253 9.25 6.53905V8.04889H11.4688L11.1141 10.3755H9.25V16C13.0745 15.3962 16 12.0663 16 8.04889Z"
      />
    </Svg>
  );
}
