/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';

import * as Plot from '@observablehq/plot';

import { usePlot } from '../../hooks';
import { colors } from '../../theme/colors';
import { sizes, space } from '../../theme/sizes';
import { fontSizes } from '../../theme/typography';

export default function BookLengthGraph({ data }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const processedData = data || [];

  const maxWidth = useResponsiveValue([
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.desktop,
  ]);

  const spaceXL = useResponsiveValue([space.l, space.l, space.l, space.xl]);
  const realWidth = maxWidth - space.l * 2 - spaceXL * 2 - 2; // acount for spacing on the sides plus borders on each side

  const options = {
    height: 110,
    width: realWidth,
    marginBottom: 20,
    style: {
      fontSize: fontSizes.xxs,
      color: isDark ? colors.modes.dark.blueberry[80] : colors.blueberry[80],
    },
    x: {
      round: true,
      label: null,
      grid: false,
      tickSize: 0,
    },
    color: {
      range: isDark
        ? [
            'rgb(48, 54, 46)',
            'rgb(68, 77, 64)',
            'rgb(100, 116, 93)',
            'rgb(154, 181, 142)',
            'rgb(181, 201, 172)',
          ]
        : [
            'rgb(233, 239, 230)',
            'rgb(218, 229, 214)',
            'rgb(194, 211, 187)',
            'rgb(154, 181, 142)',
            'rgb(43, 88, 79)',
          ],
    },
    marks: [
      Plot.barX(
        processedData,
        Plot.binX(
          { fill: 'count', stroke: 'count' },
          // TODO: threshold should be based on the delta between most & least pages, not number read
          {
            x: {
              thresholds: Math.max(processedData.length / 10, 40),
              value: 'pageCount',
              stroke: 'pageCount',
            },
          }
        )
      ),
    ],
  };

  const ref = usePlot(options);
  return <div ref={ref} />;
}
