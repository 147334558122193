/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import theme from '../theme';

export default function SwiperContainer({ children }) {
  return <div sx={sx.container}>{children}</div>;
}

const sx = {
  container: {
    width: [
      `calc(100% + ${theme.space.s * 2}px)`,
      `calc(100% + ${theme.space.s * 2}px)`,
      '100%',
    ],
    marginLeft: [`-${theme.space.s}px`, `-${theme.space.s}px`, 0],
  },
};
