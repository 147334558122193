import React from 'react';

import Svg from '../components/Svg';

export default function TrashIcon() {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 1C6.75272 1 6.27581 1.19754 5.92418 1.54917C5.57254 1.90081 5.375 2.37772 5.375 2.875V3.25L3.87723 3.25L3.875 3.25L3.87277 3.25L2.75 3.25C2.33579 3.25 2 3.58579 2 4C2 4.41422 2.33579 4.75 2.75 4.75H3.125V14.6875C3.125 15.0356 3.26328 15.3694 3.50942 15.6156C3.75556 15.8617 4.0894 16 4.4375 16H13.4375C13.7856 16 14.1194 15.8617 14.3656 15.6156C14.6117 15.3694 14.75 15.0356 14.75 14.6875V4.75H15.125C15.5392 4.75 15.875 4.41421 15.875 4C15.875 3.58579 15.5392 3.25 15.125 3.25L14.0007 3.25L14 3.25L13.9993 3.25L12.5 3.25V2.875C12.5 2.37772 12.3025 1.90081 11.9508 1.54917C11.5992 1.19754 11.1223 1 10.625 1H7.25ZM11 3.25V2.875C11 2.77554 10.9605 2.68016 10.8902 2.60983C10.8198 2.53951 10.7245 2.5 10.625 2.5H7.25C7.15054 2.5 7.05516 2.53951 6.98484 2.60983C6.91451 2.68016 6.875 2.77554 6.875 2.875V3.25L11 3.25ZM4.625 14.5V4.75L13.25 4.75V14.5H4.625ZM7.25 6.625C7.66421 6.625 8 6.96079 8 7.375V11.875C8 12.2892 7.66421 12.625 7.25 12.625C6.83579 12.625 6.5 12.2892 6.5 11.875V7.375C6.5 6.96079 6.83579 6.625 7.25 6.625ZM11.375 7.375C11.375 6.96079 11.0392 6.625 10.625 6.625C10.2108 6.625 9.875 6.96079 9.875 7.375V11.875C9.875 12.2892 10.2108 12.625 10.625 12.625C11.0392 12.625 11.375 12.2892 11.375 11.875V7.375Z"
      />
    </Svg>
  );
}
