/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';
import { useResponsiveValue } from '@theme-ui/match-media';

import Heading from './Heading';
import Text from './Text';

export default function ProfileReviewMeta({ author, link, subtitle, title }) {
  const showSubtitle = useResponsiveValue([false, false, false, true]);
  return (
    <div sx={sx.container}>
      <RouterLink to={link}>
        <Heading fontFamily="serif" sx={sx.title}>
          {title}
        </Heading>
      </RouterLink>
      {showSubtitle && <Text sx={sx.subtitle}>{subtitle}</Text>}
      <Text sx={sx.author}>{author}</Text>
    </div>
  );
}

const sx = {
  container: {
    flex: [1, 1, 1, 'unset'],
    minWidth: 0,
  },
  title: {
    color: 'blackberry',
    lineHeight: 'heading',
    fontSize: ['m', 'm', 'm', 'l'],
  },
  subtitle: {
    fontSize: 'xs',
    marginY: 'xxs',
  },
  author: {
    fontSize: 'xxs',
  },
};
