/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import CardLandscape from './CardLandscape';
import ListWrap from './ListWrap';

import {
  getBookAuthors,
  getBookSlug,
  getBookSubTitle,
  getBookTitle,
  getRatings,
} from '../selectors';

export default function ListLandscape({ books, route, cap = 999, heading }) {
  const columns = [
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
  ];
  return (
    <ListWrap
      columns={columns}
      heading={heading}
      length={books.length}
      route={route}
    >
      {books.map((book, index) => {
        const { title, subtitle } = book;
        const bookTitle = getBookTitle({ title, subtitle });
        const bookSubtitle = getBookSubTitle({ title, subtitle });
        const authors = getBookAuthors(book.authors);
        const slug = getBookSlug(book);
        const renderableRatings = getRatings(book.ratings);
        if (index < cap) {
          return (
            <CardLandscape
              authors={authors}
              key={book.id}
              ratings={renderableRatings}
              subtitle={bookSubtitle}
              thumbnail={book.imageLinks.thumbnail}
              title={bookTitle}
              slug={slug}
            />
          );
        }
        return null;
      })}
    </ListWrap>
  );
}
