/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Link from './Link';

export default function TabLink({ children, isActive, ...props }) {
  const styles = {
    paddingY: 's',
    marginRight: ['s', 's', 's', 'm'],
    marginBottom: '-1px',
    borderBottom: '3px solid',
    borderColor: isActive ? 'blueberry.100' : 'transparent',
    fontSize: 's',
    cursor: isActive ? 'default' : 'pointer',
  };
  return (
    <Link
      sx={{ ...styles }}
      hasTransition={false}
      variant={isActive ? 'menu.active' : 'menu.default'}
      {...props}
    >
      {children}
    </Link>
  );
}
