import React from 'react';

import Svg from '../components/Svg';

export default function TickIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m22.7164 7.30228c.3853.39564.377 1.02875-.0187 1.41409l-11.2941 11.00003c-.3883.3781-1.0072.3781-1.3954 0l-4.70592-4.5834c-.39564-.3853-.40399-1.0184-.01865-1.4141.38534-.3956 1.01845-.4039 1.41409-.0186l4.00818 3.9038 10.5964-10.32047c.3956-.38534 1.0287-.37699 1.4141.01865z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
