/* eslint-disable no-console */

export const createFetchApi = () => {
  const API_BASE = `/api`;

  window.API_BASE = API_BASE;
  window._fetch = window.fetch;

  window.fetch = (url, options) => {
    if (url[0] === '/') {
      if (!url.startsWith('/api/')) {
        // eslint-disable-next-line
        url = `${window.API_BASE}${url}`;
      }
      const headers = options.headers || {};
      const method = options.method || 'get';
      if (method.toLowerCase() !== 'get') {
        const match = document.cookie.match(/csrftoken=([a-z0-9A-Z]+)/);
        if (match !== null) {
          headers['X-CSRFToken'] = match[1];
        }
      }
      // eslint-disable-next-line
      options.headers = headers;
    }
    return window._fetch(url, options);
  };
};

export const fetchApi = (url, { body, method = 'GET', token }) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  return fetch(url, {
    body: JSON.stringify(body),
    method,
    headers,
  });
};

export const callApi = (url, { body, method = 'GET', token }) => {
  return fetchApi(url, { body, method, token }).then(resp => {
    if (!resp.ok) {
      return Promise.reject(resp);
    }
    if (resp.status === 204) {
      return {};
    }
    return resp.json().catch(e => {
      console.log(e, resp);
      return {};
    });
  });
};
