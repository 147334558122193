/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useResponsiveValue } from '@theme-ui/match-media';

import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import CreateHighlightModal from '../../modals/CreateHighlight';

import CreateHighlightButton from './CreateHighlightButton';
import HighlightsEmptyState from './HighlightsEmptyState';
import HighlightsPageBody from './HighlightsPageBody';
import HighlightsSidebar from './HighlightsSidebar';

import {
  FEATURES,
  getAvatar,
  getHighlightsFromThread,
  getToken,
  getUserHighlights,
  getUsername,
  hasFeature,
} from '../../selectors';

import { PRICING_ROUTE } from '../../constants';
import { fetchHighlightsForUser } from '../../ducks/highlights';

export default function Highlights() {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const dispatch = useDispatch();

  const username = useSelector(getUsername);
  const avatar = useSelector(getAvatar);
  const token = useSelector(getToken);
  const books = useSelector(getUserHighlights(username));
  const highlights = useSelector(
    getHighlightsFromThread(selectedBook?.highlightsThread)
  );
  const highlightsEnabled = useSelector(hasFeature(FEATURES.HIGHLIGHTS));
  const { bookId } = useParams();

  // Fetch highlights when username and token are available
  useEffect(() => {
    if (username && token && highlightsEnabled) {
      fetchHighlightsForUser({ dispatch, token, username }).then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 420);
      });
    }
  }, [username, token]);

  // Set the selected book based on the query param of the book id
  useEffect(() => {
    onSelectBook(books?.items?.filter(({ book }) => book.id === bookId)[0]);
  }, [books, bookId]);

  useEffect(() => {
    if (highlightsEnabled) {
      mixpanel.track('View Your Highlights');
    } else {
      mixpanel.track('View Upsell: Highlights');
    }
  }, []);

  const onSelectBook = book => {
    if (selectedBook === book) {
      setSelectedBook(null);
    } else {
      setSelectedBook(book);
    }
  };

  // Show/hide sidebar and body based on resolution and location
  const isOnDesktop = useResponsiveValue([false, false, false, false, true]);
  const showSidebar = isOnDesktop || (!isOnDesktop && !bookId);
  const showBody = isOnDesktop || (!isOnDesktop && bookId);

  // Some style adjustments based on wether there's a selected book or loading state
  const fakeHeight = use100vh();
  const fallbackHeight = fakeHeight || '100vh';
  const height = ['auto', 'auto', 'auto', 'auto', fallbackHeight];

  if (!highlightsEnabled) {
    return (
      <HighlightsEmptyState
        buttonLink={PRICING_ROUTE}
        buttonText="Find out more"
        showBadge
        heading="Highlights"
        subheading="Keep your highlights handy with a subscription to Oku premium."
        title="Highlights"
      />
    );
  }

  if (books?.items?.length === 0) {
    return (
      <React.Fragment>
        <HighlightsEmptyState
          buttonText="+ New highlight"
          buttonClick={openModal}
          heading="You don't have any highlights"
          subheading="Click on the create new button to create your first highlight!"
          title="Highlights"
        />
        <CreateHighlightModal
          book={selectedBook}
          showModal={showModal}
          closeModal={closeModal}
        />
      </React.Fragment>
    );
  }

  return (
    <Page title="Highlights">
      <PageBody maxWidth="100%" extraStyles={sx.body}>
        {isOnDesktop && (
          <div sx={sx.nav}>
            <CreateHighlightButton
              closeModal={closeModal}
              isLoading={isLoading}
              openModal={openModal}
              selectedBook={selectedBook?.book}
              showModal={showModal}
            />
          </div>
        )}
        <div sx={{ ...sx.inner, height }}>
          {showSidebar && (
            <HighlightsSidebar
              books={books?.items}
              isLoading={isLoading}
              onSelectBook={onSelectBook}
              selectedBook={selectedBook}
              closeModal={closeModal}
              openModal={openModal}
              showModal={showModal}
            />
          )}
          {showBody && (
            <HighlightsPageBody
              avatar={avatar}
              closeModal={closeModal}
              highlights={highlights}
              openModal={openModal}
              selectedBook={selectedBook}
              showModal={showModal}
            />
          )}
        </div>
      </PageBody>
    </Page>
  );
}

const sx = {
  body: {
    paddingX: 0,
  },
  inner: {
    display: 'flex',
  },
  nav: {
    position: 'fixed',
    padding: ['s', 's', 'm'],
    top: 0,
    right: 0,
  },
};
