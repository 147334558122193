/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

import Badge from '../../components/Badge';
import Page from '../../components/Page';
import Image from '../../components/Image';
import PageBody from '../../components/PageBody';
import FullHeight from '../../components/FullHeight';
import Heading from '../../components/Heading';
import Text from '../../components/Text';
import PrimaryButton from '../../components/PrimaryButton';

import { TIERS } from '../../constants';
import { catWindow, catWindow2x } from '../../assets';

export default function HighlightsEmptyState({
  title,
  heading,
  subheading,
  showBadge,
  buttonText,
  buttonLink,
  buttonClick,
}) {
  const buttonProps = buttonLink && {
    as: Link,
    to: buttonLink,
  };
  return (
    <Page title={title}>
      <PageBody maxWidth="100%" extraStyles={sx.body}>
        <FullHeight alignItems="center" justifyContent="center">
          <Image
            alt="Dog goofying around"
            maxWidth={293}
            respondToDarkMode
            src={catWindow}
            src2x={catWindow2x}
          />
          <div sx={sx.heading}>
            <Heading as="h1" fontSize="l" fontFamily="serif" sx={sx.greeting}>
              {heading}
            </Heading>
            {showBadge && <Badge kind={TIERS.PREMIUM} />}
          </div>
          <Text variant="body">{subheading}</Text>
          <PrimaryButton sx={sx.button} onClick={buttonClick} {...buttonProps}>
            {buttonText}
          </PrimaryButton>
        </FullHeight>
      </PageBody>
    </Page>
  );
}

const sx = {
  heading: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'm',
    h3: {
      marginLeft: 'xs',
    },
  },
  button: {
    marginTop: 'm',
  },
};
