/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import AspectImage from '../../components/AspectImage';

import Heading from '../../components/Heading';
import Text from '../../components/Text';
import {
  goalPage,
  goalPage2x,
  goalPageDark,
  goalPageDark2x,
} from '../../assets';

export default function StatsBlock() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const imageArgs = {
    src: isDark ? goalPageDark : goalPage,
    src2x: isDark ? goalPageDark2x : goalPage2x,
  };
  return (
    <div sx={sx.wrapper}>
      <div sx={sx.container}>
        <div sx={sx.long}>
          <Heading as="h2" fontFamily="serif" variant="marketingTight">
            Set a reading goal for the year
          </Heading>
          <Text as="p" color="blueberry.80" variant="body">
            If you&apos;re trying to kickstart a reading habit or just want to
            challenge yourself, motivate yourself to stick to it with a reading
            goal.
          </Text>
        </div>
        <div sx={sx.short}>
          <figure>
            <AspectImage
              aspectWidth={400}
              aspectHeight={303}
              maxWidth={800}
              width="100%"
              {...imageArgs}
            />
          </figure>
        </div>
      </div>
    </div>
  );
}

const sx = {
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    maxWidth: 912,
    paddingX: 'l',
    marginX: 'auto',
    color: 'blueberry.100',

    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: ['center', 'center', 'left', 'left', 'left'],

    p: {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 'm',
    },
  },
  short: {
    marginTop: ['l', 'l', 'l', 0],
    width: ['100%', '100%', '100%', '50%'],
    figure: {
      position: 'relative',
      display: 'block',
      width: ['100%', '100%', '100%', '140%', '160%'],
      transform: ['rotate(0)', 'rotate(0)', 'rotate(0)', 'rotate(1deg)'],
    },
  },
  long: {
    width: ['100%', '100%', '100%', '50%'],
    paddingRight: [0, 0, 0, 'xl'],
  },
};
