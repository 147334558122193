/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import mixpanel from 'mixpanel-browser';

import Button from './Button';

export default function CopyButton({ children, variant, text, eventName }) {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    if (eventName !== undefined) {
      mixpanel.track(eventName);
    }
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={text}>
      <Button variant={variant}>{copied ? 'Copied!' : children}</Button>
    </CopyToClipboard>
  );
}
