/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function ModalContent({ children, extraStyles }) {
  return (
    <main
      sx={{
        flex: 1,
        padding: ['s', 's', 'm'],
        minHeight: 0,
        overflow: 'auto',
        ...extraStyles,
      }}
    >
      {children}
    </main>
  );
}
