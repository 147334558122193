/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from './Avatar';
import ContentLoader from './ContentLoader';
import Link from './Link';
import Text from './Text';

const AVATAR_SIZE = 20;

export default function ByPerson({
  preText,
  bookCount,
  displayName,
  profileRoute,
  avatarUrl,
  hasLink = true,
}) {
  return (
    <Text as="p" variant="subtitle" sx={sx.container}>
      {preText}
      {bookCount && `A collection of ${bookCount}`}
      {displayName && (
        <Fragment>
          {' '}
          by{' '}
          <LinkWrapper to={profileRoute} hasLink={hasLink}>
            {avatarUrl ? (
              <Avatar
                name={displayName}
                src={avatarUrl}
                src2x={avatarUrl}
                size={AVATAR_SIZE}
              />
            ) : (
              <ContentLoader sx={sx.avatarLoader} />
            )}
          </LinkWrapper>
          <LinkWrapper to={profileRoute} hasLink={hasLink} hasUnderline>
            {displayName}
          </LinkWrapper>
        </Fragment>
      )}
    </Text>
  );
}

function LinkWrapper({ hasLink, children, hasUnderline, to }) {
  const styles = !hasUnderline ? sx.avatar : undefined;
  if (hasLink) {
    return (
      <Link
        as={RouterLink}
        hasUnderline={hasUnderline}
        sx={styles}
        to={to}
        variant="body"
      >
        {children}
      </Link>
    );
  }
  return (
    <Text as="span" variant="subtitle" sx={styles}>
      {children}
    </Text>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  avatar: {
    marginX: 'xxs',
  },
  avatarLoader: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: 'circle',
  },
};
