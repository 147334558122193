import React from 'react';

import Svg from '../../components/Svg';

export default function HighlightsIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14 8c-3.3137 0-6 2.6863-6 6 0 1.9798.95886 3.7356 2.4375 4.8284v-3.1409c0-.3481.1383-.6819.3844-.9281.2015-.2015.4618-.3307.7406-.3709v-2.6385c0-.2841.1605-.5438.4146-.6708l3.375-1.68752c.2325-.11625.5086-.10382.7297.03283.2211.13666.3557.37806.3557.63799v4.326c.2788.0402.5391.1694.7406.3709.2461.2462.3844.58.3844.9281v3.1409c1.4786-1.0928 2.4375-2.8486 2.4375-4.8284 0-3.3137-2.6863-6-6-6zm3.1399 12.8131c2.5739-1.1882 4.3601-3.7921 4.3601-6.8131 0-4.14214-3.3579-7.5-7.5-7.5-4.14214 0-7.5 3.35786-7.5 7.5 0 3.021 1.78617 5.6249 4.3601 6.8131.0099.0048.0199.0094.03.0138.9474.4322 2.0005.6731 3.1099.6731s2.1625-.2409 3.1099-.6731c.0101-.0044.0201-.009.03-.0138zm-1.0774-1.177v-3.7611h-.3738c-.0004 0-.0008 0-.0012 0s-.0008 0-.0012 0h-3.3726c-.0004 0-.0008 0-.0012 0s-.0008 0-.0012 0h-.3738v3.7611c.6431.2354 1.3378.3639 2.0625.3639s1.4194-.1285 2.0625-.3639zm-3-5.2611h1.875v-3.099l-1.875.9375z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
