/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { darken } from '@theme-ui/color';

import BookCover from './BookCover';
import { hasTouch } from '../utils';

export default function BookStack({ books, asBlock, hasHover = true }) {
  return (
    <Stack items={books.length} asBlock={asBlock} hasHover={hasHover}>
      {books
        .slice(0, 3)
        .reverse() // flip it  around to most recent first
        .map(book => (
          <span key={book.title}>
            <BookCover
              key={book.title}
              thumbnail={book.imageLinks.thumbnail}
              thumbnail2x={book.imageLinks.thumbnail}
              title={book.title}
              width="book"
              withAspectRatio
              visibleByDefault
            />
          </span>
        ))}
    </Stack>
  );
}

const Stack = ({ children, asBlock, items, hasHover }) => {
  // When the stack is supposed to look like a block
  const blockStyles = asBlock && {
    paddingX: 'l',
    borderRadius: 'block',
    cursor: 'pointer',
    flex: 1,
    willChange: 'transform',
    backfaceVisibility: 'hidden',
  };

  const blockHoverStyles = asBlock && {
    bg: darken('blueberry.5', 0.02),
  };

  const transitionStyles = !asBlock && {
    transition: 'smooth.medium',
  };

  // 3 items in the stack
  const threeItemStyles = items > 2 && {
    paddingY: asBlock ? 'xxl' : 'l',

    '> span': {
      willChange: 'transform',
      backfaceVisibility: 'hidden',
    },

    '> span:first-of-type': {
      display: 'block',
      transform: 'translate3d(50%, -10%, 0) rotate(-10deg)',
      ...transitionStyles,
    },

    '> span:last-of-type': {
      display: 'block',
      transform: 'translate3d(-50%, 10%, 0) rotate(10deg)',
      ...transitionStyles,
    },
  };

  const threeItemHoverStyles = items > 2 && {
    '> span:first-of-type': {
      transform: 'translate3d(20%, -10%, 0) rotate(-15deg)',
    },

    '> span:last-of-type': {
      transform: 'translate3d(-20%, 10%, 0) rotate(10deg)',
    },
  };

  // 2 items in the stack
  const twoItemStyles = items === 2 && {
    paddingY: asBlock ? 'xxl' : 'l',

    '> span': {
      willChange: 'transform',
      backfaceVisibility: 'hidden',
    },

    '> span:first-of-type': {
      display: 'block',
      transform: 'translate3d(20%, -10%, 0) rotate(-10deg)',
      ...transitionStyles,
    },

    '> span:last-of-type': {
      display: 'block',
      transform: 'translate3d(-20%, 10%, 0) rotate(10deg)',
      ...transitionStyles,
    },
  };

  const twoItemHoverStyles = items === 2 && {
    '> span:first-of-type': {
      transform: 'translate3d(0, -10%, 0) rotate(-15deg)',
    },

    '> span:last-of-type': {
      transform: 'translate3d(0, 10%, 0) rotate(10deg)',
    },
  };

  return (
    <div
      sx={{
        ...blockStyles,
        ...twoItemStyles,
        ...threeItemStyles,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'smooth.medium',

        '&:hover': !hasTouch() &&
          hasHover && {
            ...blockHoverStyles,
            ...twoItemHoverStyles,
            ...threeItemHoverStyles,
            transform: 'translate3d(0, -4px, 0) scale(1.05)',
          },
      }}
    >
      {children}
    </div>
  );
};
