import { getCollectionItemsThreadId } from './lists';
import { getThreadById, getThreadItems } from './threads';

export const getFriendsThreadId = () => `dash:friends`;

export const getFriendsReading = state => {
  const friendsThread = getThreadById(getFriendsThreadId())(state);
  const friends = getThreadItems(friendsThread);

  return friends.map(item => {
    const listId = item.listId;
    const tid = getCollectionItemsThreadId(listId);
    const thread = getThreadById(tid)(state);
    const books = getThreadItems(thread);
    return {
      ...item,
      books,
    };
  });
};
