/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BookPageWrap from './BookPageWrap';
import BookInfo from './BookInfo';

export default function BookInfoPage() {
  return (
    <BookPageWrap title="/ Book">
      <BookInfo />
    </BookPageWrap>
  );
}
