import React from 'react';

import Svg from '../components/Svg';

export default function RainbowIcon(props) {
  return (
    <Svg fill="none" height="18" viewBox="0 0 18 18" width="18" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="m12.375 13v-1.125c0-.8951-.3556-1.7536-.9885-2.38649-.6329-.63293-1.49139-.98851-2.3865-.98851s-1.75355.35558-2.38649.98851c-.63293.63289-.98851 1.49139-.98851 2.38649v1.125" />
        <path d="m14.625 13v-1.125c0-1.4918-.5926-2.92258-1.6475-3.97748-1.0549-1.05489-2.4857-1.64752-3.9775-1.64752-1.49184 0-2.92258.59263-3.97748 1.64752-1.05489 1.0549-1.64752 2.48568-1.64752 3.97748v1.125" />
        <path d="m16.875 13v-1.125c0-2.08858-.8297-4.09162-2.3065-5.56847-1.4769-1.47684-3.4799-2.30653-5.5685-2.30653-2.08858 0-4.09162.82969-5.56847 2.30653-1.47684 1.47685-2.30653 3.47989-2.30653 5.56847v1.125" />
      </g>
    </Svg>
  );
}
