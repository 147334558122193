/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React from 'react';

import PrimaryButton from '../../components/PrimaryButton';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Link from '../../components/Link';
import Text from '../../components/Text';

import {
  getSupportEmailLink,
  BLOG_ROUTE,
  PRICING_ROUTE,
  PRIVACY_ROUTE,
  SIGN_UP_ROUTE,
  TWITTER_LINK,
  TERMS_ROUTE,
  FACEBOOK_LINK,
  SEARCH_ROUTE,
} from '../../constants';

import {
  bookOneHome,
  bookOneHome2x,
  bookTwoHome,
  bookTwoHome2x,
  bookThreeHome,
  bookThreeHome2x,
  fujiHome,
  fujiHome2x,
} from '../../assets';

import Wave from './Wave';

export default function FooterBlock() {
  const location = useLocation();
  return (
    <React.Fragment>
      <footer sx={sx.container}>
        <Wave color="milk" />
        <figure sx={sx.bookOne}>
          <Image
            alt="Book floating around"
            width={92}
            height={105}
            respondToDarkMode
            src={bookOneHome}
            src2x={bookOneHome2x}
          />
        </figure>
        <figure sx={sx.bookTwo}>
          <Image
            alt="Book floating around"
            width={110}
            height={120}
            respondToDarkMode
            src={bookTwoHome}
            src2x={bookTwoHome2x}
          />
        </figure>
        <figure sx={sx.bookThree}>
          <Image
            alt="Book floating around"
            width={120}
            height={100}
            respondToDarkMode
            src={bookThreeHome}
            src2x={bookThreeHome2x}
          />
        </figure>
        <div sx={sx.copy}>
          <Heading as="h2" fontFamily="serif" variant="marketing">
            Ready to join us?
          </Heading>
          <Text as="p" color="blueberry.80" variant="body">
            Oku is the companion app for every book.{' '}
            <br sx={{ display: ['none', 'none', 'block'] }} />
            Become a member for free to track, rate & review your favorite books
            with a community of others.
          </Text>
          <PrimaryButton
            as={RouterLink}
            to={{ pathname: SIGN_UP_ROUTE, search: location.search }}
            size="l"
            sx={sx.copy.button}
          >
            Create a free account
          </PrimaryButton>
        </div>
        <div sx={sx.links}>
          <figure sx={sx.fuji}>
            <Image
              alt="Dog tail swinging"
              width={270}
              height={240}
              respondToDarkMode
              src={fujiHome}
              src2x={fujiHome2x}
            />
          </figure>
          <div sx={sx.linksInner}>
            <ul>
              <Link
                as={RouterLink}
                to={{ pathname: SEARCH_ROUTE, search: location.search }}
                sx={sx.link}
                variant="primary"
              >
                Explore
              </Link>
              <Link
                as={RouterLink}
                to={{ pathname: BLOG_ROUTE, search: location.search }}
                sx={sx.link}
                variant="primary"
              >
                Blog
              </Link>
              <Link
                as={RouterLink}
                to={{ pathname: PRICING_ROUTE, search: location.search }}
                sx={sx.link}
                variant="primary"
              >
                Pricing
              </Link>
              <Link
                as={RouterLink}
                to={{ pathname: PRIVACY_ROUTE, search: location.search }}
                sx={sx.link}
                variant="primary"
              >
                Privacy
              </Link>
              <Link
                as={RouterLink}
                to={{ pathname: TERMS_ROUTE, search: location.search }}
                sx={sx.link}
                variant="primary"
              >
                Terms
              </Link>
              <Link
                href={getSupportEmailLink()}
                sx={sx.link}
                target="_blank"
                variant="primary"
              >
                Contact
              </Link>
              <Link
                href={TWITTER_LINK}
                sx={sx.link}
                target="_blank"
                variant="primary"
              >
                Twitter
              </Link>
              <Link
                href={FACEBOOK_LINK}
                sx={sx.link}
                target="_blank"
                variant="primary"
              >
                Facebook
              </Link>
            </ul>
            <Text variant="marketingSub">Copyright &copy; 2022 Readng BV.</Text>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

const sx = {
  container: {
    bg: 'book',
    position: 'relative',
    overflow: 'hidden',
    svg: {
      width: '100%',
      height: 'auto',
    },
  },
  copy: {
    maxWidth: [
      '100%',
      'maxWidth.mobile',
      'maxWidth.phablet',
      'maxWidth.tablet',
      'maxWidth.notebook',
      'maxWidth.laptop',
    ],
    marginX: 'auto',
    color: 'blueberry.100',
    textAlign: 'center',
    paddingY: [140, 140, 190, 190, 190],
    paddingX: 'l',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    borderBottom: '1px solid',
    borderColor: 'blueberry.10',

    button: {
      marginTop: 'l',
      borderRadius: 'button',
      paddingX: 'xl',
    },

    '>p': {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 's',
      maxWidth: 500,
    },
  },
  links: {
    maxWidth: [
      '100%',
      'maxWidth.mobile',
      'maxWidth.phablet',
      'maxWidth.tablet',
      'maxWidth.notebook',
      'maxWidth.laptop',
    ],
    marginX: 'auto',
    position: 'relative',
  },
  linksInner: {
    paddingX: 'm',
    width: '100%',
    height: ['auto', 'auto', 'auto', 'auto', 80],
    paddingY: ['m', 'm', 'm', 'm', 0],
    display: ['block', 'block', 'block', 'block', 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',

    bg: 'book',
    position: 'relative',

    ul: {
      display: 'flex',
      marginBottom: ['xs', 'xs', 'xs', 'xs', 0],
      flexWrap: 'wrap',

      a: {
        fontSize: [13, 13, 's', 's', 's'],
        marginRight: ['s', 's', 'm', 'm', 'm'],
        paddingBottom: ['s', 's', 's', 's', 0],
      },
    },
  },
  bookOne: {
    display: 'block',
    position: 'absolute',
    top: [0, 0, 30, 30, 30],
    left: ['30vw', '30vw', '14vw', '14vw', '14vw'],
  },
  bookTwo: {
    display: 'block',
    position: 'absolute',
    bottom: 100,
    left: ['-8vw', '-8vw', '7vw', '7vw', '7vw'],
  },
  bookThree: {
    position: 'absolute',
    top: 200,
    right: '7vw',
    display: ['none', 'none', 'block', 'block', 'block'],
  },
  fuji: {
    display: 'block',
    position: 'absolute',
    bottom: 10,
    right: -170,
  },
};
