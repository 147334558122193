/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import BookCover from './BookCover';
import BookReviewBody from './BookReviewBody';
import BookReviewHeaderDesktop from './BookReviewHeaderDesktop';
import Link from './Link';
import ModalDialog from './ModalDialog';
import ProfileReviewMeta from './ProfileReviewMeta';

import DeleteReviewModal from '../modals/DeleteReview';
import ReviewBookModal from '../modals/ReviewBook';

export default function ProfileReviewBlock({
  author,
  authorUsername,
  bookAuthor,
  bookId,
  bookSlug,
  bookLink,
  bookSubtitle,
  bookThumbnail,
  bookTitle,
  date,
  hasSpoilers,
  id,
  ownReview,
  recommended,
  review,
  tags,
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Edit modal
  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  // Delete modal
  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <React.Fragment>
      <div sx={sx.container}>
        <div sx={sx.headerWrap}>
          <div sx={sx.cover}>
            <Link as={RouterLink} to={bookLink}>
              <BookCover
                isRaised
                thumbnail={bookThumbnail}
                thumbnail2x={bookThumbnail}
                title={bookTitle}
                visibleByDefault
                width={[60, 60, 60, 100]}
              />
            </Link>
          </div>
          <ProfileReviewMeta
            author={bookAuthor}
            link={bookLink}
            subtitle={bookSubtitle}
            title={bookTitle}
          />
        </div>
        <BookReviewHeaderDesktop
          author={author}
          authorUsername={authorUsername}
          bookSlug={bookSlug}
          bookThumbnail={bookThumbnail}
          bookTitle={bookTitle}
          date={date}
          id={id}
          openDeleteModal={openDeleteModal}
          openEditModal={openEditModal}
          ownReview={ownReview}
          recommended={recommended}
        />
        <BookReviewBody hasSpoilers={hasSpoilers} review={review} tags={tags} />
      </div>
      <ModalDialog isVisible={isDeleteModalOpen} onClose={closeDeleteModal}>
        <DeleteReviewModal
          bookId={bookId}
          onClose={closeDeleteModal}
          reviewId={id}
        />
      </ModalDialog>
      <ModalDialog isVisible={isEditModalOpen} onClose={closeEditModal}>
        <ReviewBookModal
          bookId={bookId}
          hasSpoilers={hasSpoilers}
          onClose={closeEditModal}
          review={review}
          reviewId={id}
        />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  container: {
    position: 'relative',
    paddingLeft: [0, 0, 0, 132],
    marginBottom: ['l', 'l', 'l', 'xl'],
    '&:last-child': {
      marginBottom: ['s', 's', 'l', 'xl'],
    },
  },
  headerWrap: {
    display: ['flex', 'flex', 'flex', 'block'],
    alignItems: 'center',
    marginBottom: 's',
  },
  cover: {
    position: ['relative', 'relative', 'relative', 'absolute'],
    top: 0,
    left: 0,
    marginRight: ['s', 's', 's', 0],
  },
};
