/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react';
import range from 'lodash-es/range';
import { jsx, Grid } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import forEach from 'lodash-es/forEach';
import moment from 'moment';

import Button from '../components/Button';
import GhostButton from '../components/GhostButton';
import HelpIcon from '../components/HelpIcon';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import ModalImage from '../components/ModalImage';
import PrimaryButton from '../components/PrimaryButton';
import Select from '../components/Select';
import Text from '../components/Text';
import ToggleButton from '../components/ToggleButton';

import ReviewBookModal from './ReviewBook';

import { nthify } from '../utils';
import { getReadList, getToken, getSelectedBookTags } from '../selectors';
import { finished, finished2x } from '../assets';
import { recommendBook, removeRecommendation } from '../ducks/books';

const TAGS = [
  'Easy to read',
  'Too Long',
  'Short but Sweet',
  'Overhyped',
  'Melancholy',
  'Reflective',
  'Insightful',
  'Uplifting',
  'Lighthearted',
  'Funny',
  'Adventurous',
  'Tense',
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function Recommend({
  bookId,
  context = 'default',
  dateAdded,
  isActive,
  onClose,
  onUpdateLinst,
  recommended,
  thumbnail,
  title,
}) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const readList = useSelector(getReadList);
  const preSelectedTags = new Set(useSelector(getSelectedBookTags(bookId)));
  const ctx = Recommend.contexts[context] || Recommend.contexts.default;

  const [selectedTags, setSelectedTags] = useState(preSelectedTags);
  const [recommend, setRecommend] = useState(recommended);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [dateError, setDateError] = useState('');

  const today = new Date();
  const [readDate, setReadDate] = useState({
    day: moment(dateAdded || today).date(),
    month: moment(dateAdded || today).month(),
    year: moment(dateAdded || today).year(),
  });

  const onUpdateDay = event => {
    const { value } = event.target;
    setReadDate({
      ...readDate,
      day: parseInt(value, 10),
    });
  };

  const onUpdateMonth = event => {
    const { value } = event.target;
    setReadDate({
      ...readDate,
      month: parseInt(value, 10),
    });
  };

  const onUpdateYear = event => {
    const { value } = event.target;
    setReadDate({
      ...readDate,
      year: parseInt(value, 10),
    });
  };

  const onGoBack = () => {
    setShowReviewModal(false);
  };

  const onSubmit = event => {
    event.preventDefault();
    setShowReviewModal(true);
    setDateError('');

    const updatedReadDate = moment(readDate).format('YYYY-MM-DD');
    const difference = moment().diff(updatedReadDate); // check if date is in the future

    if (difference < 0) {
      setDateError('Are you a time traveller?');
      return;
    }
    if (!isActive) {
      onUpdateLinst();
    }

    recommendBook({
      dispatch,
      token,
      bookId,
      recommend,
      readDate: updatedReadDate,
      tags: Array.from(selectedTags),
    }).catch(() => {
      toast("That didn't work", { autoClose: 3500 });
    });

    if (!dateAdded) {
      setShowReviewModal(true);
      return null;
    }

    onClose();
  };

  const onRemove = () => {
    if (isActive) {
      onUpdateLinst();
    }
    removeRecommendation({ token, bookId });
    onClose();
  };

  const isRecommending = () => {
    setRecommend(true);
  };

  const isNotRecommending = () => {
    setRecommend(false);
  };

  const tagLimit = 3;
  const toggleTag = tag => {
    return () => {
      const newTags = new Set(selectedTags);
      if (newTags.has(tag)) {
        newTags.delete(tag);
      } else {
        if (selectedTags.size >= tagLimit) {
          return;
        }
        newTags.add(tag);
      }
      setSelectedTags(newTags);
    };
  };

  return (
    <React.Fragment>
      {showReviewModal ? (
        <ReviewBookModal
          onClose={onClose}
          onGoBack={onGoBack}
          withShare
          thumbnail={thumbnail}
          title={title}
        />
      ) : (
        <form onSubmit={onSubmit}>
          <ModalBody maxHeight={776}>
            <ModalImage
              alt="Man floating with books and dog around him"
              height={122}
              src={finished}
              src2x={finished2x}
              width={480}
            />
            <ModalHeading extraStyles={sx.heading}>
              {ctx.heading}
              <Text as="p" variant="subheading" sx={sx.explainer}>
                {ctx.subheading(readList)}
              </Text>
            </ModalHeading>
            <ModalContent>
              <Text
                as="p"
                variant="subheading"
                sx={{ ...sx.label, ...sx.labelExtra }}
              >
                Add to your recommendations? (Optional){' '}
                <div sx={sx.helperTooltip}>
                  <HelpIcon
                    position="bottomRight"
                    content="Choosing 'Yes' will add this book to your recommendations collection and contribute towards the book's overall rating, whereas 'No' will take the rating down."
                  />
                </div>
              </Text>
              <Grid gap="s" columns={2}>
                <ToggleButton
                  isSelected={recommend === true}
                  onClick={isRecommending}
                  title="Yes!"
                />
                <ToggleButton
                  isSelected={recommend === false}
                  onClick={isNotRecommending}
                  title="Nope"
                />
              </Grid>
              <Text as="p" variant="subheading" sx={sx.label}>
                Which descriptions apply to this book?
                <span>
                  {selectedTags.size}/{tagLimit}
                </span>
              </Text>
              <Grid gap="xs" columns={[3, 3, 4, 4]}>
                {TAGS.map(tag => {
                  return (
                    <ToggleButton
                      size="s"
                      title={tag}
                      key={tag}
                      onClick={toggleTag(tag)}
                      isSelected={selectedTags.has(tag)}
                    />
                  );
                })}
              </Grid>
              <Text as="p" variant="subheading" sx={sx.label}>
                Read date
                {dateError && (
                  <Text as="span" color="strawberry.100">
                    {dateError}
                  </Text>
                )}
              </Text>
              <Grid
                gap="xs"
                columns={['60px auto auto', '60px auto auto', 3, 3]}
              >
                <Select
                  onChange={onUpdateDay}
                  value={readDate.day}
                  variant={dateError ? 'input.error' : 'input.primary'}
                >
                  {range(1, 32).map(day => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </Select>
                <Select
                  onChange={onUpdateMonth}
                  value={readDate.month}
                  variant={dateError ? 'input.error' : 'input.primary'}
                >
                  {MONTHS.map((month, index) => (
                    <option key={month} value={index}>
                      {month}
                    </option>
                  ))}
                </Select>
                <Select
                  onChange={onUpdateYear}
                  value={readDate.year}
                  variant={dateError ? 'input.error' : 'input.primary'}
                >
                  {range(1970, moment().year() + 1).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </Grid>
            </ModalContent>
            <ModalFooter>
              {isActive ? (
                <Button
                  onClick={onRemove}
                  type="reset"
                  variant="strawberry.default"
                >
                  I haven&apos;t read this
                </Button>
              ) : (
                <GhostButton type="reset" onClick={onClose}>
                  Cancel
                </GhostButton>
              )}
              <PrimaryButton type="submit">
                {dateAdded ? 'Save' : 'Next'}
              </PrimaryButton>
            </ModalFooter>
          </ModalBody>
        </form>
      )}
    </React.Fragment>
  );
}

Recommend.contexts = {
  default: {
    heading: 'Good job!',
    subheading: readList => {
      let readBooks = 1;
      const cutoff = moment().startOf('year');
      forEach(readList.books, book => {
        const readDate = moment(book.addedAt);
        if (readDate.isBefore(cutoff)) {
          return false;
        }
        readBooks++;
      });
      return (
        <Fragment>
          That was your <strong>{nthify(readBooks)} book</strong> of the year.
        </Fragment>
      );
    },
  },

  update: {
    heading: 'Update your rating',
    subheading: () => 'Changed your mind?',
  },
};

const sx = {
  heading: {
    paddingTop: 's',
  },
  explainer: {
    marginTop: 'xs',
    fontSize: ['xs', 'xs', 'm'],
    strong: {
      fontWeight: 'button',
      color: 'blackberry',
    },
  },
  label: {
    fontSize: 'xs',
    marginTop: ['s', 's', 'm'],
    marginBottom: 's',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  labelExtra: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  helperTooltip: {
    marginLeft: 'xs',
  },
};
