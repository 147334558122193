import React from 'react';

import Svg from '../../components/Svg';

export default function PlusSmallIcon(props) {
  return (
    <Svg height="20" viewBox="0 0 20 20" width="20" {...props}>
      <path
        clipRule="evenodd"
        d="m10.6349 5.63492c0-.35066-.2842-.63492-.6349-.63492-.35066 0-.63492.28426-.63492.63492v3.73016h-3.73016c-.35066 0-.63492.28426-.63492.63492 0 .3507.28426.6349.63492.6349h3.73016v3.7302c0 .3506.28426.6349.63492.6349.3507 0 .6349-.2843.6349-.6349v-3.7302h3.7302c.3506 0 .6349-.2842.6349-.6349 0-.35066-.2843-.63492-.6349-.63492h-3.7302z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
