/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from './Avatar';
import Text from './Text';
import Link from './Link';

import { getProfileDisplayName } from '../selectors';
import { getProfileRoute } from '../constants';

export default function FloatingFriend({ who }) {
  const user = { details: who }; // otherwise the selector won't work
  const name = getProfileDisplayName(user);
  const profileRoute = getProfileRoute(who.username);
  return (
    <div sx={sx.user}>
      <Link as={RouterLink} to={profileRoute} sx={sx.link}>
        <div sx={sx.avatar}>
          <Avatar name={name} size={40} src={who.image} src2x={who.image} />
        </div>
        <Text as="p" variant="caption" sx={sx.username}>
          {name}
        </Text>
      </Link>
    </div>
  );
}

const sx = {
  user: {
    position: 'absolute',
    bottom: '-43px',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    display: 'block',
  },
  avatar: {
    display: 'block',
    border: '4px solid',
    borderColor: 'milk',
    borderRadius: 'circle',
  },
  username: {
    marginTop: 'xs',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};
