/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';
/* eslint-disable */
import {
  //   installAndroid,
  //   installAndroid2x,
  //   installIos,
  //   installIos2x,
  highlightsPage,
  highlightsPage2x,
  highlightsPageDark,
  highlightsPageDark2x,
  graphsPreview,
  graphsPreview2x,
  graphsPreviewDark,
  graphsPreviewDark2x,
} from '../../../assets';

export default function BlogPost() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const images = {
    highlights: {
      src: isDark ? highlightsPageDark : highlightsPage,
      src2x: isDark ? highlightsPageDark2x : highlightsPage2x,
    },
    stats: {
      src: isDark ? graphsPreviewDark : graphsPreview,
      src2x: isDark ? graphsPreviewDark2x : graphsPreview2x,
    },
  };

  return (
    <BlogPageWrap title="We've dropped our prices / Blog">
      <time>Mar 11, 2022</time>
      <h1>We&apos;ve dropped our prices</h1>

      <p>
        Oku premium is better value than ever!
        <br />
        Now you can gain access to your reading statistics, the ability to save
        and share highlights from the books you read, and set yourself an annual
        reading goal from as little as $1 per week!
      </p>

      <p>
        Head over to our{' '}
        <Link href="https://oku.club/pricing">pricing page</Link> to get your
        hands on all this and more.
      </p>

      <Image
        {...images.highlights}
        alt="A preview of Oku's highlights feature"
      />
      <Image {...images.stats} alt="A preview of Oku's reading stats feature" />

      {/* 
      <p>
        Using Safari, open Oku, tap the share icon and then tap &apos;add to
        homescreen&apos;. That&apos;s it!
      </p>

      <h2>Android devices</h2>

      <Image
        src={installAndroid}
        src2x={installAndroid2x}
        alt="How to install Oku on an Android device"
      />

      <p>
        Open Oku in Chrome and tap the three dots in the top-right hand corner.
        From there you can select &apos;add to homescreen&apos; in the menu that
        appears. You&apos;re done!
      </p> */}
    </BlogPageWrap>
  );
}
