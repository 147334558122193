export const colors = {
  milk: '#FFFFFF',
  book: '#F6F6F6',
  candy: '#E32020',
  olive: '#131314',
  blackberry: '#000000',
  highlight: '#F8DF80',

  blueberry: {
    // % opacity when placed on milk
    100: '#131314',
    80: '#46474E',
    50: '#8C8C90',
    20: '#D1D1D3',
    10: '#E8E8E9',
    5: '#F3F4F4',
    2: '#FAFAFB',
  },
  strawberry: {
    // % opacity when placed on milk
    100: '#EA4E46',
    15: '#FCE5E3',
    7: '#FDF2F2',
    5: '#FEF6F5',
  },
  banana: {
    // % opacity when placed on milk
    100: '#F1B205',
    15: '#FDF3DA',
    7: '#FEFAEE',
    5: '#FEFBF3',
  },
  kiwi: {
    // % opacity when placed on milk
    100: '#349E54',
    15: '#E1F0E5',
    7: '#F1F8F3',
    5: '#F5FAF6',
  },
  dragonfruit: {
    // % opacity when placed on milk
    100: '#FD8067',
    15: '#FFECE8',
    7: '#FFF6F4',
    5: '#FFF9F7',
  },
  facebook: {
    // % opacity when placed on milk
    100: '#1877F2',
    15: '#DDEBFD',
    7: '#EFF5FE',
    5: '#F3F8FE',
  },
  twitter: {
    // % opacity when placed on milk
    100: '#00AAEC',
    15: '#D9F2FC',
    7: '#EDF9FE',
    5: '#F2FAFE',
  },

  badge: {
    smoke: {
      100: '#EFEAE8',
    },
    premium: {
      100: '#6F9965',
      13: '#ECF2EB',
    },
    supporter: {
      100: '#BF90AC',
      13: '#F7F1F4',
    },
  },

  modes: {
    dark: {
      milk: '#131314',
      book: '#000000', // blackberry
      blackberry: '#E0E1E4', // modes.dark.blueberry.100
      candy: '#E32020',
      olive: '#E0E1E4',
      highlight: '#55460E',

      blueberry: {
        // % opacity when placed on modes.dark.milk
        100: '#C1BECB',
        80: '#9E9CA6',
        50: '#6A686F',
        20: '#363539',
        10: '#242426',
        5: '#1C1C1D',
        2: '#1C1C1D',
      },
      strawberry: {
        // % opacity when placed on modes.dark.milk
        100: '#EA4E46',
        15: '#3A2325',
        7: '#291F22',
        5: '#251E21',
      },
      banana: {
        // % opacity when placed on modes.dark.milk
        100: '#F1B205',
        15: '#3B321B',
        7: '#2A261D',
        5: '#26231E',
      },
      kiwi: {
        // % opacity when placed on modes.dark.milk
        100: '#349E54',
        15: '#1F2F27',
        7: '#1D2423',
        5: '#1C2222',
      },
      dragonfruit: {
        // % opacity when placed on modes.dark.milk
        100: '#FD8067',
        15: '#3D2A2A',
        7: '#2B2224',
        5: '#262023',
      },
      facebook: {
        // % opacity when placed on milk
        100: '#1877F2',
        15: '#1B293E',
        7: '#1B212E',
        5: '#1B2029',
      },
      twitter: {
        // % opacity when placed on milk
        100: '#00AAEC',
        15: '#17303D',
        7: '#19252E',
        5: '#1A2229',
      },

      badge: {
        smoke: {
          100: '#EFEAE8',
        },
        premium: {
          100: '#6F9965',
          13: '#1F241F',
        },
        supporter: {
          100: '#BF90AC',
          13: '#292328',
        },
      },
    },
  },
};
