/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

import Card from './Card';
import BookCover from './BookCover';
import Heading from './Heading';
import Text from './Text';

export default function CardPortrait({
  authors,
  shouldRespond = true,
  slug,
  thumbnail,
  title,
}) {
  const sx = getStyles(shouldRespond);
  return (
    <Card slug={slug}>
      <div sx={sx.container}>
        <figure sx={sx.aspect}>
          <AspectRatio ratio={4 / 3}>
            <div sx={sx.image}>
              <BookCover
                thumbnail={thumbnail}
                thumbnail2x={thumbnail}
                title={title}
                width={shouldRespond ? ['50%', '50%', '50%', '40%'] : '40%'}
              />
            </div>
          </AspectRatio>
        </figure>
        <div sx={sx.body}>
          <Heading as="h3" variant="h2" sx={sx.heading}>
            {title}
          </Heading>
          <Text as="p" variant="author">
            {authors}
          </Text>
        </div>
      </div>
    </Card>
  );
}

const getStyles = shouldRespond => ({
  container: {
    display: shouldRespond ? ['flex', 'flex', 'flex', 'block'] : 'block',
    minWidth: 0,
    height: '100%',
    alignItems: 'center',
    flexDirection: shouldRespond ? ['row', 'row', 'row', 'column'] : 'column',
  },
  image: {
    height: '100%',
    display: 'flex',
    alignItems: shouldRespond
      ? ['center', 'center', 'center', 'flex-end']
      : 'flex-end',
    justifyContent: 'center',
  },
  aspect: {
    width: shouldRespond ? [120, 120, 120, 'auto'] : 'auto',
    height: shouldRespond ? [120, 120, 120, 'auto'] : 'auto',
    minWidth: 0,
    overflow: 'visible',
    div: {
      overflow: 'visible',
    },
    '> div': {
      height: shouldRespond ? ['100%', '100%', '100%', 'auto'] : 'auto',
    },
  },
  subtitle: {
    display: shouldRespond ? ['none', 'none', 'block'] : 'block',
  },
  body: {
    paddingX: 'm',
    paddingY: 'm',
    paddingLeft: [0, 0, 0, 'm'],
    textAlign: shouldRespond ? ['left', 'left', 'left', 'center'] : 'center',
    maxWidth: shouldRespond
      ? [
          'calc(100% - 120px)',
          'calc(100% - 120px)',
          'calc(100% - 120px)',
          '100%',
        ]
      : '100%',
  },
  heading: {
    marginBottom: 'xxs',
  },
  ratings: {
    marginTop: 's',
    display: 'flex',
    '> div': {
      marginRight: 's',
    },
  },
});
