import React from 'react';

import Svg from '../components/Svg';

export default function ShareSmallIcon() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5758 2.17574C10.8101 1.94142 11.19 1.94142 11.4243 2.17574L14.4243 5.17574C14.5186 5.27006 14.575 5.38798 14.5933 5.51049C14.6023 5.5698 14.6023 5.6302 14.5933 5.68951C14.589 5.7186 14.5826 5.747 14.5742 5.77457C14.5465 5.86595 14.4965 5.95203 14.4243 6.02426L11.4243 9.02426C11.19 9.25858 10.8101 9.25858 10.5758 9.02426C10.3414 8.78995 10.3414 8.41005 10.5758 8.17574L12.5515 6.2H10.5C9.30266 6.2001 8.13928 6.59795 7.19262 7.33103C6.24595 8.06413 5.56963 9.09095 5.26991 10.2502C5.18696 10.571 4.85964 10.7638 4.53882 10.6809C4.218 10.5979 4.02517 10.2706 4.10811 9.94979C4.4744 8.53308 5.30095 7.27819 6.4579 6.38226C7.61484 5.48632 9.03664 5.00011 10.4999 5H12.5515L10.5758 3.02426C10.3414 2.78995 10.3414 2.41005 10.5758 2.17574ZM2.00002 4C2.3314 4 2.60002 4.26863 2.60002 4.6V12H12C12.3314 12 12.6 12.2686 12.6 12.6C12.6 12.9314 12.3314 13.2 12 13.2H2.50002C2.20829 13.2 1.9285 13.0841 1.72221 12.8778C1.51592 12.6715 1.40002 12.3917 1.40002 12.1V4.6C1.40002 4.26863 1.66865 4 2.00002 4Z"
      />
    </Svg>
  );
}
