/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, Grid } from 'theme-ui';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import range from 'lodash-es/range';

import ProfileWrap from './ProfileWrap';
import ProfileEmptyState from './ProfileEmptyState';
import CardSelectableSmall from '../../components/CardSelectableSmall';
import ContentLoader from '../../components/ContentLoader';

import {
  getProfile,
  getProfileDisplayName,
  getBookTitle,
  getToken,
  getUserHighlights,
} from '../../selectors';

import { fetchHighlightsForUser } from '../../ducks/highlights';
import { getProfileHighlightsBooksRoute } from '../../constants/routes';
import { pluralise } from '../../utils';

export default function ProfileHighlights() {
  const match = useRouteMatch();
  const { username } = match.params;
  const user = useSelector(getProfile(username));
  const name = getProfileDisplayName(user);
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  // const currentUser = useSelector(getUsername);
  const [isLoading, setIsLoading] = useState(true);
  const books = useSelector(getUserHighlights(username));

  useEffect(() => {
    if (username) {
      fetchHighlightsForUser({ dispatch, token, username }).then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 420);
      });
    }
  }, [username]);

  const isEmpty = books?.items?.length === 0;

  if (isLoading) {
    return (
      <ProfileWrap title=" / Highlights">
        <div sx={sx.container}>
          <Grid gap="s" columns={[1, 1, 1, 1, 2, 2, 3]}>
            {range(5).map(item => (
              <div key={item}>
                <ContentLoader sx={sx.loader} />
              </div>
            ))}
          </Grid>
        </div>
      </ProfileWrap>
    );
  }

  if (isEmpty) {
    return (
      <ProfileEmptyState
        title=" / Highlights"
        heading="Nothing interesting."
        subheading={`${name} hasn't added any highlights yet.`}
      />
    );
  }

  return (
    <ProfileWrap title=" / Highlights">
      <div sx={sx.container}>
        <Grid gap="s" columns={[1, 1, 1, 1, 2, 2, 3]}>
          {books?.items.map(item => {
            const { title, id, slug } = item.book;
            const bookTitle = getBookTitle({ title });
            const subtitle = pluralise({
              count: item.highlightCount,
              word: 'highlight',
            });
            return (
              <div key={id}>
                <CardSelectableSmall
                  subtitle={subtitle}
                  thumbnail={item.book.imageLinks.thumbnail}
                  title={bookTitle}
                  to={getProfileHighlightsBooksRoute(username, slug)}
                />
              </div>
            );
          })}
        </Grid>
      </div>
    </ProfileWrap>
  );
}

const sx = {
  container: {
    paddingTop: 'm',
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
  loader: {
    height: 120,
    borderRadius: 'button',
  },
  loadMoreButton: {
    marginBottom: ['s', 's', 'l', 'xl'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: [0, 0, 0, 132],
  },
};
