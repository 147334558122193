/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';

import ContainsSpoilers from './ContainsSpoilers';
import MarkdownBlock from './MarkdownBlock';
import Tag from './Tag';

import { hasTouch } from '../utils';

export default function BookReviewBody({ hasSpoilers, review, tags }) {
  const [spolerAlertHidden, setSpolerAlertHidden] = useState(true);
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const sx = getStyles({ isDark });

  const reviewExtraStyles = hasSpoilers ? sx.reviewMinHeight : undefined;
  const hasTags = tags?.length > 0;

  const extraTagStyles = hasTags
    ? { paddingBottom: 'xs' }
    : { paddingBottom: 's' };

  const hideSpoilerAlert = () => {
    setSpolerAlertHidden(false);
  };

  if (!review) {
    return null;
  }

  return (
    <main sx={{ ...sx.review, ...reviewExtraStyles, ...extraTagStyles }}>
      <MarkdownBlock>{review}</MarkdownBlock>
      {hasTags && (
        <div sx={sx.tags}>
          {tags.map(tag => (
            <Tag key={tag} marginRight="xs" marginBottom="xs">
              {tag}
            </Tag>
          ))}
        </div>
      )}
      {hasSpoilers && spolerAlertHidden && (
        <button onClick={hideSpoilerAlert} sx={sx.spoilerAlert} type="button">
          <ContainsSpoilers />
        </button>
      )}
    </main>
  );
}

const getStyles = ({ isDark }) => ({
  review: {
    paddingX: 's',
    paddingTop: 's',

    border: '1px solid',
    borderColor: 'blueberry.10',
    borderRadius: 'button',
    position: 'relative',

    fontSize: 'xs',
    lineHeight: 'body',
    color: 'blueberry.80',

    p: {
      marginBottom: 's',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    ul: {
      marginBottom: 's',
      listStyleType: 'disc',
      marginLeft: 'm',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    ol: {
      marginBottom: 's',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    li: {
      marginBottom: 'xs',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    em: {
      fontStyle: 'italic',
    },
    strong: {
      fontWeight: 'button',
    },
  },
  reviewMinHeight: {
    minHeight: 115,
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  spoilerAlert: {
    width: '100%',
    border: 'none',
    outline: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: isDark ? 'rgba(30,30,30, 0.8)' : 'rgba(246,246,246, 0.8)',
    backdropFilter: 'blur(5px)',
    borderRadius: 'button',
    cursor: 'pointer',
    transition: 'smooth.fast',

    display: 'flex',
    alignItems: 'center',
    justifyContent: ['flex-start', 'flex-start', 'flex-start', 'center'],
    flexDirection: 'column',
    py: 'xl',

    svg: {
      color: 'blueberry.100',
    },

    '&:hover': !hasTouch() && {
      bg: isDark ? 'rgba(19,19,20, 0.8)' : 'rgba(255,255,255, 0.8)',
    },
  },
});
