import React from 'react';

import Svg from '../../components/Svg';

export default function ClockIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m7.5 13.5c0-3.3137 2.6863-6 6-6s6 2.6863 6 6-2.6863 6-6 6-6-2.6863-6-6zm6-7.5c-4.14214 0-7.5 3.35786-7.5 7.5 0 4.1421 3.35786 7.5 7.5 7.5 4.1421 0 7.5-3.3579 7.5-7.5 0-4.14214-3.3579-7.5-7.5-7.5zm.75 3.5625c0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75v3.9375c0 .4142.3358.75.75.75h3.9375c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75h-3.1875z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
