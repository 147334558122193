/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Heading from '../../components/Heading';
import Text from '../../components/Text';
import Link from '../../components/Link';

export default function MoreInfoBlock() {
  return (
    <div sx={sx.container}>
      <div sx={sx.column}>
        <div sx={sx.short}>
          <Heading variant="h2" as="h3">
            Looking to switch?
          </Heading>
        </div>
        <div sx={sx.long}>
          <Heading variant="h2" as="h3">
            Import from Goodreads
          </Heading>
          <Text variant="marketingSub" as="p">
            There&apos;s no need to start over -- our{' '}
            <Link
              as={RouterLink}
              variant="primary"
              to="/blog/2021/importing-your-data-from-goodreads"
              hasUnderline
            >
              automated import tool
            </Link>{' '}
            can bring your existing Goodreads library with you. No fuss.
          </Text>
        </div>
      </div>
      <div sx={sx.column}>
        <div sx={sx.short}>
          <Heading variant="h2" as="h3">
            Data & Privacy
          </Heading>
        </div>
        <div sx={sx.long}>
          <Heading variant="h2" as="h3">
            Retain control of your data
          </Heading>
          <Text variant="marketingSub" as="p">
            Your data is your business, not ours. We&apos;ll never sell it to
            somebody else and we don&apos;t use invasive tracking that
            advertisers rely on. And since you&apos;re in charge, you&apos;re
            free to export your data and take it with you.
          </Text>
        </div>
      </div>
      <div sx={sx.column}>
        <div sx={sx.short}>
          <Heading variant="h2" as="h3">
            Is this sustainable?
          </Heading>
        </div>
        <div sx={sx.long}>
          <Heading variant="h2" as="h3">
            We are member supported
          </Heading>
          <Text variant="marketingSub" as="p">
            We choose to charge our members directly for extra benefits so that
            we profit when we make them happy instead of when we show them ads.
          </Text>
        </div>
      </div>
    </div>
  );
}

const sx = {
  container: {
    maxWidth: 912,
    marginX: 'auto',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    color: 'blueberry.100',
    paddingX: 'xl',

    textAlign: ['center', 'center', 'left', 'left', 'left'],

    paddingBottom: [16, 16, 56, 56, 56],
    marginBottom: [80, 80, 120, 120, 120],

    h3: {
      fontWeight: 500,
      marginBottom: 'xs',
    },

    p: {
      fontSize: ['s', 's', 'm', 'm', 'm'],
    },
  },
  column: {
    display: 'flex',
    marginBottom: 'xxl',
  },
  short: {
    width: '30%',
    display: ['none', 'none', 'block', 'block', 'block'],
  },
  long: {
    width: ['100%', '100%', '70%', '70%', '70%'],
  },
};
