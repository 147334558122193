import React from 'react';
import { useResponsiveValue } from '@theme-ui/match-media';

import NavigationMenu from './NavigationMenu';
import Sidebar from './Sidebar';

export default function Navigation() {
  const showOnDesktop = useResponsiveValue([false, false, false, true]);
  return (
    <React.Fragment>
      {showOnDesktop ? <Sidebar /> : <NavigationMenu />}
    </React.Fragment>
  );
}
