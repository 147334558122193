/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import ContentLoader from '../../components/ContentLoader';
import ActionButton from './ActionButton';

export default function BookActions({
  areListsLoadng,
  bookId,
  hasReviewed,
  isAuthenticated,
  isReadList,
  listIds,
  lists,
  openSignUp,
  recommended,
  selectedList,
  thumbnail,
  title,
  updateList,
  userIsStale,
}) {
  if (userIsStale) {
    return (
      // this is required because adding to lists or editing ratings
      // won't work before the user is loaded
      <div sx={sx.buttons}>
        <div sx={sx.container}>
          <ContentLoader kind="dark" sx={sx.longButton} />
          <ContentLoader kind="dark" sx={sx.text} />
        </div>
      </div>
    );
  }
  return (
    <div sx={sx.buttons}>
      {isReadList ? (
        <ActionButton
          areListsLoadng={areListsLoadng}
          bookId={bookId}
          hasReviewed={hasReviewed}
          isAuthenticated={isAuthenticated}
          key="read"
          list="read"
          listIds={listIds}
          openSignUp={openSignUp}
          recommended={recommended}
          selectedList={selectedList}
          thumbnail={thumbnail}
          title={title}
          updateList={updateList}
        />
      ) : (
        lists.map(list => (
          <ActionButton
            areListsLoadng={areListsLoadng}
            bookId={bookId}
            hasReviewed={hasReviewed}
            isAuthenticated={isAuthenticated}
            key={list}
            list={list}
            listIds={listIds}
            openSignUp={openSignUp}
            recommended={recommended}
            selectedList={selectedList}
            thumbnail={thumbnail}
            title={title}
            updateList={updateList}
          />
        ))
      )}
    </div>
  );
}

const sx = {
  buttons: {
    marginTop: 'm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', 'center', 'center', 'center', 'flex-start'],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    marginTop: 's',
    height: 12,
    width: '80%',
    borderRadius: 'toast',
    paddingLeft: 'xs',
    alignSelf: ['center', 'center', 'center', 'center', 'flex-start'],
  },
  longButton: {
    width: '90vw',
    height: 50,
    maxWidth: [320, 320, 350],
    borderRadius: 'button',
  },
  addToCollection: {
    minWidth: 0,
    maxWidth: 186,
    marginBottom: ['l', 'l', 'xl'],
    height: '44px',
    borderRadius: 'button',
  },
};
