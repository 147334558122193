/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../../components/PrimaryButton';
import FormError from '../../components/FormError';
import InputWithLabel from '../../components/InputWithLabel';
import Link from '../../components/Link';

import { shouldSignIn } from '../../ducks/auth';
import { SIGN_UP_ROUTE, SEND_FORGOT_PASSWORD_ROUTE } from '../../constants';

import {
  getAuthFormLoading,
  getAuthEmailError,
  getAuthFormError,
  getAuthPasswordError,
} from '../../selectors';

export default function SignIn() {
  const sx = getStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const isLoading = useSelector(getAuthFormLoading);
  const emailError = useSelector(getAuthEmailError);
  const formError = useSelector(getAuthFormError);
  const passwordError = useSelector(getAuthPasswordError);

  const loc = useLocation();
  const qs = new URLSearchParams(loc.search);
  const onSubmit = event => {
    // todo: this is a potentially dangerous open redirect
    const nextUrl =
      loc.state?.successUrl || qs.get('next') || loc.state?.from?.pathname;
    event.preventDefault();
    shouldSignIn({
      dispatch,
      email,
      password,
      nextUrl,
    });
  };

  const onEmailChange = event => {
    setEmail(event.target.value);
  };

  const onPasswordChange = event => {
    setPassword(event.target.value);
  };

  return (
    <div sx={sx.body}>
      <FormError>{formError}</FormError>
      <form onSubmit={onSubmit}>
        <InputWithLabel
          error={emailError}
          id="email"
          label="Email address"
          onChange={onEmailChange}
          placeholder="your.email@address.com"
          sx={sx.input}
          type="text"
          value={email}
          inputMode="email"
        />
        <div sx={sx.relative}>
          <InputWithLabel
            error={passwordError}
            id="password"
            label="Password"
            onChange={onPasswordChange}
            placeholder="Enter your password"
            sx={sx.input}
            type="password"
            value={password}
            required
          />
          <div sx={sx.resetPassword}>
            <Link
              as={RouterLink}
              hasUnderline
              sx={{ ...sx.link, lineHeight: 'body' }}
              to={{ pathname: SEND_FORGOT_PASSWORD_ROUTE, search: loc.search }}
              variant="body"
            >
              Forgot password?
            </Link>
          </div>
        </div>
        <div sx={sx.controls}>
          <PrimaryButton type="submit" isLoading={isLoading}>
            Sign in
          </PrimaryButton>
          <Link
            as={RouterLink}
            hasUnderline
            sx={sx.link}
            to={`${SIGN_UP_ROUTE}${loc.search}`}
            variant="body"
          >
            Or sign up
          </Link>
        </div>
      </form>
    </div>
  );
}

const getStyles = () => ({
  text: {
    maxWidth: 420,
    textAlign: 'center',
    marginTop: 'xs',
    marginBottom: 'l',
  },
  body: {
    width: '100%',
    maxWidth: 'page.mobile',
  },
  input: {
    marginBottom: 'm',
  },
  relative: {
    position: 'relative',
  },
  resetPassword: {
    textAlign: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  controls: {
    marginTop: 'l',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: 'xs',
    lineHeight: 'heading',
  },
});
