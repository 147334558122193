import React from 'react';

import Svg from '../../components/Svg';

export default function Feedback(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m7.5 15.7417v-7.2417h8.625v2.625 3.1875h-5.9991c-.0003 0-.0006 0-.0009 0s-.0006 0-.0009 0h-.59093c-.17162 0-.33805.0589-.47151.1668zm1.875.4131-2.15349 1.7409c-.22474.1817-.53388.2181-.79467.0936s-.42684-.3878-.42684-.6768v-9c0-.3481.13828-.68194.38442-.92808s.57998-.38442.92808-.38442h9c.3481 0 .6819.13828.9281.38442.2461.24614.3844.57998.3844.92808v2.0625h2.0625c.3481 0 .6819.1383.9281.3844.2461.2462.3844.58.3844.9281v9c0 .289-.166.5523-.4268.6768s-.57.0881-.7947-.0936l-2.5769-2.0832h-6.5141c-.3481 0-.6819-.1383-.92808-.3844-.24614-.2462-.38442-.58-.38442-.9281zm1.5-.3423v1.875h6.5918c.1716 0 .3381.0589.4715.1668l1.5617 1.2624v-7.2417h-1.875v2.625c0 .3481-.1383.6819-.3844.9281-.2462.2461-.58.3844-.9281.3844z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
