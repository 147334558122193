/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Heading from './Heading';
import Text from './Text';

export default function ModalHeadingText({ heading, text }) {
  return (
    <React.Fragment>
      <Heading as="h1" variant="h1" fontFamily="serif">
        {heading}
      </Heading>
      <Text as="p" variant="subheading" sx={sx.paragraph}>
        {text}
      </Text>
    </React.Fragment>
  );
}

const sx = {
  paragraph: {
    marginTop: 'xs',
    display: 'inline-block',
  },
};
