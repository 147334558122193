import { batch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import {
  getBookReviewSummaryUrl,
  getBookReviewsUrl,
  getProfileReviewUrl,
} from '../constants';
import { getBookReviewThreadId, getProfileReviewThreadId } from '../selectors';

import { callApi } from '../utils/api';

import {
  appendItems,
  removeItem as removeThreadItems,
  prependItems,
} from './threads';

import {
  bumpBookReviewCount,
  decreaseBookReviewCount,
  addBooks,
} from './books';

// Actions
const SET_ITEM = 'SET';
const REMOVE_ITEM = 'REMOVE';

// Reducer
// Pretty sure we'll reduce this reducer later for other objects, will extract it then
const keyValueReducer = (
  state = {
    items: {},
  },
  action
) => {
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.itemId]: {
            ...state.items[action.itemId],
            ...action.item,
          },
        },
      };
    case REMOVE_ITEM:
      const newState = {
        ...state,
      };
      delete newState.items[action.itemId];
      return newState;
    default:
      return state;
  }
};

const reviewReducer = keyValueReducer;

export const setItem = ({ itemId, item }) => ({
  type: SET_ITEM,
  itemId,
  item,
});

export const removeItem = itemId => ({
  type: REMOVE_ITEM,
  itemId,
});

// Side effects
export function fetchReviewThread({ thread, token, dispatch }) {
  return callApi(thread.nextLink, { token, method: 'GET' }).then(data => {
    batch(() => {
      const reviewIds = [];

      data.results.forEach(apiReview => {
        const review = formatApiReview(apiReview);
        reviewIds.push(review.id);

        // set the individual reviews in the review reducer
        dispatch(setItem({ itemId: review.id, item: review }));
      });

      // update the thread with the IDs
      dispatch(appendItems(thread.id, reviewIds, data.next));
    });
  });
}

const formatApiReview = review => {
  // takes in API format, spits out react state format
  const formatted = {
    hasSpoilers: review.contains_spoiler,
    reviewedBy: review.reviewed_by,
    addedAt: review.added_at,
    ...review,
  };
  delete formatted.contains_spoiler;
  delete formatted.reviewed_by;
  delete formatted.added_at;
  return formatted;
};

export function fetchBookReviewSummaries({ bookId, token, dispatch }) {
  const url = getBookReviewSummaryUrl(bookId);
  return callApi(url, { token, method: 'GET' }).then(data => {
    const friends = data.friends;
    const publicReviews = data.public || data.all;

    const allThreadId = getBookReviewThreadId({ threadType: 'public', bookId });
    const friendsThreadId = getBookReviewThreadId({
      threadType: 'friends',
      bookId,
    });

    batch(() => {
      // first make sure we've added the individuals reviews by ID to the review reducers
      publicReviews.results.forEach(apiReview => {
        const review = formatApiReview(apiReview);
        dispatch(setItem({ itemId: review.id, item: review }));
      });

      friends.results.forEach(apiReview => {
        const review = formatApiReview(apiReview);
        dispatch(setItem({ itemId: review.id, item: review }));
      });

      // then we'll make sure the appropriate book review threads include the IDs
      dispatch(
        appendItems(
          allThreadId,
          publicReviews.results.map(r => r.id),
          publicReviews.next
        )
      );
      dispatch(
        appendItems(
          friendsThreadId,
          friends.results.map(r => r.id),
          friends.next
        )
      );
    });
  });
}

export function fetchProfileReviews({ username, token, dispatch, thread }) {
  const url = thread?.nextLink || getProfileReviewUrl(username);
  return callApi(url, { token, method: 'GET' }).then(data => {
    const threadId = thread?.id || getProfileReviewThreadId(username);

    batch(() => {
      // first make sure we've added the individuals reviews by ID to the review reducers
      data.results.forEach(item => {
        const review = formatApiReview(item.review);
        const bookPreview = item.bookPreview;
        dispatch(setItem({ itemId: review.id, item: review }));
        dispatch(addBooks({ books: [bookPreview], isComplete: false }));
      });

      // then we'll make sure the appropriate profile review thread includes the IDs
      dispatch(
        appendItems(
          threadId,
          data.results.map(r => [r.review.id, r.bookPreview.id]),
          data.next
        )
      );
    });

    return data;
  });
}

export function createBookReview({ bookId, token, text, spoiler, dispatch }) {
  // Possible failures:
  // HTTP 409
  // there's already a review from this user for this book or for another edition
  // of the same book. what should we do?
  //
  // HTTP 403
  // this book is not released yet
  const url = getBookReviewsUrl(bookId);
  const body = {
    text,
    contains_spoiler: spoiler === true,
  };
  return callApi(url, { token, method: 'POST', body }).then(apiReview => {
    const review = formatApiReview(apiReview);
    const bookThreadId = getBookReviewThreadId({
      threadType: 'public',
      bookId,
    });
    const profileThreadId = getProfileReviewThreadId(
      review.reviewedBy.username
    );
    batch(() => {
      dispatch(setItem({ itemId: review.id, item: review }));
      dispatch(
        prependItems({
          threadId: bookThreadId,
          items: [review.id],
          createIfNotExists: false,
        })
      );
      dispatch(
        prependItems({
          threadId: profileThreadId,
          items: [[review.id, bookId]],
          createIfNotExists: false,
        })
      );
      dispatch(bumpBookReviewCount({ bookId }));
    });
    mixpanel.track('Review Book');
    return review;
  });
}

export function updateBookReview({
  bookId,
  reviewId,
  token,
  text,
  spoiler,
  dispatch,
}) {
  const url = `${getBookReviewsUrl(bookId)}/${reviewId}`;
  const body = {
    text,
    contains_spoiler: spoiler === true,
  };
  return callApi(url, { token, method: 'PATCH', body }).then(apiReview => {
    const review = formatApiReview(apiReview);
    dispatch(setItem({ itemId: review.id, item: review }));
    return review;
  });
}

export function deleteBookReview({
  bookId,
  username,
  reviewId,
  token,
  dispatch,
}) {
  const url = `${getBookReviewsUrl(bookId)}/${reviewId}`;
  return callApi(url, { token, method: 'DELETE' }).then(() => {
    const bookThreadId = getBookReviewThreadId({
      threadType: 'public',
      bookId,
    });
    const profileThreadId = getProfileReviewThreadId(username);
    batch(() => {
      dispatch(removeThreadItems(bookThreadId, reviewId));
      dispatch(
        removeThreadItems(
          profileThreadId,
          [reviewId, bookId],
          (action, item) => {
            return action.item[0] !== item[0];
          }
        )
      );
      dispatch(removeItem(reviewId));
      dispatch(decreaseBookReviewCount({ bookId }));
    });
  });
}

export function getBookReview({ bookId, reviewId, token, dispatch }) {
  const url = `${getBookReviewsUrl(bookId)}/${reviewId}`;
  return callApi(url, { token, method: 'GET' }).then(data => {
    dispatch(setItem({ itemId: data.id, item: formatApiReview(data) }));
  });
}

export default reviewReducer;
