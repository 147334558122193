/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  getListName,
  getIsAuthenticated,
  getDateBookAddedToList,
  getWorkId,
  getLists,
  getUserStale,
} from '../../selectors';

import {
  BookmarkIcon,
  ClockIcon,
  TickIcon,
  DotsIcon,
  EyeIcon,
} from '../../icons';

import { hasTouch } from '../../utils';

import AbandonModal from '../../modals/AbandonBook';
import RecommendModal from '../../modals/Recommend';
import ReviewBookModal from '../../modals/ReviewBook';

import Button from '../../components/Button';
import GhostButton from '../../components/GhostButton';
import ModalDialog from '../../components/ModalDialog';
import Text from '../../components/Text';

export default function ActionButton({
  areListsLoadng,
  bookId,
  hasReviewed,
  list,
  listIds,
  recommended,
  selectedList,
  thumbnail,
  title,
  updateList,
}) {
  const sx = getStyles();
  const [currentModal, setCurrentModal] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const isReadList = list === 'read';
  const isReadingList = list === 'reading';
  const isToReadList = list === 'to-read';
  const currentlyAbandoned = isReadingList && selectedList?.key === 'abandoned';
  let isActive = selectedList?.key === list;
  if (isReadingList && currentlyAbandoned) {
    isActive = true;
  }
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userIsStale = useSelector(getUserStale);
  const buttonVariant = getButtonVariant({ isActive, list, isDark });
  const workId = useSelector(getWorkId(bookId));
  const buttonBg = !isActive ? { bg: 'milk' } : {};

  const listData = useSelector(getLists)[list];
  const dateAdded = getDateBookAddedToList({
    list: listData,
    bookId,
    workId,
  });
  const tooltip = getButtonTooltip({
    isActive,
    dateAdded,
    list: currentlyAbandoned ? 'abandoned' : list,
  });
  const listName = currentlyAbandoned ? 'Abandoned' : getListName(list);
  const isReadActive = isReadList && isActive;
  const isReadingActive = isReadingList && isActive;
  const extraInnerButtonStyles = isReadActive && hasReviewed && sx.buttonExtra;

  const closeModal = () => {
    setCurrentModal('');
  };

  const onUpdateList = listId => {
    if (isAuthenticated) {
      setIsLoading(true);
    }
    // updateList will open the signup modal if needed
    updateList(listId || listIds[list]);
  };

  const onClick = () => {
    if (isReadList && !!selectedList?.key) {
      // if marking as read and book is already in a list,
      // show recommendation modal
      return setCurrentModal('recommend');
    }

    // if book is in reading and being moved to
    // anywhere other than read, show abandon modal
    if (selectedList?.key === 'reading' && !isReadList) {
      return setCurrentModal('abandon');
    }

    if (isReadingList && currentlyAbandoned) {
      // show abandon modal for editing
      return setCurrentModal('abandon');
    }

    onUpdateList();
  };

  useEffect(() => {
    if (!areListsLoadng) {
      setIsLoading(false);
    }
  }, [areListsLoadng]);

  let abandonContext = isToReadList ? 'toRead' : 'default';
  if (currentlyAbandoned) {
    abandonContext = 'resurrect';
  }

  const showReviewButton = isReadActive && !hasReviewed;

  const recommendedText = useResponsiveValue([
    'Recommended',
    'Recommended',
    'Recommended',
    'Recommended by you',
    'Recommended',
    'Recommended by you',
  ]);

  const showRecommendedIcons = useResponsiveValue([
    false,
    false,
    false,
    true,
    false,
    true,
  ]);

  const finishedContainerStyles = showReviewButton
    ? sx.containerFinished
    : undefined;
  const buttonContainerStyles = showReviewButton
    ? sx.buttonContainer
    : undefined;
  const buttonTextStyles = showRecommendedIcons
    ? sx.dotsIconReading
    : undefined;
  const tooltipTextStyles = showReviewButton
    ? sx.textWithReviewButton
    : undefined;

  return (
    <React.Fragment>
      {showReviewButton && (
        <ModalDialog
          onClose={closeModal}
          isVisible={currentModal === 'review'}
          width={520}
        >
          <ReviewBookModal onClose={closeModal} />
        </ModalDialog>
      )}
      {isReadList && (
        <ModalDialog
          onClose={closeModal}
          isVisible={currentModal === 'recommend'}
          width={520}
        >
          <RecommendModal
            bookId={bookId}
            context={isActive ? 'update' : 'default'}
            dateAdded={dateAdded}
            isActive={isActive}
            onClose={closeModal}
            onUpdateLinst={onUpdateList}
            recommended={recommended}
            thumbnail={thumbnail}
            title={title}
          />
        </ModalDialog>
      )}
      {(currentlyAbandoned || selectedList?.key === 'reading') && (
        <ModalDialog
          onClose={closeModal}
          isVisible={currentModal === 'abandon'}
          width={510}
        >
          <AbandonModal
            bookId={bookId}
            listIds={listIds}
            selectedList={selectedList}
            onClose={closeModal}
            updateList={onUpdateList}
            context={abandonContext}
          />
        </ModalDialog>
      )}
      <div sx={{ ...sx.container, ...finishedContainerStyles }}>
        <div sx={buttonContainerStyles}>
          <Button
            // if user is stale, we've only got cached data, so don't know which lists this will be in
            isLoading={isLoading || userIsStale}
            onClick={onClick}
            sx={{ ...sx.button, ...buttonBg, ...extraInnerButtonStyles }}
            variant={buttonVariant}
          >
            <div sx={sx.buttonInner}>
              {(!showReviewButton || showRecommendedIcons) && (
                <ButtonIcon
                  list={currentlyAbandoned ? 'abandoned' : list}
                  isActive={isReadingList && isActive}
                />
              )}
              {isReadActive && (
                <React.Fragment>
                  {recommended ? (
                    <span sx={buttonTextStyles}>{recommendedText}</span>
                  ) : (
                    <span sx={buttonTextStyles}>Finished</span>
                  )}
                  {!showReviewButton && (
                    <div sx={sx.dotsIcon}>
                      <DotsIcon />
                    </div>
                  )}
                </React.Fragment>
              )}
              {isReadingActive && (
                <div sx={sx.dotsIconReading}>
                  <DotsIcon />
                </div>
              )}
            </div>
          </Button>
          {showReviewButton && (
            <GhostButton
              sx={sx.reviewButton}
              onClick={() => setCurrentModal('review')}
            >
              Write a review
            </GhostButton>
          )}
        </div>
        {isReadActive ? (
          <Text
            as="p"
            variant="subtitle"
            sx={{ ...sx.text, ...tooltipTextStyles }}
          >
            {tooltip}
          </Text>
        ) : (
          <Text as="p" variant="subtitle" sx={sx.text}>
            <span>{listName}</span>
            <span>{tooltip}</span>
          </Text>
        )}
      </div>
    </React.Fragment>
  );
}

const getButtonVariant = ({ list, isActive, isDark }) => {
  switch (list) {
    case 'read':
      if (isActive) return 'kiwi.active';
      if (isDark) return 'kiwi.dark';
      return 'kiwi.default';
    case 'reading':
      if (isActive) return 'banana.active';
      if (isDark) return 'banana.dark';
      return 'banana.default';
    case 'to-read':
      if (isActive) return 'dragonfruit.active';
      if (isDark) return 'dragonfruit.dark';
      return 'dragonfruit.default';
    default:
      return 'primary';
  }
};

const getButtonTooltip = ({ list, isActive, dateAdded }) => {
  switch (list) {
    case 'read':
      if (!isActive) return 'Mark as finished';
      if (!dateAdded) return '';
      const readDate = moment(dateAdded);
      const today = moment();
      if (today.isSame(readDate, 'year')) {
        // 10 oct
        return `Read ${readDate.format('Do MMMM')}`;
      }
      // 10 oct 2022
      return `Read ${readDate.format('Do MMM YYYY')}`;

    case 'abandoned':
      return 'Not abandoned?';

    case 'reading':
      if (isActive) return 'Not reading this?';
      return 'Mark as reading';

    case 'to-read':
      if (isActive) return 'Remove from list';
      return 'Save for later';
    default:
      return 'primary';
  }
};

const ButtonIcon = ({ list, isActive, ...props }) => {
  switch (list) {
    case 'read':
      return <TickIcon {...props} />;
    case 'reading':
      return <EyeIcon {...props} />;
    case 'abandoned':
      return <ClockIcon {...props} />;
    case 'to-read':
      return <BookmarkIcon {...props} />;
    default:
      return <TickIcon {...props} />;
  }
};

const getStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: ['s', 's', 'm'],

    '&:last-child': {
      margin: 0,
    },
    '> p > span:first-of-type': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',

      opacity: 1,
      willChange: 'transform',
      transform: 'translate3d(0, 0, 0)',
      transition: 'smooth.fast',
    },
    '> p > span:last-child': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',

      opacity: 0,
      willChange: 'transform',
      transform: 'translate3d(0, 5px, 0)',
      transition: 'smooth.fast',
    },
    '&:hover': !hasTouch() && {
      '> p > span:first-of-type': {
        opacity: 0,
        transform: 'translate3d(0, -5px, 0)',
      },
      '> p > span:last-child': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
  containerFinished: {
    width: ['100vw', '100vw', '100vw', 'auto'],
  },
  buttonContainer: {
    display: 'flex',
  },
  reviewButton: {
    marginLeft: 's',
    paddingX: 'l',
    height: 50,
    bg: 'milk',
  },
  button: {
    paddingX: 'l',
    height: 50,
    borderRadius: 'button',
    position: 'relative',
  },
  buttonSpaced: {
    paddingX: ['m', 'm', 'm', 'l'],
  },
  buttonExtra: {
    width: '90vw',
    maxWidth: [320, 320, 350],
    padding: '0 !important',
    whiteSpace: 'nowrap',
  },
  buttonInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
      display: 'block',
    },
  },
  text: {
    marginTop: 'xs',
    textAlign: 'center',
    position: 'relative',
    height: 20,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  textWithReviewButton: {
    textAlign: ['center', 'center', 'center', 'center', 'left'],
    paddingLeft: 'xxs',
  },
  dotsIcon: {
    position: 'absolute',
    top: '50%',
    marginTop: '-2px',
    right: 20,
  },
  dotsIconReading: {
    marginLeft: 'xs',
  },
});
