/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Card from './Card';
import BookStack from './BookStack';
import Heading from './Heading';
import Text from './Text';

import { getCollectionRoute } from '../constants';
import { pluralise } from '../utils';

export default function CardCollection({
  list,
  username,
  showBookCount = true,
}) {
  const { books, slug, name, key } = list;
  const bookCount = pluralise({ count: books.length, word: 'book' });
  const isEmpty = books.length === 0;
  const hasOneBook = books.length === 1;
  const emptyCollectionStyles = isEmpty && sx.emptyCollection;
  const collectionRoute = getCollectionRoute(username, { slug, key });
  const linkStyles = { ...sx.collection, ...emptyCollectionStyles };
  const metaStyles = {
    ...sx.meta,
    marginBottom: showBookCount ? [0, 0, 'm'] : 0,
  };
  return (
    <Card to={collectionRoute}>
      <div sx={linkStyles}>
        <div sx={metaStyles}>
          <Heading as="h2" sx={sx.breakWord} variant="h2">
            {name}
          </Heading>
          {showBookCount && (
            <Text as="p" variant="subtitle">
              {bookCount}
            </Text>
          )}
        </div>
        {!isEmpty && (
          <div sx={hasOneBook ? sx.singleStack : sx.stack}>
            <BookStack hasHover={false} books={books} />
          </div>
        )}
      </div>
    </Card>
  );
}

const sx = {
  meta: {
    p: {
      marginTop: 'xs',
    },
  },
  collection: {
    paddingX: [0, 0, 'l'],
    paddingTop: 'xl',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderRadius: 'block',

    textAlign: 'center',
    overflow: 'hidden',
    userSelect: 'none',
    transition: 'smooth.fast',
  },
  emptyCollection: {
    justifyContent: 'center',
    paddingY: 'xl',
  },
  breakWord: {
    paddingX: ['m', 'm', 0],
    wordBreak: 'break-word',
  },
  stack: {
    paddingTop: 'm',
    marginBottom: ['-20%', '-20%', '-35%'],
  },
  singleStack: {
    paddingTop: 'm',
    marginBottom: ['-10%', '-10%', '-15%'],
  },
};
