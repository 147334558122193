import React from 'react';

import Svg from '../components/Svg';

export default function CaretUpIcon({ ...props }) {
  return (
    <Svg height="6" viewBox="0 0 10 6" width="10" {...props}>
      <path
        clipRule="evenodd"
        d="m9.79079 5.64134c-.27895.27825-.73121.27825-1.01015 0l-3.78064-3.77119-3.78064 3.77119c-.278944.27825-.731204.27825-1.010151 0-.2789454-.27825-.2789454-.72938 0-1.00763l4.285711-4.274999c.13396-.13362.31564-.208687.50508-.208687s.37112.075067.50508.208687l4.28571 4.274999c.27891.27825.27891.72938 0 1.00763z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
