/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Badge from '../../components/Badge';
import Heading from '../../components/Heading';
import PrimaryButton from '../../components/PrimaryButton';

import {
  getSubscriptionDetails,
  getSubTier,
} from '../../selectors/subscription';

import { PRICING_ROUTE } from '../../constants';
import { getTier } from '../../selectors';

export default function Subscription() {
  const theSub = useSelector(getSubscriptionDetails);
  let currentTier = getSubTier(theSub);
  const profileTier = useSelector(getTier);
  if (typeof currentTier === 'undefined') {
    currentTier = profileTier;
  }
  return (
    <section sx={sx.subscription}>
      <div sx={sx.box}>
        <div sx={sx.left}>
          <Heading as="h4" sx={sx.heading}>
            Membership Status
          </Heading>
          <Badge kind={currentTier} />
        </div>
        <PrimaryButton as={RouterLink} to={PRICING_ROUTE}>
          Change
        </PrimaryButton>
      </div>
    </section>
  );
}

const sx = {
  subscription: {
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    marginBottom: ['l', 'l', 'xl'],
    paddingBottom: ['l', 'l', 'xl'],
  },
  box: {
    padding: 'l',
    borderRadius: 'button',
    border: '1px solid',
    borderColor: 'blueberry.10',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 'm',
    color: 'blueberry.100',
    marginBottom: 'xxs',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};
