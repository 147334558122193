/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';

import { LANGUAGES } from '../../constants';

import Emoji from '../../components/Emoji';
import GhostButton from '../../components/GhostButton';
import LanguageSelector from '../../modals/LanguageSelector';
import ModalDialog from '../../components/ModalDialog';

export default function LanguageButton({ selectedLanguage, onLanguageSelect }) {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const showOnMobile = useResponsiveValue([true, true, false, false]);

  const openLanguageModal = () => {
    setFilterModalOpen(true);
  };
  const closeLanguageModal = () => {
    setFilterModalOpen(false);
  };

  return (
    <React.Fragment>
      <GhostButton size="m" onClick={openLanguageModal} sx={sx.languageButton}>
        {showOnMobile ? (
          <span sx={sx.uppercase}>
            {selectedLanguage !== null ? (
              selectedLanguage
            ) : (
              <Emoji label="Globe icon" character="🌍" />
            )}
          </span>
        ) : (
          <React.Fragment>
            <Emoji label="Globe icon" character="🌍" />
            <span>
              {' '}
              {selectedLanguage !== null
                ? `${LANGUAGES[selectedLanguage]}`
                : 'Language'}
            </span>
          </React.Fragment>
        )}
      </GhostButton>
      <ModalDialog isVisible={filterModalOpen} onClose={closeLanguageModal}>
        <LanguageSelector
          closeModal={closeLanguageModal}
          updateLang={onLanguageSelect}
        />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  languageButton: {
    height: '42px',
    lineHeight: '42px',
    marginLeft: ['xs', 's', 's', 's'],
    position: 'relative',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
};
