/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import { hasTouch } from '../utils';

export default function ToggleButton({
  isSelected,
  onClick,
  size = 'm',
  title,
}) {
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';
  const sx = getStyles({ isDarkMode });
  return (
    <button
      onClick={onClick}
      sx={{ ...sx.toggle, ...sizes[size] }}
      type="button"
    >
      {title}
      <div
        sx={{
          ...sx.itemSelected,
          borderRadius: sizes[size].borderRadius,
          opacity: isSelected ? 1 : 0,
        }}
      />
    </button>
  );
}

const sizes = {
  s: {
    paddingY: 's',
    fontSize: 'xxs',
    borderRadius: 'button',

    '&:hover': !hasTouch() && {
      bg: 'blueberry.10',
      transform: 'translate3d(0, -1px, 0)',
    },
    '&:active': {
      transform: 'translate3d(0, 1px, 0)',
    },
  },
  m: {
    paddingY: 's',
    fontSize: 'xs',
    borderRadius: 'button',

    '&:hover': !hasTouch() && {
      bg: 'blueberry.10',
      transform: 'translate3d(0, -1px, 0)',
    },
    '&:active': {
      transform: 'translate3d(0, 1px, 0)',
    },
  },
};

const getStyles = ({ isDarkMode }) => ({
  toggle: {
    flexGrow: 1,
    border: 'none',
    bg: 'blueberry.5',
    transform: 'translate3d(0, 0, 0)',
    transition: 'smooth.fast',
    position: 'relative',
    overflow: 'hidden',
    color: 'blackberry',
    cursor: 'pointer',

    p: {
      color: 'blackberry',
    },
  },
  itemSelected: {
    transition: 'smooth.fast',
    boxShadow: isDarkMode ? 'collection.selectedDark' : 'collection.selected',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
});
