import React from 'react';
import { useSelector } from 'react-redux';

import { getUsername, getUserStale } from '../../selectors';

import Settings from './Settings';
import Loading from '../Loading';

export default props => {
  // the settings form is broken if rendered
  // without initial form data available
  const username = useSelector(getUsername);
  const isStale = useSelector(getUserStale);
  if (!username || isStale) {
    return <Loading />;
  }
  return <Settings {...props} />;
};
