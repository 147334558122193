import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './ducks';

const composeEnhancer =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export default function configureStore(history) {
  const store = createStore(
    createRootReducer(history),
    composeEnhancer(applyMiddleware(routerMiddleware(history)))
  );
  return store;
}
