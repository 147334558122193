/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import Button from '../components/Button';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalImage from '../components/ModalImage';
import ModalHeadingText from '../components/ModalHeadingText';

import { getDisplayName } from '../selectors';
import { FEEDBACK_LINKS } from '../constants';
import { feedback, feedback2x } from '../assets';

export default function FeedbackModal() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const displayName = useSelector(getDisplayName);

  useEffect(() => {
    window.nolt('init', {
      url: 'https://oku.nolt.io/',
      selector: '.nolt-button',
    });
  }, []);

  return (
    <ModalBody canGrow={false}>
      <ModalImage
        alt="Dog and human reading a book"
        height={160}
        src={feedback}
        src2x={feedback2x}
        width={480}
      />
      <ModalContent extraStyles={sx.content}>
        <ModalHeadingText
          heading={`Hey ${displayName}!`}
          text="We would love to get your feedback, ideas and feelings about Oku. This helps us improve the product and make it better for everyone. Let us know what you think!"
        />
      </ModalContent>
      <ModalFooter direction="vertical">
        {FEEDBACK_LINKS.map(link => (
          <Button
            as="a"
            target="_blank"
            className={link.className}
            href={link.href}
            key={link.name}
            sx={sx.item}
            variant={isDark ? 'medium' : 'light'}
          >
            {link.name}
          </Button>
        ))}
      </ModalFooter>
    </ModalBody>
  );
}

const sx = {
  content: {
    textAlign: 'center',
  },
  item: {
    width: '100%',
    marginBottom: 's',
    '&:last-child': {
      marginBottom: 0,
    },
  },
};
