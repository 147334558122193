/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, merge } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';

import { TickIcon } from '../icons';
import { hasTouch } from '../utils';

import theme from '../theme';

export default function SelectableList({
  items,
  height,
  onClick,
  sxOverride = {},
}) {
  const style = merge(sx, sxOverride);
  return (
    <div sx={{ ...style.block }}>
      <ul sx={{ height }}>
        {items.map(item => {
          const Icon = item.icon;
          return (
            <li
              aria-hidden="true"
              onClick={() => onClick(item.id)}
              sx={style.item}
              key={item.id}
            >
              {!!Icon && <Icon />}
              <div sx={style.inner}>
                <div>{item.name}</div>
                <AnimatePresence>
                  {item.isSelected && (
                    <motion.div sx={style.activeCheck} {...tickProps}>
                      <TickIcon width={20} height={20} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const tickProps = {
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
  },
  initial: {
    opacity: 0,
    scale: 0.5,
  },
  transition: {
    ease: theme.easing.smooth.array,
    duration: 0.3,
  },
};

const sx = {
  block: {
    alignItems: 'center',
    display: 'flex',
    borderRadius: 'button',
    bg: 'blueberry.5',
    overflow: 'hidden',

    '> ul': {
      width: '100%',
      overflow: 'auto',
      paddingY: 's',
    },
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    fontSize: 'xs',
    paddingLeft: 'l',
    color: 'blueberry.100',
    transition: 'smooth.fast',

    '> svg': {
      marginRight: 's',
    },

    '> div': {
      flex: 1,
      borderBottom: '1px solid',
      borderColor: 'blueberry.10',
    },

    '&:last-child > div': {
      borderBottom: 'none',
    },

    '&:hover': !hasTouch() && {
      color: 'blackberry',
      bg: 'blueberry.10',
    },
  },
  inner: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 46,
  },
  activeCheck: {
    width: 28,
    height: 28,

    color: 'milk',
    bg: 'blackberry',
    borderRadius: 'circle',
    marginRight: 's',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
