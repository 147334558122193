/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';

import FullHeight from '../components/FullHeight';
import Loader from '../components/Loader';

import theme from '../theme';
import { getIsAuthenticated } from '../selectors';

export default function Loading() {
  const isAuthenticated = useSelector(getIsAuthenticated);

  const bodyStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: isAuthenticated
      ? [0, 0, 0, theme.sizes.sidebar, theme.sizes.sidebar]
      : 0,
  };

  return (
    <FullHeight>
      <div sx={bodyStyle}>
        <Loader />
      </div>
    </FullHeight>
  );
}
