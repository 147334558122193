export const easing = {
  bouncy: {
    string: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
    array: [0.68, -0.55, 0.265, 1.55],
  },
  smooth: {
    string: 'cubic-bezier(0.23, 1, 0.32, 1)',
    array: [0.23, 1, 0.32, 1],
  },
};

export const transitions = {
  standard: {
    fast: '150ms ease-in-out',
  },
  smooth: {
    fast: `all 300ms ${easing.smooth.string}`,
    medium: `all 600ms ${easing.smooth.string}`,
    slow: `all 900ms ${easing.smooth.string}`,
  },
};
