/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { push } from 'connected-react-router';

import GhostButton from '../components/GhostButton';
import Heading from '../components/Heading';
import InputWithLabel from '../components/InputWithLabel';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import ModalHeadingText from '../components/ModalHeadingText';
import PrimaryButton from '../components/PrimaryButton';
import Text from '../components/Text';
import { SuggestEditForm } from './SuggestEdit';
import SuggestionThanksModal from './SuggestionThanks';
import { fetchSearchResults } from '../ducks/search';
import { QuestionIcon } from '../icons';
import { getToken } from '../selectors';
import { suggestNewBook } from '../ducks/books';
import { getBookRoute } from '../constants';

const Step = {
  IsbnSearch: 'ISBN_SEARCH',
  SuggestEdit: 'SUGGEST_EDIT',
  AddBook: 'ADD_BOOK',
  Success: 'SUCCESS',
};

export default function AddBookModal({ onCancel, subtext }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const [step, setStep] = useState(Step.IsbnSearch);
  const [isbn, setIsbn] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  if (step === Step.Success) {
    return <SuggestionThanksModal onClose={onCancel} />;
  }

  if (step === Step.IsbnSearch) {
    return (
      <IsbnSearchStep
        setStep={setStep}
        onCancel={onCancel}
        subtext={subtext}
        setBookIsbn={setIsbn}
      />
    );
  }

  const onSubmit = event => {
    event.preventDefault();
    setLoading(true);
    suggestNewBook({ dispatch, token, ...formData })
      .then(({ book }) => {
        setTimeout(() => {
          if (book?.slug) {
            dispatch(push(getBookRoute(book.slug)));
          }
          setLoading(false);
        }, 600);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <ModalHeading as="div">
          <ModalHeadingText heading="Add a missing book" />
        </ModalHeading>
        <ModalContent extraStyles={sx.body}>
          <SuggestEditForm onDataUpdate={data => setFormData(data)} />
          <InputWithLabel
            id="isbn"
            label="ISBN number"
            placeholder="9781847924018"
            type="number"
            value={isbn}
            onChange={e => setFormData({ ...formData, isbn: e.target.value })}
          />
        </ModalContent>
        <ModalFooter>
          <GhostButton onClick={onCancel}>Cancel</GhostButton>
          <PrimaryButton variant="primary" loading={loading}>
            Next
          </PrimaryButton>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

function IsbnSearchStep({ onCancel, setStep, setBookIsbn }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const [isbn, setIsbn] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const searchIsbn = event => {
    event.preventDefault();
    setError('');

    if (!isbn) {
      setStep(Step.AddBook);
      return null;
    }

    if (![10, 13].includes(isbn.length)) {
      setError('An ISBN is 10 or 13 digits');
      return null;
    }

    setIsLoading(true);

    fetchSearchResults({
      isSearchingForUsers: false,
      maxResults: 1,
      query: isbn,
      token,
    })
      .then(({ results }) => {
        if (results.length > 0) {
          dispatch(push(getBookRoute(results[0].slug)));
          return null;
        }
        setIsLoading(false);
        setStep(Step.AddBook);
      })
      .catch(() => {
        setStep(Step.AddBook);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={searchIsbn}>
      <ModalBody canGrow={false}>
        <ModalHeading as="div">
          <ModalHeadingText heading="Add a missing book" text="Step 1" />
        </ModalHeading>
        <ModalContent>
          <InputWithLabel
            disabled={isLoading}
            error={error}
            id="isbn"
            label="First, let's search by ISBN"
            onChange={event => {
              setIsbn(event.target.value);
              setBookIsbn(event.target.value);
            }}
            placeholder="9781847924018"
            type="number"
          />
          <Text variant="caption" sx={sx.caption}>
            An ISBN is a unique 10 or 13 digit number assigned to each published
            edition of a book.
          </Text>
          <div sx={sx.box}>
            <QuestionIcon />
            <div>
              <Heading variant="subtitle2">No ISBN? No Problem.</Heading>
              <Text variant="caption" sx={sx.subtitle}>
                You can fill in the blanks on the next step.
              </Text>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <GhostButton type="reset" disabled={isLoading} onClick={onCancel}>
            Cancel
          </GhostButton>
          <PrimaryButton type="submit" variant="primary" isLoading={isLoading}>
            Next
          </PrimaryButton>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

const sx = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 's',
  },
  caption: {
    marginTop: 's',
    marginBottom: 'm',
    lineHeight: 'sub',
  },
  box: {
    bg: 'blueberry.5',
    padding: 'm',
    gap: 's',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'button',
  },
  subtitle: {
    marginTop: 'xxs',
  },
};
