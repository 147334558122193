import { hasTouch } from '../utils';

export const styles = {
  root: {
    bg: 'milk',
    fontFamily: 'body',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },
};

export const text = {
  xxl: {
    color: 'blueberry.100',
    fontSize: [32, 'xl', 'xxl'],
    lineHeight: 'heading',
    fontWeight: 'heading',
  },
  marketing: {
    color: 'blackberry',
    fontSize: [28, 28, 38, 38, 48],
    lineHeight: 'heading',
    fontWeight: 400,
    letterSpacing: '-0.006em',
  },
  marketingTight: {
    color: 'blackberry',
    fontSize: [28, 28, 38, 38, 48],
    lineHeight: 1.2,
    fontWeight: 400,
    letterSpacing: '-0.006em',
  },
  marketingSub: {
    fontSize: 13,
    color: 'blueberry.80',
    lineHeight: 'body',
  },
  h1: {
    color: 'blueberry.100',
    fontSize: 'l',
    fontWeight: 'heading',
    lineHeight: 'heading',
    letterSpacing: '-0.7px',
  },
  h2: {
    color: 'blueberry.100',
    fontSize: 'm',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  subheading: {
    color: 'blueberry.80',
    fontSize: 's',
    fontWeight: 'heading',
    lineHeight: 'body',
  },
  body: {
    color: 'blueberry.80',
    fontSize: 'xs',
    fontWeight: 'body',
    lineHeight: 'body',
  },
  author: {
    color: 'blueberry.80',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    fontWeight: 'body',
    lineHeight: 'body',
  },
  subtitle: {
    color: 'blueberry.80',
    fontSize: 'xxs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  overline: {
    color: 'blueberry.80',
    fontSize: 'xxxxs',
    fontWeight: 'heading',
    letterSpacing: '0.07em',
    lineHeight: 'heading',
    textTransform: 'uppercase',
  },
  menu: {
    fontSize: 'xxxs',
    fontWeight: 'heading',
    letterSpacing: '0.16px',
  },
  button: {
    color: 'blueberry.80',
    fontSize: 'xs',
    fontWeight: 'button',
  },

  // New variants, to replace the old ones
  subtitle1: {
    fontSize: 'm',
    color: 'blueberry.100',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  subtitle2: {
    color: 'blueberry.100',
    fontSize: 'xs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  caption: {
    color: 'blueberry.80',
    fontSize: 'xxs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  highlight: {
    color: 'blueberry.100',
    fontFamily: 'serif',
    fontSize: '18px',
    lineHeight: 'body',
  },
  highlightStandalone: {
    color: 'blueberry.100',
    fontFamily: 'serif',
    fontSize: ['18px', '18px', '20px', '22px'],
    lineHeight: 'body',
  },
  secondary: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xxs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
};

export const links = {
  primary: {
    color: 'blueberry.100',
    '&:hover': !hasTouch() && {
      color: 'blackberry',
    },
  },
  secondary: {
    color: 'blueberry.80',
    '&:hover': !hasTouch() && {
      color: 'blueberry.100',
    },
  },
  body: {
    color: 'blueberry.80',
    fontWeight: 'body',
    '&:hover': !hasTouch() && {
      color: 'blueberry.100',
    },
  },
  milk: {
    color: 'milk',
    '&:hover': !hasTouch() && {
      color: 'milk',
    },
  },
  menu: {
    default: {
      color: 'blueberry.50',
      '&:hover': !hasTouch() && {
        color: 'blueberry.100',
      },
    },
    active: {
      color: 'blueberry.100',
      '&:hover': !hasTouch() && {
        color: 'blackberry',
      },
    },
  },
};

export const buttons = {
  primary: {
    bg: 'blackberry',
    boxShadow: '0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
    color: 'milk',
    '&:hover': !hasTouch() && {
      boxShadow: '0px 6px 6px -5px rgba(0, 0, 0, 0.35)',
    },
  },
  light: {
    color: 'blueberry.100',
    bg: 'blueberry.5',
    '&:hover': !hasTouch() && {
      color: 'blueberry.100',
      bg: 'blueberry.10',
    },
  },
  medium: {
    color: 'blueberry.100',
    bg: 'blueberry.10',
    '&:hover': !hasTouch() && {
      color: 'blueberry.100',
      bg: 'blueberry.20',
    },
  },
  strong: {
    color: 'blueberry.100',
    bg: 'blueberry.10',
    '&:hover': !hasTouch() && {
      color: 'blackberry',
      bg: 'blueberry.20',
    },
  },
  ghost: {
    dark: {
      bg: 'transparent',
      color: 'olive',
      boxShadow: 'button.ghost.dark',
      '&:hover': !hasTouch() && {
        color: 'olive',
        bg: 'blueberry.5',
        boxShadow: 'button.ghost.darkHover',
      },
    },
    light: {
      bg: 'transparent',
      color: 'olive',
      boxShadow: 'button.ghost.light',
      '&:hover': !hasTouch() && {
        color: 'olive',
        bg: 'blueberry.5',
        boxShadow: 'button.ghost.lightHover',
      },
    },
  },
  transparent: {
    bg: 'transparent',
    color: 'blueberry.100',
    '&:hover': !hasTouch() && {
      bg: 'transparent',
      color: 'blueberry.100',
    },
  },
  banana: {
    default: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.light',
      '&:hover': !hasTouch() && {
        bg: 'milk',
        color: 'banana.100',
        boxShadow: 'button.banana.light',
      },
    },
    dark: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.dark',
      '&:hover': !hasTouch() && {
        color: 'banana.100',
        bg: 'banana.15',
        boxShadow: 'button.banana.dark',
      },
    },
    active: {
      color: 'milk',
      bg: 'banana.100',
    },
  },
  kiwi: {
    default: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.light',
      '&:hover': !hasTouch() && {
        bg: 'milk',
        color: 'kiwi.100',
        boxShadow: 'button.kiwi.light',
      },
    },
    dark: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.dark',
      '&:hover': !hasTouch() && {
        bg: 'milk',
        color: 'kiwi.100',
        boxShadow: 'button.kiwi.dark',
      },
    },
    active: {
      color: 'milk',
      bg: 'kiwi.100',
    },
  },
  dragonfruit: {
    default: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.light',
      '&:hover': !hasTouch() && {
        bg: 'milk',
        color: 'dragonfruit.100',
        boxShadow: 'button.dragonfruit.light',
      },
    },
    dark: {
      bg: 'transparent',
      color: 'blueberry.100',
      boxShadow: 'button.ghost.dark',
      '&:hover': !hasTouch() && {
        bg: 'milk',
        color: 'dragonfruit.100',
        boxShadow: 'button.dragonfruit.dark',
      },
    },
    active: {
      color: 'milk',
      bg: 'dragonfruit.100',
    },
  },
  facebook: {
    default: {
      color: 'facebook.100',
      bg: 'facebook.5',
      '&:hover': !hasTouch() && {
        color: 'facebook.100',
        bg: 'facebook.15',
      },
    },
    active: {
      color: 'milk',
      bg: 'facebook.100',
    },
  },
  twitter: {
    default: {
      color: 'twitter.100',
      bg: 'twitter.5',
      '&:hover': !hasTouch() && {
        color: 'twitter.100',
        bg: 'twitter.15',
      },
    },
    active: {
      color: 'milk',
      bg: 'twitter.100',
    },
  },
  strawberry: {
    default: {
      color: 'strawberry.100',
      bg: 'strawberry.5',
      '&:hover': !hasTouch() && {
        color: 'strawberry.100',
        bg: 'strawberry.15',
      },
    },
    active: {
      color: 'milk',
      bg: 'strawberry.100',
    },
  },
};

export const forms = {
  input: {
    primary: {
      bg: 'milk',
      color: 'blueberry.100',
      borderColor: 'blueberry.20',
      '&:hover': !hasTouch() && {
        borderColor: 'blueberry.50',
      },
      '&:focus': {
        borderColor: 'blueberry.80',
        boxShadow: 'input.default',
      },
    },
    error: {
      color: 'candy',
      borderColor: 'candy',
      '&:hover': !hasTouch() && {
        borderColor: 'candy',
      },
      '&:focus': {
        borderColor: 'candy',
        boxShadow: 'input.error',
      },
    },
    filled: {
      border: 'none',
      bg: 'blueberry.5',
      color: 'blueberry.100',
      '&:hover': !hasTouch() && {
        bg: 'blueberry.10',
      },
      '&:focus': {
        bg: 'blueberry.10',
      },
    },
  },
};
