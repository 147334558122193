const isProduction = process.env.NODE_ENV === 'production';
export const APP_URL = isProduction
  ? 'https://oku.club'
  : 'http://localhost:3000';

export const API_BOOKS_URL = '/books';
export const API_FEED_GLOBAL_URL = '/feed/global';
export const API_FEED_FRIENDS_URL = '/feed/personal';
export const API_LIST_URL = '/collections';
export const API_PROFILE_URL = '/users/profile';
export const API_SEARCH_URL = '/search/books';
export const API_SEARCH_USERS_URL = '/search/users';
export const API_SEARCH_AUTHORS_URL = '/search/authors';
export const API_SIGN_IN_URL = '/signin';
export const API_SIGN_OUT_URL = '/signout';
export const API_SIGN_UP_URL = '/signup';
export const API_SEND_FORGOT_PASSWORD_URL = '/send-forgot-password';
export const API_SET_FORGOT_PASSWORD_URL = '/set-forgot-password';
export const API_USER_URL = '/users/me';
export const API_DASHBOARD_URL = '/api/users/dashboard';
export const API_EXPLORE_URL = '/api/explore';
export const API_INVITES_URL = '/api/invite';
export const API_BOOK_URL = '/api/books';
export const API_REVIEWS_URL = '/api/reviews';
export const API_GOALS_URL = '/api/goals/annual';
export const API_IMPORTER_URL = '/api/gr/import';
export const API_GR_COMPLETE_AUTH_URL = '/api/gr/complete';

export const getRecommendUrl = id => `${API_BOOK_URL}/${id}/vote`;

export const getBookReviewsUrl = id => `${API_BOOK_URL}/${id}/reviews`;
export const getBookReviewSummaryUrl = id => `${getBookReviewsUrl(id)}/summary`;
export const getProfileReviewUrl = username =>
  `${API_REVIEWS_URL}/user/${username}`;

export const getHighlightUrl = highlightId => `/api/highlights/${highlightId}`;
export const getBookHighlightsUrl = bookId => `/api/highlights/book/${bookId}`;
export const getUserHighlightsUrl = username =>
  `/api/highlights/user/${username}`;
