/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

import Heading from '../components/Heading';
import Image from '../components/Image';
import SignUpForm from '../pages/Auth/SignUp';

import { dogAndMan, dogAndMan2x } from '../assets';

export default function SignUp({ heading = 'Join our book community' }) {
  return (
    <div>
      <AspectRatio ratio={3 / 2}>
        <figure sx={sx.figure}>
          <Image
            alt="Man floating with books and dog around him"
            blendIn
            maxWidth="90%"
            respondToDarkMode
            src={dogAndMan}
            src2x={dogAndMan2x}
            visibleByDefault
            width="100%"
          />
        </figure>
      </AspectRatio>
      <Heading as="h1" variant="h1" sx={sx.heading}>
        {heading}
      </Heading>
      <div sx={sx.form}>
        <SignUpForm />
      </div>
    </div>
  );
}

const sx = {
  heading: {
    paddingX: ['m', 'm', 'xl', 'xl'],
    textAlign: 'center',
    position: 'relative',
    marginTop: [-80, -80, -100, -100],
  },
  figure: {
    bg: 'milk',
    transform: 'scaleX(-1) rotate(70deg)',
    bottom: '0',
    right: '-10%',
    position: 'absolute',
    zIndex: -1,
  },
  form: {
    paddingX: ['l', 'l', 'xl', 'xl'],
    paddingBottom: ['l', 'l', 'xl', 'xl'],
    paddingTop: ['m'],
  },
};
