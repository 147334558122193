/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import GhostButton from '../../components/GhostButton';
import CreateCollection from '../../modals/CreateCollection';
import ModalDialog from '../../components/ModalDialog';
import CardCollection from '../../components/CardCollection';

import { VISIBILITY } from '../../constants';
import { hasTouch } from '../../utils';
import { PencilIcon, LockIcon } from '../../icons';

export default function Collection({ list, isCurrentUser, username }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const openModal = () => {
    setIsEditModalOpen(true);
  };
  const closeModal = () => {
    setIsEditModalOpen(false);
  };

  const { key, visibility } = list;
  const showEditButton = isCurrentUser && key === null;

  return (
    <React.Fragment>
      <div sx={sx.outer}>
        <CardCollection list={list} username={username} />
        {visibility === VISIBILITY.HIDDEN && (
          <div sx={sx.visibility}>
            <div sx={sx.lockIcon}>
              <LockIcon />
            </div>
          </div>
        )}
        {showEditButton && (
          <div sx={sx.button} className="edit-button">
            <GhostButton size="icon" onClick={openModal}>
              <PencilIcon />
            </GhostButton>
          </div>
        )}
      </div>
      <ModalDialog onClose={closeModal} isVisible={isEditModalOpen}>
        <CreateCollection list={list} onClose={closeModal} shouldUpdate />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  outer: {
    position: 'relative',
    '&:hover': !hasTouch() && {
      '.edit-button': {
        opacity: 1,
      },
    },
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 'm',
    opacity: 0,
    transition: 'smooth.fast',
    '> button': {
      bg: 'milk',
    },
  },
  visibility: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 'm',
  },
  lockIcon: {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'blueberry.100',
  },
};
