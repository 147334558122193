/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';

import { getIsAuthenticated } from '../selectors';

const PageBody = ({
  children,
  extraStyles,
  maxWidth = [
    '100%',
    '100%',
    'maxWidth.phablet',
    'maxWidth.tablet',
    'maxWidth.notebook',
    'maxWidth.laptop',
    'maxWidth.desktop',
  ],
}) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const navHeight = isAuthenticated ? 58 : 0;
  const extraSpacingProps = {
    // Tap bar height + border + the safe area on iOS
    paddingBottom: [
      `calc(${navHeight}px + 1px + env(safe-area-inset-bottom))`,
      `calc(${navHeight}px + 1px + env(safe-area-inset-bottom))`,
      `calc(${navHeight}px + 1px + env(safe-area-inset-bottom))`,
      0,
    ],
  };
  return (
    <div
      sx={{
        maxWidth,
        width: '100%',
        boxSizing: 'border-box',
        margin: '0 auto',
        paddingX: ['s', 's', 'l', 'l', 'l', 'l', 'l'],
        ...extraSpacingProps,
        ...extraStyles,
      }}
    >
      {children}
    </div>
  );
};

export default PageBody;
