/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import GhostButton from '../../components/GhostButton';
import AddNewBookModal from '../../modals/AddNewBook';
import ModalDialog from '../../components/ModalDialog';
import { KeyboardIcon } from '../../icons';

export default function AddBookButton() {
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const openAddBookModal = () => {
    setFilterModalOpen(true);
  };
  const closeAddBookModal = () => {
    setFilterModalOpen(false);
  };

  return (
    <React.Fragment>
      <GhostButton size="m" onClick={openAddBookModal} sx={sx.addBookButton}>
        <KeyboardIcon />
        Missing book?
      </GhostButton>
      <ModalDialog isVisible={filterModalOpen} onClose={closeAddBookModal}>
        <AddNewBookModal onCancel={closeAddBookModal} />
      </ModalDialog>
    </React.Fragment>
  );
}

const sx = {
  addBookButton: {
    width: '100%',
    height: '42px',
    lineHeight: '42px',
    '> span:first-child': {
      width: '100%',
      height: '42px',
      lineHeight: '42px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        marginRight: 'xs',
      },
    },
  },
};
