import React from 'react';

import Svg from '../components/Svg';

export default function GoogleIcon({ ...props }) {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="m12.0843 10.8119v2.3762s2.3376-.0031 3.2894-.0031c-.5154 1.5405-1.3168 2.3794-3.2894 2.3794-1.9962 0-3.55418-1.5959-3.55418-3.5644s1.55798-3.56435 3.55418-3.56435c1.0555 0 1.7371.36582 2.3623.8757.5005-.49354.4587-.56389 1.732-1.74969-1.0809-.97022-2.5175-1.56166-4.0943-1.56166-3.36026 0-6.0843 2.68628-6.0843 6 0 3.3137 2.72404 6 6.0843 6 5.0228 0 6.2504-4.3129 5.8434-7.1881z" />
    </Svg>
  );
}
