/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import { jsx } from 'theme-ui';
import moment from 'moment';

import Badge from '../components/Badge';
import Button from '../components/Button';
import Counter from '../components/Counter';
import Heading from '../components/Heading';
import PrimaryButton from '../components/PrimaryButton';
import ReadingGoalLogo from '../components/ReadingGoalLogo';
import Text from '../components/Text';

import {
  getToken,
  getActiveReadingGoal,
  hasFeature,
  FEATURES,
} from '../selectors';

import { TIERS, PRICING_ROUTE } from '../constants';
import { setReadingGoal } from '../ducks/goals';

export default function ReadingGoalModal({ onClose }) {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const count = useSelector(getActiveReadingGoal);
  const [books, setBooks] = useState(count || 5);
  const [isFocussed, setIsFocussed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const enableGoals = useSelector(hasFeature(FEATURES.GOALS));

  const increaseBookCount = () => {
    if (books === 999) {
      return;
    }
    setBooks(books + 1);
  };

  const decreaseBookCount = () => {
    if (books === 0) {
      return;
    }
    setBooks(books - 1);
  };

  const onInputChange = event => {
    const { value } = event.target;
    if (value === '') {
      setBooks(0);
      return;
    }

    const valueNumber = parseInt(event.target.value, 10);

    if (valueNumber > 999) {
      setBooks(999);
      return;
    }

    setBooks(valueNumber);
  };

  const onInputFocus = () => {
    setIsFocussed(true);
  };

  const onInputBlur = () => {
    setIsFocussed(false);
  };

  const submitBookCount = () => {
    setIsLoading(true);
    setReadingGoal({ target: books, token, dispatch })
      .then(() => {
        onClose();
        toast('Goal updated, happy reading!', { autoClose: 4500 });
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        toast(
          "Oops, couldn't update your goal! Please try again in a few minutes.",
          { autoClose: 7500 }
        );
        setIsLoading(false);
      });
  };

  const getBookPerMonth = () => {
    const booksPerMonth = books / 12;

    if (booksPerMonth === 0) {
      return 'No books at all this year? :(';
    }
    if (booksPerMonth < 1 && booksPerMonth > 0.4) {
      return `Around half a book per month`;
    }
    if (booksPerMonth < 1) {
      return 'Less than 1 book per month';
    }
    if (booksPerMonth < 2 && booksPerMonth > 1.4) {
      return `Around 1 and a half books per month`;
    }
    if (booksPerMonth < 2) {
      return `Around a book per month`;
    }

    return `~ ${parseFloat(booksPerMonth).toFixed(1)} books per month`;
  };

  const averageBooksCount = getBookPerMonth();
  const year = moment().format('YYYY');

  if (!enableGoals) {
    return (
      <div sx={sx.container}>
        <div sx={sx.logo}>
          <ReadingGoalLogo />
        </div>
        <Heading as="h1" fontFamily="serif" sx={sx.heading}>
          <div sx={sx.headingText}>Reading Goal {year}</div>{' '}
          <Badge kind={TIERS.PREMIUM} />
        </Heading>
        <Text as="p" sx={sx.subheading}>
          Upgrade for annual reading goals, personal stats and more!
        </Text>
        <Button
          as={RouterLink}
          sx={{ ...sx.button, marginTop: 'l' }}
          to={PRICING_ROUTE}
        >
          More information
        </Button>
      </div>
    );
  }

  return (
    <div sx={sx.container}>
      <div sx={sx.logo}>
        <ReadingGoalLogo />
      </div>
      <Heading as="h1" fontFamily="serif" sx={sx.heading}>
        Reading Goal {year}
      </Heading>
      <Text as="p" sx={sx.subheading}>
        How many books do you want to read?
      </Text>
      <main sx={sx.body}>
        <div sx={sx.counter}>
          <Counter
            onDecrease={decreaseBookCount}
            onIncrease={increaseBookCount}
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={isFocussed ? books : `${books} per year`}
          />
        </div>
        <div sx={sx.average}>{averageBooksCount}</div>
      </main>
      <PrimaryButton
        isLoading={isLoading}
        onClick={submitBookCount}
        sx={sx.button}
      >
        Set your goal
      </PrimaryButton>
    </div>
  );
}

const sx = {
  container: {
    padding: ['s', 's', 'm'],
  },
  logo: {
    marginTop: 'l',
    marginBottom: 's',
    display: 'flex',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 'l',
    color: 'blueberry.100',
    fontWeight: 'heading',
    textAlign: 'center',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingText: {
    marginRight: 'xs',
  },
  subheading: {
    textAlign: 'center',
    paddingX: 'm',
  },
  body: {
    marginY: 'l',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  counter: {
    maxWidth: 320,
  },
  average: {
    textAlign: 'center',
    marginTop: 's',
    color: 'blueberry.80',
    fontSize: 'xxs',
  },
  button: {
    width: '100%',
  },
};
