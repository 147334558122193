import React from 'react';

import Svg from '../components/Svg';

export default function HomeTop() {
  return (
    <Svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.5185C7 11.864 7.52233 11.3333 8.16667 11.3333H19.8333C20.4777 11.3333 21 11.864 21 12.5185V20.8148C21 21.4694 20.4777 22 19.8333 22H8.16667C7.52233 22 7 21.4694 7 20.8148V12.5185ZM19.8333 12.5185H8.16667V20.8148H19.8333V12.5185Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.18519C13.4585 7.18519 12.9392 7.4037 12.5563 7.79267C12.1734 8.18163 11.9583 8.70918 11.9583 9.25926V11.9259C11.9583 12.2532 11.6972 12.5185 11.375 12.5185C11.0528 12.5185 10.7917 12.2532 10.7917 11.9259V9.25926C10.7917 8.39485 11.1297 7.56584 11.7314 6.95461C12.333 6.34339 13.1491 6 14 6C14.8509 6 15.667 6.34339 16.2686 6.95461C16.8703 7.56584 17.2083 8.39485 17.2083 9.25926V11.9259C17.2083 12.2532 16.9472 12.5185 16.625 12.5185C16.3028 12.5185 16.0417 12.2532 16.0417 11.9259V9.25926C16.0417 8.70918 15.8266 8.18163 15.4437 7.79267C15.0608 7.4037 14.5415 7.18519 14 7.18519Z"
      />
      <path d="M14 17.5556C14.4832 17.5556 14.875 17.1576 14.875 16.6667C14.875 16.1757 14.4832 15.7778 14 15.7778C13.5168 15.7778 13.125 16.1757 13.125 16.6667C13.125 17.1576 13.5168 17.5556 14 17.5556Z" />
    </Svg>
  );
}
