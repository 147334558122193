/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function BaseButton({ name, sx, children, ...props }) {
  return (
    <button
      type="button"
      sx={{
        border: 'none',
        outline: 'none',
        padding: 0,
        margin: 0,
        userSelect: 'none',
        boxShadow: 'none',
        ...sx,
      }}
      {...props}
    >
      {children}
    </button>
  );
}
