import { push } from 'connected-react-router';
import mixpanel from 'mixpanel-browser';
import { NOT_FOUND_ROUTE, API_BOOKS_URL, getRecommendUrl } from '../constants';

import { fetchApi } from '../utils/api';
import { updateListBookAddedAt } from './lists';

// Actions
export const BOOKS_LOADING = 'BOOKS_LOADING';
export const BOOKS_LOADED = 'BOOKS_LOADED';
export const ADD_BOOKS = 'ADD_BOOKS';
export const BUMP_BOOK_REVIEW_COUNT = 'BUMP_BOOK_REVIEW_COUNT';
export const DECREASE_BOOK_REVIEW_COUNT = 'DECREASE_BOOK_REVIEW_COUNT';

// Reducer
const booksReducer = (
  state = {
    areLoading: true,
    items: {},
  },
  action
) => {
  switch (action.type) {
    case ADD_BOOKS:
      const books = (state.books || [])
        .concat(action.books)
        .reduce((acc, curr) => {
          if (curr) {
            acc[curr.id] = {
              ...curr,
              collections: action.collections,
              friends: action.friends,
              highlightCount: action.highlightCount,
              isComplete: action.isComplete,
              otherCollections: action.otherCollections,
              recommended: action.recommended,
              recommendedBy: action.recommendedBy,
              reviewCount: action.reviewCount,
              tags: action.tags,
              yourReview: action.yourReview,
            };
          }
          return acc;
        }, {});
      return {
        ...state,
        items: {
          ...state.items,
          ...books,
        },
      };
    case BOOKS_LOADING:
      return {
        ...state,
        areLoading: true,
      };
    case BOOKS_LOADED:
      return {
        ...state,
        areLoading: false,
      };
    case BUMP_BOOK_REVIEW_COUNT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.bookId]: {
            ...state.items[action.bookId],
            reviewCount: state.items[action.bookId].reviewCount + 1,
          },
        },
      };
    case DECREASE_BOOK_REVIEW_COUNT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.bookId]: {
            ...state.items[action.bookId],
            reviewCount: state.items[action.bookId].reviewCount - 1,
          },
        },
      };
    default:
      return state;
  }
};

// Action creators
export const addBooks = ({
  books,
  collections,
  friends,
  highlightCount,
  isComplete,
  otherCollections,
  recommended,
  recommendedBy,
  reviewCount,
  tags,
  yourReview,
}) => ({
  books,
  collections,
  friends,
  highlightCount,
  isComplete: isComplete !== false,
  otherCollections,
  recommended,
  recommendedBy,
  reviewCount,
  tags,
  type: ADD_BOOKS,
  yourReview,
});

export const booksLoading = () => ({
  type: BOOKS_LOADING,
});

export const booksLoaded = () => ({
  type: BOOKS_LOADED,
});

export const bumpBookReviewCount = ({ bookId }) => ({
  type: BUMP_BOOK_REVIEW_COUNT,
  bookId,
});

export const decreaseBookReviewCount = ({ bookId }) => ({
  type: DECREASE_BOOK_REVIEW_COUNT,
  bookId,
});

// Side effects
export function getBookDetails({ bookId, token, dispatch, shouldLoad = true }) {
  if (shouldLoad) {
    dispatch(booksLoading());
  }
  fetchApi(`${API_BOOKS_URL}/${bookId}`, {
    token,
  })
    .then(response => {
      if (response.status !== 404) {
        return response.json();
      }
      dispatch(push(NOT_FOUND_ROUTE));
    })
    .then(
      ({
        book,
        collections,
        friends,
        highlightCount,
        isComplete,
        otherCollections,
        recommended,
        recommendedBy,
        reviewCount,
        tags,
        yourReview,
      }) => {
        dispatch(
          addBooks({
            books: [book],
            collections,
            friends,
            highlightCount,
            isComplete,
            otherCollections,
            recommended,
            recommendedBy,
            reviewCount,
            tags,
            yourReview,
          })
        );
        setTimeout(() => {
          dispatch(booksLoaded());
        }, 600);
      }
    );
}

export function recommendBook({
  bookId,
  dispatch,
  readDate,
  recommend,
  token,
  tags,
}) {
  const API_RECOMMEND_URL = getRecommendUrl(bookId);
  return fetchApi(API_RECOMMEND_URL, {
    method: 'POST',
    body: {
      direction: recommend,
      read_date: readDate,
      tags: tags.map(t => t.toLowerCase()),
    },
    token,
  }).then(() => {
    dispatch(
      updateListBookAddedAt({
        list: { key: 'read' },
        bookId,
        addedAt: readDate,
      })
    );
    getBookDetails({ bookId, token, dispatch, shouldLoad: false });
    mixpanel.track('Rate Book', {
      recommended: recommend,
    });
    window.plausible('rate-book');
  });
}

export function removeRecommendation({ bookId, token }) {
  const API_RECOMMEND_URL = getRecommendUrl(bookId);
  fetchApi(API_RECOMMEND_URL, {
    method: 'DELETE',
    token,
  });
}

export function reportBookData({ token, bookId, reportData }) {
  return fetchApi(`${API_BOOKS_URL}/${bookId}/report`, {
    token,
    method: 'POST',
    body: reportData,
  });
}

export function suggestBookDetails({ dispatch, token, bookId, ...data }) {
  const requestData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    requestData.append(key, value);
  });

  return fetch(`/books/${bookId}/edit`, {
    headers: {
      Authorization: `Token ${token}`,
    },
    method: 'put',
    body: requestData,
  }).then(async response => {
    if (!response.ok) {
      return Promise.reject(response);
    }
    return response.json();
  });
}

export function suggestNewBook({ dispatch, token, ...data }) {
  const requestData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    requestData.append(key, value);
  });

  return fetch(`/books/submit`, {
    headers: {
      Authorization: `Token ${token}`,
    },
    method: 'post',
    body: requestData,
  }).then(async response => {
    if (!response.ok) {
      return Promise.reject(response);
    }
    return response.json();
  });
}

export default booksReducer;
