/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import mixpanel from 'mixpanel-browser';

import PrimaryButton from './PrimaryButton';
import Image from './Image';
import ModalDialog from './ModalDialog';
import Heading from './Heading';
import Text from './Text';
import InviteUsers from '../modals/InviteUsers';

import { dog, dog2x } from '../assets';
import { shouldShowUpsells } from '../selectors';
import { hasTouch } from '../utils';

export default function InviteUsersBlock() {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const closeInvite = () => {
    setIsInviteOpen(false);
  };

  const openInvite = () => {
    setIsInviteOpen(true);
    mixpanel.track('Modal: Invite User', {
      from: 'feed',
    });
  };

  const showUpsells = useSelector(shouldShowUpsells);

  return (
    <React.Fragment>
      <ModalDialog isVisible={isInviteOpen} onClose={closeInvite}>
        <InviteUsers onClose={closeInvite} />
      </ModalDialog>
      <div aria-hidden="true" onClick={openInvite} sx={sx.box}>
        <figure sx={sx.leftDog}>
          <Image
            alt="Dog goofying around"
            maxWidth={[240, 240, 380, 380]}
            respondToDarkMode
            src={dog}
            src2x={dog2x}
          />
        </figure>
        <figure sx={sx.rightDog}>
          <Image
            alt="Dog goofying around"
            maxWidth={[240, 240, 400, 400]}
            respondToDarkMode
            src={dog}
            src2x={dog2x}
          />
        </figure>
        <Heading as="h2" variant="h1" sx={sx.heading} fontFamily="serif">
          Invite a friend
        </Heading>
        <Text as="p" variant="subtitle2" sx={sx.lastParagraph}>
          {showUpsells
            ? 'Refer your friends to earn a free month of Oku Premium.'
            : 'Books are better with friends'}
        </Text>
        <PrimaryButton transformOnHover={false}>Grab your code</PrimaryButton>
      </div>
    </React.Fragment>
  );
}

const sx = {
  box: {
    border: '1px solid',
    borderColor: 'blueberry.10',
    borderRadius: 'button',

    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: ['xl', 'xl', 'xxl'],
    paddingX: 'm',
    cursor: 'pointer',
    transition: 'smooth.fast',
    position: 'relative',
    overflow: 'hidden',
    willChange: 'transform',

    p: {
      lineHeight: 'sub',
      marginTop: 'xs',
      marginBottom: 'm',
      maxWidth: 240,
    },

    '&:hover': !hasTouch() && {
      bg: 'blueberry.2',
      borderColor: 'blueberry.20',
      transform: 'translate3d(0, -1px, 0)',
    },
    '&:active': {
      transform: 'translate3d(0, 0, 0)',
    },

    button: {
      position: 'relative',
    },
  },
  leftDog: {
    position: 'absolute',
    top: ['-60px', '-60px', '-150px', '-150px', '-125px'],
    left: ['-120px', '-120px', '-140px'],
    transform: 'scaleX(-1) rotate(-40deg)',
  },
  rightDog: {
    position: 'absolute',
    bottom: ['-60px', '-60px', '-150px', '-150px', '-130px'],
    right: ['-100px', '-100px', '-200px'],
    transform: 'rotate(220deg)',
  },
};
