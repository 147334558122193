/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useResponsiveValue } from '@theme-ui/match-media';

import SwiperContainer from '../../components/SwiperContainer';
import SwiperGrid from '../../components/SwiperGrid';
import SwiperHeading from '../../components/SwiperHeading';
import CollectionFriend from '../../components/CollectionFriend';

import { getRandomClassName } from '../../utils';

export default function SwiperCollections({ collections, responsiveHeading }) {
  const defaultHeading = ['Collection updates'];
  const header = useResponsiveValue(responsiveHeading || defaultHeading);

  const grid = useState(getRandomClassName())[0];
  const prev = useState(getRandomClassName())[0];
  const next = useState(getRandomClassName())[0];

  return (
    <SwiperContainer>
      <SwiperHeading
        heading={header}
        prevClassName={prev}
        nextClassName={next}
      />
      <SwiperGrid
        gridClassName={grid}
        prevClassName={prev}
        nextClassName={next}
        paddingBottom={40 + 16}
      >
        {collections.map(item => {
          const list = {
            ...item.list,
            // todo: sort this out elsewhere?
            books: item.books?.results || [],
          };
          return (
            <SwiperSlide key={item.id}>
              <CollectionFriend list={list} who={item.user} />
            </SwiperSlide>
          );
        })}
      </SwiperGrid>
    </SwiperContainer>
  );
}
