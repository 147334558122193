import React from 'react';

import Svg from '../components/Svg';

export default function ArrowLeftIcon(props) {
  return (
    <Svg {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9421 7.30587C14.3254 7.70342 14.3139 8.33648 13.9164 8.71985L9.47768 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H9.47768L13.9164 19.2802C14.3139 19.6635 14.3254 20.2966 13.9421 20.6941C13.5587 21.0917 12.9256 21.1032 12.5281 20.7198L6.30586 14.7198C6.11041 14.5314 6 14.2715 6 14C6 13.7285 6.11041 13.4686 6.30586 13.2802L12.5281 7.28016C12.9256 6.8968 13.5587 6.90831 13.9421 7.30587Z"
      />
    </Svg>
  );
}
