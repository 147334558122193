/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';

import GhostButton from '../../components/GhostButton';
import Heading from '../../components/Heading';
import Text from '../../components/Text';

import { FEATURES, getToken, hasFeature } from '../../selectors';
import {
  beginImport,
  disconnectGoodreads,
  fetchGoodreadsInfo,
} from '../../ducks/goodreads';
import Button from '../../components/Button';
import ConfirmDenyModal from '../../modals/ConfirmDeny';
import ModalDialog from '../../components/ModalDialog';
import { formatDate } from '../../utils';

export default function Integrations() {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [lastSynced, setLastSynced] = useState(false);
  useEffect(() => {
    fetchGoodreadsInfo({ token }).then(({ connected, last_sync }) => {
      setIsConnected(connected);
      if (last_sync) {
        try {
          setLastSynced(new Date(last_sync));
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, []);

  const enabled = useSelector(hasFeature(FEATURES.IMPORTER_IOS));
  if (!enabled) {
    return null;
  }
  const closeModal = e => {
    e?.preventDefault();
    setModalVisible(false);
  };
  const openModal = e => {
    e?.preventDefault();
    setModalVisible(true);
  };

  const startImport = evt => {
    evt.preventDefault();
    const onGoodreadsConnected = () => {
      setIsConnected(true);
      setIsImporting(true);
    };
    beginImport({ token, dispatch, onGoodreadsConnected })
      .then(() => {
        setIsConnected(true);
        setIsImporting(false);
        setLastSynced(new Date());
      })
      .catch(e => {
        setIsImporting(false);
        toast(
          'Unable to import because of an error. Please wait a bit and try again.',
          { autoClose: 7500 }
        );
        console.error('Error importing:', e);
      });
  };
  const confirmDisconnect = evt => {
    evt.preventDefault();
    disconnectGoodreads({ token }).then(() => {
      toast('Goodreads has been disconnected', { autoClose: 7500 });
      mixpanel.track('Goodreads: Disconnect');
      setIsConnected(false);
      setLastSynced(false);
      setIsImporting(false);
      closeModal();
    });
  };

  return (
    <React.Fragment>
      <Heading variant="h2" as="h2" sx={sx.heading}>
        Integrations
      </Heading>
      <div sx={sx.block}>
        <div sx={{ paddingRight: 'l' }}>
          <Heading variant="h2" as="h2" marginBottom="xs">
            Goodreads
          </Heading>
          <Text variant="body" as="p">
            Import books and collections from your Goodreads account.
          </Text>
        </div>
        <div sx={sx.buttons}>
          <div>
            <GhostButton
              onClick={startImport}
              isLoading={isImporting}
              sx={sx.import}
            >
              {isConnected ? 'Sync' : 'Connect & Import'} ↗
            </GhostButton>

            {isImporting ? (
              <Text variant="subtitle" as="p" sx={sx.lastSynced}>
                Syncing..
              </Text>
            ) : null}

            {lastSynced && !isImporting && (
              <Text variant="subtitle" as="p" sx={sx.lastSynced}>
                Synced on {formatDate(lastSynced)}
              </Text>
            )}
          </div>
          {isConnected && (
            <Button
              variant="transparent"
              onClick={openModal}
              sx={sx.disconnect}
            >
              Disconnect
            </Button>
          )}
          <ModalDialog isVisible={modalVisible} onClose={closeModal}>
            <ConfirmDenyModal
              heading="Disconnect Goodreads?"
              subtext="You can reconnect at any time in the future."
              confirmText="Disconnect"
              onDeny={closeModal}
              onConfirm={confirmDisconnect}
            />
          </ModalDialog>
        </div>
      </div>
    </React.Fragment>
  );
}

const sx = {
  heading: {
    marginTop: 'xl',
    marginBottom: 'm',
    paddingLeft: 'xxs',
  },
  block: {
    borderRadius: 'button',
    bg: 'blueberry.5',
    padding: 'l',
  },
  buttons: {
    width: '100%',
    marginTop: ['s', 's', 's', 's'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  import: {
    bg: 'milk',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  disconnect: {
    justifySelf: 'flex-end',
    color: 'dragonfruit.100',
    ':hover': {
      color: 'dragonfruit.100',
    },
  },
  lastSynced: {
    marginLeft: 'xs',
    display: 'inline-block',
  },
};
