/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import GhostButton from '../../components/GhostButton';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import ListLandscape from '../../components/ListLandscape';
import ListPortrait from '../../components/ListPortrait';
import PrimaryButton from '../../components/PrimaryButton';
import Text from '../../components/Text';

import { girlReading, girlReading2x } from '../../assets';

import {
  getListBooks,
  getProfileFullName,
  getProfileReadingList,
  getProfileReadList,
  getProfileToReadList,
  getProfile,
  getToken,
  getUsername,
  hasFeature,
  FEATURES,
} from '../../selectors';

import {
  getReadingRoute,
  getReadRoute,
  getToReadRoute,
  SEARCH_ROUTE,
} from '../../constants';

import ProfileWrap from './ProfileWrap';
import { beginImport } from '../../ducks/goodreads';

export default function Profile() {
  const match = useRouteMatch();
  const currentUser = useSelector(getUsername);
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const { username } = match.params;
  const user = useSelector(getProfile(username));

  const fullName = getProfileFullName(user);

  const readingList = getProfileReadingList(user);
  const booksReading = getListBooks(readingList);
  const readingRoute = getReadingRoute(username);

  const toReadList = getProfileToReadList(user);
  const booksToRead = getListBooks(toReadList);
  const toReadRoute = getToReadRoute(username);

  const readList = getProfileReadList(user);
  const booksRead = getListBooks(readList);
  const readRoute = getReadRoute(username);

  const isCurrentUser = currentUser === username;
  const shouldShowEmptyState =
    booksRead.length === 0 &&
    booksToRead.length === 0 &&
    booksReading.length === 0;

  const portraintCap = useResponsiveValue([4, 4, 4, 4, 3, 4, 5]);
  const landscapeCap = useResponsiveValue([4, 4, 4, 4, 4, 4, 6]);

  const enableImport = useSelector(hasFeature(FEATURES.IMPORTER_IOS));
  let bodyCopy = `${fullName} isn't tracking any books just yet.`;
  const searchCopy = 'Browse Explore';
  if (isCurrentUser) {
    if (!enableImport) {
      bodyCopy = 'Take a look at Explore to get started building your library';
    } else {
      bodyCopy =
        'Take a look at Explore to get started building your library or import your books from Goodreads.';
    }
  }

  const startImport = () => {
    beginImport({ dispatch, token }).catch(e => {
      toast(
        'Unable to import because of an error. Please wait a bit and try again.',
        { autoClose: 7500 }
      );
      console.error('Error Importing:', e);
    });
  };

  return (
    <ProfileWrap shouldShowEmptyState={shouldShowEmptyState}>
      {shouldShowEmptyState ? (
        <div sx={sx.emptyState}>
          <div />
          <div sx={sx.emptyStateBody}>
            <Heading fontFamily="serif" variant="h1" as="h2">
              Nothing but cobwebs
            </Heading>
            <Text variant="body" as="p">
              {bodyCopy}
            </Text>
            {isCurrentUser && (
              <GhostButton
                as={RouterLink}
                hasUnderline
                to={SEARCH_ROUTE}
                sx={sx.button}
              >
                {searchCopy}
              </GhostButton>
            )}
            {isCurrentUser && enableImport && (
              <PrimaryButton as="a" onClick={startImport} sx={sx.button}>
                Import from Goodreads ↗
              </PrimaryButton>
            )}
          </div>
          <figure sx={sx.image}>
            <Image
              alt="Girl reading a book"
              maxWidth={300}
              respondToDarkMode
              src={girlReading}
              src2x={girlReading2x}
              width="100%"
            />
          </figure>
        </div>
      ) : (
        <div sx={sx.body}>
          {booksReading.length > 0 && (
            <div sx={sx.section}>
              <ListPortrait
                books={booksReading}
                heading="Reading"
                cap={portraintCap}
                route={readingRoute}
              />
            </div>
          )}
          {booksRead.length > 0 && (
            <div sx={sx.section}>
              <ListLandscape
                heading="Read"
                books={booksRead}
                cap={landscapeCap}
                route={readRoute}
              />
            </div>
          )}
          {booksToRead.length > 0 && (
            <div sx={sx.section}>
              <ListLandscape
                heading="To Read"
                books={booksToRead}
                cap={landscapeCap}
                route={toReadRoute}
              />
            </div>
          )}
        </div>
      )}
    </ProfileWrap>
  );
}

const sx = {
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginLeft: -8, // xs
    width: 'calc(100% + 16px)', // xs x2
  },
  listItem: {
    width: ['100%', '100%', '50%', '33.333%'],
    padding: 'xs',
  },
  emptyState: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 'xl',
  },
  emptyStateBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '>p': {
      marginTop: 'xs',
    },
  },
  image: {
    bg: 'milk',
    minHeight: [254, 254, 204, 204],
    overflow: 'hidden',
    '> span': {
      marginBottom: [-50, -50, -100, -100],
    },
  },
  button: {
    minWidth: 210,
    marginTop: 'm',
    '&:last-child': {
      marginTop: 's',
    },
  },
  section: {
    marginTop: 'xl',
  },
  body: {
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
};
