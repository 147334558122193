/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Text from '../../components/Text';

export default function SearchEmptyState({ heading, subheading }) {
  return (
    <div sx={sx.emptyState}>
      <Heading fontFamily="serif" variant="h1" as="h1">
        {heading}
      </Heading>
      <Text variant="body" as="p" sx={sx.text}>
        {subheading}
      </Text>
    </div>
  );
}

const sx = {
  emptyState: {
    textAlign: 'center',
    '>p': {
      marginTop: 'xs',
    },
  },
};
