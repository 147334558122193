/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import {
  adamCollier,
  fabrizioRinaldi,
  ifttt,
  simonedavico,
} from '../../../assets';

const sx = {
  code: {
    width: '100%',
    height: '80px',
    iframe: {
      width: '100%',
    },
  },
  image: {
    margin: 'l',
    img: {
      margin: '0 auto',
      border: '2px solid',
      borderColor: 'blueberry.10',
      borderRadius: 'book',
    },
  },
};

export default function BlogPost() {
  return (
    <BlogPageWrap title="Oku has RSS Feeds / Blog">
      <time>Jul 17, 2021</time>
      <h1>Oku has RSS Feeds</h1>

      <p>
        Every collection on Oku has basic support for RSS feeds. These feeds
        will let you retrieve the books inside a collection and are supported by
        many tools such as IFTTT and Zapier.
      </p>

      <h2 id="Finding your RSS feeds">Finding your RSS feeds</h2>
      <p>
        Your RSS reader probably supports auto discovery, so you can paste your
        collection link into your reader and away you go. Otherwise, you need to
        navigate to the collection page on a desktop and view the page source.{' '}
      </p>
      <br />
      <p>The RSS link will look like this:</p>

      <div sx={sx.code}>
        <iframe
          title="Link"
          src="https://gist.github.com/joealcorn/351929ddafa48e12f29e5f4cd4eeda37.pibb"
        />
      </div>

      <h2 id="What can I use this for?">What can I use this for?</h2>
      <br />
      <h3 id="Send to Notion / Twitter / Tumblr / ???">
        Send to Notion / Twitter / Tumblr / ???
      </h3>
      <p>
        <Link target="_blank" href="https://ifttt.com/search/query/rss">
          IFTTT
        </Link>{' '}
        is easy to use and supports posting RSS feeds to so many different
        services, and{' '}
        <Link target="_blank" href="https://zapier.com/apps/rss/integrations">
          Zapier
        </Link>{' '}
        is similar but aimed more at serious users and businesses that need more
        flexibility. Both have free plans.
      </p>

      <p>
        <Image
          sx={sx.image}
          alt=""
          src={ifttt}
          src2x={ifttt}
          visibleByDefault={false}
        />
      </p>

      <h3 id="Subscribe to collection updates">
        Subscribe to collection updates
      </h3>
      <p>
        The traditional usage of RSS is to subscribe to a feed using an RSS
        reader to notify you when new things appear.
      </p>

      <h3 id="On your website">On your website</h3>
      <p>
        Some members have gone further and used it to display what they’re
        currently reading or have recently read on their home pages!
      </p>

      <p>
        <Link
          target="_blank"
          href="https://adamcollier.co.uk/?utm_source=oku&utm_medium=blog"
        >
          Adam Collier
        </Link>{' '}
        is using these feeds to display his latest activity on his own website:
      </p>
      <a
        target="_blank"
        rel="noopener"
        href="https://adamcollier.co.uk/?utm_source=oku&utm_medium=blog"
      >
        <Image
          sx={sx.image}
          alt=""
          src={adamCollier}
          src2x={adamCollier}
          visibleByDefault={false}
        />
      </a>
      <p>
        <Link
          target="_blank"
          href="https://fabriziorinaldi.com/?utm_source=oku&utm_medium=blog"
        >
          Fabrizio Rinaldi
        </Link>{' '}
        is doing something similar with his own feeds:
      </p>
      <a
        target="_blank"
        rel="noopener"
        href="https://fabriziorinaldi.com/?utm_source=oku&utm_medium=blog"
      >
        <Image
          visibleByDefault={false}
          sx={sx.image}
          alt=""
          src={fabrizioRinaldi}
          src2x={fabrizioRinaldi}
        />
      </a>
      <p>
        <Link
          target="_blank"
          href="https://github.com/simonedavico?utm_source=oku&utm_medium=blog"
        >
          Simone D’Avico
        </Link>{' '}
        has used the feeds to update his GitHub profile with the books he’s
        reading and planning to read next. (And the code is{' '}
        <Link
          target="_blank"
          href="https://github.com/simonedavico/simonedavico/blob/main/index.js"
        >
          open source
        </Link>
        !)
      </p>
      <a
        target="_blank"
        rel="noopener"
        href="https://github.com/simonedavico?utm_source=oku&utm_medium=blog"
      >
        <Image
          sx={sx.image}
          alt=""
          src={simonedavico}
          src2x={simonedavico}
          visibleByDefault={false}
        />
      </a>
      <h2 id="Email us">Email us</h2>
      <p>
        If enough people get use out of these we’ll integrate it further into
        the product. We’d also love to show off more examples above, so{' '}
        <Link href="mailto:team@oku.club">let us know</Link> if you do something
        cool with these feeds!
      </p>
    </BlogPageWrap>
  );
}
