import React from 'react';
import { Tooltip } from 'react-lightweight-tooltip';

import { hexToRGB } from '../utils';
import theme from '../theme';

const CARET_SIZE = 4;

export default function StyledTooltip({
  children,
  content,
  cursor = 'pointer',
  display = 'inline-block',
  padding,
  pointerEvents,
  position = 'top',
  whiteSpace = 'nowrap',
  width,
  ...props
}) {
  if (!content) return children;
  return (
    <Tooltip
      content={content}
      styles={getTooltipStyle({
        cursor,
        display,
        padding,
        pointerEvents,
        // eslint-disable-next-line
        position: positions[position],
        whiteSpace,
        width,
      })}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export function getTooltipStyle({
  cursor,
  display,
  padding = '5px 10px 5px',
  pointerEvents,
  position,
  whiteSpace,
  width,
}) {
  return {
    wrapper: {
      cursor,
      display,
      zIndex: 'unset',
    },
    content: {
      backgroundColor: 'transparent',
      color: theme.colors.milk,
      display: 'block',
      fontSize: 11,
      lineHeight: 1.5,
      padding: 0,
      whiteSpace,
      width,
    },
    tooltip: {
      backgroundColor: hexToRGB(theme.colors.blackberry, 0.9),
      borderRadius: 3,
      display: 'block',
      margin: 0,
      minWidth: 0,
      zIndex: 20,
      padding,
      pointerEvents,
      ...position.tooltip,
    },
    arrow: {
      ...position.arrow,
    },
    gap: {
      ...position.gap,
    },
  };
}

export const positions = {
  top: {
    tooltip: {
      top: 'auto',
      bottom: '100%',
      margin: '0 0 10px 0',
    },
    arrow: {
      left: 'calc(50% + 1px)',
      bottom: `-${CARET_SIZE}px`,
      borderTop: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderLeft: `solid transparent ${CARET_SIZE}px`,
      borderRight: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      height: '10px',
      bottom: '-10px',
      left: 0,
    },
  },
  left: {
    tooltip: {
      left: 'auto',
      right: '100%',
      bottom: 'auto',
      top: '50%',
      margin: '0 10px 0 0',
      transform: 'translateY(-50%)',
    },
    arrow: {
      top: '50%',
      bottom: 'auto',
      left: 'auto',
      right: `-${CARET_SIZE}px`,
      transform: 'translateY(-50%)',
      borderRight: 'none',
      borderLeft: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderTop: `solid transparent ${CARET_SIZE}px`,
      borderBottom: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      width: '10px',
      left: '-10px',
      top: 0,
    },
  },
  right: {
    tooltip: {
      right: 'auto',
      left: '100%',
      bottom: 'auto',
      top: '50%',
      margin: '0 0 0 10px',
      transform: 'translateY(-50%)',
    },
    arrow: {
      top: '50%',
      bottom: 'auto',
      right: 'auto',
      left: 1,
      transform: 'translateY(-50%)',
      borderLeft: 'none',
      borderRight: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderTop: `solid transparent ${CARET_SIZE}px`,
      borderBottom: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      width: '10px',
      left: 'auto',
      right: '-10px',
      top: 0,
    },
  },
  topLeft: {
    tooltip: {
      top: 'auto',
      bottom: '100%',
      left: 0,
      right: 'auto',
      margin: '0 0 10px 0',
      transform: 'none',
    },
    arrow: {
      right: 'auto',
      left: '12px',
      bottom: `-${CARET_SIZE}px`,
      borderBottom: 'none',
      borderTop: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderLeft: `solid transparent ${CARET_SIZE}px`,
      borderRight: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      height: '10px',
      bottom: '-10px',
      top: 'auto',
      left: 0,
    },
  },
  bottom: {
    tooltip: {
      bottom: 'auto',
      top: '100%',
      margin: '10px 0 0 0',
    },
    arrow: {
      top: `-${CARET_SIZE}px`,
      borderTop: 'none',
      borderBottom: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderLeft: `solid transparent ${CARET_SIZE}px`,
      borderRight: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      height: '10px',
      top: '-10px',
      bottom: 'auto',
      left: 0,
    },
  },
  bottomRight: {
    tooltip: {
      bottom: 'auto',
      top: '100%',
      left: 'auto',
      right: 0,
      margin: '10px 0 0 0',
      transform: 'none',
    },
    arrow: {
      left: 'auto',
      right: '12px',
      top: `-${CARET_SIZE}px`,
      borderTop: 'none',
      borderBottom: `solid ${hexToRGB(
        theme.colors.blackberry,
        0.9
      )} ${CARET_SIZE}px`,
      borderLeft: `solid transparent ${CARET_SIZE}px`,
      borderRight: `solid transparent ${CARET_SIZE}px`,
    },
    gap: {
      height: '10px',
      top: '-10px',
      bottom: 'auto',
      left: 0,
    },
  },
};
