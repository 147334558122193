/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import GhostButton from '../components/GhostButton';
import PrimaryButton from '../components/PrimaryButton';
import ModalBody from '../components/ModalBody';
import ModalHeading from '../components/ModalHeading';
import ModalFooter from '../components/ModalFooter';
import ModalHeadingText from '../components/ModalHeadingText';

export default function ConfirmDenyModal({
  onDeny,
  onConfirm,
  denyText = 'Cancel',
  confirmText = 'Confirm',
  confirmVariant = 'primary',
  isLoading,
  heading,
  subtext,
}) {
  return (
    <ModalBody canGrow={false}>
      <ModalHeading as="div">
        <ModalHeadingText heading={heading} text={subtext && subtext} />
      </ModalHeading>
      <ModalFooter extraStyles={{ marginTop: ['s', 's', 'm'] }}>
        <GhostButton disabled={isLoading} onClick={onDeny}>
          {denyText}
        </GhostButton>
        <PrimaryButton
          variant={confirmVariant}
          onClick={onConfirm}
          isLoading={isLoading}
        >
          {confirmText}
        </PrimaryButton>
      </ModalFooter>
    </ModalBody>
  );
}
