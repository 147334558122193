/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Image({
  alt,
  blendIn,
  borderRadius,
  boxShadow,
  className,
  display = 'block',
  filter,
  hasTransition = true,
  height = 'auto',
  maxWidth,
  objectFit,
  objectPosition,
  respondToDarkMode = false,
  src,
  src2x,
  visibleByDefault = true,
  width = 'auto',
}) {
  const [colorMode] = useColorMode();
  const shouldInvert = respondToDarkMode && colorMode === 'dark';

  const invertedProps = shouldInvert && {
    filter: 'invert(100%)',
    mixBlendMode: 'screen',
  };

  const blendInProps = blendIn && {
    mixBlendMode: colorMode === 'dark' ? 'lighten' : 'darken',
  };

  const effectProps = hasTransition && { effect: 'opacity' };
  return (
    <span
      className={className}
      sx={{
        display,
        '.lazy-load-image-background': {
          display: `${display} !important`,
        },
        img: {
          color: 'rgba(0, 0, 0, 0)',
          borderRadius,
          boxShadow,
          display,
          filter,
          height,
          maxWidth,
          objectFit,
          objectPosition,
          width,
          ...invertedProps,
          ...blendInProps,
        },
      }}
    >
      <LazyLoadImage
        alt={alt}
        height={height}
        src={src}
        srcSet={`${src2x} 2x`}
        visibleByDefault={visibleByDefault}
        width={width}
        {...effectProps}
      />
    </span>
  );
}
