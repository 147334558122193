/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import GhostButton from '../components/GhostButton';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeadingText from '../components/ModalHeadingText';
import ModalImage from '../components/ModalImage';
import PrimaryButton from '../components/PrimaryButton';

import { getBook, getBookTitle } from '../selectors';
import { abandon, abandon2x } from '../assets';

export default function AbandonBook({
  bookId,
  onClose,
  updateList,
  selectedList,
  listIds,
  context = 'default',
}) {
  const ctx = AbandonBook.contexts[context] || AbandonBook.contexts.default;
  const buttonTargets = ctx.buttonTargets({ selectedList: selectedList?.key });
  const buttons = buttonTargets.map(targetKey => {
    const onClick = () => {
      // fallback to using the key for abandoned instead of id as the list may not exist yet
      const listId = listIds[targetKey] || targetKey;
      updateList(listId);
      onClose();
    };
    return (
      <TargetButton
        onClick={onClick}
        selectedList={selectedList}
        targetKey={targetKey}
      />
    );
  });
  const bookTitle = getBookTitle(useSelector(getBook(bookId)));
  return (
    <ModalBody canGrow={false}>
      <ModalImage
        alt="Man floating with books and dog around him"
        height={120}
        src={abandon}
        src2x={abandon2x}
        width={480}
      />
      <ModalContent extraStyles={sx.body}>
        <ModalHeadingText heading={ctx.header} text={ctx.copy(bookTitle)} />
      </ModalContent>
      <ModalFooter direction="vertical">{buttons}</ModalFooter>
    </ModalBody>
  );
}

function TargetButton({ targetKey, onClick, selectedList }) {
  if (selectedList?.key === targetKey) {
    // we will be removing from library in this case
    return (
      <Button onClick={onClick} type="reset" variant="strawberry.default">
        Remove from my library
      </Button>
    );
  }
  switch (targetKey) {
    case 'to-read':
      return (
        <PrimaryButton onClick={onClick} type="reset">
          Mark as to-read
        </PrimaryButton>
      );
    case 'reading':
      return (
        <GhostButton onClick={onClick} type="reset">
          I&apos;m reading this
        </GhostButton>
      );
    case 'abandoned':
      return (
        <GhostButton onClick={onClick} type="reset">
          Abandon this book
        </GhostButton>
      );
    default:
      break;
  }
}

AbandonBook.contexts = {
  default: {
    // when reading state is being disabled
    header: 'No hard feelings.',
    buttonTargets: ({ selectedList }) => [selectedList, 'abandoned'],
    copy: title => (
      <span>
        Do you want to mark this as abandoned or remove {title} from your
        library entirely?
      </span>
    ),
  },
  toRead: {
    // when book is moving from reading to to-read
    header: 'Are you done with that?',
    buttonTargets: () => ['to-read', 'abandoned'],
    copy: title => (
      <span>Would you like to mark {title} as abandoned or to-read?</span>
    ),
  },
  resurrect: {
    // when book is abandoned
    header: "So you didn't finish.",
    buttonTargets: ({ selectedList }) => [selectedList, 'reading'],
    copy: title => (
      <span>
        It happens. Are you reading again or shall we remove {title} from your
        library entirely?
      </span>
    ),
  },
};

const sx = {
  body: {
    textAlign: 'center',
  },
};
