import React from 'react';

import Svg from '../../components/Svg';

export default function SearchIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m8.5 13.2194c0-2.6065 2.1129-4.7194 4.7194-4.7194s4.7194 2.1129 4.7194 4.7194c0 1.2918-.519 2.4625-1.36 3.3147-.0078.0072-.0155.0145-.023.0221-.0076.0075-.0149.0152-.0221.023-.8522.8407-2.0226 1.3596-3.3143 1.3596-2.6065 0-4.7194-2.1129-4.7194-4.7194zm8.5547 4.8964c-1.0566.8288-2.3882 1.323-3.8353 1.323-3.43488 0-6.2194-2.7845-6.2194-6.2194 0-3.43488 2.78452-6.2194 6.2194-6.2194 3.4349 0 6.2194 2.78452 6.2194 6.2194 0 1.4473-.4943 2.7791-1.3234 3.8358l2.6655 2.6655c.2929.2929.2929.7677 0 1.0606s-.7678.2929-1.0607 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
