/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { jsx } from 'theme-ui';

import ContentLoader from '../components/ContentLoader';
import Highlight from './Highlights/Highlight';
import Page from '../components/Page';
import PageBody from '../components/PageBody';
import PageNavigation from '../components/PageNavigation';
import ProfileBookHighlightsHeader from './Profile/ProfileBookHighlightsHeader';
import Footer from './Home/Footer';

import { getIsAuthenticated, getToken } from '../selectors';
import { fetchSpecificHighlight } from '../ducks/highlights';

export default function HighlightPage() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const highlightId = match.params.id;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = !!useSelector(getIsAuthenticated);

  useEffect(() => {
    fetchSpecificHighlight({ dispatch, token, highlightId }).then(response => {
      setData(response);
      setTimeout(() => setIsLoading(false), 420);
    });
  }, []);

  if (isLoading) {
    return (
      <Page title="Highlight">
        {isAuthenticated && <PageNavigation showBackButton showShareButton />}
        <PageBody>
          <ProfileBookHighlightsHeader isLoading />
          <ContentLoader sx={sx.loader} />
        </PageBody>
      </Page>
    );
  }

  return (
    <Page title={`Highlight / ${data?.book.title}`}>
      {isAuthenticated && <PageNavigation showBackButton showShareButton />}
      <PageBody>
        <ProfileBookHighlightsHeader book={data.book} />
        <Highlight
          canRemove={data.highlight.is_saved}
          canSave={!data.highlight.is_saved}
          containsSpoiler={data.highlight.contains_spoiler}
          countSaves={data.highlight.count_saves}
          date={data.highlight.saved_at}
          id={data.highlight.id}
          position={data.highlight.position}
          standalone
          text={data.highlight.text}
        />
      </PageBody>
      {!isAuthenticated && <Footer />}
    </Page>
  );
}

const sx = {
  loader: {
    width: '100%',
    height: 300,
    borderRadius: 'button',
  },
};
