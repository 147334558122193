/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { alpha } from '@theme-ui/color';
import { ToastContainer, cssTransition } from 'react-toastify';

/* eslint-disable-next-line */
import { Global } from '@emotion/react';

import theme from '../theme';

// These are defined in ./src/assets/css/toast.css
const SlideIn = cssTransition({
  enter: 'slideIn',
  exit: 'slideOut',
});

export default function StyledToastContainer() {
  // Tap bar height + extra space above it + the safe area on iOS
  const bottom = useResponsiveValue([
    'calc(73px + env(safe-area-inset-bottom)) !important',
    'calc(73px + env(safe-area-inset-bottom)) !important',
    'calc(73px + env(safe-area-inset-bottom)) !important',
    '1em !important',
    '1em !important',
  ]);
  const maxWidth = useResponsiveValue([
    '100% !important',
    '100% !important',
    '320px !important',
    '320px !important',
  ]);
  const sx = {
    toast: {
      '.Toastify__toast-container': {
        maxWidth: 320,
        width: '100%',
        padding: '10rem !important',
        bottom: 'calc(env(safe-area-inset-bottom) + 1em) !important',
      },
      '.Toastify__transition': {
        transition: theme.transitions.smooth.fast,
      },
      '.Toastify__toast--default': {
        bg: alpha('blackberry', 0.9),
        borderRadius: 'toast',
        color: 'milk',
        fontSize: 'xs',
        lineHeight: 'body',
        fontFamily: 'body',
      },
      '.Toastify__close-button': {
        marginTop: -8,
        marginRight: -8,
      },
      '.Toastify__close-button--light': {
        color: 'milk',
      },
      '.Toastify__toast-body': {
        padding: 0,
      },
      '.Toastify__toast': {
        minHeight: 0,
        paddingX: 's',
        paddingY: 's',
        marginBottom: '1rem !important',
        cursor: 'auto',
        '&:last-child': {
          marginBottom: '0px !important',
        },
      },
    },
  };
  return (
    <React.Fragment>
      <Global
        styles={() => ({
          '.Toastify__toast-container': {
            width: 'calc(100% - 2em) !important',
            left: '1em !important',
            right: '1em !important',
            maxWidth,
            bottom,
          },
        })}
      />
      <ToastContainer
        hideProgressBar
        pauseOnHover
        position="bottom-left"
        draggable={false}
        sx={sx.toast}
        transition={SlideIn}
      />
    </React.Fragment>
  );
}
