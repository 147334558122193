/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import BackButton from '../../components/BackButton';
import ContentLoader from '../../components/ContentLoader';
import FullHeight from '../../components/FullHeight';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import Text from '../../components/Text';

import { bookcase } from '../../assets';
import { PlusIcon } from '../../icons';
import { ROOT_ROUTE } from '../../constants';
import { isNativeIos } from '../../utils';

export default function AuthPageWrap({
  children,
  heading,
  image,
  invitedBy,
  subHeading,
  title,
}) {
  const location = useLocation();
  const isNative = isNativeIos();
  return (
    <Page title={title} headerless>
      <div sx={sx.logo}>
        {isNative ? (
          <BackButton
            as={RouterLink}
            to={{
              pathname: ROOT_ROUTE,
              search: location.search,
            }}
          />
        ) : (
          <Logo />
        )}
      </div>
      <FullHeight flexDirection="row">
        <div sx={sx.page}>
          <div />
          <div sx={sx.body}>
            <header sx={sx.header}>
              {invitedBy && (
                <div sx={sx.invited}>
                  <div sx={sx.outerCircle}>
                    <div sx={sx.circle}>
                      <PlusIcon />
                    </div>
                  </div>
                  <div sx={sx.avatar}>
                    {invitedBy.isLoading ? (
                      <ContentLoader sx={sx.loader} />
                    ) : (
                      <Avatar
                        src={invitedBy.avatar}
                        src2x={invitedBy.avatar}
                        size={64}
                        name={invitedBy.fullName}
                      />
                    )}
                  </div>
                </div>
              )}
              {heading && (
                <Heading
                  lineHeight="heading"
                  fontFamily="serif"
                  variant="h1"
                  as="h1"
                >
                  {heading}
                </Heading>
              )}
              {subHeading && (
                <Text variant="body" as="p" sx={sx.text}>
                  {subHeading}
                </Text>
              )}
            </header>
            {children}
          </div>
          <div />
        </div>
        <div sx={image.oneX === bookcase ? sx.imageLeft : sx.image}>
          {image && (
            <Image
              alt="Cat and man chilling"
              maxWidth={650}
              respondToDarkMode
              src={image.oneX}
              src2x={image.twoX}
            />
          )}
        </div>
      </FullHeight>
    </Page>
  );
}

const sx = {
  body: {
    width: '100%',
    maxWidth: 'page.mobile',
    paddingTop: 'xl',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 'l',
    textAlign: 'left',
  },
  text: {
    marginTop: 'xs',
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 'm',
  },
  page: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingX: 'l',
    paddingY: 'xl',
  },
  image: {
    overflow: 'hidden',
    width: '50%',
    display: ['none', 'none', 'none', 'flex', 'flex'],
    minHeight: '100%',
    bg: 'blueberry.2',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '> span': {
      marginRight: '-10%',
    },
  },
  imageLeft: {
    overflow: 'hidden',
    width: '50%',
    display: ['none', 'none', 'none', 'flex', 'flex'],
    minHeight: '100%',
    bg: 'blueberry.2',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '> span': {
      marginLeft: '-2%',
    },
  },
  nav: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
  },
  invited: {
    marginBottom: 'm',
    display: 'flex',
    alignItems: 'center',
  },
  outerCircle: {
    width: 76,
    height: 76,
    position: 'relative',
    zIndex: 1,
    borderRadius: 'circle',
    bg: 'milk',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-6px',
  },
  circle: {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'circle',
    color: 'blueberry.100',
    bg: 'milk',
    border: '1px solid',
    borderColor: 'blueberry.10',
    boxShadow:
      '0px 8px 17px rgba(0, 0, 0, 0.07), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',
  },
  avatar: {
    marginLeft: '-16px',
  },
  loader: {
    width: 64,
    height: 64,
    borderRadius: 'circle',
  },
};
