/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteConfirmation from './DeleteConfirmation';

import { getBookId } from '../utils';
import { getToken, getUsername } from '../selectors';
import { deleteBookReview } from '../ducks/reviews';

export default function DeleteReview({ onClose, bookId, reviewId }) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const username = useSelector(getUsername);
  const targetBookId = bookId || getBookId(match.params.slug);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    deleteBookReview({
      bookId: targetBookId,
      username,
      reviewId,
      token,
      dispatch,
    })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
          onClose();
        }, 600);
      })
      .catch(e => {
        console.error(e);
        toast('Something went wrong. Please try again!');
        setTimeout(() => setIsLoading(false), 600);
      });
    return null;
  };

  return (
    <DeleteConfirmation
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
      heading={
        <>
          Do you really want to <br /> delete your review?
        </>
      }
    />
  );
}
