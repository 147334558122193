/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, useColorMode, Link as ThemeLink } from 'theme-ui';

import { hasTouch } from '../utils';

const Link = React.forwardRef((props, ref) => {
  const {
    children,
    fontSize,
    hasTransition = true,
    hasUnderline,
    sx,
    truncated,
    variant,
    ...rest
  } = props;

  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';
  const gradient = getLineGradient({ variant, isDarkMode });

  const truncatedProps = truncated && {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const underlineProps = hasUnderline && {
    cursor: 'pointer',
    backgroundSize: '2px 2px',
    backgroundPosition: '0 100%',
    backgroundRepeat: 'repeat-x',
    backgroundImage: gradient.default,

    '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
      backgroundImage: gradient.retina,
    },
  };

  return (
    <ThemeLink
      {...rest}
      variant={variant}
      sx={{
        fontFamily: 'body',
        textDecoration: 'none',
        display: 'inline-block',
        transition: 'standard.fast',
        willChange: 'transform',
        cursor: 'pointer',

        '&:hover': hasTransition &&
          !hasTouch() && {
            transform: 'translate3d(0, -1px, 0)',
          },
        '&:active': hasTransition &&
          !hasTouch() && {
            transform: 'translate3d(0, 0, 0)',
          },

        ...truncatedProps,
        ...underlineProps,
        ...sx,
      }}
      ref={ref}
    >
      {children}
    </ThemeLink>
  );
});

const getLineGradient = ({ variant, isDarkMode }) => {
  if (
    (variant === 'milk' && !isDarkMode) ||
    (variant !== 'milk' && isDarkMode)
  ) {
    return {
      default:
        'linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.35) 50%)',
      retina:
        'linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0.35) 75%)',
    };
  }
  if (
    (variant === 'milk' && isDarkMode) ||
    (variant !== 'milk' && !isDarkMode)
  ) {
    return {
      default:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.80) 50%)',
      retina:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.80) 75%)',
    };
  }
};

export default Link;
