/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useState, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import Counter from '../components/Counter';
import GhostButton from '../components/GhostButton';
import Label from '../components/Label';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import PrimaryButton from '../components/PrimaryButton';
import SpoilersToggle from '../components/SpoilersToggle';
import TextareaWithLabel from '../components/TextareaWithLabel';

import { createHighlight, extractTextFromImage } from '../ducks/highlights';
import { getBookTitle, getToken, getUsername } from '../selectors';
import { getPersonalHighlightRoute } from '../constants';
import { CameraIcon } from '../icons';

export default function NewHighlight({ onGoBack, book, onClose }) {
  const [page, setPage] = useState(0);
  const [highlight, setHighlight] = useState('');
  const [error, setError] = useState('');
  const [spoiler, setSpoiler] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const username = useSelector(getUsername);

  const increasePage = event => {
    event.preventDefault();
    if (page === 999) return;
    setPage(page + 1);
  };

  const decreasePage = event => {
    event.preventDefault();
    if (page === 0) return;
    setPage(page - 1);
  };

  const onPageChange = event => {
    const { value } = event.target;
    if (value === '') {
      setPage(0);
      return;
    }
    const valueNumber = parseInt(event.target.value, 10);
    if (valueNumber > 9999) {
      setPage(9999);
      return;
    }
    setPage(valueNumber);
  };

  const onHighlightChange = event => {
    setError('');
    setHighlight(event.target.value);
  };

  const toggleSpoilers = () => {
    setSpoiler(!spoiler);
  };

  const onSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    if (highlight === '') {
      setError('Please enter a highlight.');
      setIsLoading(false);
      return null;
    }
    createHighlight({
      bookId: book.id,
      dispatch,
      position: page,
      spoiler,
      text: highlight,
      token,
      username,
    }).then(() => {
      setIsLoading(false);
      dispatch(push(getPersonalHighlightRoute(book.id)));
      onClose();
    });
  };

  const uploadImage = useCallback(
    acceptedFiles => {
      setIsExtracting(true);
      acceptedFiles.forEach(file => {
        extractTextFromImage({
          dispatch,
          token,
          file,
        })
          .then(text => {
            // We probably shouldn't overwrite the text if it's already set
            setHighlight(`${highlight} ${text}`);
            setIsExtracting(false);
          })
          .catch(e => {
            console.error(e);
            setIsExtracting(false);
            toast('We hit an error pulling text from that image :(', {
              autoClose: 6500,
            });
          });
      });
    },
    [highlight]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadImage,
    accept: 'image/jpeg,image/jpg,image/png',
  });

  // Control the height on iPhone 5/SE only
  const extraStyles = {
    '@media screen and (max-height: 568px)': {
      height: 560,
    },
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalBody canGrow={false} extraStyles={extraStyles}>
        <ModalHeading>{getBookTitle(book)}</ModalHeading>
        <ModalContent>
          <div sx={sx.textareaContainer}>
            <TextareaWithLabel
              error={error}
              id="new-highlight-text"
              label="New highlight"
              onChange={onHighlightChange}
              placeholder="Paste your highlight or take a photo"
              sx={sx.textarea}
              value={highlight}
            />
          </div>
          <div {...getRootProps()} sx={sx.buttonContainer}>
            <input {...getInputProps()} />
            <GhostButton
              isLoading={isExtracting}
              as="a"
              size="l"
              sx={sx.button}
            >
              <CameraIcon />
              Use a photo
            </GhostButton>
          </div>
          <Box marginTop={['s', 's', 'm']}>
            <Label label="Page (optional)" id="new-highlight-page">
              <Counter
                id="new-highlight-page"
                onChange={onPageChange}
                onDecrease={decreasePage}
                onIncrease={increasePage}
                value={page}
              />
            </Label>
          </Box>
          <SpoilersToggle onChange={toggleSpoilers} checked={spoiler} />
        </ModalContent>
        <ModalFooter>
          <GhostButton disabled={isLoading} onClick={onGoBack}>
            Back
          </GhostButton>
          <PrimaryButton type="submit" isLoading={isLoading} onClick={onSubmit}>
            Create
          </PrimaryButton>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

const sx = {
  textareaContainer: {
    bg: 'milk',
    position: 'relative',
    zIndex: 1,
  },
  textarea: {
    height: 230,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonContainer: {
    marginTop: '-1px',
  },
  button: {
    width: '100%',

    '>span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        marginRight: 'xxs',
      },
    },

    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    '&:hover': {
      position: 'relative',
      zIndex: 2,
    },
  },
};
