/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import ChartBox from '../../components/ChartBox';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';

import BookLengthGraph from './BookLengthGraph';
import BooksReadGraph from './BooksReadGraph';
import CommonAuthorsTable from './CommonAuthorsTable';
import Legend from './Legend';
import StatsPageHeader from './StatsPageHeader';
import WIPBox from './WIPBox';

import { HOME_ROUTE } from '../../constants';
import { fetchStats } from '../../ducks/stats';

import {
  FEATURES,
  getIsAuthenticated,
  getStats,
  getToken,
  hasFeature,
} from '../../selectors';

export default function Stats() {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const data = useSelector(getStats);
  const isAuthenticated = !!useSelector(getIsAuthenticated);

  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const COLOR_RECOMMENDED = isDark ? 'rgb(181, 201, 172)' : 'rgb(43, 88, 79)';
  const COLOR_READ = isDark ? 'rgb(100, 116, 93)' : 'rgb(154, 181, 142)';

  const bookReadLegendItems = [
    {
      title: 'Read',
      color: COLOR_READ,
    },
    {
      title: 'Recommended',
      color: COLOR_RECOMMENDED,
    },
  ];

  useEffect(() => {
    if (!data?.fetched) {
      fetchStats({ dispatch, token }).catch(e => {
        console.error(e);
      });
    }
  }, []);

  const featureEnabled = useSelector(hasFeature(FEATURES.GOALS, true));
  useEffect(() => {
    if (!featureEnabled) {
      dispatch(push(HOME_ROUTE));
    }
  }, [featureEnabled]);

  return (
    <Page title="Personalised reading stats">
      {isAuthenticated && <PageNavigation showBackButton />}
      <PageBody>
        <StatsPageHeader stats={data} />
        <ChartBox
          heading="Books read by year"
          legend={<Legend items={bookReadLegendItems} />}
        >
          <BooksReadGraph
            colorRange={[COLOR_READ, COLOR_RECOMMENDED]}
            data={data.signal}
          />
        </ChartBox>
        <ChartBox heading="Average book length">
          <BookLengthGraph data={data.length} />
        </ChartBox>
        <ChartBox justifyContent="flex-start" heading="Most read authors">
          <CommonAuthorsTable data={data.commonAuthors} />
        </ChartBox>
        <WIPBox />
      </PageBody>
    </Page>
  );
}
