/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { hasTouch } from '../../utils';

export default function SideBlockLink({
  alignItems,
  children,
  flexDirection,
  href,
  rel,
  target,
  to,
  mixpanelEvent,
}) {
  const sx = {
    item: {
      alignItems,
      borderRadius: 'toast',
      display: 'flex',
      flexDirection,
      padding: 'xs',
      transition: 'smooth.fast',
      '&:hover': !hasTouch() && {
        cursor: 'pointer',
        bg: 'blueberry.5',
        transform: 'translate3d(0, -1px, 0)',
      },
      '&:active': {
        transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1)',
      },
    },
  };
  const onClick = () => {
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent);
    }
  };

  if (href) {
    return (
      <a href={href} target={target} rel={rel} onClick={onClick} sx={sx.item}>
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <RouterLink sx={sx.item} to={to} onClick={onClick}>
        {children}
      </RouterLink>
    );
  }
  return <div sx={sx.item}>{children}</div>;
}
