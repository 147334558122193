/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Grid } from 'theme-ui';

import ListHeading from './ListHeading';
import Text from './Text';

import { pluralise } from '../utils';

export default function ListWrap({
  children,
  columns = [
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
    'repeat(4, minmax(0, 1fr))',
    'repeat(5, minmax(0, 1fr))',
  ],
  heading,
  length,
  route,
}) {
  const bookCount = `${pluralise({
    count: length,
    word: 'book',
  })}`;
  return (
    <React.Fragment>
      {heading && (
        <ListHeading to={route}>
          {heading} &middot;{' '}
          <Text as="span" color="blueberry.80">
            {bookCount}
          </Text>
        </ListHeading>
      )}
      <Grid gap="s" columns={columns}>
        {children}
      </Grid>
    </React.Fragment>
  );
}
