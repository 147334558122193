/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, useColorMode } from 'theme-ui';

import theme from '../theme';

const ContentLoader = React.forwardRef((props, ref) => {
  const { children, sx, kind = 'light', ...rest } = props;
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const kinds = getKinds(isDark);
  return (
    <div
      sx={{
        display: 'block',
        animationDuration: '2000ms',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'placeHolderShimmer',
        animationTimingFunction: 'linear',

        ...kinds[kind],
        ...sx,

        backgroundSize: '800px 104px',
      }}
      ref={ref}
      {...rest}
    />
  );
});

const getKinds = isDarkMode => {
  if (isDarkMode) {
    return {
      light: {
        background: `linear-gradient(
          to right,
          ${theme.colors.modes.dark.blueberry[5]} 8%,
          ${theme.colors.modes.dark.blueberry[10]} 18%,
          ${theme.colors.modes.dark.blueberry[5]} 33%
        )`,
      },
      dark: {
        background: `linear-gradient(
          to right,
          ${theme.colors.modes.dark.blueberry[10]} 8%,
          ${theme.colors.modes.dark.blueberry[20]} 18%,
          ${theme.colors.modes.dark.blueberry[10]} 33%
        )`,
      },
    };
  }
  return {
    light: {
      background: `linear-gradient(
        to right,
        ${theme.colors.blueberry[5]} 8%,
        ${theme.colors.blueberry[10]} 18%,
        ${theme.colors.blueberry[5]} 33%
      )`,
    },
    dark: {
      background: `linear-gradient(
        to right,
        ${theme.colors.blueberry[10]} 8%,
        ${theme.colors.blueberry[20]} 18%,
        ${theme.colors.blueberry[10]} 33%
      )`,
    },
  };
};
export default ContentLoader;
