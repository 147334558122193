/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import { hasTouch } from '../../utils';
import BookSideBlock from './BookSideBlock';
import Heading from '../../components/Heading';
import Link from '../../components/Link';
import Section from './Section';
import Text from '../../components/Text';

export default function BuyModule({ purchaseLinks }) {
  if (purchaseLinks.length === 0) return null;
  return (
    <Section title="Buy">
      <BookSideBlock padding={0}>
        <div sx={sx.header}>
          <Heading marginBottom="xs" variant="h2">
            Get this book
          </Heading>
          <Text variant="subtitle" lineHeight="body">
            We partner with some online stores that will give us a small
            affiliate fee on purchase.
            {/* <Link hasUnderline variant="primary" sx={{ lineHeight: 'heading' }}>
              Learn more ↗
            </Link> */}
          </Text>
        </div>
        <ul sx={sx.list}>
          {purchaseLinks.map(({ store, url }) => {
            const meta = getStoreMeta(store);
            const linkProps = {
              href: url,
              rel: 'noreferrer noopener',
              target: '_blank',
            };
            if (meta) {
              return (
                <a sx={sx.link} key={store} {...linkProps}>
                  <div sx={sx.icon}>{meta.icon}</div>
                  <div sx={sx.meta}>
                    <Text as="h3" variant="subtitle">
                      <Link
                        hasUnderline
                        sx={{ lineHeight: 'heading' }}
                        variant="primary"
                        {...linkProps}
                      >
                        {meta.title} ↗
                      </Link>
                    </Text>
                  </div>
                </a>
              );
            }
            return null;
          })}
        </ul>
      </BookSideBlock>
    </Section>
  );
}

const getStoreMeta = store => {
  switch (store) {
    case 'bookshop':
      return {
        title: 'bookshop.org',
        icon: '🇺🇸',
      };
    case 'bkshp-uk':
      return {
        title: 'uk.bookshop.org',
        icon: '🇬🇧',
      };
    case 'hive':
      return {
        title: 'hive.co.uk',
        icon: '🇬🇧',
      };
    case 'amz-uk':
      return {
        title: 'Amazon.co.uk',
        icon: '🇬🇧',
      };
    case 'amz-us':
      return {
        title: 'Amazon.com',
        subtitle: '(US / North America)',
        icon: '🇺🇸',
      };
    default:
      return null;
  }
};

const sx = {
  header: {
    padding: 'm',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
  },
  meta: {
    marginLeft: 's',
    h3: {
      lineHeight: 'sub',
    },
    p: {
      lineHeight: 'sub',
      marginTop: 'xxs',
      marginBottom: 2,
    },
  },
  list: {
    padding: 'm',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 's',
    '&:hover > div:first-child': {
      bg: 'blueberry.10',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 32 / 2,

    bg: 'blueberry.5',
    color: 'blueberry.50',
    transition: 'smooth.fast',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': !hasTouch() && {
      color: 'blueberry.80',
    },
  },
};
