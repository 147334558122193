import moment from 'moment';
import { API_LIST_URL } from '../constants';
import { callApi, fetchApi } from '../utils';
import { setProfileList, removeProfileList } from './profile';

// Actions
export const LISTS_LOADED = 'LISTS_LOADED';
export const LISTS_LOADING = 'LISTS_LOADING';
export const REMOVE_BOOK_FROM_LIST = 'REMOVE_BOOK_FROM_LIST';
export const UPDATE_BOOK_ADDED_AT = 'list.book.addedAt';
export const SET_LIST = 'SET_LIST';
export const SET_LISTS = 'SET_LISTS';

// Reducer
const listsReducer = (
  state = {
    areLoading: false,
    items: {},
  },
  action
) => {
  switch (action.type) {
    case LISTS_LOADING:
      return {
        ...state,
        areLoading: true,
      };
    case LISTS_LOADED:
      return {
        ...state,
        areLoading: false,
      };
    case SET_LISTS:
      return {
        ...state,
        items: action.lists,
      };
    case SET_LIST:
      const out = {
        ...state,
        items: {
          ...state.items,
          [action.list.id]: action.list,
        },
      };
      if (action.isAuthedUser && action.list.key) {
        out.items[action.list.key] = action.list;
      }
      return out;
    case REMOVE_BOOK_FROM_LIST:
      const bookId = action.bookId;
      const workId = action.workId;
      const newList = state.items[action.list.key];
      const newBooks = newList.books.filter(item => {
        let cmp = book => book.id === bookId;
        if (workId) {
          // if a workId is given we want to remove
          // all books with a matching workId
          cmp = book => book.workId === workId;
        }
        return item !== newList.books.find(cmp);
      });

      return {
        ...state,
        items: {
          ...state.items,
          [action.list.key]: {
            ...newList,
            books: newBooks,
          },
        },
      };
    case UPDATE_BOOK_ADDED_AT:
      const l = state.items[action.list.key];
      // todo: may have to sort by date too
      const books = l.books.map(b => {
        if (
          b.id === action.bookId ||
          (b.workId && b.workId === action.workId)
        ) {
          return {
            ...b,
            addedAt: moment(action.addedAt).format('Y-M-D'),
          };
        }
        return b;
      });
      return {
        ...state,
        items: {
          ...state.items,
          [action.list.key]: {
            ...l,
            books,
          },
        },
      };
    default:
      return state;
  }
};

// Action creators
export const listsLoading = () => ({
  type: LISTS_LOADING,
});

export const listsLoaded = () => ({
  type: LISTS_LOADED,
});

export const setLists = lists => ({
  // Beware: will wipe out any pre-existing lists.
  // currently unused - but might be needed?
  type: SET_LISTS,
  lists,
});

export const setList = ({ list, isAuthedUser = false }) => ({
  type: SET_LIST,
  list,
  isAuthedUser,
});

export const removeBookFromList = ({ list, bookId, workId }) => ({
  type: REMOVE_BOOK_FROM_LIST,
  bookId,
  list,
  workId,
});

export const updateListBookAddedAt = ({ list, bookId, workId, addedAt }) => ({
  type: UPDATE_BOOK_ADDED_AT,
  bookId,
  workId,
  list,
  addedAt,
});

export function shouldFetchList({
  token,
  dispatch,
  username,
  authedUsername,
  listId,
  key,
}) {
  let url;
  if (key) {
    url = `${API_LIST_URL}/user/${username}/${key}`;
  } else {
    url = `${API_LIST_URL}/${listId}/`;
  }
  return callApi(url, { token }).then(list => {
    dispatch(setList({ list, isAuthedUser: username === authedUsername }));
    dispatch(setProfileList(username, list));
    return list;
  });
}

// Create a collection
export function shouldCreateList({
  blurb,
  bookId,
  dispatch,
  name,
  token,
  username,
  visibility,
}) {
  dispatch(listsLoading());
  return fetchApi(`${API_LIST_URL}/`, {
    method: 'POST',
    body: { name, blurb, visibility },
    token,
  })
    .then(response => response.json())
    .then(list => {
      setTimeout(() => {
        dispatch(setList({ list, isAuthedUser: true }));
        dispatch(setProfileList(username, list));
        if (bookId) {
          shouldUpdateListItem({
            action: 'ADD',
            bookId,
            dispatch,
            listId: list.id,
            token,
            username,
          });
        }
        dispatch(listsLoaded());
      }, 600);
      return list;
    })
    .catch(() => {
      dispatch(listsLoaded());
    });
}

// Delete collection
export function shouldDeleteList({ dispatch, username, key, id, token }) {
  dispatch(listsLoading());
  return fetchApi(`${API_LIST_URL}/${id}/`, {
    method: 'DELETE',
    token,
  })
    .then(() => {
      dispatch(removeProfileList(username, { key, id }));
      dispatch(listsLoaded());
    })
    .catch(() => {
      dispatch(listsLoaded());
    });
}

// Update collection details
export function shouldUpdateList({
  blurb,
  dispatch,
  id,
  name,
  token,
  username,
  visibility,
}) {
  dispatch(listsLoading());
  return fetchApi(`${API_LIST_URL}/${id}/`, {
    method: 'PUT',
    body: { name, blurb, visibility },
    token,
  })
    .then(response => response.json())
    .then(list => {
      setTimeout(() => {
        dispatch(setList({ list, isAuthedUser: true }));
        dispatch(setProfileList(username, list));
        dispatch(listsLoaded());
      }, 600);
      return list;
    })
    .catch(() => {
      dispatch(listsLoaded());
    });
}

// Update add or remove books from a collection
export function shouldUpdateListItem({
  dispatch,
  action,
  bookId,
  listId,
  token,
  username,
}) {
  dispatch(listsLoading());
  return fetchApi(`${API_LIST_URL}/${listId}/items`, {
    method: action === 'ADD' ? 'POST' : 'DELETE',
    body: { bookId },
    token,
  })
    .then(response => response.json())
    .then(list => {
      setTimeout(() => {
        dispatch(setList({ list, isAuthedUser: true }));
        dispatch(setProfileList(username, list));
        dispatch(listsLoaded());
      }, 600);
    })
    .catch(() => {
      dispatch(listsLoaded());
    });
}

// Update selected collections
export function shouldUpdateSelectedList({
  bookId,
  workId,
  dispatch,
  listId,
  selectedList,
  token,
}) {
  dispatch(listsLoading());
  if (selectedList) {
    dispatch(removeBookFromList({ list: selectedList, bookId, workId }));
  }
  const isCurrentList = selectedList?.id === listId;
  return fetchApi(`${API_LIST_URL}/${listId}/items`, {
    method: isCurrentList ? 'DELETE' : 'POST',
    body: { bookId },
    token,
  })
    .then(response => response.json())
    .then(list => {
      setTimeout(() => {
        dispatch(setList({ list, isAuthedUser: true }));
        dispatch(listsLoaded());
      }, 600);
    })
    .catch(() => {
      dispatch(listsLoaded());
    });
}

export default listsReducer;
