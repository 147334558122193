/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import {
  getAbandonedListId,
  getBook,
  getBookAuthors,
  getBooksLoading,
  getBookSubTitle,
  getBookTitle,
  getCoverUrl,
  getIsAuthenticated,
  getListsLoading,
  getReadingListId,
  getReadListId,
  getSelectedBookOtherCollections,
  getSelectedList,
  getToken,
  getToReadListId,
  getUserStale,
} from '../../selectors';

import {
  getBookHighlightsRoute,
  getBookReviewsRoute,
  getBookRoute,
  APP_URL,
} from '../../constants';

import { shouldUpdateSelectedList } from '../../ducks/lists';
import { getBookDetails } from '../../ducks/books';
import { listsWithoutKey, getBookId } from '../../utils';
import { ShareIcon } from '../../icons';

import CircleButton from '../../components/CircleButton';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';
import TabInner from '../../components/TabInner';
import TabLink from '../../components/TabLink';
import Tabs from '../../components/Tabs';

import AddToCollection from './AddToCollection';
import BookCollections from './BookCollections';
import BookHeader from './BookHeader';
import BookRating from './BookRating';
import BuyModule from './Buy';
import LoadingPage from '../Loading';
import ModalDialog from '../../components/ModalDialog';
import ShareBookModal from '../../modals/ShareBook';
import SignUpModal from '../../modals/SignUp';
import SuggestEditModal from '../../modals/SuggestEdit';

import Footer from '../Home/Footer';

export default function BookPageWrap({ children, title }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const isLoading = useSelector(getBooksLoading);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const token = useSelector(getToken);
  const location = useLocation();

  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isSuggestOpen, setIsSuggestOpen] = useState(false);

  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  const openSignUp = () => {
    setIsSignUpOpen(true);
  };

  const closeShare = () => {
    setIsShareOpen(false);
  };

  const openShare = () => {
    setIsShareOpen(true);
  };

  const closeSuggest = () => {
    setIsSuggestOpen(false);
  };

  const openSuggest = () => {
    setIsSuggestOpen(true);
  };

  const { url } = match;
  const { slug } = match.params;
  const bookRoute = getBookRoute(slug);
  const reviewsRoute = getBookReviewsRoute(slug);
  const highlightsRoute = getBookHighlightsRoute(slug);
  const bookId = getBookId(slug);
  const book = useSelector(getBook(bookId));
  const workId = book ? book.workId : '';

  useEffect(() => {
    if (!book || !book.isComplete) {
      getBookDetails({ bookId, token, dispatch });
    }
  }, [bookId, token]);

  const userIsStale = useSelector(getUserStale);
  const areListsLoadng = useSelector(getListsLoading);
  const selectedList = useSelector(getSelectedList(bookId, workId));
  const otherCollections = useSelector(getSelectedBookOtherCollections(bookId));
  const listIds = {
    reading: useSelector(getReadingListId),
    'to-read': useSelector(getToReadListId),
    read: useSelector(getReadListId),
    abandoned: useSelector(getAbandonedListId),
  };

  if (isLoading || !book?.isComplete) {
    return <LoadingPage />;
  }

  const {
    authors,
    collections,
    highlightCount,
    imageLinks,
    purchaseLinks,
    ratings,
    recommended,
    recommendedBy,
    reviewCount,
    subtitle,
    tags,
    yourReview,
  } = book;

  const collectionsWithoutKey = listsWithoutKey(collections);
  const collectionsLength = collectionsWithoutKey.length;
  const thumbnail = getCoverUrl(imageLinks);
  const bookTitle = getBookTitle({ title: book.title, subtitle });
  const bookSubtitle = getBookSubTitle({ title: book.title, subtitle });
  const bookAuthors = getBookAuthors(authors);
  const popularTags = tags?.popular;

  const updateList = listId => {
    if (!isAuthenticated) {
      openSignUp();
      return;
    }
    shouldUpdateSelectedList({
      bookId,
      workId,
      dispatch,
      listId,
      selectedList,
      token,
    }).then(() => {
      const added = selectedList?.listId !== listId;
      if (!added) {
        // book has been removed from list. no tracking for now.
        return;
      }
      switch (listId) {
        case listIds.reading:
          mixpanel.track('Track Book: Reading');
          break;
        case listIds.read:
          mixpanel.track('Track Book: Finished');
          break;
        case listIds['to-read']:
          mixpanel.track('Track Book: Want To Read');
          break;
        case listIds.abandoned:
          mixpanel.track('Track Book: DNF');
          break;
        default:
          break;
      }
    });
  };

  const lists = ['to-read', 'reading', 'read'];
  const isReadList = selectedList?.key === 'read';
  const readngRating = ratings.find(rating => rating.source === 'recs');

  const percentage = readngRating
    ? (readngRating.score / readngRating.count) * 100
    : undefined;

  const thirdPartyRatings = ratings.filter(
    rating => ['gdreads', 'gbooks'].indexOf(rating.source) !== -1
  );

  // Share modal props
  const shareUrl = `${APP_URL}${location.pathname}`;
  const quote = `Check out ${bookTitle} on Oku`;

  return (
    <Page title={`${bookTitle} ${title}`}>
      {isAuthenticated && (
        <PageNavigation showBackButton showShareButton={false}>
          <AddToCollection
            bookId={bookId}
            collectionsLength={collectionsLength}
            isAuthenticated={isAuthenticated}
            openSignUp={openSignUp}
            userIsStale={userIsStale}
          />
          <CircleButton onClick={openShare}>
            <ShareIcon />
          </CircleButton>
          <ModalDialog isVisible={isShareOpen} onClose={closeShare}>
            <ShareBookModal
              showSkipButton={false}
              heading="Spread the word"
              onClose={closeShare}
              quote={quote}
              shareUrl={shareUrl}
              subheading={
                <React.Fragment>
                  Think somebody might enjoy this? <br /> Share it with them!
                </React.Fragment>
              }
              thumbnail={thumbnail}
              title={bookTitle}
            />
          </ModalDialog>
        </PageNavigation>
      )}
      <PageBody extraStyles={sx.pageBody}>
        <BookHeader
          areListsLoadng={areListsLoadng}
          authors={bookAuthors}
          bookId={bookId}
          isAuthenticated={isAuthenticated}
          isReadList={isReadList}
          listIds={listIds}
          lists={lists}
          openSignUp={openSignUp}
          recommended={recommended}
          hasReviewed={yourReview !== null}
          selectedList={selectedList}
          subtitle={bookSubtitle}
          thumbnail={thumbnail}
          title={bookTitle}
          updateList={updateList}
          userIsStale={userIsStale}
          openSuggest={openSuggest}
        />
        <div sx={sx.tabs}>
          <Tabs>
            <Tab isActive={bookRoute === url} to={bookRoute}>
              <TabInner title="Book Info" isActive={bookRoute === url} />
            </Tab>
            <Tab isActive={reviewsRoute === url} to={reviewsRoute}>
              <TabInner
                title="Reviews"
                count={reviewCount}
                isActive={reviewsRoute === url}
              />
            </Tab>
            <Tab isActive={highlightsRoute === url} to={highlightsRoute}>
              <TabInner
                title="Highlights"
                count={highlightCount}
                isActive={highlightsRoute === url}
              />
            </Tab>
          </Tabs>
        </div>
        <div sx={sx.grid}>
          <section sx={sx.leftCol}>{children}</section>
          <section sx={sx.rightCol}>
            <BookRating
              percentage={percentage}
              readngRating={readngRating}
              recommendedBy={recommendedBy}
              thirdPartyRatings={thirdPartyRatings}
              tags={popularTags}
            />
            <BookCollections otherCollections={otherCollections} />
            <BuyModule purchaseLinks={purchaseLinks} />
          </section>
        </div>
      </PageBody>
      {!isAuthenticated && <Footer />}
      <ModalDialog isVisible={isSignUpOpen} onClose={closeSignUp}>
        <SignUpModal />
      </ModalDialog>
      <ModalDialog isVisible={isSuggestOpen} onClose={closeSuggest}>
        <SuggestEditModal bookId={bookId} onClose={closeSuggest} />
      </ModalDialog>
    </Page>
  );
}

const Tab = props => <TabLink as={Link} {...props} />;

const sx = {
  grid: {
    position: 'relative',
    marginTop: [0, 'xl', 'xl', 'xl', 'xl'],
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column', 'row'],
  },
  leftCol: {
    flex: 1,
    width: ['100%', '100%', '100%', '100%', '60%'],
    marginBottom: ['l', 'l', 'l', 'l', 0],
  },
  rightCol: {
    maxWidth: ['100%', '100%', '100%', '100%', 420],
    width: ['100%', '100%', '100%', '100%', '40%'],
    paddingLeft: [0, 0, 0, 0, 'xxl'],
  },
  tabs: {
    marginBottom: 'xl',
    marginTop: ['l', 'l', 'l', 0],
  },
  pageBody: {
    marginBottom: ['s', 's', 'xl'],
  },
};
