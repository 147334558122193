/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Link from './Link';
import Avatar from './Avatar';

import { StarIcon } from '../icons';
import { getReviewRoute, getProfileRoute } from '../constants';

export default function BookReviewHeaderMobile({
  author,
  authorUsername,
  avatar,
  bookSlug,
  date,
  id,
  recommended,
}) {
  const profileRoute = getProfileRoute(authorUsername);
  const reviewRoute = getReviewRoute(bookSlug, id);
  return (
    <header sx={sx.header}>
      <Link as={RouterLink} to={profileRoute} sx={sx.avatar}>
        <Avatar size={48} src={avatar} src2x={avatar} />
      </Link>
      <div>
        <div sx={sx.author}>
          Review by{' '}
          <Link as={RouterLink} to={profileRoute} variant="primary">
            {author}
          </Link>
        </div>
        <div sx={sx.headerLeft}>
          {recommended && (
            <div sx={sx.recommended}>
              <StarIcon />
              Recommended
              <div sx={sx.headerDot}>·</div>
            </div>
          )}
          <Link as={RouterLink} to={reviewRoute} sx={sx.date}>
            {date}
          </Link>
        </div>
      </div>
    </header>
  );
}

const sx = {
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  header: {
    position: 'relative',
    minHeight: 48,
    paddingLeft: 48 + 12,
    marginBottom: 's',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'xs',
  },
  headerLeft: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '16px',
    minWidth: 0,
    paddingRight: 'xs',
  },
  author: {
    color: 'blueberry.100',
    marginBottom: 'xxs',
  },
  recommended: {
    color: 'kiwi.100',
    fontWeight: 'heading',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerDot: {
    paddingX: 'xxs',
    color: 'blueberry.100',
    display: 'inline-block',
  },
  date: {
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    fontWeight: 'heading',
    color: 'blueberry.80',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
