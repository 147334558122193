/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

import Text from '../../components/Text';
import ContainsSpoilers, {
  ContainsSpoilersWrapper,
} from '../../components/ContainsSpoilers';

import { truncateText } from '../../utils';

export default function FeedItem({
  action,
  containsSpoiler,
  date,
  firstName,
  link,
  review,
  title,
}) {
  return (
    <footer sx={sx.footer}>
      <Link to={link} sx={sx.meta}>
        <div sx={sx.metaHeader}>
          <Text as="span" variant="caption" sx={sx.metaName}>
            {firstName}
          </Text>
          <Text as="span" variant="caption" sx={sx.metaDot}>
            &middot;
          </Text>
          <Text as="span" variant="caption" sx={sx.metaDate}>
            {date}
          </Text>
        </div>
        <div>
          <Text as="span" variant="subtitle2" sx={sx.metaAction}>
            {action}
          </Text>{' '}
          <Text as="span" variant="subtitle2" sx={sx.metaTitle}>
            {title}
          </Text>
        </div>
        {review && (
          <Text as="span" variant="subtitle2" sx={sx.metaReview}>
            {containsSpoiler && (
              <ContainsSpoilersWrapper left="s">
                <ContainsSpoilers hasIcon={false} />
              </ContainsSpoilersWrapper>
            )}
            {truncateText(review)}
          </Text>
        )}
      </Link>
    </footer>
  );
}

const sx = {
  footer: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 18,
    paddingX: 20,
  },
  meta: {
    width: '100%',
  },
  metaHeader: {
    marginBottom: 'xs',
    lineHeight: 'base',
  },
  metaName: {
    color: 'blueberry.100',
    lineHeight: 'base',
  },
  metaDot: {
    marginX: 'xxs',
    lineHeight: 'base',
  },
  metaDate: {
    color: 'blueberry.80',
    lineHeight: 'base',
  },
  metaAction: {
    color: 'blueberry.80',
  },
  metaTitle: {
    color: 'blueberry.100',
    fontWeight: 'button',
  },
  metaReview: {
    paddingLeft: 's',
    borderLeft: '2px solid',
    borderColor: 'blueberry.10',
    color: 'blueberry.80',
    display: 'block',
    marginTop: 's',
    paddingY: 2,
    lineHeight: 1.7,
    position: 'relative',
  },
};
