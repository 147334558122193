/** @jsxRuntime classic */
/** @jsx jsx */
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { jsx, useColorMode } from 'theme-ui';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Footer from '../Home/Footer';

export default function BlogPageWrap({ children, title, isIndex }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const sx = getStyles({ isDark });

  useEffect(() => {
    if (isIndex) {
      mixpanel.track('View Blog Index');
    } else {
      mixpanel.track('View Blog Post');
    }
  }, []);
  return (
    <Page headerless hasSidebar={false} title={title} showSidebar={false}>
      <PageHeader showHeader />
      {isIndex ? children : <div sx={sx.body}>{children}</div>}
      <Footer />
    </Page>
  );
}

export const BLOG_SPACE = ['xl', 'xl', 'xl', 'xxl', 'xxl', 100, 100];

const getStyles = ({ isDark }) => ({
  body: {
    maxWidth: 'page.tablet',
    marginX: 'auto',
    paddingBottom: BLOG_SPACE,
    paddingX: 'l',

    h1: {
      textAlign: 'center',
      color: 'blueberry.100',
      fontSize: ['l', 'l', 'xl', 'xl', 'xxl', 'xxl', 'xxl'],
      fontFamily: 'serif',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: '-0.7px',
      marginBottom: BLOG_SPACE,
    },
    time: {
      display: 'block',
      marginTop: BLOG_SPACE,
      marginBottom: 'm',
      fontSize: 'm',
      textAlign: 'center',
      color: 'blueberry.80',
    },
    h2: {
      fontWeight: 'button',
      color: 'blueberry.100',
      marginTop: ['l', 'l', 'xl', 'xl', 'xxl', 'xxl', 'xxl'],
      marginBottom: 'xxs',
      fontSize: 'm',
    },
    h3: {
      fontWeight: 'button',
      color: 'blueberry.100',
      marginBottom: 'xxs',
      fontSize: 'm',
    },
    p: {
      fontSize: 'm',
      lineHeight: 'body',
      color: 'blueberry.80',
      marginBottom: 'm',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    ul: {
      fontSize: 'm',
      lineHeight: 'body',
      color: 'blueberry.80',
      marginBottom: 'm',
      listStyleType: 'disc',
      paddingLeft: 'm',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    li: {
      marginBottom: 's',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    strong: {
      fontWeight: 'button',
      color: 'blueberry.100',
    },
    a: {
      color: 'blueberry.80',
      '&:hover': {
        color: 'blueberry.100',
      },
    },
    img: {
      display: 'block',
      marginY: 'l',
    },
    section: {
      paddingTop: 38,
      paddingBottom: 'm',
      paddingX: 'm',
      marginY: ['l', 'l', 'xxl'],
      boxShadow: isDark ? 'blog.sectionDark' : 'blog.section',
      borderRadius: 'button',
      textAlign: 'center',
      position: 'relative',

      p: {
        fontSize: 'xs',
      },
      h3: {
        fontSize: 'm',
        paddingBottom: 's',
      },
    },
    code: {
      backgroundColor: 'olive',
      color: 'blueberry',
      paddingX: 'm',
      paddingY: 's',
    },
  },
});
