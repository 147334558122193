/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';

export default function BookSection({ title, children }) {
  const sx = getDetailsStyles();
  return (
    <div sx={sx.container}>
      {title && (
        <Heading sx={{ ...sx.heading }} variant="h2">
          {title}
        </Heading>
      )}
      {children}
    </div>
  );
}

const getDetailsStyles = () => ({
  container: {
    marginBottom: ['l', 'l', 'xl'],
    '&:last-child': {
      marginBottom: 0,
    },
  },
  heading: {
    marginBottom: 's',
  },
});
