import objectReducer, { setKey, SET_KEY } from './object';
import { callApi } from '../utils/api';

const SET_STATISTIC = 'statistic.set';
const statsReducer = (
  state = {
    lastFetched: null,
  },
  action
) => {
  switch (action.type) {
    case SET_STATISTIC:
      return objectReducer(state, {
        ...action,
        type: SET_KEY,
      });
    default:
      return state;
  }
};

export default statsReducer;

export function fetchStats({ dispatch, token }) {
  return callApi('/api/stats/mine', {
    token,
  }).then(d => {
    dispatch(setKey({ type: SET_STATISTIC, key: 'fetched', value: true }));
    dispatch(setKey({ type: SET_STATISTIC, key: 'signal', value: d.signal }));
    dispatch(setKey({ type: SET_STATISTIC, key: 'length', value: d.length }));
    dispatch(setKey({ type: SET_STATISTIC, key: 'totals', value: d.totals }));
    dispatch(
      setKey({
        type: SET_STATISTIC,
        key: 'commonAuthors',
        value: d.commonAuthors,
      })
    );
  });
}
