/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';

import Link from './Link';
import ModalDialog from './ModalDialog';
import ProgressBar from './ProgressBar';
import ReadingGoalLogo from './ReadingGoalLogo';
import ReadingGoalModal from '../modals/ReadingGoal';
import Text from './Text';

export default function ReadingProgress({ booksRead, booksToRead, hasLogo }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const percentage = booksToRead
    ? Math.round((booksRead / booksToRead) * 100)
    : 0;
  const percentageString = `${percentage}%`;

  return (
    <div sx={sx.progress}>
      {hasLogo && <ReadingGoalLogo width={48} height={48} />}
      <div sx={hasLogo ? sx.main : sx.mainWithoutLogo}>
        <ProgressBar progress={percentageString} />
        <footer sx={sx.footer}>
          {booksToRead ? (
            <Text variant="subtitle2" sx={sx.text}>
              You’ve read <strong>{booksRead}</strong> out of{' '}
              <strong>{booksToRead}</strong> books{' '}
              <strong>({percentageString})</strong>{' '}
            </Text>
          ) : (
            <Text variant="subtitle2" sx={sx.text}>
              You haven’t set your goal yet!{' '}
            </Text>
          )}
          <Link hasUnderline onClick={openModal} sx={sx.link} variant="body">
            {booksToRead ? 'Edit goal' : 'Set goal'}
          </Link>
          <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
            <ReadingGoalModal onClose={closeModal} />
          </ModalDialog>
        </footer>
      </div>
    </div>
  );
}

const sx = {
  progress: {
    bg: 'blueberry.5',
    padding: 'm',
    borderRadius: 'button',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    paddingTop: 'xxs',
    marginLeft: 's',
    flex: 1,
  },
  mainWithoutLogo: {
    width: ['100%', '100%', '100%', '100%', 'auto'],
  },
  footer: {
    marginTop: 's',
    display: ['block', 'block', 'block', 'flex'],
    alignItems: 'center',
    justifyContent: ['center', 'center', 'center', 'space-between'],
    textAlign: ['left', 'left', 'center', 'left'],
    flexDirection: ['column', 'column', 'column', 'row'],
    width: '100%',
  },
  link: {
    fontSize: 'xs',
    lineHeight: 'sub',
  },
  text: {
    display: ['inline', 'inline', 'inline', 'inline-block'],
    marginBottom: ['xs', 'xs', 'xs', 0],
    marginRight: [0, 0, 0, 'l'],
    strong: {
      fontWeight: 'button',
    },
  },
};
