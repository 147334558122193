/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { useSelector } from 'react-redux';

import UserBlock from './UserBlock';
import { getUsername } from '../selectors';

export default function ListUsers({
  gap = ['s', 's', 'm'],
  marginTop = ['s', 's', 'm'],
  users,
}) {
  const username = useSelector(getUsername);
  return (
    <Grid marginTop={marginTop} gap={gap} columns={[1, 1, 1, 1, 2, 2, 3]}>
      {users?.map(person => (
        <UserBlock
          firstname={person.firstname}
          following={person.following}
          image={person.image}
          key={person.username}
          lastname={person.lastname}
          selfUsername={username}
          username={person.username}
        />
      ))}
    </Grid>
  );
}
