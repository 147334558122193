/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import Button from './Button';
import PrimaryButton from './PrimaryButton';
import CopyButton from './CopyButton';

import { ShareIcon, FacebookIcon, TwitterIcon } from '../icons';

export default function ModalShareButtons({
  onClose,
  quote,
  shareUrl,
  showSkipButton = true,
}) {
  const webShareUtm = '?ref=web-share';
  const nativeShareUtm = '?ref=native-share';
  const nativeShareUrl = encodeURI(shareUrl + nativeShareUtm);
  const webShareUrl = encodeURI(shareUrl + webShareUtm);
  const showShareButton = !!window.navigator.share;

  const onNativeShare = () => {
    window.plausible('share-book-click');
    navigator
      .share({
        text: quote,
        url: nativeShareUrl,
      })
      .catch(() => {
        window.plausible('share-book-cancel');
      });
  };

  if (showShareButton) {
    return (
      <React.Fragment>
        <PrimaryButton onClick={onNativeShare} sx={sx.nativeShareButton}>
          <ShareIcon />
          Share
        </PrimaryButton>
        <Button variant="light" onClick={onClose}>
          Skip
        </Button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TwitterShareButton url={webShareUrl} title={quote} via="okuclub">
        <Button as="div" variant="twitter.default" sx={sx.shareButton}>
          <TwitterIcon />
          Share on Twitter
        </Button>
      </TwitterShareButton>
      <FacebookShareButton url={webShareUrl} quote={quote}>
        <Button as="div" variant="facebook.default" sx={sx.shareButton}>
          <FacebookIcon />
          Share on Facebook
        </Button>
      </FacebookShareButton>
      <CopyButton text={webShareUrl} variant="light">
        Copy link
      </CopyButton>
      {showSkipButton && (
        <Button variant="light" onClick={onClose}>
          Skip
        </Button>
      )}
    </React.Fragment>
  );
}

const sx = {
  nativeShareButton: {
    width: '100%',
    position: 'relative',
    svg: {
      position: 'absolute',
      top: '50%',
      left: 14,
      marginTop: '-14px',
    },
  },
  shareButton: {
    width: '100%',
    position: 'relative',
    svg: {
      position: 'absolute',
      top: '50%',
      left: 20,
      marginTop: '-8px',
    },
  },
  skipLink: {
    fontSize: 'xs',
    marginTop: 's',
    color: 'blueberry.100',
    cursor: 'pointer',
  },
};
