/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from './Heading';

export default function ModalHeading({ as = 'h1', children, extraStyles }) {
  return (
    <Heading as={as} variant="h1" sx={{ ...sx.heading, ...extraStyles }}>
      {children}
    </Heading>
  );
}

const sx = {
  heading: {
    paddingX: 'm',
    paddingBottom: 'm',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    paddingTop: 'xl',
    fontFamily: 'serif',
    textAlign: 'center',
  },
};
