/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BlogLink from './Blog/BlogLink';
import BlogPageWrap from './Blog/BlogPageWrap';

export default function SupportPage() {
  return (
    <BlogPageWrap title="Support">
      <time />
      <h1>Support</h1>

      <p>
        The best way for our members to get support is to email us at{' '}
        <BlogLink href="mailto:team@oku.club">team@oku.club</BlogLink>, but you
        can also reach out on{' '}
        <BlogLink href="https://twitter.com/okuclub">
          Twitter at @okuclub
        </BlogLink>{' '}
        if you prefer.
      </p>
    </BlogPageWrap>
  );
}
