/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Grid } from 'theme-ui';

import GhostButton from '../components/GhostButton';
import PrimaryButton from '../components/PrimaryButton';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Badge from '../components/Badge';
import { TIERS } from '../constants';

// Scenarios:
// 1. Upgrade to higher tier
// 2. Sidegrade to same tier, different billing frequency
// 3. Downgrade to lower tier (happens after renew_date)
// 4. Downgrade to free (happens after renew_date)

// In each of these scenarios, how much & when the user is charged
// depends upon the rules documented with our payment provider:
// https://developer.paddle.com/guides/how-tos/subscriptions/upgrade-and-downgrade#upgrades--downgrades-billing-behaviour

export default function ConfirmSubscriptionModal({
  interval,
  isLoading,
  kind = TIERS.SUPPORTER,
  onClose,
  onConfirm,
  price,
}) {
  const bgColors = {
    [TIERS.FREE]: 'blueberry.5',
    [TIERS.SUPPORTER]: 'badge.supporter.13',
    [TIERS.PREMIUM]: 'badge.premium.13',
  };

  return (
    <div>
      <Heading as="h1" variant="h1" fontFamily="serif" sx={sx.heading}>
        Update your membership
      </Heading>
      <main sx={sx.body}>
        <div sx={{ bg: bgColors[kind], ...sx.box }}>
          <Badge kind={kind} />
          <div sx={sx.price}>
            ${price} <span>/ {interval}</span>
          </div>
        </div>
        <Text as="p" variant="subtitle2" sx={sx.subheading}>
          {kind === TIERS.FREE ? (
            <React.Fragment>
              We&apos;ll stop charging your card and downgrade your account at
              the end of your billing period.
            </React.Fragment>
          ) : (
            <React.Fragment>
              Your existing payment method provided will be charged{' '}
              <strong>US${price}</strong> each {interval} until cancelled.
            </React.Fragment>
          )}
        </Text>
        <Grid gap="s" columns={2}>
          <GhostButton disabled={isLoading} onClick={onClose}>
            Cancel
          </GhostButton>
          <PrimaryButton onClick={onConfirm} isLoading={isLoading}>
            Confirm
          </PrimaryButton>
        </Grid>
      </main>
    </div>
  );
}

const sx = {
  heading: {
    textAlign: 'center',
    marginTop: 'xl',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
    paddingBottom: 's',
  },
  subheading: {
    textAlign: 'center',
    paddingX: 's',
    marginY: 'm',
    color: 'blueberry.80',
    lineHeight: 'sub',
    strong: {
      fontWeight: 'bold',
    },
  },
  body: {
    padding: 's',
  },
  footer: {
    '> button': {
      width: '100%',
    },
  },
  box: {
    padding: 'm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 'button',
  },
  price: {
    fontSize: 'l',
    fontFamily: 'serif',
    color: 'blueberry.100',
    span: {
      color: 'blueberry.80',
      fontSize: 'xs',
      fontFamily: 'body',
    },
  },
};
