/** @jsxRuntime classic */
import React from 'react';
import { abandon, abandon2x } from '../assets';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeadingText from '../components/ModalHeadingText';
import ModalImage from '../components/ModalImage';
import PrimaryButton from '../components/PrimaryButton';

export default function SuggestionThanksModal({ onClose }) {
  return (
    <ModalBody canGrow={false}>
      <ModalContent extraStyles={sx.success}>
        <div sx={sx.image}>
          <ModalImage
            alt="Illustration of a man floating in white space with books and a dog around him"
            height={120}
            src={abandon}
            src2x={abandon2x}
            width={480}
          />
        </div>
        <ModalHeadingText
          heading="You’re incredible!"
          text="Thanks for helping us out, we will send you an email when the change has been approved."
        />
      </ModalContent>
      <ModalFooter direction="vertical">
        <PrimaryButton onClick={onClose}>No worries</PrimaryButton>
      </ModalFooter>
    </ModalBody>
  );
}

const sx = {
  success: {
    textAlign: 'center',
  },
  image: {
    marginTop: '-m',
    marginBottom: 'm',
  },
};
