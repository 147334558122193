/** @jsxRuntime classic */
/** @jsx jsx */
import { Link as RouterLink } from 'react-router-dom';
import { jsx } from 'theme-ui';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import { installAndroid, installAndroid2x, appStore2x } from '../../../assets';
import { APP_STORE_URL, POST_ROUTE } from '../../../constants';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Install Oku on your Android device / Blog">
      {/* <time>Feb 24, 2021</time> */}
      <time>Apr 11, 2022</time>
      <h1>Get Oku on your Android device</h1>

      <p>
        Oku is great on your computer but it works just as well on your Android
        device. Installing Oku lets you access it straight from your homescreen
        in a fullscreen window. You won&apos;t find it in the play stores, but
        it&apos;s really easy, so here&apos;s how it can be done.
      </p>

      <h2>How to install Oku on an Android device</h2>
      <br />
      <p>
        <li>
          Open Oku in Chrome and tap the three dots in the top-right hand
          corner.
        </li>
        <li>Tap &apos;add to homescreen&apos; in the menu that appears.</li>
        <li>There is no third step, you&apos;re done!</li>
      </p>

      <Image
        src={installAndroid}
        src2x={installAndroid2x}
        alt="Install Oku on an Android device"
      />

      <br />
      <br />

      <h2>Using iOS?</h2>

      <p>
        iOS users can find Oku on the{' '}
        <Link href={APP_STORE_URL}>App Store</Link>!
        <a href={APP_STORE_URL}>
          <Image
            src={appStore2x}
            src2x={appStore2x}
            alt="Oku is now available on iOS"
          />
        </a>
        Read the{' '}
        <Link to={POST_ROUTE.OkuForIosPost} as={RouterLink}>
          launch announcement post
        </Link>{' '}
        for more information.
      </p>
    </BlogPageWrap>
  );
}
