import { getThreadItems } from './threads';
import { getBook } from './book';

export function getBookReviewThreadId({ threadType, bookId, workId }) {
  const id = workId ? `w${workId}` : `b${bookId}`;
  return `reviews:book:${id}:${threadType}`;
}

export function getProfileReviewThreadId(username) {
  return `reviews:user:${username}`;
}

export function getReviewById(id) {
  return state => {
    return state.reviews.items[id];
  };
}

export function getReviewsForThread(thread) {
  const items = getThreadItems(thread);
  return state => {
    return items
      .map(id => {
        return getReviewById(id)(state);
      })
      .filter(r => r !== undefined);
  };
}

export function getReviewAndBookForThread(thread) {
  // for use specifically with the profile review thread,
  // which stores both the [reviewId, bookId]
  const items = getThreadItems(thread);
  return state => {
    return items
      .map(([reviewId, bookId]) => {
        return {
          book: getBook(bookId)(state),
          review: getReviewById(reviewId)(state),
        };
      })
      .filter(r => r.review !== undefined);
  };
}
