/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Image from '../../components/Image';
import InviteUsersButton from '../../components/InviteUsersButton';
import Text from '../../components/Text';

import { girlReading, girlReading2x } from '../../assets';

export default function EmptyState() {
  return (
    <div sx={sx.emptyState}>
      <div />
      <div sx={sx.emptyStateBody}>
        <Heading as="h1" variant="h1" fontFamily="serif">
          Books are better with friends
        </Heading>
        <Text as="p" variant="body">
          Follow some people and you’ll see their updates here.
        </Text>
        <div sx={sx.button}>
          <InviteUsersButton />
        </div>
      </div>
      <figure sx={sx.image}>
        <Image
          alt="Girl reading a book"
          maxWidth={300}
          respondToDarkMode
          src={girlReading}
          src2x={girlReading2x}
          visibleByDefault
          width="100%"
        />
      </figure>
    </div>
  );
}

const sx = {
  emptyState: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 'xl',
  },
  emptyStateBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '>p': {
      marginTop: 'xs',
    },
  },
  button: {
    marginTop: 'm',
  },
  image: {
    bg: 'milk',
    minHeight: [254, 254, 204, 204],
    overflow: 'hidden',
    '> span': {
      marginBottom: [-50, -50, -100, -100],
    },
  },
};
