import React from 'react';

import Svg from '../components/Svg';

export default function FeatureHeartIcon({ ...props }) {
  return (
    <Svg fill="none" height="32" viewBox="0 0 32 32" width="32" {...props}>
      <path
        d="m16.0312 22.375s-7.0312-3.9375-7.0312-8.7187c.00014-.8451.29296-1.664.82866-2.3176.53574-.6536 1.28124-1.1014 2.10984-1.2674.8286-.16605 1.6891-.04 2.4352.3568.7462.3967 1.3319 1.0397 1.6576 1.8194l-.0001.0001c.3257-.7798.9114-1.4228 1.6576-1.8195.7461-.3968 1.6066-.52285 2.4352-.3568.8286.166 1.5741.6138 2.1098 1.2674s.8286 1.4725.8287 2.3176c0 4.7812-7.0313 8.7187-7.0313 8.7187z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
}
