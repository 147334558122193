import React from 'react';

import Svg from '../components/Svg';

export default function ClockIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m4 14c0-5.52285 4.47715-10 10-10 5.5228 0 10 4.47715 10 10 0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10zm10-8c-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8 4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8zm0 2c.5523 0 1 .44772 1 1v4h3c.5523 0 1 .4477 1 1s-.4477 1-1 1h-4c-.5523 0-1-.4477-1-1v-5c0-.55228.4477-1 1-1z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
