import { push } from 'connected-react-router';
import { API_PROFILE_URL, NOT_FOUND_ROUTE } from '../constants';
import { listsById, listsWithoutKey, fetchApi } from '../utils';

// Actions
export const FOLLOW_LOADED = 'FOLLOW_LOADED';
export const FOLLOW_LOADING = 'FOLLOW_LOADING';
export const FOLLOW_USER = 'FOLLOW_USER';
export const REMOVE_PROFILE_LIST = 'REMOVE_PROFILE_LIST';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_FOLLOWS = 'SET_PROFILE_FOLLOWS';
export const SET_PROFILE_LIST = 'SET_PROFILE_LIST';
export const SET_PROFILE_LISTS = 'SET_PROFILE_LISTS';
export const SET_PROFILE_TIER = 'SET_PROFILE_TIER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';

// Reducer
const profileReducer = (state = {}, action) => {
  const username = (
    action.username ||
    action.user?.details?.username ||
    ''
  ).toLowerCase();
  switch (action.type) {
    case FOLLOW_USER:
      if (!username) {
        return state;
      }
      return {
        ...state,
        [username]: {
          ...state[username],
          details: {
            ...state[username].details,
            following: true,
          },
        },
      };
    case UNFOLLOW_USER:
      if (!username) {
        return state;
      }
      return {
        ...state,
        [username]: {
          ...state[username],
          details: {
            ...state[username].details,
            following: false,
          },
        },
      };
    case SET_PROFILE:
      if (!action.user.lists) {
        return {
          ...state,
          [username]: {
            ...state[username],
            details: action.user.details,
          },
        };
      }
      return {
        ...state,
        [username]: {
          ...state[username],
          collections: action.user.collections,
          details: action.user.details,
          lists: action.user.lists,
        },
      };
    case SET_PROFILE_TIER:
      if (!state?.[username]?.details) {
        return state;
      }
      return {
        ...state,
        [username]: {
          ...state[username],
          details: {
            ...state[username].details,
            tier: action.tier,
          },
        },
      };
    case SET_PROFILE_FOLLOWS:
      return {
        ...state,
        [username]: {
          ...state[username],
          followers: action.follows.followers,
          following: action.follows.following,
        },
      };
    case SET_PROFILE_LISTS:
      return {
        ...state,
        [username]: {
          ...state[username],
          lists: {
            ...state[username].lists,
            ...action.lists,
          },
        },
      };
    case SET_PROFILE_LIST:
      const id = action.list.key || action.list.id;
      return {
        ...state,
        [username]: {
          ...state[username],
          lists: {
            ...state[username]?.lists,
            [id]: action.list,
          },
        },
      };
    case REMOVE_PROFILE_LIST:
      const lists = state[username].lists;

      if (action.list.key) {
        delete lists[action.list.key];
      } else {
        delete lists[action.list.id];
      }

      return {
        ...state,
        [username]: {
          ...state[username],
          lists,
        },
      };
    default:
      return state;
  }
};

// Action creators
export const followUser = username => ({
  type: FOLLOW_USER,
  username,
});

export const unfollowUser = username => ({
  type: UNFOLLOW_USER,
  username,
});

export const setProfile = user => ({
  type: SET_PROFILE,
  user,
});

export const setProfileTier = (username, tier) => ({
  type: SET_PROFILE_TIER,
  username,
  tier,
});

export const setProfileLists = (username, collections) => ({
  type: SET_PROFILE_LISTS,
  username,
  collections,
});

export const setProfileList = (username, list) => ({
  type: SET_PROFILE_LIST,
  username,
  list,
});

export const removeProfileList = (username, list) => ({
  type: REMOVE_PROFILE_LIST,
  username,
  list,
});

export const setProfileFollows = (username, follows) => ({
  type: SET_PROFILE_FOLLOWS,
  username,
  follows,
});

// Side effects
export function shouldFetchProfile({
  dispatch,
  username,
  token,
  rejectOnFailure,
}) {
  return fetchApi(`${API_PROFILE_URL}/${username}`, {
    token,
  })
    .then(response => {
      if (response.status !== 404) {
        return response.json();
      }
      if (rejectOnFailure) {
        throw new Error('Request 404');
      } else {
        dispatch(push(NOT_FOUND_ROUTE));
      }
    })
    .then(data => {
      if (data) {
        const { user, collections } = data;
        shouldFetchFollows({ dispatch, username, token });
        dispatch(
          setProfile({
            collections: listsWithoutKey(collections),
            details: user,
            lists: listsById(collections),
          })
        );
      }
    });
}

export function shouldFetchFollows({ dispatch, username, token }) {
  return fetchApi(`${API_PROFILE_URL}/${username}/follows`, {
    token,
  })
    .then(response => {
      if (response.status !== 404) {
        return response.json();
      }
      dispatch(push(NOT_FOUND_ROUTE));
    })
    .then(data => {
      if (data) {
        dispatch(setProfileFollows(username, data));
      }
    });
}

export function shouldFollowUser({
  token,
  dispatch,
  username,
  shouldFetchUserFirst,
}) {
  const callback = () => {
    dispatch(followUser(username));
    return fetchApi(`${API_PROFILE_URL}/${username}`, {
      method: 'POST',
      token,
    });
  };
  if (shouldFetchUserFirst) {
    return shouldFetchProfile({
      dispatch,
      username,
      token,
    }).then(callback);
  }

  return callback();
}

export function shouldUnfollowUser({
  token,
  dispatch,
  username,
  shouldFetchUserFirst,
}) {
  const callback = () => {
    dispatch(unfollowUser(username));
    return fetchApi(`${API_PROFILE_URL}/${username}`, {
      method: 'DELETE',
      token,
    });
  };
  if (shouldFetchUserFirst) {
    return shouldFetchProfile({
      dispatch,
      username,
      token,
    }).then(callback);
  }
  return callback();
}

export default profileReducer;
