/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

import CircleButton from './CircleButton';
import { SettingsIcon } from '../icons';
import { SETTINGS_ROUTE } from '../constants';

export default function SettingsButton({ isActive }) {
  return (
    <CircleButton as={Link} to={SETTINGS_ROUTE} isActive={isActive}>
      <SettingsIcon />
    </CircleButton>
  );
}
