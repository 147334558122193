/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import {
  ACTIVITY_ROUTE,
  getCollectionsRoute,
  getProfileRoute,
  getReadingRoute,
  getReadRoute,
  getToReadRoute,
  HIGHLIGHTS_ROUTE,
  HOME_ROUTE,
  SEARCH_ROUTE,
} from '../constants';

import {
  BookmarkIcon,
  CheckIcon,
  EyeIcon,
  HighlightsIcon,
  HomeIcon,
  MoonIcon,
  PlusSmallIcon,
  SearchIcon,
  SunIcon,
} from '../icons/sidebar';

import GhostButton from './GhostButton';
import Link from './Link';
import Logo from './Logo';
import ModalDialog from './ModalDialog';
import SidebarCollections from './SidebarCollections';
import SidebarLink from './SidebarLink';
import SidebarUserBlock from './SidebarUserBlock';

import CreateCollectionModal from '../modals/CreateCollection';
import FeedbackModal from '../modals/Feedback';
import InviteModal from '../modals/InviteUsers';

import {
  getAvatar,
  getFullName,
  getProfile,
  getProfileDisplayListKeys,
  getProfileLists,
  getUsername,
  getUserStale,
} from '../selectors';

export default function Sidebar() {
  const avatar = useSelector(getAvatar);
  const fullName = useSelector(getFullName);
  const username = useSelector(getUsername);
  const isUserStale = useSelector(getUserStale);

  const readingRoute = getReadingRoute(username);
  const readRoute = getReadRoute(username);
  const toReadRoute = getToReadRoute(username);
  const profileRoute = getProfileRoute(username);
  const collectionsRoute = getCollectionsRoute(username);

  const user = useSelector(getProfile(username));
  const lists = getProfileLists(user);
  const listKeys = getProfileDisplayListKeys(user);

  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const [isCreatingCollection, setIsCreatingCollection] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  const openModal = () => {
    setIsCreatingCollection(true);
  };

  const closeModal = () => {
    setIsCreatingCollection(false);
  };

  const closeFeedbackModal = () => {
    setIsFeedbackOpen(false);
  };

  const openFeedbackModal = () => {
    setIsFeedbackOpen(true);
  };

  const closeInviteModal = () => {
    setIsInviteOpen(false);
  };

  const openInviteModal = () => {
    setIsInviteOpen(true);
    mixpanel.track('Modal: Invite User', {
      from: 'sidebar',
    });
  };

  // Reading goals logic (to change sidebar height)
  const localPreference = localStorage.getItem('READNG_READING_GOAL_OPEN');
  const localPreferenceNumber =
    localPreference === null ? 0 : parseInt(localPreference, 10);
  const [isReadingGoalMinimised, setIsReadingGoalMinimised] = useState(
    localPreferenceNumber
  );

  const hideReadingGoal = () => {
    localStorage.setItem('READNG_READING_GOAL_OPEN', 1);
    setIsReadingGoalMinimised(1);
  };

  const showReadingGoal = () => {
    localStorage.setItem('READNG_READING_GOAL_OPEN', 0);
    setIsReadingGoalMinimised(0);
  };

  const sidebarStyles = {
    ...sx.sidebar,
    '&:after': {
      content: '""',
      display: 'block',
      height: isReadingGoalMinimised ? 116 : 196,
      width: '100%',
    },
  };

  return (
    <React.Fragment>
      <aside sx={sidebarStyles}>
        <div sx={sx.logo}>
          <Logo />
        </div>
        <main sx={sx.main}>
          <nav sx={sx.nav}>
            <SidebarLink to={HOME_ROUTE} icon={HomeIcon}>
              Home
            </SidebarLink>
            <SidebarLink to={ACTIVITY_ROUTE} icon={isDark ? MoonIcon : SunIcon}>
              Feed
            </SidebarLink>
            <SidebarLink to={SEARCH_ROUTE} icon={SearchIcon}>
              Explore
            </SidebarLink>
            <SidebarLink to={HIGHLIGHTS_ROUTE} icon={HighlightsIcon}>
              Highlights
            </SidebarLink>
          </nav>
          <HeadingLink to={profileRoute}>Library</HeadingLink>
          <nav sx={sx.nav}>
            <SidebarLink to={toReadRoute} icon={BookmarkIcon}>
              To Read
            </SidebarLink>
            <SidebarLink to={readingRoute} icon={EyeIcon}>
              Reading
            </SidebarLink>
            <SidebarLink to={readRoute} icon={CheckIcon}>
              Read
            </SidebarLink>
          </nav>
          <HeadingLink to={collectionsRoute}>Collections</HeadingLink>
          <nav sx={sx.nav}>
            <SidebarLink onClick={openModal}>
              <div sx={{ ...sx.buttonInner, ...sx.createButton }}>
                <GhostButton sx={sx.ghostButton} size="inline">
                  <PlusSmallIcon />
                </GhostButton>
                Create new
              </div>
            </SidebarLink>
            <SidebarCollections
              isUserStale={isUserStale}
              listKeys={listKeys}
              lists={lists}
              username={username}
            />
          </nav>
        </main>
        <SidebarUserBlock
          avatar={avatar}
          fullName={fullName}
          hideReadingGoal={hideReadingGoal}
          isReadingGoalMinimised={isReadingGoalMinimised}
          openFeedbackModal={openFeedbackModal}
          openInviteModal={openInviteModal}
          profileRoute={profileRoute}
          showReadingGoal={showReadingGoal}
        />
      </aside>
      <ModalDialog onClose={closeModal} isVisible={isCreatingCollection}>
        <CreateCollectionModal onClose={closeModal} />
      </ModalDialog>
      <ModalDialog isVisible={isFeedbackOpen} onClose={closeFeedbackModal}>
        <FeedbackModal />
      </ModalDialog>
      <ModalDialog isVisible={isInviteOpen} onClose={closeInviteModal}>
        <InviteModal />
      </ModalDialog>
    </React.Fragment>
  );
}

function HeadingLink({ children, to }) {
  const as = to ? RouterLink : undefined;
  return (
    <Link as={as} to={to} sx={sx.heading}>
      {children}
    </Link>
  );
}

const sx = {
  sidebar: {
    bg: 'milk',
    borderRight: '1px solid',
    borderColor: 'blueberry.10',

    width: 'sidebar',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    overflow: 'auto',
  },
  logo: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 'm',
    marginBottom: 'xs',
  },
  main: {
    paddingX: 's',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  heading: {
    fontSize: 'xs',
    color: 'blueberry.100',
    paddingX: 'xs',
    marginTop: 'l',
    marginBottom: 's',
  },
  createButton: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonInner: {
    padding: 'xs',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  ghostButton: {
    bg: 'milk',
    marginRight: 12,
  },
};
