/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';

import CardSelectable from '../components/CardSelectable';
import Loader from '../components/Loader';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalHeading from '../components/ModalHeading';
import SearchInput from '../components/SearchInput';

import {
  getBookAuthors,
  getBookSubTitle,
  getBookTitle,
  getAllBooks,
} from '../selectors';

export default function NewHighlightChooseBook({ onSelectBook }) {
  const [books, setBooks] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const allBooks = useSelector(getAllBooks);

  // Populate the array initially
  useEffect(() => {
    if (books.length === 0 && allBooks) {
      setBooks(allBooks);
      setTimeout(() => setIsLoading(false), 250);
    }
  }, [allBooks]);

  // Clear the search query and return the full list
  const onSearchClear = () => {
    setSearch('');
    setBooks(allBooks);
  };

  // Handle the search query
  const onSearchChange = event => {
    if (!event) return;
    const { value } = event.target;
    setSearch(value);

    if (value === '') {
      onSearchClear();
      return;
    }
    const newBooks = allBooks.filter(book => {
      return (
        book.title.toLowerCase().includes(value.toLowerCase()) ||
        book.subtitle.toLowerCase().includes(value.toLowerCase())
      );
    });
    setBooks(newBooks);
  };

  return (
    <ModalBody maxHeight={['85vh', '85vh', 694]}>
      <ModalHeading>New highlight</ModalHeading>
      <div sx={sx.search}>
        <SearchInput
          as={DebounceInput}
          debounceTimeout={250}
          onChange={onSearchChange}
          onClear={onSearchClear}
          placeholder="Search for a book in your lists"
          value={search}
        />
      </div>
      <ModalContent>
        {isLoading ? (
          <div sx={sx.loader}>
            <Loader />
          </div>
        ) : (
          <div sx={sx.books}>
            {books?.map(book => {
              const { title, subtitle } = book;
              const bookTitle = getBookTitle({ title, subtitle });
              const bookSubtitle = getBookSubTitle({ title, subtitle });
              const authors = getBookAuthors(book.authors);
              return (
                <div key={book.id} sx={sx.card}>
                  <CardSelectable
                    authors={authors}
                    onClick={() => onSelectBook(book)}
                    subtitle={bookSubtitle}
                    thumbnail={book.imageLinks.thumbnail}
                    title={bookTitle}
                    searchWords={search.split(' ')}
                  />
                </div>
              );
            })}
          </div>
        )}
      </ModalContent>
    </ModalBody>
  );
}

const sx = {
  body: {
    padding: 'm',
  },
  search: {
    paddingX: ['s', 's', 'm'],
    paddingTop: ['s', 's', 'm'],
  },
  card: {
    marginBottom: 's',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  books: {
    marginTop: 'm',
    '> h3': {
      marginBottom: 's',
    },
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
};
