/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import range from 'lodash-es/range';
import random from 'lodash-es/random';

import { getCollectionRoute } from '../constants';
import ContentLoader from './ContentLoader';
import SidebarLink from './SidebarLink';

import { StarIcon, ClockIcon } from '../icons/sidebar';

export default function SidebarCollections({
  isUserStale,
  listKeys,
  lists,
  username,
}) {
  // Show loading state
  if (isUserStale) {
    return range(4).map(item => (
      <div key={item} sx={sx.loader.outer}>
        <ContentLoader
          sx={{
            ...sx.loader.inner,
            width: `${random(60, 100)}%`,
          }}
        />
      </div>
    ));
  }

  // Re-order the list keys to put My Rcs and Abandoned at the top
  const orderedListKeys = [];
  listKeys.map(key => {
    if (key === 'recs' || (key === 'abandoned' && listKeys[0] !== 'recs')) {
      return orderedListKeys.unshift(key);
    }
    if (key === 'abandoned' && listKeys[0] === 'recs') {
      return orderedListKeys.splice(1, 0, key);
    }
    return orderedListKeys.push(key);
  });

  return orderedListKeys.map((listKey, index) => {
    const { slug, name, key } = lists[listKey];
    const collectionRoute = getCollectionRoute(username, {
      slug,
      key,
    });
    return (
      <SidebarLink key={index} to={collectionRoute} icon={getIcon(listKey)}>
        <div sx={getInner(listKey)}>
          {name === 'My Recommendations' ? 'Recommendations' : name}
        </div>
      </SidebarLink>
    );
  });
}

const getIcon = key => {
  switch (key) {
    case 'recs':
      return StarIcon;
    case 'abandoned':
      return ClockIcon;
    default:
      return null;
  }
};

const getInner = key => {
  if (key !== 'recs' && key !== 'abandoned') {
    return sx.buttonInner;
  }
  return null;
};

const sx = {
  buttonInner: {
    padding: 'xs',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    button: {
      marginRight: 'xs',
    },
  },
  loader: {
    outer: {
      width: '100%',
      paddingY: 'xs',
      paddingX: 'xs',
      marginBottom: 'xxs',
    },
    inner: {
      height: 28,
      borderRadius: 'toast',
    },
  },
  popoverWrap: {
    position: 'relative',
    '&:hover > div': {
      opacity: 1,
      visibility: 'visible',
      transform: 'translate3d(0, 0, 0)',
    },
  },
};
