/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';
import { Link } from 'react-router-dom';

import ActivityDot from '../../components/ActivityDot';
import Avatar from '../../components/Avatar';
import BookCover from '../../components/BookCover';

import FeedItem from './FeedItem';

export default function BookFeedItem({
  action,
  avatar,
  bookRoute,
  containsSpoiler,
  cover,
  date,
  firstName,
  link,
  listKey,
  profileRoute,
  review,
  title,
  visibleByDefault,
}) {
  return (
    <FeedItem
      action={action}
      containsSpoiler={containsSpoiler}
      date={date}
      firstName={firstName}
      link={link}
      review={review}
      title={title}
    >
      <div sx={sx.body}>
        <Link to={bookRoute}>
          <AspectRatio ratio={5 / 3} sx={sx.aspect}>
            <BookCover
              thumbnail={cover}
              thumbnail2x={cover}
              title={title}
              visibleByDefault={visibleByDefault}
              width="30%"
            />
          </AspectRatio>
        </Link>
        <div sx={sx.avatarWrapper}>
          <Link to={profileRoute} sx={sx.avatar}>
            <Avatar src={avatar} src2x={avatar} name={firstName} />
            <div sx={sx.dot}>
              <ActivityDot isReview={!!review} isOnFeed list={listKey} />
            </div>
          </Link>
        </div>
      </div>
    </FeedItem>
  );
}

const sx = {
  avatarWrapper: {
    position: 'absolute',
    top: 16,
    left: 16,
  },
  avatar: {
    display: 'block',
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  aspect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
