/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import ListHeading from './ListHeading';
import SwiperNavigation from './SwiperNavigation';

export default function SwiperHeading({
  heading,
  prevClassName,
  nextClassName,
}) {
  return (
    <div sx={sx.heading}>
      <ListHeading
        alignItems="center"
        additionalElements={
          <SwiperNavigation
            prevClassName={prevClassName}
            nextClassName={nextClassName}
          />
        }
      >
        {heading}
      </ListHeading>
    </div>
  );
}

const sx = {
  heading: {
    paddingX: ['s', 's', 0],
  },
};
