/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { Link } from 'react-router-dom';

import { hasTouch } from '../utils';
import { getBookRoute } from '../constants';

export default function Card({
  children,
  extraStyles,
  href,
  isActive,
  onClick,
  slug,
  target,
  to,
}) {
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const selectedStyles = isActive && {
    borderColor: 'blueberry.80',
    boxShadow: isDarkMode ? 'collection.selectedDark' : 'collection.selected',
    '&:hover': !hasTouch() && {
      ...sx.card['&:hover'],
      borderColor: 'blueberry.80',
    },
  };

  const styles = {
    ...sx.card,
    ...extraStyles,
    ...selectedStyles,
  };

  if (!slug && !to && !onClick && !href) {
    return <div sx={styles}>{children}</div>;
  }
  if (href) {
    return (
      <a sx={styles} href={href} target={target}>
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      // eslint-disable-next-line
      <div sx={styles} onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <Link to={to || getBookRoute(slug)} sx={styles}>
      {children}
    </Link>
  );
}

const sx = {
  card: {
    display: 'block',
    border: '1px solid',
    borderColor: 'blueberry.10',
    borderRadius: 'button',
    backgroundClip: 'padding-box',

    bg: 'milk',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'smooth.fast',
    position: 'relative',
    backfaceVisibility: 'hidden',
    willChange: 'transform',

    WebkitMaskImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)',

    '&:hover': !hasTouch() && {
      bg: 'blueberry.2',
      borderColor: 'blueberry.20',
      transform: 'translate3d(0, -1px, 0) perspective(1px)',
    },
    '&:active': {
      transform: 'translate3d(0, 1px, 0) perspective(1px)',
    },
  },
};
