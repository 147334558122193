import { useEffect } from 'react';
import { useColorMode } from 'theme-ui';

import { APPEARANCE_PREFERENCE, MODE_SYSTEM_DEFAULT } from '../constants';
import { messageNative } from '../utils/native';

export function useColorModeBasedOnMediaQuery() {
  const [colorMode, setColorMode] = useColorMode();
  const localPreference =
    localStorage.getItem(APPEARANCE_PREFERENCE) || MODE_SYSTEM_DEFAULT;

  useEffect(() => {
    switch (localPreference) {
      case MODE_SYSTEM_DEFAULT:
      case 'untitled':
        const switchMode = event => {
          if (event.matches) {
            setColorMode('dark');
            messageNative({ colorMode: 'dark' });
          } else {
            setColorMode('default');
            messageNative({ colorMode: 'default' });
          }
        };

        const darkModeMediaQuery = window.matchMedia(
          '(prefers-color-scheme: dark)'
        );

        if (darkModeMediaQuery.matches) {
          setColorMode('dark');
          messageNative({ colorMode: 'dark' });
        } else {
          setColorMode('default');
          messageNative({ colorMode: 'default' });
        }

        darkModeMediaQuery.addListener(switchMode);
        return () => {
          darkModeMediaQuery.removeListener(switchMode);
        };
      default:
        break;
    }
  }, [colorMode, setColorMode, localPreference]);
}
