/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';

import Link from '../../components/Link';
import Text from '../../components/Text';
import MarkdownBlock from '../../components/MarkdownBlock';

export default function Description({ description }) {
  const [isConcealed, setIsConcealed] = useState(true);
  const truncateTo = 1000;
  const canTruncate = description.length > truncateTo;
  const truncatedDescription = `${description.substring(0, truncateTo)}...`;

  const toggleConcealed = () => {
    setIsConcealed(!isConcealed);
  };

  return (
    <Text as="div" variant="body" sx={sx.text}>
      <DescriptionInner
        canTruncate={canTruncate}
        isConcealed={isConcealed}
        description={description}
        truncatedDescription={truncatedDescription}
      />
      {canTruncate && (
        <div sx={sx.container}>
          <Link
            variant="primary"
            hasUnderline
            onClick={toggleConcealed}
            sx={sx.link}
          >
            {isConcealed ? '↓ Show more' : '↑ Show less'}
          </Link>
        </div>
      )}
    </Text>
  );
}

function DescriptionInner({
  canTruncate,
  isConcealed,
  description,
  truncatedDescription,
}) {
  if (canTruncate && !isConcealed) {
    return (
      <span sx={sx.formatted}>
        <MarkdownBlock>{description}</MarkdownBlock>
      </span>
    );
  }
  if (canTruncate && isConcealed) {
    return (
      <span sx={sx.formatted}>
        <MarkdownBlock>{truncatedDescription}</MarkdownBlock>
      </span>
    );
  }
  return (
    <span sx={sx.formatted}>
      <MarkdownBlock>{description}</MarkdownBlock>
    </span>
  );
}

const sx = {
  text: {
    color: 'blueberry.100',
    maxWidth: 820,
  },
  container: {
    display: 'block',
    marginTop: 's',
  },
  link: {
    lineHeight: 1.7,
  },
  formatted: {
    'b:empty': {
      display: 'none',
    },
    'i:empty': {
      display: 'none',
    },
    'p:empty': {
      display: 'none',
    },
    p: {
      marginBottom: 24,
    },
  },
};
