function getGreeting() {
  const hours = new Date().getHours();

  if (hours >= 5 && hours <= 12) {
    return 'Good morning';
  }

  if (hours >= 13 && hours <= 17) {
    return 'Welcome back';
  }

  if (hours >= 18) {
    return 'Good evening';
  }

  return 'Welcome back';
}

export function getPersonalisedGreeting(name) {
  if (name) {
    return `${getGreeting()}, ${name}`;
  }
  return `${getGreeting()}!`;
}
