/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Box } from 'theme-ui';
import { useParams } from 'react-router-dom';
import { useResponsiveValue } from '@theme-ui/match-media';
import range from 'lodash-es/range';

import CardSelectableSmall from '../../components/CardSelectableSmall';
import ContentLoader from '../../components/ContentLoader';
import Heading from '../../components/Heading';
import PageNavigation from '../../components/PageNavigation';
import CreateHighlightButton from './CreateHighlightButton';

import theme from '../../theme';
import { getBookTitle } from '../../selectors';
import { pluralise } from '../../utils';

import { getPersonalHighlightRoute, HIGHLIGHTS_ROUTE } from '../../constants';

export default function HighlightsSidebar({
  books,
  closeModal,
  isLoading,
  openModal,
  selectedBook,
  showModal,
}) {
  const { bookId } = useParams();
  const isOnDesktop = useResponsiveValue([false, false, false, false, true]);

  if (isLoading) {
    return (
      <div sx={sx.sidebar}>
        {isOnDesktop ? (
          <Box marginTop="m" marginBottom="s">
            <HighlightsHeading />
          </Box>
        ) : (
          <SidebarNavigation
            closeModal={closeModal}
            isLoading={isLoading}
            openModal={openModal}
            selectedBook={selectedBook}
            showModal={showModal}
          />
        )}
        <main sx={sx.padding}>
          {range(4).map(item => (
            <div key={item} sx={sx.card}>
              <ContentLoader sx={sx.loader} />
            </div>
          ))}
        </main>
      </div>
    );
  }

  const noBooks = !books || books?.length === 0;
  if (isOnDesktop && noBooks) {
    return null;
  }

  if (!isOnDesktop && noBooks) {
    return (
      <div sx={sx.sidebar}>
        <SidebarNavigation
          closeModal={closeModal}
          isLoading={isLoading}
          openModal={openModal}
          selectedBook={selectedBook}
          showModal={showModal}
        />
      </div>
    );
  }

  return (
    <div sx={sx.sidebar}>
      {isOnDesktop ? (
        <Box marginTop="m" marginBottom="s">
          <HighlightsHeading />
        </Box>
      ) : (
        <SidebarNavigation
          closeModal={closeModal}
          isLoading={isLoading}
          openModal={openModal}
          selectedBook={selectedBook}
          showModal={showModal}
        />
      )}
      <main sx={sx.padding}>
        {books?.map(item => {
          const { title, id } = item.book;
          const bookTitle = getBookTitle({ title });
          const isSelected = id === bookId;
          const subtitle = pluralise({
            count: item.highlightCount,
            word: 'highlight',
          });
          return (
            <div key={id} sx={sx.card}>
              <CardSelectableSmall
                isSelected={isSelected}
                subtitle={subtitle}
                thumbnail={item.book.imageLinks.thumbnail}
                title={bookTitle}
                to={
                  isSelected ? HIGHLIGHTS_ROUTE : getPersonalHighlightRoute(id)
                }
              />
            </div>
          );
        })}
      </main>
    </div>
  );
}

function SidebarNavigation({
  closeModal,
  isLoading,
  openModal,
  selectedBook,
  showModal,
}) {
  return (
    <React.Fragment>
      <PageNavigation>
        <CreateHighlightButton
          closeModal={closeModal}
          isLoading={isLoading}
          openModal={openModal}
          selectedBook={selectedBook?.book}
          showModal={showModal}
        />
      </PageNavigation>
      <Box marginBottom="s">
        <HighlightsHeading />
      </Box>
    </React.Fragment>
  );
}

function HighlightsHeading() {
  return (
    <div sx={sx.heading}>
      <Heading as="h1" fontFamily="serif" variant="h1">
        Your Highlights
      </Heading>
    </div>
  );
}

const SIDEBAR_WIDTH = theme.sizes.sidebar * 1.75;

const sx = {
  card: {
    marginBottom: 's',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  loader: {
    height: 120,
    borderRadius: 'button',
  },
  sidebar: {
    bg: 'milk',
    borderRight: '1px solid',
    borderColor: 'blueberry.10',
    flex: 1,
    maxWidth: ['100%', '100%', '100%', '100%', SIDEBAR_WIDTH],
    overflow: ['unset', 'unset', 'unset', 'unset', 'auto'],
  },
  padding: {
    padding: 's',
  },
  heading: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 's',
  },
};
