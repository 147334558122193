import React from 'react';

import Svg from '../components/Svg';

export default function Keyboard() {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.625 14.875V5.125H17.375V14.875H2.625ZM2.53906 3.625C1.7581 3.625 1.125 4.2581 1.125 5.03906V14.9609C1.125 15.7419 1.7581 16.375 2.53906 16.375H17.4609C18.2419 16.375 18.875 15.7419 18.875 14.9609V5.03906C18.875 4.2581 18.2419 3.625 17.4609 3.625H2.53906ZM4.375 9.25C3.96079 9.25 3.625 9.58579 3.625 10C3.625 10.4142 3.96079 10.75 4.375 10.75H15.625C16.0392 10.75 16.375 10.4142 16.375 10C16.375 9.58579 16.0392 9.25 15.625 9.25H4.375ZM3.625 7.5C3.625 7.08579 3.96079 6.75 4.375 6.75H15.625C16.0392 6.75 16.375 7.08579 16.375 7.5C16.375 7.91421 16.0392 8.25 15.625 8.25H4.375C3.96079 8.25 3.625 7.91421 3.625 7.5ZM4.375 11.75C3.96079 11.75 3.625 12.0858 3.625 12.5C3.625 12.9142 3.96079 13.25 4.375 13.25H5C5.41421 13.25 5.75 12.9142 5.75 12.5C5.75 12.0858 5.41421 11.75 5 11.75H4.375ZM6.75 12.5C6.75 12.0858 7.08579 11.75 7.5 11.75H12.5C12.9142 11.75 13.25 12.0858 13.25 12.5C13.25 12.9142 12.9142 13.25 12.5 13.25H7.5C7.08579 13.25 6.75 12.9142 6.75 12.5ZM15 11.75C14.5858 11.75 14.25 12.0858 14.25 12.5C14.25 12.9142 14.5858 13.25 15 13.25H15.625C16.0392 13.25 16.375 12.9142 16.375 12.5C16.375 12.0858 16.0392 11.75 15.625 11.75H15Z"
        fill="currentColor"
      />
    </Svg>
  );
}
