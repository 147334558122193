import { useEffect } from 'react';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export default function useOnClickOutside(ref, handler) {
  useEffect(() => {
    if (!canUseDOM) return;
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!handler || !ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
