/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';

import * as Plot from '@observablehq/plot';

import { usePlot } from '../../hooks';
import { colors } from '../../theme/colors';
import { sizes, space } from '../../theme/sizes';
import { fontSizes } from '../../theme/typography';

export default function BookLengthGraph({ data, colorRange }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const maxWidth = useResponsiveValue([
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.laptop,
    sizes.maxWidth.desktop,
  ]);

  const spaceXL = useResponsiveValue([space.l, space.l, space.l, space.xl]);
  const realWidth = maxWidth - space.l * 2 - spaceXL * 2 - 2; // acount for spacing on the sides plus borders on each side

  const options = {
    width: realWidth,
    height: realWidth / 3,
    x: {
      round: true,
      label: null,
      tickSize: 0,
      inset: 30,
    },
    y: {
      label: null,
      grid: true,
      tickSize: 0,
    },
    color: {
      // range: isDark
      //   ? ['rgb(100, 116, 93)', 'rgb(181, 201, 172)']
      //   : ['rgb(154, 181, 142)', 'rgb(43, 88, 79)'],
      range: colorRange,
    },
    marks: [
      Plot.barY(
        data || [],
        Plot.stackY({
          x: 'year',
          y: 'count',
          fill: 'type',
        })
      ),
    ],
    style: {
      fontSize: fontSizes.xxs,
      color: isDark ? colors.modes.dark.blueberry[80] : colors.blueberry[80],
    },
  };

  const ref = usePlot(options);
  return <div ref={ref} />;
}
