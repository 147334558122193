// Actions
export const SET_KEY = 'object.set';
export const REMOVE_KEY = 'object.remove';

// Reducer
const objectReducer = (
  state = {
    items: {},
  },
  action
) => {
  switch (action.type) {
    case SET_KEY:
      return {
        ...state,
        items: {
          ...state.items,
          [action.key]: action.value,
        },
      };
    case REMOVE_KEY:
      const newState = {
        ...state,
      };
      delete newState[action.key];
      return newState;
    default:
      return state;
  }
};

export const setKey = ({ key, value, type = SET_KEY }) => ({
  type,
  key,
  value,
});

export const removeKey = ({ key, type = REMOVE_KEY }) => ({
  type,
  key,
});

export default objectReducer;
