/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable no-console */
import { jsx, Grid, useColorMode } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import Badge from '../components/Badge';
import Button from '../components/Button';
import ConfirmSubscriptionModal from '../modals/ConfirmSubscription';
import Footer from './Home/Footer';
import GhostButton from '../components/GhostButton';
import Heading from '../components/Heading';
import ModalDialog from '../components/ModalDialog';
import NavigationMenu from '../components/NavigationMenu';
import Page from '../components/Page';
import PlanButton from '../components/PlanButton';
import PageHeader from '../components/PageHeader';
import PageNavigation from '../components/PageNavigation';
import PrimaryButton from '../components/PrimaryButton';
import TeamBlock from './Home/Team';
import Text from '../components/Text';
import Toggle from '../components/Toggle';
import StatsBlock from './Home/StatsBlock';
import GoalsBlock from './Home/GoalsBlock';
import HighlightsBlock from './Home/HighlightsBlock';

import {
  TIERS,
  SIGN_UP_ROUTE,
  PROCESSOR_APPLE,
  APP_STORE_URL,
} from '../constants';
import { colors } from '../theme/colors';
import { getIsAuthenticated, getToken, getUsername } from '../selectors';
import { FeatureArrowIcon, FeatureHeartIcon, FeatureTickIcon } from '../icons';

import {
  startCheckoutSession,
  switchExistingSubscription,
  fetchSubscriptionDetails,
  fetchLocalPrices,
} from '../ducks/subscription';

import {
  getSubscriptionDetails,
  getSubTier,
  getSubCancelled,
  getSubRenewDate,
  getProductPrices,
} from '../selectors/subscription';
import {
  formatCurrency,
  messageNative,
  canUseNativePayments,
  isNativeIos,
} from '../utils';

export default function PricingPage() {
  const [billingCycle, setBillingCycle] = useState('year');
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const isAuthenticated = useSelector(getIsAuthenticated);
  const username = useSelector(getUsername);

  const [session, setSession] = useState({});
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const [subLoading, setSubLoading] = useState(isAuthenticated);

  const theSub = useSelector(getSubscriptionDetails);
  const currentTier = theSub ? getSubTier(theSub) : TIERS.FREE;
  const subCancelled = getSubCancelled(theSub);
  const subRenewDate = getSubRenewDate(theSub);
  const useNativePayments = canUseNativePayments(theSub);
  const isNative = isNativeIos();

  const showOnMobile = useResponsiveValue([true, true, true, false]);

  const prices = useSelector(getProductPrices);

  let localCurrency = 'USD';
  if (prices !== undefined) {
    try {
      localCurrency = Object.values(prices).find(
        p => p.currency !== localCurrency
      ).currency;
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscriptionDetails({ dispatch, token }).then(() => {
        // only enable the subscribe/switch plan buttons once we've fetched
        // the current subscription status
        setSubLoading(false);
      });
    }

    const productIds = PLANS.map(plan =>
      Object.values(plan.getProductIds(theSub))
    ).reduce((prev, current) => {
      return prev.concat(current);
    });

    if (useNativePayments) {
      // we need to indicate to the ios app to load the prices
      // from apple rather than getting them from paddle
      messageNative({
        loadProducts: productIds,
      });
      return;
    }

    // localises prices via paddle. Not used for iOS
    fetchLocalPrices({ productIds, dispatch });
  }, []);

  useEffect(() => {
    try {
      mixpanel.track('View Pricing', {
        isAuthenticated,
        native: useNativePayments,
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const onCycleChange = () => {
    if (billingCycle === 'year') {
      setBillingCycle('month');
    } else {
      setBillingCycle('year');
    }
  };

  const columns = [
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
  ];

  const isYearly = billingCycle === 'year';
  const openAppStore = e => {
    e.preventDefault();
    window.location.href = APP_STORE_URL;
  };

  const subscribeToPlan = ({ productId, tier, interval }) => {
    return () => {
      if (useNativePayments) {
        messageNative({
          purchaseSubscription: {
            tier,
            interval,
            productId,
          },
        });
        mixpanel.track('Checkout: Started', {
          tier,
          currentTier,
          native: true,
        });
        // if native, don't continue with web checkout
        return;
      }

      // todo: should probably move this to the ducks?
      const onPaymentSuccess = (data, sessionKey) => {
        const checkoutId = data.checkout.id;
        console.log(
          'payment success',
          sessionKey,
          checkoutId,
          JSON.stringify(data)
        );
        // the checkout progress will handle polling until the payment is fully
        // processed & the user's subscription is set up
        dispatch(
          push(`/checkout/${sessionKey}`, {
            checkoutId,
          })
        );
        mixpanel.track('Checkout: Payment Success', {
          tier,
          interval,
          native: false,
        });
      };

      startCheckoutSession({ token, tier, interval })
        .then(
          ({
            customerId,
            sessionKey,
            paddlePlanId,
            paddleSubId,
            subscription,
          }) => {
            console.log(
              'checkout started',
              customerId,
              sessionKey,
              paddleSubId,
              subscription
            );

            const isCancelled = getSubCancelled(subscription);
            const checkoutNeeded = paddleSubId === null || isCancelled;
            mixpanel.track('Checkout: Started', {
              checkoutNeeded,
              tier,
              currentTier,
              native: false,
            });
            if (tier !== TIERS.FREE && checkoutNeeded) {
              window.Paddle.Checkout.open({
                product: paddlePlanId,
                // email: "joe@oku.club",
                successCallback: data => onPaymentSuccess(data, sessionKey),
                passthrough: JSON.stringify({ key: sessionKey }),
                displayModeTheme: colorMode,
                allowQuantity: false,
              });
            } else {
              console.log(
                'no checkout needed, modify existing subscription here'
              );

              // pass off the rest of the process to the confirm switch modal
              const plan = PLANS.find(({ id }) => id === tier);
              const price = plan.price[interval];
              setSession({ tier, interval, price });
              setShowModal(true);
            }
          }
        )
        .catch(e => {
          toast(
            'Error when starting your checkout session, please try again shortly'
          );
          console.error(e);
          mixpanel.track('Checkout: Error', {
            tier,
            currentTier,
            interval,
            where: 'sessionStart',
            native: false,
          });
        });
    };
  };

  const confirmSwitch = () => {
    console.log('session is', session);
    setSubLoading(true);
    return switchExistingSubscription({
      dispatch,
      token,
      tier: session.tier,
      interval: session.interval,
      username,
    })
      .then(() => {
        setSubLoading(false);
        setSession({});
        setShowModal(false);
        mixpanel.track('Checkout: Confirmed', {
          tier: session.tier,
          interval: session.interval,
          native: false,
        });
      })
      .catch(e => {
        console.error(e);
        toast(
          'Unable to switch your subscription. Please reach out to help@oku.club'
        );
        mixpanel.track('Checkout: Error', {
          tier: session.tier,
          currentTier,
          interval: session.interval,
          where: 'subSwitch',
          native: false,
        });
      });
  };

  const getButton = ({
    productId,
    tier,
    isEnabled,
    isFree,
    isCancelled,
    sub,
  }) => {
    if (!isAuthenticated) {
      const copy = isFree ? 'Join for free' : 'Subscribe';
      return (
        <PlanButton as={RouterLink} to={SIGN_UP_ROUTE} sx={sx.button}>
          {copy}
        </PlanButton>
      );
    }

    const commonAttrs = {};
    if (subLoading || !currentTier) {
      commonAttrs.isLoading = true;
    }

    if (isEnabled) {
      let text = 'Current plan';
      if (isCancelled) {
        const now = moment();
        text = `Cancelled (${subRenewDate.diff(now, 'days')} days left)`;
      }
      return (
        <GhostButton disabled sx={sx.button} {...commonAttrs}>
          {text}
        </GhostButton>
      );
    }

    if (isCancelled) {
      // current sub is cancelled, so will revert to free after
      // the next renew date
      const copy = isFree ? 'Next plan' : 'Unavailable';
      return (
        <GhostButton sx={sx.button} {...commonAttrs} disabled>
          {copy}
        </GhostButton>
      );
    }

    const upgradeButton = (
      <PrimaryButton
        onClick={subscribeToPlan({ productId, tier, interval: billingCycle })}
        sx={sx.button}
        {...commonAttrs}
      >
        Subscribe
      </PrimaryButton>
    );

    const downgradeButton = (
      <Button
        variant={isDark ? 'dragonfruit.dark' : 'dragonfruit.default'}
        onClick={subscribeToPlan({ productId, tier, interval: billingCycle })}
        sx={sx.button}
        {...commonAttrs}
      >
        Downgrade
      </Button>
    );

    const manageViaAppleButton = (
      <GhostButton sx={sx.button} {...commonAttrs} onClick={openAppStore}>
        Manage via App
      </GhostButton>
    );

    if (
      sub?.payment_processor === PROCESSOR_APPLE &&
      !canUseNativePayments(sub)
    ) {
      // user is already subscribed via apple but are not using
      // the native app, so we shouldn't let them change their subscription
      return manageViaAppleButton;
    }

    const scores = {
      [TIERS.FREE]: 0,
      [TIERS.PREMIUM]: 1,
      [TIERS.SUPPORTER]: 2,
    };
    const isUpgrade = scores[currentTier] < scores[tier];

    if (isUpgrade) {
      return upgradeButton;
    }
    return downgradeButton;
  };

  return (
    <Page headerless hasSidebar={false} title="Pricing" showSidebar={false}>
      {isAuthenticated && showOnMobile ? (
        <React.Fragment>
          <PageNavigation showBackButton />
          <NavigationMenu />
        </React.Fragment>
      ) : (
        <PageHeader showHeader />
      )}
      <div sx={sx.header}>
        <Heading as="h1" fontFamily="serif" variant="marketing">
          Oku Premium
        </Heading>
        <Text as="p" color="blueberry.80" variant="body" sx={sx.subheading}>
          Our premium friends get some cool extras, plus the warm feeling inside
          that comes from supporting an indie team.
        </Text>
        {!isNative && currentTier === TIERS.FREE && (
          <div sx={sx.producthuntWrapper}>
            <Text
              as="p"
              color="blueberry.80"
              variant="body"
              sx={sx.producthunt}
            >
              Grab <strong>50% off yearly premium</strong> during January and
              make 2024 your best year of books yet!
            </Text>
          </div>
        )}
        <div sx={sx.billingCycleSwitch}>
          <Text
            sx={{
              ...sx.billingCycleText,
              color: isYearly ? 'blueberry.50' : 'blueberry.100',
            }}
          >
            Pay monthly
          </Text>
          <div sx={sx.toggle}>
            <Toggle
              checked={billingCycle === 'year'}
              id="billing-cycle"
              onChange={onCycleChange}
            />
          </div>
          <div sx={{ position: 'relative' }}>
            <Text
              sx={{
                ...sx.billingCycleText,
                color: !isYearly ? 'blueberry.50' : 'blueberry.100',
              }}
            >
              Pay yearly
            </Text>
            <div sx={sx.bestValue}>Save {isNative ? '30%' : '50%'}!</div>
          </div>
        </div>
      </div>
      <Grid gap="s" columns={columns} sx={sx.grid}>
        {PLANS.map(plan => {
          const {
            id,
            bg,
            price: _price,
            description,
            features,
            getProductIds,
          } = plan;
          const isFree = id === TIERS.FREE;
          const isEnabled = id === currentTier;
          const usdPrice = _price[billingCycle];
          const productIds = getProductIds(theSub);
          const productId = productIds[billingCycle];
          const planButton = getButton({
            isEnabled,
            isFree,
            tier: id,
            isCancelled: subCancelled,
            productId,
            sub: theSub,
          });
          const product = (prices || {})[productId];
          const currency = product?.currency || localCurrency;
          const price = product?.price?.gross || usdPrice;
          return (
            <div key={id}>
              <div sx={{ bg, ...sx.plan }}>
                <div>
                  {id === TIERS.PREMIUM && isYearly && (
                    <div sx={{ ...sx.triangle, ...sx.trianglePremium }}>
                      <div sx={sx.discount}>save 50%</div>
                    </div>
                  )}
                  {id === TIERS.SUPPORTER && isYearly && (
                    <div sx={{ ...sx.triangle, ...sx.triangleSupporter }}>
                      <div sx={sx.discount}>save 30%</div>
                    </div>
                  )}
                  <div sx={sx.badge}>
                    {isFree ? <h3 sx={sx.name}>Free</h3> : <Badge kind={id} />}
                  </div>
                  <div sx={sx.priceContainer}>
                    <Text sx={sx.price}>
                      {/* {isYearly && !isFree && <span>${price.month * 12}</span>} */}
                      {formatCurrency(price, currency)}
                    </Text>
                    {!isFree && (
                      <React.Fragment>
                        {isYearly ? (
                          <Text sx={sx.billingCycle}>
                            <span>/ year</span>
                            <span>
                              (
                              <strong>
                                {formatCurrency(price / 52, currency)}
                              </strong>{' '}
                              per week)
                            </span>
                          </Text>
                        ) : (
                          <Text sx={sx.billingCycle}>/ month</Text>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  <Text sx={sx.description}>{description}</Text>
                  {features.current.length > 0 && (
                    <ul sx={sx.featureList}>
                      {features.current.map(feature => (
                        <li key={feature.id} sx={sx.feature}>
                          <div sx={sx.featureIcon}>
                            <Icon kind={feature.type} />
                          </div>
                          {feature.name}
                        </li>
                      ))}
                    </ul>
                  )}
                  {features.planned.length > 0 && (
                    <div>
                      <Text sx={sx.comingSoon}>Plus, help us build:</Text>
                      <ul sx={sx.featureList}>
                        {features.planned.map(feature => (
                          <li key={feature.id} sx={sx.feature}>
                            <div sx={sx.featureIcon}>
                              <Icon kind={feature.type} />
                            </div>
                            {feature.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {planButton}
              </div>
            </div>
          );
        })}
      </Grid>

      <Line />
      <GoalsBlock />
      <Line />
      <StatsBlock />
      <Line />
      <HighlightsBlock />
      <Line />

      <div
        sx={{
          textAlign: 'center',
          maxWidth: 912,
          marginX: 'auto',
          paddingBottom: 'xxl',
          paddingX: 'l',
        }}
      >
        <Heading as="h2" fontFamily="serif" variant="marketing">
          Why charge money?
        </Heading>
        <Text
          as="p"
          color="blueberry.80"
          variant="body"
          sx={{
            marginTop: 'm',
            maxWidth: 720,
            marginX: 'auto',
            fontSize: ['xs', 'xs', 'm', 17, 17],
          }}
        >
          We have big ambitions for Oku, but things can move rather slowly when
          we can only work on it in our spare time! After more than 18 months of
          evenings and weekends we’re itching to pour more time into it, which
          ultimately requires a business model.
        </Text>
      </div>

      <div
        sx={{
          textAlign: 'center',
          maxWidth: 912,
          marginX: 'auto',
          paddingBottom: 'xxl',
          paddingX: 'l',
        }}
      >
        <Heading as="h2" fontFamily="serif" variant="marketing">
          Why not show ads?
        </Heading>
        <Text
          as="p"
          color="blueberry.80"
          variant="body"
          sx={{
            marginTop: 'm',
            maxWidth: 720,
            marginX: 'auto',
            fontSize: ['xs', 'xs', 'm', 17, 17],
          }}
        >
          We are member-supported so that our success is directly linked to
          member satisfaction, rather than how many ads we can show and how much
          data we can collect to microtarget them. Our members should be the
          customer and not the product.
        </Text>
      </div>

      <TeamBlock />
      {!isAuthenticated && <Footer />}
      <ModalDialog isVisible={showModal} onClose={closeModal}>
        <ConfirmSubscriptionModal
          interval={session.interval}
          isLoading={subLoading}
          kind={session.tier}
          onClose={closeModal}
          onConfirm={confirmSwitch}
          price={session.price}
        />
      </ModalDialog>
    </Page>
  );
}

function Icon({ kind }) {
  switch (kind) {
    case 'arrow':
      return <FeatureArrowIcon />;
    case 'heart':
      return <FeatureHeartIcon />;
    default:
      return <FeatureTickIcon />;
  }
}

function Line() {
  return (
    <div sx={sx.line}>
      <div />
    </div>
  );
}

const sx = {
  header: {
    textAlign: 'center',
    maxWidth: 'marketing.laptop',
    marginX: 'auto',
    marginTop: ['xl', 'xl', 'xl', 'xxl'],
    marginBottom: 'xxl',
  },
  subheading: {
    maxWidth: 580,
    marginX: 'auto',
    fontSize: ['xs', 'xs', 'm', 17, 17],
    marginTop: ['s', 's', 's', 'm'],
    paddingX: ['m', 'm', 'm', 0],
  },
  producthuntWrapper: {
    paddingX: ['s', 's', 's', 0],
  },
  producthunt: {
    maxWidth: 420,
    marginX: 'auto',
    fontSize: ['xs', 'xs', 's'],
    marginTop: ['s', 's', 's', 'xl'],
    padding: 's',
    bg: 'banana.7',
    borderRadius: 'button',
    strong: {
      display: 'inline',
      fontWeight: 'bold',
    },
  },
  grid: {
    maxWidth: 'marketing.desktop',
    marginX: 'auto',
    marginBottom: 'xxl',
    paddingX: 's',
  },
  line: {
    marginX: 'auto',
    maxWidth: 'marketing.laptop',
    marginY: [80, 80, 120, 120, 120],
    '> div': {
      height: 1,
      bg: 'blueberry.10',
    },
  },
  plan: {
    padding: [28, 28, 28, 40],
    borderRadius: 'button',
    overflow: 'hidden',
    position: 'relative',
    height: ['auto', 'auto', '100%', '100%', 'auto', 'auto', 'auto'],

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  badge: {
    display: 'flex',
    marginBottom: 'xs',
  },
  name: {
    height: '23px',
    lineHeight: '23px',
    fontFamily: 'serif',
    color: 'blueberry.100',
    fontSize: 's',
  },
  button: {
    width: '100%',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  price: {
    marginTop: 'xs',
    fontSize: '39px',
    fontFamily: 'serif',
    color: 'blueberry.100',
    lineHeight: 1,
    '>span': {
      color: 'blueberry.50',
      textDecoration: ' line-through',
      marginRight: 'xs',
    },
  },
  billingCycle: {
    color: 'blueberry.80',
    fontSize: 'm',
    marginLeft: 12,
    lineHeight: 'sub',
    '>span': {
      display: 'block',
    },
    '> span:first-of-type': {
      fontSize: 'xs',
    },
    '> span:last-child': {
      fontSize: 'xxs',
    },
    strong: {
      fontWeight: 'button',
      color: 'blueberry.100',
    },
  },
  description: {
    color: 'blueberry.100',
    fontSize: 'xs',
    marginTop: 's',
    marginBottom: 'l',
  },
  billingCycleSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginY: 'xl',
  },
  billingCycleText: {
    fontSize: 'm',
    color: 'blueberry.100',
    fontFamily: 'serif',
  },
  toggle: {
    marginX: 'm',
  },
  comingSoon: {
    marginBottom: 's',
  },
  featureList: {
    marginBottom: 'l',
  },
  featureIcon: {
    position: 'absolute',
    top: -3,
    left: 0,
  },
  feature: {
    fontSize: 'xs',
    color: 'blueberry.100',
    marginBottom: 's',
    lineHeight: 'body',
    paddingLeft: 'l',
    position: 'relative',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  triangle: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: -10,
    right: -42,
    transform: 'rotate(45deg)',
  },
  trianglePremium: {
    borderBottom: `64px solid ${colors.badge.premium[100]}`,
    borderLeft: '64px solid transparent',
    borderRight: '64px solid transparent',
  },
  triangleSupporter: {
    borderBottom: `64px solid ${colors.badge.supporter[100]}`,
    borderLeft: '64px solid transparent',
    borderRight: '64px solid transparent',
  },
  discount: {
    position: 'absolute',
    top: 40,
    left: -27,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 'xxs',
    color: colors.milk,
    whiteSpace: 'nowrap',
  },
  bestValue: {
    position: 'absolute',
    top: '100%',
    left: 0,
    color: 'badge.premium.100',
    fontSize: 'xxs',
  },
};

const PLANS = [
  {
    id: TIERS.FREE,
    name: 'Free',
    getProductIds: () => Object(),
    price: {
      month: 0,
      year: 0,
    },
    description:
      'All accounts let you track, rate & review books, plus basic collection organisation tools.',
    bg: 'blueberry.5',
    features: {
      current: [
        { id: 101, name: 'Track books and build your TBR', type: 'tick' },
        { id: 102, name: 'Rate & review', type: 'tick' },
        { id: 110, name: 'Follow your friends', type: 'tick' },
        { id: 103, name: 'Create your own collections', type: 'tick' },
        { id: 106, name: 'Save highlights from your books', type: 'tick' },
        { id: 104, name: 'Yearly reading goals', type: 'tick' },
        { id: 105, name: 'Personal reading stats', type: 'tick' },
      ],
      planned: [],
    },
  },
  {
    id: TIERS.PREMIUM,
    name: 'Premium',
    getProductIds: sub => {
      return {
        month: canUseNativePayments(sub)
          ? 'club.oku.ios.premium.monthly'
          : 758324,
        year: canUseNativePayments(sub)
          ? 'club.oku.ios.premium.yearly'
          : 867272,
      };
    },
    price: {
      month: 6,
      year: 35,
    },
    description: 'Help us pay the bills continue working on improvements!',
    bg: 'badge.premium.13',
    features: {
      current: [
        {
          id: 0,
          type: 'arrow',
          name: 'Everything from Free',
        },
        {
          id: 10,
          type: 'tick',
          name: 'Make your collections private',
        },
        // {
        //   id: 1,
        //   type: 'tick',
        //   name: 'Personal reading stats',
        // },
        // { id: 2, name: 'Save highlights from your books', type: 'tick' },
        // { id: 3, name: 'Yearly reading goals', type: 'tick' },
        // {
        //   id: 8,
        //   type: 'tick',
        //   name: 'Private highlights',
        // },
        {
          id: 4,
          type: 'tick',
          name: 'Premium profile badge',
        },
        {
          id: 5,
          type: 'tick',
          name: 'Access to our subscriber Discord',
        },
        {
          id: 6,
          type: 'tick',
          name: 'Support an indie team!',
        },
        // {
        //   id: 2,
        //   type: 'tick',
        //   name: 'Pin reviews to your profile',
        // },
        // {
        //   id: 3,
        //   type: 'tick',
        //   name: 'Access to our roadmap to suggest and/or upvote features',
        // },
      ],
      planned: [
        // {
        //   id: 0,
        //   type: 'tick',
        //   name: 'Advanced collections',
        // },
        // {
        //   id: 3,
        //   type: 'tick',
        //   name: 'Customisable profiles',
        // },
        // {
        //   id: 10,
        //   type: 'tick',
        //   name: 'Search improvements',
        // },
        // {
        //   id: 2,
        //   type: 'tick',
        //   name: 'Filter and sort books in any collection',
        // },
      ],
    },
  },
  {
    id: TIERS.SUPPORTER,
    name: 'Supporter',
    // todo: should probs get these ids from the backend
    getProductIds: sub => {
      return {
        month: canUseNativePayments(sub)
          ? 'club.oku.ios.supporter.monthly'
          : 653714,
        year: canUseNativePayments(sub)
          ? 'club.oku.ios.supporter.yearly'
          : 653715,
      };
    },
    price: {
      month: 15,
      year: 125,
    },
    description:
      'You believe in what we’re doing and really want to show your support!',
    bg: 'badge.supporter.13',
    features: {
      current: [
        {
          id: 0,
          type: 'arrow',
          name: 'Everything in Premium',
        },
        {
          id: 2,
          type: 'tick',
          name: 'Early access to new features',
        },
        // {
        //   id: 1,
        //   type: 'tick',
        //   name: 'Your own spot on our supporter wall (coming soon)',
        // },
        // {
        //   id: 4,
        //   type: 'tick',
        //   name: 'Priority support',
        // },
        {
          id: 5,
          type: 'heart',
          name: 'Our eternal gratitude',
        },
      ],
      planned: [],
    },
  },
];
