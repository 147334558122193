import React from 'react';

import Svg from '../components/Svg';

export default function EyeClosedIcon(props) {
  return (
    <Svg viewBox="0 0 256 256" {...props}>
      <line
        x1="201.14971"
        y1="127.30467"
        x2="223.95961"
        y2="166.81257"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14"
      />
      <line
        x1="154.18201"
        y1="149.26298"
        x2="161.29573"
        y2="189.60689"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14"
      />
      <line
        x1="101.72972"
        y1="149.24366"
        x2="94.61483"
        y2="189.59423"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14"
      />
      <line
        x1="54.80859"
        y1="127.27241"
        x2="31.88882"
        y2="166.97062"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M31.99943,104.87509C48.81193,125.68556,79.63353,152,128,152c48.36629,0,79.18784-26.31424,96.00039-47.12468"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14"
      />
    </Svg>
  );
}
