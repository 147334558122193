/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import LoadingPage from '../Loading';

import { getToken } from '../../selectors';
import { ROOT_ROUTE } from '../../constants';
import { shouldSignOut } from '../../ducks/auth';

export default function SignOut() {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  useEffect(() => {
    if (token === 'null') {
      dispatch(push(ROOT_ROUTE));
    } else {
      shouldSignOut({ dispatch });
    }
  }, []);

  return <LoadingPage />;
}
