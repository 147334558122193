import React from 'react';

import Svg from '../components/Svg';

export default function ReviewSmall(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m4 5.5c0-.27614.22386-.5.5-.5h7.2187c.2762 0 .5001.22386.5001.5s-.2239.5-.5001.5h-7.2187c-.27614 0-.5-.22386-.5-.5zm0 2.29639c0-.27615.22386-.5.5-.5h7.2187c.2762 0 .5001.22385.5001.5 0 .27614-.2239.5-.5001.5h-7.2187c-.27614 0-.5-.22386-.5-.5zm.5 1.79785c-.27614 0-.5.22386-.5.49996 0 .2762.22386.5.5.5h7.2187c.2762 0 .5001-.2238.5001-.5 0-.2761-.2239-.49996-.5001-.49996z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
