import React from 'react';

import Svg from '../components/Svg';

export default function BurgerClose(props) {
  return (
    <Svg {...props}>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="m6 6 15.5563 15.5563" />
        <path d="m6 21.5562 15.5563-15.5564" />
      </g>
    </Svg>
  );
}
