/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Heading from '../../components/Heading';
import Text from '../../components/Text';

import { getBookHighlightsRoute } from '../../constants';
import { getBookTitle } from '../../selectors';
import { pluralise } from '../../utils';

export default function HighlightPageHeader({ title, slug, highlightCount }) {
  return (
    <div sx={sx.container}>
      <RouterLink to={getBookHighlightsRoute(slug)}>
        <Heading
          as="h1"
          marginTop={['s', 's', 0]}
          fontFamily="serif"
          variant="h1"
        >
          {getBookTitle({ title })}
        </Heading>
      </RouterLink>
      <Text
        as="h3"
        marginBottom={['l', 'l', 'l', 'xl']}
        marginTop="xxs"
        variant="body"
      >
        {pluralise({
          count: highlightCount,
          word: 'highlight',
        })}
      </Text>
    </div>
  );
}

const sx = {
  container: {
    textAlign: ['center', 'center', 'center', 'center', 'left'],
    paddingRight: [0, 0, 0, 0, 200], // Because of button
  },
};
