import { toast } from 'react-toastify';
import {
  API_SEARCH_AUTHORS_URL,
  API_SEARCH_URL,
  API_SEARCH_USERS_URL,
} from '../constants';
import { callApi } from '../utils';

// Actions
export const SET_AUTHOR_HINTS = 'SET_AUTHOR_HINTS';
export const SET_SEARCH_BOOK_RESULTS = 'SET_SEARCH_BOOK_RESULTS';
export const SET_SEARCH_USER_RESULTS = 'SET_SEARCH_USER_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_LOADED = 'SEARCH_LOADED';

// Reducer
const searchReducer = (
  state = {
    isLoading: false,
    parameters: {
      query: '',
      startIndex: 0,
      maxResults: 10,
    },
    books: {},
    users: {},
    authorHints: [],
  },
  action
) => {
  switch (action.type) {
    case SEARCH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_SEARCH_BOOK_RESULTS:
      const newBookResults = { ...state.books };
      action.searchResults.forEach(
        result => (newBookResults[result.id] = result)
      );
      return {
        ...state,
        books: newBookResults,
        parameters: action.searchParameters,
      };
    case SET_AUTHOR_HINTS:
      return {
        ...state,
        authorHints: action.hints,
      };
    case SET_SEARCH_USER_RESULTS:
      const newUserResults = { ...state.users };
      action.searchResults.forEach(
        result => (newUserResults[result.userId] = result)
      );
      return {
        ...state,
        users: newUserResults,
        parameters: action.searchParameters,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        isLoading: false,
        parameters: {
          query: '',
          startIndex: 0,
          maxResults: 10,
        },
        books: {},
        users: {},
      };
    default:
      return {
        ...state,
      };
  }
};

// Action creators
export const searchLoading = () => ({
  type: SEARCH_LOADING,
});

export const searchLoaded = () => ({
  type: SEARCH_LOADED,
});

export const setSearchBookResults = (searchResults, searchParameters) => ({
  type: SET_SEARCH_BOOK_RESULTS,
  searchResults,
  searchParameters,
});

export const setAuthorHints = authors => ({
  type: SET_AUTHOR_HINTS,
  hints: authors,
});

export const setSearchUserResults = (searchResults, searchParameters) => ({
  type: SET_SEARCH_USER_RESULTS,
  searchResults,
  searchParameters,
});

export const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export function fetchAuthorSearch({ query, startIndex = 0, token }) {
  return callApi(API_SEARCH_AUTHORS_URL, {
    method: 'POST',
    body: { query, startIndex },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(({ matches }) => {
    return { matches };
  });
}

export function fetchSearchResults({
  isSearchingForUsers,
  lang = null,
  maxResults = 10,
  query,
  author,
  startIndex = 0,
  token,
}) {
  let searchAlgorithm;
  return fetch(isSearchingForUsers ? API_SEARCH_USERS_URL : API_SEARCH_URL, {
    method: 'POST',
    body: JSON.stringify({ query, author, startIndex, maxResults, lang }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(async response => {
    searchAlgorithm = response.headers.get('X-Search-Algo') || 'Unknown';
    const hints = response.headers
      .get('x-author-hints')
      ?.split(':')
      .filter(s => !!s);

    const header = (
      response.headers.get('X-Results-Degraded') || 'false'
    ).toLowerCase();
    if (header === 'true') {
      toast(
        "Searches are temporarily returning limited results. Sorry! We're working on it.",
        {
          autoClose: 7500,
        }
      );
    }
    const results = await response.json();
    return { results, hints, searchAlgorithm };
  });
}

// Side effects
export function shouldSearch({
  dispatch,
  isSearchingForUsers,
  lang = null,
  maxResults = 10,
  query,
  author,
  startIndex = 0,
  token,
}) {
  dispatch(searchLoading());
  return fetchSearchResults({
    dispatch,
    isSearchingForUsers,
    lang,
    maxResults,
    query,
    author,
    startIndex,
    token,
  }).then(({ results, hints, searchAlgorithm }) => {
    if (isSearchingForUsers) {
      dispatch(
        setSearchUserResults(results, { query, startIndex, maxResults })
      );
    } else {
      dispatch(
        setSearchBookResults(results, { query, startIndex, maxResults })
      );
    }
    if (hints && hints.length > 0) {
      dispatch(setAuthorHints(hints));
    }
    dispatch(searchLoaded());

    return { searchAlgorithm, results, hints };
  });
}

export default searchReducer;
