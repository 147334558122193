/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Marquee3k from 'marquee3000';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import Page from '../../components/Page';
import ModalDialog from '../../components/ModalDialog';
import SignUpModal from '../../modals/SignUp';

import BooksBlock from './Books';
import FooterBlock from './Footer';
import GoodreadsBlock from './Goodreads';
import HeroBlock from './Hero';
import MoreInfoBlock from './MoreInfo';
import FeaturesBlock from './Features';
import TeamBlock from './Team';

import { getFeed } from '../../selectors';
import { shouldFetchFeed } from '../../ducks/feed';

export default function HomePage() {
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const feed = useSelector(getFeed(false));
  const { items, updated } = feed;
  const wasRecentlyUpdated = moment().subtract(45, 'seconds').isBefore(updated);

  useEffect(() => {
    // The code below crashes the page in development, disabling it for now
    if (!isLoading && process.env.NODE_ENV !== 'development') {
      setIsLoading(true);
      shouldFetchFeed({ dispatch, friends: false })
        .then(() => {
          setIsLoading(false);
          Marquee3k.init();
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [updated === undefined, wasRecentlyUpdated]);

  useEffect(() => {
    mixpanel.track('View Home');
    window.plausible('new-home-view');
  }, []);

  const closeSignUp = () => {
    setSignUpOpen(false);
  };

  const openSignUp = () => {
    setSignUpOpen(true);
  };

  return (
    <Page headerless title="The companion app for every book">
      <HeroBlock openSignUp={openSignUp} />
      <FeaturesBlock />
      <GoodreadsBlock />
      <BooksBlock items={items} isLoading={isLoading} />
      <MoreInfoBlock />
      <TeamBlock />
      <FooterBlock />
      <ModalDialog isVisible={isSignUpOpen} onClose={closeSignUp}>
        <SignUpModal />
      </ModalDialog>
    </Page>
  );
}
