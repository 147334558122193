import React from 'react';

import Svg from '../components/Svg';

export default function ShareIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m16.625 8.375c0-.9665.7835-1.75 1.75-1.75s1.75.7835 1.75 1.75-.7835 1.75-1.75 1.75c-.6118 0-1.1502-.3139-1.463-.78942l-.009-.01422-.0098-.01489c-.1699-.26974-.2682-.58913-.2682-.93147zm1.75 3.25c-.8754 0-1.67-.3461-2.2544-.909l-3.4292 2.2044c.1189.3378.1836.7012.1836 1.0796 0 .3786-.0647.742-.1837 1.0799l3.429 2.2044c.5844-.5631 1.3791-.9093 2.2547-.9093 1.7949 0 3.25 1.4551 3.25 3.25s-1.4551 3.25-3.25 3.25-3.25-1.4551-3.25-3.25c0-.3784.0647-.7417.1835-1.0794l-3.4292-2.2045c-.5844.5628-1.3789.9089-2.2543.9089-1.79493 0-3.25-1.4551-3.25-3.25s1.45507-3.25 3.25-3.25c.8755 0 1.6702.3462 2.2545.9091l3.4292-2.20438c-.119-.33782-.1837-.70122-.1837-1.07972 0-1.79493 1.4551-3.25 3.25-3.25s3.25 1.45507 3.25 3.25c0 1.7949-1.4551 3.25-3.25 3.25zm-7.2648 3.301c-.0046.0067-.0091.0135-.0135.0204-.0043.0065-.0084.0131-.0124.0198-.3133.4724-.8499.7838-1.4593.7838-.9665 0-1.75-.7835-1.75-1.75s.7835-1.75 1.75-1.75c.6079 0 1.1433.3099 1.457.7804.0047.0079.0096.0158.0147.0236.0052.0081.0105.0161.016.0239.1663.2678.2623.5837.2623.9221 0 .34-.097.6574-.2648.926zm5.7799 3.7725c-.168.2687-.2651.5862-.2651.9265 0 .9665.7835 1.75 1.75 1.75s1.75-.7835 1.75-1.75-.7835-1.75-1.75-1.75c-.6098 0-1.1468.3119-1.4601.785-.0038.0064-.0078.0127-.0119.019-.0042.0066-.0085.0131-.0129.0195z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
