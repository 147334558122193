/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import Button from './Button';
import GhostButton from './GhostButton';
import Logo from './Logo';
import Link from './Link';

import {
  BLOG_ROUTE,
  PRICING_ROUTE,
  REQUEST_ACCESS_ROUTE,
  SEND_FORGOT_PASSWORD_ROUTE,
  SENT_FORGOT_PASSWORD_ROUTE,
  SET_FORGOT_PASSWORD_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  TWITTER_LINK,
  getSupportEmailLink,
  SEARCH_ROUTE,
} from '../constants';

import { getIsAuthenticated } from '../selectors';
import { BurgerOpenIcon, BurgerCloseIcon } from '../icons';

export default function PageHeaderMenu({
  onJoinClick,
  shouldHide,
  withSpace = false,
}) {
  const [ref, inView] = useInView();
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const extraStyles = !inView && {
    bg: isDark ? 'rgba(19, 19, 20, 0.96)' : 'rgba(255, 255, 255, 0.96)',
    boxShadow: isDark ? 'none' : '0 1px 0 0 rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(10px)',
  };

  const extraSpace = withSpace && {
    marginBottom: ['m', 'm', 'l'],
  };

  return (
    <React.Fragment>
      {shouldHide ? (
        <div sx={sx.placeholderHidden} ref={ref} />
      ) : (
        <div sx={{ ...sx.placeholderNormal, ...extraSpace }} ref={ref} />
      )}
      <div sx={{ ...sx.fixedMenu, ...extraStyles }}>
        <InnerMenu onJoinClick={onJoinClick} />
      </div>
    </React.Fragment>
  );
}

function InnerMenu({ onJoinClick }) {
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { pathname } = location;

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const isOnDesktop = useResponsiveValue([false, false, false, false, true]);

  useEffect(() => {
    setIsMenuOpen(isOnDesktop);
  }, [isOnDesktop]);

  const SIGN_IN_ROUTE_WITH_PARAMS = {
    pathname: SIGN_IN_ROUTE,
    search: location.search,
    state: {
      successUrl: pathname,
    },
  };

  const isNotOnAuthPages =
    pathname !== SIGN_IN_ROUTE &&
    pathname !== SIGN_UP_ROUTE &&
    pathname !== REQUEST_ACCESS_ROUTE &&
    pathname !== SEND_FORGOT_PASSWORD_ROUTE &&
    pathname !== SENT_FORGOT_PASSWORD_ROUTE &&
    pathname !== SET_FORGOT_PASSWORD_ROUTE;

  return (
    <div sx={sx.menuInner}>
      <div sx={sx.logo}>
        <Logo />
      </div>
      {isMenuOpen && (
        <nav sx={sx.links}>
          <li sx={sx.mobileLink}>
            <Link onClick={onJoinClick} variant="primary">
              Join
            </Link>
          </li>
          <li sx={sx.mobileLink}>
            <Link
              as={RouterLink}
              hasUnderline={SIGN_IN_ROUTE === pathname}
              to={SIGN_IN_ROUTE_WITH_PARAMS}
              variant="primary"
            >
              Sign in
            </Link>
          </li>
          <li>
            <Link
              as={RouterLink}
              hasUnderline={SEARCH_ROUTE === pathname}
              to={{ pathname: SEARCH_ROUTE, search: location.search }}
              variant="primary"
            >
              Explore
            </Link>
          </li>
          <li>
            <Link
              as={RouterLink}
              hasUnderline={BLOG_ROUTE === pathname}
              to={{ pathname: BLOG_ROUTE, search: location.search }}
              variant="primary"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              as={RouterLink}
              hasUnderline={PRICING_ROUTE === pathname}
              to={{ pathname: PRICING_ROUTE, search: location.search }}
              variant="primary"
            >
              Pricing
            </Link>
          </li>
          <li>
            <Link variant="primary" target="_blank" href={TWITTER_LINK}>
              Twitter
            </Link>
          </li>
          <li>
            <Link
              variant="primary"
              target="_blank"
              href={getSupportEmailLink()}
            >
              Contact
            </Link>
          </li>
        </nav>
      )}
      {isNotOnAuthPages && !isAuthenticated && (
        <div sx={sx.buttons}>
          <GhostButton
            as={RouterLink}
            to={SIGN_IN_ROUTE_WITH_PARAMS}
            sx={sx.signInButton}
          >
            Sign in
          </GhostButton>
          <Button onClick={onJoinClick} sx={sx.joinButton}>
            Join
          </Button>
        </div>
      )}
      <div sx={sx.burger}>
        {isMenuOpen ? (
          <Button
            onClick={() => setIsMenuOpen(false)}
            sx={sx.emptyButton}
            transformOnHover={false}
          >
            <BurgerCloseIcon width={24} height={24} />
          </Button>
        ) : (
          <Button
            onClick={() => setIsMenuOpen(true)}
            sx={sx.emptyButton}
            transformOnHover={false}
          >
            <BurgerOpenIcon width={22} height={22} />
          </Button>
        )}
      </div>
    </div>
  );
}

const sx = {
  placeholderNormal: {
    position: 'relative',
    width: '100%',
    height: 80,
    pointerEvents: 'none',
  },
  placeholderHidden: {
    position: 'absolute',
    width: '100%',
    height: 80,
    top: 160,
    pointerEvents: 'none',
  },
  fixedMenu: {
    position: 'fixed',
    height: 80,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'footer',
    transition: 'smooth.medium',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuInner: {
    width: '100%',
    paddingX: 'm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  signInButton: {
    bg: 'milk',
    borderRadius: 'button',
    minWidth: [90, 100, 112, 112, 112, 112],
    marginRight: 's',
  },
  joinButton: {
    borderRadius: 'button',
    minWidth: [90, 100, 112, 112, 112, 112],
  },
  links: {
    listStyle: 'none',
    margin: 0,
    padding: ['m', 'm', 'm', 0],
    display: 'flex',
    alignItems: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'center',
    ],
    position: 'absolute',
    top: [0, 0, 0, 0, 'auto'],
    left: [0, 0, 0, 0, '50%'],
    right: [0, 0, 0, 0, 'auto'],
    bottom: [0, 0, 0, 0, 'auto'],
    transform: ['none', 'none', 'none', 'none', 'translateX(-50%)'],
    zIndex: 'overlay',
    bg: ['milk', 'milk', 'milk', 'milk', 'transparent'],
    width: ['100vw', '100vw', '100vw', '100vw', 'auto'],
    height: ['100vh', '100vh', '100vh', '100vh', 'auto'],
    flexDirection: ['column', 'column', 'column', 'column', 'row'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'center',
    ],
    li: {
      marginX: 's',
      marginY: 'xs',
      lineHeight: 'sub',
      fontSize: ['xl', 'xl', 'xl', 'xl', 'm'],
    },
    a: {
      fontFamily: ['serif', 'serif', 'serif', 'serif', 'body'],
    },
  },
  buttons: {
    display: ['none', 'none', 'none', 'none', 'block'],
  },
  mobileLink: {
    display: ['block', 'block', 'block', 'block', 'none'],
  },
  burger: {
    display: ['block', 'block', 'block', 'block', 'none'],
    position: 'relative',
    zIndex: 'overlay',
  },
  emptyButton: {
    bg: 'transparent',
    color: 'blackberry',
    paddingX: 0,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
};
