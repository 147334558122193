/** @jsxRuntime classic */
/** @jsx jsx */

import { useEffect, useRef } from 'react';
import * as Plot from '@observablehq/plot';

const usePlot = options => {
  const ref = useRef(null);

  useEffect(() => {
    const plot = Plot.plot(options);
    if (ref.current) {
      if (ref.current.children[0]) {
        ref.current.children[0].remove();
      }
      ref.current.appendChild(plot);
    }
  }, [ref, options]);
  return ref;
};

export default usePlot;
