import React from 'react';

import Svg from '../components/Svg';

export default function BurgerOpen(props) {
  return (
    <Svg {...props}>
      <path
        d="M3 14H25M3 6H25M3 22H25"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  );
}
