/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { toast } from 'react-toastify';

import Link from '../components/Link';
import { onReload } from '../utils';
import { ROOT_ROUTE } from '../constants';

const onUpdateReady = () => {
  const sx = { display: 'inline-block' };
  toast(
    <React.Fragment>
      A new version is available.{' '}
      <Link hasUnderline onClick={onReload} variant="milk" sx={sx}>
        Update now!
      </Link>
    </React.Fragment>
  );
};

const useCache = pathname => {
  useEffect(() => {
    const { applicationCache, removeEventListener } = window;
    const isOnRoot = pathname === ROOT_ROUTE;

    if (isOnRoot || !applicationCache) {
      return;
    }

    applicationCache.addEventListener('updateready', onUpdateReady);

    if (applicationCache.status === applicationCache.UPDATEREADY) {
      onUpdateReady();
    }

    // Remove event listeners on cleanup
    return () => {
      removeEventListener('updateready', onUpdateReady);
    };
  }, []);
};

export default useCache;
