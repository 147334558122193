/* eslint-disable */
import React from 'react';

export const pluralise = ({ count, word }) => {
  if (count === 1) {
    return `${count} ${word}`;
  }
  return `${count} ${word}s`;
};

export const nthify = number => {
  if (number > 3 && number < 21) return `${number}th`;
  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const pluraliseWithSpan = ({ count, word }) => {
  if (count === 1) {
    return (
      <React.Fragment>
        <span>{count}</span> {word}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <span>{count}</span> {word}s
    </React.Fragment>
  );
};

// the url path looks something like this: /book/radical-candor-YHpW6
// the bookId should always be on the end of the slug.
// it's ok if the slug is only the bookId too: /book/YHpW6
export const getBookId = slug => {
  const parts = slug.split('-');
  return parts[parts.length - 1];
};
