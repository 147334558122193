import React from 'react';

import Svg from '../components/Svg';

export default function QuestionIcon(props) {
  return (
    <Svg height="40" viewBox="0 0 40 40" width="40" fill="none" {...props}>
      <path
        d="m20 35c8.2843 0 15-6.7157 15-15s-6.7157-15-15-15-15 6.7157-15 15 6.7157 15 15 15z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="m20 30c1.0355 0 1.875-.8395 1.875-1.875s-.8395-1.875-1.875-1.875-1.875.8395-1.875 1.875.8395 1.875 1.875 1.875z"
        fill="currentColor"
      />
      <path
        d="m20 22.5v-1.25c.8653 0 1.7112-.2566 2.4306-.7373.7195-.4808 1.2802-1.164 1.6114-1.9635.3311-.7994.4177-1.6791.2489-2.5277-.1688-.8487-.5855-1.6282-1.1973-2.2401-.6119-.6118-1.3914-1.0285-2.2401-1.1973-.8486-.1688-1.7283-.0822-2.5277.2489-.7995.3312-1.4827.8919-1.9635 1.6114-.4807.7194-.7373 1.5653-.7373 2.4306"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  );
}
