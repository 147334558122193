import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducer from './app';
import authReducer from './auth';
import booksReducer from './books';
import feedReducer from './feed';
import formsReducer from './forms';
import goalsReducer from './goals';
import highlightsReducer from './highlights';
import invitesReducer from './invites';
import listsReducer from './lists';
import profileReducer from './profile';
import searchReducer from './search';
import statsReducer from './stats';
import subscriptionReducer from './subscription';
import threadsReducer from './threads';
import reviewsReducer from './reviews';
import userReducer from './user';

const createRootReducer = history =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    books: booksReducer,
    feed: feedReducer,
    forms: formsReducer,
    goals: goalsReducer,
    highlights: highlightsReducer,
    invites: invitesReducer,
    lists: listsReducer,
    profile: profileReducer,
    router: connectRouter(history),
    search: searchReducer,
    stats: statsReducer,
    subscription: subscriptionReducer,
    threads: threadsReducer,
    user: userReducer,
    reviews: reviewsReducer,
  });

export default createRootReducer;
