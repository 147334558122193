/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const BADGE_SIZE = 24;

export default function TabInner({ title, isActive, count }) {
  return (
    <span sx={sx.badge}>
      {title}
      {typeof count !== 'undefined' && (
        <span
          sx={{
            ...sx.count,
            bg: isActive ? 'blackberry' : 'blueberry.5',
            color: isActive ? 'milk' : 'blueberry.80',
          }}
        >
          {count}
        </span>
      )}
    </span>
  );
}

const sx = {
  badge: {
    display: 'flex',
    alignItems: 'center',
    height: BADGE_SIZE,
  },
  count: {
    borderRadius: BADGE_SIZE / 2,
    display: 'block',
    fontSize: 'xxs',
    fontWeight: 'button',
    height: BADGE_SIZE,
    lineHeight: `${BADGE_SIZE}px`,
    marginLeft: 'xs',
    minWidth: BADGE_SIZE,
    paddingX: 'xs',
    textAlign: 'center',
  },
};
