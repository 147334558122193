/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Grid } from 'theme-ui';

import Heading from '../../components/Heading';
import ContentLoader from '../../components/ContentLoader';
import { makeBigNumbersFriendly } from '../../utils';

export default function StatsPageHeader({
  isLoading,
  showHeading = true,
  stats,
}) {
  const authorsRead = stats.totals?.authorsRead || 0;
  const booksRead = stats.totals?.booksRead || 0;
  const languages = stats.totals?.languages || 0;
  const pagesRead = makeBigNumbersFriendly(stats.totals?.pagesRead);
  const hasLanguages = languages >= 2;
  return (
    <React.Fragment>
      {showHeading && (
        <header sx={sx.header}>
          <div>
            <Heading as="h1" sx={sx.heading}>
              All-time statistics
            </Heading>
          </div>
        </header>
      )}
      {isLoading ? (
        <Grid gap={['s', 's', 's', 'm']} columns={[2, 2, 2, 2, 4]}>
          <ContentLoader sx={sx.statLoading} />
          <ContentLoader sx={sx.statLoading} />
          <ContentLoader sx={sx.statLoading} />
          <ContentLoader sx={sx.statLoading} />
        </Grid>
      ) : (
        <Grid
          gap={hasLanguages ? ['s', 's', 's', 'm'] : ['xs', 'xs', 's', 'm']}
          columns={hasLanguages ? [2, 2, 2, 2, 4] : 3}
        >
          <div sx={sx.stat}>
            <div sx={sx.number}>{booksRead}</div>
            <div sx={sx.text}>Books</div>
          </div>
          <div sx={sx.stat}>
            <div sx={sx.number}>{authorsRead}</div>
            <div sx={sx.text}>Authors</div>
          </div>
          <div sx={sx.stat}>
            <div sx={sx.number}>{pagesRead}</div>
            <div sx={sx.text}>Pages</div>
          </div>
          {hasLanguages && (
            <div sx={sx.stat}>
              <div sx={sx.number}>{languages}</div>
              <div sx={sx.text}>Languages</div>
            </div>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}

const sx = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'column', 'row'],
    marginTop: [0, 0, 0, 'm'],
  },
  heading: {
    color: 'blueberry.100',
    fontFamily: 'serif',
    fontSize: ['l', 'l', 'l', 'l', 'xxl'],
    lineHeight: 1,
    marginBottom: ['l', 'l', 'l', 'l', 'xxl'],
  },
  subheading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', 'center', 'center', 'flex-start'],
    fontSize: ['s', 's', 's', 's', 'm'],
    marginBottom: ['m', 'm', 'm', 'l'],
  },
  stat: {
    flex: 1,
    textAlign: 'center',
    border: '1px solid',
    borderColor: ['transparent', 'transparent', 'transparent', 'blueberry.10'],
    borderRadius: 'button',
    paddingY: ['l', 'l', 'xl'],
    bg: ['blueberry.5', 'blueberry.5', 'blueberry.5', 'milk'],
  },
  statLoading: {
    height: [116, 116, 162, 162, 176.5],
    borderRadius: 'button',
  },
  number: {
    fontSize: ['l', 'l', 'xl', 'xl', 'xxl'],
    fontFamily: 'serif',
    color: 'blueberry.100',
    marginBottom: 'xxs',
  },
  text: {
    color: 'blueberry.80',
    fontSize: 'xs',
  },
};
