import React from 'react';

import Svg from '../../components/Svg';

export default function Wave(props) {
  return (
    <Svg
      fill="none"
      height="120"
      viewBox="0 0 1440 120"
      width="1440"
      {...props}
    >
      <clipPath id="wave">
        <path d="m0 0h1440v120h-1440z" />
      </clipPath>
      <g clipPath="url(#wave)">
        <ellipse cx="720" cy="-600" fill="currentColor" rx="1316" ry="720" />
      </g>
    </Svg>
  );
}
