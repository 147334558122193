import React from 'react';

import Svg from '../components/Svg';

export default function TickSmallIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m11.3635 5.65669c-.1896-.20076-.5061-.2098-.7068-.0202l-3.892 3.67577-1.42139-1.34243c-.20076-.18961-.51721-.18057-.70682.02019-.1896.20076-.18056.51722.0202.70682l1.7647 1.66666c.1927.182.49393.182.68663 0l4.23528-3.99999c.2008-.18961.2098-.50606.0202-.70682z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
