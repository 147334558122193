import React from 'react';

import Svg from '../components/Svg';

export default function CloseIcon(props) {
  return (
    <Svg {...props}>
      <path d="M9.27934 9.27934C8.90689 9.65179 8.90689 10.2557 9.27934 10.6281L12.6513 14L9.27935 17.3719C8.9069 17.7444 8.9069 18.3483 9.27935 18.7207C9.6518 19.0931 10.2557 19.0931 10.6281 18.7207L14 15.3487L17.3719 18.7207C17.7443 19.0931 18.3482 19.0931 18.7206 18.7207C19.0931 18.3482 19.0931 17.7444 18.7206 17.3719L15.3488 14L18.7206 10.6281C19.0931 10.2557 19.0931 9.65179 18.7206 9.27934C18.3482 8.9069 17.7443 8.9069 17.3719 9.27934L14 12.6512L10.6281 9.27934C10.2557 8.90689 9.65179 8.90689 9.27934 9.27934Z" />
    </Svg>
  );
}
