/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jsx } from 'theme-ui';
import range from 'lodash-es/range';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import BookReviewBlock from '../../components/BookReviewBlock';
import ContentLoader from '../../components/ContentLoader';
import GhostButton from '../../components/GhostButton';
import Heading from '../../components/Heading';
import ModalDialog from '../../components/ModalDialog';

import ReviewBookModal from '../../modals/ReviewBook';

import BookEmptyState from './BookEmptyState';
import BookPageWrap from './BookPageWrap';
import { getBookId, pluralise } from '../../utils';

import {
  getBookReviewThreadId,
  getProfileDisplayName,
  getReviewsForThread,
  getThreadById,
  getToken,
  getUsername,
  getBook,
  getBookTitle,
  getCoverUrl,
  getSelectedList,
} from '../../selectors';

import {
  fetchBookReviewSummaries,
  fetchReviewThread,
} from '../../ducks/reviews';

export default function BookReviewsWrapper() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const token = useSelector(getToken);
  const slug = match.params.slug;
  const bookId = getBookId(slug);
  const allThreadId = getBookReviewThreadId({ bookId, threadType: 'public' });
  const friendThreadId = getBookReviewThreadId({
    bookId,
    threadType: 'friends',
  });

  const threadAll = useSelector(getThreadById(allThreadId));
  const threadFriends = useSelector(getThreadById(friendThreadId));

  const fetchNeeded = threadAll === undefined && threadFriends === undefined;
  const [fetched, setFetched] = useState(!fetchNeeded);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetchNeeded && !fetched) {
      setFetched(true);
      setIsLoading(true);
      fetchBookReviewSummaries({ bookId, token, dispatch })
        .then(() => {
          setTimeout(() => setIsLoading(false), 600);
        })
        .catch(e => {
          console.error(e);
          toast('Something went wrong. Please try again!');
          // TODO: show loading error or just a toast
          // setFetched?
          setTimeout(() => setIsLoading(false), 600);
        });
    }
  }, [bookId]);

  const fetchNextPage = thread => {
    return () => {
      if (thread?.nextLink === null) {
        return;
      }
      fetchReviewThread({ thread, token, dispatch }).catch(e => {
        // TODO: let user know too
        console.error(e);
        toast('Something went wrong. Please try again!');
      });
    };
  };

  return (
    <BookReviewsPage
      bookId={bookId}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
      threadAll={threadAll}
      threadFriends={threadFriends}
    />
  );
}

export function BookReviewsPage({
  bookId,
  fetchNextPage,
  isLoading,
  threadAll,
  threadFriends,
}) {
  const [openModal, setOpenModal] = useState(false);

  const currentUser = useSelector(getUsername);
  const allReviews = useSelector(getReviewsForThread(threadAll));
  const friendReviews = useSelector(getReviewsForThread(threadFriends));
  const book = useSelector(getBook(bookId));
  const workId = book ? book.workId : '';
  const selectedList = useSelector(getSelectedList(bookId, workId));
  const isReadList = selectedList?.key === 'read';

  useEffect(() => {
    mixpanel.track('View Book Reviews', {
      bookId,
    });
  }, [bookId]);

  // isLoading here refers to the reviews, but we also can't render
  // until the book loads
  if (isLoading || book === undefined) {
    return (
      <BookPageWrap title="/ Book reviews">
        <ContentLoader sx={sx.headingLoader} />
        {range(1, 3).map(loader => (
          <BookReviewBlock key={loader} isLoading />
        ))}
      </BookPageWrap>
    );
  }

  const { imageLinks, subtitle, slug } = book;
  const bookThumbnail = getCoverUrl(imageLinks);
  const bookTitle = getBookTitle({ title: book.title, subtitle });
  const isEmpty = allReviews.length + friendReviews.length === 0;

  if (isEmpty) {
    const hasNotReviewed = book.yourReview === null && isReadList;
    const reviewProps = hasNotReviewed && {
      buttonText: 'Write a review',
      onButtonClick: () => setOpenModal(true),
    };
    return (
      <React.Fragment>
        <BookEmptyState
          heading="No reviews just yet"
          subheading="Maybe you could be the first?"
          title="/ Book reviews"
          {...reviewProps}
        />
        {hasNotReviewed && (
          <ModalDialog
            isVisible={openModal}
            onClose={() => setOpenModal(false)}
            width={520}
          >
            <ReviewBookModal onClose={() => setOpenModal(false)} />
          </ModalDialog>
        )}
      </React.Fragment>
    );
  }

  return (
    <BookPageWrap title="/ Book reviews">
      <div sx={sx.body}>
        {friendReviews.length > 0 && (
          <Heading as="h2" variant="subtitle1" sx={sx.heading}>
            {pluralise({
              count: friendReviews.length,
              word: 'review',
            })}{' '}
            from friends
          </Heading>
        )}
        {friendReviews.map(review => (
          <BookReviewBlock
            author={getProfileDisplayName({ details: review.reviewedBy })}
            authorUsername={review.reviewedBy.username}
            avatar={review.reviewedBy.image}
            bookId={bookId}
            bookThumbnail={bookThumbnail}
            bookTitle={bookTitle}
            bookSlug={slug}
            date={moment(review.addedAt).format('DD MMM YYYY')}
            hasSpoilers={review.hasSpoilers}
            id={review.id}
            key={review.id}
            ownReview={currentUser === review.reviewedBy.username}
            recommended={review.recommended}
            review={review.text}
            tags={review.tags}
          />
        ))}
        {threadFriends && threadFriends.nextLink !== null && (
          <div sx={sx.loadMore}>
            <GhostButton onClick={fetchNextPage(threadFriends)}>
              Load more reviews
            </GhostButton>
          </div>
        )}
        {allReviews.length > 0 && (
          <Heading as="h2" variant="subtitle1" sx={sx.heading}>
            Recent reviews
          </Heading>
        )}
        {allReviews.map(review => (
          <BookReviewBlock
            author={getProfileDisplayName({ details: review.reviewedBy })}
            authorUsername={review.reviewedBy.username}
            avatar={review.reviewedBy.image}
            bookId={bookId}
            bookThumbnail={bookThumbnail}
            bookTitle={bookTitle}
            bookSlug={slug}
            date={moment(review.addedAt).format('DD MMM YYYY')}
            hasSpoilers={review.hasSpoilers}
            id={review.id}
            key={review.id}
            ownReview={currentUser === review.reviewedBy.username}
            recommended={review.recommended}
            review={review.text}
            tags={review.tags}
          />
        ))}
        {threadAll && threadAll.nextLink !== null && (
          <div sx={sx.loadMore}>
            <GhostButton onClick={fetchNextPage(threadAll)}>
              Load more reviews
            </GhostButton>
          </div>
        )}
      </div>
    </BookPageWrap>
  );
}

const sx = {
  heading: {
    marginTop: 'xl',
    marginBottom: 'm',
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 68,
  },
  headingLoader: {
    width: 220,
    height: 20,
    borderRadius: 'book',
    marginBottom: 'l',
  },
  body: {
    paddingBottom: ['xl', 'xl', 'xl', 'xl', 0],
    borderBottom: ['1px solid', '1px solid', '1px solid', '1px solid', 'none'],
    borderColor: [
      'blueberry.20',
      'blueberry.20',
      'blueberry.20',
      'blueberry.20',
    ],
  },
};
