/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CountryLanguage from 'country-language';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import BookSideBlock from './BookSideBlock';
import Description from './Description';
import Detail from './Detail';
import FriendsList from './FriendsList';
import GhostButton from '../../components/GhostButton';
import Link from '../../components/Link';
import ModalDialog from '../../components/ModalDialog';
import ReportBookData from './ReportBookData';
import Section from './Section';
import SuggestEditModal from '../../modals/SuggestEdit';
import Text from '../../components/Text';
import { PlusIcon } from '../../icons';
import { getBookId } from '../../utils';

import { getBook, getIsAuthenticated, getBookLanguages } from '../../selectors';

export default function BookInfo() {
  const match = useRouteMatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const bookId = getBookId(match.params.slug);
  const book = useSelector(getBook(bookId));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const {
    descriptionMd: description,
    friends,
    genre,
    isbn10,
    isbn13,
    language,
    pageCount,
    publishedDate,
    publisher,
  } = book;

  const date = publishedDate && moment(publishedDate).format('MMMM Do YYYY');
  const countryLanguage = CountryLanguage.getLanguage(language);
  const languages = getBookLanguages(countryLanguage.nativeName);

  const details = [
    { label: 'Genre', content: genre },
    { label: 'Pages', content: pageCount },
    { label: 'Released', content: date },
    { label: 'Publisher', content: publisher },
    { label: 'Language', content: languages },
    { label: 'ISBN', content: isbn13 || isbn10 },
  ].filter(d => d.content);

  useEffect(() => {
    mixpanel.track('View Book Info', {
      bookId,
    });
  }, [bookId]);

  return (
    <React.Fragment>
      <BookDescriptionWrap
        description={description}
        isAuthenticated={isAuthenticated}
        openModal={openModal}
      />
      {!!details.length && (
        <Section title="Details">
          {details.map(
            detail =>
              detail.content && (
                <Detail
                  key={detail.label}
                  label={detail.label}
                  content={detail.content}
                />
              )
          )}
          {isAuthenticated && <ReportBookData bookId={bookId} />}
        </Section>
      )}
      <FriendsList friends={friends} isAuthenticated={isAuthenticated} />
      {isAuthenticated && (
        <ModalDialog isVisible={isModalOpen} onClose={closeModal}>
          <SuggestEditModal bookId={bookId} onClose={closeModal} />
        </ModalDialog>
      )}
    </React.Fragment>
  );
}

export function BookDescriptionWrap({
  description,
  isAuthenticated,
  openModal,
}) {
  if (description) {
    return (
      <Section title="Description">
        <Description description={description} />
      </Section>
    );
  }

  if (isAuthenticated) {
    return (
      <Section title="Description">
        <BookSideBlock
          bg="blueberry.5"
          borderColor="transparent"
          extraStyles={sx.bookSideBlock}
          onClick={openModal}
        >
          <GhostButton size="icon" sx={sx.bookSideButton}>
            <PlusIcon />
          </GhostButton>
          <Text variant="body" sx={{ textAlign: 'center', lineHeight: 'sub' }}>
            We don&apos;t know much about this book yet.
            <br /> Do you?{' '}
            <Link variant="secondary" hasUnderline hasTransition={false}>
              Suggest a description
            </Link>
            .
          </Text>
        </BookSideBlock>
      </Section>
    );
  }

  return null;
}

const sx = {
  bookSideBlock: {
    padding: 'l',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'smooth.fast',
    flexDirection: 'column',
    '&:hover': {
      bg: 'blueberry.10',
    },
  },
  bookSideButton: {
    bg: 'milk',
    marginBottom: 'xs',
  },
};
