/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jsx, useColorMode } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';

import { SIGN_OUT_ROUTE, SETTINGS_ROUTE, PRICING_ROUTE } from '../constants';

import { fetchSubscriptionDetails } from '../ducks/subscription';
import {
  FeedbackIcon,
  LogoutIcon,
  ProfileIcon,
  InvitePlusIcon,
} from '../icons/sidebar';
import { DotsIcon, FeatureHeartIcon, SettingsIcon } from '../icons';
import { useOnClickOutside } from '../hooks';
import { easing } from '../theme/transitions';
import {
  FEATURES,
  hasFeature,
  getToken,
  shouldShowUpsells,
} from '../selectors';
import { getSubscriptionDetails, getSubTier } from '../selectors/subscription';

import Avatar from './Avatar';
import SidebarLink from './SidebarLink';
import SidebarReadingGoal from './SidebarReadingGoal';

export default function SidebarUserBlock({
  avatar,
  fullName,
  hideReadingGoal,
  isReadingGoalMinimised,
  openFeedbackModal,
  openInviteModal,
  profileRoute,
  showReadingGoal,
}) {
  const dispatch = useDispatch();
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';
  const sx = getStyles({ isDarkMode });
  const containerElement = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const enableGoals = useSelector(hasFeature(FEATURES.GOALS));
  const showUpsells = useSelector(shouldShowUpsells);
  const token = useSelector(getToken);
  const theSub = useSelector(getSubscriptionDetails);
  const currentTier = getSubTier(theSub);

  useEffect(() => {
    if (typeof currentTier === 'undefined') {
      fetchSubscriptionDetails({ dispatch, token });
    }
  }, []);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  useOnClickOutside(containerElement, closePopover);

  if (!profileRoute) {
    return null;
  }

  return (
    <footer sx={sx.footer}>
      {(enableGoals || showUpsells) && (
        <SidebarReadingGoal
          enableGoals={enableGoals}
          hideReadingGoal={hideReadingGoal}
          isMinimised={isReadingGoalMinimised}
          showReadingGoal={showReadingGoal}
          showUpsells={showUpsells}
        />
      )}
      <div sx={sx.popoverWrap} ref={containerElement}>
        <AnimatePresence>
          {isPopoverOpen && (
            <motion.div sx={sx.popover} {...animationProps}>
              <div sx={sx.popoverProfile}>
                <SidebarLink icon={ProfileIcon} to={profileRoute}>
                  Profile
                </SidebarLink>
                <SidebarLink icon={SettingsIcon} to={SETTINGS_ROUTE}>
                  Settings
                </SidebarLink>
              </div>
              <div sx={sx.links}>
                <SidebarLink onClick={openInviteModal} icon={InvitePlusIcon}>
                  Invite friends
                </SidebarLink>
                <SidebarLink onClick={openFeedbackModal} icon={FeedbackIcon}>
                  Feedback
                </SidebarLink>
                {showUpsells && (
                  <SidebarLink to={PRICING_ROUTE} icon={FeatureHeartIcon}>
                    Upgrade
                  </SidebarLink>
                )}
                <SidebarLink icon={LogoutIcon} to={SIGN_OUT_ROUTE}>
                  Sign out
                </SidebarLink>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <SidebarLink to={profileRoute}>
          <div sx={sx.footerLink}>
            <Avatar size={24} src={avatar} src2x={avatar} name={fullName} />
            <p>{fullName}</p>
          </div>
        </SidebarLink>
        <div sx={sx.buttonWrap}>
          <SidebarLink
            extraStyles={sx.button}
            isActive={isPopoverOpen}
            onClick={togglePopover}
          >
            <DotsIcon width={11} />
          </SidebarLink>
        </div>
      </div>
    </footer>
  );
}

const animationProps = {
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 10,
  },
  initial: {
    opacity: 0,
    y: 10,
  },
  transition: {
    ease: easing.smooth.array,
    duration: 0.3,
  },
};

const getStyles = ({ isDarkMode }) => ({
  footer: {
    width: 'sidebar',
    position: 'fixed',
    bottom: 0,
    left: 0,

    bg: 'milk',
    paddingX: 's',
    paddingBottom: 's',
    marginLeft: -1,

    '&:before': {
      backgroundImage: isDarkMode
        ? 'linear-gradient(to top, rgba(19,19,20,1), rgba(19, 19, 20,0))'
        : 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
      display: 'block',
      position: 'absolute',
      content: '""',
      height: 20,
      top: -20,
      left: 0,
      right: 0,
    },
  },
  footerLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 'xs',
    p: {
      flex: 1,
      minWidth: 0,
      marginLeft: 'xs',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 126,
    },
  },
  popoverWrap: {
    position: 'relative',
  },
  popover: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: '100%',
    marginBottom: 'xs',
    bg: 'milk',
    borderRadius: 'button',
    boxShadow: isDarkMode
      ? '0px 0px 0px 1px rgba(193, 190, 203, 0.15), 0px 8px 17px rgba(0, 0, 0, 0.07), 0px 4px 4px -5px rgba(255, 255, 255, 0.25)'
      : '0px 0px 0px 1px rgba(19, 19, 20, 0.15), 0px 8px 17px rgba(0, 0, 0, 0.07), 0px 4px 4px -5px rgba(0, 0, 0, 0.25)',

    '&:after': {
      position: 'absolute',
      bottom: -8,
      left: 0,
      right: 0,
      height: 8,
      content: '""',
      display: 'block',
    },
  },
  popoverProfile: {
    padding: 'xs',
    borderBottom: '1px solid',
    borderColor: 'blueberry.10',
  },
  buttonWrap: {
    position: 'absolute',
    top: '50%',
    right: 5,
    marginTop: -15,
  },
  button: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  links: {
    padding: 'xs',
  },
});
