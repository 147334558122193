/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from './Text';
import Tooltip from './Tooltip';

import { GoodreadsIcon, GoogleIcon } from '../icons';

export default function Rating({
  bg,
  color = 'blueberry.50',
  rating,
  size = 28,
  source,
  withText,
}) {
  const sx = styles({ size, bg, color });
  const content = getScoreText(source);
  return (
    <div sx={sx.container}>
      <Tooltip content={withText ? undefined : content.tooltip}>
        <div sx={sx.icon}>
          <Icon size={size} source={source} />
        </div>
      </Tooltip>
      <Text sx={sx.text} variant="subtitle">
        {rating.score}
        {rating.score && rating.max_score && '/'}
        {rating.max_score}
        {withText && content.text}
      </Text>
    </div>
  );
}

function Icon({ source, size }) {
  if (source === 'gbooks') {
    return <GoogleIcon width={size} height={size} />;
  }
  return <GoodreadsIcon width={size} height={size} />;
}

const getScoreText = source => {
  if (source === 'gdreads') {
    return { tooltip: 'Goodreads rating', text: ' on Goodreads' };
  }
  if (source === 'gbooks') {
    return { tooltip: 'Google rating', text: ' on Google' };
  }
  return { tooltip: 'Rating' };
};

const styles = ({ size, bg = 'milk', color }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backfaceVisibility: 'hidden',
    transform: 'translateZ(0) scale(1.0, 1.0)',
  },
  icon: {
    width: size,
    height: size,
    borderRadius: size / 2,

    bg,
    color,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginLeft: 'xs',
  },
});
