// Friends grid
export const SWIPER_FRIENDS_GRID = 'swiper-friends';
export const SWIPER_FRIENDS_NEXT = 'swiper-friends-next';
export const SWIPER_FRIENDS_PREV = 'swiper-friends-prev';

// Recommended grid
export const SWIPER_RECOMMENDED_GRID = 'swiper-recommended';
export const SWIPER_RECOMMENDED_NEXT = 'swiper-recommended-next';
export const SWIPER_RECOMMENDED_PREV = 'swiper-recommended-prev';

// Reviews grid
export const SWIPER_REVIEWS_GRID = 'swiper-reviews';
export const SWIPER_REVIEWS_NEXT = 'swiper-reviews-next';
export const SWIPER_REVIEWS_PREV = 'swiper-reviews-prev';
