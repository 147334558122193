import React from 'react';
import moment from 'moment';
import { Box } from 'theme-ui';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useResponsiveValue } from '@theme-ui/match-media';

import {
  getBookSlug,
  getCoverUrl,
  getFeedAction,
  getFeedBook,
  getFeedBookImage,
  getFeedBookTitle,
  getFeedDate,
  getFeedItemAvatar,
  getFeedItemFirstName,
  getFeedItemLink,
  getFeedItemUsername,
  getFeedReviewText,
  getFeedReviewSpoiler,
} from '../../selectors';

import BookFeedItem from './BookFeedItem';
import InviteUsersBlock from '../../components/InviteUsersBlock';
import { BOOK_ROUTE, getProfileRoute } from '../../constants';
import theme from '../../theme';

export default function Feed({ items }) {
  const gutter = useResponsiveValue([
    theme.space.s,
    theme.space.s,
    theme.space.m,
  ]);
  return (
    <Box paddingBottom={['s', 's', 'm']}>
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          [parseInt(theme.breakpoints[0]) - 1]: 1,
          [parseInt(theme.breakpoints[1]) - 1]: 1,
          [parseInt(theme.breakpoints[2]) - 1]: 1,
          [parseInt(theme.breakpoints[3]) - 1]: 2,
          [parseInt(theme.breakpoints[4]) - 1]: 3,
        }}
      >
        <Masonry gutter={`${gutter}px`}>
          <InviteUsersBlock />
          {items.map((item, index) => {
            const username = getFeedItemUsername(item);
            const firstName = getFeedItemFirstName(item);
            const avatar = getFeedItemAvatar(item);
            const date = getFeedDate(item);
            const when = moment(date).fromNow();
            const action = getFeedAction(item);
            const imageLinks = getFeedBookImage(item);
            const cover = getCoverUrl(imageLinks);
            const title = getFeedBookTitle(item);
            const book = getFeedBook(item);
            const slug = getBookSlug(book);
            const link = getFeedItemLink(item);
            const reviewText = getFeedReviewText(item);
            const reviewSpoiler = getFeedReviewSpoiler(item);
            const profileRoute = getProfileRoute(username);
            return (
              <BookFeedItem
                action={action}
                avatar={avatar}
                bookRoute={`${BOOK_ROUTE}/${slug}`}
                containsSpoiler={reviewSpoiler}
                cover={cover}
                date={when}
                firstName={firstName}
                key={index}
                link={link}
                listKey={item.list.key}
                profileRoute={profileRoute}
                review={reviewText}
                title={title}
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Box>
  );
}
