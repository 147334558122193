/*  eslint-disable */
import { batch } from 'react-redux';

import { API_EXPLORE_URL } from '../constants';
import { callApi } from '../utils';
import { setItems } from './threads';
import {
  exploreRecentColsThreadId,
  exploreFeaturedColsThreadId,
  exploreRecsThreadId,
  exploreReviewsThreadId,
} from '../selectors/explore';

export function fetchExplore({ dispatch, token }) {
  return callApi(API_EXPLORE_URL, { token }).then(data => {
    const {
      recommended,
      reviews,
      recentCollections,
      featuredCollections,
    } = data;
    batch(() => {
      dispatch(setItems(exploreRecsThreadId, recommended?.results));
      dispatch(
        setItems(exploreFeaturedColsThreadId, featuredCollections?.results)
      );
      dispatch(setItems(exploreReviewsThreadId, reviews?.results));
      dispatch(setItems(exploreRecentColsThreadId, recentCollections?.results));
    });
  });
}
