import React from 'react';

import Svg from '../../components/Svg';

export default function HomeIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m13.1087 7.33564c.2438-.21596.5614-.33563.8909-.33564.3294-.00001.6471.11964.8909.33558l5.676 5.02782c.1366.121.2456.2684.3203.4328.0746.1644.1132.3422.1132.522v6.9445c0 .4072-.3388.7373-.7568.7373-.0541 0-.1069-.0055-.1578-.0161-.0508.0105-.1035.016-.1575.016h-11.85584c-.054 0-.10669-.0055-.15749-.016-.0509.0106-.1037.0161-.15781.0161-.41795 0-.75676-.3301-.75676-.7373v-6.9445c0-.1798.03855-.3576.11319-.522.07463-.1644.1837-.3118.32021-.4327zm6.3778 6.06416v6.1255h-3.0275v-3.6873c0-.4072-.3388-.7373-.7567-.7373h-3.4054c-.418 0-.7568.3301-.7568.7373v3.6873h-3.02659v-6.1256l5.48609-4.86022zm-6.4329 6.1255h1.8919v-2.9499h-1.8919z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
