export const checkIfStandalone = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }
  return false;
};

export const checkIfiOS = () => {
  return navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const checkIfAndroid = () => {
  return (
    navigator.platform &&
    /Android/.test(navigator.userAgent) &&
    /Chrome/.test(navigator.userAgent)
  );
};
