/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Button as ThemeButton } from 'theme-ui';

import Loader from './Loader';
import { hasTouch } from '../utils';

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    disabled,
    isLoading,
    size = 'm',
    sx,
    transformOnHover = true,
    ...rest
  } = props;

  const buttonStyles = {
    cursor: 'pointer',
    fontFamily: 'body',
    fontWeight: 'button',

    outline: 'none',
    transition: 'smooth.fast',

    paddingY: 0,
    pointerEvents: isLoading || disabled ? 'none' : 'all',
    position: isLoading ? 'relative' : 'unset',
    willChange: 'transform',

    ...sizes[size],

    '&:hover': !hasTouch() &&
      transformOnHover && {
        transform: 'translate3d(0, -1px, 0)',
      },

    '&:active': transformOnHover && {
      transform: 'translate3d(0, 0, 0)',
    },

    ...sx,
  };

  const buttonInnerStyles = {
    display: 'block',
    opacity: isLoading ? 0 : 1,
  };

  const loaderStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <ThemeButton
      {...rest}
      disabled={disabled || isLoading}
      ref={ref}
      sx={buttonStyles}
    >
      <span sx={buttonInnerStyles}>{children}</span>
      {isLoading && (
        <span sx={loaderStyles}>
          <Loader color="currentColor" size={24} />
        </span>
      )}
    </ThemeButton>
  );
});

const sizes = {
  s: {
    paddingX: 'm',
    height: '34px',
    fontSize: 'xs',
    lineHeight: '34px',
    borderRadius: 'button',
  },
  m: {
    paddingX: 'm',
    height: '40px',
    fontSize: 'xs',
    lineHeight: '40px',
    borderRadius: 'button',
  },
  l: {
    paddingX: 'm',
    height: ['46px', '46px', '46px', '54px'],
    fontSize: ['xs', 'xs', 'xs', 's'],
    lineHeight: ['46px', '46px', '46px', '54px'],
    borderRadius: 'button',
  },
  icon: {
    paddingX: 0,
    width: '32px',
    height: '32px',
    borderRadius: 'circle',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconBig: {
    paddingX: 0,
    width: '46px',
    height: '46px',
    borderRadius: 'circle',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inline: {
    paddingX: 0,
    width: '20px',
    height: '20px',
    borderRadius: 'toast',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default Button;
