/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function Svg({
  children,
  color,
  display = 'block',
  fill = 'currentColor',
  height = 28,
  stroke,
  transform,
  viewBox = '0 0 28 28',
  width = 28,
}) {
  return (
    <svg
      height={height}
      sx={{ color, display, fill, stroke, transform }}
      viewBox={viewBox}
      width={width}
    >
      {children}
    </svg>
  );
}
