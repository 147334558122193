/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Text from '../../components/Text';
import Tweet from './Tweet';

export default function GoodreadsBlock() {
  return (
    <div sx={sx.wrapper}>
      <div sx={sx.container}>
        <div sx={sx.long}>
          <Heading as="h2" fontFamily="serif" variant="marketing">
            You&apos;re in good company
          </Heading>
          <Text as="p" color="blueberry.80" variant="body">
            53% of our members have imported their historical data from
            Goodreads. It&apos;s time for a change. Don&apos;t loathe your
            reading list –– liberate it.
          </Text>
        </div>
        <div sx={sx.short}>
          <div sx={sx.upperTweet}>
            <Tweet
              author="fem"
              avatar="https://pbs.twimg.com/profile_images/1385605859494055936/Nc6SwLtQ_400x400.jpg"
              date="2:48 PM · Mar 23, 2021"
              handle="femke"
              url="https://twitter.com/femkesvs/status/1374372383360884748"
              tweet={
                <React.Fragment>
                  i still think @okuclub was my favorite new app of 2020. Such a
                  refreshing experience from goodreads. <br />
                  <br />
                  follow me here: https://oku.club/user/femke
                </React.Fragment>
              }
            />
          </div>
          <div sx={sx.lowerTweet}>
            <Tweet
              author="Joe Russell"
              avatar="https://pbs.twimg.com/profile_images/1485210358700810241/t3dblWBa_400x400.png"
              avatarBg="transparent"
              date="1:29 PM · Feb 21, 2021"
              handle="Joebob"
              url="https://twitter.com/Joebob/status/1363481076300214277"
              tweet={
                <React.Fragment>
                  Readng app is really lovely and simple. It works for people
                  who have barely read in years as well as those who consume
                  more pages than food.
                </React.Fragment>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const sx = {
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    maxWidth: 912,
    paddingX: 'l',
    marginX: 'auto',
    color: 'blueberry.100',

    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: ['center', 'center', 'left', 'left', 'left'],

    marginBottom: [80, 80, 120, 120, 120],

    p: {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 'm',
    },
  },
  upperTweet: {
    position: 'relative',
    width: ['100%', '100%', '100%', '100%', '110%'],
  },
  lowerTweet: {
    position: 'relative',
    marginTop: 'm',
    right: [0, 0, 0, 0, '-50%'],
    width: ['100%', '100%', '100%', '100%', '110%'],
  },
  short: {
    width: ['100%', '100%', '100%', '45%', '45%'],
    marginTop: ['xl', 'xl', 0, 0, 0],
    paddingY: '2px',
  },
  long: {
    width: ['100%', '100%', '100%', '55%', '55%'],
    paddingRight: 'm',
  },
};
