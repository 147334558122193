/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import React from 'react';

import Button from './Button';

const PlanButton = React.forwardRef(({ children, ...rest }, ref) => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <Button variant={isDark ? 'ghost.dark' : 'primary'} ref={ref} {...rest}>
      {children}
    </Button>
  );
});

export default PlanButton;
