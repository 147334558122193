export const listsById = lists => {
  const object = {};
  if (lists) {
    lists.forEach(list => {
      if (list.key) {
        object[list.key] = list;
      } else {
        object[list.id] = list;
      }
    });
  }
  return object;
};

export const listsWithoutKey = lists => {
  const array = [];
  if (lists) {
    lists.forEach(list => {
      if (!list.key) {
        array.push(list);
      }
    });
  }
  return array;
};
