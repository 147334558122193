import React, { useState, useContext, createContext } from 'react';

const AppContext = createContext({
  isVisible: false,
  setVisible: () => undefined,
});

export function useAppContext() {
  return useContext(AppContext);
}

export default function ContextProvider({ children }) {
  const [visible, setVisible] = useState(false);
  return (
    <AppContext.Provider value={{ visible, setVisible }}>
      {children}
    </AppContext.Provider>
  );
}
