/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch, Link } from 'react-router-dom';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import FullHeight from '../components/FullHeight';
import Heading from '../components/Heading';
import Image from '../components/Image';
import Loader from '../components/Loader';
import Page from '../components/Page';
import PrimaryButton from '../components/PrimaryButton';
import Text from '../components/Text';

import { useInterval } from '../hooks';
import { getProfileRoute } from '../constants';
import { getSubscriptionDetails } from '../selectors/subscription';
import {
  pollCheckoutSession,
  reconcileAppleSubscription,
} from '../ducks/subscription';
import { getToken, getUsername } from '../selectors';
import { girlReading, girlReading2x } from '../assets';
import { messageNative } from '../utils';

// Please note: this handles checkout both via paddle and apple
export default function CheckoutProgress() {
  const dispatch = useDispatch();
  const location = useLocation();
  const route = useRouteMatch();
  const username = useSelector(getUsername);
  const { sessionKey } = route.params;

  const sub = useSelector(getSubscriptionDetails);
  const token = useSelector(getToken);
  const hasSub = sub && Object.keys(sub).length !== 0;

  const checkoutId = location.state?.checkoutId;

  // we could use the isNativeIos util here but checking the url
  // makes it easier to debug during development...
  const isNative = location.pathname.indexOf('/ios/') !== -1;

  useEffect(() => {
    if (isNative) {
      // we should be using apple's payment system here instead of paddle
      reconcileAppleSubscription({
        dispatch,
        token,
        transactionId: sessionKey,
      }).then(({ subscription }) => {
        messageNative({ finishTransaction: sessionKey });
        mixpanel.track('Checkout: Payment Success', {
          tier: subscription?.tier,
          interval: subscription?.interval,
          native: true,
        });
      });
    }
  }, []);

  useInterval(() => {
    if (isNative) {
      return;
    }

    if (!hasSub) {
      pollCheckoutSession({
        token,
        sessionKey,
        checkoutId,
        username,
        dispatch,
      });
    }
  }, 2500);

  if (!hasSub) {
    return (
      <Page hasSidebar={false} title="Checkout progress">
        <FullHeight>
          <div sx={sx.container}>
            <div sx={sx.loader}>
              <Loader />
            </div>
            <Heading as="h1" fontSize="l" fontFamily="serif" sx={sx.greeting}>
              Upgrading your account...
            </Heading>
            <Text sx={sx.body} variant="body">
              This should only hurt a bit
            </Text>
          </div>
        </FullHeight>
      </Page>
    );
  }

  return (
    <Page hasSidebar={false} title="Checkout progress">
      <FullHeight>
        <div sx={sx.container}>
          <Image
            alt="Man floating with books around him"
            blendIn
            maxWidth={280}
            width="90vw"
            respondToDarkMode
            src={girlReading}
            src2x={girlReading2x}
            sx={sx.image}
          />
          <Heading as="h1" fontSize="l" fontFamily="serif" sx={sx.greeting}>
            Membership upgraded
          </Heading>
          <Text sx={sx.body} variant="body">
            Your account now has access to all of our premium features.
            <br />
            Thanks for upgrading! Premium members like you are what keeps Oku
            alive.
          </Text>
          {username && (
            <PrimaryButton
              as={Link}
              sx={sx.button}
              to={getProfileRoute(username)}
            >
              Go to your profile
            </PrimaryButton>
          )}
        </div>
      </FullHeight>
    </Page>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    flex: 1,
  },
  loader: {
    marginBottom: 's',
  },
  greeting: {
    color: 'blueberry.100',
  },
  body: {
    marginTop: 'xxs',
    textAlign: 'center',
    paddingLeft: 'm',
    paddingRight: 'm',
  },
  button: {
    marginTop: 'm',
  },
};
