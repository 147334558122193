/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';

import { inviteSidebar, inviteFeed } from '../../../assets';
import BlogLink from '../BlogLink';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Earn rewards for inviting your friends to Oku / Blog">
      <time>Nov 30, 2021</time>
      <h1>Earn rewards for inviting your friends to Oku</h1>

      <p>
        Oku has a referral reward program! Simply get <b>3 friends</b> to sign
        up with your unique invite link and we’ll award your account with a free
        month of{' '}
        <BlogLink href="https://oku.club/pricing">Oku Premium</BlogLink>!
        This’ll give you access to all of our premium features, including
        reading goals & stats.
      </p>
      <br />
      <p>
        You can find your unique invite code in the sidebar menu on a computer,
        and also at the top of the feed on all other devices.
      </p>
      <br />

      <div sx={{ display: 'flex', justifyContent: 'center' }}>
        <Image
          src={inviteSidebar}
          src2x={inviteSidebar}
          alt="How to install Oku on an iOS device"
        />
        <Image
          src={inviteFeed}
          src2x={inviteFeed}
          alt="How to install Oku on an iOS device"
        />
      </div>
    </BlogPageWrap>
  );
}
