/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../../components/PrimaryButton';
import FormError from '../../components/FormError';
import InputWithLabel from '../../components/InputWithLabel';
import Link from '../../components/Link';

import {
  SEND_FORGOT_PASSWORD_FORM,
  SET_FORGOT_PASSWORD_FORM,
  updateFormState,
} from '../../ducks/forms';
import {
  shouldSendResetPasswordEmail,
  shouldSetForgotPassword,
} from '../../ducks/auth';
import { SIGN_IN_ROUTE } from '../../constants';
import { getAuthFormLoading, getFormState } from '../../selectors';
import { updateFieldState } from '../../utils/forms';

export default function ResetPassword() {
  const sx = getStyles();

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const location = useLocation();

  const isLoading = useSelector(getAuthFormLoading);
  const formState = useSelector(getFormState(SEND_FORGOT_PASSWORD_FORM));

  const onSubmit = event => {
    event.preventDefault();
    shouldSendResetPasswordEmail({
      dispatch,
      email,
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <FormError>{formState.errors._global}</FormError>

      <InputWithLabel
        id="email"
        error={formState.errors.email}
        onChange={updateFieldState(setEmail)}
        label="Email address"
        placeholder="your.email@address.com"
        sx={sx.input}
        type="email"
        required
      />
      <div sx={sx.controls}>
        <PrimaryButton type="submit" isLoading={isLoading}>
          Reset my password
        </PrimaryButton>
        <Link
          as={RouterLink}
          hasUnderline
          sx={sx.link}
          to={{ pathname: SIGN_IN_ROUTE, search: location.search }}
          variant="body"
        >
          Or sign in
        </Link>
      </div>
    </form>
  );
}

export function SetForgotPasswordPage() {
  const sx = getStyles();
  const dispatch = useDispatch();

  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const isLoading = useSelector(getAuthFormLoading);
  const formState = useSelector(getFormState(SET_FORGOT_PASSWORD_FORM));

  const onSubmit = event => {
    event.preventDefault();

    if (confirmation !== password) {
      dispatch(
        updateFormState(
          SET_FORGOT_PASSWORD_FORM,
          'Your passwords need to match',
          'confirmation'
        )
      );
      return;
    }

    shouldSetForgotPassword({ dispatch, token, password });
  };

  return (
    <form onSubmit={onSubmit}>
      <FormError>{formState.errors._global}</FormError>

      <InputWithLabel
        id="password"
        error={formState.errors.password}
        onChange={updateFieldState(setPassword)}
        label="Password"
        placeholder="Enter your new password"
        sx={sx.input}
        type="password"
        required
      />
      <InputWithLabel
        id="confirmation"
        error={formState.errors.confirmation}
        onChange={updateFieldState(setConfirmation)}
        label="Confirm password"
        placeholder="Confirm your new password"
        sx={sx.input}
        type="password"
        required
      />
      <div sx={sx.controls}>
        <PrimaryButton type="submit" isLoading={isLoading}>
          Set your password
        </PrimaryButton>
      </div>
    </form>
  );
}

const getStyles = () => ({
  body: {
    width: '100%',
    maxWidth: 'page.mobile',
  },
  input: {
    marginBottom: 'm',
  },
  relative: {
    position: 'relative',
  },
  resetPassword: {
    textAlign: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  controls: {
    marginTop: 'l',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: 'xs',
    lineHeight: 'heading',
  },
});
