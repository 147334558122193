/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import range from 'lodash-es/range';

import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';
import ContentLoader from '../../components/ContentLoader';
import Highlight from '../Highlights/Highlight';
import ProfileBookHighlightsHeader from './ProfileBookHighlightsHeader';

import {
  getIsAuthenticated,
  getToken,
  getProfile,
  getUserHighlights,
  getHighlightsFromThread,
} from '../../selectors';

import { getProfileHighlightsRoute } from '../../constants';

import { pluralise } from '../../utils';
import { fetchHighlightsForUser } from '../../ducks/highlights';
import { shouldFetchProfile } from '../../ducks/profile';
import theme from '../../theme';

export default function ProfileBookHighlights() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { username, slug } = match.params;
  const user = useSelector(getProfile(username));
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = !!useSelector(getIsAuthenticated);

  const token = useSelector(getToken);
  const books = useSelector(getUserHighlights(username));
  const book = books?.items.find(item => item.book.slug === slug);
  const highlights = useSelector(
    getHighlightsFromThread(book?.highlightsThread)
  );

  useEffect(() => {
    if (username) {
      fetchHighlightsForUser({ dispatch, token, username }).then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 420);
      });
      if (!user) {
        shouldFetchProfile({
          dispatch,
          token,
          username,
          rejectOnFailure: true,
        }).catch(() => {});
      }
    }
  }, [username, token]);

  useEffect(() => {
    if (!isLoading && highlights === undefined) {
      // user has no highlights for this book
      dispatch(push(getProfileHighlightsRoute(username)));
    }
  }, [isLoading, highlights]);

  if (isLoading || highlights === undefined) {
    return (
      <Page title="Profile highlights">
        {isAuthenticated && <PageNavigation showBackButton showShareButton />}
        <PageBody>
          <div sx={sx.container}>
            <ProfileBookHighlightsHeader isLoading />
            <Grid>
              {range(4).map((item, index) => (
                <ContentLoader
                  key={item}
                  sx={{
                    ...sx.loader,
                    height: (200 * (index + 1)) / 2,
                  }}
                />
              ))}
            </Grid>
          </div>
        </PageBody>
      </Page>
    );
  }

  return (
    <Page title="Profile highlights">
      {isAuthenticated && <PageNavigation showBackButton showShareButton />}
      <PageBody>
        <div sx={sx.container}>
          <ProfileBookHighlightsHeader
            book={book?.book}
            preText={pluralise({
              count: highlights?.length,
              word: 'highlight',
            })}
            username={username}
          />
          <Grid>
            {highlights?.map(item => (
              <Highlight
                // avatar={avatar}
                canRemove={item.is_saved}
                canSave={!item.is_saved}
                countSaves={item.count_saves}
                id={item.id}
                bookSlug={slug}
                key={item.id}
                position={item.position}
                text={item.text}
              />
            ))}
          </Grid>
        </div>
      </PageBody>
    </Page>
  );
}

function Grid({ children }) {
  const gutter = useResponsiveValue([
    theme.space.s,
    theme.space.s,
    theme.space.m,
  ]);
  const columnes = {
    [parseInt(theme.breakpoints[0]) - 1]: 1,
    [parseInt(theme.breakpoints[1]) - 1]: 1,
    [parseInt(theme.breakpoints[2]) - 1]: 1,
    [parseInt(theme.breakpoints[3]) - 1]: 2,
  };
  return (
    <ResponsiveMasonry columnsCountBreakPoints={columnes}>
      <Masonry gutter={`${gutter}px`}>{children}</Masonry>
    </ResponsiveMasonry>
  );
}

const sx = {
  container: {
    paddingTop: [0, 0, 'm'],
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
  loader: {
    width: '100%',
    borderRadius: 'button',
  },
};
