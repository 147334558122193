import moment from 'moment';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkEmailValidity = emailToCheck => {
  return emailRegex.test(emailToCheck);
};

export const onReload = () => {
  window.plausible('get-new-version');
  window.location.reload();
};

export const intlFormat = num => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export const makeBigNumbersFriendly = num => {
  if (!num) return 0;
  if (num >= 1000000) return `${intlFormat(num / 1000000)}M`;
  if (num >= 1000) return `${intlFormat(num / 1000)}k`;
  return intlFormat(num);
};

export const truncateText = (input, length = 300) => {
  if (!input) return null;
  if (input.length > length) {
    return `${input.substring(0, length)}...`;
  }
  return input;
};

export const formatDate = date => {
  return moment(date).format('DD MMM YYYY');
};

export const formatCurrency = (amount, currency) => {
  const formatted = new Intl.NumberFormat('gb', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
  }).format(amount);
  if (currency === 'USD' && formatted.substring(0, 2) === 'US') {
    // turn US$140 into $140
    // chrome return US$140 - safari just returns $140
    return formatted.substring(2);
  }
  return formatted;
};

export const urlEndsWith = (baseUrl, url) => {
  return baseUrl.toLowerCase().endsWith(url?.toLowerCase());
};

export const getRandomClassName = () => {
  // prefix with letter `a` since a class can't start with a numbre
  return `a${Math.random().toString(36).substring(2, 15)}`;
};
