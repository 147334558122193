/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState } from 'react';

import ModalDialog from '../components/ModalDialog';
import NewHighlightChooseBookModal from './NewHighlightChooseBook';
import NewHighlightModal from './NewHighlight';

export default function CreateHighlight({ book, showModal, closeModal }) {
  const [selectedBook, setSelectedBook] = useState(null);
  const onSelectBook = b => setSelectedBook(b);
  const onGoBack = selectedBook && (() => setSelectedBook(null));

  useEffect(() => {
    setSelectedBook(book);
  }, [book]);

  return (
    <ModalDialog onGoBack={onGoBack} isVisible={showModal} onClose={closeModal}>
      {selectedBook ? (
        <NewHighlightModal
          book={selectedBook}
          onClose={closeModal}
          onGoBack={onGoBack}
        />
      ) : (
        <NewHighlightChooseBookModal onSelectBook={onSelectBook} />
      )}
    </ModalDialog>
  );
}
