/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../../components/PrimaryButton';
import FormError from '../../components/FormError';
import InputWithLabel from '../../components/InputWithLabel';
import Link from '../../components/Link';
import Text from '../../components/Text';

import { shouldSignUp } from '../../ducks/auth';
import { PRIVACY_ROUTE, SIGN_IN_ROUTE, TERMS_ROUTE } from '../../constants';
import { getAuthFormLoading, getFormState } from '../../selectors';

export default function SignUp() {
  const sx = getStyles();

  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;

  const urlParams = new URLSearchParams(search);
  const inviteCode = urlParams.get('inviteCode');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const isLoading = useSelector(getAuthFormLoading);
  const formState = useSelector(getFormState('signup'));
  const updateFieldState = updateFunc => {
    return event => updateFunc(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    shouldSignUp({ dispatch, email, username, password, inviteCode });
  };
  return (
    <form onSubmit={onSubmit}>
      <FormError>{formState.errors._global}</FormError>
      <InputWithLabel
        id="email"
        error={formState.errors.email}
        onChange={updateFieldState(setEmail)}
        label="Email address"
        placeholder="your.email@address.com"
        sx={sx.input}
        type="email"
        inputMode="email"
        required
      />
      <InputWithLabel
        id="username"
        error={formState.errors.username}
        onChange={updateFieldState(setUsername)}
        label="Username"
        placeholder="johndoe"
        sx={sx.input}
        type="username"
        required
      />
      <InputWithLabel
        id="password"
        error={formState.errors.password}
        onChange={updateFieldState(setPassword)}
        label="Password (8+ chars)"
        placeholder="Choose your password"
        sx={sx.input}
        type="password"
        required
      />
      <Text variant="marketingSub" sx={sx.agreementsText}>
        By signing up you agree to our{' '}
        <Link
          as={RouterLink}
          to={{ pathname: PRIVACY_ROUTE, search: location.search }}
          target="blank"
          hasUnderline
        >
          Privacy Policy
        </Link>{' '}
        and
        <Link
          as={RouterLink}
          to={{ pathname: TERMS_ROUTE, search: location.search }}
          target="blank"
          hasUnderline
        >
          Terms of Service
        </Link>
        .
      </Text>
      <div sx={sx.controls}>
        <PrimaryButton type="submit" isLoading={isLoading}>
          Create a free account
        </PrimaryButton>
        <Link
          as={RouterLink}
          hasUnderline
          sx={sx.link}
          to={`${SIGN_IN_ROUTE}${location.search}`}
          variant="body"
        >
          Or sign in
        </Link>
      </div>
    </form>
  );
}

const getStyles = () => ({
  body: {
    width: '100%',
    maxWidth: 'page.mobile',
  },
  input: {
    marginBottom: 'm',
  },
  relative: {
    position: 'relative',
  },
  resetPassword: {
    textAlign: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  controls: {
    marginTop: 'l',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: 'xs',
    lineHeight: 'heading',
  },
  agreementsText: {
    marginTop: 'm',
    lineHeight: 1.5,
    a: {
      color: 'blueberry.80',
      hover: {
        color: 'blueberry.100',
      },
    },
  },
});
