/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import Text from './Text';
import Heading from './Heading';

export default function InfoBlock({ heading, icon, paragraph }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const Icon = icon;

  const sx = {
    section: {
      paddingTop: 38,
      paddingBottom: 'm',
      paddingX: 'm',
      boxShadow: isDark ? 'blog.sectionDark' : 'blog.section',
      borderRadius: 'button',
      textAlign: 'center',
      position: 'relative',
      h3: {
        fontSize: 'm',
        marginBottom: 'xs',
      },
      p: {
        fontSize: 'xs',
        lineHeight: 'sub',
      },
    },
    icon: {
      position: 'absolute',
      top: -20,
      left: '50%',
      color: 'blueberry.100',
      transform: 'translateX(-50%)',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      width: 40,
      height: 40,
      bg: 'milk',
      borderRadius: 'circle',
      boxShadow: isDark ? 'blog.sectionDark' : 'blog.section',
    },
  };

  return (
    <section sx={sx.section}>
      {icon && (
        <span sx={sx.icon}>
          <Icon />
        </span>
      )}
      {heading && (
        <Heading as="h3" variant="subtitle1">
          {heading}
        </Heading>
      )}
      {paragraph && (
        <Text as="p" variant="body">
          {paragraph}
        </Text>
      )}
    </section>
  );
}
