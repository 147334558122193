import React from 'react';

import Svg from '../components/Svg';

export default function MegaphoneIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m2.98038 2.01526c-.33032-.04391-.66632.00741-.96847.14794-.30215.14052-.55789.3644-.73715.6453-.17926.28091-.27459.60755-.27476.94078v19.45852c.00017.3332.0955.6595.27476.9404s.435.5047.73715.6453c.30215.1405.63815.1918.96847.1479.3299-.0439.64043-.1809.8952-.395 3.17555-2.6633 6.41112-3.9934 8.84962-4.6578 1.2192-.3321 2.2372-.4973 2.9453-.5794.1188-.0138.2288-.0252.3295-.0347v4.6688c0 .2881.0711.5718.2071.8258.1359.254.3325.4705.5722.6303l1.374.916c.2333.1556.5006.2528.7794.2835.2787.0306.5608-.0062.8224-.1073.2615-.1011.495-.2636.6806-.4738s.318-.462.3861-.734l1.5215-6.0861c1.307-.1356 2.535-.7159 3.4726-1.6535 1.0783-1.0783 1.6841-2.5409 1.6841-4.0659s-.6058-2.9875-1.6841-4.06583c-1.0784-1.07834-2.5409-1.68414-4.0659-1.68414h-4.9991-.0011l-.0099-.0001c-.0098-.00013-.026-.0004-.0484-.00099-.0447-.00116-.1141-.00357-.2064-.00855-.1846-.00996-.4606-.03017-.8146-.0712-.7081-.0821-1.7261-.24729-2.9453-.57946-2.4384-.66435-5.67373-1.9943-8.84913-4.65738-.25485-.21434-.56558-.35151-.89569-.39539zm17.80912 17.21304h-3.2895v4.715c0 .0412.0102.0817.0296.118s.0475.0672.0817.09l1.374.9161c.0334.0222.0716.0361.1114.0404.0398.0044.0801-.0008.1175-.0153.0373-.0144.0707-.0376.0972-.0677.0265-.03.0454-.066.0552-.1048zm-3.2895-1.5h4.25c1.1272 0 2.2082-.4477 3.0052-1.2448.797-.797 1.2448-1.878 1.2448-3.0052 0-1.1271-.4478-2.2081-1.2448-3.0052-.797-.797-1.878-1.24477-3.0052-1.24477h-4.25zm-1.5-8.54015c-.1478-.01256-.3157-.02903-.5022-.05064-.7728-.08958-1.8657-.26773-3.1669-.62224-2.60221-.70897-6.04488-2.12565-9.41924-4.95575l-.001-.00083c-.03639-.03064-.08078-.05024-.12794-.05651-.04715-.00627-.09512.00106-.13826.02112-.04313.02006-.07964.05202-.10524.09212-.02556.04007-.03917.08659-.03922.13411v19.45757c.00005.0476.01366.0941.03922.1341.0256.0401.06211.0721.10524.0922.04314.02.09111.0274.13826.0211.04716-.0063.09155-.0259.12794-.0565l.001-.0009c3.37436-2.8301 6.81703-4.2467 9.41924-4.9557 1.3012-.3545 2.3941-.5327 3.1669-.6222.1865-.0217.3544-.0381.5022-.0507z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
