import React from 'react';

import Svg from '../../components/Svg';

export default function LogoutIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m13.4375 8.5625h-4.875v10.875h4.875c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-5.0625c-.3481 0-.68194-.1383-.92808-.3844-.24614-.2462-.38442-.58-.38442-.9281v-11.25c0-.3481.13828-.68194.38442-.92808s.57998-.38442.92808-.38442h5.0625c.4142 0 .75.33579.75.75s-.3358.75-.75.75zm3.2686 1.954c.2929-.2928.7677-.2928 1.0606 0l2.9531 2.9532c.2929.2929.2929.7677 0 1.0606l-2.9531 2.9532c-.2929.2928-.7677.2928-1.0606 0-.2929-.2929-.2929-.7678 0-1.0607l1.6728-1.6728h-6.0664c-.4142 0-.75-.3358-.75-.75s.3358-.75.75-.75h6.0664l-1.6728-1.6728c-.2929-.2929-.2929-.7678 0-1.0607z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
