/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';

import GhostButton from '../components/GhostButton';
import InputWithLabel from '../components/InputWithLabel';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import ModalHeadingText from '../components/ModalHeadingText';
import PrimaryButton from '../components/PrimaryButton';
import TextareaWithLabel from '../components/TextareaWithLabel';
import DropzoneFile from '../components/DropzoneFile';

import { suggestBookDetails } from '../ducks/books';
import {
  getBook,
  getBookAuthorNames,
  getBookSubTitle,
  getBookTitle,
  getCoverUrl,
  getToken,
} from '../selectors';
import TagInput from '../components/TagInput';
import { fetchAuthorSearch } from '../ducks/search';
import SuggestionThanksModal from './SuggestionThanks';

export function SuggestEditForm({
  title = '',
  subtitle = '',
  description = '',
  authors = [],
  imageLinks,
  onDataUpdate,
}) {
  const token = useSelector(getToken);

  const bookTitle = getBookTitle({ title, subtitle });
  const bookSubtitle = getBookSubTitle({ title, subtitle });
  const originalThumbnail = getCoverUrl(imageLinks);

  const [coverDataUrl, setCoverDataUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const thumbnail = coverDataUrl || originalThumbnail;

  const [fieldData, setFieldData] = useState({
    title: bookTitle,
    subtitle: bookSubtitle,
    authors,
    description,
  });

  useEffect(() => {
    if (onDataUpdate) {
      onDataUpdate(fieldData);
    }
  }, [fieldData]);

  useEffect(() => {
    const newData = { ...fieldData, cover: imageFile };
    if (!imageFile) {
      delete newData.cover;
    }
    setFieldData(newData);
  }, [imageFile]);

  const onFileDrop = file => {
    // avoid setting fieldData directly here
    // because the value here can be stale
    setImageFile(file);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        setCoverDataUrl(fileReader.result);
        resolve(true);
      });
      try {
        fileReader.readAsDataURL(file);
      } catch (e) {
        console.error('Could not generate data url', e, file);
        reject(e);
      }
    });
  };

  const fetchAuthorSuggestions = query => {
    return fetchAuthorSearch({ token, query }).then(({ matches }) => {
      return matches.map(({ id, name }) => ({ id, text: name }));
    });
  };
  const onAuthorsChanged = ({ tags }) => {
    setFieldData({ ...fieldData, authors: tags.map(t => t.id) });
  };

  return (
    <React.Fragment>
      <DropzoneFile
        bookProps={{
          title: bookTitle,
          // title: '',
          thumbnail,
          thumbnail2x: thumbnail,
        }}
        onFileDrop={onFileDrop}
        helperText="Drag and drop an image or select from your files, should be about 500px wide and under 1mb."
      />
      <InputWithLabel
        id="book-title"
        label="Title"
        name="MERGE0"
        placeholder="Enter the book title"
        type="text"
        value={fieldData.title}
        onChange={event => {
          event.preventDefault();
          setFieldData({ ...fieldData, title: event.target.value });
        }}
      />
      <InputWithLabel
        id="book-subtitle"
        label="Subtitle"
        name="MERGE0"
        placeholder="Enter the book subtitle"
        type="text"
        value={fieldData.subtitle}
        onChange={event => {
          event.preventDefault();
          setFieldData({ ...fieldData, subtitle: event.target.value });
        }}
      />
      <TagInput
        fetchSuggestions={fetchAuthorSuggestions}
        id="book-authors"
        initialTags={authors.map(a => ({ id: a.id, text: a.name }))}
        label="Authors"
        onTagsChange={onAuthorsChanged}
        placeholder="Start typing to add an author"
      />
      <TextareaWithLabel
        id="book-description"
        label="Description"
        name="MERGE0"
        placeholder="Enter the book description"
        type="text"
        value={fieldData.description}
        onChange={event => {
          event.preventDefault();
          setFieldData({ ...fieldData, description: event.target.value });
        }}
      />
    </React.Fragment>
  );
}

export default function SuggestEditModal({ prefetchedBook, bookId, onClose }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const localStateBook = useSelector(getBook(bookId));
  const book = prefetchedBook || localStateBook;

  const {
    authors,
    descriptionMd: description,
    imageLinks,
    title,
    subtitle,
  } = book;

  const bookTitle = getBookTitle({ title: book.title, subtitle });
  const bookSubtitle = getBookSubTitle({ title, subtitle });
  const bookAuthors = getBookAuthorNames(authors);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    title: bookTitle,
    subtitle: bookSubtitle,
    authors,
    description,
  });

  const onSubmit = event => {
    event.preventDefault();
    setLoading(true);
    suggestBookDetails({ dispatch, token, bookId, ...formData })
      .then(({ changes }) => {
        setTimeout(() => {
          if (!changes) {
            // `changes` is true when the suggested data is
            // different to the existing data
            return onClose();
          }
          setLoading(false);
          setSuccess(true);
        }, 600);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (success) {
    return <SuggestionThanksModal onClose={onClose} />;
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalBody maxHeight={832}>
        <React.Fragment>
          <ModalHeading as="div">
            <ModalHeadingText
              heading="Suggest an edit"
              text={`${title} by ${bookAuthors}`}
            />
          </ModalHeading>
          <ModalContent extraStyles={sx.body}>
            <SuggestEditForm
              title={formData.title}
              subtitle={formData.subtitle}
              authors={authors}
              description={formData.description}
              onDataUpdate={data => {
                setFormData(data);
              }}
              imageLinks={imageLinks}
            />
          </ModalContent>
          <ModalFooter hasBorder>
            <GhostButton disabled={loading} type="button" onClick={onClose}>
              Cancel
            </GhostButton>
            <PrimaryButton isLoading={loading} type="submit">
              Submit
            </PrimaryButton>
          </ModalFooter>
        </React.Fragment>
      </ModalBody>
    </form>
  );
}

const sx = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 's',
  },
  toggleContainer: {
    marginY: 's',
  },
  image: {
    marginTop: '-m',
    marginBottom: 'm',
  },
};
