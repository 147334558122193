import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';

import { fetchApi } from '../utils';
import { API_INVITES_URL } from '../constants';

// Actions
export const INVITES_LOADED = 'INVITES_LOADED';
export const INVITES_LOADING = 'INVITES_LOADING';
export const SET_INVITES = 'SET_INVITES';

// Reducer
const invitesReducer = (
  state = {
    areLoading: false,
    accepted: null,
    sent: {},
  },
  action
) => {
  switch (action.type) {
    case INVITES_LOADING:
      return {
        ...state,
        areLoading: true,
      };
    case INVITES_LOADED:
      return {
        ...state,
        areLoading: false,
      };
    case SET_INVITES:
      return {
        ...state,
        accepted: action.invites.accepted,
        inviteCode: action.invites.inviteCode,
      };
    default:
      return state;
  }
};

export const setInvitesLoaded = () => ({
  type: INVITES_LOADED,
});

export const setInvitesLoading = () => ({
  type: INVITES_LOADING,
});

export const setInvites = invites => ({
  type: SET_INVITES,
  invites,
});

// Side effects
export function shouldFetchInvites({ token, dispatch }) {
  dispatch(setInvitesLoading());
  return fetch(API_INVITES_URL, {
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(({ accepted, sent, invite_code }) => {
      dispatch(setInvites({ accepted, sent, inviteCode: invite_code }));
      dispatch(setInvitesLoaded());
    });
}

export function shouldSendInvites({ token, emails, dispatch }) {
  dispatch(setInvitesLoading());
  return fetchApi(API_INVITES_URL, {
    method: 'POST',
    body: { emails },
    token,
  })
    .then(response => response.json())
    .then(() => {
      toast('Your invites were sent successfully!');
      shouldFetchInvites({ token, dispatch });
      mixpanel.track('Invite: Sent', {
        amount: emails.length,
      });
    })
    .catch(() => {
      toast("Oops! That didn't work for some reason. Try again in a bit!");
      dispatch(setInvitesLoaded());
    });
}

export default invitesReducer;
