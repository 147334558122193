/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import Button from './Button';

const sizes = {
  xs: {
    width: 24,
    height: 24,
  },
  s: {
    width: 32,
    height: 32,
  },
  m: {
    width: 40,
    height: 40,
  },
};

export default function CircleButton({
  children,
  isActive,
  size = 'm',
  ...props
}) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const variant = getButonVariant({ isDark, isActive });
  return (
    <Button variant={variant} sx={{ ...sx.button, ...sizes[size] }} {...props}>
      {children}
    </Button>
  );
}

const getButonVariant = ({ isDark, isActive }) => {
  if (isDark) {
    return 'ghost.dark';
  }
  if (isActive) {
    return 'primary';
  }
  return 'ghost.light';
};

const sx = {
  button: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'milk',
    borderRadius: 'circle',
  },
};
