/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import BookCover from '../components/BookCover';
import Button from '../components/Button';
import Card from '../components/Card';
import Heading from '../components/Heading';
import Image from '../components/Image';
import Link from '../components/Link';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import PrimaryButton from '../components/PrimaryButton';
import Text from '../components/Text';

import { getBookDetails } from '../ducks/books';
import { getToken, getWorkId, getLists, getUsername } from '../selectors';
import { girlFloating, girlFloating2x } from '../assets';
import { pluralise } from '../utils';
import { PlusIcon } from '../icons';

import { shouldUpdateListItem } from '../ducks/lists';
import { getCollectionRoute } from '../constants';

export default function AddToCollection({ bookId, onCreate, closeModal }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const username = useSelector(getUsername);

  const lists = useSelector(getLists);
  const listKeys = Object.keys(lists);

  const workId = useSelector(getWorkId(bookId));
  const initialItems = Object.values(lists)
    .filter(list => {
      return (
        list &&
        !!list.books.find(book => {
          if (book.workId) {
            return book.workId === workId || book.id === bookId;
          }
          return book.id === bookId;
        })
      );
    })
    .map(l => l.id);

  const [data, setData] = useState({
    isLoading: false,
    items: initialItems,
    changes: {},
  });

  const toggleItem = id => {
    const selectedItems = data.items;
    const index = selectedItems.indexOf(id);
    let score = data.changes[id] || 0;

    if (selectedItems.includes(id)) {
      score--;
      selectedItems.splice(index, 1);
    } else {
      score++;
      selectedItems.push(id);
    }
    setData({
      ...data,
      items: selectedItems,
      changes: { ...data.changes, [id]: score },
    });
  };

  const onSubmit = event => {
    event.preventDefault();

    const changedLists = Object.values(lists)
      .map(l => l.id)
      .filter(id => {
        return (data.changes[id] || 0) !== 0;
      });

    Promise.all(
      changedLists.map(listId => {
        const adding = data.changes[listId] === 1;
        const action = adding ? 'ADD' : 'DELETE';
        return shouldUpdateListItem({
          dispatch,
          action,
          bookId,
          listId,
          token,
          username,
        }).then(() => {
          if (adding) {
            mixpanel.track('Collection: Add Book');
          } else {
            mixpanel.track('Collection: Remove Book');
          }
        });
      })
    ).then(() => {
      closeModal();
      getBookDetails({ bookId, token, dispatch, shouldLoad: false });
      if (!changedLists.length) {
        return;
      }
      let link;
      if (changedLists.length === 1) {
        const slug = lists[changedLists[0]].slug;
        const linkUrl = getCollectionRoute(username, { slug });
        link = (
          <Link as={RouterLink} to={linkUrl} hasUnderline variant="milk">
            Go to collection.
          </Link>
        );
      }
      toast(<p>Consider it done. {link}</p>, {
        autoClose: 6500,
      });
    });
  };

  return (
    <ModalBody>
      <ModalHeading>Add to collection</ModalHeading>
      <ModalContent>
        <CreateCollection onCreate={onCreate} />
        {listKeys.reverse().map(key => {
          // We don't wanna show the standard lists
          if (lists[key].key) {
            return null;
          }
          const { name, books, id } = lists[key];
          return (
            <Collection
              books={books}
              isSelected={data.items.includes(id)}
              key={id}
              onClick={() => toggleItem(id)}
              title={name}
            />
          );
        })}
      </ModalContent>
      <ModalFooter>
        <PrimaryButton isLoading={data.isLoading} onClick={onSubmit}>
          Done
        </PrimaryButton>
      </ModalFooter>
    </ModalBody>
  );
}

function CreateCollection({ onCreate }) {
  return (
    <Card extraStyles={sx.createCollection} onClick={onCreate}>
      <figure sx={sx.image}>
        <Image
          alt="Girl reading a book"
          blendIn
          hasTransition={false}
          maxWidth={350}
          respondToDarkMode
          src={girlFloating}
          src2x={girlFloating2x}
          width={200}
        />
      </figure>
      <div sx={sx.createCollectionInfo}>
        <Button as="span" size="icon">
          <PlusIcon />
        </Button>
        <Text variant="h2" sx={sx.createCollectionText}>
          Create a collection
        </Text>
      </div>
    </Card>
  );
}

function Collection({ title, books, isSelected, onClick }) {
  const bookCount = pluralise({ count: books.length, word: 'book' });
  return (
    <Card isActive={isSelected} onClick={onClick} extraStyles={sx.item}>
      <Heading as="h2" variant="h2" color="blackberry">
        {title}
      </Heading>
      <Text as="p" variant="overline">
        {bookCount}
      </Text>
      {books && (
        <div sx={sx.books}>
          {books.slice(0, 2).map(book => (
            <span key={book.title}>
              <BookCover
                key={book.title}
                thumbnail={book.imageLinks.thumbnail}
                thumbnail2x={book.imageLinks.thumbnail}
                title={book.title}
                width="book"
                withAspectRatio
              />
            </span>
          ))}
        </div>
      )}
    </Card>
  );
}

const sx = {
  header: {
    paddingX: 'm',
    paddingTop: ['l', 'l', 'xl'],
  },
  content: {
    paddingX: 'm',
    paddingTop: 'm',
    paddingBottom: 94,
  },
  item: {
    width: '100%',
    display: 'block',
    textAlign: 'left',

    paddingLeft: 'l',
    paddingY: 'l',
    paddingRight: 154,
    marginBottom: 's',
    position: 'relative',
    overflow: 'hidden',

    '&:last-child': {
      marginBottom: 0,
    },

    p: {
      marginTop: 'xs',
    },
  },
  books: {
    '>span': {
      position: 'absolute',
      display: 'block',
    },
    '> span:first-of-type': {
      transform: 'rotate(-30deg)',
      top: -15,
      right: -15,
    },
    '> span:last-child': {
      transform: 'rotate(-15deg)',
      top: -15,
      right: -45,
    },
  },
  createCollection: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    marginBottom: 's',
    paddingX: 'm',
    paddingY: 'l',
  },
  image: {
    img: {
      position: 'absolute',
      top: 10,
      right: -50,
      transform: 'rotate(226deg)',
    },
  },
  createCollectionInfo: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  createCollectionText: {
    marginLeft: 's',
    color: 'blackberry',
  },
};
