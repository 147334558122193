/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo, useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { WithContext as ReactTags } from 'react-tag-input';
import { throttle } from 'lodash-es';

import { hasTouch } from '../utils';
import { inputStyles } from './Input';
import { CloseIcon } from '../icons';
import Label from './Label';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function TagInput({
  error,
  fetchSuggestions,
  id: inputId,
  initialTags,
  label,
  onTagsChange,
  placeholder,
}) {
  const _tags = initialTags?.map(({ id, text }) => {
    return { id: id.toString(), text };
  });
  const [tags, setTags] = useState(_tags);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    onTagsChange({ tags });
  }, [tags]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = index => {
    /* eslint-disable-next-line */
    console.log(`The tag at index ${index} was clicked`, tags[index]);
  };

  const handleInputBlur = value => {
    if (value) {
      handleAddition({ id: value, text: value });
    }
    setSuggestions([]);
  };

  const handleInputChange = value => {
    if (!value) {
      setSuggestions([]);
      return;
    }
    if (value.length <= 2) {
      return;
    }
    fetchSuggestions(value).then(suggestedResults => {
      const data = suggestedResults.map(s => {
        return { ...s, id: s.id.toString() };
      });
      if (data.length > 0) {
        setSuggestions(data);
      }
    });
  };
  const debounced = useMemo(() => {
    return throttle(handleInputChange, 1200, {
      leading: true,
      trailing: true,
    });
  }, []);
  return (
    // https://github.com/react-tags/react-tags#Options
    <Label error={error} id={inputId} label={label}>
      <div sx={sx.inputWrapper}>
        <ReactTags
          autofocus={false}
          autocomplete
          delimiters={delimiters}
          handleAddition={handleAddition}
          handleInputBlur={handleInputBlur}
          handleDelete={handleDelete}
          handleDrag={handleDrag}
          handleInputChange={debounced}
          handleTagClick={handleTagClick}
          id={inputId}
          inline
          inputFieldPosition="top"
          placeholder={placeholder}
          suggestions={suggestions}
          tags={tags}
          unique
          renderSuggestion={({ text }, query) => (
            <div sx={sx.suggestion}>
              {text} ({query})
            </div>
          )}
          removeComponent={({ className, onRemove, onKeyDown }) => {
            return (
              <button
                className={className}
                onClick={onRemove}
                onKeyDown={onKeyDown}
                type="button"
                sx={sx.delete}
              >
                <CloseIcon width="18px" height="18px" />
              </button>
            );
          }}
        />
      </div>
    </Label>
  );
}

const sx = {
  delete: {
    padding: '2px',
    border: 'none',
    cursor: 'pointer',
    bg: 'blueberry.10',
    borderRadius: 'round',
    transition: 'smooth.fast',

    '&:hover': {
      bg: 'blueberry.20',
    },
  },
  suggestion: {
    fontSize: 'xs',
    bg: 'blueberry.5',
    color: 'blueberry.100',

    paddingX: '16px',
    paddingTop: '24px',
    paddingBottom: '16px',
    marginTop: '-8px',

    cursor: 'pointer',
    transition: 'smooth.fast',
    borderBottomLeftRadius: 'button',
    borderBottomRightRadius: 'button',

    '&:hover': {
      bg: 'blueberry.10',
    },
  },
  inputWrapper: {
    '.tag-wrapper': {
      gap: 'xs',
      paddingY: 'xs',
      marginRight: 'xs',
      paddingRight: 'xs',
      paddingLeft: 's',
      fontSize: 'xxs',
      fontWeight: 'button',
      marginTop: '12px',
      borderRadius: 'round',
      display: 'inline-flex',
      alignItems: 'center',
      color: 'blueberry.80',
      backgroundColor: 'blueberry.5',
    },
    input: {
      ...inputStyles,
      bg: 'milk',
      width: '100%',
      border: '1px solid',
      color: 'blueberry.100',
      borderColor: 'blueberry.20',
      '&:hover': !hasTouch() && {
        borderColor: 'blueberry.50',
      },
      '&:focus': {
        borderColor: 'blueberry.80',
        boxShadow: 'input.default',
      },
    },
  },
};
