/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from './Text';

export default function Tag({ children, marginRight, marginBottom }) {
  return (
    <Text
      as="span"
      variant="subtitle"
      sx={{ ...sx.tag, marginRight, marginBottom }}
    >
      {children}
    </Text>
  );
}

const sx = {
  tag: {
    bg: 'blueberry.5',
    display: 'block',
    borderRadius: 'button',
    height: '30px',
    paddingX: '12px',
    fontSize: 'xxs',
    lineHeight: '30px',
  },
};
