/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

import Image from './Image';

export default function ModalImage({ src, src2x, alt, width, height }) {
  return (
    <AspectRatio ratio={width / height}>
      <figure sx={sx.figure}>
        <Image
          alt={alt}
          blendIn
          respondToDarkMode
          src={src}
          src2x={src2x}
          visibleByDefault
          width="100%"
        />
      </figure>
    </AspectRatio>
  );
}

const sx = {
  figure: {
    top: 0,
    right: 0,
    left: 0,
    position: 'absolute',
    zIndex: -1,
    bg: 'milk',
  },
};
