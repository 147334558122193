/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import FullHeight from '../../components/FullHeight';
import GhostButton from '../../components/GhostButton';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Page from '../../components/Page';
import PrimaryButton from '../../components/PrimaryButton';
import Text from '../../components/Text';

import { SearchIcon } from '../../icons';
import { book, book2x } from '../../assets';
import { FEATURES, getToken, hasFeature } from '../../selectors';
import { SEARCH_ROUTE } from '../../constants';
import { beginImport } from '../../ducks/goodreads';

export default function DashboardEmptyState() {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const enableImport = useSelector(hasFeature(FEATURES.IMPORTER_IOS));

  const startImport = () => {
    beginImport({ token, dispatch }).catch(e => {
      toast(
        'Unable to import because of an error. Please wait a bit and try again.',
        { autoClose: 7500 }
      );
      console.error(e);
    });
  };

  const bodyCopy = "Let's start by building your reading list";
  return (
    <Page title="Dashboard">
      <FullHeight alignItems="center" justifyContent="center">
        <div sx={sx.header}>
          <Image
            alt="An open book"
            height={66}
            respondToDarkMode
            src={book}
            src2x={book2x}
            width={66}
          />
          <Heading as="h1" fontSize="l" fontFamily="serif" sx={sx.greeting}>
            Welcome to Oku!
          </Heading>
          <Text sx={sx.body} variant="body">
            {bodyCopy}
          </Text>
          <GhostButton as={RouterLink} to={SEARCH_ROUTE} sx={sx.searchButton}>
            <SearchIcon />
            Browse Explore
          </GhostButton>
          {enableImport && (
            <PrimaryButton as="a" onClick={startImport} sx={sx.importButton}>
              Import from Goodreads ↗
            </PrimaryButton>
          )}
        </div>
      </FullHeight>
    </Page>
  );
}

const sx = {
  greeting: {
    color: 'blueberry.100',
    marginTop: 'm',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  importButton: {
    minWidth: 270,
  },
  searchButton: {
    marginTop: 'l',
    marginBottom: 's',
    minWidth: 270,
    span: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        marginRight: 'xs',
      },
    },
  },
  body: {
    maxWidth: 380,
    marginTop: 'xxs',
    textAlign: 'center',
    paddingLeft: 'm',
    paddingRight: 'm',
  },
};
