import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { ConnectedRouter as Router } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import mixpanel from 'mixpanel-browser';
import SwiperCore, { Navigation } from 'swiper/core';

import 'react-toastify/dist/ReactToastify.css';
import 'minireset.css/minireset.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/toast.css';

import { createFetchApi } from './utils';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import theme from './theme';

import App from './App';
import ContextProvider from './utils/context';
import { setKey } from './ducks/object';
import { SET_PRICES } from './ducks/subscription';

SwiperCore.use([Navigation]);

export const history = createBrowserHistory();
export const store = configureStore(history);

window.addEventListener('beforeinstallprompt', e => {
  // stash this away for later use in AddToHomescreen.js
  window.beforeinstall = e;

  // // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
});

createFetchApi();

window.nativeMessageHandler = msg => {
  // receives messages from the iOS app wrapper
  let data;
  try {
    data = JSON.parse(msg);
  } catch (e) {
    console.error(e);
    return;
  }

  switch (data.type) {
    case 'productsLoaded':
      const products = {};
      data.products.forEach(product => {
        products[product.id] = product;
      });
      store.dispatch(
        setKey({
          type: SET_PRICES,
          key: 'ios',
          value: products,
        })
      );
      break;
    default:
      break;
  }
};

const enableMixpanel = process.env.REACT_APP_ENABLE_MIXPANEL === '1';
const token = process.env.REACT_APP_MIXPANEL_KEY || '123';
mixpanel.init(token, {
  api_host: 'https://mx.oku.club',
  use_ip: false,
});

if (!enableMixpanel) {
  // eslint-disable-next-line
  console.log('Disabling mixpanel');
  try {
    mixpanel.disable();
  } catch (e) {
    console.warn(e);
  }
  const noop = (a1, a2) => {
    // eslint-disable-next-line
    console.log('MixPanel:', a1, a2);
  };
  mixpanel.track = noop;
  mixpanel.indentify = noop;
  mixpanel.people = {
    set: noop,
  };
}

ReactDOM.render(
  <Provider store={store}>
    <ContextProvider>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </ContextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
