/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

// import Avatar from '../../components/Avatar';
import Heading from '../../components/Heading';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ModalDialog from '../../components/ModalDialog';
import Text from '../../components/Text';
import SignUpModal from '../../modals/SignUp';
import DeleteConfirmationModal from '../../modals/DeleteConfirmation';

import ContainsSpoilers, {
  ContainsSpoilersWrapper,
} from '../../components/ContainsSpoilers';

import { hasTouch } from '../../utils';

import { TrashIcon, PlusIcon } from '../../icons';
import { getIsAuthenticated, getToken, getUsername } from '../../selectors';
import { removeHighlight, saveExistingHighlight } from '../../ducks/highlights';
import { getHighlightRoute } from '../../constants';

export default function Highlight({
  // avatar,
  bookSlug,
  canRemove,
  canSave,
  containsSpoiler,
  countSaves,
  date,
  id,
  position,
  standalone,
  text,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(getToken);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [signupVisible, setSignupOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showSpoilerAlert, setShowSpoilerAlert] = useState(true);

  const hideSpoilerAlert = () => {
    setShowSpoilerAlert(false);
  };

  const onStartRemovingHighlight = event => {
    event.preventDefault();
    setIsRemoving(true);
  };

  const onCancelRemovingHighlight = () => {
    setIsRemoving(false);
  };

  const onRemoveHighlight = event => {
    event.preventDefault();
    setIsLoading(true);
    removeHighlight({ highlightId: id, token, dispatch, username }).then(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 700);
    });
  };

  const onSaveHighlight = event => {
    event.preventDefault();

    if (!isAuthenticated) {
      setSignupOpen(true);
      mixpanel.track('Save Highlight: Not Authed');
      return;
    }

    setIsLoading(true);
    saveExistingHighlight({ highlightId: id, token, dispatch, username })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      })
      .catch(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
        toast(
          'Uh oh - unable to save that highlight. Please try again later!',
          {
            autoClose: 10000,
          }
        );
      });
  };

  const savedAt = date && moment(date).format('DD MMM YYYY');
  const loadingStyles = isLoading && sx.isLoading;

  return (
    <>
      <Container
        id={id}
        bookSlug={bookSlug}
        standalone={standalone}
        blockLink={isRemoving}
      >
        <div sx={{ ...sx.body, ...loadingStyles }}>
          <Heading
            as="h2"
            variant={standalone ? 'highlightStandalone' : 'highlight'}
          >
            {text}
          </Heading>
          <footer sx={sx.footer}>
            <Text variant="secondary">
              {position > 0 && <React.Fragment>Page {position}</React.Fragment>}
              {position > 0 && (countSaves || savedAt) && (
                <React.Fragment> &middot; </React.Fragment>
              )}
              {savedAt && <>{savedAt}</>}
              {savedAt && countSaves && (
                <React.Fragment> &middot; </React.Fragment>
              )}
              {countSaves && (
                <React.Fragment>
                  Saved by {countSaves} {countSaves === 1 ? 'person' : 'people'}
                </React.Fragment>
              )}
            </Text>
            <div sx={sx.footerActions}>
              {canSave && (
                <Text variant="secondary">
                  <Link onClick={onSaveHighlight} sx={sx.link}>
                    <span sx={sx.plusIcon}>
                      <PlusIcon width="22px" height="22px" />
                    </span>
                    Save
                  </Link>
                </Text>
              )}
              {canRemove && (
                <Text variant="secondary" sx={{ marginLeft: 's' }}>
                  <Link onClick={onStartRemovingHighlight} sx={sx.link}>
                    <TrashIcon />
                    Scrap
                  </Link>
                </Text>
              )}
            </div>
          </footer>
          {/* {canRemove && (
          <div sx={sx.addNoteContainer}>
            <Avatar size={32} src={avatar} src2x={avatar} />
            <div sx={sx.addNote}>Add note...</div>
          </div>
        )} */}
        </div>
        {isLoading && (
          <div sx={sx.pinCenter}>
            <Loader />
          </div>
        )}
        {containsSpoiler && showSpoilerAlert && (
          <ContainsSpoilersWrapper onClick={hideSpoilerAlert}>
            <ContainsSpoilers headingVariant="highlight" hasIcon />
          </ContainsSpoilersWrapper>
        )}
      </Container>
      {isAuthenticated ? (
        <ModalDialog isVisible={isRemoving} onClose={onCancelRemovingHighlight}>
          <DeleteConfirmationModal
            onClose={onCancelRemovingHighlight}
            onSubmit={onRemoveHighlight}
            isLoading={isLoading}
            heading={
              <>
                Do you really want to <br /> delete your highlight?
              </>
            }
          />
        </ModalDialog>
      ) : (
        <ModalDialog
          isVisible={signupVisible}
          onClose={() => setSignupOpen(false)}
        >
          <SignUpModal heading="Join Oku to save this highlight" />
        </ModalDialog>
      )}
    </>
  );
}

function Container({ children, id, bookSlug, standalone, blockLink }) {
  if (standalone || blockLink) {
    return <div sx={sx.container}>{children}</div>;
  }
  const link = getHighlightRoute({ bookSlug, highlightId: id });
  return (
    <RouterLink sx={{ ...sx.container, ...sx.hover }} to={link}>
      {children}
    </RouterLink>
  );
}

const sx = {
  container: {
    display: 'block',
    paddingX: 'm',
    paddingBottom: 'l',
    paddingTop: '22px', // need to compensate that line height
    borderRadius: 'button',
    border: '1px solid',
    borderColor: 'blueberry.10',
    marginBottom: ['s', 's', 'm'],
    boxShadow: 'highlight.light',
    position: 'relative',
    transition: 'smooth.fast',
    overflow: 'hidden',
    h2: {
      fontFamily: 'serif',
      lineHeight: 'body',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  hover: {
    cursor: 'pointer',
    '&:hover': !hasTouch() && {
      boxShadow: 'highlight.hover',
    },
    '&:active': {
      boxShadow: 'highlight.light',
    },
  },
  footer: {
    marginTop: 's',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    transition: 'smooth.fast',
  },
  isLoading: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  pinCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '> svg': {
      marginRight: 'xxs',
      marginTop: 1,
    },
  },
  addNoteContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 'm',
    marginTop: 'm',
    borderTop: '1px solid',
    borderColor: 'blueberry.10',
  },
  addNote: {
    flex: 1,
    height: '40px',
    cursor: 'pointer',
    lineHeight: '40px',
    bg: 'blueberry.5',
    color: 'blueberry.80',
    borderRadius: 'button',
    transition: 'smooth.fast',
    fontSize: 'xs',
    marginLeft: 's',
    paddingLeft: 's',
    '&:hover': {
      bg: 'blueberry.10',
    },
  },
  plusIcon: {
    width: 18,
    height: 18,
    border: '1px solid',
    borderRadius: 'toast',
    borderColor: 'blueberry.20',
    marginRight: '6px',
    position: 'relative',
    display: 'block',
    svg: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'block',
    },
  },
  footerActions: {
    display: 'flex',
    alignItems: 'center',
  },
};
