/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import FullHeight from '../../components/FullHeight';
import Heading from '../../components/Heading';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import TabLink from '../../components/TabLink';
import Tabs from '../../components/Tabs';

import {
  ACTIVITY_EVERYBODY_ROUTE,
  ACTIVITY_FRIENDS_ROUTE,
} from '../../constants';

import {
  ACTIVITY_PREFERENCE,
  ACTIVE_TAB_GLOBAL,
  ACTIVE_TAB_FRIENDS,
} from './constants';

export default function ActivityPageWrap({ activeTab, children }) {
  const setFriendsPreference = () => {
    localStorage.setItem(ACTIVITY_PREFERENCE, ACTIVE_TAB_FRIENDS);
  };
  const setGlobalPreference = () => {
    localStorage.setItem(ACTIVITY_PREFERENCE, ACTIVE_TAB_GLOBAL);
  };
  const pageTitle =
    activeTab === ACTIVE_TAB_FRIENDS ? 'Friends / Activity' : 'Activity';

  useEffect(() => {
    try {
      mixpanel.track('View Activity', {
        activeTab,
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  return (
    <Page title={pageTitle}>
      <FullHeight>
        <PageBody extraStyles={sx.body}>
          <header sx={sx.header}>
            <Heading as="h1" variant="h1" fontFamily="serif" lineHeight="34px">
              Feed
            </Heading>
            <Tabs extraStyle={sx.tabs}>
              <TabLink
                as={Link}
                isActive={activeTab === ACTIVE_TAB_FRIENDS}
                onClick={setFriendsPreference}
                to={ACTIVITY_FRIENDS_ROUTE}
              >
                Friends
              </TabLink>
              <TabLink
                as={Link}
                isActive={activeTab === ACTIVE_TAB_GLOBAL}
                onClick={setGlobalPreference}
                to={ACTIVITY_EVERYBODY_ROUTE}
              >
                Everybody
              </TabLink>
            </Tabs>
          </header>
          {children}
        </PageBody>
      </FullHeight>
    </Page>
  );
}

const sx = {
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ['s', 's', 'm'],
    paddingTop: ['m', 'm', 'l'],

    position: 'sticky',
    top: 0,
    bg: 'milk',
    zIndex: 100,
  },
  tabs: {
    width: '100%',
    marginTop: 'xs',
  },
};
