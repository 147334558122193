/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';

import Text from '../../components/Text';
import BookCover from '../../components/BookCover';
import Link from '../../components/Link';

import { sizes, space } from '../../theme/sizes';
import { getBookRoute } from '../../constants';

const AUTHOR_WIDTH = 200;

export default function CommonAuthorsTable({ data }) {
  const maxWidth = useResponsiveValue([
    sizes.maxWidth.mobile,
    sizes.maxWidth.mobile,
    sizes.maxWidth.phablet,
    sizes.maxWidth.tablet,
    sizes.maxWidth.notebook,
    sizes.maxWidth.laptop,
    sizes.maxWidth.desktop,
  ]);
  const spaceXL = useResponsiveValue([space.l, space.l, space.l, space.xl]);
  const authorWidth = useResponsiveValue([
    0,
    0,
    0,
    0,
    AUTHOR_WIDTH,
    AUTHOR_WIDTH,
    AUTHOR_WIDTH,
  ]);
  const bookSpace = useResponsiveValue([0, 0, space.s]);
  const booksPerRow = useResponsiveValue([2, 2, 4, 4, 5, 7, 9]);
  const realWidth = maxWidth - space.xl * 2;
  const bookContainerWidth = realWidth - authorWidth - spaceXL;
  const bookWidth = bookContainerWidth / booksPerRow;

  return (
    <div sx={sx.container}>
      {data?.map(({ author, books }) => {
        return (
          <div sx={sx.author} key={author}>
            <div sx={sx.authorContainer}>
              <div sx={sx.authorMeta}>
                <Text variant="subtitle1">{author}</Text>
                <Text variant="body">{books.length} books</Text>
              </div>
            </div>
            <Grid gap="s" columns={booksPerRow}>
              {books.map(book => {
                const { slug, thumbnail, title } = book;
                const route = getBookRoute(slug);
                return (
                  <Link
                    href={route}
                    key={route}
                    sx={{
                      ...sx.book,
                      maxWidth: '100%',
                      width: bookWidth - bookSpace,
                    }}
                  >
                    <BookCover
                      thumbnail={thumbnail}
                      thumbnail2x={thumbnail}
                      title={title}
                      visibleByDefault
                      width="100%"
                      alt={title}
                      withAspectRatio
                    />
                  </Link>
                );
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
}

const sx = {
  author: {
    marginBottom: 'xl',
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column', 'row'],
    '&:last-child': {
      marginBottom: 0,
    },
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 150, // TODO
    marginBottom: ['m', 'm', 'm', 'm', 0],
  },
  authorMeta: {
    width: AUTHOR_WIDTH,
  },
  // covers: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   justifyContent: 'flex-start',
  //   marginRight: -16,
  //   marginBottom: -16,
  // },
  book: {
    overflow: 'visible',
    div: {
      overflow: 'visible',
    },
  },
};
