/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';

import Button from '../components/Button';
import FullHeight from '../components/FullHeight';
import Heading from '../components/Heading';
import Image from '../components/Image';
import Link from '../components/Link';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import Text from '../components/Text';

import { HOME_ROUTE, SEARCH_BOOKS_ROUTE } from '../constants';

import theme from '../theme';
import { getIsAuthenticated, getToken } from '../selectors';
import { dogAndMan, dogAndMan2x } from '../assets';
import { beginImport, completeGoodreadsAuth } from '../ducks/goodreads';
import Loader from '../components/Loader';

export function FullPageError({ title, children }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const topStyles = isAuthenticated
    ? sx.image.topAuthenticated
    : sx.image.topRegular;
  return (
    <Page headerless title={title || 'Connect Oku to Goodreads'}>
      <FullHeight>
        <div sx={sx.page}>
          <figure sx={{ ...sx.image.top, ...topStyles }}>
            <Image
              alt="Dog and human reading a book"
              maxWidth={500}
              respondToDarkMode
              src={dogAndMan}
              src2x={dogAndMan2x}
              visibleByDefault
              width="100%"
            />
          </figure>
          <figure sx={sx.image.bottom}>
            <Image
              alt="Dog and human reading a book"
              maxWidth={500}
              respondToDarkMode
              src={dogAndMan}
              src2x={dogAndMan2x}
              visibleByDefault
              width="100%"
            />
          </figure>
          {isAuthenticated ? <div /> : <PageHeader />}
          <div sx={sx.body}>{children}</div>
          <div />
        </div>
      </FullHeight>
    </Page>
  );
}

const getCopy = (authState, isNative) => {
  if (isNative) {
    if (authState === 'importing') {
      return {
        title: "We're importing your things!",
        text: "Tap 'Done' (up top) to return to Oku",
        buttonText: null,
      };
    }
  }

  const allCopy = {
    authing: {
      title: 'Authing',
      text: <Loader />,
      buttonText: null,
    },
    pending: {
      title: 'Linking with Goodreads',
      text: <Loader />,
      buttonText: null,
    },
    complete: {
      title: 'Your Goodreads account has been connected',
      text:
        'This window should close automatically. If not, click the button below to continue with your import',
      buttonText: 'Begin your import',
    },
    importing: {
      title: "We're importing your things!",
      text:
        "We'll let you know when your books & shelves are available. Why not check out Explore while you wait?",
      buttonText: 'Browse Explore',
      buttonLink: SEARCH_BOOKS_ROUTE,
    },
    denied: {
      title: 'You denied the Goodreads connection',
      text: 'Please retry if you change your mind :)',
      buttonText: 'Back to Oku',
    },
    conflict: {
      title: 'That account is connected somewhere else',
      text:
        'Another member has already linked this Goodreads account. They should disconnect it first.',
      buttonText: 'Back to Oku',
    },
    error: {
      title: 'We were unable to import your library because of an error',
      text: "We're really sorry - please give it a few minutes and try again.",
      buttonText: 'Back to Oku',
    },
  };
  return allCopy[authState] || allCopy.error;
};

// this component is opened in a new window after being redirected back from goodreads,
// but also in the main browser window when browsers block popups, in which case it is also
// responsible for kicking off the importer.
export default function GoodreadsConnected() {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const loc = useLocation();
  const allParams = new URLSearchParams(loc.search);
  // const nativeIos = allParams.get('native') === '1';
  // goodreads doesn't play nice when adding a querystring
  // to this callback url so we hack it by ending our url
  // with ?rest=, which will then become ?rest=?oauth_token=1&authorize=0
  const rest = allParams.get('rest');
  if (rest) {
    new URLSearchParams(rest).forEach((value, key) => {
      allParams.set(key, value);
    });
  }
  const goodreadsToken = allParams.get('oauth_token');
  const okuToken = allParams.get('okutoken');
  const nativeIos = !!okuToken;
  const unauthorized = allParams.get('authorize') === '0';
  const [authState, setAuthState] = useState(
    unauthorized ? 'denied' : 'pending'
  );
  const copy = getCopy(authState, nativeIos);

  useEffect(() => {
    if (authState === 'pending') {
      completeGoodreadsAuth({
        token,
        okuToken,
        oauthToken: goodreadsToken,
        dispatch,
      })
        .then(() => {
          mixpanel.track('Goodreads: Connected');
          setAuthState('complete');
        })
        .catch(resp => {
          const state = resp.status === 409 ? 'conflict' : 'error';
          mixpanel.track('Goodreads: Connection Fail', { reason: state });
          setAuthState(state);
        });
    }
  }, []);

  const onComplete = () => {
    if (window.opener) {
      // the parent window should close the popup if need be
      window.opener.postMessage({ authState }, window.location);
    }

    // if this is a popup this window should get closed by the parent when authState
    // is complete.let's wait a bit and then kick off the import for the browsers that
    // blocked the popup
    if (authState === 'complete') {
      setTimeout(() => {
        setAuthState('importing');
        beginImport({ token, dispatch, skipAuth: true }).catch(e => {
          toast(
            'Unable to import because of an error. Please wait a bit and try again.',
            { autoClose: 7500 }
          );
          setAuthState('error');
          console.error(e);
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (authState === 'complete') {
      onComplete();
    }
  }, [authState]);

  return (
    <FullPageError>
      <Heading sx={sx.heading} variant="h2" as="h1">
        {copy.title}
      </Heading>
      <Text variant="body" as="p" sx={sx.text}>
        {copy.text}
      </Text>
      <div>
        {copy.buttonText && (
          <Button
            as={Link}
            onClick={onComplete}
            href={copy.buttonLink || HOME_ROUTE}
            sx={sx.button}
            variant="primary"
          >
            {copy.buttonText}
          </Button>
        )}
      </div>
    </FullPageError>
  );
}

const sx = {
  page: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingX: 'l',
  },
  image: {
    top: {
      top: [-200, -200, -270],
      position: 'fixed',
      zIndex: -1,
      bg: 'milk',
    },
    topRegular: {
      left: [-200, -200, -120],
    },
    topAuthenticated: {
      left: theme.sizes.sidebar - 20,
    },
    bottom: {
      position: 'fixed',
      bottom: [-200, -200, -100],
      right: -60,
      zIndex: -1,
      bg: 'milk',
    },
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignText: 'center',
  },

  // Generic
  heading: {
    textAlign: ['left', 'left', 'center'],
  },
  text: {
    maxWidth: 420,
    textAlign: ['left', 'left', 'center'],
    marginTop: 'xs',
  },
  button: {
    marginTop: 'm',
    marginX: 'xxs',
  },
  br: {
    display: ['none', 'block', 'block', 'block'],
  },
};
