/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDispatch } from 'react-redux';

import Highlight from './Highlight';
import HighlightPageHeader from './HighlightPageHeader';
import Image from '../../components/Image';
import PageNavigation from '../../components/PageNavigation';

import { HIGHLIGHTS_ROUTE } from '../../constants';
import { readingWorld, readingWorld2x } from '../../assets';
import CreateHighlightButton from './CreateHighlightButton';

export default function HighlightsPageBody({
  avatar,
  closeModal,
  highlights,
  isLoading,
  openModal,
  selectedBook,
  showModal,
}) {
  const dispatch = useDispatch();
  const isOnMobile = useResponsiveValue([true, true, true, true, false]);

  useEffect(() => {
    if (highlights?.length === 0) {
      dispatch(push(HIGHLIGHTS_ROUTE));
    }
  }, [highlights]);

  if (!selectedBook) {
    return (
      <div sx={sx.emptyState}>
        <Image
          src={readingWorld}
          src2x={readingWorld2x}
          alt="A man floating around in a world of books"
          blendIn
          hasTransition={false}
          maxWidth={580}
          respondToDarkMode
          width="100%"
        />
      </div>
    );
  }

  return (
    <div sx={sx.body}>
      {isOnMobile && (
        <PageNavigation
          onGoBack={() => dispatch(push(HIGHLIGHTS_ROUTE))}
          showBackButton
        >
          <CreateHighlightButton
            closeModal={closeModal}
            isLoading={isLoading}
            openModal={openModal}
            selectedBook={selectedBook.book}
            showModal={showModal}
          />
        </PageNavigation>
      )}
      <main sx={sx.highlights}>
        <HighlightPageHeader
          highlightCount={selectedBook.highlightCount}
          slug={selectedBook.book.slug}
          title={selectedBook.book.title}
        />
        {highlights.map(item => {
          return (
            <Highlight
              avatar={avatar}
              canRemove
              id={item.id}
              bookSlug={selectedBook.book.slug}
              key={item.id}
              position={item.position}
              text={item.text}
              date={item.saved_at}
            />
          );
        })}
      </main>
    </div>
  );
}

const sx = {
  emptyState: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'blueberry.2',
  },
  body: {
    paddingX: [0, 0, 0, 0, 'l'],
    paddingBottom: ['s', 's', 'm', 'l'],
    paddingTop: [0, 0, 0, 0, 'xl'],
    overflow: ['unset', 'unset', 'unset', 'unset', 'auto'],
    flex: 1,
  },
  highlights: {
    paddingX: ['s', 's', 'm', 'l', 0],
  },
};
