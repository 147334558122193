export const zIndices = {
  negative: -1,
  modal: {
    content: 1,
    header: 10,
    nav: 20,
  },
  search: 79,
  navigation: 89,
  header: 89,
  footer: 89,
  overlay: 199,
  corners: 899,
};
