import { TIERS } from '../constants';

export const getProfile = username => {
  return state => {
    return state.profile[username?.toLowerCase()];
  };
};

export const getProfileFirstName = user => {
  return user?.details?.firstname;
};
export const getProfileLastName = user => {
  return user?.details?.lastname;
};
export const getProfileUserName = user => {
  return user?.details?.username;
};

export const getProfileDisplayName = user =>
  getProfileFirstName(user) || getProfileUserName(user);

export const getProfileAvatar = user => {
  return user?.details?.image;
};
export const getProfileBio = user => {
  return user?.details?.bio;
};
export const getProfileTier = user => {
  return user?.details?.tier || TIERS.FREE;
};
export const getProfileFollowing = user => {
  return user?.details?.following;
};

export const getProfileFollows = user => {
  if (!user) {
    return;
  }
  const { followers, following } = user;
  return { followers, following };
};

export const getProfileFullName = user => {
  const name = `${getProfileFirstName(user)} ${getProfileLastName(
    user
  )}`.trim();
  return name || getProfileUserName(user);
};

export const getProfileReviewCount = user => {
  return user?.details?.reviewCount;
};

export const getProfileHighlightCount = user => {
  return user?.details?.highlightCount || 0;
};

export const getProfileLists = user => {
  return user?.lists || {};
};

export const getProfileDisplayLists = user => {
  // return the lists which are shown under the collections tab
  // on the profile page.
  const allLists = getProfileLists(user);
  const lists = Object.keys(allLists)
    .map(listId => {
      return allLists[listId];
    })
    .filter(list => {
      switch (list.key) {
        case 'to-read':
        case 'reading':
        case 'read':
          return false;
        case 'recs':
          return list.books.length > 0;
        default:
          return true;
      }
    });
  return lists;
};

export const getProfileDisplayListKeys = user => {
  let keys = getProfileDisplayLists(user).map(list => list.key || list.id);
  if (keys.includes('recs')) {
    // move recommendation list to the top
    keys = keys.filter(key => key !== 'recs');
    keys.unshift('recs');
  }
  return keys;
};

export const getProfileReadingList = user => {
  return user?.lists?.reading || [];
  // return user.lists.reading;
};

export const getProfileToReadList = user => {
  if (!user?.lists) return [];
  return user.lists['to-read'];
};

export const getProfileReadList = user => {
  return user?.lists?.read || [];
};
