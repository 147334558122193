export const getBooksLoading = state => state.books.areLoading;
export const getBook = bookId => {
  return state => {
    return state.books.items[bookId];
  };
};

export const getWorkId = bookId => {
  return state => {
    return getBook(bookId)(state).workId || null;
  };
};

export const getSelectedBookTags = bookId => {
  return state => {
    return getBook(bookId)(state).tags?.yours || [];
  };
};

export const getSelectedBookOtherCollections = bookId => {
  return state => {
    return getBook(bookId)(state)?.otherCollections || [];
  };
};

export const getBookLanguages = languages => {
  return languages ? languages.join(', ') : '';
};

export const getCoverUrl = imageLinks => {
  if (imageLinks?.small) {
    return imageLinks.small;
  }
  if (imageLinks?.thumbnail) {
    return imageLinks.thumbnail;
  }
  if (imageLinks?.medium) {
    return imageLinks.medium;
  }
  if (imageLinks?.large) {
    return imageLinks.large;
  }
  if (imageLinks?.extraLarge) {
    return imageLinks.extraLarge;
  }
};

/*
 * Utils */
export const getBookTitle = ({ title, subtitle }) => {
  if (!title) return '';
  if (subtitle) {
    return title;
  }
  const indexOfSemi = title.indexOf(':');
  return indexOfSemi !== -1 ? title.substring(0, indexOfSemi) : title;
};

export const getBookSubTitle = ({ title, subtitle }) => {
  if (subtitle) {
    return subtitle;
  }
  const indexOfSemi = title.indexOf(':');
  return indexOfSemi !== -1 ? title.substring(indexOfSemi + 2) : '';
};

export const getBookAuthorNames = authors => {
  return authors.map(author => (author.name ? author.name : author));
};

export const getBookAuthors = authors => {
  const authorNames = getBookAuthorNames(authors);
  return authors ? `by ${authorNames.join(', ')}` : '';
};

export const getBookSlug = book => {
  if (!book.slug) {
    return book.id;
  }
  return book.slug;
};

export const getRatings = ratings =>
  ratings.filter(rating => ['gdreads', 'gbooks'].indexOf(rating.source) !== -1);
