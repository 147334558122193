export const getEmail = state => state.user.details.email;
export const getFirstName = state => state.user.details.firstname;
export const getLastName = state => state.user.details.lastname;
export const getUsername = state => state.user.details.username;
export const getVisibility = state => state.user.details.visibility;
export const getDisplayName = state =>
  getFirstName(state) || getUsername(state);
export const getAvatar = state => state.user.details.image;
export const getBio = state => state.user.details.bio;
export const getUserStale = state => !!state.user.details._stale;
export const getTier = state => state.user.details.tier;

export const getFullName = state => {
  const firstName = getFirstName(state);
  const lastName = getLastName(state);
  const username = getUsername(state);
  if (firstName) {
    return `${firstName} ${lastName}`;
  }
  return username;
};

export const getUpdateUserFormLoading = state => state.user.isLoading;
