/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import AspectImage from '../../components/AspectImage';
import Heading from '../../components/Heading';
import Text from '../../components/Text';

import { yav, yav2x, joe, joe2x, aziz, aziz2x } from '../../assets';

export default function TeamBlock() {
  return (
    <div sx={sx.container}>
      <Heading as="h2" fontFamily="serif" variant="marketing">
        Meet the team
      </Heading>
      <Text as="p" color="blueberry.80" variant="body">
        As three friends scattered throughout Europe, we were looking for a way
        to discover books and share our own recommendations. Nothing cut the
        mustard, so we took the challenge on. We may not have deep pockets or
        lots of time, but we&apos;re willing to bet we can make up for it by
        working smart and taking pride in our work.
      </Text>
      <div sx={sx.team}>
        <div sx={sx.image}>
          <figure sx={sx.avatar}>
            <AspectImage
              alt="A man and a dog"
              aspectHeight={124}
              aspectWidth={124}
              height={124}
              respondToDarkMode
              src={yav}
              src2x={yav2x}
              width={124}
            />
          </figure>
          <Heading as="h4">Yavor Punchev &amp; Fuji</Heading>
          <Text as="p">Sofia, Bulgaria</Text>
        </div>
        <div sx={sx.image}>
          <figure sx={sx.avatar}>
            <AspectImage
              alt="A black cat"
              aspectHeight={124}
              aspectWidth={124}
              respondToDarkMode
              src={joe}
              src2x={joe2x}
              width={124}
              height={124}
            />
          </figure>
          <Heading as="h4">Joe Alcorn</Heading>
          <Text as="p">London, United Kingdom</Text>
        </div>
        <div sx={sx.image}>
          <figure sx={sx.avatar}>
            <AspectImage
              alt="A man chilling reading a book"
              aspectHeight={124}
              aspectWidth={124}
              respondToDarkMode
              src={aziz}
              src2x={aziz2x}
              width={124}
              height={124}
            />
          </figure>
          <Heading as="h4">Aziz Firat</Heading>
          <Text as="p">Oslo, Norway</Text>
        </div>
      </div>
    </div>
  );
}

const sx = {
  container: {
    maxWidth: 912,
    marginX: 'auto',
    color: 'blueberry.100',
    textAlign: 'center',
    paddingX: 'l',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    paddingBottom: [80, 80, 120, 120, 120],

    '>p': {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 'm',
      maxWidth: 720,
    },
  },
  team: {
    width: '80%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 'xl',
    h4: {
      fontWeight: 'button',
    },
  },
  avatar: {
    width: 124,
    height: 124,
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: ['100%', '100%', '100%', '33.3333%', '33.3333%'],
    marginBottom: ['l', 'l', 'l', 0, 0],

    h4: {
      color: 'blackberry',
      fontSize: 's',
      marginBottom: 'xxs',
      marginTop: 'm',
    },

    p: {
      fontSize: 13,
      color: 'blueberry.80',
      lineHeight: 'body',
    },

    img: {
      borderRadius: 'circle',
      boxShadow: '0 0 0 1px rgba(83, 84, 94, 0.1)',
    },
  },
};
