import React from 'react';

import Svg from '../../components/Svg';

export default function EyeIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14 8c-3.1951 0-5.43782 1.42977-6.8632 2.84-.71043.7028-1.22246 1.4044-1.55792 1.9315-.16811.2641-.293.4863-.3772.6453-.04213.0796-.07417.1435-.09642.1892-.01113.0229-.01981.0412-.0261.0547l-.00765.0165-.00248.0054-.0009.002-.00038.0009c-.00016.0003-.00031.0007.71486.3151l-.71517-.3144c-.08993.2001-.08992.4286.00002.6288l.71515-.3144c-.71515.3144-.71499.3148-.71483.3151l.00037.0008.00091.0021.00248.0054.00764.0165c.00629.0135.01498.0318.02611.0546.02225.0457.05429.1097.09642.1893.08421.159.20909.381.37721.6451.33546.5271.8475 1.2284 1.55794 1.9312 1.42539 1.4099 3.66814 2.8393 6.86314 2.8393s5.4377-1.4294 6.8631-2.8393c.7105-.7028 1.2225-1.4041 1.558-1.9312.1681-.2641.293-.4861.3772-.6451.0421-.0796.0742-.1436.0964-.1893.0111-.0228.0198-.0411.0261-.0546l.0077-.0165.0024-.0054.0009-.0021.0004-.0008c.0002-.0003.0003-.0007-.7148-.3151l.7151.3144c.09-.2002.09-.4287.0001-.6288l-.7152.3144c.7152-.3144.715-.3148.7148-.3151l-.0003-.0009-.0009-.002-.0025-.0054-.0077-.0165c-.0062-.0135-.0149-.0318-.0261-.0547-.0222-.0457-.0542-.1096-.0964-.1892-.0842-.159-.2091-.3812-.3772-.6453-.3354-.5271-.8475-1.2287-1.5579-1.9315-1.4254-1.41023-3.6681-2.84-6.8632-2.84zm-7.09658 6.4045c-.09907-.1557-.17946-.2925-.24183-.404.06238-.1115.14278-.2484.24187-.4041.28818-.4528.72995-1.0579 1.34018-1.6616 1.21592-1.203 3.08186-2.38641 5.75636-2.38641s4.5404 1.18341 5.7564 2.38641c.6102.6037 1.052 1.2088 1.3401 1.6616.0991.1557.1795.2926.2419.4041-.0624.1115-.1427.2483-.2418.404-.2882.4527-.73 1.0576-1.3402 1.6612-1.2159 1.2027-3.0819 2.3859-5.7564 2.3859s-4.54051-1.1832-5.75642-2.3859c-.61022-.6036-1.05198-1.2085-1.34016-1.6612zm4.94438-.4039c0-1.1759.9636-2.1291 2.1522-2.1291s2.1522.9532 2.1522 2.1291c0 1.1758-.9636 2.129-2.1522 2.129s-2.1522-.9532-2.1522-2.129zm2.1522-3.6775c-2.0531 0-3.7174 1.6465-3.7174 3.6775 0 2.0309 1.6643 3.6774 3.7174 3.6774 2.053 0 3.7174-1.6465 3.7174-3.6774 0-2.031-1.6644-3.6775-3.7174-3.6775z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
