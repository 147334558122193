/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function ProgressBar({ height = 4, progress }) {
  return (
    <div
      sx={{
        height,
        position: 'relative',
        borderRadius: 'round',
        bg: 'blueberry.10',
      }}
    >
      <div
        sx={{
          height,
          width: progress,
          maxWidth: '100%',
          bg: 'blueberry.100',
          borderRadius: 'round',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
}
