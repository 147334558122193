export const getStats = state => {
  return state?.stats?.items || { signal: [], totals: {}, length: [] };
  // console.log(state)
  // const data = [
  //   {
  //       "read": 5,
  //       "recommended": 0,
  //       "bucket": "2013"
  //   },
  //   {
  //       "read": 4,
  //       "recommended": 0,
  //       "bucket": "2014"
  //   },
  //   {
  //       "read": 7,
  //       "recommended": 0,
  //       "bucket": "2015"
  //   },
  //   {
  //       "read": 6,
  //       "recommended": 2,
  //       "bucket": "2016"
  //   },
  //   {
  //       "read": 13,
  //       "recommended": 2,
  //       "bucket": "2017"
  //   },
  //   {
  //       "read": 9,
  //       "recommended": 1,
  //       "bucket": "2018"
  //   },
  //   {
  //       "read": 0,
  //       "recommended": 0,
  //       "bucket": "2019"
  //   },
  //   {
  //       "read": 22,
  //       "recommended": 8,
  //       "bucket": "2020"
  //   },
  //   {
  //       "read": 5,
  //       "recommended": 4,
  //       "bucket": "2021"
  //   }
  // ];
  // return data;
};
