import React from 'react';

import Svg from '../components/Svg';

export default function BadgeSnow({ ...props }) {
  return (
    <Svg height="8" viewBox="0 0 8 8" width="8" {...props}>
      <path
        clipRule="evenodd"
        d="m7.78204 1.22436c.29062.29914.29061.78414-.00002 1.08327l-4.34108 4.46803c-.29062.29911-.76178.29912-1.05241.00001l-2.170539-2.23391c-.2906362-.29913-.2906572-.78412-.000047-1.08327.290609-.29915.761802-.29917 1.052436-.00005l1.64434 1.69235 3.81489-3.92645c.29063-.299129.76182-.299118 1.05243.00002z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
