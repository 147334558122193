/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
/* eslint-disable-next-line */
import { keyframes } from '@emotion/react';

const loaderScale = keyframes`
  0% {
    transform: scale3d(0, 0, 0);
    opacity: 0; 
  }
  50% {
    opacity: 1; 
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 0; 
  } 
`;

const Loader = ({
  borderWidth = 3,
  color = 'blackberry',
  duration = 1,
  size = 32,
}) => (
  <div
    sx={{
      color,

      width: size,
      height: size,

      borderRadius: '50%',
      position: 'relative',

      '> span:first-of-type': {
        borderRadius: '50%',
        boxShadow: `0 0 0 ${borderWidth}px currentColor`,

        width: size,
        height: size,

        opacity: 0,
        animation: `${loaderScale} ${duration}s ease-out infinite`,
        animationDelay: `${duration / 2}s`,

        position: 'absolute',
        top: 0,
        left: 0,
      },

      '> span:last-of-type': {
        borderRadius: '50%',
        border: `${borderWidth}px solid currentColor`,

        width: size,
        height: size,

        opacity: 0,
        animation: `${loaderScale} ${duration}s ease-out infinite`,

        position: 'absolute',
        top: 0,
        left: 0,
      },
    }}
  >
    <span />
    <span />
  </div>
);

export default Loader;
