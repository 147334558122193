import React from 'react';

import Svg from '../../components/Svg';

export default function SunIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14 5c.4322 0 .7826.35039.7826.78261v1.46739c0 .43222-.3504.78261-.7826.78261s-.7826-.35039-.7826-.78261v-1.46739c0-.43222.3504-.78261.7826-.78261zm0 5.3804c-1.999 0-3.6195 1.6206-3.6195 3.6196s1.6205 3.6196 3.6195 3.6196c1.9991 0 3.6196-1.6206 3.6196-3.6196s-1.6205-3.6196-3.6196-3.6196zm-5.18475 3.6196c0-2.8635 2.32135-5.18478 5.18475-5.18478 2.8635 0 5.1848 2.32128 5.1848 5.18478s-2.3213 5.1848-5.1848 5.1848c-2.8634 0-5.18475-2.3213-5.18475-5.1848zm-.07239-6.36391c-.30563-.30563-.80115-.30563-1.10678 0-.30563.30562-.30563.80114 0 1.10677l1.0376 1.0376c.30563.30564.80115.30564 1.10678 0 .30564-.30562.30564-.80114 0-1.10677zm-3.74286 6.36391c0-.4322.35039-.7826.78261-.7826h1.46739c.43222 0 .78261.3504.78261.7826s-.35039.7826-.78261.7826h-1.46739c-.43222 0-.78261-.3504-.78261-.7826zm4.78046 5.3264c.30564-.3056.30564-.8011 0-1.1068-.30563-.3056-.80115-.3056-1.10678 0l-1.0376 1.0376c-.30563.3057-.30563.8012 0 1.1068.30563.3057.80115.3057 1.10678 0zm4.21954.641c.4322 0 .7826.3504.7826.7826v1.4674c0 .4322-.3504.7826-.7826.7826s-.7826-.3504-.7826-.7826v-1.4674c0-.4322.3504-.7826.7826-.7826zm5.3264-1.7478c-.3056-.3056-.8011-.3056-1.1068 0-.3056.3057-.3056.8012 0 1.1068l1.0376 1.0376c.3057.3057.8012.3057 1.1068 0 .3056-.3056.3056-.8011 0-1.1068zm.641-4.2196c0-.4322.3504-.7826.7826-.7826h1.4674c.4322 0 .7826.3504.7826.7826s-.3504.7826-.7826.7826h-1.4674c-.4322 0-.7826-.3504-.7826-.7826zm.3966-5.25714c.3056-.30563.3056-.80115 0-1.10677-.3056-.30563-.8011-.30563-1.1068 0l-1.0376 1.0376c-.3056.30563-.3056.80115 0 1.10677.3057.30564.8012.30564 1.1068 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
