/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import { appStore2x } from '../../../assets';
import { APP_STORE_URL, POST_ROUTE } from '../../../constants';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Oku now available for iOS / Blog">
      <time>Apr 11, 2022</time>
      <h1>Oku now available for iOS</h1>
      {/* <h1>Get Oku on your iOS device</h1> */}

      <a href={APP_STORE_URL} target="_blank" rel="noreferrer noopener">
        <Image
          src={appStore2x}
          src2x={appStore2x}
          alt="Oku is now available for iOS!"
        />
      </a>

      {/* todo: add qr code */}

      <p>
        If you&apos;re looking to track your reading, organise your book
        highlights &amp; collections, and more all from your phone, there has
        never been a better time! The same Oku you know and love is{' '}
        <Link href={APP_STORE_URL} target="_blank" rel="noreferrer">
          now available for download
        </Link>{' '}
        on iPhone and iPad.
      </p>
      <p>
        Give it a go and if you&apos;re feeling it,{' '}
        <Link
          href="https://twitter.com/okuclub"
          rel="noreferrer"
          target="_blank"
        >
          tweet us
        </Link>{' '}
        a screenshot of Oku on your home screen ❤️
      </p>

      <h2>Using Android?</h2>
      <p>
        Android users can still{' '}
        <Link href={POST_ROUTE.InstallReadngPost}>install the web app</Link>{' '}
        onto your homescreen! There are less Android users on Oku so it made
        sense for us to focus on iOS first, but you should{' '}
        <Link
          href="https://twitter.com/okuclub"
          rel="noreferrer"
          target="_blank"
        >
          let us know
        </Link>{' '}
        if an Android app is important to you.
      </p>
    </BlogPageWrap>
  );
}
