/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import Button from '../components/Button';
import CopyButton from '../components/CopyButton';
import InfoBlock from '../components/InfoBlock';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeadingText from '../components/ModalHeadingText';
import ModalImage from '../components/ModalImage';
import ProgressBar from '../components/ProgressBar';

import {
  getInviteCode,
  getAcceptedInvites,
  getTotalBooksCount,
  getUsername,
  getToken,
  getTier,
  shouldShowUpsells,
} from '../selectors';

import { getInviteRoute } from '../constants';
import { invitesHeader, invitesHeader2x } from '../assets';
import { ShareIcon, GiftIcon } from '../icons';
import { shouldFetchInvites } from '../ducks/invites';
import ModalLoading from './ModalLoading';

export default function InviteUsers({ onClose }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const inviteCode = useSelector(getInviteCode);
  const acceptedInvites = useSelector(getAcceptedInvites);
  const totalBooksCount = useSelector(getTotalBooksCount);
  const username = useSelector(getUsername);
  const inviteRoute = getInviteRoute(inviteCode, username);
  const tier = useSelector(getTier);
  const showUpsell = useSelector(shouldShowUpsells);

  useEffect(() => {
    shouldFetchInvites({ token, dispatch });
  }, []);

  const loaded = inviteCode !== undefined && tier !== undefined;

  if (!loaded) {
    return <ModalLoading />;
  }

  const showInfoBlock = showUpsell && acceptedInvites <= 3;

  const openNativeShare = () => {
    mixpanel.track('Invite Modal: Open Share');
    navigator
      .share({
        url: inviteRoute,
      })
      .catch(() => {});
  };

  return (
    <ModalBody canGrow={false}>
      <ModalImage
        alt="Animals enjoying books"
        height={170}
        src={invitesHeader}
        src2x={invitesHeader2x}
        width={476}
      />
      {totalBooksCount > 2 ? (
        <React.Fragment>
          <ModalContent extraStyles={sx.body}>
            <ModalHeadingText
              heading="Bring your friends"
              text=" Inviting a friend helps Oku and makes it a nicer place for the both of you. After all, books are better with friends."
            />
            {showInfoBlock && (
              <div sx={sx.section}>
                <InfoBlock
                  heading="Earn a month of Oku Premium!"
                  icon={GiftIcon}
                  paragraph={<InfoPara referred={acceptedInvites} />}
                />
              </div>
            )}
          </ModalContent>
          <ModalFooter direction="vertical">
            {!!window.navigator.share && (
              <Button variant="light" sx={sx.button} onClick={openNativeShare}>
                <ShareIcon />
                Share your link
              </Button>
            )}
            <CopyButton
              variant="light"
              text={inviteRoute}
              eventName="Invite Modal: Copy Link"
            >
              Copy your link
            </CopyButton>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ModalContent extraStyles={sx.body}>
            <ModalHeadingText
              heading="Books are better with friends"
              text="Add at least 3 books to your collections before inviting friends – set an example!"
            />
          </ModalContent>
          <ModalFooter direction="vertical">
            <Button variant="light" onClick={onClose}>
              Ok will do
            </Button>
          </ModalFooter>
        </React.Fragment>
      )}
    </ModalBody>
  );
}

const InfoPara = ({ referred }) => {
  const percentage = (referred / 3) * 100;
  return (
    <div>
      <p sx={sx.infoPara}>
        Refer <span>3</span> of your friends with your unique link and get a
        month of premium on us.
      </p>
      <ProgressBar progress={`${percentage}%`} />
    </div>
  );
};

const sx = {
  section: {
    marginTop: 'l',
  },
  body: {
    textAlign: 'center',
  },
  button: {
    position: 'relative',
    svg: {
      position: 'absolute',
      top: 9,
      left: 9,
    },
  },
  infoPara: {
    marginBottom: 's',
    span: {
      fontWeight: 'button',
      color: 'blueberry.100',
    },
  },
};
