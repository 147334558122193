/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import Heading from '../components/Heading';
import Text from '../components/Text';
import FullPageError from './Error';

import { SEARCH_ROUTE } from '../constants';
import { getIsAuthenticated } from '../selectors';

export default function NotFound() {
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    window.plausible('404-page');
  }, []);

  return (
    <FullPageError title="404 - Not found">
      <Heading fontFamily="serif" variant="h1" as="h1">
        How did you get here?
      </Heading>
      <Text variant="body" as="p" sx={sx.text}>
        We couldn&apos;t find whatever it is you were looking for.
        <br sx={sx.br} /> Sorry about that.{' '}
        {isAuthenticated && 'Maybe try searching instead?'}
      </Text>
      {isAuthenticated && (
        <Button as={Link} to={SEARCH_ROUTE} sx={sx.button}>
          Find a book
        </Button>
      )}
    </FullPageError>
  );
}

const sx = {
  text: {
    maxWidth: 420,
    textAlign: 'center',
    marginTop: 'xs',
  },
  button: {
    marginTop: 'm',
  },
  br: {
    display: ['none', 'none', 'block', 'block', 'block'],
  },
};
