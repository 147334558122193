/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from '../../components/Text';

export default function Legend({ items }) {
  return (
    <div sx={sx.legend}>
      {items.map(item => (
        <Item title={item.title} color={item.color} key={item.title} />
      ))}
    </div>
  );
}

function Item({ title, color }) {
  return (
    <div sx={sx.item}>
      <div sx={{ ...sx.color, bg: color }} />
      <Text sx={sx.text}>{title}</Text>
    </div>
  );
}

const sx = {
  legend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  color: {
    width: [16, 16, 16, 18],
    height: [16, 16, 16, 18],
    borderRadius: 'badge',
    display: 'block',
    marginRight: ['xxs', 'xxs', 'xxs', 'xs'],
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: ['xs', 'xs', 'xs', 's'],
    '&:last-child': {
      marginRight: 0,
    },
  },
  text: {
    color: 'blueberry.100',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
  },
};
