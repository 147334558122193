import { breakpoints, radii, space, sizes } from './sizes';
import { colors } from './colors';
import { fonts, fontSizes, fontWeights, lineHeights } from './typography';
import { shadows } from './shadows';
import { styles, text, links, buttons, forms } from './variants';
import { easing, transitions } from './transitions';
import { zIndices } from './zIndices';

export default {
  breakpoints,
  buttons,
  colors,
  easing,
  fonts,
  fontSizes,
  fontWeights,
  forms,
  lineHeights,
  links,
  radii,
  shadows,
  sizes,
  space,
  styles,
  text,
  transitions,
  zIndices,
};
