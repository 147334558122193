/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { SwiperSlide } from 'swiper/react';

import BookFeedItem from '../Activity/BookFeedItem';
import SwiperContainer from '../../components/SwiperContainer';
import SwiperGrid from '../../components/SwiperGrid';
import SwiperHeading from '../../components/SwiperHeading';

import { formatDate } from '../../utils';
import { getBookTitle } from '../../selectors';

import {
  getBookReviewRoute,
  getBookRoute,
  getProfileRoute,
  SWIPER_REVIEWS_GRID,
  SWIPER_REVIEWS_NEXT,
  SWIPER_REVIEWS_PREV,
} from '../../constants';

export default function SearchBody({ reviews }) {
  if (reviews?.length === 0) {
    return null;
  }
  return (
    <SwiperContainer>
      <SwiperHeading
        heading="Latest reviews"
        prevClassName={SWIPER_REVIEWS_PREV}
        nextClassName={SWIPER_REVIEWS_NEXT}
      />
      <SwiperGrid
        gridClassName={SWIPER_REVIEWS_GRID}
        prevClassName={SWIPER_REVIEWS_PREV}
        nextClassName={SWIPER_REVIEWS_NEXT}
      >
        {reviews.map(item => {
          const { review, book } = item;
          const { reviewed_by } = review;
          const profileRoute = getProfileRoute(reviewed_by.username);
          const bookRoute = getBookRoute(book.slug);
          const date = formatDate(review.added_at);
          const link = getBookReviewRoute({
            bookId: book.id,
            reviewId: review.id,
          });
          const title = getBookTitle({
            title: book.title,
            subtitle: book.subtitle,
          });
          return (
            <SwiperSlide key={review.id}>
              <BookFeedItem
                action="Reviewed"
                avatar={reviewed_by.image}
                bookRoute={bookRoute}
                containsSpoiler={review.contains_spoiler}
                cover={book.imageLinks.thumbnail}
                date={date}
                firstName={reviewed_by.firstname || reviewed_by.username}
                link={link}
                profileRoute={profileRoute}
                review={review.text}
                title={title}
              />
            </SwiperSlide>
          );
        })}
      </SwiperGrid>
    </SwiperContainer>
  );
}
