/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import { StarIcon } from '../icons';

export default function RecommendedText() {
  return (
    <div sx={sx.recommended}>
      <div>
        <StarIcon />
      </div>
      <span>Recommended</span>
    </div>
  );
}

const sx = {
  recommended: {
    color: 'kiwi.100',
    fontWeight: 'heading',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
