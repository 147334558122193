import {
  MenuFeedIcon,
  MenuHighlightsIcon,
  MenuHomeIcon,
  MenuMoonIcon,
  MenuProfileIcon,
  MenuSearchIcon,
} from '../icons';

import { APP_URL } from './urls';

export const ACTIVITY_EVERYBODY_ROUTE = '/activity/everybody';
export const ACTIVITY_FRIENDS_ROUTE = '/activity/friends';
export const ACTIVITY_ROUTE = '/activity';
export const BOOK_ROUTE = '/book';
export const BOOK_REVIEWS_ROUTE = '/reviews';
export const BOOK_HIGHLIGHTS_ROUTE = '/book/highlights';
export const HOME_ROUTE = '/dashboard';
export const NOT_FOUND_ROUTE = '/404';
export const PROFILE_ROUTE = '/user';
export const COLLECTIONS_ROUTE = '/collections';
export const PROFILE_REVIEWS_ROUTE = '/reviews';
export const PROFILE_HIGHLIGHTS_ROUTE = '/highlights';
export const REQUEST_ACCESS_ROUTE = '/request-access';
export const SEND_FORGOT_PASSWORD_ROUTE = '/reset-password';
export const SENT_FORGOT_PASSWORD_ROUTE = '/password-reset-sent';
export const SET_FORGOT_PASSWORD_ROUTE = '/reset-password/:token';
export const ROOT_ROUTE = '/';
export const PRIVACY_ROUTE = '/privacy';
export const TERMS_ROUTE = '/terms';
export const SUPPORT_ROUTE = '/support';
export const SEARCH_LEGACY_ROUTE = '/search';
export const SEARCH_ROUTE = '/explore';
export const SEARCH_BOOKS_ROUTE = `${SEARCH_ROUTE}/books`;
export const SEARCH_USERS_ROUTE = `${SEARCH_ROUTE}/users`;
export const SETTINGS_ROUTE = '/settings';
export const SIGN_IN_ROUTE = '/signin';
export const SIGN_OUT_ROUTE = '/signout';
export const SIGN_UP_ROUTE = '/join';
export const PRICING_ROUTE = '/pricing';
export const READING_GOAL_ROUTE = '/reading-goal';
export const STATS_ROUTE = '/stats';
export const HIGHLIGHTS_ROUTE = '/highlights';

export const BOOK_ROUTE_PARAMS = `${BOOK_ROUTE}/:slug`;
export const BOOK_REVIEWS_ROUTE_PARAMS = `${BOOK_REVIEWS_ROUTE}/:slug`;
export const BOOK_HIGHLIGHTS_ROUTE_PARAMS = `${BOOK_HIGHLIGHTS_ROUTE}/:slug`;
export const BOOK_HIGHLIGHT_ROUTE_PARAMS = `${BOOK_HIGHLIGHTS_ROUTE}/:slug/:id`;
export const REVIEW_ROUTE_PARAMS = `${BOOK_REVIEWS_ROUTE_PARAMS}/:reviewId`;
export const PROFILE_ROUTE_PARAMS = `${PROFILE_ROUTE}/:username`;
export const COLLECTIONS_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}${COLLECTIONS_ROUTE}`;
export const COLLECTION_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}/collection/:slug`;
export const INVITE_JOIN_ROUTE_PARAMS = `/join/:inviteCode`;
export const HIGHLIGHT_ROUTE_PARAMS = `${HIGHLIGHTS_ROUTE}/:bookId`;

export const FOLLOWING_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}/following`;
export const FOLLOWERS_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}/followers`;
export const PROFILE_REVIEWS_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}/reviews`;
export const PROFILE_HIGHLIGHTS_ROUTE_PARAMS = `${PROFILE_ROUTE_PARAMS}/highlights`;
export const READING_GOAL_ROUTE_PARAMS = `${READING_GOAL_ROUTE}`;
export const STATS_ROUTE_PARAMS = `${STATS_ROUTE}`;
export const PROFILE_HIGHLIGHTS_BOOK_ROUTE_PARAMS = `${PROFILE_HIGHLIGHTS_ROUTE_PARAMS}/:slug`;

// Blog
export const BLOG_ROUTE = '/blog';

export const POST_ROUTE = {
  DeepWorkPost: `${BLOG_ROUTE}/2021/deep-work-on-the-rise`,
  GoodreadsImporterPost: `${BLOG_ROUTE}/2021/importing-your-data-from-goodreads`,
  InstallReadngPost: `${BLOG_ROUTE}/2021/how-to-install-on-a-mobile-device`,
  IntroducingOkuPost: `${BLOG_ROUTE}/2021/introducing-oku`,
  NewLookPost: `${BLOG_ROUTE}/2021/a-new-look-and-a-sneak-peek`,
  OkuForIosPost: `${BLOG_ROUTE}/oku-now-available-for-ios`,
  PremiumLaunchPost: `${BLOG_ROUTE}/premium-membership-is-here`,
  PriceDropPost: `${BLOG_ROUTE}/oku-premium-is-better-value-than-ever`,
  ReviewsPost: `${BLOG_ROUTE}/say-hello-to-book-reviews`,
  RssFeedsPost: `${BLOG_ROUTE}/oku-has-rss-feeds`,
  ReferralProgramPost: `${BLOG_ROUTE}/oku-referral-reward-program`,
};

// Utils
export const getProfileRoute = username => {
  return username && `${PROFILE_ROUTE}/${username}`;
};

export const getProfileFollowingRoute = username => {
  return `${PROFILE_ROUTE}/${username}/following`;
};

export const getProfileFollowersRoute = username => {
  return `${PROFILE_ROUTE}/${username}/followers`;
};

export const getCollectionsRoute = username => {
  return `${getProfileRoute(username)}${COLLECTIONS_ROUTE}`;
};

export const getProfileReviewsRoute = username => {
  return `${getProfileRoute(username)}${PROFILE_REVIEWS_ROUTE}`;
};

export const getProfileHighlightsRoute = username => {
  return `${getProfileRoute(username)}${PROFILE_HIGHLIGHTS_ROUTE}`;
};

export const getProfileHighlightsBooksRoute = (username, slug) => {
  return `${getProfileRoute(username)}${PROFILE_HIGHLIGHTS_ROUTE}/${slug}`;
};

// TODO: hook up the username when we make it part of people's profiles
/* eslint-disable-next-line */
export const getProfileStatsRoute = username => {
  return STATS_ROUTE;
};

export const getReadingGoalRoute = () => {
  return READING_GOAL_ROUTE;
};

export const getBookRoute = slug => {
  return `${BOOK_ROUTE}/${slug}`;
};

export const getBookReviewsRoute = slug => {
  return `${BOOK_REVIEWS_ROUTE}/${slug}`;
};

export const getHighlightRoute = ({ bookSlug, highlightId }) => {
  return `${BOOK_HIGHLIGHTS_ROUTE}/${bookSlug}/${highlightId}`;
};

export const getBookHighlightsRoute = slug => {
  return `${BOOK_HIGHLIGHTS_ROUTE}/${slug}`;
};

export const getBookReviewRoute = ({ bookId, reviewId }) =>
  `/reviews/${bookId}/${reviewId}`;

export const getCollectionRoute = (username, { slug, key }) => {
  return `${getProfileRoute(username)}/collection/${key || slug}`;
};

export const getReadingRoute = username => {
  return username && `${getProfileRoute(username)}/collection/reading`;
};

export const getReadRoute = username => {
  return username && `${getProfileRoute(username)}/collection/read`;
};

export const getToReadRoute = username => {
  return username && `${getProfileRoute(username)}/collection/to-read`;
};

export const getReviewRoute = (slug, reviewId) => {
  return `${getBookReviewsRoute(slug)}/${reviewId}`;
};

export const getPersonalHighlightRoute = id => `${HIGHLIGHTS_ROUTE}/${id}`;

export const getMenuRoutes = (username, isDark, hasHighlights) => {
  const routes = [
    {
      id: 1,
      to: HOME_ROUTE,
      name: 'Home',
      icon: MenuHomeIcon,
    },
    {
      id: 2,
      to: ACTIVITY_ROUTE,
      name: 'Feed',
      icon: isDark ? MenuMoonIcon : MenuFeedIcon,
    },
    {
      id: 3,
      to: SEARCH_ROUTE,
      name: 'Explore',
      icon: MenuSearchIcon,
    },
    {
      id: 4,
      to: getProfileRoute(username),
      name: 'Profile',
      icon: MenuProfileIcon,
    },
  ];
  if (hasHighlights) {
    const index = 3;
    routes.splice(index, 0, {
      id: 5,
      to: HIGHLIGHTS_ROUTE,
      icon: MenuHighlightsIcon,
      name: 'Highlights',
    });
  }
  return routes;
};

export const getInviteRoute = (inviteCode, username) =>
  `${APP_URL}${SIGN_UP_ROUTE}?invitedBy=${username}&inviteCode=${inviteCode}`;
