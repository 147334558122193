import React from 'react';

import Svg from '../../components/Svg';

export default function StarIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m13.3022 6.23624c.2229-.15384.4874-.23624.7582-.23624.2709 0 .5353.08239.7583.23624.221.15254.3908.36817.4874.61865l1.5119 3.80751 4.0493.2628c1.2485.081 1.6773 1.6423.7659 2.4009l-3.112 2.5902 1.0041 3.95c.2871 1.1295-.9341 2.169-2.0099 1.4874l-3.455-2.189-3.2043 2.0301c-1.1664.7391-2.50017-.3858-2.18613-1.6212l.92972-3.6573-3.11201-2.5902c-.91144-.7586-.48264-2.3199.76589-2.4009l4.04933-.2628 1.5119-3.80751c.0965-.25048.2664-.46611.4874-.61865zm.667 12.87066.4014-.6336-.4028.6328c.0005.0003.0009.0006.0014.0008zm.8041-1.2662c.0003.0002-.0002-.0002 0 0l3.2057 2.0309-.9316-3.6643c-.0001-.0005-.0002-.0011-.0004-.0016-.0635-.2485-.0558-.5099.0223-.7543.0783-.2449.2241-.4627.4207-.6284l.0035-.003 2.9098-2.4218-3.7909-.246c-.2536-.0181-.4969-.1079-.7014-.2589-.2036-.1503-.3606-.3552-.4527-.5908l-1.3979-3.5201-1.3978 3.5201c-.0922.2356-.2491.4405-.4527.5908-.2046.151-.4478.2408-.7014.2589l-.0049.0003-3.78602.2457 2.90972 2.4218.0036.003c.1966.1657.3424.3835.4207.6284.0781.2445.0858.506.0222.7546-.0002.0004-.0003.0009-.0004.0013l-.9315 3.6643 3.2056-2.0309c.0003-.0001-.0002.0001 0 0 .2129-.1354.4606-.2077.7129-.2077s.5001.0724.7129.2077zm-.6203 1.2654c-.0004.0003-.0009.0006-.0013.0008z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
