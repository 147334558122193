import React from 'react';

import Svg from '../components/Svg';

export default function MoonIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m13.425 13.4193c-2.092-.993-3.426-3.103-3.426-5.41998 0-2.316 1.334-4.426 3.426-5.42.35-.164.575-.517.575-.904 0-.388-.225-.739999-.576-.904999-3.36-1.592-7.377-.657-9.69 2.255999-2.312 2.913-2.312 7.03598 0 9.94998 2.313 2.912 6.33 3.847 9.69 2.255.35-.164.575-.517.575-.904 0-.388-.225-.74-.576-.905z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
