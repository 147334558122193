/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';

export default function PasswordResetSent() {
  const history = useHistory();
  const goBack = () => history.goBack();
  return (
    <div sx={sx.flex}>
      <Button onClick={goBack}>Go back</Button>
    </div>
  );
}

const sx = {
  flex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};
