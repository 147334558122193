export * from './colorMode';
export * from './routes';
export * from './urls';
export * from './swiper';

export const AUTH_TOKEN = 'READNG_AUTH_TOKEN';
export const AUTH_CACHE_KEY = '_RDNG_AUTH_CACHE';

export const APP_STORE_URL = 'https://apps.apple.com/us/app/oku/id1604015977';
export const SUPPORT_EMAIL = 'team@oku.club';
export const TWITTER_LINK = 'https://twitter.com/okuclub';
export const FACEBOOK_LINK = 'https://www.facebook.com/Oku-107500838225631';

export const getSupportEmailLink = () => `mailto:${SUPPORT_EMAIL}`;

export const FEEDBACK_LINKS = [
  {
    className: 'nolt-button',
    href: 'https://oku.nolt.io',
    name: 'Feature requests',
  },
  {
    className: 'roadmap-button',
    name: 'Changelog',
    href: 'https://oku.nolt.io/roadmap',
  },
  {
    name: 'Get in touch',
    href: getSupportEmailLink(),
  },
];

export const LANGUAGE_PREF = 'READNG_LANG_PREF';
// ISO 639-1
export const LANGUAGES = {
  ar: 'اللغة العربية',
  bn: 'বাংলা',
  cs: 'Český',
  de: 'Deutsch',
  dk: 'Dansk',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  hi: 'हिन्दी',
  it: 'Italiano',
  id: 'Bahasa Indonesia',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  no: 'Norsk',
  pt: 'Português',
  ru: 'Русский',
  sv: 'Svenska',
  tr: 'Türkçe',
};

export const VISIBILITY = {
  HIDDEN: 'hidden',
  PUBLIC: 'public',
};

export const MARKDOWN_ALLOWED_TYPES = [
  'text',
  'root',
  'break',
  'paragraph',
  'emphasis',
  'strong',
  'list',
  'listItem',
];

export const TIERS = {
  FREE: 'free',
  PREMIUM: 'premium',
  SUPPORTER: 'supporter',
};

export const FEED_ITEM_TYPES = {
  REVIEW: 'addedreview',
};

// Card
export const COVER_WIDTH = 128;
export const COVER_WIDTH_MOBILE = 100;
export const COVER_HEIGHT = 152;

// Payments & Pricing
export const PROCESSOR_PADDLE = 'paddle';
export const PROCESSOR_APPLE = 'apple';
