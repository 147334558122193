/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Link from './Link';
import ModalDialog from './ModalDialog';
import ShareBookModal from '../modals/ShareBook';

import { StarIcon, ShareSmallIcon } from '../icons';
import { getProfileRoute, getReviewRoute } from '../constants';

export default function BookReviewHeaderDesktop({
  author,
  authorUsername,
  bookSlug,
  bookThumbnail,
  bookTitle,
  date,
  id,
  openDeleteModal,
  openEditModal,
  ownReview,
  recommended,
}) {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const profileRoute = getProfileRoute(authorUsername);
  const reviewRoute = getReviewRoute(bookSlug, id);
  const shareQuote = `Great review of ${bookTitle} on Oku`;
  const location = window.location;
  const shareUrl = `${location.origin}${reviewRoute}`;

  return (
    <header sx={sx.header}>
      <div sx={sx.headerLeft}>
        {recommended && (
          <div sx={sx.recommended}>
            <div>
              <StarIcon />
            </div>
            <span>Recommended</span>
            {author && (
              <span sx={sx.blueberry}>
                &nbsp;by{' '}
                <Link as={RouterLink} variant="primary" to={profileRoute}>
                  {author}
                </Link>
              </span>
            )}
          </div>
        )}
        {!recommended && author && (
          <div sx={sx.author}>
            Reviewed by{' '}
            <Link as={RouterLink} variant="primary" to={profileRoute}>
              {author}
            </Link>
          </div>
        )}
        <div sx={sx.date}>
          {(recommended || author) && <div sx={sx.headerDot}>·</div>}
          <Link as={RouterLink} variant="secondary" to={reviewRoute}>
            {date}
          </Link>
        </div>
      </div>
      <div sx={sx.links}>
        {ownReview && (
          <React.Fragment>
            <Link onClick={openEditModal} sx={sx.editLink} variant="primary">
              Edit
            </Link>
            <div sx={sx.linksDot}>·</div>
            <Link
              onClick={openDeleteModal}
              sx={sx.deleteLink}
              variant="primary"
            >
              Delete
            </Link>
            <div sx={sx.linksDot}>·</div>
          </React.Fragment>
        )}
        <Link sx={sx.shareLink} variant="primary" onClick={openShareModal}>
          <ShareSmallIcon /> Share
        </Link>
        <ModalDialog isVisible={isShareModalOpen} onClose={closeShareModal}>
          <ShareBookModal
            heading="Share this review"
            onClose={closeShareModal}
            quote={shareQuote}
            shareUrl={shareUrl}
            subheading="Share this Oku review with others who might find it insightful!"
            thumbnail={bookThumbnail}
            title={bookTitle}
          />
        </ModalDialog>
      </div>
    </header>
  );
}

const sx = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  headerLeft: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '16px',
    minWidth: 0,
    paddingRight: 'xs',
  },
  recommended: {
    color: 'kiwi.100',
    fontWeight: 'heading',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerDot: {
    paddingX: 'xxs',
    color: 'blueberry.100',
    display: 'inline-block',
  },
  linksDot: {
    paddingX: ['xxs', 'xxs', 'xxs', 'xs'],
    color: 'blueberry.100',
    display: 'inline-block',
  },
  author: {
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    fontWeight: 'heading',
    color: 'blueberry.100',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  blueberry: {
    color: 'blueberry.100',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    fontWeight: 'heading',
    color: 'blueberry.80',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  shareLink: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    svg: {
      marginRight: 'xxs',
    },
  },
  links: {
    whiteSpace: 'nowrap',
    lineHeight: '16px',
    display: 'flex',
  },
  editLink: {
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    cursor: 'pointer',
    color: 'blueberry.80',
  },
  deleteLink: {
    fontSize: ['xxs', 'xxs', 'xxs', 'xs'],
    color: 'strawberry.100',
    cursor: 'pointer',
    '&:hover': {
      color: 'strawberry.100',
    },
  },
};
