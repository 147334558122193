import moment from 'moment';

export const getSubscriptionDetails = state => {
  return state?.subscription?.items.details;
};

export const getSubTier = subscription => {
  return subscription?.tier;
};

export const getSubCancelled = subscription => {
  return subscription?.is_cancelled || false;
};

export const getSubRenewDate = subscription => {
  const date = subscription?.renew_date;
  return date ? moment(date) : null;
};

export const getProductPrices = state => {
  const items = state?.subscription?.items;
  if (items?.ios) {
    return items.ios;
  }
  return items.prices;
};
