/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx, Box } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import noop from 'lodash-es/noop';
import mixpanel from 'mixpanel-browser';

import Button from '../components/Button';
import GhostButton from '../components/GhostButton';
import InputWithLabel from '../components/InputWithLabel';
import Link from '../components/Link';
import ModalBody from '../components/ModalBody';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import ModalHeading from '../components/ModalHeading';
import PrimaryButton from '../components/PrimaryButton';
import Text from '../components/Text';
import TextareaWithLabel from '../components/TextareaWithLabel';
import Toggle from '../components/Toggle';

import {
  shouldCreateList,
  shouldUpdateList,
  shouldDeleteList,
} from '../ducks/lists';

import {
  getToken,
  getUsername,
  getListsLoading,
  hasFeature,
  FEATURES,
} from '../selectors';
import { TIERS, getCollectionRoute } from '../constants';
import Badge from '../components/Badge';

export default function CreateCollection({
  bookId,
  list = {},
  onClose = noop,
  onDelete = noop,
  shouldUpdate = false,
}) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const username = useSelector(getUsername);
  const isLoading = useSelector(getListsLoading);

  const { blurb, id, name, key, visibility } = list;
  const [isDeleting, setIsDeleting] = useState(false);
  const hasPrivateCollections = useSelector(
    hasFeature(FEATURES.PRIVATE_COLLECTIONS, false)
  );

  const [data, setData] = useState({
    name,
    blurb,
    visibility,
    error: {
      name: '',
      blurb: '',
    },
  });

  const onNameChange = event => {
    setData({
      ...data,
      error: {
        name: '',
        blurb: '',
      },
      name: event.target.value,
    });
  };

  const onBlurbChange = event => {
    setData({
      ...data,
      error: {
        name: '',
        blurb: '',
      },
      blurb: event.target.value,
    });
  };

  const onVisibilityChange = event => {
    const newVis = event.target.checked ? 'hidden' : 'public';
    setData({
      ...data,
      error: {
        name: '',
        blurb: '',
      },
      visibility: newVis,
    });
  };

  const onSubmit = event => {
    event.preventDefault();

    if (!data.name) {
      setData({
        ...data,
        error: {
          ...data.error,
          name: 'Please enter a name',
        },
      });
      return;
    }

    if (data.name && data.name.length >= 55) {
      setData({
        ...data,
        error: {
          ...data.error,
          name: 'The name has to be less than 1024 characters.',
        },
      });
      return;
    }

    if (data.blurb && data.blurb.length >= 1024) {
      setData({
        ...data,
        error: {
          ...data.error,
          blurb: 'The description has to be less than 1024 characters.',
        },
      });
      return;
    }

    if (shouldUpdate) {
      shouldUpdateList({ dispatch, token, id, username, ...data }).then(() => {
        toast('Consider it done.', {
          autoClose: 3500,
        });
      });
    } else {
      shouldCreateList({ dispatch, token, username, bookId, ...data }).then(
        ({ slug, name: listName }) => {
          const linkUrl = getCollectionRoute(username, { slug });
          const toastLink = (
            <Link as={RouterLink} to={linkUrl} hasUnderline variant="milk">
              {listName}.
            </Link>
          );
          toast(
            <p>
              Saved {bookId && 'to '} a new collection – {toastLink}
            </p>,
            { autoClose: 6500 }
          );
          mixpanel.track('Create Collection');
        }
      );
    }
    if (!isLoading) {
      onClose();
    }
  };

  const onDeleteCollection = event => {
    event.preventDefault();
    if (shouldUpdate) {
      shouldDeleteList({ dispatch, token, id, username, key }).then(() => {
        onDelete();
        toast('Consider it done.', { autoClose: 3500 });
      });
    }
    if (!isLoading) {
      onClose();
    }
  };

  if (isDeleting) {
    return (
      <form onSubmit={onDeleteCollection}>
        <ModalHeading extraStyles={sx.deleteHeading}>
          Do you really want to <br /> delete your collection?
        </ModalHeading>
        <ModalFooter>
          <GhostButton type="button" onClick={onClose}>
            Leave it be
          </GhostButton>
          <Button variant="strawberry.active" isLoading={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalBody canGrow={false}>
        <ModalHeading>
          {shouldUpdate ? 'Update' : 'Create a'} collection
        </ModalHeading>
        <ModalContent>
          <Box marginBottom="m">
            <InputWithLabel
              defaultValue={name}
              error={data.error.name}
              id="name"
              isDisabled={isLoading}
              label="Name"
              onChange={onNameChange}
              placeholder="Like 'All-time favorites' or 'Design Classics'"
              sx={sx.input}
              type="text"
            />
          </Box>
          <Box marginBottom="m">
            <TextareaWithLabel
              defaultValue={blurb}
              error={data.error.blurb}
              id="description"
              isDisabled={isLoading}
              label="Description (optional)"
              onChange={onBlurbChange}
              placeholder="What ties these books together?"
              sx={sx.input}
              type="text"
            />
          </Box>
          <Box sx={sx.checkbox}>
            <Text as="label" variant="body" htmlFor="visibility" sx={sx.label}>
              Keep this collection private?
            </Text>
            {!hasPrivateCollections ? (
              <Link as={RouterLink} to="/pricing" hasUnderline variant="milk">
                <Badge kind={TIERS.PREMIUM} />
              </Link>
            ) : (
              <Toggle
                checked={data.visibility === 'hidden'}
                id="visibility"
                isDisabled={isLoading}
                label="Visibility"
                onChange={onVisibilityChange}
              />
            )}
          </Box>
        </ModalContent>
        <ModalFooter>
          {shouldUpdate && (
            <Button
              isLoading={isLoading}
              onClick={() => setIsDeleting(true)}
              variant="strawberry.default"
              tabIndex="-1"
            >
              Delete
            </Button>
          )}
          <PrimaryButton tabIndex="0" isLoading={isLoading} type="submit">
            {shouldUpdate ? 'Update' : 'Create'}
          </PrimaryButton>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

const sx = {
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    paddingLeft: 'xxs',
    '&:hover': {
      color: 'blackberry',
    },
  },
  deleteHeading: {
    marginBottom: ['s', 's', 'm'],
  },
};
