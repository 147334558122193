/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

import Image from './Image';

export default function AspectImage({ aspectWidth, aspectHeight, ...props }) {
  return (
    <AspectRatio ratio={aspectWidth / aspectHeight}>
      <Image {...props} />
    </AspectRatio>
  );
}
