import React from 'react';

import Svg from '../components/Svg';

export default function CameraIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m11.4995 6.375c-.2507 0-.4849.12533-.624.33397l-1.02735 1.54103h-2.09815c-.53043 0-1.03914.21071-1.41421.58579-.37508.37507-.58579.88378-.58579 1.41421v8.75c0 .5304.21071 1.0391.58579 1.4142.37507.3751.88378.5858 1.41421.5858h12.5c.5304 0 1.0391-.2107 1.4142-.5858s.5858-.8838.5858-1.4142v-8.75c0-.53043-.2107-1.03914-.5858-1.41421-.3751-.37508-.8838-.58579-1.4142-.58579h-2.0991l-1.0273-1.54103c-.1391-.20864-.3733-.33397-.6241-.33397zm-.6259 3.04103 1.0273-1.54103h4.1973l1.0273 1.54103c.1391.20864.3733.33397.624.33397h2.5005c.1326 0 .2598.05268.3536.14645.0937.09377.1464.22095.1464.35355v8.75c0 .1326-.0527.2598-.1464.3536-.0938.0937-.221.1464-.3536.1464h-12.5c-.13261 0-.25979-.0527-.35355-.1464-.09377-.0938-.14645-.221-.14645-.3536v-8.75c0-.1326.05268-.25978.14645-.35355.09376-.09377.22094-.14645.35355-.14645h2.4995c.2508 0 .485-.12533.6241-.33397zm1.0639 4.89647c0-1.1391.9234-2.0625 2.0625-2.0625s2.0625.9234 2.0625 2.0625-.9234 2.0625-2.0625 2.0625-2.0625-.9234-2.0625-2.0625zm2.0625-3.5625c-1.9675 0-3.5625 1.595-3.5625 3.5625s1.595 3.5625 3.5625 3.5625 3.5625-1.595 3.5625-3.5625-1.595-3.5625-3.5625-3.5625z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
