/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import Heading from './Heading';

export default function ChartBox({ children, heading, legend }) {
  return (
    <React.Fragment>
      <div sx={sx.heading}>
        {heading && (
          <Heading
            variant="subtitle1"
            fontFamily="serif"
            fontSize={['m', 'm', 'm', 'l']}
          >
            {heading}
          </Heading>
        )}
        {legend}
      </div>
      <div sx={sx.wrapper}>{children}</div>
    </React.Fragment>
  );
}

const sx = {
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 'xl',
  },
  wrapper: {
    border: '1px solid',
    borderColor: 'blueberry.10',
    borderRadius: 'button',
    padding: ['l', 'l', 'l', 'xl'],
    marginTop: 'm',
    overflow: 'auto',
  },
};
