/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import CreateCollection from '../modals/CreateCollection';
import GhostButton from '../components/GhostButton';
import Heading from '../components/Heading';
import ByPerson from '../components/ByPerson';
import ListPortrait from '../components/ListPortrait';
import ModalDialog from '../components/ModalDialog';
import Page from '../components/Page';
import PageBody from '../components/PageBody';
import Text from '../components/Text';

import LoadingPage from './Loading';
import NotFound from './404';
import { GenericErrorPage } from './Error';

import {
  getIsAuthenticated,
  getListName,
  getLists,
  getProfile,
  getProfileDisplayName,
  getToken,
  getUsername,
} from '../selectors';

import { getProfileRoute, getCollectionsRoute } from '../constants';

import { PencilIcon } from '../icons';
import { pluralise } from '../utils';
import { shouldFetchProfile } from '../ducks/profile';
import { shouldFetchList } from '../ducks/lists';
import PageNavigation from '../components/PageNavigation';

export default function Collection() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const authedUsername = useSelector(getUsername);
  const { username, slug } = match.params;
  const user = useSelector(getProfile(username));
  const isAuthenticated = !!useSelector(getIsAuthenticated);
  let collectionId;

  const isKey =
    ['to-read', 'read', 'reading', 'recs', 'abandoned'].indexOf(slug) !== -1;

  if (isKey) {
    // this key is a special case that we can't split on '-''
    collectionId = slug;
  } else {
    const parts = slug.split('-');
    collectionId = parts[parts.length - 1];
  }

  let list = useSelector(getLists)[collectionId];
  if (list?.key === collectionId && username !== authedUsername) {
    // if the key matches but the user does not, we may have accidentally
    // grabbed the authedUser's to-read/read (etc) list by key
    // rather than picking the right user's list
    list = undefined;
  }
  if (!list) {
    // not found in state.lists, fallback to seaching the profile
    // ToDo: don't rely on the profile
    list = user?.lists[collectionId];
  }

  const [errorCode, setErrorCode] = useState('');

  useEffect(() => {
    shouldFetchList({
      token,
      dispatch,
      username,
      authedUsername,
      key: isKey && collectionId,
      listId: !isKey && collectionId,
    }).catch(err => {
      // Errors will only render if we have no existing collection data.
      // Either way, we set the error code here.
      if (err.status === 404) {
        return setErrorCode('notFound');
      }

      if (!window.navigator.onLine) {
        toast('Your device is offline.', { autoClose: 6500 });
        return setErrorCode('offline');
      }

      return setErrorCode('fetchFailed');
    });
  }, [token, collectionId]);

  useEffect(() => {
    if (!user) {
      shouldFetchProfile({ dispatch, username, token });
    }
  }, [token, username]);

  const openModal = () => {
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    // without a redirect on delete we'll end up with a 404
    dispatch(push(getCollectionsRoute(username)));
  };

  if (!list) {
    // assume we are already fetching or have tried already
    switch (errorCode) {
      case '':
        break;
      case 'notFound':
        return <NotFound />;
      case 'fetchFailed':
      case 'offline':
      default:
        return <GenericErrorPage code={errorCode} />;
    }

    return <LoadingPage />;
  }

  const books = list?.books;
  const blurb = list?.blurb;
  const listName = getListName(list);
  const isCurrentUser = authedUsername === username;
  const profileRoute = getProfileRoute(username);

  const image = user?.details?.image;
  const displayName = getProfileDisplayName(user) || username;
  const bookCount = pluralise({ count: books.length, word: 'book' });
  const sx = getStyles();
  return (
    <Page title={`${listName} / Collection`}>
      {isAuthenticated && (
        <PageNavigation showBackButton showShareButton>
          {isCurrentUser && !list.key && (
            <GhostButton sx={sx.button} onClick={openModal}>
              <PencilIcon />
              Edit
            </GhostButton>
          )}
        </PageNavigation>
      )}
      <PageBody extraStyles={sx.pageBody}>
        <header sx={sx.header}>
          <Heading as="h1" variant="h1" fontFamily="serif" sx={sx.heading}>
            {listName}
          </Heading>
          {blurb && (
            <Text as="p" variant="body" sx={sx.body}>
              {blurb}
            </Text>
          )}
          <div sx={sx.byline}>
            <ByPerson
              bookCount={bookCount}
              displayName={displayName}
              profileRoute={profileRoute}
              avatarUrl={image}
            />
          </div>
        </header>
        {books.length > 0 && <ListPortrait books={books} />}
      </PageBody>
      {isCurrentUser && (
        <ModalDialog onClose={closeModal} isVisible={isEditModalOpen}>
          <CreateCollection
            list={list}
            onClose={closeModal}
            onDelete={onDelete}
            shouldUpdate
          />
        </ModalDialog>
      )}
    </Page>
  );
}

const getStyles = () => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 'xl',
    textAlign: 'center',
  },
  heading: {
    paddingX: [0, 'm', 'l', 'xl'],
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    width: ['100%', '100%', '50%', '33.333%'],
    padding: 'xs',
  },
  body: {
    marginY: 'xs',
    textAlign: 'center',
    maxWidth: 520,
  },
  button: {
    bg: 'milk',
    borderRadius: 'round',
    '> span': {
      display: 'flex',
      alignItems: 'center',
      '> svg': {
        marginTop: '-2px',
        display: 'block',
        marginRight: 'xs',
      },
    },
  },
  pageBody: {
    marginBottom: ['s', 's', 'm', 'xl'],
  },
  byline: {
    marginTop: 'xs',
  },
});
