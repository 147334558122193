import React from 'react';

import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { MARKDOWN_ALLOWED_TYPES } from '../constants';

export default function MarkdownBlock({ children }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkBreaks]}
      allowedTypes={MARKDOWN_ALLOWED_TYPES}
      unwrapDisallowed
    >
      {children}
    </ReactMarkdown>
  );
}
