import React from 'react';

import Svg from '../../components/Svg';

export default function MoonIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m17.2305 5.85714c0-.47338-.3701-.85714-.8267-.85714-.4565 0-.8266.38376-.8266.85714v.47238h-.4554c-.4565 0-.8266.38375-.8266.85714 0 .47338.3701.85714.8266.85714h.4554v.47209c0 .47339.3701.85714.8266.85714.4566 0 .8267-.38375.8267-.85714v-.47209h.4555c.4565 0 .8267-.38376.8267-.85714 0-.47339-.3702-.85714-.8267-.85714h-.4555zm3.0199 2.46614c.4565 0 .8266.38376.8266.85714v1.13608h1.0963c.4565 0 .8266.3838.8266.8572s-.3701.8571-.8266.8571h-1.0963v1.1377c0 .4734-.3701.8572-.8266.8572-.4566 0-.8267-.3838-.8267-.8572v-1.1377h-1.0967c-.4565 0-.8266-.3837-.8266-.8571s.3701-.8572.8266-.8572h1.0967v-1.13608c0-.47338.3701-.85714.8267-.85714zm-8.7861-2.01077c-.1565-.0494-.3222-.04918-.4766-.00157-1.37463.39932-2.62501 1.16388-3.62734 2.21814-1.00583 1.05795-1.72677 2.37072-2.09099 3.80752-.36422 1.4367-.359 2.9474.01512 4.3814.37412 1.4341 1.10409 2.7414 2.1172 3.7919 1.0131 1.0504 2.27397 1.8073 3.65701 2.1952 1.383.3879 2.84.3933 4.2256.0157 1.3857-.3777 2.6517-1.1252 3.6721-2.1682 1.0163-1.0389 1.7535-2.3349 2.1387-3.7596.0446-.1543.0467-.3198.0041-.4773l-.0001-.0003c-.1001-.3703-.4259-.6261-.7968-.6257-.0837 0-.165.0132-.242.0377-1.1109.3158-2.2827.3223-3.3969.0184-1.1216-.3058-2.1446-.915-2.9646-1.7653-.82-.8502-1.4076-1.911-1.7025-3.0739-.2933-1.15606-.2869-2.37179.0182-3.52429.0224-.07626.0348-.15667.0358-.23938.0044-.38103-.2343-.71936-.5858-.83035zm-2.92655 3.42006c.47588-.50054 1.0218-.91928 1.61695-1.24355-.075.95477.0058 1.91978.2427 2.85368.3694 1.4565 1.1053 2.7851 2.1323 3.85 1.0271 1.0649 2.3084 1.8279 3.7131 2.211.9007.2456 1.8313.3294 2.7521.2516-.3127.6171-.7165 1.1831-1.1992 1.6765-.8146.8327-1.8254 1.4295-2.9317 1.731s-2.2694.2972-3.3736-.0125-2.11082-.9139-2.91965-1.7526c-.80884-.8387-1.39163-1.8824-1.69032-3.0273-.29868-1.1449-.30284-2.351-.01206-3.4981.29078-1.147.86635-2.1951 1.66938-3.03973z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
