/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Text } from 'theme-ui';

const StyledText = React.forwardRef((props, ref) => {
  const {
    children,
    color,
    fontFamily = 'body',
    fontSize,
    lineHeight,
    marginBottom,
    maxWidth,
    sx,
    truncated,
    variant = 'body',
    ...rest
  } = props;

  const truncatedStyles = truncated && {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const styles = {
    color,
    fontFamily,
    fontSize,
    lineHeight,
    marginBottom,
    maxWidth,
    ...truncatedStyles,
    ...sx,
  };

  return (
    <Text as="p" {...rest} variant={variant} sx={styles} ref={ref}>
      {children}
    </Text>
  );
});

export default StyledText;
