/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import CircleButton from './CircleButton';
import { ShareIcon } from '../icons';

export default function ShareButton() {
  // the location returned from useLocation doesn't contain everything we need
  const location = window.location;

  const onClick = () => {
    window.plausible('share-click');
    navigator
      .share({
        url: `${location.origin}${location.pathname}?ref=native-share`,
      })
      .catch(() => {
        window.plausible('share-cancel');
      });
  };

  // TODO: add a desktop version of this, will be useful
  if (!window.navigator.share) {
    return null;
  }

  return (
    <CircleButton onClick={onClick}>
      <ShareIcon />
    </CircleButton>
  );
}
