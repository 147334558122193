/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Highlighter from 'react-highlight-words';

export default function StyledHighlighter(props) {
  return (
    <span sx={sx.highlighter}>
      <Highlighter highlightClassName="highlighter-color" {...props} />
    </span>
  );
}

const sx = {
  highlighter: {
    '.highlighter-color': {
      bg: 'highlight',
      borderRadius: '2px',
    },
  },
};
