const GUTTER = 16;
const SIDEBAR = 224;
const CARD_MOBILE = 336;
const CARD_DESKTOP = 256;

export const breakpoints = [
  `${CARD_MOBILE + GUTTER * 2}px`,
  `${CARD_DESKTOP * 2 + GUTTER * 5}px`,
  `${SIDEBAR + CARD_DESKTOP * 2 + GUTTER * 5}px`,
  `${SIDEBAR + CARD_DESKTOP * 3 + GUTTER * 6}px`,
  `${SIDEBAR + CARD_DESKTOP * 4 + GUTTER * 7}px`,
  `${SIDEBAR + CARD_DESKTOP * 5 + GUTTER * 8}px`,
];

export const space = {
  xxxxs: 0,
  xxs: 4,
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
  xxl: 64,
  xxxl: 128,
};

export const radii = {
  book: '3px',
  badge: '4px',
  toast: '6px',
  button: '8px',
  mini: '10px',
  block: '20px',
  round: '99px',
  circle: '50%',
};

export const sizes = {
  book: CARD_DESKTOP / 2,
  sidebar: SIDEBAR,
  card: {
    mobile: CARD_MOBILE,
    desktop: CARD_DESKTOP,
  },
  page: {
    mobile: breakpoints[0],
    phablet: breakpoints[1],
    tablet: breakpoints[2],
    notebook: breakpoints[3],
    laptop: breakpoints[4],
    desktop: breakpoints[5],
  },
  maxWidth: {
    mobile: CARD_MOBILE + GUTTER * 2,
    phablet: CARD_DESKTOP * 2 + GUTTER * 5,
    tablet: CARD_DESKTOP * 2 + GUTTER * 5,
    notebook: CARD_DESKTOP * 3 + GUTTER * 6,
    laptop: CARD_DESKTOP * 4 + GUTTER * 7,
    desktop: CARD_DESKTOP * 5 + GUTTER * 8,
  },
  marketing: {
    desktop: 1196,
    laptop: 720,
    mobile: 460,
  },
};
