/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Heading from '../../components/Heading';
import Image from '../../components/Image';
import PrimaryButton from '../../components/PrimaryButton';
import Text from '../../components/Text';

import BookPageWrap from './BookPageWrap';
import { bookReviews, bookReviews2x } from '../../assets';

export default function BookEmptyState({
  buttonText,
  heading,
  onButtonClick,
  subheading,
  title,
}) {
  return (
    <BookPageWrap title={title}>
      <div sx={sx.container}>
        <Image
          alt="Floating book"
          height={56}
          respondToDarkMode
          src={bookReviews}
          src2x={bookReviews2x}
          width={82}
        />
        <Heading as="h2" variant="h1" fontFamily="serif">
          {heading}
        </Heading>
        <Text as="p" variant="body">
          {subheading}
        </Text>
        {onButtonClick && buttonText && (
          <PrimaryButton onClick={onButtonClick}>{buttonText}</PrimaryButton>
        )}
      </div>
    </BookPageWrap>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 300,
    marginX: 'auto',
    textAlign: 'center',
    paddingY: ['xl', 'xl', 'xxl', 'xxl', 'xxxl', 'xxxl', 'xxxl'],

    paddingBottom: ['xl', 'xl', 'xl', 'xl', 0],
    borderBottom: ['1px solid', '1px solid', '1px solid', '1px solid', 'none'],
    borderColor: [
      'blueberry.20',
      'blueberry.20',
      'blueberry.20',
      'blueberry.20',
    ],

    h2: {
      marginTop: 'm',
      marginBottom: 'xxs',
    },

    button: {
      marginTop: 's',
    },
  },
};
