/*  eslint-disable */
import forEach from 'lodash-es/forEach';
import toPairs from 'lodash-es/toPairs';
import { batch } from 'react-redux';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';

import { API_USER_URL, AUTH_CACHE_KEY, API_DASHBOARD_URL } from '../constants';
import { setAppError } from './app';
import { authenticate, setToken } from './auth';
import { listsById, fetchApi, callApi, listsWithoutKey } from '../utils';
import { setList } from './lists';
import { updateFormState } from './forms';
import { shouldFetchProfile, setProfile, shouldFetchFollows } from './profile';
import { getCollectionItemsThreadId } from '../selectors';
import { appendItems, setItems } from './threads';
import { getFriendsThreadId } from '../selectors/dashboard';

// Actions
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOADING = 'USER_LOADING';

// Reducer
const userReducer = (
  state = {
    isLoading: false,
    details: {},
  },
  action
) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_USER:
      cacheUserInfo({ user: action.user });
      return {
        ...state,
        details: action.user,
      };
    case UPDATE_USER:
      cacheUserInfo({ user: action.user });
      return {
        ...state,
        details: { ...state.details, ...action.user },
      };
    default:
      return state;
  }
};

// Action Creators
export const setUser = user => ({
  type: SET_USER,
  user,
});

export const updateUser = user => ({
  type: UPDATE_USER,
  user,
});

export const userLoading = () => ({
  type: USER_LOADING,
});

export const userLoaded = () => ({
  type: USER_LOADED,
});

export const cacheUserInfo = ({ user }) => {
  const info = {
    username: user.username,
    firstname: user.firstname,
    lastname: user.lastname,
    image: user.image,
    tier: user.tier,
  };
  localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(info));
};

// Side effects
export function shouldSetUser({ dispatch, token }) {
  if (token === 'null') {
    dispatch(setAppError('Missing token'));
    return Promise.reject('User is not authenticated');
  }
  return fetchApi(API_USER_URL, { token })
    .then(response => {
      if (!response.ok) {
        // we expect a 403 when user is not authed,
        // but should log them out if we get a 403 invalid token
        if (response.status === 403) {
          response
            .json()
            .then(errorData => {
              if (errorData?.detail === 'Invalid token.') {
                toast("You've been logged out");
              }
              dispatch(setUser({}));
              dispatch(setToken(null));
            })
            .catch(err => {
              console.error(err);
            });
          return Promise.reject('You are not authenticated');
        }

        dispatch(setAppError(response.statusText));
        return Promise.reject('You are not authenticated');
      }
      return response.json();
    })
    .then(({ user, collections }) => {
      if (user && collections) {
        const collectionsByKey = listsById(collections);
        batch(() => {
          dispatch(setUser({ ...user }));
          dispatch(authenticate(true));
          dispatch(
            setProfile({
              collections: listsWithoutKey(collections),
              details: user,
              lists: collectionsByKey,
            })
          );

          // avoiding setLists here as it'll wipe out any pre-existing lists
          collections.forEach(list =>
            dispatch(setList({ list, isAuthedUser: true }))
          );
        });
        shouldFetchFollows({ dispatch, username: user.username, token });
        if (user.userId) {
          mixpanel.identify(user.userId);
          mixpanel.people.set({
            // dont want to hand over their real email
            Email: `user~${user.username}@oku.club`,
            Tier: user.tier,
          });
        }
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(setAppError(error));
    });
}

export function shouldUpdateUser({
  bio,
  dispatch,
  firstName,
  lastName,
  token,
  email,
  userName,
}) {
  dispatch(updateFormState('settings', ''));
  dispatch(updateFormState('settings', '', 'username'));
  dispatch(updateFormState('settings', '', 'firstname'));
  dispatch(updateFormState('settings', '', 'lastname'));
  dispatch(updateFormState('settings', '', 'email'));

  if (!userName) {
    dispatch(updateFormState('settings', "You'll need a username", 'username'));
    return;
  }
  if (!email) {
    dispatch(
      updateFormState(
        'settings',
        'You need to enter your email address',
        'email'
      )
    );
    return;
  }

  dispatch(userLoading());

  return fetchApi(API_USER_URL, {
    body: {
      bio,
      email,
      firstname: firstName,
      lastname: lastName,
      username: userName,
    },
    method: 'PUT',
    token,
  })
    .then(async response => {
      setTimeout(() => dispatch(userLoaded()), 600);
      if (response.ok) {
        const data = await response.json();
        dispatch(updateUser(data));
        const { username } = data;
        // eslint-disable-next-line
        if (!!username) {
          shouldFetchProfile({ dispatch, username, token });
        }
        toast('Profile updated. Looking good.', {
          delay: 500,
          autoClose: 4500,
        });
      } else {
        const data = await response.json();
        // if there is a validation error the backend will try to respond with:
        // {'errors': {'username': ['Must be 3+ chars long']}}
        if (!data.errors) {
          dispatch(
            updateFormState(
              'settings',
              'Something went wrong. Please try again!'
            )
          );
          return;
        }
        forEach(toPairs(data.errors), ([fieldName, messages]) => {
          dispatch(updateFormState('settings', messages[0], fieldName));
        });
      }
    })
    .catch(() => {
      dispatch(userLoaded());
      dispatch(
        updateFormState('settings', 'Something went wrong. Please try again!')
      );
    });
}

export function shouldUpdateVisibility({ dispatch, token, visibility }) {
  return callApi('/users/me', {
    token,
    method: 'PUT',
    body: { visibility },
  }).then(details => {
    dispatch(updateUser(details));
  });
}

export function shouldUpdateAvatar({ dispatch, token, file }) {
  dispatch(updateFormState('settings', ''));

  const requestData = new FormData();
  requestData.append('image', file);

  return fetch('/users/me/avatar', {
    headers: {
      Authorization: `Token ${token}`,
    },
    method: 'post',
    body: requestData,
  })
    .then(async response => {
      if (!response.ok) {
        dispatch(
          updateFormState('settings', 'Something went wrong. Please try again!')
        );
        return {};
      }
      const respData = await response.json();
      dispatch(updateUser(respData.user));
    })
    .catch(() => {
      dispatch(
        updateFormState('settings', 'Something went wrong. Please try again!')
      );
    });
}

export function shouldRemoveAvatar({ dispatch, token }) {
  return callApi('/users/me/avatar', {
    method: 'DELETE',
    token,
  }).then(respData => {
    dispatch(updateUser(respData));
  });
}

export function fetchDashboard({ dispatch, token }) {
  return callApi(API_DASHBOARD_URL, { token }).then(data => {
    const { user, friends } = data;
    const threadItems = friends.map(({ who, list, books }) => {
      const threadId = getCollectionItemsThreadId(list.id);
      dispatch(setItems(threadId, books.results, books.next));
      return { who, threadId, listId: list.id };
    });
    const friendsThreadId = getFriendsThreadId();
    dispatch(setItems(friendsThreadId, threadItems, null));

    // todo: needs more refactoring so we can uncomment below
    // dispatch(setUser(user));
  });
}

export default userReducer;
