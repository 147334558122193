import mixpanel from 'mixpanel-browser';
import objectReducer, {
  setKey,
  SET_KEY,
  REMOVE_KEY,
  removeKey,
} from './object';
import { API_GOALS_URL } from '../constants';
import { callApi } from '../utils/api';

const SET_ANNUAL_GOAL = 'goal.set';
const UNSET_ANNUAL_GOAL = 'goal.unset';
const goalsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ANNUAL_GOAL:
      return objectReducer(state, {
        ...action,
        type: SET_KEY,
      });

    case UNSET_ANNUAL_GOAL:
      return objectReducer(state, {
        ...action,
        type: REMOVE_KEY,
      });
    default:
      return state;
  }
};

export function fetchReadingGoal({ token, dispatch }) {
  return callApi(API_GOALS_URL, { token }).then(data => {
    data.forEach(item => {
      dispatch(
        setKey({
          key: item.year,
          type: SET_ANNUAL_GOAL,
          value: item.target,
        })
      );
    });
  });
}

export function setReadingGoal({ target, token, dispatch }) {
  return callApi(API_GOALS_URL, {
    method: 'POST',
    body: { target },
    token,
  }).then(resp => {
    mixpanel.track('Goal: Set');
    dispatch(
      setKey({
        key: resp.year,
        value: resp.target,
        type: SET_ANNUAL_GOAL,
      })
    );
  });
}

export function deleteReadingGoal({ target, token, dispatch }) {
  return callApi(API_GOALS_URL, {
    method: 'POST',
    body: { target },
    token,
  }).then(() => {
    mixpanel.track('Goal: Remove');
    const year = new Date().getFullYear();
    dispatch(
      removeKey({
        key: year,
        type: UNSET_ANNUAL_GOAL,
      })
    );
  });
}

export default goalsReducer;
