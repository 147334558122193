import React from 'react';

import Svg from '../components/Svg';

export default function DotsIcon({ ...props }) {
  return (
    <Svg height="4" viewBox="0 0 16 4" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m14 4c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm-6 0c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm-6 0c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
