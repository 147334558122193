/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BlogPageWrap from '../BlogPageWrap';
import Image from '../../../components/Image';
import Link from '../BlogLink';
import Tweet from '../../Home/Tweet';
import TweetCompact from '../../Home/TweetCompact';

import { energySchedule } from '../../../assets';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Let your body set the schedule / Blog">
      <time>Mar 07, 2021</time>
      <h1>Let your body set the schedule</h1>

      <p>
        We&apos;re no productivity gurus here, but since{' '}
        <Link href="https://oku.club">Oku</Link> is a side project and everybody
        involved has a day job, we know how important it is to make the most of
        our time. That&apos;s why I wanted to share something I learnt about
        recently that just might help you produce your best work more
        consistently.
      </p>
      <p>
        You probably know about your body&apos;s circadian rhythm, a kind of
        internal clock usually regulated by Earth&apos;s day/night cycle. You
        likely have some understanding of whether you&apos;re naturally more of
        a morning person, a night owl, or neither. Your body clock has a more
        complex effect on your energy than that though, but thankfully these
        days we can benefit from from what we know about sleep science just by
        visiting our local app store.
      </p>

      <p>
        I&apos;m talking about{' '}
        <Link href="https://www.risescience.com/">Rise Sleep</Link>. Rise uses
        your sleep debt and timing to estimate your circadian rhythm and
        therefore your natural energy levels throughout the day. Let me
        illustrate by turning my energy schedule on its side, from wake up to
        night time:
      </p>

      <Image src={energySchedule} src2x={energySchedule} />

      <p>
        An important thing to notice is that my energy levels are not just up in
        the day and down at night. Instead each day has a morning peak and an
        afternoon peak, with a dip in between. My rhythm is not your rhythm, but
        this pattern of daily peaks holds true for most people, shifted earlier
        for morning people or later for night owls like me.
      </p>
      <p>
        It turns out that{' '}
        <strong>
          during the peaks people are more attentive, find it easier to focus,
          are more energetic and make better decisions.
        </strong>
        Therefore we all stand to benefit greatly if we can align our activities
        with our energy schedule.
      </p>
      <p>
        So what does this mean in practice? Peaks are when you are at your best.
        This is when you want to deliver that important presentation or knock
        out an essay you need to write. If your work benefits from a state of
        flow, this is when you&apos;re most likely to achieve it. If you&apos;re
        a proponent of{' '}
        <Link href="https://oku.club/book/deep-work-by-cal-newport-NnJIW">
          Deep Work,
        </Link>{' '}
        this when you need to be scheduling it.{' '}
      </p>

      <p>
        Similarly, it also means less important or &quot;shallow&quot; tasks
        that may not require your full attention, such as email, can be
        relegated to your dips so that your peaks are protected. Rise suggests
        that dips are also a perfect time to take a break or go for a walk,
        which I&apos;d have to agree with, but it has been observed that
        creative work can flourish during these off-peak times as well.
      </p>

      <div sx={sx.center}>
        <Tweet
          avatar="https://pbs.twimg.com/profile_images/1093259553305587712/VViR4AnI_400x400.jpg"
          url="https://twitter.com/pdstafford/status/1365788928494637057"
          handle="pdstafford"
          author="Patrick Stafford"
          date="12:20 am · 28 Feb 2021"
          tweet={
            <div>
              This is 100% true, and is the secret to getting things done. You
              need to be very, very self-aware about when you are most
              productive / alert and then schedule your days around that.
              <div sx={{ marginTop: 'm' }}>
                <TweetCompact
                  avatar="https://pbs.twimg.com/profile_images/737160582999232512/LHH7Yl8L_x96.jpg"
                  url="https://twitter.com/isaach/status/1365549147185549313"
                  handle="isaach"
                  author="Isaac Hepworth"
                  date="8:27 am · 27 Feb 2021"
                  tweet="i heard from someone this week that managing your calendar is more about managing your energy than managing your time. i like that."
                />
              </div>
            </div>
          }
        />
      </div>

      <p>
        I know it&apos;s not practical for most of us to structure our lives
        around our energy schedule, especially those of us with the biggest
        constraints on our time. However many of us do have some level of
        control at least some of the time, and hopefully this knowledge makes it
        a bit easier to work with our bodies instead of against them.{' '}
      </p>

      <p>Give it a try!</p>
      <br />
      <p>
        <Link href="https://oku.club/user/joe">– Joe</Link>
      </p>
    </BlogPageWrap>
  );
}

const sx = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 'l',

    a: {
      display: 'block',
      maxWidth: 520,
    },
  },
};
