import { getReadList, getListBooks } from './lists';

export function getReadingGoals(state) {
  return state.goals.items;
}

export function getActiveReadingGoal(state) {
  const year = new Date().getFullYear();
  return (getReadingGoals(state) || {})[year];
}

export function getCurrentGoalProgress(state) {
  const goal = getActiveReadingGoal(state);
  const readBooks = getListBooks(getReadList(state));
  const year = new Date().getFullYear();

  let counter = 0;
  for (let i = 0; i < readBooks.length; i++) {
    const book = readBooks[i];
    const addedAt = new Date(book.addedAt).getFullYear();
    if (addedAt === year) {
      counter++;
    } else if (addedAt < year) {
      // we can skip the rest
      break;
    }
  }

  return Math.min((counter / goal) * 100, 100);
}
