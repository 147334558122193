import React from 'react';

import Svg from '../../components/Svg';

export default function PencilIcon() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m8.2 17.125v-8.925h12.3v8.925h-4.144c-.2008 0-.3981.052-.5728.1509s-.3208.2413-.4241.4135l.5693.3415-.5693-.3415-1.0091 1.6819-1.0091-1.6819-.5145.3087.5145-.3087c-.1033-.1722-.2494-.3146-.4241-.4135s-.372-.1509-.5728-.1509zm-.0375-10.125c-.30831 0-.604.12248-.82201.34049s-.34049.5137-.34049.82201v9c0 .3083.12248.604.34049.822s.51369.3405.82201.3405h4.1603l1.0304 1.7173c.1032.1721.2493.3146.424.4135s.3721.1509.5728.1509.3981-.052.5728-.1509.3208-.2414.424-.4135l1.0304-1.7173h4.1603c.3083 0 .604-.1225.822-.3405s.3405-.5137.3405-.822v-9c0-.30832-.1225-.604-.3405-.82201s-.5137-.34049-.822-.34049zm3.3376 4.5375c0-.3314.2686-.6.6-.6h4.5c.3313 0 .6.2686.6.6s-.2687.6-.6.6h-4.5c-.3314 0-.6-.2686-.6-.6zm0 2.25c0-.3314.2686-.6.6-.6h4.5c.3313 0 .6.2686.6.6s-.2687.6-.6.6h-4.5c-.3314 0-.6-.2686-.6-.6z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
