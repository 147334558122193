// Actions
export const SET_APP_ERROR = 'SET_APP_ERROR';
export const SET_NATIVE_IOS = 'SET_NATIVE_IOS';

// Reducer
const appReducer = (
  state = {
    appError: '',
    isLoading: true,
    isNativeIos: false,
  },
  action
) => {
  switch (action.type) {
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.error,
      };
    case SET_NATIVE_IOS:
      return {
        ...state,
        isNativeIos: action.value,
      };
    default:
      return state;
  }
};

// Action creators
export const setAppError = error => ({
  type: SET_APP_ERROR,
  error,
});

export const setNativeIos = isNative => ({
  type: SET_NATIVE_IOS,
  value: isNative,
});

export default appReducer;
