/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio, Grid } from 'theme-ui';

import {
  appStore2x,
  oku,
  goodreadsImport,
  goodreadsImport2x,
  installAndroid,
  installAndroid2x,
  alarmClock,
  reviews,
  reviews2x,
  statsHeader,
  dashboard,
  dashboard2x,
  rss,
} from '../../assets';

import BlogPageWrap from './BlogPageWrap';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Card from '../../components/Card';
import Text from '../../components/Text';

import { POST_ROUTE } from '../../constants';

const POSTS = [
  {
    date: 'Feb 9, 2021',
    title: 'A new look and a sneak peak',
    route: POST_ROUTE.NewLookPost,
    excerpt: `February's here and 2021 has already left a mark! It's shaping up to be an exciting year for Oku, and to kick it all off, we've started with a refresh of our visual style. We think it brings consistency & maturity to the product and sets up well for the things to come this year. As ever we will continue to refine things, so let us know what you think, but first let's take a look at what's new.`,
    asset: dashboard,
    asset2x: dashboard2x,
  },
  {
    date: 'Feb 22, 2021',
    title: 'Importing your Goodreads reviews, books & shelves',
    route: POST_ROUTE.GoodreadsImporterPost,
    excerpt: `Did you know 53% of Oku members have imported their existing data over from Goodreads? It couldn't be easier. If you're a Goodreads user who wants to try out Oku but doesn't want to lose your historical data, this is for you.`,
    asset: goodreadsImport,
    asset2x: goodreadsImport2x,
  },
  {
    date: 'Mar 07, 2021',
    title: 'Let your body set the schedule',
    route: POST_ROUTE.DeepWorkPost,
    excerpt: `We're no productivity gurus here, but since Oku is a side project and everybody involved has a day job, we know how important it is to make the most of our time. That's why I wanted to share something I learnt about recently that just might help you produce your best work more consistently.`,
    asset: alarmClock,
    asset2x: alarmClock,
  },
  {
    date: 'Apr 09, 2021',
    title: 'Reviews have arrived on Oku',
    route: POST_ROUTE.ReviewsPost,
    excerpt: `We first gave our beta members the ability to review their books during our sprint week a month ago. Well, we've just released the final big piece of the feature (for now), so it's about time I officially showed you around.`,
    asset: reviews,
    asset2x: reviews2x,
  },
  {
    date: 'Jun 08, 2021',
    title: 'Goals & stats available now for premium members',
    route: POST_ROUTE.PremiumLaunchPost,
    excerpt: `Today we’re excitedly announcing the introduction of a premium tier to Oku! Our premium friends will get some cool extras, plus the warm feeling inside that comes with the knowledge you’re supporting an indie team. More on that below, first I want to show you the first two new features launching with the premium plan.`,
    asset: statsHeader,
    asset2x: statsHeader,
  },
  {
    date: 'Jun 28, 2021',
    title: 'Readng is now Oku!',
    route: POST_ROUTE.IntroducingOkuPost,
    excerpt: `We have been hearing feedback for a while that echoes our own experiences: the name "readng" is awkward to pronounce and spell out. So we committed to change our name, this time for something simple, memorable and somewhat unique. Many bad names later, we think we've found something that fits us perfectly.`,
    asset: oku,
    asset2x: oku,
  },
  {
    date: 'Jul 17, 2021',
    title: 'Oku has RSS feeds',
    route: POST_ROUTE.RssFeedsPost,
    excerpt: `Every collection on Oku has basic support for RSS feeds. These feeds will let you retrieve the books inside a collection and are supported by many tools such as IFTT and Zapier.`,
    asset: rss,
    asset2x: rss,
  },
  {
    date: 'Nov 30, 2021',
    title: 'Earn rewards for inviting your friends to Oku',
    route: POST_ROUTE.ReferralProgramPost,
    excerpt: `Announcing Oku's referral reward program! Because books are better with friends.`,
    asset: oku,
    asset2x: oku,
  },
  // {
  //   date: 'Mar 11, 2022',
  //   title: "Premium's getting cheaper",
  //   route: POST_ROUTE.PriceDropPost,
  //   excerpt: `Oku Premium is better value than ever. Gain access to all Premium benefits for as little as $1 per week.`,
  //   asset: oku,
  //   asset2x: oku,
  // },
  {
    date: 'Apr 11 2022',
    title: 'How to install Oku on your Android device',
    route: POST_ROUTE.InstallReadngPost,
    excerpt: `Oku is great on your computer but it works really well on your Android device too. You won't find it in the play store, but installing is really easy - here's how.`,
    asset: installAndroid,
    asset2x: installAndroid2x,
  },
  {
    date: 'Apr 11, 2022',
    title: 'Oku now available for iOS!',
    route: POST_ROUTE.OkuForIosPost,
    excerpt: `Oku is newly available for your iOS devices. Track your reading, organise your book highlights & collections, and more, all from your iPhone or iPad.`,
    asset: appStore2x,
    asset2x: appStore2x,
  },
].reverse();

export default function BlogIndex() {
  return (
    <BlogPageWrap title="Blog" isIndex>
      <section sx={sx.container}>
        <div sx={sx.header}>
          <Heading as="h1" fontFamily="serif" variant="marketing">
            Oku Product Blog
          </Heading>
        </div>
        <Grid gap="l" columns={[1, 1, 1, 2]}>
          {POSTS.map(post => (
            <Card to={post.route} key={post.title}>
              <div sx={sx.blockHeading}>
                <figure sx={sx.aspect}>
                  <AspectRatio ratio={16 / 9}>
                    <Image
                      alt={post.title}
                      height="100%"
                      objectFit="cover"
                      src={post.asset}
                      src2x={post.asset2x}
                      visibleByDefault
                      width="100%"
                    />
                  </AspectRatio>
                </figure>
                <div sx={sx.date}>{post.date}</div>
                <Heading as="h2" fontFamily="serif" maxWidth={640}>
                  {post.title}
                </Heading>
                <Text as="p" variant="marketingSub">
                  {post.excerpt}
                </Text>
              </div>
            </Card>
          ))}
        </Grid>
      </section>
    </BlogPageWrap>
  );
}

const sx = {
  container: {
    marginX: 'auto',
    paddingX: ['l', 'l', 'l', 'xl'],
    maxWidth: 'page.laptop',
    marginBottom: ['xxl', 'xxl', 'xxl', 'xxxl'],
  },
  header: {
    textAlign: 'center',
    maxWidth: 'marketing.laptop',
    marginX: 'auto',
    marginTop: ['xl', 'xl', 'xl', 'xxl'],
    marginBottom: 'xxl',
  },
  blockHeading: {
    display: 'flex',
    paddingBottom: ['l', 'l', 'xl'],
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    h2: {
      paddingX: ['l', 'l', 'xl'],
      fontSize: ['l', 'l', 'l', 'xl'],
      color: 'blackberry',
      lineHeight: 'heading',
      fontWeight: 400,
      letterSpacing: '-0.006em',
    },
    p: {
      marginTop: 's',
      maxWidth: 640,
      paddingX: ['l', 'l', 'xl'],
      fontSize: ['xs', 'xs', 'm', 'm', 'm'],
    },
  },
  date: {
    marginTop: ['l', 'l', 'xl'],
    marginBottom: 's',
    fontSize: 'm',
    textAlign: 'center',
    color: 'blueberry.50',
  },
  aspect: {
    display: 'block',
    width: '100%',
    '> div': {
      width: '100%',
    },
    span: {
      height: '100%',
    },
    img: {
      borderRadius: '8px 8px 0 0',
    },
  },
};
