/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import CardCollection from './CardCollection';
import FloatingFriend from './FloatingFriend';

export default function CollectionFriend({ list, who }) {
  return (
    <div sx={sx.container}>
      <CardCollection
        list={list}
        showBookCount={false}
        username={who.username}
      />
      <FloatingFriend who={who} />
    </div>
  );
}

const sx = {
  container: {
    position: 'relative',
  },
};
