/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from './Text';

export default function FormError({ children }) {
  const sx = getStyles();

  if (!children) {
    return null;
  }

  return (
    <Text variant="body" sx={sx.error}>
      {children}
    </Text>
  );
}

const getStyles = () => ({
  error: {
    color: 'candy',
    marginBottom: 's',
    paddingLeft: 'xxs',
  },
});
