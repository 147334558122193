/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import ListUsers from '../../components/ListUsers';
import Loader from '../../components/Loader';
import SearchEmptyState from './SearchEmptyState';
import SearchLoader from './SearchLoader';

export default function SearchBody({
  hasSearched,
  isInputEmpty,
  isLoading,
  userIds,
  users,
}) {
  const noResults = userIds.length === 0;

  // Show loading state
  if (noResults && isLoading) {
    return <Loader />;
  }

  // Show an empty state for when there are no results
  if (noResults && !isInputEmpty && hasSearched) {
    return (
      <SearchEmptyState
        heading="Oh no!"
        subheading="Nothing really matches your search :'("
      />
    );
  }

  // Show regular empty state
  if (noResults && !isLoading) {
    return (
      <SearchEmptyState
        heading="Search Oku"
        subheading="Find people within the Oku community"
      />
    );
  }

  // Render results
  return (
    <div sx={sx.container}>
      <ListUsers marginTop={0} users={userIds.map(id => users[id])} />
      {isLoading && <SearchLoader />}
    </div>
  );
}

const sx = {
  container: {
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
};
