/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

import Avatar from './Avatar';
import BookCover from './BookCover';
import Card from './Card';
import Heading from './Heading';
import Rating from './Rating';
import Text from './Text';
import Tooltip from './Tooltip';

import {
  getProfileRoute,
  COVER_WIDTH,
  COVER_WIDTH_MOBILE,
  COVER_HEIGHT,
} from '../constants';

export default function CardLandscape({
  authors,
  ratings = [],
  recommendedBy,
  slug,
  subtitle,
  thumbnail,
  title,
}) {
  return (
    <Card slug={slug}>
      <div sx={sx.inner}>
        <div>
          <div sx={sx.book}>
            <BookCover
              thumbnail={thumbnail}
              thumbnail2x={thumbnail}
              title={title}
              width="60%"
            />
          </div>
        </div>
        <div sx={sx.meta}>
          {title && (
            <Heading as="h2" sx={sx.heading} truncated>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Text as="h3" sx={sx.subheading} truncated>
              {subtitle}
            </Text>
          )}
          {authors && (
            <Text as="p" sx={sx.authors} truncated>
              {authors}
            </Text>
          )}
          {ratings.length > 0 && (
            <div sx={sx.ratings}>
              {ratings.map(rating => (
                <Rating
                  bg="blueberry.5"
                  key={rating.source}
                  rating={rating}
                  size={16}
                  source={rating.source}
                />
              ))}
            </div>
          )}
          {recommendedBy && (
            <div sx={sx.avatars}>
              {recommendedBy.map(person => (
                <Link to={getProfileRoute(person.username)} sx={sx.avatar}>
                  <Tooltip content={person.username} display="block">
                    <Avatar
                      size={16}
                      src={person.image}
                      src2x={person.image}
                      extraStyles={sx.avatarInner}
                    />
                  </Tooltip>
                </Link>
              ))}
              <Text as="p" sx={sx.recommend} truncated>
                78% recommend this
              </Text>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

const sx = {
  inner: {
    display: 'flex',
    alignItems: 'center',
  },
  book: {
    width: [
      COVER_WIDTH_MOBILE,
      COVER_WIDTH_MOBILE,
      COVER_WIDTH_MOBILE,
      COVER_WIDTH,
    ],
    height: COVER_HEIGHT,
    bg: 'blueberry.5',
    borderTopLeftRadius: 'button',
    borderBottomLeftRadius: 'button',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  meta: {
    padding: 'm',
    maxWidth: [
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH_MOBILE}px)`,
      `calc(100% - ${COVER_WIDTH}px)`,
    ],
  },
  heading: {
    color: 'olive',
    display: 'block',
    fontSize: 'm',
    fontWeight: 'heading',
    lineHeight: 'heading',
    marginBottom: 'xs',
  },
  subheading: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xs',
    fontWeight: 'heading',
    lineHeight: 'heading',
    marginBottom: 10,
  },
  authors: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xxs',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  recommend: {
    color: 'blueberry.80',
    display: 'block',
    fontSize: 'xxs',
    fontWeight: 'heading',
    marginLeft: 'xxs',
  },
  avatars: {
    marginTop: 's',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: -4,
    border: '2px solid',
    borderColor: 'milk',
    position: 'relative',
    borderRadius: '50%',
    display: 'block',
    '&:first-of-type': {
      marginLeft: -2,
    },
  },
  ratings: {
    marginTop: 's',
    display: 'flex',
    '> div': {
      marginRight: 's',
    },
  },
};
