/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from './Text';
import Toggle from './Toggle';
import { MegaphoneIcon } from '../icons';

export default function SpoilersToggle({ checked, onChange }) {
  return (
    <div sx={sx.container}>
      <Text as="label" htmlFor="spoilers-toggle" sx={sx.label}>
        <MegaphoneIcon />
        Contains spoilers?
      </Text>
      <Toggle id="spoilers-toggle" checked={checked} onChange={onChange} />
    </div>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: ['s', 's', 'm'],
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      width: 24,
      height: 24,
      marginRight: 'xs',
    },
  },
};
