/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import Button from '../components/Button';
import FullHeight from '../components/FullHeight';
import Heading from '../components/Heading';
import Image from '../components/Image';
import Link from '../components/Link';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import Text from '../components/Text';

import { HOME_ROUTE, getSupportEmailLink } from '../constants';

import theme from '../theme';
import { getIsAuthenticated, getToken } from '../selectors';
import { dogAndMan, dogAndMan2x } from '../assets';

export default function FullPageError({ title, children }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const topStyles = isAuthenticated
    ? sx.image.topAuthenticated
    : sx.image.topRegular;
  return (
    <Page headerless title={title || 'Uh oh'}>
      <FullHeight>
        <div sx={sx.page}>
          <figure sx={{ ...sx.image.top, ...topStyles }}>
            <Image
              alt="Dog and human reading a book"
              maxWidth={500}
              respondToDarkMode
              src={dogAndMan}
              src2x={dogAndMan2x}
              visibleByDefault
              width="100%"
            />
          </figure>
          <figure sx={sx.image.bottom}>
            <Image
              alt="Dog and human reading a book"
              maxWidth={500}
              respondToDarkMode
              src={dogAndMan}
              src2x={dogAndMan2x}
              visibleByDefault
              width="100%"
            />
          </figure>
          {isAuthenticated ? <div /> : <PageHeader />}
          <div sx={sx.body}>{children}</div>
          <div />
        </div>
      </FullHeight>
    </Page>
  );
}

const translateErrorCode = function (errCode, token, location) {
  const details = {
    heading: "We're having some trouble here.",
    text: "This is embarassing, but I'm not sure what's going on.",
    email: {
      to: getSupportEmailLink(),
      subject: '',
    },
    buttons: [
      {
        link: HOME_ROUTE,
        text: 'Dashboard',
      },
    ],
  };

  // goodreads
  if (errCode === 'gr-auth') {
    // todo: might not be used any more
    details.heading = "Couldn't connect your Goodreads account";
    details.text = `
      This is usually because you have the Goodreads app installed, and can be fixed by retrying on a device without the app.
      If this is not the case, please explain your issue in an email and we'll do our best to help.
    `;
    details.email.subject = "[Goodreads Auth] Can't connect my account";
  }

  if (errCode === 'gr-connected') {
    details.heading =
      'That Goodreads account is connected to a different Oku account';
    details.text = `
      Another member has already connected their Oku account to that Goodreads account.
      We can help you disconnect it and reconnect it if you email us.
    `;
    details.email.subject =
      '[Goodreads Auth] My Goodreads account is already connected';
  }

  const reloadButton = {
    // add timestamp so it does a refresh
    link: `${location.pathname}?ts=${new Date().getTime()}`,
    text: 'Retry',
    variant: 'light',
  };

  // offline
  if (errCode === 'offline') {
    return {
      ...details,
      heading: 'Did you bring a book?',
      text: "Your device is offline. Try again when you're reconnected.",
      buttons: [reloadButton],
    };
  }

  // could not fetch a vital resource
  if (errCode === 'fetchFailed') {
    return {
      ...details,
      heading: "We're having trouble loading that.",
      text:
        'Try again in a little while, and if it continues, please email us.',
      buttons: [reloadButton],
    };
  }

  return details;
};

export function GenericErrorPage({ code }) {
  const token = useSelector(getToken);
  const loc = useLocation();
  const queryCode = new URLSearchParams(loc.search).get('code');
  const errCode = code || queryCode;
  const details = translateErrorCode(errCode, token, loc);

  useEffect(() => {
    window.plausible('generic-error', {
      props: {
        code: errCode,
      },
    });
  }, []);

  return (
    <FullPageError>
      <Heading sx={sx.heading} variant="h2" as="h1">
        {details.heading}
      </Heading>
      <Text variant="body" as="p" sx={sx.text}>
        {details.text}
      </Text>
      <div>
        {details.buttons.map(button => {
          return (
            <Button
              as={Link}
              href={button.link}
              sx={sx.button}
              variant={button.variant || 'primary'}
              key={button.link}
            >
              {button.text}
            </Button>
          );
        })}
        <Button
          variant="transparent"
          as={Link}
          href={`mailto:${details.email.to}?subject=${details.email.subject}`}
          target="_blank"
          sx={sx.button}
        >
          Email us
        </Button>
      </div>
    </FullPageError>
  );
}

const sx = {
  page: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingX: 'l',
  },
  image: {
    top: {
      top: [-200, -200, -270],
      position: 'fixed',
      zIndex: -1,
      bg: 'milk',
    },
    topRegular: {
      left: [-200, -200, -120],
    },
    topAuthenticated: {
      left: theme.sizes.sidebar - 20,
    },
    bottom: {
      position: 'fixed',
      bottom: [-200, -200, -100],
      right: -60,
      zIndex: -1,
      bg: 'milk',
    },
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignText: 'center',
  },

  // Generic
  heading: {
    textAlign: ['left', 'left', 'center'],
  },
  text: {
    maxWidth: 420,
    textAlign: ['left', 'left', 'center'],
    marginTop: 'xs',
  },
  button: {
    marginTop: 'm',
    marginX: 'xxs',
  },
  br: {
    display: ['none', 'block', 'block', 'block'],
  },
};
