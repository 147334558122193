/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useResponsiveValue } from '@theme-ui/match-media';

import { SEARCH_BOOKS_ROUTE, SEARCH_USERS_ROUTE } from '../../constants';

import SearchInput from '../../components/SearchInput';
import TabLink from '../../components/TabLink';
import Tabs from '../../components/Tabs';
import LanguageButton from './LanguageButton';
import AddBookButton from './AddBookButton';

const SearchHeader = React.forwardRef((props, ref) => {
  const location = useLocation();
  const { pathname } = location;

  const {
    inputValue,
    isSearchingForUsers,
    onClearResults,
    onInputChange,
    onLanguageSelect,
    onSubmit,
    selectedLanguage,
    showAddBookButton,
  } = props;

  const booksPlaceholder = useResponsiveValue([
    'Search Oku or paste ISBN',
    'Search Oku or paste ISBN',
    'Search for books, authors or paste ISBN',
    'Search for books, authors or paste ISBN',
  ]);

  const usersPlaceholder = useResponsiveValue([
    'Search Oku',
    'Search Oku',
    'Search for Oku members',
    'Search for Oku members',
  ]);

  const placeholeder = isSearchingForUsers
    ? usersPlaceholder
    : booksPlaceholder;

  return (
    <div sx={sx.search}>
      <div sx={sx.searchInner}>
        <div sx={sx.searchInput}>
          <form onSubmit={onSubmit} action=".">
            <SearchInput
              onChange={onInputChange}
              onClear={onClearResults}
              placeholder={placeholeder}
              ref={ref}
              value={inputValue}
            />
          </form>
        </div>
        <LanguageButton
          onLanguageSelect={onLanguageSelect}
          selectedLanguage={selectedLanguage}
        />
        {showAddBookButton && (
          <div sx={sx.addBookButton}>
            <AddBookButton />
          </div>
        )}
      </div>
      <div sx={sx.tabs}>
        <Tabs>
          <TabLink
            as={RouterLink}
            to={SEARCH_BOOKS_ROUTE}
            isActive={pathname === SEARCH_BOOKS_ROUTE}
          >
            Books
          </TabLink>
          <TabLink
            as={RouterLink}
            to={SEARCH_USERS_ROUTE}
            isActive={pathname === SEARCH_USERS_ROUTE}
          >
            Members
          </TabLink>
        </Tabs>
      </div>
    </div>
  );
});

const sx = {
  search: {
    width: '100%',
    paddingTop: ['s', 's', 'l'],
    paddingBottom: ['s', 's', 'm'],
    zIndex: 'search',
    position: 'sticky',
    top: 0,
    '&:before': {
      bg: 'milk',
      position: 'absolute',
      content: '""',
      top: 0,
      left: [0, -16, -32],
      right: [0, -16, -32],
      bottom: ['s', 's', 'm'],
    },
  },
  searchInner: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  searchInput: {
    position: 'relative',
    flex: 1,
  },
  tabs: {
    marginTop: 'xs',
    paddingBottom: '1px',
    position: 'relative',
    '> div': {
      marginBottom: '-1px',
    },
  },
  addBookButton: {
    marginLeft: ['xs', 's', 's', 's'],
    display: ['none', 'none', 'none', 'none', 'block'],
  },
};

export default SearchHeader;
