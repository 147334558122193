/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AspectImage from '../../components/AspectImage';
import Heading from '../../components/Heading';
import Link from '../../components/Link';
import PageHeaderMenu from '../../components/PageHeaderMenu';
import PrimaryButton from '../../components/PrimaryButton';
import Text from '../../components/Text';
import { AppStoreIcon } from '../../icons';

import { SIGN_IN_ROUTE, APP_STORE_URL } from '../../constants';
import { bookcaseHome, bookcaseHome2x } from '../../assets';

export default function HeroBlock({ openSignUp }) {
  const location = useLocation();
  return (
    <div>
      <PageHeaderMenu onJoinClick={openSignUp} />
      <div sx={sx.container}>
        <header sx={sx.header}>
          <div>
            <Heading
              fontFamily="serif"
              as="h1"
              variant="marketing"
              sx={sx.heading}
            >
              The companion app for every book
            </Heading>
            <div sx={sx.buttons}>
              <PrimaryButton size="l" onClick={openSignUp}>
                Join for free
              </PrimaryButton>
              <PrimaryButton
                as="a"
                href={APP_STORE_URL}
                size="l"
                sx={sx.appStoreButton}
                target="_blank"
              >
                <AppStoreIcon />
              </PrimaryButton>
            </div>
            <Text variant="marketingSub" sx={sx.signinText}>
              Already a member?{' '}
              <Link
                as={RouterLink}
                to={{ pathname: SIGN_IN_ROUTE, search: location.search }}
                hasUnderline
              >
                Sign in.
              </Link>
            </Text>
          </div>
          <div sx={sx.image}>
            <AspectImage
              alt="Oku – The companion app for every book"
              aspectHeight={1}
              aspectWidth={1}
              maxWidth={766}
              respondToDarkMode
              src={bookcaseHome}
              src2x={bookcaseHome2x}
              width="100%"
            />
          </div>
        </header>
      </div>
    </div>
  );
}

const sx = {
  container: {
    overflow: 'hidden',
  },
  header: {
    marginX: 'auto',
    marginY: ['xl', 'xl', 'xl', 'xxl'],
    paddingX: 'xl',
    display: 'flex',
    alignItems: 'center',
    flexDirection: ['column', 'column', 'column', 'row'],
    maxWidth: 'page.laptop',

    '>div': {
      flex: 1,
      width: ['100%', '100%', '100%', '50%'],
    },
  },
  heading: {
    marginBottom: 'l',
    maxWidth: 460,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: ['xs', 'xs', 'xs', 's'],
  },
  appStoreButton: {
    paddingX: 0,
    display: 'block',
    '> span': {
      display: 'block',
    },
    svg: {
      marginX: 'auto',
      height: ['46px', '46px', '46px', '54px'],
      width: 'auto',
    },
  },
  signinText: {
    marginTop: 'm',
    lineHeight: 1.5,
    a: {
      color: 'blueberry.80',
      hover: {
        color: 'blueberry.100',
      },
    },
  },
  image: {
    bg: 'milk',
    marginTop: ['l', 'l', 'l', 0],
    '>div': {
      width: ['100%', '100%', '100%', '120%'],
    },
  },
};
