import React from 'react';

import Svg from '../components/Svg';

export default function ImageIcon(props) {
  return (
    <Svg height="14" viewBox="0 0 14 14" width="14" {...props}>
      <path
        clipRule="evenodd"
        d="m1.51351 1.51351v7.06498l1.13997-1.13996c.12272-.12395.26872-.22245.42964-.28983.16199-.06783.33585-.10276.51147-.10276.17563 0 .34949.03493.51148.10276.16092.06738.30693.16588.42965.28984l1.32914 1.32914 3.0319-3.03189c.12271-.12394.2687-.22242.4296-.28979.16199-.06783.33586-.10276.51148-.10276.17566 0 .34946.03493.51146.10276.1609.06737.3069.16585.4296.28979l1.7076 1.70757v-5.92985zm2.08108 7.12433-2.08108 2.08106v1.7676h10.97299v-2.90271l-2.64866-2.64865-3.03185 3.03184c-.12272.12392-.26873.22242-.42965.28982-.16199.0679-.33585.1028-.51148.1028-.17562 0-.34948-.0349-.51147-.1028-.16092-.0674-.30693-.1659-.42965-.28982zm-3.59459-7.31352v9.08118 2.2702c0 .7314.59292 1.3243 1.32432 1.3243h11.35138c.7314 0 1.3243-.5929 1.3243-1.3243v-3.40537-7.94601c0-.7314-.5929-1.32432-1.3243-1.32432h-11.35138c-.7314 0-1.32432.59292-1.32432 1.32432zm5.86486 3.12163c0 .47018-.38116.85135-.85135.85135-.47018 0-.85135-.38117-.85135-.85135 0-.47019.38117-.85136.85135-.85136.47019 0 .85135.38117.85135.85136z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
