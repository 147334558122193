/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const DIRECTION = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
};

export default function ModalFooter({
  children,
  extraStyles,
  direction = DIRECTION.Horizontal,
  hasBorder = false,
}) {
  const directionStyles =
    direction === DIRECTION.Horizontal
      ? {
          button: {
            flex: 1,
            '&:first-of-type:not(:only-child)': {
              marginRight: 'xs',
            },
            '&:last-child:not(:only-child)': {
              marginLeft: 'xs',
            },
          },
        }
      : {
          flexDirection: 'column',
          button: {
            flex: 1,
            '&:not(:last-child)': {
              marginBottom: 's',
            },
          },
        };
  return (
    <footer
      sx={{
        display: 'flex',
        paddingX: ['s', 's', 'm'],
        ...(hasBorder
          ? {
              paddingY: ['s', 's', 'm'],
              boxShadow: 'border.top.light',
            }
          : {
              paddingBottom: ['s', 's', 'm'],
            }),
        ...directionStyles,
        ...extraStyles,
      }}
    >
      {children}
    </footer>
  );
}
