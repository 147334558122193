/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import { statsHeader, statsBody, profileBadge, goal } from '../../../assets';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Goals &amp; stats available now for premium members / Blog">
      <time>Jun 08, 2021</time>
      <h1>Goals &amp; stats available now for premium members</h1>

      <a
        href="https://oku.club/pricing?utm_source=blog&utm_campaign=premium-launch"
        target="_blank noopener"
      >
        <img src={statsHeader} alt="Oku stats screenshot" />
      </a>

      <p>
        Today we’re excitedly announcing the introduction of a premium tier to
        Oku! Our premium friends will get some cool extras, plus the warm
        feeling inside that comes with the knowledge you’re supporting an indie
        team. More on that below, first I want to show you the first{' '}
        <b>two new features</b> launching with the premium plan.
      </p>

      <h2>Reading goals</h2>
      <p>
        If you’re trying to pick up a reading habit, try an annual reading goal
        to keep yourself on track.
      </p>
      <a
        href="https://oku.club/pricing?utm_source=blog&utm_campaign=premium-launch"
        target="_blank noopener"
      >
        <img src={goal} alt="Oku goal page screenshot" />
      </a>

      <h2>Personal stats</h2>
      <p>
        Premium members unlock access to their all-time reading statistics to
        gain insights into your reading like never before.
      </p>
      <a
        href="https://oku.club/pricing?utm_source=blog&utm_campaign=premium-launch"
        target="_blank noopener"
      >
        <img src={statsBody} alt="Oku stats screenshot" />
      </a>

      <h2>Profile badge</h2>
      <p>Display your support with a badge on your profile.</p>
      <a
        href="https://oku.club/pricing?utm_source=blog&utm_campaign=premium-launch"
        target="_blank noopener"
      >
        <img
          src={profileBadge}
          alt="Two screenshots of Oku profile pages, with the premium & supporter badges"
        />
      </a>

      <p>
        That’s everything we’re launching with the premium plan <em>today</em>,
        but the proceeds will fund more features for premium & free users alike!
      </p>

      <h2>Questions / Answers</h2>
      <br />
      <h3>Why charge money?</h3>
      <p>
        We have big ambitions for readng, but things can move rather slowly when
        we can only work on it in our spare time! After more than 18 months of
        evenings and weekends we’re itching to pour more time into it, which
        ultimately requires a business model.
      </p>

      <h3>Why not show ads?</h3>
      <p>
        We are member-supported so that our success is directly linked to member
        satisfaction, rather than how many ads we can show and how much data we
        can collect to microtarget them. Our members should be the customer and
        not the product.
      </p>

      <h3>How about free users?</h3>
      <p>
        Everything on the free tier remains free. We are charging for extra
        benefits and features but the free plan will continue to get
        improvements too!
      </p>

      <h1>Available now on premium</h1>
      <p>
        Head over to our{' '}
        <Link href="https://oku.club/pricing?utm_source=blog&utm_campaign=premium-launch">
          pricing page to upgrade
        </Link>{' '}
        if you’re willing & able to support us. If you can’t afford to support
        us financially, sharing us with friends and family goes a long way too
        ❤️
      </p>

      <p> Thanks for reading.</p>
    </BlogPageWrap>
  );
}
