/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as RouterLink } from 'react-router-dom';

import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import { oku, okuScreenshot } from '../../../assets';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Readng is now Oku! / Blog">
      <time>Jun 28, 2021</time>
      <h1>Readng is now Oku!</h1>

      <p>
        We have been hearing feedback for a while that echoes our own
        experiences: the name &quot;readng&quot; is awkward to pronounce and
        spell out. So we committed to change our name, this time for something
        simple, memorable and somewhat unique. Many bad names later, we think
        we&apos;ve found something that fits us perfectly.
      </p>

      <h2>Introducing Oku</h2>

      <Image src={oku} src2x={oku} alt="Oku – our new name and logo" />

      <p>
        Oku is a <strong>Turkish word meaning read</strong>, among other things.{' '}
        <Link as={RouterLink} to="/user/tarochan">
          Burcu
        </Link>
        ,{' '}
        <Link as={RouterLink} to="/user/aziz">
          Aziz
        </Link>
        &apos;s wife, sketched out the new logo, and{' '}
        <Link href="https://twitter.com/mariussunde" target="_blank">
          Marius Sunde
        </Link>{' '}
        completed the final touches that matched our existing brand. We also
        managed to snatch a cool domain name – from now on you&apos;ll find us
        at{' '}
        <Link href="https://oku.club" target="_blank">
          oku.club
        </Link>
        .
      </p>

      <Image
        src={okuScreenshot}
        src2x={okuScreenshot}
        alt="Oku – our new name and logo"
      />

      <p>
        What do you think? We&apos;d love to know – let us know on{' '}
        <Link href="https://twitter.com/okuclub">Twitter</Link> or just spread
        the word around. Hopefully it&apos;ll be easier this time round ;)
      </p>
    </BlogPageWrap>
  );
}
