/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function ModalBody({
  canGrow = true,
  children,
  extraStyles,
  height = '90vh',
  maxHeight = 640,
}) {
  const growStyles = canGrow && {
    height,
    maxHeight,
  };
  return (
    <main
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...growStyles,
        ...extraStyles,
      }}
    >
      {children}
    </main>
  );
}
