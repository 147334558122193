import React from 'react';

import Svg from '../../components/Svg';

export default function InvitePlus() {
  return (
    <Svg>
      <path
        clipRule="evenodd"
        d="m20.5 14c0 3.5899-2.9101 6.5-6.5 6.5s-6.5-2.9101-6.5-6.5 2.9101-6.5 6.5-6.5 6.5 2.9101 6.5 6.5zm1.5 0c0 4.4183-3.5817 8-8 8-4.41828 0-8-3.5817-8-8 0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8zm-8-3.75c.4142 0 .75.3358.75.75v2.25h2.25c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-2.25v2.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75v-2.25h-2.25c-.4142 0-.75-.3358-.75-.75s.3358-.75.75-.75h2.25v-2.25c0-.4142.3358-.75.75-.75z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
