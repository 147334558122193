/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Select as ThemeSelect } from 'theme-ui';

const Select = React.forwardRef(
  ({ children, sx, disabled, variant = 'input.primary', ...props }, ref) => {
    const bg = disabled ? 'blueberry.10' : undefined;
    const color = disabled ? 'blueberry.50' : undefined;
    const borderColor = disabled ? 'blueberry.20' : undefined;
    return (
      <ThemeSelect
        {...props}
        sx={{
          bg,
          color,
          fontSize: 'xs',
          fontFamily: 'body',
          fontWeight: 'select',

          paddingY: 0,
          paddingX: ['s', 's', 'm'],
          transition: 'smooth.fast',

          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,

          height: '44px',
          lineHeight: '42px',
          borderRadius: 'mini',

          '&::-moz-placeholder': {
            opacity: 0.5,
            color: 'blueberry.100',
            transition: 'smooth.fast',
          },
          '::-webkit-input-placeholder': {
            opacity: 0.5,
            color: 'blueberry.100',
            transition: 'smooth.fast',
          },
          '&:-ms-input-placeholder': {
            opacity: 0.5,
            color: 'blueberry.100',
            transition: 'smooth.fast',
          },
          '&:hover': {
            borderColor,
          },
          '&:focus': {
            '&::-moz-placeholder': {
              opacity: 0.7,
            },
            '::-webkit-input-placeholder': {
              opacity: 0.7,
            },
            '&:-ms-input-placeholder': {
              opacity: 0.7,
            },
          },

          ...sx,
        }}
        ref={ref}
        disabled={disabled}
        variant={variant}
      >
        {children}
      </ThemeSelect>
    );
  }
);

export default Select;
