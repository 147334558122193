/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from 'react-router-dom';
import { jsx } from 'theme-ui';

import Avatar from './Avatar';
import Text from './Text';
import Tooltip from './Tooltip';

import { getProfileRoute } from '../constants';

export default function AvatarList({
  borderColor = 'blueberry.5',
  people,
  text,
}) {
  return (
    <div sx={sx.avatars}>
      <div sx={sx.avatarList}>
        {people.map(person => (
          <Link
            sx={sx.avatar}
            key={person.username}
            to={getProfileRoute(person.username)}
          >
            <Tooltip position="topLeft" content={person.username}>
              <Avatar
                size={26}
                src={person.image}
                src2x={person.image}
                extraStyles={{ ...sx.avatarInner, borderColor }}
              />
            </Tooltip>
          </Link>
        ))}
      </div>
      {text && <Text variant="subtitle">{text}</Text>}
    </div>
  );
}

const sx = {
  avatars: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarList: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'xs',
  },
  avatar: {
    width: 26,
    height: 26,
    display: 'block',
    marginLeft: -8,

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  avatarInner: {
    width: 26,
    height: 26,
    display: 'block',
    overflow: 'hidden',
    borderRadius: '50%',
    border: '2px solid',
    position: 'relative',
  },
};
