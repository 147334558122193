/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import Tabs from '../../components/Tabs';
import TabLink from '../../components/TabLink';
import TabInner from '../../components/TabInner';

import {
  getProfile,
  getProfileDisplayLists,
  getProfileReviewCount,
  getProfileHighlightCount,
} from '../../selectors';

import {
  COLLECTIONS_ROUTE,
  getCollectionsRoute,
  getProfileFollowersRoute,
  getProfileFollowingRoute,
  getProfileHighlightsRoute,
  getProfileReviewsRoute,
  getProfileRoute,
  PROFILE_HIGHLIGHTS_ROUTE,
  PROFILE_REVIEWS_ROUTE,
} from '../../constants';

export default function ProfileWrap({ shouldShowTabs, shouldShowUserDetails }) {
  const match = useRouteMatch();

  const { username } = match.params;
  const matchUrl = match.url;
  const user = useSelector(getProfile(username));

  const PROFILE_ROUTE = getProfileRoute(username);
  const COLLECTIONS_FULL_ROUTE = getCollectionsRoute(username);
  const FOLLOWING_ROUTE = getProfileFollowingRoute(username);
  const FOLLOWERS_ROUTE = getProfileFollowersRoute(username);
  const REVIEWS_ROUTE = getProfileReviewsRoute(username);
  const HIGHLIGHTS_ROUTE = getProfileHighlightsRoute(username);

  const isCollectionsActive = matchUrl.includes(COLLECTIONS_ROUTE);
  const isFollowingActive = matchUrl.includes(FOLLOWING_ROUTE);
  const isReviewsActive = matchUrl.includes(PROFILE_REVIEWS_ROUTE);
  const isHighlightsActive = matchUrl.includes(PROFILE_HIGHLIGHTS_ROUTE);

  const tabs = shouldShowUserDetails
    ? [
        {
          isActive:
            !isCollectionsActive && !isReviewsActive && !isHighlightsActive,
          to: PROFILE_ROUTE,
          title: 'Library',
        },
        {
          isActive: isCollectionsActive,
          to: COLLECTIONS_FULL_ROUTE,
          title: 'Collections',
          count: getCollectionTabCount(user),
        },
        {
          isActive: isReviewsActive,
          to: REVIEWS_ROUTE,
          title: 'Reviews',
          count: getReviewTabCount(user),
        },
        {
          isActive: isHighlightsActive,
          to: HIGHLIGHTS_ROUTE,
          title: 'Highlights',
          count: getProfileHighlightCount(user),
        },
      ]
    : [
        {
          isActive: isFollowingActive,
          to: FOLLOWING_ROUTE,
          title: 'Following',
          count: user?.following?.length,
        },
        {
          isActive: !isFollowingActive,
          to: FOLLOWERS_ROUTE,
          title: 'Followers',
          count: user?.followers?.length,
        },
      ];

  if (!shouldShowTabs) {
    return null;
  }

  return (
    <Tabs extraStyle={sx.tabs}>
      {tabs.map(tab => (
        <TabLink
          as={RouterLink}
          isActive={tab.isActive}
          key={tab.to}
          to={tab.to}
        >
          <TabInner
            title={tab.title}
            count={tab.count}
            isActive={tab.isActive}
          />
        </TabLink>
      ))}
    </Tabs>
  );
}

const sx = {
  tabs: {
    marginTop: 'l',
  },
};

const getCollectionTabCount = user => {
  if (!user) return 'Collections';
  const collections = getProfileDisplayLists(user);
  return collections.length;
};

const getReviewTabCount = user => {
  const count = getProfileReviewCount(user);
  if (typeof count !== 'number') return null;
  return count;
};
