/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Avatar from '../../components/Avatar';
import Heading from '../../components/Heading';
import Text from '../../components/Text';
import Card from '../../components/Card';

export default function Tweet({ avatar, url, author, handle, date, tweet }) {
  return (
    <Card href={url} target="_blank">
      <div sx={sx.block}>
        <div sx={sx.header}>
          <div sx={sx.meta}>
            <div sx={sx.avatar}>
              <Avatar
                isGrayscale={false}
                name={author}
                size={20}
                src={avatar}
                src2x={avatar}
              />
            </div>
            <Heading as="div" sx={sx.heading}>
              {author}
            </Heading>
            <Text as="div" sx={sx.handle}>
              @{handle} {date}
            </Text>
          </div>
        </div>
        <div sx={sx.body}>{tweet}</div>
      </div>
    </Card>
  );
}

const sx = {
  block: {
    padding: 's',
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 'xs',
    img: {
      marginY: 0,
    },
  },
  heading: {
    color: 'blackberry',
    fontWeight: 500,
    fontSize: 's',
    textAlign: 'left',
    lineHeight: '14px',
  },
  handle: {
    fontWeight: 400,
    fontSize: 's',
    color: '#697882',
    textAlign: 'left',
    lineHeight: '14px',
    marginLeft: 'xs',
  },
  body: {
    color: 'blackberry',
    fontSize: 's',
    lineHeight: 1.375,
    marginTop: 'xs',
    textAlign: 'left',
  },
};
