/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import Button from './Button';
import ModalDialog from './ModalDialog';
import SignUpModal from '../modals/SignUp';

import { getIsAuthenticated, getProfile, getToken } from '../selectors';
import { shouldFollowUser, shouldUnfollowUser } from '../ducks/profile';

export default function FollowButton({
  isFollowing,
  size = 'm',
  user,
  userToFollow,
}) {
  const [colorMode] = useColorMode();
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const isDarkMode = colorMode === 'dark';
  const isAuthenticated = useSelector(getIsAuthenticated);
  const profileToFollow = useSelector(getProfile(userToFollow));
  const shouldFetchUserFirst = typeof profileToFollow === 'undefined';

  const closeSignUp = () => {
    setIsSignUpOpen(false);
  };

  const openSignUp = () => {
    setIsSignUpOpen(true);
  };

  if (userToFollow === user) {
    return null;
  }

  const isFollowingFromState = profileToFollow?.details?.following;
  const isFollowingToUse = shouldFetchUserFirst
    ? isFollowing
    : isFollowingFromState;

  const onClick = () => {
    if (!isAuthenticated) {
      openSignUp();
      return;
    }

    setIsLoading(true);

    if (isFollowingToUse) {
      shouldUnfollowUser({
        token,
        dispatch,
        shouldFetchUserFirst,
        username: userToFollow,
      }).then(() => {
        mixpanel.track('Remove Friend');
      });
    } else {
      shouldFollowUser({
        token,
        dispatch,
        shouldFetchUserFirst,
        username: userToFollow,
      }).then(() => {
        mixpanel.track('Add Friend');
      });
    }
  };

  const variant = getButtonVariant({
    isFollowing: isFollowingToUse,
    isDarkMode,
  });
  const text = isFollowingToUse ? 'Following' : 'Follow';

  // eslint-disable-next-line
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 420);
  }, [isFollowingFromState]);

  const sx = {
    borderRadius: 'round',
    bg: isFollowing ? 'milk' : undefined,
  };

  return (
    <React.Fragment>
      <ModalDialog isVisible={isSignUpOpen} onClose={closeSignUp}>
        <SignUpModal />
      </ModalDialog>
      <Button
        isLoading={isLoading}
        onClick={onClick}
        size={size}
        variant={variant}
        sx={sx}
      >
        {text}
      </Button>
    </React.Fragment>
  );
}

const getButtonVariant = ({ isDarkMode, isFollowing }) => {
  if (isDarkMode && isFollowing) {
    return 'ghost.dark';
  }
  if (!isDarkMode && isFollowing) {
    return 'ghost.light';
  }
  if (isDarkMode && !isFollowing) {
    return 'medium';
  }
  return 'primary';
};
