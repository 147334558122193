/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import ProfileWrap from './ProfileWrap';
import ListUsers from '../../components/ListUsers';

import { getProfile, getProfileFollows } from '../../selectors';

export default function Following() {
  const match = useRouteMatch();
  const { username } = match.params;
  const user = useSelector(getProfile(username));
  const follows = getProfileFollows(user);
  const shouldShowTabs = follows?.followers?.length !== 0;
  const marginTop = shouldShowTabs ? 'm' : 'xxl';
  return (
    <ProfileWrap shouldShowTabs={shouldShowTabs} shouldShowUserDetails={false}>
      <ListUsers users={follows?.following} marginTop={marginTop} />
    </ProfileWrap>
  );
}
