import {
  FEED_ITEM_TYPES,
  getBookReviewRoute,
  getCollectionRoute,
} from '../constants';
import { getBookSlug } from './book';
import { getFullName } from './user';

export const getFeed = friends => {
  const feedName = friends ? 'friends' : 'global';
  return state => state.feed[feedName];
};

export const getGlobalFeed = () => {
  return state => state.feed.global;
};

export const getFriendsFeed = () => {
  return state => state.feed.friends;
};

export const getFeedItemType = feedItem => {
  if (!feedItem) return null;
  return feedItem.type;
};

export const getFeedItemUser = feedItem => {
  if (!feedItem) return null;
  return feedItem.who;
};

export const getFeedItemUsername = item => {
  if (!item) return null;
  const user = getFeedItemUser(item);
  return user.username;
};

export const getFeedItemFirstName = feed => {
  if (!feed) return null;
  const user = getFeedItemUser(feed);
  if (user.firstname) {
    return user.firstname;
  }
  return user.username;
};

export const getFeedItemLastName = feed => {
  if (!feed) return null;
  const user = getFeedItemUser(feed);
  return user.lastname;
};

export const getFeedItemFullName = feed => {
  if (!feed) return null;
  const user = getFeedItemUser(feed);
  return getFullName(user);
};

export const getFeedItemAvatar = feed => {
  if (!feed) return null;
  const user = getFeedItemUser(feed);
  return user.image;
};

export const getFeedAction = feedItem => {
  if (!feedItem) return null;

  if (getFeedItemType(feedItem) === 'recommended') {
    return 'Recommends';
  }

  if (getFeedItemType(feedItem) === 'addedreview') {
    return 'Reviewed';
  }

  if (feedItem.list.key === 'to-read') {
    return 'Wants to read';
  }
  if (feedItem.list.key === 'reading') {
    return 'Reading';
  }
  if (feedItem.list.key === 'read') {
    return 'Finished';
  }
  if (feedItem.list.key === 'abandoned') {
    return 'Abandoned';
  }
  return `Added to ${feedItem.list.name}`;
};

export const getFeedItemLink = feedItem => {
  if (!feedItem) return null;
  const type = getFeedItemType(feedItem);
  if (type === FEED_ITEM_TYPES.REVIEW) {
    const book = getFeedBook(feedItem);
    return getBookReviewRoute({
      bookId: getBookSlug(book),
      reviewId: getFeedReviewId(feedItem),
    });
  }

  return getCollectionRoute(getFeedItemUsername(feedItem), {
    ...feedItem.list,
  });
};

export const getFeedDate = feedItem => {
  if (!feedItem) return null;
  return feedItem.when;
};

export const getFeedBook = feedItem => {
  if (!feedItem) return null;
  return feedItem.book;
};

export const getFeedBookTitle = feedItem => {
  if (!feedItem) return null;
  const book = getFeedBook(feedItem);
  return book.title;
};

export const getFeedBookImage = feedItem => {
  if (!feedItem) return null;
  const book = getFeedBook(feedItem);
  return book.imageLinks;
};

export const getFeedBookId = feedItem => {
  if (!feedItem) return null;
  const book = getFeedBook(feedItem);
  return book.id;
};

export const getFeedReview = feedItem => {
  if (!feedItem) return null;
  return feedItem.review;
};

export const getFeedReviewId = feedItem => {
  if (!feedItem) return null;
  const review = getFeedReview(feedItem);
  return review?.id;
};

export const getFeedReviewText = feedItem => {
  if (!feedItem) return null;
  const review = getFeedReview(feedItem);
  return review?.text;
};

export const getFeedReviewSpoiler = feedItem => {
  if (!feedItem) return null;
  const review = getFeedReview(feedItem);
  return review?.contains_spoiler;
};
