/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { motion, AnimatePresence } from 'framer-motion';
/* eslint-disable-next-line */
import { Global, css } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom';

import { useKeyPress } from '../hooks';
import { ArrowLeftIcon, CloseIcon } from '../icons';

import GhostButton from './GhostButton';
import theme from '../theme';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default function ModalDialog({
  children,
  height,
  isVisible,
  maxHeight,
  onClose,
  onGoBack,
  width = 480,
}) {
  useKeyPress('Escape', () => onClose());

  const yDistanceActive = useResponsiveValue([0, 0, '-50%', '-50%']);
  const yDistanceDefault = useResponsiveValue(['35%', '35%', '-35%', '-35%']);

  const framer = {
    overlay: {
      animate: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
      },
      initial: {
        opacity: 0,
      },
      transition: {
        ease: theme.easing.smooth.array,
        duration: 0.6,
      },
    },
    modal: {
      animate: {
        opacity: 1,
        x: '-50%',
        y: yDistanceActive,
      },
      exit: {
        opacity: 0,
        x: '-50%',
        y: yDistanceDefault,
      },
      initial: {
        opacity: 0,
        x: '-50%',
        y: yDistanceDefault,
      },
      transition: {
        ease: theme.easing.smooth.array,
        duration: 0.6,
      },
    },
  };

  return (
    <Modal>
      {isVisible && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            animate={framer.overlay.animate}
            exit={framer.overlay.exit}
            initial={framer.overlay.initial}
            key="overlay"
            onClick={onClose}
            transition={framer.overlay.transition}
            sx={sx.overlay}
          />
        )}
        {isVisible && (
          <motion.div
            animate={framer.modal.animate}
            exit={framer.modal.exit}
            initial={framer.modal.initial}
            key="modal"
            transition={framer.modal.transition}
            sx={{
              ...sx.modal,
              height,
              maxHeight,
              width: ['100%', '100%', width, width],
            }}
          >
            {children}
            {onGoBack && (
              <GhostButton onClick={onGoBack} size="icon" sx={sx.backButton}>
                <ArrowLeftIcon />
              </GhostButton>
            )}
            {onClose && (
              <GhostButton onClick={onClose} size="icon" sx={sx.closeButton}>
                <CloseIcon />
              </GhostButton>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  );
}

const sx = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bg: 'rgba(0, 0, 0, 0.6)',
    zIndex: 'overlay',
  },
  modal: {
    position: 'fixed',
    top: ['auto', 'auto', '50%', '50%'],
    bottom: [0, 0, 'auto', 'auto'],
    left: '50%',
    bg: 'milk',
    zIndex: 'overlay',
    borderRadius: ['20px 20px 0 0', '20px 20px 0 0', 20, 20],
    overflow: 'hidden',
    paddingBottom: 'env(safe-area-inset-bottom)',
  },
  backButton: {
    position: 'absolute',
    top: 16,
    left: 16,
    bg: 'milk',
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    bg: 'milk',
  },
};
