import React from 'react';

import Svg from '../components/Svg';

export default function QuoteIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path d="m10.4501 11c-.51658 0-.94203-.1955-1.27631-.5865-.33428-.406-.50143-.93982-.50143-1.60147 0-.91729.25831-1.69173.77493-2.32331.53182-.63158 1.26871-1.12782 2.21081-1.48872l.3419.81203c-.699.30075-1.2004.64662-1.5043 1.03759-.2887.37594-.4482.87218-.4786 1.48873h.7977c.7446.16541 1.1168.57142 1.1168 1.21804 0 .40602-.1443.75191-.433 1.03761-.2735.2707-.623.406-1.0485.406zm-4.67232 0c-.51662 0-.94207-.1955-1.27636-.5865-.33428-.406-.50142-.93982-.50142-1.60147 0-.91729.25831-1.69173.77493-2.32331.53181-.63158 1.26875-1.12782 2.21082-1.48872l.34189.81203c-.69896.30075-1.20038.64662-1.50428 1.03759-.2887.37594-.44824.87218-.47863 1.48873h.79772c.74454.16541 1.11681.57142 1.11681 1.21804 0 .40602-.14435.75191-.43305 1.03761-.2735.2707-.62298.406-1.04843.406z" />
    </Svg>
  );
}
