import React from 'react';

import Svg from '../components/Svg';

export default function StarIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m5.84258 12c-.10749 0-.21247-.0352-.29996-.1013-.16997-.128-.24496-.3556-.19147-.5695l.58191-2.26228-1.74322-1.47321c-.16498-.13597-.22997-.3679-.16498-.57798.06599-.21114.24746-.35724.45643-.3679l2.24215-.12104.79988-2.18608c.07898-.20635.26595-.34071.47592-.34071.20847 0 .39594.1349.47393.34124l.80337 2.18609 2.24166.12103c.2085.01066.3899.15623.4569.3679.065.21008 0 .44095-.164.57798l-1.7422 1.47108.5824 2.26178c.054.2133-.0225.441-.1919.5689-.17.1291-.3975.1355-.57495.016l-1.88271-1.2796-1.88271 1.2796c-.08198.0565-.17797.0869-.27595.0875z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
