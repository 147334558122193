/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import range from 'lodash-es/range';
import moment from 'moment';

import CardPortrait from '../../components/CardPortrait';
import Heading from '../../components/Heading';
import ListWrap from '../../components/ListWrap';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';
import ReadingGoalLogo from '../../components/ReadingGoalLogo';
import ReadingProgress from '../../components/ReadingProgress';

import {
  FEATURES,
  getActiveReadingGoal,
  getBookAuthors,
  getBooksAddedSince,
  getBookSlug,
  getBookTitle,
  getIsAuthenticated,
  getReadList,
  getUserStale,
  hasFeature,
} from '../../selectors';

import { ROOT_ROUTE } from '../../constants';

export default function ReadingGoal() {
  const isAuthenticated = !!useSelector(getIsAuthenticated);
  const goal = useSelector(getActiveReadingGoal);
  const dispatch = useDispatch();

  const cutoff = moment().startOf('year');
  const books = getBooksAddedSince(useSelector(getReadList), cutoff).reverse();
  const extraTiles = Math.max(0, goal - books.length);

  const enableGoals = useSelector(hasFeature(FEATURES.GOALS));
  const userStale = useSelector(getUserStale);

  // if user is stale we probably don't know the current tier,
  // so hold off on the redirect
  if (!enableGoals && userStale) {
    dispatch(push(ROOT_ROUTE));
  }

  return (
    <Page title="Reading goal">
      {isAuthenticated && <PageNavigation showBackButton showShareButton />}
      <PageBody>
        <header sx={sx.header}>
          <div sx={sx.date}>
            <ReadingGoalLogo />
            <Heading as="h1" fontFamily="serif" sx={sx.heading}>
              Reading Goal: {cutoff.format('YYYY')}
            </Heading>
          </div>
          <div sx={sx.progress}>
            <ReadingProgress booksRead={books.length} booksToRead={goal} />
          </div>
        </header>
        <main sx={sx.main}>
          <ListWrap>
            {books.length > 0 &&
              books.map(book => {
                const { title, subtitle } = book;
                const bookTitle = getBookTitle({ title, subtitle });
                const authors = getBookAuthors(book.authors);
                const slug = getBookSlug(book);
                return (
                  <CardPortrait
                    authors={authors}
                    key={book.id}
                    thumbnail={book.imageLinks.thumbnail}
                    title={bookTitle}
                    slug={slug}
                  />
                );
              })}
            {range(extraTiles).map(number => (
              <div key={number} sx={sx.bookPlaceholder}>
                {number + books.length + 1}
              </div>
            ))}
          </ListWrap>
        </main>
      </PageBody>
    </Page>
  );
}

const sx = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', 'center', 'center', 'center', 'space-between'],
    flexDirection: ['column', 'column', 'column', 'column', 'row'],
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: ['column', 'column', 'column', 'column', 'row'],
  },
  heading: {
    fontSize: 'l',
    color: 'blueberry.100',
    fontWeight: 'heading',
    marginLeft: 's',
    marginTop: ['m', 'm', 'm', 'm', 0],
  },
  progress: {
    marginTop: ['l', 'l', 'l', 'l', 0],
    width: ['100%', '100%', '100%', '100%', 'auto'],
    flex: [1, 1, 1, 1, 'inherit'],
  },
  bookPlaceholder: {
    bg: 'blueberry.5',
    minHeight: [122, 122, 122, 320],
    borderRadius: 'button',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'serif',
    fontSize: 39,
    color: 'blueberry.20',
    userSelect: 'none',
  },
  main: {
    marginTop: ['s', 's', 's', 'xl'],
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
};
