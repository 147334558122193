/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, useColorMode } from 'theme-ui';

import Heading from './Heading';
import Text from './Text';

import { MegaphoneIcon } from '../icons';

export default function ContainsSpoilers({
  hasIcon = true,
  headingVariant = 'subtitle2',
}) {
  return (
    <React.Fragment>
      {hasIcon && (
        <div sx={{ marginBottom: 's' }}>
          <MegaphoneIcon />
        </div>
      )}
      <Heading as="h2" variant={headingVariant} sx={{ marginBottom: 'xxs' }}>
        Review may contain spoilers
      </Heading>
      <Text as="span" variant="caption">
        Tap to read
      </Text>
    </React.Fragment>
  );
}

export function ContainsSpoilersWrapper({ children, left = 0, onClick }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <button
      onClick={onClick}
      type="button"
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left,
        right: 0,
        bottom: 0,
        backdropFilter: 'blur(5px)',
        border: 'none',
        outline: 'none',
        appearance: 'none',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bg: isDark ? 'rgba(30,30,30, 0.8)' : 'rgba(246,246,246, 0.8)',
      }}
    >
      {children}
    </button>
  );
}
