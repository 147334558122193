import React from 'react';

import Svg from '../components/Svg';

export default function FeatureTickIcon({ ...props }) {
  return (
    <Svg height="32" viewBox="0 0 32 32" width="32" {...props}>
      <path
        clipRule="evenodd"
        d="m21.7803 12.2197c.2929.2929.2929.7678 0 1.0606l-7 6.9997c-.2929.2929-.7677.2929-1.0606 0l-3.5-3.4996c-.29291-.2929-.29293-.7678-.0001-1.0607.2929-.2929.7678-.2929 1.0607-.0001l2.9697 2.9695 6.4697-6.4694c.2929-.2929.7678-.2929 1.0606 0z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
