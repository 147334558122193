/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';

import ContentLoader from '../../components/ContentLoader';
import GhostButton from '../../components/GhostButton';
import ModalDialog from '../../components/ModalDialog';
import PrimaryButton from '../../components/PrimaryButton';

import AddToCollectionModal from '../../modals/AddToCollection';
import CreateCollectionModal from '../../modals/CreateCollection';

import { TickIcon } from '../../icons';
import { pluralise } from '../../utils';

const MODAL_HEIGHT = 640;

export default function AddToCollection({
  bookId,
  collectionsLength,
  isAuthenticated,
  openSignUp,
  userIsStale,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const openModal = () => {
    if (isAuthenticated) {
      setIsModalOpen(true);
      setIsCreating(false);
    } else {
      openSignUp();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsCreating(false);
  };

  const onCreate = () => {
    setIsCreating(true);
  };

  const onGoBack = () => {
    setIsCreating(false);
  };

  const onFinishCreating = () => {
    setIsCreating(false);
    closeModal();
  };

  if (userIsStale) {
    return <ContentLoader sx={sx.loader} />;
  }

  return (
    <React.Fragment>
      <CollectionsButton
        collectionsLength={collectionsLength}
        onClick={openModal}
      />
      {isAuthenticated && (
        <ModalDialog
          isVisible={isModalOpen}
          onClose={closeModal}
          onGoBack={isCreating ? onGoBack : undefined}
        >
          {isCreating ? (
            <div sx={sx.createCollectionModal}>
              <CreateCollectionModal
                bookId={bookId}
                height={MODAL_HEIGHT}
                onClose={onFinishCreating}
                onCreate={onCreate}
              />
            </div>
          ) : (
            <AddToCollectionModal
              bookId={bookId}
              height={MODAL_HEIGHT}
              onCreate={onCreate}
              onFinishCreating={onFinishCreating}
              closeModal={closeModal}
            />
          )}
        </ModalDialog>
      )}
    </React.Fragment>
  );
}

function CollectionsButton({ collectionsLength, children, ...rest }) {
  const isInCollections = collectionsLength > 0;

  if (isInCollections) {
    return (
      <PrimaryButton sx={sx.withIcon} {...rest}>
        <TickIcon width={24} height={24} /> Part of{' '}
        {pluralise({ count: collectionsLength, word: 'collection' })}
      </PrimaryButton>
    );
  }

  return (
    <GhostButton sx={sx.withoutIcon} {...rest}>
      + Add to collection
    </GhostButton>
  );
}

const sx = {
  createCollectionModal: {
    marginTop: 's',
  },
  withoutIcon: {
    bg: 'milk',
    borderRadius: 'round',
  },
  withIcon: {
    borderRadius: 'round',
    '> span': {
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginRight: 'xs',
      },
    },
  },
  loader: {
    minWidth: 0,
    width: 186,
    height: 40,
    borderRadius: 'round',
  },
};
