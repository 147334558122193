/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Loader from '../../components/Loader';

export default function ActivityLoader() {
  return (
    <div sx={sx.loader}>
      <Loader />
    </div>
  );
}

const sx = {
  loader: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
};
