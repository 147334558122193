/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import CircleButton from './CircleButton';

import { ArrowLeftIcon, ArrowRightIcon } from '../icons';

export default function SwiperNavigation({ prevClassName, nextClassName }) {
  return (
    <div sx={sx.container}>
      <CircleButton className={prevClassName} size="s">
        <ArrowLeftIcon width={20} height={20} />
      </CircleButton>
      <CircleButton className={nextClassName} size="s">
        <ArrowRightIcon width={20} height={20} />
      </CircleButton>
    </div>
  );
}

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
    'button:first-of-type': {
      marginRight: 'xxs',
    },
  },
};
