/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function BookSideBlock({
  bg,
  borderColor = 'blueberry.10',
  children,
  padding = 'm',
  extraStyles,
  onClick,
}) {
  return (
    // eslint-disable-next-line
    <div
      onClick={onClick}
      sx={{
        border: '1px solid',
        borderRadius: 'button',
        overflow: 'hidden',
        position: 'relative',
        bg,
        borderColor,
        padding,
        ...extraStyles,
      }}
    >
      {children}
    </div>
  );
}
