/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import Heading from '../../components/Heading';
import Text from '../../components/Text';
import PrimaryButton from '../../components/PrimaryButton';

export default function WIPBox() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';

  const extraStyles = isDark
    ? {
        border: '1px solid',
        borderColor: 'blueberry.10',
      }
    : {
        bg: 'blueberry.5',
      };

  return (
    <div sx={{ ...sx.wip, ...extraStyles }}>
      <div sx={sx.wipBody}>
        <Heading sx={sx.heading}>Work in progress</Heading>
        <Text sx={sx.paragraph}>
          We’re working to add more insights here soon. Until then, if you have
          suggestions for stats you want to see, we’d love to hear it!
        </Text>
        <PrimaryButton
          as="a"
          href="mailto:team@oku.club?subject=I%20have%20a%20stats%20suggestion!"
        >
          Send a suggestion
        </PrimaryButton>
      </div>
    </div>
  );
}

const sx = {
  wip: {
    borderRadius: 'button',
    padding: ['l', 'l', 'l', 'xl'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginY: ['s', 's', 'l', 'xl'],
  },
  wipBody: {
    maxWidth: 440,
    textAlign: 'center',
  },
  heading: {
    fontSize: 'l',
    fontFamily: 'serif',
    color: 'blueberry.100',
  },
  paragraph: {
    color: 'blueberry.100',
    marginBottom: 's',
  },
};
