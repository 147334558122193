/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import theme from '../theme';

const Toggle = React.forwardRef((props, ref) => {
  const { id, ...rest } = props;
  return (
    <div sx={sx.container}>
      <input type="checkbox" id={id} {...rest} ref={ref} sx={sx.input} />
      {/* eslint-disable-next-line */}
      <label htmlFor={id} sx={sx.label} />
    </div>
  );
});

const DISTANCE = 3;
const SIZE = 54;

const sx = {
  container: {
    position: 'relative',
  },
  input: {
    height: 0,
    width: 0,
    visibility: 'hidden',
    position: 'absolute',

    '&:checked + label': {
      bg: 'blackberry',
    },

    '&:checked + label:after': {
      left: `calc(100% - ${DISTANCE}px)`,
      transform: 'translateX(-100%)',
    },

    '&:checked + label:active:after': {
      transform: 'translateX(-100%) scaleX(1.1)',
      transformOrigin: 'right',
    },
  },
  label: {
    bg: 'blueberry.10',
    cursor: 'pointer',
    textIndent: -9999,

    width: SIZE - DISTANCE * 4,
    height: SIZE / 2,
    borderRadius: SIZE / 2,

    display: 'block',
    position: 'relative',
    transition: theme.transitions.smooth.fast,

    '&:after': {
      content: "' '",
      position: 'absolute',
      top: DISTANCE,
      left: DISTANCE,
      width: SIZE / 2 - DISTANCE * 2,
      height: SIZE / 2 - DISTANCE * 2,
      backgroundColor: 'milk',
      borderRadius: SIZE / 2 - DISTANCE * 2,
      transition: theme.transitions.smooth.fast,
    },

    '&:active:after': {
      transform: 'scaleX(1.1)',
      transformOrigin: 'left',
    },
  },
};

export default Toggle;
