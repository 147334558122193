import React from 'react';

import Svg from '../components/Svg';

export default function MinusIcon(props) {
  return (
    <Svg fill="none" {...props}>
      <path
        d="m19 14h-10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.7"
      />
    </Svg>
  );
}
