/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';

import { RainbowSpacedIcon } from '../icons';

export default function ReadingGoalLogo({ width = 56, height = 56 }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <div
      sx={{
        width,
        height,
        color: isDark ? 'blackberry' : 'milk',
        borderRadius: 'circle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isDark
          ? 'linear-gradient(180deg, #525964 0%, #454F60 36.46%, #31384E 77.08%, #1A2542 100%)'
          : 'linear-gradient(180deg, #CACBD3 0%, #DECCB4 36.46%, #DEA78E 77.08%, #D9715C 100%);',
      }}
    >
      <RainbowSpacedIcon width="50%" height="50%" />
    </div>
  );
}
