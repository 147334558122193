/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import GhostButton from '../components/GhostButton';
import ModalContent from '../components/ModalContent';
import ModalHeading from '../components/ModalHeading';
import ModalFooter from '../components/ModalFooter';
import PrimaryButton from '../components/PrimaryButton';
import TextareaWithLabel from '../components/TextareaWithLabel';
import SpoilersToggle from '../components/SpoilersToggle';

import ShareBookModal from './ShareBook';

import { getBookId } from '../utils';
import { getToken } from '../selectors';
import { getBookReviewsRoute } from '../constants';
import { createBookReview, updateBookReview } from '../ducks/reviews';

export default function ReviewBook({
  bookId,
  hasSpoilers,
  onClose,
  onGoBack,
  review,
  reviewId,
  slug,
  thumbnail,
  title,
  withShare,
}) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const [text, setText] = useState(review || '');
  const [error, setError] = useState('');
  const [spoiler, setSpoiler] = useState(hasSpoilers || false);
  const [isLoading, setIsLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const targetBookId = bookId || getBookId(slug || match.params.slug);
  const bookReviewsUrl = getBookReviewsRoute(slug || match.params.slug);
  const isInEditMode = review && reviewId;

  const toggleSpoilers = () => {
    setSpoiler(!spoiler);
  };
  const onReviewChange = event => {
    if (error) {
      setError('');
    }
    setText(event.target.value);
  };

  const goToReviewsPage = () => {
    dispatch(push(bookReviewsUrl));
    onClose();
  };

  const goToShareModal = () => {
    setShowShareModal(true);
  };

  const onSubmit = event => {
    event.preventDefault();
    setError('');

    if (text === '' && isInEditMode) {
      setError('Please enter some text');
      return null;
    }

    if (text === '' && !isInEditMode) {
      goToShareModal();
      return null;
    }

    setIsLoading(true);

    // Edit current review
    if (isInEditMode) {
      updateBookReview({
        bookId: targetBookId,
        reviewId,
        token,
        text,
        spoiler,
        dispatch,
      })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
            onClose();
          }, 600);
        })
        .catch(() => {
          toast('Something went wrong. Please try again!');
          setTimeout(() => setIsLoading(false), 600);
        });
      return null;
    }

    // Or submit a new review
    createBookReview({ bookId: targetBookId, token, text, spoiler, dispatch })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);

          if (withShare) {
            setShowShareModal(true);
          } else {
            goToReviewsPage();
          }
        }, 600);
      })
      .catch(() => {
        toast('Something went wrong. Please try again!');
        setTimeout(() => setIsLoading(false), 600);
      });
  };

  const location = window.location;
  const shareUrl = `${location.origin}${location.pathname}`;
  const quote = `I just recommended ${title} on Oku`;

  if (showShareModal) {
    return (
      <ShareBookModal
        heading="Spread the word!"
        onClose={text ? goToReviewsPage : onClose}
        quote={quote}
        shareUrl={shareUrl}
        subheading="Know somebody with taste as excellent as yours? Let 'em know you loved this!"
        thumbnail={thumbnail}
        title={title}
      />
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalHeading>
        {isInEditMode ? 'Update your review' : 'Add a review?'}
      </ModalHeading>
      <ModalContent>
        <TextareaWithLabel
          error={error}
          id="enter-review"
          label={isInEditMode ? 'Update your review' : 'Review (optional)'}
          onChange={onReviewChange}
          sx={sx.textarea}
          value={text}
        />
        <SpoilersToggle checked={spoiler} onChange={toggleSpoilers} />
      </ModalContent>
      <ModalFooter>
        {onGoBack && (
          <GhostButton type="button" onClick={onGoBack}>
            Back
          </GhostButton>
        )}
        <PrimaryButton isLoading={isLoading} onClick={onSubmit} type="submit">
          Save
        </PrimaryButton>
      </ModalFooter>
    </form>
  );
}

const sx = {
  textarea: {
    height: [200, 200, 200, 300],
  },
};
