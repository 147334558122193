/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui';

import Image from './Image';
import Text from './Text';
import Link from './Link';
import { ImageIcon } from '../icons';
import { getBookTitle } from '../selectors';

export default function BookCover({
  hasEmptyState,
  isLoading,
  isRaised = false,
  maxWidth,
  thumbnail,
  thumbnail2x,
  title,
  visibleByDefault = false,
  width,
  withAspectRatio,
}) {
  const height = withAspectRatio ? '100%' : 'auto';
  return (
    <Wrapper maxWidth={maxWidth} width={width} height={height}>
      <Book hasThumbnail={thumbnail && thumbnail2x} isRaised={isRaised}>
        <BookImage
          hasEmptyState={hasEmptyState}
          height={height}
          isLoading={isLoading}
          thumbnail={thumbnail}
          thumbnail2x={thumbnail2x}
          title={getBookTitle({ title })}
          visibleByDefault={visibleByDefault}
          withAspectRatio={withAspectRatio}
        />
      </Book>
    </Wrapper>
  );
}

function BookImage({
  hasEmptyState,
  height,
  isLoading,
  thumbnail,
  thumbnail2x,
  title,
  visibleByDefault,
  withAspectRatio,
}) {
  const commonProps = {
    alt: title,
    borderRadius: 'book',
    display: 'block',
    objectFit: 'cover',
    src: thumbnail,
    src2x: thumbnail2x,
    width: '100%',
    height,
    visibleByDefault,
  };
  if (isLoading) {
    return <div />;
  }
  if (thumbnail && thumbnail2x && !withAspectRatio) {
    return <Image className="with-texture" {...commonProps} />;
  }
  if (thumbnail && thumbnail2x && withAspectRatio) {
    return (
      <AspectRatio ratio={0.65} sx={{ '> span': { height: '100%' } }}>
        <Image className="with-texture" {...commonProps} />
      </AspectRatio>
    );
  }
  if (hasEmptyState) {
    return (
      <AspectRatio ratio={0.65}>
        <Title centered hasHover>
          <ImageIcon />
          <Text variant="subtitle" sx={{ marginTop: 'xs' }}>
            <Link variant="secondary" hasUnderline hasTransition={false}>
              Suggest cover
            </Link>
          </Text>
        </Title>
      </AspectRatio>
    );
  }
  return (
    <AspectRatio ratio={0.65}>
      <Title>
        <EmptyState title={title} />
      </Title>
    </AspectRatio>
  );
}

const Title = ({ children, centered, hasHover }) => (
  <div
    sx={{
      display: 'flex',
      alignItems: centered ? 'center' : 'flex-end',
      textAlign: centered ? 'center' : 'left',
      justifyContent: centered ? 'center' : 'flex-end',
      flexDirection: 'column',
      bg: 'blueberry.10',
      height: '100%',
      paddingX: '12%',
      paddingY: '12%',
      width: '100%',

      ...(hasHover && {
        cursor: 'pointer',
        transition: 'smooth.fast',
        '&:hover': {
          bg: 'blueberry.20',
        },
      }),
    }}
  >
    {children}
  </div>
);

const EmptyState = ({ title }) => {
  const truncateTo = 24;
  const text =
    title.length < truncateTo ? title : `${title.substring(0, truncateTo)}...`;
  return (
    <div
      sx={{
        color: 'blueberry.50',
        lineHeight: '1.3',
        fontSize: 'xs',

        width: '100%',
        wordWrap: 'break-word',
        display: 'inline-block',
      }}
    >
      {text}
    </div>
  );
};

const Wrapper = ({ children, maxWidth, width, height }) => (
  <div
    sx={{
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width,
      height,
      maxWidth,
    }}
  >
    {children}
  </div>
);

const Book = ({ children, isRaised, hasThumbnail }) => {
  const texture = {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 'book',
      pointerEvents: 'none',
      boxShadow: hasThumbnail
        ? 'inset 0 0 0 1px rgba(15, 15, 15, 0.1)'
        : 'none',
      background:
        'linear-gradient(90deg, rgba(0, 0, 0, 0.118) 0.65%, rgba(255, 255, 255, 0.2) 1.53%, rgba(255, 255, 255, 0.102) 2.38%, rgba(0, 0, 0, 0.05) 3.26%, rgba(255, 255, 255, 0.14) 5.68%, rgba(244, 244, 244, 0) 6.96%)',
    },
  };
  if (hasThumbnail) {
    return (
      <div
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          boxShadow: isRaised ? 'book.default' : 'book.default',
          borderRadius: 'book',
          overflow: 'hidden',
          '.with-texture': {
            position: 'relative',
            display: 'block',
            ...texture,
          },
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        boxShadow: isRaised ? 'book.default' : 'book.default',
        borderRadius: 'book',
        overflow: 'hidden',
        ...texture,
      }}
    >
      {children}
    </div>
  );
};
