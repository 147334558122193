/** @jsxRuntime classic */
/** @jsx jsx */
import keys from 'lodash-es/keys';
import { jsx } from 'theme-ui';

import Page from '../components/Page';
import Text from '../components/Text';
import Heading from '../components/Heading';

import theme from '../theme';

export default function Theme() {
  const { fontSizes, colors } = theme;
  return (
    <Page title="Style guide">
      <main sx={sx.main}>
        <Heading sx={sx.heading}>Font sizes</Heading>
        {keys(fontSizes).map((size, index) => (
          <FontSize key={index} size={size} value={fontSizes[size]} />
        ))}
        <Heading sx={sx.heading}>
          Light colors{' '}
          <Text variant="body">
            Numbers next to the color name stand for % opacity on the base color
            (milk)
          </Text>
        </Heading>
        {keys(colors).map((color, index) => (
          <Color key={index} value={color} color={colors[color]} />
        ))}
        <Heading sx={sx.heading}>
          Dark colors{' '}
          <Text variant="body">
            Numbers next to the color name stand for % opacity on the base color
            (milk)
          </Text>
        </Heading>
        {keys(colors.modes.dark).map((color, index) => (
          <Color key={index} value={color} color={colors.modes.dark[color]} />
        ))}
      </main>
    </Page>
  );
}

function FontSize({ size, value }) {
  return (
    <div sx={sx.fontSize.container}>
      <div sx={sx.fontSize.value}>
        {size}: {value}px
      </div>
      <div sx={{ fontSize: size }}>
        The quick brown fox jumps over the lazy dog
      </div>
    </div>
  );
}

function Color({ color, value, name }) {
  // ignore dark mode for now
  if (value === 'modes') {
    return null;
  }

  if (typeof color === 'string') {
    return (
      <div sx={sx.color.upper.container}>
        <div sx={{ ...sx.color.upper.color, bg: color }} />
        <p sx={sx.color.upper.p}>
          {name} {value}
        </p>
        <p sx={sx.color.upper.value}>{color}</p>
      </div>
    );
  }

  if (typeof color === 'object') {
    return (
      <div sx={sx.color.inner.container}>
        {keys(color).map(inner => {
          return (
            <div sx={sx.color.inner.child}>
              <Color name={value} value={inner} color={color[inner]} />
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

const sx = {
  main: {
    width: 'page.desktop',
    marginX: 'auto',
    paddingX: 'm',
    marginY: 'xxl',
  },
  heading: {
    color: 'olive',
    fontSize: 'xl',
    fontFamily: 'serif',
    borderBottom: '1px solid',
    borderColor: 'blueberry.20',
    paddingBottom: 'm',
    marginBottom: 'l',
    marginTop: 'xxl',
  },
  fontSize: {
    container: {
      marginBottom: 'm',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    value: {
      color: 'blueberry.50',
      marginBottom: 'xs',
      fontSize: 'xs',
    },
  },
  color: {
    upper: {
      container: {
        marginBottom: 'm',
      },
      color: {
        width: 120,
        height: 120,
        borderRadius: 'toast',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
      },
      p: {
        fontSize: 's',
        marginTop: 's',
        textTransform: 'capitalize',
        color: 'blueberry.80',
      },
      value: {
        fontSize: 'xs',
        marginTop: 'xxs',
        textTransform: 'capitalize',
        color: 'blueberry.50',
      },
    },
    inner: {
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      child: {
        marginRight: 's',
      },
    },
  },
};
