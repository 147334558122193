import React from 'react';

import Svg from '../components/Svg';

export default function FeatureArrowIcon({ ...props }) {
  return (
    <Svg height="32" viewBox="0 0 32 32" width="32" {...props}>
      <path
        clipRule="evenodd"
        d="m15.7803 12.2803c.2929-.2929.2929-.7677 0-1.0606s-.7677-.2929-1.0606 0l-4.5 4.5c-.1465.1464-.2197.3384-.2197.5303 0 .1017.0202.1987.0569.2871.0358.0864.0884.1676.1579.2383m.0053.0054 4.4996 4.4995c.2929.2929.7677.2929 1.0606 0s.2929-.7677 0-1.0606l-3.2196-3.2197h9.1893c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75h-9.1893l3.2196-3.2197"
        fillRule="evenodd"
      />
    </Svg>
  );
}
