/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useSelector } from 'react-redux';

import BookCover from '../../components/BookCover';
import ProfileReviewMeta from '../../components/ProfileReviewMeta';
import ByPerson from '../../components/ByPerson';
import ContentLoader from '../../components/ContentLoader';

import {
  getBookAuthors,
  getBookSlug,
  getBookSubTitle,
  getBookTitle,
  getProfile,
  getProfileDisplayName,
  getProfileAvatar,
} from '../../selectors';

import { getBookRoute, getProfileRoute } from '../../constants';

export default function ProfileBookHighlightsHeader({
  book,
  isLoading,
  preText,
  username,
}) {
  const user = useSelector(getProfile(username));
  const displayName = getProfileDisplayName(user);
  const profileRoute = getProfileRoute(username);
  const avatar = getProfileAvatar(user);

  if (isLoading) {
    return (
      <header sx={sx.header}>
        <div sx={sx.cover}>
          <ContentLoader sx={sx.loader.book} />
        </div>
        <div sx={{ flex: 1 }}>
          <ContentLoader sx={sx.loader.h1} />
          <ContentLoader sx={sx.loader.h2} />
          <ContentLoader sx={sx.loader.h3} />
          <ContentLoader sx={sx.loader.user} />
        </div>
      </header>
    );
  }

  return (
    <header sx={sx.header}>
      <div sx={sx.cover}>
        <BookCover
          thumbnail={book.imageLinks.thumbnail}
          thumbnail2x={book.imageLinks.thumbnail}
          title={book.title}
          width={[64, 64, 90]}
          withAspectRatio
          visibleByDefault
        />
      </div>
      <div>
        <ProfileReviewMeta
          author={getBookAuthors(book.authors)}
          link={getBookRoute(getBookSlug(book))}
          subtitle={getBookSubTitle(book)}
          title={getBookTitle(book)}
        />
        <div sx={sx.byPerson}>
          <ByPerson
            displayName={displayName}
            profileRoute={profileRoute}
            avatarUrl={avatar}
            preText={preText}
          />
        </div>
      </div>
    </header>
  );
}

const sx = {
  container: {
    paddingTop: [0, 0, 'm'],
    paddingBottom: ['s', 's', 'm', 'xl'],
  },
  header: {
    marginBottom: ['s', 's', 'xl'],
    display: 'flex',
    alignItems: 'center',
  },
  cover: {
    marginRight: ['s', 's', 'm'],
  },
  byPerson: {
    marginTop: 'xs',
  },
  loader: {
    book: {
      width: [64, 64, 90],
      height: [98, 98, 138],
      borderRadius: 'book',
    },
    h1: {
      width: ['80%', '80%', 300],
      height: [20, 20, 30],
      borderRadius: 'book',
    },
    h2: {
      width: ['90%', '90%', 400],
      height: [10, 10, 20],
      marginY: 6,
      borderRadius: 'book',
    },
    h3: {
      width: ['60%', '60%', 180],
      height: [10, 10, 20],
      borderRadius: 'book',
    },
    user: {
      width: ['35%', '35%', 120],
      height: [9, 9, 18],
      marginTop: 6,
      borderRadius: 'book',
    },
    highlight: {
      width: '100%',
      borderRadius: 'button',
    },
  },
};
