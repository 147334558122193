/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Textarea as ThemeTextarea } from 'theme-ui';

const Textarea = React.forwardRef(({ sx, ...props }, ref) => (
  <ThemeTextarea
    {...props}
    sx={{
      minHeight: 146,
      paddingX: 's',
      paddingTop: '12px',
      outline: 'none',
      resize: 'vertical',
      borderRadius: 'button',

      fontSize: 'xs',
      fontFamily: 'body',
      lineHeight: 'body',

      transition: 'smooth.fast',
      '&::-moz-placeholder': {
        opacity: 0.5,
        color: 'blueberry.100',
        transition: 'smooth.fast',
      },
      '::-webkit-input-placeholder': {
        opacity: 0.5,
        color: 'blueberry.100',
        transition: 'smooth.fast',
      },
      '&:-ms-input-placeholder': {
        opacity: 0.5,
        color: 'blueberry.100',
        transition: 'smooth.fast',
      },
      '&:focus': {
        '&::-moz-placeholder': {
          opacity: 0.7,
        },
        '::-webkit-input-placeholder': {
          opacity: 0.7,
        },
        '&:-ms-input-placeholder': {
          opacity: 0.7,
        },
      },
      ...sx,
    }}
    ref={ref}
  />
));

export default Textarea;
