/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import range from 'lodash-es/range';

import GhostButton from '../../components/GhostButton';
import ProfileReviewBlock from '../../components/ProfileReviewBlock';
import BookReviewBlock from '../../components/BookReviewBlock';

import ProfileWrap from './ProfileWrap';
import ProfileEmptyState from './ProfileEmptyState';

import {
  getBookAuthors,
  getProfile,
  getProfileDisplayName,
  getProfileReviewCount,
  getProfileReviewThreadId,
  getReviewAndBookForThread,
  getThreadById,
  getThreadItems,
  getToken,
  getUsername,
  getBookTitle,
  getBookSubTitle,
} from '../../selectors';

import { fetchProfileReviews } from '../../ducks/reviews';
import { getBookRoute } from '../../constants';

export default function ProfileReviews() {
  const match = useRouteMatch();
  const { username } = match.params;
  const user = useSelector(getProfile(username));
  const name = getProfileDisplayName(user);
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const currentUser = useSelector(getUsername);
  const reviewThreadId = getProfileReviewThreadId(username);
  const thread = useSelector(getThreadById(reviewThreadId));
  const items = getThreadItems(thread);
  const hasNextPage = !!thread?.nextLink;

  const [isLoading, setIsLoading] = useState(!items.length);

  const fetchThread = () => {
    fetchProfileReviews({ username, token, dispatch, thread }).catch(e => {
      console.error(e);
      toast("Oops, something's broken. Unable to load more reviews.", {
        autoClose: 8000,
      });
    });
  };

  useEffect(() => {
    if (isLoading) {
      fetchProfileReviews({ username, token, dispatch })
        .then(() => {
          setIsLoading(false);
        })
        .catch(e => {
          console.error(e);
          setIsLoading(false);
        });
    }
  }, []);

  const reviews = useSelector(getReviewAndBookForThread(thread));
  const isEmpty = reviews.length === 0;
  const profileReviewCount = getProfileReviewCount(user);

  // if we expect the request to return 0 reviews we can skip the loaders
  // and let it render the empty state instead
  if (isLoading && profileReviewCount !== 0) {
    return (
      <ProfileWrap title=" / Reviews" shouldShowEmptyState>
        <div sx={sx.container}>
          {range(0, Math.min(profileReviewCount, 10)).map(loader => (
            <BookReviewBlock key={loader} isLoading />
          ))}
        </div>
      </ProfileWrap>
    );
  }

  if (isEmpty) {
    return (
      <ProfileEmptyState
        title=" / Reviews"
        heading="Nothing to see here."
        subheading={`${name} hasn't reviewed any books.`}
      />
    );
  }

  return (
    <ProfileWrap title=" / Reviews">
      <div sx={sx.container}>
        {reviews.map(({ review, book }) => (
          <ProfileReviewBlock
            bookAuthor={getBookAuthors(book.authors)}
            bookId={book.id}
            bookLink={getBookRoute(book.slug)}
            bookSlug={book.slug}
            bookSubtitle={getBookSubTitle({
              title: book.title,
              subtitle: book.subtitle,
            })}
            bookThumbnail={book.thumbnail}
            bookTitle={getBookTitle({
              title: book.title,
              subtitle: book.subtitle,
            })}
            date={moment(review.addedAt).format('DD MMM YYYY')}
            hasSpoilers={review.hasSpoilers}
            id={review.id}
            key={review.id}
            ownReview={currentUser === review.reviewedBy.username}
            recommended={review.recommended}
            review={review.text}
            tags={review.tags}
          />
        ))}
      </div>
      {hasNextPage && (
        <div sx={sx.loadMoreButton}>
          <GhostButton onClick={fetchThread}>Load more reviews</GhostButton>
        </div>
      )}
    </ProfileWrap>
  );
}

const sx = {
  container: {
    paddingTop: 'xl',
  },
  loadMoreButton: {
    marginBottom: ['s', 's', 'l', 'xl'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: [0, 0, 0, 132],
  },
};
