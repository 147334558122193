import React from 'react';

import Svg from '../../components/Svg';

export default function HighlightsIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m14 6c-4.41827 0-8 3.58173-8 8 0 2.6397 1.27848 4.9808 3.25 6.4379v-4.1879c0-.4641.1844-.9092.51253-1.2375.26867-.2686.61577-.4409.98747-.4945v-3.518c0-.3788.214-.7251.5528-.8944l4.5-2.25003c.31-.155.6781-.13842.9729.04378.2948.18221.4743.50408.4743.85065v5.768c.3717.0536.7188.2259.9875.4945.3281.3283.5125.7734.5125 1.2375v4.1879c1.9715-1.4571 3.25-3.7982 3.25-6.4379 0-4.41827-3.5817-8-8-8zm4.1865 17.0841c3.4319-1.5842 5.8135-5.0561 5.8135-9.0841 0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 4.028 2.38156 7.4999 5.81347 9.0841.0132.0064.02653.0126.04.0184 1.26323.5763 2.66733.8975 4.14653.8975s2.8833-.3212 4.1465-.8975c.0135-.0058.0268-.012.04-.0184zm-1.4365-1.5693v-5.0148h-.4984c-.0005 0-.0011 0-.0016 0s-.0011 0-.0016 0h-4.4968c-.0005 0-.0011 0-.0016 0s-.0011 0-.0016 0h-.4984v5.0148c.8575.3139 1.7837.4852 2.75.4852s1.8925-.1713 2.75-.4852zm-4-7.0148h2.5v-4.132l-2.5 1.25z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
