import mixpanel from 'mixpanel-browser';
import { batch } from 'react-redux';
import {
  getBookHighlightsUrl,
  getHighlightUrl,
  getUserHighlightsUrl,
} from '../constants';
import {
  getBookHighlightsThreadId,
  getUserHighlightsForBookThreadId,
  getUserHighlightsThreadId,
} from '../selectors/highlights';
import { callApi } from '../utils/api';
import objectReducer, { setKey, SET_KEY } from './object';
import { setItems, prependItems, removeItem } from './threads';

const SET_HIGHLIGHT = 'highlight.set';
const highlightsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_HIGHLIGHT:
      return objectReducer(state, {
        ...action,
        type: SET_KEY,
      });
    default:
      return state;
  }
};

export default highlightsReducer;

export function createHighlight({
  bookId,
  dispatch,
  position,
  spoiler,
  text,
  token,
  username,
}) {
  return callApi(getBookHighlightsUrl(bookId), {
    body: {
      contains_spoiler: spoiler,
      position,
      text,
    },
    method: 'POST',
    token,
  }).then(({ highlight, book, highlight_count }) => {
    const bookThread = getBookHighlightsThreadId(bookId);
    const userThread = getUserHighlightsThreadId(username);
    const userBookThread = getUserHighlightsForBookThreadId(username, book.id);

    batch(() => {
      dispatch(
        setKey({ type: SET_HIGHLIGHT, key: highlight.id, value: highlight })
      );

      dispatch(
        prependItems({
          threadId: userBookThread,
          items: [highlight.id],
        })
      );
      dispatch(
        prependItems({
          threadId: bookThread,
          items: [highlight.id],
          createIfNotExists: false,
        })
      );
      dispatch(
        removeItem(userThread, book.id, (action, threadItem) => {
          return action.item !== threadItem.book.id;
        })
      );
      dispatch(
        prependItems({
          threadId: userThread,
          items: [
            {
              book,
              highlightsThread: userBookThread,
              highlightCount: highlight_count,
            },
          ],
        })
      );
    });
    mixpanel.track('Create Highlight', {
      bookId,
    });
  });
}

export function saveExistingHighlight({
  highlightId,
  token,
  dispatch,
  username,
}) {
  return callApi(getHighlightUrl(highlightId), {
    method: 'POST',
    token,
  }).then(({ highlight, book, highlight_count }) => {
    const userThread = getUserHighlightsThreadId(username);
    const userBookThread = getUserHighlightsForBookThreadId(username, book.id);

    batch(() => {
      dispatch(
        setKey({
          type: SET_HIGHLIGHT,
          key: highlight.id,
          value: highlight,
        })
      );
      dispatch(
        prependItems({
          threadId: userBookThread,
          items: [highlight.id],
        })
      );
      dispatch(
        removeItem(userThread, book.id, (action, threadItem) => {
          return action.item !== threadItem.book.id;
        })
      );
      dispatch(
        prependItems({
          threadId: userThread,
          items: [
            {
              book,
              highlightsThread: userBookThread,
              highlightCount: highlight_count,
            },
          ],
        })
      );
    });
    mixpanel.track('Save Highlight', {
      bookId: book.id,
    });
  });
}

export function removeHighlight({ highlightId, token, dispatch, username }) {
  return callApi(getHighlightUrl(highlightId), {
    method: 'DELETE',
    token,
  }).then(({ book_id, highlight_count, highlight }) => {
    const userThread = getUserHighlightsThreadId(username);
    const userBookThread = getUserHighlightsForBookThreadId(username, book_id);
    setTimeout(() => {
      batch(() => {
        dispatch(
          setKey({ type: SET_HIGHLIGHT, key: highlight.id, value: highlight })
        );
        dispatch(removeItem(userBookThread, highlightId));

        if (highlight_count === 0) {
          dispatch(
            removeItem(userThread, book_id, (action, threadItem) => {
              return action.item !== threadItem.book.id;
            })
          );
        }
      });
    }, 700);
  });
}

export function fetchSpecificHighlight({ token, dispatch, highlightId }) {
  return callApi(getHighlightUrl(highlightId), {
    token,
  }).then(({ book, highlight }) => {
    dispatch(
      setKey({ type: SET_HIGHLIGHT, key: highlight.id, value: highlight })
    );
    return { book, highlight };
  });
}

export function fetchHighlightsForBook({ dispatch, token, bookId }) {
  return callApi(getBookHighlightsUrl(bookId), {
    token,
  }).then(data => {
    const threadId = getBookHighlightsThreadId(bookId);
    batch(() => {
      const highlightIds = data.results.map(highlight => {
        dispatch(
          setKey({ type: SET_HIGHLIGHT, key: highlight.id, value: highlight })
        );
        return highlight.id;
      });
      dispatch(setItems(threadId, highlightIds, data.next));
    });
  });
}

export function fetchHighlightsForUser({ dispatch, token, username }) {
  return callApi(getUserHighlightsUrl(username), {
    token,
  }).then(data => {
    batch(() => {
      const books = data.map(({ book, highlights, highlight_count }) => {
        // add the highlights to their own thread so we can paginate them independently
        const highlightIds = highlights.results.map(highlight => {
          dispatch(
            setKey({ type: SET_HIGHLIGHT, key: highlight.id, value: highlight })
          );
          return highlight.id;
        });

        const highlightsThreadId = getUserHighlightsForBookThreadId(
          username,
          book.id
        );
        dispatch(setItems(highlightsThreadId, highlightIds, highlights.next));
        return {
          book,
          highlightsThread: highlightsThreadId,
          highlightCount: highlight_count,
        };
      });

      // also add the book & the highlights threadId to their own thread
      const threadId = getUserHighlightsThreadId(username);
      dispatch(setItems(threadId, books));
    });
  });
}

export function extractTextFromImage({ token, file }) {
  const requestData = new FormData();
  requestData.append('image', file);

  return fetch('/highlights/extract', {
    headers: {
      Authorization: `Token ${token}`,
    },
    method: 'post',
    body: requestData,
  }).then(async response => {
    mixpanel.track('Highlights: Extract From Photo');
    const data = await response.json();
    return data.text;
  });
}
