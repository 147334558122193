/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import React, { useEffect, useState } from 'react';

import {
  Link as RouterLink,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import Avatar from '../../components/Avatar';
import Badge from '../../components/Badge';
import FollowButton from '../../components/FollowButton';
import FullHeight from '../../components/FullHeight';
import Heading from '../../components/Heading';
import Link from '../../components/Link';
import LoadingPage from '../Loading';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';
import PageNavigation from '../../components/PageNavigation';
import SettingsButton from '../../components/SettingsButton';
import Text from '../../components/Text';

import ProfileTabs from './ProfileTabs';

import {
  getProfile,
  getProfileAvatar,
  getProfileBio,
  getProfileDisplayName,
  getProfileFollowing,
  getProfileFollows,
  getProfileFullName,
  getToken,
  getUsername,
  getIsAuthenticated,
  getProfileTier,
} from '../../selectors';

import {
  getCollectionsRoute,
  getProfileFollowersRoute,
  getProfileFollowingRoute,
  getProfileHighlightsRoute,
  getProfileReviewsRoute,
  getProfileRoute,
  TIERS,
} from '../../constants';

import { urlEndsWith } from '../../utils';
import { shouldFetchProfile } from '../../ducks/profile';

export default function ProfileWrap({
  children,
  shouldShowEmptyState,
  shouldShowTabs = true,
  shouldShowUserDetails = true,
  title = '',
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const currentUser = useSelector(getUsername);
  const token = useSelector(getToken);
  const isAuthenticated = !!useSelector(getIsAuthenticated);

  const { username } = match.params;
  const matchUrl = match.url;
  const user = useSelector(getProfile(username));

  // if the initial route to a user's profile is via the collection page,
  // there's a chance `user` has a collection in it and nothing else. This
  // breaks down if we use !user for checking whether we need to show the
  // load or not, so isFetched should be used instead
  const isFetched = user?.details?.username && user?.details?.image;
  const [isLoading, setIsLoading] = useState(!isFetched);

  const FOLLOWING_ROUTE = getProfileFollowingRoute(username);
  const FOLLOWERS_ROUTE = getProfileFollowersRoute(username);

  const PROFILE_ROUTE = getProfileRoute(username);
  const CURRENT_PROFILE_ROUTE = getProfileRoute(currentUser);
  const CURRENT_COLLECTIONS_ROUTE = getCollectionsRoute(currentUser);
  const CURRENT_REVIEWS_ROUTE = getProfileReviewsRoute(currentUser);
  const CURRENT_FOLLOWING_ROUTE = getProfileFollowingRoute(currentUser);
  const CURRENT_FOLLOWERS_ROUTE = getProfileFollowersRoute(currentUser);
  const CURRENT_HIGHLIGHTS_ROUTE = getProfileHighlightsRoute(currentUser);

  const isFollowingActive = matchUrl.includes(FOLLOWING_ROUTE);

  useEffect(() => {
    if (!isFetched) {
      shouldFetchProfile({ dispatch, username, token });
    } else {
      setIsLoading(false);
    }
  }, [username, token]);

  useEffect(() => {
    const stateUsername = user?.details?.username;
    if (stateUsername && stateUsername !== username) {
      // if the username in the url doesn't match the data, redirect
      const newPath = location.pathname.replace(username, stateUsername);
      dispatch(push(newPath));
    }
  }, [user]);

  if (isLoading && !isFetched) {
    return <LoadingPage />;
  }

  const bio = getProfileBio(user);
  const avatar = getProfileAvatar(user);
  const fullName = getProfileFullName(user);
  const displayName = getProfileDisplayName(user);
  const isFollowing = getProfileFollowing(user);
  const { followers, following } = getProfileFollows(user);

  let extraTitle;

  if (!shouldShowUserDetails && isFollowingActive) {
    extraTitle = ' follows';
  }

  if (!shouldShowUserDetails && !isFollowingActive) {
    extraTitle = "'s followers";
  }

  let followersCopy = null;
  if (username === currentUser && followers?.length !== undefined) {
    followersCopy = `${followers.length} follow you`;
  }

  let followingCopy = null;
  if (following?.length !== undefined) {
    if (username === currentUser) {
      followingCopy = `Following ${following?.length}`;
    } else if (following.length > 0) {
      followingCopy = `Follows ${following.length}`;
    }
  }

  // Show settings button on your profile if on
  // - library
  // - collections
  // - reviews
  // - your follows
  // - your highlights
  const showSettingsButton =
    isAuthenticated &&
    (urlEndsWith(matchUrl, CURRENT_PROFILE_ROUTE) ||
      urlEndsWith(matchUrl, CURRENT_COLLECTIONS_ROUTE) ||
      urlEndsWith(matchUrl, CURRENT_REVIEWS_ROUTE) ||
      urlEndsWith(matchUrl, CURRENT_FOLLOWING_ROUTE) ||
      urlEndsWith(matchUrl, CURRENT_FOLLOWERS_ROUTE) ||
      urlEndsWith(matchUrl, CURRENT_HIGHLIGHTS_ROUTE));

  // Show badge based on profile tier, crew is hardcoded for now
  const planTier = getProfileTier(user);
  const badgeKind =
    username === 'joe' ||
    username === 'aziz' ||
    username === 'yavor' ||
    username === 'Oku'
      ? 'Crew'
      : {
          free: null,
          premium: TIERS.PREMIUM,
          supporter: TIERS.SUPPORTER,
        }[planTier];

  // Show back button if
  // - profile is not your own
  // - you're on following/followers
  const showBackButton =
    username !== currentUser ||
    urlEndsWith(matchUrl, CURRENT_FOLLOWING_ROUTE) ||
    urlEndsWith(matchUrl, CURRENT_FOLLOWERS_ROUTE);

  const noPadding = shouldShowEmptyState && {
    paddingBottom: 0,
  };

  return (
    <Page title={`${displayName} / Profile${title}`}>
      <FullHeight>
        {isAuthenticated && (
          <PageNavigation showBackButton={showBackButton} showShareButton>
            {username !== currentUser && (
              <FollowButton
                isFollowing={isFollowing}
                user={currentUser}
                userToFollow={username}
              />
            )}
            {showSettingsButton && <SettingsButton />}
          </PageNavigation>
        )}
        <PageBody extraStyles={{ ...sx.pageBody, ...noPadding }}>
          <div>
            <div sx={sx.header}>
              <div>
                <div sx={sx.fullName}>
                  <Heading variant="h1" fontFamily="serif">
                    {fullName}
                    {extraTitle}
                  </Heading>
                  {!extraTitle && (
                    <div sx={sx.badge}>
                      <Badge kind={badgeKind} />
                    </div>
                  )}
                </div>
                {shouldShowUserDetails && (
                  <React.Fragment>
                    <Text variant="body" sx={sx.bio}>
                      {bio}
                    </Text>
                    {(followingCopy || followersCopy) && (
                      <Flex sx={sx.links}>
                        <Link
                          as={RouterLink}
                          hasUnderline
                          sx={sx.link}
                          to={PROFILE_ROUTE}
                          variant="body"
                        >
                          @{username}
                        </Link>
                        {!!followingCopy && (
                          <React.Fragment>
                            <Text variant="body" sx={sx.middot}>
                              &middot;
                            </Text>
                            <Link
                              as={RouterLink}
                              hasUnderline
                              sx={sx.link}
                              to={FOLLOWING_ROUTE}
                              variant="body"
                            >
                              {followingCopy}
                            </Link>
                          </React.Fragment>
                        )}
                        {!!followersCopy && (
                          <React.Fragment>
                            <Text variant="body" sx={sx.middot}>
                              &middot;
                            </Text>
                            <Link
                              as={RouterLink}
                              hasUnderline
                              sx={sx.link}
                              to={FOLLOWERS_ROUTE}
                              variant="body"
                            >
                              {followersCopy}
                            </Link>
                          </React.Fragment>
                        )}
                      </Flex>
                    )}
                  </React.Fragment>
                )}
              </div>
              {shouldShowUserDetails && (
                <Avatar size={80} name={fullName} src={avatar} src2x={avatar} />
              )}
            </div>
            <ProfileTabs
              shouldShowTabs={shouldShowTabs}
              shouldShowUserDetails={shouldShowUserDetails}
            />
          </div>
          {children}
        </PageBody>
      </FullHeight>
    </Page>
  );
}

const sx = {
  header: {
    display: 'flex',
    alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'],
    justifyContent: ['flex-start', 'flex-start', 'flex-start', 'space-between'],
    flexDirection: [
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'row',
    ],
  },
  fullName: {
    marginTop: ['m', 'm', 'm', '0'],
    marginBottom: 'xxs',
    display: 'flex',
    alignItems: 'center',
  },
  bio: {
    maxWidth: 540,
  },
  links: {
    marginTop: 's',
    alignItems: 'center',
  },
  link: {
    fontSize: 'xxs',
    display: 'block',
    height: 18,
  },
  middot: {
    height: 18,
    lineHeight: '16px',
    fontSize: 'xxs',
    marginX: 'xs',
  },
  badge: {
    marginLeft: 'xs',
  },
  pageBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};
