/** @jsxRuntime classic */
/** @jsx jsx */
import { push } from 'connected-react-router';
import { useEffect, useState, useRef } from 'react';
import { jsx } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import {
  clearSearchResults,
  searchLoading,
  setAuthorHints,
  shouldSearch,
} from '../../ducks/search';

import {
  getBookSlug,
  getSearchBookResults,
  getSearchLoading,
  getSearchParameters,
  getSearchUserResults,
  getToken,
} from '../../selectors';

import { girlReading, girlReading2x } from '../../assets';

import {
  LANGUAGE_PREF,
  SEARCH_USERS_ROUTE,
  getBookRoute,
} from '../../constants';

import FullHeight from '../../components/FullHeight';
import Image from '../../components/Image';
import Page from '../../components/Page';
import PageBody from '../../components/PageBody';

import SearchBooksBody from './SearchBooksBody';
import SearchHeader from './SearchHeader';
import SearchUsersBody from './SearchUsersBody';

export default function Search() {
  const inputElement = useRef(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const isSearchingForUsers = pathname === SEARCH_USERS_ROUTE;
  const [inputValue, setInputValue] = useState('');
  const [friendsLoading, setFriendsLoading] = useState(true);
  const [exploreLoading, setExploreLoading] = useState(true);
  const [hasSearched, setHasSearched] = useState(false);
  const [authorFilter, setAuthorFilter] = useState(null);

  const token = useSelector(getToken);
  const isLoading = useSelector(getSearchLoading);
  const parameters = useSelector(getSearchParameters);

  const books = useSelector(getSearchBookResults);
  const bookIds = Object.keys(books);

  const users = useSelector(getSearchUserResults);
  const userIds = Object.keys(users);

  const initialLang = localStorage.getItem(LANGUAGE_PREF) || 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(initialLang);
  const onLanguageSelect = lang => {
    setSelectedLanguage(lang);
  };

  const onClearResults = () => {
    setInputValue('');
    dispatch(clearSearchResults());
    setAuthorFilter(null);
    dispatch(setAuthorHints([]));
    inputElement.current.focus();
    setHasSearched(false);
  };

  const onSearch = () => {
    if (inputValue === '') {
      onClearResults();
    } else {
      setHasSearched(true);
      dispatch(searchLoading());
      dispatch(clearSearchResults());
      shouldSearch({
        dispatch,
        isSearchingForUsers,
        lang: selectedLanguage,
        author: authorFilter,
        query: inputValue,
        token,
      }).then(({ searchAlgorithm, results }) => {
        const searchingFor = isSearchingForUsers ? 'Users' : 'Books';
        const eventData = {
          searchingFor,
          language: selectedLanguage,
        };
        if (authorFilter !== null) {
          eventData.authorFilter = authorFilter;
        }
        if (!isSearchingForUsers) {
          eventData.algorithm = searchAlgorithm;
        }
        mixpanel.track('Search', eventData);

        if (results?.length === 1 && !isLoading && !authorFilter) {
          // Redirect if there's only one book search result (e.g. when somebody pastes ISBN),
          // but not when somebody filters the results by author, since it tends to be different
          // to the book the user is actually looking for
          const slug = getBookRoute(getBookSlug(results[0]));
          setExploreLoading(true);
          setHasSearched(false);
          setTimeout(() => {
            dispatch(push(slug));
          }, 400);
        }
      });
    }
  };

  const onInputChange = event => {
    const { value } = event.target;
    setInputValue(value);
    if (value === '') {
      onClearResults();
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    if (inputValue !== '') {
      onSearch();
    }
  };

  useEffect(() => {
    // search again when language is changed or cleared
    if (inputValue !== '') {
      onSearch();
    }
  }, [selectedLanguage, authorFilter]);

  // Infinite scroll
  const handleScroll = () => {
    if (!parameters.query) {
      return;
    }
    const body = document.body;
    const html = document.documentElement;
    const scrollViewHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const viewportHeight = window.innerHeight;
    const searchResultHeight = 128;
    const threshold =
      scrollViewHeight - 2 * searchResultHeight - viewportHeight;

    if (window.scrollY < threshold || isLoading) {
      return;
    }

    shouldSearch({
      ...parameters,
      dispatch,
      author: authorFilter,
      token,
      startIndex: parameters.startIndex + 10,
      lang: selectedLanguage,
    });
  };

  // Prefill search value
  useEffect(() => {
    setInputValue(parameters.query);
  }, []);

  // Infinite scroll
  useEffect(() => {
    // We don't have pagination on the users tab
    if (!isSearchingForUsers) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [parameters, isLoading]);

  // Focus the input on page load (but not when on the books page on mobile)
  const notOnMobile = useResponsiveValue([false, false, true]);

  useEffect(() => {
    if (isSearchingForUsers || (!isSearchingForUsers && notOnMobile)) {
      inputElement.current.focus();
    }
  }, [inputElement, isSearchingForUsers, notOnMobile]);

  const isInputEmpty = inputValue === '';
  const isBooksPageLoading = isLoading || exploreLoading || friendsLoading;
  const noUsers = userIds.length === 0;
  const noBooks = bookIds.length === 0;

  // We need to show the empty state when
  //
  // -> the page is loading
  // -> there are no results on the users page AND you have or haven't searched for stuff
  // -> there are no results on the books page AND you have searched for stuff
  //
  // If there are no results on the books page AND you haven't searched for stuff,
  // we show the explore page hence this should be false.
  const showEmptyState =
    (isSearchingForUsers && (isLoading || noUsers)) ||
    (!isSearchingForUsers && isBooksPageLoading && noBooks) ||
    (!isSearchingForUsers && noBooks && !isInputEmpty && hasSearched);

  return (
    <Page title="Explore, search for books or authors">
      <FullHeight flexDirection="row">
        <PageBody
          extraStyles={showEmptyState ? sx.flexContainer : sx.normalContainer}
        >
          <SearchHeader
            inputValue={inputValue}
            isSearchingForUsers={isSearchingForUsers}
            onClearResults={onClearResults}
            onInputChange={onInputChange}
            onLanguageSelect={onLanguageSelect}
            onSubmit={onSubmit}
            ref={inputElement}
            selectedLanguage={selectedLanguage}
            showAddBookButton={!isSearchingForUsers}
          />
          {isSearchingForUsers ? (
            <SearchUsersBody
              hasSearched={hasSearched}
              isInputEmpty={isInputEmpty}
              isLoading={isLoading}
              userIds={userIds}
              users={users}
            />
          ) : (
            <SearchBooksBody
              authorFilter={authorFilter}
              bookIds={bookIds}
              books={books}
              hasSearched={hasSearched}
              isInputEmpty={isInputEmpty}
              isLoading={isBooksPageLoading}
              setAuthorFilter={setAuthorFilter}
              setExploreLoading={setExploreLoading}
              setFriendsLoading={setFriendsLoading}
            />
          )}
          {showEmptyState && (
            <figure sx={sx.image}>
              <Image
                alt="Girl reading a book"
                blendIn
                maxWidth={300}
                respondToDarkMode
                src={girlReading}
                src2x={girlReading2x}
                width="100%"
              />
            </figure>
          )}
        </PageBody>
      </FullHeight>
    </Page>
  );
}

const sx = {
  flexContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  normalContainer: {
    paddingTop: 0,
  },
  container: {
    width: '100%',
  },
  text: {
    maxWidth: 420,
    marginTop: 'xs',
    textAlign: 'center',
  },
  image: {
    bg: 'milk',
    minHeight: [254, 254, 204, 204],
    overflow: 'hidden',
    '> span': {
      marginBottom: [-50, -50, -100, -100],
    },
  },
};
