/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProfileWrap from './ProfileWrap';
import CreateCollection from './CreateCollection';
import Collection from './Collection';

import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Text from '../../components/Text';

import {
  getUsername,
  getProfile,
  getProfileLists,
  getProfileDisplayName,
  getProfileDisplayListKeys,
} from '../../selectors';

import { girlReading, girlReading2x } from '../../assets';

export default function Collections() {
  const match = useRouteMatch();
  const currentUser = useSelector(getUsername);

  const { username } = match.params;
  const user = useSelector(getProfile(username));

  const lists = getProfileLists(user);
  const name = getProfileDisplayName(user);
  const listKeys = getProfileDisplayListKeys(user);
  const isCurrentUser = currentUser === username;

  const columns = [
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
  ];

  if (!isCurrentUser && listKeys.length === 0) {
    return (
      <ProfileWrap title=" / Collections" shouldShowEmptyState>
        <div sx={sx.emptyState}>
          <div />
          <div sx={sx.emptyStateBody}>
            <Heading as="h2" variant="h2">
              There&apos;s nothing to show.
            </Heading>
            <Text as="p" variant="body">
              {name} isn&apos;t much of a collector
            </Text>
          </div>
          <figure sx={sx.image}>
            <Image
              alt="Girl reading a book"
              maxWidth={300}
              respondToDarkMode
              src={girlReading}
              src2x={girlReading2x}
              width="100%"
            />
          </figure>
        </div>
      </ProfileWrap>
    );
  }

  return (
    <ProfileWrap title=" / Collections">
      <Grid
        gap={['s', 's', 'm']}
        columns={columns}
        marginTop={['s', 's', 'm']}
        marginBottom={['s', 's', 'm', 'xl']}
      >
        {isCurrentUser && <CreateCollection />}
        {listKeys.map(key => {
          return (
            <Collection
              isCurrentUser={isCurrentUser}
              key={key}
              list={lists[key]}
              username={username}
            />
          );
        })}
      </Grid>
    </ProfileWrap>
  );
}

const sx = {
  emptyState: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 'xl',
  },
  emptyStateBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '>p': {
      marginTop: 'xs',
    },
  },
  image: {
    minHeight: [254, 254, 204, 204],
    overflow: 'hidden',
    '> span': {
      marginBottom: [-50, -50, -100, -100],
    },
  },
};
