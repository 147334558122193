/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Loader from '../components/Loader';

export default function InviteUsers() {
  return (
    <div sx={sx.loader}>
      <Loader />
    </div>
  );
}

const sx = {
  loader: {
    padding: 'xxl',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
