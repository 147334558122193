/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import Text from './Text';

export default function Label({
  id,
  error,
  children,
  label,
  variant = 'body',
}) {
  const sx = {
    display: 'inline-block',
    marginBottom: '12px',
    color: error ? 'candy' : 'blueberry.80',
  };
  if (!label || !id) {
    return children;
  }
  return (
    <label htmlFor={id}>
      <Text as="span" variant={variant} sx={sx}>
        {error || label}
      </Text>
      {children}
    </label>
  );
}
