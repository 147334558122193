/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import BlogLink from './Blog/BlogLink';
import BlogPageWrap from './Blog/BlogPageWrap';

export default function PrivacyPage() {
  return (
    <BlogPageWrap title="Privacy Policy">
      <time>Last updated Feb 2021, effective Feb 2021</time>
      <h1>Privacy Policy</h1>

      <p>
        The long and short of it is this:{' '}
        <strong>We collect only what we need to</strong>, and{' '}
        <strong>share as little as we can</strong>, for reasons like error
        tracking and consensual email marketing.{' '}
        <strong>We don&apos;t sell your data</strong> and we{' '}
        <strong>avoid services that track you</strong> to sell ads. That&apos;s
        the core of our philosophy, but read on for details.
      </p>

      <h2>Data we collect</h2>
      <p>
        When using the service we may log your <strong>IP address</strong> to
        help us prevent abuse. When registering for an account we collect two
        further pieces of personal information: <strong>email address</strong>{' '}
        and <strong>username</strong>.
      </p>

      <h2>Your rights & Contact us</h2>
      <p>
        We&apos;ll delete any data we have on you at your request. You can also
        ask for a copy of your personal data, or request that we update it.
        Please email us at{' '}
        <BlogLink href="mailto:data@oku.club">data@oku.club</BlogLink>.
      </p>

      <h2>Third parties & data sharing</h2>
      <p>
        We avoid it where we can, but sometimes we have to share data when we
        make use of third party services. Here&apos;s the list of third parties
        that we share information with.
      </p>
      <ul>
        <li>
          <BlogLink href="https://plausible.io">Plausible Analytics</BlogLink>:
          A website analytics tool that makes their money directly from us, not
          from tracking people to sell them ads. When using our website
          Plausible will receive your <strong>IP address</strong>.
        </li>

        <li>
          <BlogLink href="https://sentry.io">Sentry</BlogLink>: An error
          tracking service that we use to track bugs and other issues as they
          arise within the service. Sentry may receive your{' '}
          <strong>IP address</strong> and an identifier unique to your account.
        </li>

        <li>
          <BlogLink href="https://substack.com.io">Substack</BlogLink>: We share
          your <strong>email address</strong> with substack in order to send out
          regular product update emails. You can unsubscribe from these.
        </li>

        <li>
          <BlogLink href="https://aws.amazon.com/ses/">
            Amazon Web Services (SES)
          </BlogLink>
          : Amazon Web Services is our email service provider for transactional
          emails. These are things like password reset emails and notifications.
          They will receive your <strong>email address</strong>.
        </li>

        <li>
          <BlogLink href="https://mailchimp.com">Mailchimp</BlogLink>: If you
          registered for our waiting list before we allowed open signups, your{' '}
          <strong>email address</strong> was collected with mailchimp.
        </li>
      </ul>

      <h2>Policy Updates</h2>
      <p>
        We&apos;ll need to update this policy from time to time. Our philosophy
        won&apos;t change, but we&apos;ll let you know if there are major
        changes to the policy & and give you a chance to agree to them.
      </p>
    </BlogPageWrap>
  );
}
