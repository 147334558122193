/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';

import CreateHighlightModal from '../../modals/CreateHighlight';
import PrimaryButton from '../../components/PrimaryButton';
import ContentLoader from '../../components/ContentLoader';

export default function CreateHighlightButton({
  closeModal,
  openModal,
  selectedBook,
  showModal,
  isLoading,
}) {
  return (
    <React.Fragment>
      {isLoading ? (
        <ContentLoader sx={sx.loader} />
      ) : (
        <PrimaryButton sx={sx.button} onClick={openModal}>
          + New highlight
        </PrimaryButton>
      )}
      <CreateHighlightModal
        book={selectedBook}
        showModal={showModal}
        closeModal={closeModal}
      />
    </React.Fragment>
  );
}

const sx = {
  loader: {
    width: 154,
    height: 40,
    borderRadius: 'round',
  },
  button: {
    borderRadius: 'round',
  },
};
