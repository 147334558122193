/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Link from './Link';
import Button from './Button';
import Text from './Text';
import ModalDialog from './ModalDialog';
import FeedbackModal from '../modals/Feedback';

import { MenuFeedbackIcon } from '../icons';
import { getMenuRoutes, SEARCH_ROUTE } from '../constants';
import { getUsername, hasFeature, FEATURES } from '../selectors';

export default function Navigation() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const sx = getStyles({ isDark });
  const location = useLocation();
  const username = useSelector(getUsername);
  const hasHighlights = useSelector(hasFeature(FEATURES.HIGHLIGHTS));
  const routes = getMenuRoutes(username, isDark, hasHighlights);

  const closeFeedbackModal = () => {
    setIsFeedbackOpen(false);
  };

  const openFeedbackModal = () => {
    setIsFeedbackOpen(true);
    window.plausible('open-feedback');
  };

  const notOnSearch = !location.pathname.includes(SEARCH_ROUTE);

  return (
    <React.Fragment>
      {notOnSearch && isDark && <div sx={{ ...sx.gradient, ...sx.top }} />}
      {isDark && <div sx={{ ...sx.gradient, ...sx.bottom }} />}
      <div sx={sx.container}>
        <nav sx={sx.menu}>
          {routes.map(route => {
            const isActive =
              route.to === routes[routes.length - 1].to // don't wanna make it active on all profiles, just your own
                ? location.pathname === route.to
                : location.pathname.includes(route.to);
            const linkVariant = isActive ? 'menu.active' : 'menu.default';
            const Icon = route.icon;
            const as = route.to ? RouterLink : undefined;
            return (
              <Link
                as={as}
                sx={sx.link}
                to={route.to}
                key={route.id}
                variant={linkVariant}
              >
                <span sx={sx.icon}>
                  <Icon />
                </span>
                <Text variant="menu">{route.name}</Text>
              </Link>
            );
          })}
        </nav>
        <Button onClick={openFeedbackModal} size="iconBig" sx={sx.button}>
          <MenuFeedbackIcon />
        </Button>
      </div>
      <ModalDialog isVisible={isFeedbackOpen} onClose={closeFeedbackModal}>
        <FeedbackModal />
      </ModalDialog>
    </React.Fragment>
  );
}

const getStyles = ({ isDark }) => ({
  container: {
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    bg: isDark ? 'blueberry.5' : 'milk',
    paddingBottom: 'env(safe-area-inset-bottom)',
    zIndex: 'navigation',
  },
  menu: {
    paddingTop: 'xs',
    paddingX: 'xxs',
    paddingBottom: 10,
    display: 'flex',

    bg: isDark ? 'blueberry.5' : 'milk',
    boxShadow: isDark ? 'menu.dark' : 'menu.light',
  },
  gradient: {
    display: 'block',
    position: 'fixed',
    width: '100%',
    left: 0,
    pointerEvents: 'none',
    zIndex: 'navigation',
  },
  bottom: {
    bottom: 28,
    height: 80,
    background:
      'linear-gradient(180deg, rgba(19,19,20,0) 0%, rgba(19,19,20,1))',
  },
  top: {
    top: 0,
    height: '48px',
    background:
      'linear-gradient(180deg, rgba(19,19,20,1) 0%, rgba(19,19,20,0))',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    flexDirection: 'column',
    flex: 1,
    userSelect: 'none',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    marginRight: 0,
  },
  button: {
    position: 'absolute',
    bottom: '100%',
    right: 12,
    marginBottom: 12,
    width: 50,
    height: 50,
  },
});
