/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jsx } from 'theme-ui';
import moment from 'moment';

import ActivityLoader from './ActivityLoader';
import ActivityPageWrap from './ActivityPageWrap';
import FeedEmptyState from './FeedEmptyState';
import Feed from './Feed';

import { getFriendsFeed, getToken } from '../../selectors';
import { shouldFetchFeed } from '../../ducks/feed';
import { ACTIVE_TAB_FRIENDS } from './constants';

export default function FeedFriends() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const feed = useSelector(getFriendsFeed());
  const items = feed.items;
  const wasRecentlyUpdated = moment()
    .subtract(45, 'seconds')
    .isBefore(feed.updated);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      shouldFetchFeed({ dispatch, token, friends: true })
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [feed.updated === undefined, wasRecentlyUpdated]);

  const noItems = items.length === 0;

  return (
    <ActivityPageWrap activeTab={ACTIVE_TAB_FRIENDS}>
      {isLoading && noItems && <ActivityLoader />}
      {!isLoading && noItems && <FeedEmptyState />}
      {!noItems && <Feed items={items} />}
    </ActivityPageWrap>
  );
}
