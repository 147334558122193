/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Image from '../../../components/Image';
import BlogPageWrap from '../BlogPageWrap';
import Link from '../BlogLink';

import {
  reviews,
  reviews2x,
  reviewPage,
  reviewPage2x,
  profileReviews,
  profileReviews2x,
} from '../../../assets';

export default function BlogPost() {
  return (
    <BlogPageWrap title="Reviews have arrived on Oku / Blog">
      <time>Apr 09, 2021</time>
      <h1>Reviews have arrived on Oku</h1>

      <p>
        We first gave our beta members the ability to review their books during
        our{' '}
        <Link href="https://twitter.com/okuclub/status/1368939605966356487">
          sprint week
        </Link>{' '}
        a month ago. Well, we&apos;ve just released the final big piece of the
        feature (for now), so it&apos;s about time I officially showed you
        around.
      </p>

      <h2>Here come community reviews!</h2>
      <p>
        On book pages, you&apos;ll now find a tab that we&apos;ve helpfully
        labelled Reviews. Click it and you&apos;ll see reviews from your friends
        before anybody else. If you&apos;ve read the book you even have the
        option to leave a review of your own, but don&apos;t forget to tag any
        spoilers!
      </p>

      <Link
        hasUnderline={false}
        href="https://oku.club/reviews/klara-and-the-sun-by-kazuo-ishiguro-DnaR1?utm_campaign=reviews+blog+post"
      >
        <Image
          src={reviews}
          src2x={reviews2x}
          alt="Two Screenshots of the mobile review experience. On the left we are reading a review, but on the right we are writing one of our own."
        />
      </Link>

      <br />

      <h2>Shareable links</h2>
      <p>
        Each review has its own link which you&apos;ll find by clicking the date
        or the share button. The book takes a step back so the review can take
        the focus. Any tags chosen by the reviewer when rating the book are also
        shown at the end of the review. Take{' '}
        <Link href="https://oku.club/reviews/too-close-to-the-falls-by-catherine-gildiner-sgVME/YLymf?utm_campaign=reviews+blog+post">
          Shelby&apos;s review of Too Close to the Falls
        </Link>
        :
      </p>

      <Link
        hasUnderline={false}
        href="https://oku.club/reviews/too-close-to-the-falls-by-catherine-gildiner-sgVME/YLymf?utm_campaign=reviews+blog+post"
      >
        <Image
          src={reviewPage}
          src2x={reviewPage2x}
          alt="Screenshots of both the web and mobile experience when viewing an individual review."
        />
      </Link>
      <br />
      <h2>Collected on your profile</h2>
      <p>
        Wander over to your profile and you&apos;ll find all your reviews
        displayed in one place.{' '}
        <Link href="https://oku.club/user/tessa/reviews?utm_campaign=reviews+blog+post">
          Tessa&apos;s reviews
        </Link>{' '}
        are a great example!
      </p>

      <Link
        hasUnderline={false}
        href="https://oku.club/user/tessa/reviews?utm_campaign=reviews+blog+post"
      >
        <Image
          src={profileReviews}
          src2x={profileReviews2x}
          alt="Desktop size screenshot of tessa's reviews on her Oku profile"
        />
      </Link>

      <br />

      <h2>Available now</h2>
      <p>
        Book reviews are available right now for beta members, which you can
        join for free below. Goodreads users can also{' '}
        <Link
          target="_self"
          href="https://oku.club/blog/2021/importing-your-data-from-goodreads?utm_campaign=reviews+blog+post"
        >
          import their existing books
        </Link>{' '}
        automatically, so don&apos;t miss out!
        <br />
        <br />
        As usual, don&apos;t be shy with your feeback, hit up our inbox below.
        Big thanks to those of you that already have!
      </p>

      <p>
        –{' '}
        <Link href="https://oku.club/user/joe?utm_campaign=reviews+blog+post">
          Joe
        </Link>
      </p>
    </BlogPageWrap>
  );
}
