/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import AspectImage from '../../components/AspectImage';

import Heading from '../../components/Heading';
// import Text from '../../components/Text';
import {
  graphsPreview,
  graphsPreview2x,
  graphsPreviewDark,
  graphsPreviewDark2x,
} from '../../assets';

export default function StatsBlock() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const imageArgs = {
    src: isDark ? graphsPreviewDark : graphsPreview,
    src2x: isDark ? graphsPreviewDark2x : graphsPreview2x,
  };
  return (
    <div sx={sx.wrapper}>
      <div sx={sx.container}>
        <div sx={sx.long}>
          <figure>
            <AspectImage
              aspectWidth={400}
              aspectHeight={303}
              maxWidth={800}
              width="100%"
              {...imageArgs}
            />
          </figure>
        </div>
        <div sx={sx.short}>
          <Heading as="h2" fontFamily="serif" variant="marketingTight">
            Unlock your
            <br /> personal statistics
          </Heading>
          {/* <Text as="p" color="blueberry.80" variant="body">
            Dig into your reading history. 53% of our members have imported
            their historical data from Goodreads. It&apos;s time for a change.
            Don&apos;t loathe your reading list –– liberate it.
          </Text> */}
        </div>
      </div>
    </div>
  );
}

const sx = {
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    maxWidth: 912,
    paddingX: 'l',
    marginX: 'auto',
    color: 'blueberry.100',

    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: ['center', 'center', 'left'],

    p: {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 'm',
    },
  },
  short: {
    width: ['100%', '100%', '100%', '45%', '45%'],
    marginTop: ['l', 'l', 'l', 0, 0, 0],
    paddingLeft: [0, 0, 0, 'xl'],
  },
  long: {
    width: ['100%', '100%', '100%', '50%'],
    figure: {
      position: 'relative',
      display: 'block',
      width: ['100%', '100%', '100%', '140%', '160%'],
      left: [0, 0, 0, '-40%', '-60%'],
      transform: ['rotate(0)', 'rotate(0)', 'rotate(0)', 'rotate(-1deg)'],
    },
  },
};
