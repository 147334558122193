/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import AspectImage from '../../components/AspectImage';

import Heading from '../../components/Heading';
import Text from '../../components/Text';

import {
  highlightsPage,
  highlightsPage2x,
  highlightsPageDark,
  highlightsPageDark2x,
} from '../../assets';

export default function HighlightsBlock() {
  const [colorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const imageArgs = {
    src: isDark ? highlightsPageDark : highlightsPage,
    src2x: isDark ? highlightsPageDark2x : highlightsPage2x,
  };
  return (
    <div sx={sx.wrapper}>
      <div sx={sx.container}>
        <div sx={sx.long}>
          <Heading as="h2" fontFamily="serif" variant="marketingTight">
            Easily highlight your favorite passages
          </Heading>
          <Text as="p" color="blueberry.80" variant="body">
            Keep your highlights {/* &amp; notes */} handy with our app or share
            them with a friend or study partner.
          </Text>
        </div>
        <div sx={sx.short}>
          <figure>
            <AspectImage
              aspectWidth={400}
              aspectHeight={303}
              maxWidth={800}
              width="100%"
              {...imageArgs}
            />
          </figure>
        </div>
      </div>
    </div>
  );
}

const sx = {
  wrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    maxWidth: 912,
    paddingX: 'l',
    marginX: 'auto',
    color: 'blueberry.100',

    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: ['center', 'center', 'left', 'left', 'left'],

    p: {
      fontSize: ['xs', 'xs', 'm', 17, 17],
      marginTop: 'm',
    },
  },
  short: {
    marginTop: ['l', 'l', 'l', 0],
    width: ['100%', '100%', '100%', '50%'],
    figure: {
      position: 'relative',
      display: 'block',
      width: ['100%', '100%', '100%', '140%', '160%'],
      transform: ['rotate(0)', 'rotate(0)', 'rotate(0)', 'rotate(1deg)'],
    },
  },
  long: {
    width: ['100%', '100%', '100%', '50%'],
    paddingRight: [0, 0, 0, 'xl'],
  },
};
