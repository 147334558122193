import moment from 'moment';
import { getIsAuthenticated } from './auth';

/*
 * Lists
 */

export const getLists = state => state.lists.items;
export const getListsLoading = state => state.lists.areLoading;

export const getReadingList = state => {
  if (!getIsAuthenticated(state)) return null;
  if (!state.lists.items) return null;
  return state.lists.items.reading;
};

export const getToReadList = state => {
  if (!getIsAuthenticated(state)) return null;
  if (!state.lists.items) return null;
  return state.lists.items['to-read'];
};

export const getReadList = state => {
  if (!getIsAuthenticated(state)) return null;
  if (!state.lists.items) return null;
  return state.lists.items.read;
};

export const getAbandonedList = state => {
  if (!getIsAuthenticated(state)) return null;
  if (!state.lists.items) return null;
  return state.lists.items.abandoned;
};

export const getAllBooks = state => {
  if (!getIsAuthenticated(state)) return null;
  if (!state.lists.items) return null;
  if (state.lists.items.length === 0) {
    return null;
  }
  return [
    ...(state.lists.items.reading.books || []),
    ...(state.lists.items['to-read']?.books || []),
    ...(state.lists.items.read?.books || []),
    ...(state.lists.items.abandoned?.books || []),
  ];
};

export const getReadingListId = state => {
  return getReadingList(state)?.id;
};

export const getToReadListId = state => {
  return getToReadList(state)?.id;
};

export const getReadListId = state => {
  return getReadList(state)?.id;
};

export const getAbandonedListId = state => {
  return getAbandonedList(state)?.id;
};

export const getListLength = list => {
  return getListBooks(list).length;
};

export const getListBooks = list => {
  if (!list?.books) return [];
  return list.books;
};

export const getBooksAddedSince = (list, cutoff) => {
  const books = getListBooks(list);
  return books.filter(book => {
    const addedAt = moment(book.addedAt);
    if (addedAt.isBefore(cutoff)) {
      return false;
    }
    return true;
  });
};

export const getDateBookAddedToList = ({ list, bookId, workId }) => {
  const books = getListBooks(list);
  const book = books.find(b => b.workId === workId || bookId === b.id);
  if (!book) return null;
  return new Date(book.addedAt);
};

export const getListName = list => {
  if (!list) return 'List';

  if (typeof list === 'string') {
    switch (list) {
      case 'to-read':
        return 'To Read';
      case 'read':
        return 'Read';
      case 'reading':
        return 'Reading';
      default:
        return 'List';
    }
  }

  switch (list.name) {
    case 'Want to read':
      return 'To Read';
    case 'Read':
      return 'Read';
    case 'Reading':
      return 'Reading';
    default:
      return list.name;
  }
};

export const getSelectedList = (bookId, workId) => {
  return state => {
    if (!getIsAuthenticated(state)) return null;

    const readingList = getReadingList(state);
    const toReadList = getToReadList(state);
    const readList = getReadList(state);
    const abandonedList = getAbandonedList(state);
    const lists = [readingList, toReadList, readList, abandonedList];
    return lists.find(list => {
      // the abandoned list may or may not exist
      const books = list?.books || [];
      return !!books.find(book => {
        if (workId && book.workId) {
          return book.workId === workId || book.id === bookId;
        }
        return book.id === bookId;
      });
    });
  };
};

export const getTotalBooksCount = state => {
  const readingBooks = getListBooks(getReadingList(state));
  const toReadBooks = getListBooks(getToReadList(state));
  const readBooks = getListBooks(getReadList(state));
  return readingBooks.length + toReadBooks.length + readBooks.length;
};

export const getCollectionItemsThreadId = collectionId => {
  return `col:${collectionId}`;
};
