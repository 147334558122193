import React from 'react';

import Svg from '../components/Svg';

export default function SunIcon(props) {
  return (
    <Svg height="16" viewBox="0 0 16 16" width="16" {...props}>
      <path
        clipRule="evenodd"
        d="m8 4c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm0-6c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1s-1-.448-1-1v-1c0-.552.448-1 1-1zm0 13c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1s-1-.448-1-1v-1c0-.552.448-1 1-1zm-7-6h1c.552 0 1 .448 1 1s-.448 1-1 1h-1c-.552 0-1-.448-1-1s.448-1 1-1zm13 0h1c.552 0 1 .448 1 1s-.448 1-1 1h-1c-.552 0-1-.448-1-1s.448-1 1-1zm-11.657-4.66c.39-.39 1.024-.39 1.414 0l.707.71c.276.247.392.627.303.987-.09.36-.37.64-.73.73-.36.087-.74-.03-.987-.307l-.707-.7c-.39-.393-.39-1.027 0-1.42zm9.193 9.2c.39-.39 1.024-.39 1.414 0l.707.7c.392.39.393 1.025.003 1.417s-1.025.393-1.417.003l-.707-.71c-.188-.187-.293-.44-.293-.705s.105-.518.293-.705zm-9.193.7.707-.7c.247-.277.626-.394.986-.306.36.09.64.37.73.73s-.026.74-.302.986l-.707.71c-.392.39-1.027.39-1.417-.003-.39-.392-.39-1.027.003-1.417zm9.193-9.19.707-.71c.254-.253.623-.35.968-.257.347.093.616.364.708.71s-.007.714-.26.967l-.708.7c-.247.277-.626.394-.986.306-.36-.09-.64-.37-.73-.73s.026-.74.302-.986z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
