import React from 'react';

import Svg from '../../components/Svg';

export default function BookmarkIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m9.54839 7.6v12.2403l4.07511-2.3396c.2338-.1343.5181-.1343.7519 0l4.0762 2.3398v-12.2405zm-1.15157-1.18995c.25408-.26255.59869-.41005.95802-.41005h9.29036c.3593 0 .7039.1475.958.41005s.3968.61865.3968.98995v13.8c0 .2835-.1452.5457-.3816.6895-.2364.1437-.5288.1475-.7685.0098l-4.8504-2.7841-4.8493 2.7841c-.23979.1377-.53218.134-.76861-.0098-.23643-.1437-.38159-.406-.38159-.6895v-13.8c0-.3713.14274-.7274.39682-.98995z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
