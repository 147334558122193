import { push } from 'connected-react-router';
import mixpanel from 'mixpanel-browser';

import {
  updateFormState,
  SIGN_UP_FORM,
  SEND_FORGOT_PASSWORD_FORM,
  SET_FORGOT_PASSWORD_FORM,
} from './forms';
import { callApi, checkEmailValidity } from '../utils';

import {
  API_SIGN_IN_URL,
  API_SIGN_OUT_URL,
  API_SIGN_UP_URL,
  AUTH_CACHE_KEY,
  AUTH_TOKEN,
  ROOT_ROUTE,
  HOME_ROUTE,
  API_SEND_FORGOT_PASSWORD_URL,
  API_SET_FORGOT_PASSWORD_URL,
  SENT_FORGOT_PASSWORD_ROUTE,
  API_USER_URL,
  // SIGN_IN_ROUTE,
} from '../constants';

// Actions
export const AUTHENTICATE = 'AUTHENTICATE';
export const FORM_LOADED = 'FORM_LOADED';
export const FORM_LOADING = 'FORM_LOADING';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const SET_TOKEN = 'SET_TOKEN';

// Reducer
const authReducer = (
  state = {
    isAuthenticated: false,
    isLoading: false,
    token: null,
    error: {
      email: '',
      form: '',
      password: '',
    },
  },
  action
) => {
  switch (action.type) {
    case AUTHENTICATE:
      if (!action.value) {
        window.localStorage.removeItem(AUTH_CACHE_KEY);
      }
      return {
        ...state,
        isAuthenticated: action.value,
      };
    case SET_TOKEN:
      const newState = {
        ...state,
        token: action.token,
      };
      if (action.token === null) {
        localStorage.removeItem(AUTH_TOKEN);
        window.localStorage.removeItem(AUTH_CACHE_KEY);
        delete newState.token;
      } else {
        localStorage.setItem(AUTH_TOKEN, action.token);
      }
      return {
        ...state,
        token: action.token,
      };
    case FORM_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FORM_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_EMAIL_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          email: action.error,
        },
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          password: action.error,
        },
      };
    case SET_FORM_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          form: action.error,
        },
      };
    default:
      return state;
  }
};

// Action Creators
export const authenticate = value => ({
  type: AUTHENTICATE,
  value,
});

export const setToken = token => ({
  type: SET_TOKEN,
  token,
});

export const formLoading = () => ({
  type: FORM_LOADING,
});

export const formLoaded = () => ({
  type: FORM_LOADED,
});

export const setEmailError = error => ({
  type: SET_EMAIL_ERROR,
  error,
});

export const setPasswordError = error => ({
  type: SET_PASSWORD_ERROR,
  error,
});

export const setFormError = error => ({
  type: SET_FORM_ERROR,
  error,
});

// Side effects
export function shouldSignIn({ email, password, dispatch, nextUrl }) {
  dispatch(setEmailError(''));
  dispatch(setPasswordError(''));

  // validate form before setting loading states
  if (!email) {
    dispatch(setEmailError('Please enter an email address.'));
    return;
  }

  const isEmailValid = checkEmailValidity(email.trim());
  if (!isEmailValid) {
    dispatch(setEmailError("Your email address doesn't look right"));
    return;
  }

  if (!password) {
    dispatch(setPasswordError('Please enter a password.'));
    return;
  }

  dispatch(formLoading());
  fetch(API_SIGN_IN_URL, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => {
      dispatch(formLoaded());
      const errorCode = data.error_code;
      if (errorCode || !data.token) {
        switch (errorCode) {
          case 'BAD_USER':
            dispatch(setEmailError('No trace, try something different'));
            break;
          case 'BAD_PASS':
            dispatch(setPasswordError('Wrong password'));
            break;
          default:
            dispatch(setFormError('Something went wrong. Please try again!'));
            break;
        }
        return;
      }
      dispatch(formLoaded());
      dispatch(setToken(data.token));
      dispatch(push(nextUrl || HOME_ROUTE));
      mixpanel.track('Sign In');
    })
    .catch(() => {
      dispatch(formLoaded());
      dispatch(setFormError('Something went wrong. Please try again!'));
    });
}

export function shouldSignUp({
  dispatch,
  email,
  username,
  password,
  inviteCode,
}) {
  dispatch(formLoading());
  dispatch(updateFormState(SIGN_UP_FORM, ''));
  dispatch(updateFormState(SIGN_UP_FORM, '', 'email'));
  dispatch(updateFormState(SIGN_UP_FORM, '', 'password'));
  dispatch(updateFormState(SIGN_UP_FORM, '', 'username'));

  return fetch(API_SIGN_UP_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      inviteCode,
      username,
    }),
  })
    .then(response => response.json())
    .then(data => {
      dispatch(formLoaded());

      const errorCode = data.error_code;
      if (errorCode || !data.token) {
        switch (errorCode) {
          case 'EMAIL_IN_USE':
            dispatch(
              updateFormState(
                SIGN_UP_FORM,
                "Somebody's already using this",
                'email'
              )
            );
            break;
          case 'ALREADY_REDEEMED':
          case 'NO_INVITE':
            dispatch(
              updateFormState(
                SIGN_UP_FORM,
                "We're sorry, but your invite code is not valid"
              )
            );
            break;
          case 'INVALID_INPUT':
            if (data.errors.password) {
              dispatch(
                updateFormState(
                  SIGN_UP_FORM,
                  data.errors.password[0],
                  'password'
                )
              );
            }
            if (data.errors.email) {
              dispatch(
                updateFormState(SIGN_UP_FORM, data.errors.email[0], 'email')
              );
            }
            if (data.errors.username) {
              dispatch(
                updateFormState(
                  SIGN_UP_FORM,
                  data.errors.username[0],
                  'username'
                )
              );
            }
            break;
          default:
            dispatch(
              updateFormState(
                SIGN_UP_FORM,
                'Something went wrong. Please try again!'
              )
            );
            break;
        }
        return;
      }
      mixpanel.track('Sign Up', {
        fromInvite: !!inviteCode,
      });
      window.plausible('signup');
      dispatch(setToken(data.token));
      dispatch(push(ROOT_ROUTE));
    })
    .catch(() => {
      dispatch(formLoaded());
      dispatch(setFormError('Something went wrong. Please try again!'));
    });
}

export function shouldSignOut({ dispatch }) {
  fetch(API_SIGN_OUT_URL, {
    method: 'POST',
  }).then(() => {
    dispatch(setToken(null));
    dispatch(authenticate(false));
    dispatch(push(ROOT_ROUTE));
    window.location.reload();
  });
  return null;
}

export default authReducer;

export function shouldSendResetPasswordEmail({ dispatch, email }) {
  dispatch(formLoading());
  dispatch(updateFormState(SEND_FORGOT_PASSWORD_FORM, ''));
  dispatch(updateFormState(SEND_FORGOT_PASSWORD_FORM, '', 'email'));

  return fetch(API_SEND_FORGOT_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then(response => response.json())
    .then(data => {
      dispatch(formLoaded());
      const errorCode = data.error_code;
      if (errorCode) {
        switch (errorCode) {
          case 'INVALID_INPUT':
            if (data.errors.email) {
              dispatch(
                updateFormState(
                  SEND_FORGOT_PASSWORD_FORM,
                  data.errors.email[0],
                  'email'
                )
              );
            }
            break;
          default:
            dispatch(
              updateFormState(
                SEND_FORGOT_PASSWORD_FORM,
                'Something went wrong. Please try again!'
              )
            );
            break;
        }
        return;
      }
      dispatch(push(SENT_FORGOT_PASSWORD_ROUTE));
    })
    .catch(() => {
      dispatch(formLoaded());
      dispatch(
        updateFormState(
          SEND_FORGOT_PASSWORD_FORM,
          'Something went wrong. Please try again!'
        )
      );
    });
}

export function shouldSetForgotPassword({ dispatch, password, token }) {
  dispatch(formLoading());
  dispatch(updateFormState(SET_FORGOT_PASSWORD_FORM, ''));
  dispatch(updateFormState(SET_FORGOT_PASSWORD_FORM, '', 'password'));
  dispatch(updateFormState(SET_FORGOT_PASSWORD_FORM, '', 'confirmation'));

  fetch(API_SET_FORGOT_PASSWORD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password, token }),
  })
    .then(response => response.json())
    .then(data => {
      dispatch(formLoaded());

      const errorCode = data.error_code;
      if (errorCode) {
        switch (errorCode) {
          case 'INVALID_INPUT':
            if (data.errors.password) {
              dispatch(
                updateFormState(
                  SET_FORGOT_PASSWORD_FORM,
                  data.errors.password[0],
                  'password'
                )
              );
            }
            break;
          default:
            dispatch(
              updateFormState(
                SET_FORGOT_PASSWORD_FORM,
                'Something went wrong. Please try again!'
              )
            );
            break;
        }
        return;
      }
      dispatch(setToken(data.token));
      dispatch(push(ROOT_ROUTE));
    })
    .catch(() => {
      dispatch(formLoaded());
    });
}

export function shouldDeleteAccount({ token, confirm, dispatch }) {
  return callApi(API_USER_URL, {
    token,
    method: 'DELETE',
    body: {
      confirm,
    },
  }).then(() => {
    mixpanel.track('Delete Account');
    dispatch(setToken(null));
    dispatch(authenticate(false));
    dispatch(push(ROOT_ROUTE));
    window.location.reload();
  });
}
