import React from 'react';

import Svg from '../../components/Svg';

export default function CheckIcon(props) {
  return (
    <Svg {...props}>
      <path
        clipRule="evenodd"
        d="m20.769 9.23434c.308.31243.308.81896 0 1.13136l-8.2817 8.4c-.308.3124-.8074.3124-1.1154 0l-4.14086-4.1998c-.30803-.3124-.30806-.8189-.00005-1.1314.308-.3124.8074-.3125 1.11544 0l3.58317 3.6341 7.724-7.83429c.308-.31242.8074-.31241 1.1154.00003z"
        fillRule="evenodd"
      />
    </Svg>
  );
}
